import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CasesService } from '@app/services/cases.service';
import { CustomersService } from '@app/services/customers.service';

@Component({
  selector: 'app-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.scss']
})
export class CustomerInfoComponent implements OnInit {

  currentCustomer;
  case
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sCustomers: CustomersService,
    private sCases: CasesService

  ) { }

  ngOnInit(): void {
    this.sCustomers.getCustomerById(this.data.id).subscribe(res => {
      this.currentCustomer = res[0];
      this.sCases.getAllCasesByCustomerId(this.data.id).subscribe(res => {
        this.case = res[0];
      });
    });
  }


}
