import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs/Rx';
import {io} from 'socket.io-client';
import { AuthenticationService } from '@app/_services';
import { apiUrl } from '@environments/env';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ChatserviceService {

  mobile;
  router;
  isAdmin = false;
  newMessage
  channels = [];
  directs = [];
  isSidenavOpen = false;
  rooms = []
  roomsID = []
  messageList = []

  currentUser
  private socket

  public message$: BehaviorSubject<string> = new BehaviorSubject('');

  historique = []
  constructor(
    private authenticationService: AuthenticationService,
    private http: HttpClient,
    ) {
    this.authenticationService.user.subscribe(x => {
      if (x) {
        this.currentUser = x;
        this.socket = io(apiUrl.apiUrl, { transports: ['websocket'], query: { token: x.token, userId: x.id.toString() }});
        this.getGroups()
      }
    });
   }

  newMessageReceived(){
      let observable = new Observable(observer=>{
          this.socket.on('messages:new:notif', (data)=>{
              observer.next(data);
          });
          return () => {this.socket.disconnect();}
      });

      return observable;
  }

    async getGroups(): Promise<void> {
        try {
          const countUnread: any = await this.http.get(apiUrl.apiUrl + '/messages/count-unread').toPromise();

          const groups: any = await this.http.get(apiUrl.apiUrl + '/groups').toPromise();
          this.channels = groups.filter(g => g.type === 'channel');
          this.directs = groups.filter(g => g.type === 'direct');
          this.isSidenavOpen = false;
        } catch (error) {}

        this.joinAllRoom()
    }

    joinAllRoom() {
        this.rooms = this.channels.concat(this.directs)
        this.roomsID = this.rooms.map(room => room._id )
        this.roomsID.forEach(id => {
          this.socket.emit('app:room:join', `groups:${id}`);
        });
    }

  leadReceive(){
      let observable = new Observable(observer=>{
          this.socket.on('lead', (data)=>{
              observer.next(data);
          });
      });
      return observable;
  }

    historiqueReceive(){
      let observable = new Observable(observer=>{
          this.socket.on('historic_receive', (data)=>{
              observer.next(data);
              this.historique.push(data)
          });
      });

      return observable;
    }

    leadPutReceive(){
      let observable = new Observable(observer=>{
          this.socket.on('lead:put', (data)=>{
              observer.next(data);
          });
      });

      return observable;
    }


}
