<img src="../../../assets/icons/cross.svg" class="close" (click)="closeModale()">
<div class="contain-pdf">
  <pdf-viewer [src]=pdfSrc [original-size]="false"></pdf-viewer>
  <a id="pdf" [attr.href]="pdfSrc" target="_blank" rel="noopener noreferrer">Voir le document</a>
</div>

<app-loading *ngIf="pdfSrc === ''"></app-loading>

<pdf-viewer *ngIf="voir" [src]="pdfSrc" [original-size]="false"></pdf-viewer>


<div *ngIf="pdfSrc != ''" style="position: absolute; right: 5%; bottom: 5%;">
  <button class="btn-secondarycrm" (click)="openDialog()">{{ skipSignature ? 'Fermer' : 'Signer'}}</button>
</div>



