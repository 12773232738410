import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChantierService } from '@app/services/chantier.service';

@Component({
  selector: 'app-chantier-choice',
  templateUrl: './chantier-choice.component.html',
  styleUrls: ['./chantier-choice.component.scss']
})
export class ChantierChoiceComponent implements OnInit {

  caseId;
  chantiers
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private sChantiers: ChantierService,
    private dialogRef: MatDialogRef<ChantierChoiceComponent>
) {
    this.caseId = data.caseId;

   }

  ngOnInit(): void {
    this.showChantierByCaseId()
  }

  showChantierByCaseId() {
    this.sChantiers.getChantierByCaseId(this.caseId).subscribe(res => {
      this.chantiers = res
  })
  }

  saveChoice(event) {
   this.dialogRef.close(event.value);

  }

}
