<div class="book-container">

  <div class="bandeau">
    <img class="logo_couleur" src="./../../../assets/book/logo_blanc.png" alt="logo" />

    <h1>Hiérarchiser les solutions</h1>
    <p *ngIf="step === 0 || step === 1" class="textHeader">{{ textHeader }}</p>
    <div *ngIf="step === 2" class="toggleSection textHeader">
      <mat-slide-toggle [(ngModel)]="isChecked" class="fadein" style="margin-right: 10%;" (change)="switchVmc($event)">
        <p>Ventilation </p>
      </mat-slide-toggle>
      <mat-slide-toggle [(ngModel)]="isChecked2" class="fadein" style="margin-right: 10%;" (change)="switchPAC($event)">
        <p>Pompe à chaleur</p>
      </mat-slide-toggle>
      <mat-slide-toggle [(ngModel)]="isChecked3" class="fadein" (change)="switchIsolation($event)">
        <p>Isolation</p>
      </mat-slide-toggle>
      <mat-slide-toggle [(ngModel)]="isChecked4" class="fadein" (change)="switchPanneau($event)">
        <p>Panneaux solaires</p>
      </mat-slide-toggle>

    </div>
    <div class="white-container">
      <p *ngIf="step === 0">Selon vous quel(s) élément(s) faut-il prioriser dans votre habitation ?</p>

      <div *ngIf="step === 0" class="row-half">
        <span class="span-item" (click)="selectSpan(1)" [ngClass]="{ 'span-selected': isSelected(1) }">Isolation</span>
        <span class="span-item" (click)="selectSpan(2)"
          [ngClass]="{ 'span-selected': isSelected(2) }">Ventilation</span>
      </div>
      <div *ngIf="step === 0" class="row-half">
        <span class="span-item" (click)="selectSpan(3)" [ngClass]="{ 'span-selected': isSelected(3) }">Chauffage
          ECS</span>
        <span class="span-item" (click)="selectSpan(4)" [ngClass]="{ 'span-selected': isSelected(4) }">Autonomie</span>
      </div>

      <div *ngIf="step === 1">
        <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="27.5" cy="27.5" r="26.5" stroke="#031966" stroke-width="2" />
          <path
            d="M25.1395 12.9967C25.063 11.3649 26.3654 10 27.999 10C29.6327 10 30.935 11.3649 30.8586 12.9967L29.9618 32.1271C29.9127 33.1753 29.0485 34 27.999 34C26.9496 34 26.0854 33.1753 26.0362 32.1271L25.1395 12.9967Z"
            fill="#031966" />
          <circle cx="27.999" cy="41" r="3" fill="#031966" />
        </svg>

        <p>
          Les performances de ces 3 éléments dépendent du maillon le plus faible.
          Il faut donc agir sur une solution globale en adéquation avec les caractéristiques de chaque bâtiment afin de
          construire
          la bonne solution.
        </p>
      </div>

      <div *ngIf="step === 2">
        <canvas #myCanvas height="100" id="canvas" baseChart [chartType]="chartType" [datasets]="chartData"
          [labels]="chartLabels" [colors]="chartColors" [options]="chartOptions"></canvas>
      </div>
    </div>
  </div>

  <button class="primary-button precedent" (click)="changeStepPrecedent()">
    <svg style="transform:rotate(-180deg);" width="22" height="20" viewBox="0 0 14 12" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0303 6.53057C13.3232 6.23768 13.3232 5.76281 13.0303 5.46991L8.25736 0.696943C7.96447 0.40405 7.48959 0.40405 7.1967 0.696943C6.90381 0.989837 6.90381 1.46471 7.1967 1.7576L11.4393 6.00024L7.1967 10.2429C6.90381 10.5358 6.90381 11.0107 7.1967 11.3035C7.48959 11.5964 7.96447 11.5964 8.25736 11.3035L13.0303 6.53057ZM0.5 6.75024H12.5V5.25024H0.5V6.75024Z"
        fill="white" />
    </svg>
    Précédent
  </button>

  <button class="primary-button suivant" (click)="selectedSpans.length > 0 && changeStepAndRouting()"
    [ngClass]="{ 'disabled': selectedSpans.length === 0 }">
    Suivant
    <svg width="22" height="20" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0303 6.53057C13.3232 6.23768 13.3232 5.76281 13.0303 5.46991L8.25736 0.696943C7.96447 0.40405 7.48959 0.40405 7.1967 0.696943C6.90381 0.989837 6.90381 1.46471 7.1967 1.7576L11.4393 6.00024L7.1967 10.2429C6.90381 10.5358 6.90381 11.0107 7.1967 11.3035C7.48959 11.5964 7.96447 11.5964 8.25736 11.3035L13.0303 6.53057ZM0.5 6.75024H12.5V5.25024H0.5V6.75024Z"
        fill="white" />
    </svg>
  </button>


</div>
<svg class="maison" width="1095" height="728" viewBox="0 0 1095 728" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="350.5" *ngIf="!isChecked3" cy="322.5" r="322.5" fill="#FFAA33" fill-opacity="0.1" />
  <path d="M673.109 348.221V541.966H620.169V287.546L673.109 348.221Z" fill="white" />
  <path d="M620.168 287.546H426.01V541.966H620.168V287.546Z" fill="#CEDADC" />
  <path d="M900.01 348.221H673.11L620.17 287.545H426.011L251.992 88H673.11L847.129 287.545L900.01 348.221Z"
    fill="#031966" />
  <path d="M426.011 287.545V541.966H78.0332V287.545L251.992 88L426.011 287.545Z" fill="url(#paint0_linear_0_1)" />
  <g opacity="0.5">
    <path opacity="0.63" d="M369.394 222.685L78.0024 513.817V450.064L339.674 188.606L369.394 222.685Z" fill="white" />
    <path opacity="0.63" d="M328.926 176.262L78.0024 426.971V313.776L276.173 115.775L328.926 176.262Z" fill="white" />
    <path opacity="0.63" d="M425.981 342.729V397.099L280.989 541.967H226.572L425.981 342.729Z" fill="white" />
    <path opacity="0.63" d="M425.98 436.85V511.294L395.27 541.968H320.783L425.98 436.85Z" fill="white" />
  </g>
  <path
    d="M283.327 217.31H345.788L338.497 209.104H283.327V123.307L282.713 122.624L276.789 115.775L124.694 290.258V544.679H136.552V468.83H200.241V544.679H209.811V511.53H273.748V544.679H283.318V468.83H346.393V460.614H283.318V385.003H346.393V376.788H283.318V301.176H346.393V292.901H283.318V217.29L283.327 217.31ZM200.251 460.614H136.562V385.003H200.251V460.614ZM200.251 376.797H136.562V301.186H200.251V376.797ZM200.251 292.921H136.562V290.268L200.192 217.31H200.251V292.921ZM273.758 503.325H209.821V427.713H273.758V503.325ZM273.758 419.448H209.821V343.837H273.758V419.448ZM273.758 335.631H209.821V260.02H273.758V335.631ZM273.758 251.804H209.821V207.56L274.917 135.037L273.748 251.794L273.758 251.804Z"
    fill="#858D96" />
  <path
    d="M410.855 343.837V335.632H355.933V260.02H383.929L376.568 251.805H355.933V228.663L346.422 217.983V544.679H355.933V511.531H410.855V503.325H355.933V427.723H410.855V419.449H355.933V343.837H410.855Z"
    fill="#858D96" />
  <path d="M900.012 348.22V382.29H702.455V541.966H673.112V348.22H900.012Z" fill="#CEDADC" />
  <path
    d="M426.039 287.545V541.966H407.93V287.545L267.772 130.344L252.021 112.686L96.1012 287.545V541.966H78.002V287.545L252.021 88L278.451 118.367L426.039 287.545Z"
    fill="white" />
  <path d="M127.665 287.546H96.1023V541.966H127.665V287.546Z" fill="#B5C9CD" />
  <path d="M267.773 130.345L127.665 287.546H96.1023L252.022 112.687L267.773 130.345Z" fill="#CEDADC" />
  <g style="mix-blend-mode:multiply" opacity="0.49">
    <path d="M293.658 159.416L181.962 287.547V541.967H127.664V287.547L267.763 130.345L293.658 159.416Z"
      fill="#959BAD" />
  </g>
  <path d="M581.621 287.556H569.634L485.805 191.386L490.124 182.616L497.792 191.386L581.621 287.556Z" fill="#DCE2E3" />
  <path d="M569.637 287.557H526.702L442.872 191.387H485.807L569.637 287.557Z" fill="#87CDD9" />
  <path d="M490.127 182.616L485.808 191.386H442.873L435.215 182.616H490.127Z" fill="#C5C9CB" />
  <path d="M581.624 287.556H569.637V382.291H581.624V287.556Z" fill="white" />
  <path d="M569.637 287.556H526.702V382.291H569.637V287.556Z" fill="#5BC1D3" />
  <path d="M508.204 431.465H468.578V542.323H508.204V431.465Z" fill="#5BC1D3" />
  <path d="M514.982 431.465H501.271V541.878H514.982V431.465Z" fill="white" />
  <path d="M879.285 382.291H702.453V541.967H879.285V382.291Z" fill="#68C5DE" />
  <g opacity="0.5">
    <path opacity="0.63" d="M811.536 382.291L702.435 491.23V448.024L768.224 382.291H811.536Z" fill="white" />
    <path opacity="0.63" d="M857.304 382.291L702.435 537.028V507.215L827.406 382.291H857.304Z" fill="white" />
    <path opacity="0.63" d="M879.294 417.411V456.478L793.8 541.968H754.63L879.294 417.411Z" fill="white" />
    <path opacity="0.63" d="M879.295 471.294V485.3L822.59 541.967H808.562L879.295 471.294Z" fill="white" />
  </g>
  <path d="M879.276 382.291H871.301V541.967H879.276V382.291Z" fill="#858D96" />
  <path d="M825.235 382.291H817.25V541.967H825.235V382.291Z" fill="#858D96" />
  <path d="M771.175 382.291H763.19V541.967H771.175V382.291Z" fill="#858D96" />
  <path d="M717.122 382.291H709.148V541.967H717.122V382.291Z" fill="#858D96" />
  <path d="M968.693 541.965H0V557.06H968.693V541.965Z" fill="white" />
  <path
    d="M928.124 463.889C925.192 469.096 923.25 474.203 922.973 480.33C922.824 483.893 926.529 486.328 928.719 488.625C931.403 491.446 925.489 495.128 925.747 501.255C925.895 504.857 928.352 507.49 928.184 511.351C928.035 514.657 926.202 515.211 927.609 518.586C928.471 520.655 935.881 520.397 935.108 518.576C933.88 515.666 934.048 513.063 934.91 510.47C935.564 508.52 934.94 505.6 934.385 503.818C933.325 500.354 931.017 499.334 933.969 494.791C934.465 494.039 935.317 491.802 935.376 490.773C935.544 487.031 934.405 486.17 931.8 483.636C925.925 477.905 930.215 470.184 933.464 464.424C934.861 461.959 929.908 462.939 928.966 464.612L928.134 463.889H928.124Z"
    fill="#895744" />
  <path
    d="M957.133 431.869C957.698 434.166 958.015 436.551 958.015 439.036C958.015 446.261 955.38 452.853 950.991 457.921C951.13 459.584 950.169 461.306 948.831 462.445C948.425 462.059 948.178 461.514 948.039 460.901C942.937 465.325 936.3 468.017 929.018 468.017C918.052 468.017 908.512 461.95 903.588 452.992C902.657 453.131 901.746 453.19 900.834 453.17C900.042 451.596 900.586 450.003 901.726 448.855C900.636 445.786 900.022 442.49 900.022 439.036C900.022 433.394 901.637 428.148 904.43 423.694C902.885 422.377 901.775 420.17 903.043 417.676C904.757 417.329 906.372 418.378 907.283 419.883C907.283 419.853 907.303 419.833 907.323 419.814L907.353 419.784C906.352 418.101 906.897 415.993 908.026 414.647C909.146 414.805 909.869 415.845 910.146 417.062C915.218 412.687 921.816 410.064 929.028 410.064C929.979 410.064 930.91 410.104 931.822 410.203C932.575 408.906 934.071 407.797 936.191 407.015C936.646 408.589 936.577 409.876 936.032 410.925C943.68 412.816 950.109 417.725 954.003 424.337C954.934 423.129 956.182 422.13 957.411 421.635C958.55 423.288 958.847 424.723 958.183 425.97C958.728 425.881 959.204 425.832 959.6 425.832C960.799 425.901 962.057 426.515 963.236 427.247C963.166 430.662 960.283 432.067 957.143 431.879L957.133 431.869Z"
    fill="#73C097" />
  <g style="mix-blend-mode:multiply" opacity="0.74">
    <path
      d="M957.13 431.87C957.695 434.167 958.012 436.552 958.012 439.027C958.012 446.252 955.377 452.844 951.008 457.912C951.137 459.575 950.166 461.297 948.819 462.426C948.412 462.04 948.165 461.495 948.026 460.882C942.944 465.306 936.307 467.988 929.006 467.988C922.903 467.988 917.266 466.108 912.58 462.881C916.266 462.851 919.862 462.267 923.27 461.188C923.428 462.079 923.795 462.94 924.32 463.693C925.409 462.99 925.826 461.822 925.548 460.426C930.759 458.447 935.395 455.398 939.08 451.696C939.556 453.121 940.893 454.398 942.469 455.378C942.944 453.923 943.063 452.26 942.766 450.766C943.925 451.29 945.044 451.577 946.183 451.627C946.134 449.677 944.747 447.173 943.36 446.47C944.529 444.698 945.46 442.857 946.183 440.947C949.552 431.88 946.867 418.31 939.467 411.995C945.589 414.341 950.681 418.726 953.98 424.318C954.931 423.11 956.159 422.111 957.388 421.616C958.527 423.279 958.824 424.704 958.161 425.951C958.735 425.862 959.181 425.813 959.577 425.813C960.806 425.882 962.034 426.496 963.213 427.218C963.143 430.633 960.281 432.029 957.12 431.85L957.13 431.87Z"
      fill="#D7B897" />
  </g>
  <g style="mix-blend-mode:multiply">
    <path
      d="M931.572 467.899C930.116 470.729 928.897 473.798 928.887 476.767C927.005 476.579 925.153 476.47 923.399 476.421C923.904 473.402 924.836 470.601 926.104 467.869C927.055 467.958 928.035 468.017 929.016 468.017C929.878 468.017 930.74 467.988 931.582 467.899H931.572Z"
      fill="#D7B897" />
  </g>
  <path d="M944.073 541.966H915.592L912.58 501.255H946.847L944.073 541.966Z" fill="white" />
  <g style="mix-blend-mode:multiply" opacity="0.74">
    <path d="M946.847 501.255L944.073 541.966H933.226L932.948 501.255H946.847Z" fill="#D8E0E2" />
  </g>
  <g style="mix-blend-mode:multiply" opacity="0.49">
    <path d="M879.296 382.291V417.41H723.565V541.967H702.434V382.291H879.296Z" fill="#959BAD" />
  </g>
  <g style="mix-blend-mode:multiply" opacity="0.49">
    <path d="M480.833 431.544H469.005V541.957H480.833V431.544Z" fill="#959BAD" />
  </g>
  <g style="mix-blend-mode:multiply" opacity="0.49">
    <path d="M536.47 287.556H526.702V382.291H536.47V287.556Z" fill="#959BAD" />
  </g>
  <path *ngIf="isChecked2" class="fadein"
    d="M610.164 478.975H529.086C527.949 478.975 527.027 479.897 527.027 481.033V536.96C527.027 538.097 527.949 539.018 529.086 539.018H610.164C611.301 539.018 612.223 538.097 612.223 536.96V481.033C612.223 479.897 611.301 478.975 610.164 478.975Z"
    fill="white" />
  <path *ngIf="isChecked2" class="fadein"
    d="M547.602 539.018H535.663C535.437 539.018 535.254 539.201 535.254 539.426V541.493C535.254 541.718 535.437 541.901 535.663 541.901H547.602C547.827 541.901 548.01 541.718 548.01 541.493V539.426C548.01 539.201 547.827 539.018 547.602 539.018Z"
    fill="#232C59" />
  <path *ngIf="isChecked2" class="fadein"
    d="M603.576 539.018H591.637C591.412 539.018 591.229 539.201 591.229 539.426V541.493C591.229 541.718 591.412 541.901 591.637 541.901H603.576C603.802 541.901 603.985 541.718 603.985 541.493V539.426C603.985 539.201 603.802 539.018 603.576 539.018Z"
    fill="#232C59" />
  <path *ngIf="isChecked2" class="fadein"
    d="M603.577 485.556H582.584C582.359 485.556 582.176 485.738 582.176 485.963V486.788C582.176 487.014 582.359 487.196 582.584 487.196H603.577C603.803 487.196 603.986 487.014 603.986 486.788V485.963C603.986 485.738 603.803 485.556 603.577 485.556Z"
    fill="#68C5DE" />
  <path *ngIf="isChecked2" class="fadein"
    d="M603.577 489.671H582.584C582.359 489.671 582.176 489.853 582.176 490.078V490.903C582.176 491.129 582.359 491.311 582.584 491.311H603.577C603.803 491.311 603.986 491.129 603.986 490.903V490.078C603.986 489.853 603.803 489.671 603.577 489.671Z"
    fill="#68C5DE" />
  <path *ngIf="isChecked2" class="fadein"
    d="M603.577 493.786H582.584C582.359 493.786 582.176 493.968 582.176 494.194V495.019C582.176 495.244 582.359 495.426 582.584 495.426H603.577C603.803 495.426 603.986 495.244 603.986 495.019V494.194C603.986 493.968 603.803 493.786 603.577 493.786Z"
    fill="#68C5DE" />
  <path *ngIf="isChecked2" class="fadein"
    d="M603.577 497.892H582.584C582.359 497.892 582.176 498.074 582.176 498.3V499.125C582.176 499.35 582.359 499.532 582.584 499.532H603.577C603.803 499.532 603.986 499.35 603.986 499.125V498.3C603.986 498.074 603.803 497.892 603.577 497.892Z"
    fill="#68C5DE" />
  <path *ngIf="isChecked2" class="fadein"
    d="M603.577 502.007H582.584C582.359 502.007 582.176 502.19 582.176 502.415V503.24C582.176 503.465 582.359 503.648 582.584 503.648H603.577C603.803 503.648 603.986 503.465 603.986 503.24V502.415C603.986 502.19 603.803 502.007 603.577 502.007Z"
    fill="#68C5DE" />
  <path *ngIf="isChecked2" class="fadein"
    d="M553.779 530.379C565.598 530.379 575.18 520.806 575.18 508.996C575.18 497.187 565.598 487.613 553.779 487.613C541.959 487.613 532.377 497.187 532.377 508.996C532.377 520.806 541.959 530.379 553.779 530.379Z"
    fill="#68C5DE" />
  <path *ngIf="isChecked2" class="fadein"
    d="M553.781 511.462C555.144 511.462 556.248 510.358 556.248 508.997C556.248 507.635 555.144 506.531 553.781 506.531C552.418 506.531 551.313 507.635 551.313 508.997C551.313 510.358 552.418 511.462 553.781 511.462Z"
    fill="#232C59" />
  <path *ngIf="isChecked2" class="fadein"
    d="M553.779 508.997C546.441 507.263 533.834 502.11 542.062 495.4C552.35 487.002 552.907 498.875 553.779 508.997Z"
    fill="#232C59" />
  <path *ngIf="isChecked2" class="fadein"
    d="M553.779 508.997C561.107 510.739 573.723 515.892 565.495 522.603C555.207 531 554.632 519.127 553.779 508.997Z"
    fill="#232C59" />
  <path *ngIf="isChecked2" class="fadein"
    d="M553.779 508.997C555.513 501.674 560.671 489.069 567.387 497.29C575.792 507.606 563.918 508.144 553.779 508.997Z"
    fill="#232C59" />
  <path *ngIf="isChecked2" class="fadein"
    d="M553.779 508.997C552.035 516.328 546.886 528.934 540.161 520.703C531.765 510.387 543.63 509.859 553.779 508.997Z"
    fill="#232C59" />
  <path *ngIf="isChecked4" class="fadein"
    d="M593.585 249.679L482.955 120.377L590.838 120.377L703.428 249.679L593.585 249.679Z" fill="black" stroke="#202020"
    stroke-width="6" />
  <path *ngIf="isChecked4" class="fadein"
    d="M524.802 122.147L520.131 122.147L628.966 249.175L634.017 249.174L524.802 122.147Z" fill="#202020" />
  <path *ngIf="isChecked4" class="fadein"
    d="M510.962 150.68L509.003 154.228L619.639 154.228L619.639 150.32L510.962 150.68Z" fill="#202020" />
  <path *ngIf="isChecked4" class="fadein"
    d="M534.134 182.325L537.372 186.234L648.016 186.234L648.016 182.325L534.134 182.325Z" fill="#202020" />
  <path *ngIf="isChecked4" class="fadein"
    d="M562.878 214.662L566.116 218.57L676.759 218.57L676.759 214.662L562.878 214.662Z" fill="#202020" />
  <path *ngIf="isChecked4" class="fadein"
    d="M561.781 121.37L557.13 121.37L665.995 249.278L671.384 249.278L561.781 121.37Z" fill="#202020" />
  <circle *ngIf="isChecked4" class="fadein pulse" opacity="0.25" cx="492.5" cy="127.5" r="17.5" fill="#73C097" />
  <circle *ngIf="isChecked4" class="fadein" cx="492.5" cy="127.5" r="9.54545" fill="#73C097" />
  <circle *ngIf="isChecked3" class="fadein pulse" opacity="0.25" cx="417.5" cy="381.5" r="17.5" fill="#73C097" />
  <circle *ngIf="isChecked3" class="fadein" cx="417.5" cy="381.5" r="9.54545" fill="#73C097" />
  <circle *ngIf="isChecked2" class="fadein pulse" opacity="0.25" cx="596.5" cy="524.5" r="17.5" fill="#73C097" />
  <circle *ngIf="isChecked2" class="fadein" cx="596.5" cy="524.5" r="9.54545" fill="#73C097" />
  <rect *ngIf="isChecked" class="fadein" x="297.794" y="78.1753" width="16.4398" height="10.1168" fill="#031966" />
  <path *ngIf="isChecked" class="fadein"
    d="M294 75.0137C294 68.3787 299.379 63 306.014 63V63C312.649 63 318.027 68.3787 318.027 75.0137V78.1752H294V75.0137Z"
    fill="#031966" />
  <circle *ngIf="isChecked" class="fadein pulse" opacity="0.25" cx="291.5" cy="88.5" r="17.5" fill="#73C097" />
  <circle *ngIf="isChecked" class="fadein" cx="291.5" cy="88.5" r="9.54545" fill="#73C097" />
  <defs>
    <linearGradient id="paint0_linear_0_1" x1="252.022" y1="541.966" x2="252.022" y2="88"
      gradientUnits="userSpaceOnUse">
      <stop stop-color="#68C5DE" />
      <stop offset="1" stop-color="#73C097" />
    </linearGradient>
  </defs>
</svg>