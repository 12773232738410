
<div *ngIf=showValidation>
<label>Date du Chantier :</label>
<input [(ngModel)]="date" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2">
<owl-date-time firstDayOfWeek="1" #dt2></owl-date-time>

<div *ngFor="let technicien of allTechniciens">

  <input type="checkbox"  name="checkboxName" [value]="technicien._id" (change)="$event.target.checked? (isfrmChecked = true) : isfrmChecked = false;
  componentMethodName($event,isfrmChecked);">
  {{technicien.username}}

</div>
  <button  (click)="validationChantier()">Validation</button>
</div>


<button class="btn-secondarycrm mt-4" (click)="openDialog()">+ Nouveau chantier</button>

<a class="p-3" (click)="showEdit()">Supprimer un chantier</a>

<div class="row" *ngIf="case">
  <mat-card class="col-xs-12 col-lg-3 col-sm-5" *ngFor="let chantier of chantiers">
    <img class="delete" src="../../../../assets/icons/delete.svg" *ngIf="showCross"  (click)="deleteChantier(chantier._id,'supprimé','travaux',chantier.title)">

      <mat-card-content    [ngClass]="showCross ? 'disabled' : ''"  (click)="goToEditChantier(chantier._id)">
        <h4 class="demande">{{ chantier.title }}</h4>
        <p>{{ chantier.date | date:'dd/MM/yy' }}</p>
        <p class="qualif-mairie"
        [ngClass]="{'attente':chantier.statut === 'En cours',
                    'conforme':chantier.statut === 'Terminé',
                    'nonconforme':chantier.statut === 'Annulé'
                  }">
          {{ chantier.statut }}
         </p>
         <span style="position: absolute;
         right: 13px;
         bottom: 3px;
         font-size: 11px;
         color: lightgrey;" *ngIf="chantier.type">{{ chantier.type }}</span>
      </mat-card-content>
  </mat-card>
</div>


