<img style="position: relative;
left: 97%;
margin-top:-1.5%;
width: 4%;
cursor: pointer;" src="../../../assets/icons/cross.svg" (click)="closeModale()">

<h1 class="text-center">Total {{data.selectedPartenaire}} {{total | number:'2.1-2' }} €</h1>

<div *ngIf="data.selectedPartenaire === 'sofinco'">
  <mat-accordion>
    <ng-container *ngFor="let agence of result | keyvalue">
      <mat-expansion-panel style="box-shadow: none;" *ngIf="agence.value.totalByFinancementType.sofinco > 0">
        <mat-expansion-panel-header style="padding:4rem 4rem 4rem 1rem;">
          <mat-panel-title style="padding-left:2rem;">
            <h2 *ngIf="agence.value.totalByFinancementType.sofinco > 0">{{ agence.value.nomAgence }} - {{agence.value.totalByFinancementType.sofinco | number:'2.1-2'}} €</h2>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngFor="let commercial of agence.value.commerciaux" style="padding-left:5rem; padding-top: 1rem;">
          <h4 *ngIf="commercial.totalSofinco">{{ commercial.nomCommercial }} - {{ commercial.totalSofinco | number:'2.1-2'}} €</h4>
          <mat-list>
            <mat-list-item style="padding-left: 4rem;" *ngFor="let affaire of commercial.affairesSofinco">
              {{affaire.customer}} - {{ affaire.price_ht | number:'2.1-2'}} €
            </mat-list-item>
          </mat-list>
        </div>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</div>

<div *ngIf="data.selectedPartenaire === 'cetelem'">
  <mat-accordion>
    <ng-container *ngFor="let agence of result | keyvalue">
      <mat-expansion-panel style="box-shadow: none;" *ngIf="agence.value.totalByFinancementType.cetelem > 0">
        <mat-expansion-panel-header style="padding:4rem 4rem 4rem 1rem;">
          <mat-panel-title style="padding-left:2rem;">
            <h2 *ngIf="agence.value.totalByFinancementType.cetelem > 0">{{ agence.value.nomAgence }} - {{agence.value.totalByFinancementType.cetelem | number:'2.1-2'}} €</h2>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngFor="let commercial of agence.value.commerciaux" style="padding-left:5rem; padding-top: 1rem;">
          <h4 *ngIf="commercial.totalCetelem">{{ commercial.nomCommercial }} - {{ commercial.totalCetelem | number:'2.1-2'}} €</h4>
          <mat-list>
            <mat-list-item style="padding-left: 4rem;" *ngFor="let affaire of commercial.affairesCetelem">
              {{affaire.customer}} - {{ affaire.price_ht | number:'2.1-2'}} €
            </mat-list-item>
          </mat-list>
        </div>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</div>

<div *ngIf="data.selectedPartenaire === 'cofidis'">
  <mat-accordion>
    <ng-container *ngFor="let agence of result | keyvalue">
      <mat-expansion-panel style="box-shadow: none;" *ngIf="agence.value.totalByFinancementType.cofidis > 0">
        <mat-expansion-panel-header style="padding:4rem 4rem 4rem 1rem;">
          <mat-panel-title style="padding-left:2rem;">
            <h2 *ngIf="agence.value.totalByFinancementType.cofidis > 0">{{ agence.value.nomAgence }} - {{agence.value.totalByFinancementType.cofidis | number:'2.1-2'}} €</h2>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngFor="let commercial of agence.value.commerciaux" style="padding-left:5rem; padding-top: 1rem;">
          <h4 *ngIf="commercial.totalCofidis">{{ commercial.nomCommercial }} - {{ commercial.totalCofidis | number:'2.1-2'}} €</h4>
          <mat-list>
            <mat-list-item style="padding-left: 4rem;" *ngFor="let affaire of commercial.affairesCofidis">
              {{affaire.customer}} - {{ affaire.price_ht | number:'2.1-2'}} €
            </mat-list-item>
          </mat-list>
        </div>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</div>

<div *ngIf="data.selectedPartenaire === 'Comptant'">
  <mat-accordion>
    <ng-container *ngFor="let agence of result | keyvalue">
      <mat-expansion-panel style="box-shadow: none;" *ngIf="agence.value.totalByFinancementType.comptant > 0">
        <mat-expansion-panel-header style="padding:4rem 4rem 4rem 1rem;">
          <mat-panel-title style="padding-left:2rem;">
            <h2 *ngIf="agence.value.totalByFinancementType.comptant > 0">{{ agence.value.nomAgence }} - {{agence.value.totalByFinancementType.comptant | number:'2.1-2'}} €</h2>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngFor="let commercial of agence.value.commerciaux" style="padding-left:5rem; padding-top: 1rem;">
          <h4 *ngIf="commercial.totalComptant">{{ commercial.nomCommercial }} - {{ commercial.totalComptant | number:'2.1-2'}} €</h4>
          <mat-list>
            <mat-list-item style="padding-left: 4rem;" *ngFor="let affaire of commercial.affairesComptant">
              {{affaire.customer}} - {{ affaire.price_ht | number:'2.1-2'}} €
            </mat-list-item>
          </mat-list>
        </div>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</div>
