import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CasesService } from '@app/services/cases.service';
import { CustomersService } from '@app/services/customers.service';
import { HistoriqueService } from '@app/services/historique.service';

@Component({
  selector: 'app-add-lead',
  templateUrl: './add-lead.component.html',
  styleUrls: ['./add-lead.component.scss']
})
export class AddLeadComponent implements OnInit {

  leadAffaire = new FormGroup({});
  currentUserId
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private sCases: CasesService,
    private sCustomers: CustomersService,
    private _snackBar: MatSnackBar,
    private dialogRef: MatDialog,
    private sHistoriques: HistoriqueService) {
    this.currentUserId = data
    this.leadAffaire = new FormGroup({
      financement_comptant: new FormGroup({
        'choice': new FormControl(''),
        'cheques': new FormControl(false),
        'cheque1': new FormGroup({
          'date': new FormControl(''),
          'encaissement': new FormControl(false),
          montant: new FormControl(''),
          'date_validation': new FormControl(''),
        }),
        'cheque2': new FormGroup({
          'date': new FormControl(''),
          'encaissement': new FormControl(false),
          montant: new FormControl(''),
          'date_validation': new FormControl(''),
          'validation': new FormControl(false),
        }),
        'cheque3': new FormGroup({
          'date': new FormControl(''),
          'encaissement': new FormControl(false),
          montant: new FormControl(''),
          'validation': new FormControl(false),
        }),
      }),
      title: new FormControl(' '),
      tmk: new FormControl(null),
      created_by: new FormControl(data),
      created_at: new FormControl(Date.now()),
      commercial: new FormControl(null),
      adv: new FormControl(null),
      description: new FormControl(''),
      mairie: new FormControl(''),
      lead: new FormControl(true),
      kits_vendus: new FormControl(JSON.stringify([
        { name: 'Adoucisseur d\'eau', type: 'Adoucisseur d\'eau', active: false, },
        { name: 'Ballon thermodynamique', type: 'Ballon thermodynamique', coude_entree_air: null, coude_sortie_air: null, active: false, options_bt: [] },
        { name: 'Batterie', type: 'Batterie', marque: 'Huawei', active: false, },
        { name: 'Mise aux normes du tableau électrique', type: 'Mise aux normes du tableau électrique', active: false, },
        { name: 'PAC Air/Air', type: 'PAC Air/Air', active: false, options_pac: [] },
        { name: 'PAC Air/Eau', type: 'PAC Air/Eau', active: false, options_pac: [] },
        { name: 'Panneaux solaires', type: 'Panneaux solaires', modules: [], onduleurs: [], nbr_relais: null, active: false, },
        { name: 'Thermostat radio', type: 'Thermostat radio', active: false, },
        { name: 'VMC Double Flux', type: 'VMC Double Flux', obturateurs: [], obturateur: null, active: false, },
        { name: 'Isolation', type: 'Isolation', active: false, },
      ])),
      state: new FormControl('Prospection'),
      letter: new FormGroup({
        status: new FormControl(''),
        date: new FormControl(''),
      }),
      r1: new FormGroup({
        debrief: new FormControl(''),
        date: new FormControl(''),
      }),
      rdv_state: new FormGroup({
        status: new FormControl(''),
        why: new FormControl(''),
      }),
      customer: new FormControl(''),
      stateTmk: new FormControl("En cours de confirmation"),
      datecase: new FormControl(Date.now()),

      leadClient: new FormGroup({
        name: new FormControl(''),
        mail: new FormControl('', Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')),
        address: new FormControl(''),
        cp: new FormControl(''),
        city: new FormControl(''),
        origin: new FormControl('Be energie'),
        mobile: new FormControl(''),
        fixe: new FormControl(''),
        tag: new FormControl('Nouveau Lead'),
        project: new FormControl(''),
        isActive: new FormControl(false),
        situation: new FormControl('Propriétaire'),
        habitation: new FormControl('Maison'),
      }),
    });
  }

  ngOnInit(): void { }

  addCustomer(reason, change, value) {
    this.sCustomers.createCustomer(this.leadAffaire.value.leadClient).then(res => {
      if (res['error']) {
        alert(res.error);
      }
      else {
        this.addCase(res._id, reason, change, value);
      }
    });
  }

  addCase(id, reason, change, value) {
    this.leadAffaire.value.customer = id;
    this.sCases.createCase(this.leadAffaire.value).then(res => {
      this._snackBar.open('Lead ajouté avec succès', '', { duration: 2000, verticalPosition: 'bottom', panelClass: ['add-snackbar'] });
      this.dialogRef.closeAll();
      this.sHistoriques.createHistorique(null, null, reason, change, value, this.currentUserId).then(res => {
        if (res['error']) {
          alert(res);
        }
        else {
          console.log(res)
        }
      });

    });
  }
  closeModale() { this.dialogRef.closeAll() }

}
