import { ChantierService } from '../../services/chantier.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { InfosService } from '@app/services/infos.service';
import { RolesService } from '@app/services/roles.service';

@Component({
  selector: 'app-technicien-list-chantier',
  templateUrl: './technicien-list-chantier.component.html',
  styleUrls: ['./technicien-list-chantier.component.scss']
})
export class TechnicienListChantierComponent implements OnInit {

  chantiers;
  currentUser;
  mobile;
  todayChantier =[];
  user;
  weekChantier=[];
  lastChantier = [];
  infos
  infosRead = []
  infosUnread = []
  searchResult = []
  query

  constructor(
    private authenticationService: AuthenticationService,
    private sChantier: ChantierService,
    private sInfos: InfosService,
    private sRoles: RolesService,
  ) {

    this.authenticationService.user.subscribe(x => this.currentUser = x);
    this.showAllTechChantier();
   }

  ngOnInit(): void {
    if (window.screen.width <= 900) {
      this.mobile = true;
    }
    this.showInfosByAdvId()
  }

  showAllTechChantier() {
    this.sChantier.getAllChantierByTechId(this.currentUser.id).subscribe(res => {
      this.chantiers = res
      this.chantiers.sort((a, b) => (a.date > b.date) ? -1 : 1)
      this.getDayChantier()
    });
  }

  research() {
    let re = new RegExp(this.query, 'i');
    this.searchResult = this.chantiers.filter(o=>o.caseId.customer.name.match(re))
  }


  getDayChantier(){
  this.todayChantier =[]
  const today = new Date()
  const dayNow = today.getDate()
  const monthNow = today.getMonth()+1
  const yearNow = today.getFullYear()



 this.chantiers.forEach(element => {

  if(element.date != undefined || null) {

    let chantierMonth = element.date.substr(5,2)
    let chantierDay = element.date.substr(8,2)
    let chantierYear = element.date.substr(0,4)

    let from = new Date((Date.now()) - 40 * 24 * 60 * 60 * 1000)
    let to = new Date((Date.now()) +0 * 24 * 60 * 60 * 1000)

    let check = new Date((element.date))
    if(monthNow == chantierMonth && dayNow == chantierDay && yearNow === parseInt(chantierYear)){
      this.todayChantier.push(element)
    }
    else if(monthNow <= chantierMonth && dayNow < chantierDay && chantierDay <= dayNow+15 && yearNow === parseInt(chantierYear)){
      //si mois en cours < mois du chantier && 30 < 01  &&
      // 09 < 10
      this.weekChantier.push(element)
    }
    else if(monthNow === chantierMonth -1  && (dayNow === 30 || dayNow === 31) && chantierDay > 0 && chantierDay <=15 && parseInt(chantierYear) === yearNow) {
      //si 09 inferieure a 10 &&
      this.weekChantier.push(element)

    }
    else if(monthNow === chantierMonth && yearNow === parseInt(chantierYear)) {
      this.weekChantier.push(element)

    }

    else if(monthNow == chantierMonth && dayNow > chantierDay && chantierDay >= dayNow-15 && yearNow === parseInt(chantierYear)){
      this.lastChantier.push(element)
    }
    if (monthNow != chantierMonth && this.dateCheck(from,to,check)) {
      this.lastChantier.push(element)
    }

  }
  });
  this.weekChantier.sort((a,b) =>(a.date < b.date) ? -1 : 1 )
  this.todayChantier.sort((a,b) =>(a.date < b.date) ? -1 : 1 )

  this.todayChantier = this.todayChantier.filter(res =>( new Date(res.date).getFullYear()) === new Date().getFullYear())
  this.lastChantier.sort((a,b) =>(a.date < b.date) ? -1 : 1 )

  this.weekChantier = this.weekChantier.filter(res =>( new Date(res.date).getFullYear()) === new Date().getFullYear())

  }

  dateCheck(from,to,check) {

    var fDate,lDate,cDate;
    fDate = from.getTime();
    lDate = to.getTime();
    cDate = check.getTime();

    if((cDate <= lDate && cDate >= fDate)) {
        return true;
    }
    return false;
}
  showInfosByAdvId() {
    this.sInfos.getInfoByAdvId(this.sRoles.user.id).subscribe(res => {
      this.infos = res;
      this.infos.forEach(element => {
         if(element.vu) {
          this.infosRead.push(element)
        }
        else {
          this.infosUnread.push(element)
        }
      });
    })
  }
}
