<div *ngIf="v2">
  <mat-tab-group (focusChange)="changeCurrentKit($event)">
    <mat-tab style="height: 100%" [label]="tab.name" *ngFor="let tab of tabs">
      <ng-template matTabContent>
        <div class="mt-4 tab-body">
          <div *ngIf="tab.name === 'Panneaux solaires'">
            <h4>Champ photovoltaïque</h4>
            <div class="row">
              <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
                <label [class.label-error]="!currentKit.marque_type"
                  >Marque et type</label
                >
                <input
                  class="custom-input"
                  [class.input-error]="!currentKit.marque_type"
                  [(ngModel)]="currentKit.marque_type"
                  type="text"
                  (change)="
                    addOption(
                      tab.name,
                      'edité',
                      'marque_type',
                      'PV',
                      currentKit.marque_type
                    )
                  "
                />
              </div>

              <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
                <div class="button-wrapper mt-1">
                  <label [class.label-error]="!n_serie_pv">N° de série</label>
                  <label
                    class="upload-label"
                    for="n_serie_pv"
                    *ngIf="!n_serie_pv"
                    >+ Ajouter la photo</label
                  >
                  <p class="mb-0">
                    <a
                      target="blank"
                      class="check"
                      *ngIf="n_serie_pv"
                      [routerLink]="['/detail-piece', piece_serie_pv[0]._id]"
                      >Photos n° de série</a
                    >
                  </p>
                  <p
                    *ngIf="n_serie_pv"
                    style="
                      color: lightgrey;
                      font-weight: 300;
                      margin-top: 0;
                      font-size: small;
                    "
                  >
                    Prise le
                    {{ piece_serie_pv[0].created_at | date : "medium" }}
                  </p>

                  <input
                    *ngIf="!n_serie_pv"
                    accept=".jpg,.jpeg,.png"
                    type="file"
                    multiple
                    name="n_serie_pv"
                    id="n_serie_pv"
                    class="upload-box"
                    (change)="
                      selectMultiple(
                        $event,
                        'N° de serie panneaux solaires',
                        'n_serie_pv'
                      )
                    "
                  />
                </div>
              </div>

              <div class="col-lg-3 col-s-6 col-xs-6 title-margin">
                <label [class.label-error]="!currentKit.nombre">Nombre</label>
                <div class="increment" [class.input-error]="!currentKit.nombre">
                  <button
                    class="rm"
                    mat-button
                    mat-icon-button
                    matPrefix
                    aria-label="Remove"
                    (click)="
                      incrementValuePv(
                        -_step,
                        'nombre',
                        tab.name,
                        'edité',
                        'nombre',
                        'nombre',
                        currentKit.nombre
                      )
                    "
                  >
                    <mat-icon>remove</mat-icon>
                  </button>
                  <input
                    type="number"
                    [(ngModel)]="currentKit.nombre"
                    (change)="
                      addOption(
                        tab.name,
                        'edité',
                        'nombre',
                        'nombre',
                        currentKit.nombre
                      )
                    "
                  />
                  <button
                    class="add"
                    mat-button
                    mat-icon-button
                    matSuffix
                    aria-label="Add"
                    (click)="
                      incrementValuePv(
                        _step,
                        'nombre',
                        tab.name,
                        'edité',
                        'nombre',
                        'nombre',
                        currentKit.nombre
                      )
                    "
                  >
                    <mat-icon>add</mat-icon>
                  </button>
                </div>
              </div>
            </div>

            <div class="row my-5">
              <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
                <label [class.label-error]="!currentKit.pose">Pose</label>
                <mat-form-field>
                  <mat-select
                    [(ngModel)]="currentKit.pose"
                    (selectionChange)="
                      addOption(
                        tab.name,
                        'edité',
                        'pose',
                        'pose',
                        currentKit.pose
                      )
                    "
                  >
                    <mat-option *ngFor="let pose of poses_pv" [value]="pose">
                      {{ pose }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
                <label [class.label-error]="!currentKit.orientation"
                  >Orientation</label
                >
                <mat-form-field>
                  <mat-select
                    [(ngModel)]="currentKit.orientation"
                    (selectionChange)="
                      addOption(
                        tab.name,
                        'edité',
                        'orientation',
                        'orientation',
                        currentKit.orientation
                      )
                    "
                  >
                    <mat-option
                      *ngFor="let orientation of orientations"
                      [value]="orientation"
                    >
                      {{ orientation }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
                <label [class.label-error]="!currentKit.declivite"
                  >Déclivité</label
                >
                <input
                  class="custom-input"
                  [class.input-error]="!currentKit.declivite"
                  [(ngModel)]="currentKit.declivite"
                  type="text"
                  (change)="
                    addOption(
                      tab.name,
                      'edité',
                      'declivite',
                      'PV',
                      currentKit.declivite
                    )
                  "
                />
              </div>
            </div>

            <h4>Type d'onduleur</h4>
            <div class="row">
              <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
                <label [class.label-error]="!currentKit.onduleur"
                  >Choix de l'onduleur</label
                >
                <mat-form-field>
                  <mat-select
                    [(ngModel)]="currentKit.onduleur"
                    (selectionChange)="
                      addOption(
                        tab.name,
                        'edité',
                        'onduleur',
                        'onduleur',
                        currentKit.onduleur
                      )
                    "
                  >
                    <mat-option *ngFor="let type of types" [value]="type">
                      {{ type }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
                <label [class.label-error]="!currentKit.marque_type_onduleur"
                  >Marque et type</label
                >
                <input
                  class="custom-input"
                  [class.input-error]="!currentKit.marque_type_onduleur"
                  [(ngModel)]="currentKit.marque_type_onduleur"
                  type="text"
                  (change)="
                    addOption(
                      tab.name,
                      'edité',
                      'marque_type_onduleur',
                      'PV',
                      currentKit.marque_type_onduleur
                    )
                  "
                />
              </div>

              <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
                <div class="button-wrapper mt-1">
                  <label [class.label-error]="!n_serie_onduleur"
                    >N° de série</label
                  >
                  <label
                    class="upload-label"
                    for="uploadOnduleur"
                    *ngIf="!n_serie_onduleur"
                    >+ Ajouter la photo</label
                  >
                  <p class="mb-0">
                    <a
                      target="blank"
                      class="check"
                      *ngIf="n_serie_onduleur"
                      [routerLink]="[
                        '/detail-piece',
                        piece_serie_onduleur[0]._id
                      ]"
                      >Photos n° de série</a
                    >
                  </p>
                  <p
                    *ngIf="n_serie_onduleur"
                    style="
                      color: lightgrey;
                      font-weight: 300;
                      margin-top: 0;
                      font-size: small;
                    "
                  >
                    Prise le
                    {{ piece_serie_onduleur[0].created_at | date : "medium" }}
                  </p>

                  <input
                    *ngIf="!n_serie_onduleur"
                    accept=".jpg,.jpeg,.png"
                    type="file"
                    multiple
                    name="uploadOnduleur"
                    id="uploadOnduleur"
                    class="upload-box"
                    (change)="
                      selectMultiple(
                        $event,
                        'N° de serie onduleurs',
                        'n_serie_onduleur'
                      )
                    "
                  />
                </div>
              </div>

              <div class="col-lg-3 col-s-6 col-xs-6 title-margin">
                <label [class.label-error]="!currentKit.nbr_onduleur"
                  >Nombre</label
                >
                <div
                  class="increment"
                  [class.input-error]="!currentKit.nbr_onduleur"
                >
                  <button
                    class="rm"
                    mat-button
                    mat-icon-button
                    matPrefix
                    aria-label="Remove"
                    (click)="
                      incrementValue(
                        -_step,
                        'nbr_onduleur',
                        tab.name,
                        'edité',
                        'nbr_onduleur',
                        'nbr_onduleur',
                        currentKit.nbr_onduleur
                      )
                    "
                  >
                    <mat-icon>remove</mat-icon>
                  </button>
                  <input
                    type="number"
                    [(ngModel)]="currentKit.nbr_onduleur"
                    (change)="
                      addOption(
                        tab.name,
                        'edité',
                        'nbr_onduleur',
                        'nbr_onduleur',
                        currentKit.nbr_onduleur
                      )
                    "
                  />
                  <button
                    class="add"
                    mat-button
                    mat-icon-button
                    matSuffix
                    aria-label="Add"
                    (click)="
                      incrementValue(
                        _step,
                        'nbr_onduleur',
                        tab.name,
                        'edité',
                        'nbr_onduleur',
                        'nbr_onduleur',
                        currentKit.nbr_onduleur
                      )
                    "
                  >
                    <mat-icon>add</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- VMC -->
          <div *ngIf="tab.name === 'VMC Double Flux'">
            <h4 class="mt-5">Caisson isolé</h4>
            <div class="row">
              <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
                <label [class.label-error]="!currentKit.marque_type"
                  >Marque et type</label
                >
                <input
                  class="custom-input"
                  [(ngModel)]="currentKit.marque_type"
                  [class.input-error]="!currentKit.marque_type"
                  type="text"
                  (change)="
                    addOption(
                      tab.name,
                      'edité',
                      'marque_type',
                      'PV',
                      currentKit.marque_type
                    )
                  "
                />
              </div>

              <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
                <div class="button-wrapper mt-1">
                  <label [class.label-error]="!n_serie_vmc">N° de série</label>
                  <label
                    class="upload-label"
                    for="n_serie_vmc"
                    *ngIf="!n_serie_vmc"
                    >+ Ajouter la photo</label
                  >
                  <p class="mb-0">
                    <a
                      target="blank"
                      class="check"
                      *ngIf="n_serie_vmc"
                      [routerLink]="['/detail-piece', piece_serie_vmc[0]._id]"
                      >Photos n° de série</a
                    >
                  </p>
                  <p
                    *ngIf="n_serie_vmc"
                    style="
                      color: lightgrey;
                      font-weight: 300;
                      margin-top: 0;
                      font-size: small;
                    "
                  >
                    Prise le
                    {{ piece_serie_vmc[0].created_at | date : "medium" }}
                  </p>

                  <input
                    *ngIf="!n_serie_vmc"
                    accept=".jpg,.jpeg,.png"
                    type="file"
                    multiple
                    name="n_serie_vmc"
                    id="n_serie_vmc"
                    class="upload-box"
                    (change)="
                      selectMultiple($event, 'N° de serie vmc', 'n_serie_vmc')
                    "
                  />
                </div>
              </div>

              <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
                <label [class.label-error]="!currentKit.fixation"
                  >Type de fixation</label
                >
                <mat-form-field>
                  <mat-select
                    [(ngModel)]="currentKit.fixation"
                    (selectionChange)="
                      addOption(
                        tab.name,
                        'edité',
                        'fixation',
                        'fixation',
                        currentKit.fixation
                      )
                    "
                  >
                    <mat-option
                      *ngFor="let fixation of fixations"
                      [value]="fixation"
                    >
                      {{ fixation }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
                <label [class.label-error]="!currentKit.local_pose"
                  >Local de pose</label
                >
                <input
                  [class.input-error]="!currentKit.local_pose"
                  class="custom-input"
                  [(ngModel)]="currentKit.local_pose"
                  type="text"
                  (change)="
                    addOption(
                      tab.name,
                      'edité',
                      'local_pose',
                      'local_pose',
                      currentKit.local_pose
                    )
                  "
                />
              </div>
            </div>

            <h4 class="title-margin">Bouches</h4>
            <div class="row">
              <div class="col-lg-3 col-s-6 col-xs-6">
                <label [class.label-error]="!currentKit.nbr_bouche_extraction"
                  >Nombre de bouche d'extraction</label
                >
                <div
                  class="increment"
                  [class.input-error]="!currentKit.nbr_bouche_extraction"
                >
                  <button
                    class="rm"
                    mat-button
                    mat-icon-button
                    matPrefix
                    aria-label="Remove"
                    (click)="
                      incrementValueBouchesExtractionVmc(
                        -_step,
                        'nbr_bouche_extraction',
                        tab.name,
                        'edité',
                        'nbr_bouche_extraction',
                        'nbr_bouche_extraction',
                        currentKit.nbr_bouche_extraction
                      )
                    "
                  >
                    <mat-icon>remove</mat-icon>
                  </button>
                  <input
                    type="number"
                    [(ngModel)]="currentKit.nbr_bouche_extraction"
                    (change)="
                      addOption(
                        tab.name,
                        'edité',
                        'nbr_bouche_extraction',
                        'nbr_bouche_extraction',
                        currentKit.nbr_bouche_extraction
                      )
                    "
                  />
                  <button
                    class="add"
                    mat-button
                    mat-icon-button
                    matSuffix
                    aria-label="Add"
                    (click)="
                      incrementValueBouchesExtractionVmc(
                        _step,
                        'nbr_bouche_extraction',
                        tab.name,
                        'edité',
                        'nbr_bouche_extraction',
                        'nbr_bouche_extraction',
                        currentKit.nbr_bouche_extraction
                      )
                    "
                  >
                    <mat-icon>add</mat-icon>
                  </button>
                </div>
              </div>

              <div class="col-lg-3 col-s-6 col-xs-6 title-margin">
                <label [class.label-error]="!currentKit.nbr_bouche_soufflage"
                  >Nombre de bouche de soufflage</label
                >
                <div
                  class="increment"
                  [class.input-error]="!currentKit.nbr_bouche_soufflage"
                >
                  <button
                    class="rm"
                    mat-button
                    mat-icon-button
                    matPrefix
                    aria-label="Remove"
                    (click)="
                      incrementValueBouchesSoufflageVmc(
                        -_step,
                        'nbr_bouche_soufflage',
                        tab.name,
                        'edité',
                        'nbr_bouche_soufflage',
                        'nbr_bouche_soufflage',
                        currentKit.nbr_bouche_soufflage
                      )
                    "
                  >
                    <mat-icon>remove</mat-icon>
                  </button>
                  <input
                    type="number"
                    [(ngModel)]="currentKit.nbr_bouche_soufflage"
                    (change)="
                      addOption(
                        tab.name,
                        'edité',
                        'nbr_bouche_soufflage',
                        'nbr_bouche_soufflage',
                        currentKit.nbr_bouche_soufflage
                      )
                    "
                  />
                  <button
                    class="add"
                    mat-button
                    mat-icon-button
                    matSuffix
                    aria-label="Add"
                    (click)="
                      incrementValueBouchesSoufflageVmc(
                        _step,
                        'nbr_bouche_soufflage',
                        tab.name,
                        'edité',
                        'nbr_bouche_soufflage',
                        'nbr_bouche_soufflage',
                        currentKit.nbr_bouche_soufflage
                      )
                    "
                  >
                    <mat-icon>add</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!--BT -->
          <div *ngIf="tab.name === 'Ballon thermodynamique'">
            <!--               <button (click)="setTypeBtEmpty()">SET this.currentKit.type_bt vide</button>
 -->
            <div
              *ngIf="currentKit.type_bt === undefined; then empty; else valid"
            ></div>
            <ng-template #empty>
              <h1>aucun type de bt sélectionné</h1>
            </ng-template>

            <ng-template #valid>
              <h4 class="mt-5">
                Ballon thermodynamique {{ currentKit.type_bt }}
              </h4>
              <div class="row">
                <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
                  <label [class.label-error]="!currentKit.marque_type"
                    >Marque et type</label
                  >
                  <input
                    [class.input-error]="!currentKit.marque_type"
                    class="custom-input"
                    [(ngModel)]="currentKit.marque_type"
                    type="text"
                    (change)="
                      addOption(
                        tab.name,
                        'edité',
                        'marque_type',
                        'PV',
                        currentKit.marque_type
                      )
                    "
                  />
                </div>

                <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
                  <div class="button-wrapper mt-1">
                    <label [class.label-error]="!n_serie_bt">N° de série</label>
                    <label class="upload-label" for="upload" *ngIf="!n_serie_bt"
                      >+ Ajouter la photo</label
                    >
                    <p class="mb-0">
                      <a
                        target="blank"
                        class="check"
                        *ngIf="n_serie_bt"
                        [routerLink]="['/detail-piece', piece_serie_bt[0]._id]"
                        >Photos n° de série</a
                      >
                    </p>
                    <p
                      *ngIf="n_serie_bt"
                      style="
                        color: lightgrey;
                        font-weight: 300;
                        margin-top: 0;
                        font-size: small;
                      "
                    >
                      Prise le
                      {{ piece_serie_bt[0].created_at | date : "medium" }}
                    </p>

                    <input
                      *ngIf="!n_serie_bt"
                      accept=".jpg,.jpeg,.png"
                      type="file"
                      multiple
                      name="upload"
                      id="upload"
                      class="upload-box"
                      (change)="
                        selectMultiple(
                          $event,
                          'N° de serie ballon thermodynamique',
                          'n_serie_bt'
                        )
                      "
                    />
                  </div>
                </div>

                <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
                  <label [class.label-error]="!currentKit.capacite"
                    >Volume en litre</label
                  >
                  <mat-form-field>
                    <mat-select
                      [(ngModel)]="currentKit.capacite"
                      (selectionChange)="
                        addOption(
                          tab.name,
                          'edité',
                          'capacite',
                          'capacite',
                          currentKit.capacite
                        )
                      "
                    >
                      <mat-option
                        *ngFor="let capacity of capacities"
                        [value]="capacity"
                      >
                        {{ capacity }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
                  <label [class.label-error]="!currentKit.local_pose"
                    >Local de pose</label
                  >
                  <input
                    class="custom-input"
                    [(ngModel)]="currentKit.local_pose"
                    type="text"
                    (change)="
                      addOption(
                        tab.name,
                        'edité',
                        'local_pose',
                        'local_pose',
                        currentKit.local_pose
                      )
                    "
                  />
                </div>

                <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
                  <label [class.label-error]="!currentKit.hors_gel"
                    >Pièce hors gel</label
                  >
                  <mat-radio-group
                    aria-label="Select an option"
                    [(ngModel)]="currentKit.hors_gel"
                    (change)="
                      addOption(
                        tab.name,
                        'edité',
                        'hors_gel',
                        'hors_gel',
                        currentKit.hors_gel
                      )
                    "
                  >
                    <mat-radio-button value="oui">Oui</mat-radio-button>
                    <mat-radio-button value="non" class="ml-4"
                      >Non</mat-radio-button
                    >
                  </mat-radio-group>
                </div>
              </div>

              <h4 *ngIf="currentKit.type_bt === 'split'" class="mt-5">
                Unité extérieure
              </h4>
              <div class="row" *ngIf="currentKit.type_bt === 'split'">
                <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
                  <div class="button-wrapper mt-1">
                    <label [class.label-error]="!n_serie_ue_bt"
                      >N° de série</label
                    >
                    <label
                      class="upload-label"
                      for="upload"
                      *ngIf="!n_serie_ue_bt"
                      >+ Ajouter la photo</label
                    >
                    <p class="mb-0">
                      <a
                        target="blank"
                        class="check"
                        *ngIf="n_serie_ue_bt"
                        [routerLink]="[
                          '/detail-piece',
                          piece_serie_ue_bt[0]._id
                        ]"
                        >Photos n° de série</a
                      >
                    </p>
                    <p
                      *ngIf="n_serie_ue_bt"
                      style="
                        color: lightgrey;
                        font-weight: 300;
                        margin-top: 0;
                        font-size: small;
                      "
                    >
                      Prise le
                      {{ piece_serie_ue_bt[0].created_at | date : "medium" }}
                    </p>

                    <input
                      *ngIf="!n_serie_ue_bt"
                      accept=".jpg,.jpeg,.png"
                      type="file"
                      multiple
                      name="upload"
                      id="upload"
                      class="upload-box"
                      (change)="
                        selectMultiple(
                          $event,
                          'N° de serie UE ballon thermodynamique',
                          'n_serie_ue_bt'
                        )
                      "
                    />
                  </div>
                </div>

                <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
                  <label [class.label-error]="!currentKit.type_pose_ue"
                    >Pose</label
                  >
                  <mat-form-field>
                    <mat-select
                      [(ngModel)]="currentKit.type_pose_ue"
                      (selectionChange)="
                        addOption(
                          tab.name,
                          'edité',
                          'type_pose_ue',
                          'type_pose_ue',
                          currentKit.type_pose_ue
                        )
                      "
                    >
                      <mat-option *ngFor="let pose of poses" [value]="pose">
                        {{ pose }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </ng-template>
          </div>

          <!-- Adoucisseur d'eau -->

          <div *ngIf="tab.name === 'Adoucisseur d\'eau'">
            <h4 class="mt-5">Adoucisseur d'eau</h4>
            <div class="row">
              <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
                <label [class.label-error]="!currentKit.marque_type"
                  >Marque et type</label
                >
                <input
                  class="custom-input"
                  [(ngModel)]="currentKit.marque_type"
                  type="text"
                  (change)="
                    addOption(
                      tab.name,
                      'edité',
                      'marque_type',
                      'PV',
                      currentKit.marque_type
                    )
                  "
                />
              </div>

              <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
                <div class="button-wrapper mt-1">
                  <label [class.label-error]="!n_serie_adoucisseur"
                    >N° de série</label
                  >
                  <label
                    class="upload-label"
                    for="n_serie_adoucisseur"
                    *ngIf="!n_serie_adoucisseur"
                    >+ Ajouter la photo</label
                  >
                  <p class="mb-0">
                    <a
                      target="blank"
                      class="check"
                      *ngIf="n_serie_adoucisseur"
                      [routerLink]="[
                        '/detail-piece',
                        piece_serie_adoucisseur[0]._id
                      ]"
                      >Photos n° de série</a
                    >
                  </p>
                  <p
                    *ngIf="n_serie_adoucisseur"
                    style="
                      color: lightgrey;
                      font-weight: 300;
                      margin-top: 0;
                      font-size: small;
                    "
                  >
                    Prise le
                    {{
                      piece_serie_adoucisseur[0].created_at | date : "medium"
                    }}
                  </p>

                  <input
                    *ngIf="!n_serie_adoucisseur"
                    accept=".jpg,.jpeg,.png"
                    type="file"
                    multiple
                    name="n_serie_adoucisseur"
                    id="n_serie_adoucisseur"
                    class="upload-box"
                    (change)="
                      selectMultiple(
                        $event,
                        'N° de serie adoucisseur',
                        'n_serie_adoucisseur'
                      )
                    "
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Batterie -->

          <div *ngIf="tab.name === 'Batterie'"></div>

          <!-- Mise aux normes du tableau électrique -->

          <div
            *ngIf="tab.name === 'Mise aux normes du tableau électrique'"
          ></div>

          <!-- PAC A/E -->
          <div *ngIf="tab.name === 'PAC Air/Eau'">
            <h4 class="mt-5">Unité exterieure</h4>

            <div class="row">
              <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
                <label [class.label-error]="!currentKit.marque_type_ue"
                  >Marque et type</label
                >
                <input
                  [class.input-error]="!currentKit.marque_type_ue"
                  class="custom-input"
                  [(ngModel)]="currentKit.marque_type_ue"
                  type="text"
                  (change)="
                    addOption(
                      tab.name,
                      'edité',
                      'marque_type_ue',
                      'PV',
                      currentKit.marque_type_ue
                    )
                  "
                />
              </div>

              <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
                <div class="button-wrapper mt-1">
                  <label [class.label-error]="!n_serie_pacaa_ue"
                    >N° de série</label
                  >
                  <label
                    class="upload-label"
                    for="n_serie_pacae_ue"
                    *ngIf="!n_serie_pacae_ue"
                    >+ Ajouter la photo</label
                  >
                  <p class="mb-0">
                    <a
                      target="blank"
                      class="check"
                      *ngIf="n_serie_pacae_ue"
                      [routerLink]="[
                        '/detail-piece',
                        piece_serie_pacae_ue[0]._id
                      ]"
                      >Photos n° de série</a
                    >
                  </p>
                  <p
                    *ngIf="n_serie_pacae_ue"
                    style="
                      color: lightgrey;
                      font-weight: 300;
                      margin-top: 0;
                      font-size: small;
                    "
                  >
                    Prise le
                    {{ piece_serie_pacae_ue[0].created_at | date : "medium" }}
                  </p>

                  <input
                    *ngIf="!n_serie_pacae_ue"
                    accept=".jpg,.jpeg,.png"
                    type="file"
                    multiple
                    name="n_serie_pacae_ue"
                    id="n_serie_pacae_ue"
                    class="upload-box"
                    (change)="
                      selectMultiple(
                        $event,
                        'N° de serie UE PAC Air Eau',
                        'n_serie_pacae_ue'
                      )
                    "
                  />
                </div>
              </div>

              <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
                <label [class.label-error]="!currentKit.pose">Pose</label>
                <mat-form-field>
                  <mat-select
                    [(ngModel)]="currentKit.pose"
                    (selectionChange)="
                      addOption(
                        tab.name,
                        'edité',
                        'pose',
                        'pose',
                        currentKit.pose
                      )
                    "
                  >
                    <mat-option *ngFor="let pose of poses" [value]="pose">
                      {{ pose }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <h4 class="mt-5">Unité intérieure</h4>
            <div class="row">
              <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
                <label [class.label-error]="!currentKit.marque_type_ui"
                  >Marque et type</label
                >
                <input
                  class="custom-input"
                  [class.input-error]="!currentKit.marque_type_ui"
                  [(ngModel)]="currentKit.marque_type_ui"
                  type="text"
                  (change)="
                    addOption(
                      tab.name,
                      'edité',
                      'marque_type_ui',
                      'marque_type_ui',
                      currentKit.marque_type_ui
                    )
                  "
                />
              </div>

              <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
                <div class="button-wrapper mt-1">
                  <label [class.label-error]="!n_serie_pacae_ui"
                    >N° de série</label
                  >
                  <label
                    class="upload-label"
                    for="n_serie_pacae_ui"
                    *ngIf="!n_serie_pacae_ui"
                    >+ Ajouter la photo</label
                  >
                  <p class="mb-0">
                    <a
                      target="blank"
                      class="check"
                      *ngIf="n_serie_pacae_ui"
                      [routerLink]="[
                        '/detail-piece',
                        piece_serie_pacae_ui[0]._id
                      ]"
                      >Photos n° de série</a
                    >
                  </p>
                  <p
                    *ngIf="n_serie_pacae_ui"
                    style="
                      color: lightgrey;
                      font-weight: 300;
                      margin-top: 0;
                      font-size: small;
                    "
                  >
                    Prise le
                    {{ piece_serie_pacae_ui[0].created_at | date : "medium" }}
                  </p>

                  <input
                    *ngIf="!n_serie_pacae_ui"
                    accept=".jpg,.jpeg,.png"
                    type="file"
                    multiple
                    name="n_serie_pacae_ui"
                    id="n_serie_pacae_ui"
                    class="upload-box"
                    (change)="
                      selectMultiple(
                        $event,
                        'N° de serie UI PAC Air Eau',
                        'n_serie_pacae_ui'
                      )
                    "
                  />
                </div>
              </div>

              <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
                <label [class.label-error]="!currentKit.local_pose"
                  >Désignation local de pose</label
                >
                <input
                  [class.input-error]="!currentKit.local_pose"
                  class="custom-input"
                  [(ngModel)]="currentKit.local_pose"
                  type="text"
                  (change)="
                    addOption(
                      tab.name,
                      'edité',
                      'local_pose',
                      'local_pose',
                      currentKit.local_pose
                    )
                  "
                />
              </div>
            </div>
          </div>

          <!-- PAC A/A -->
          <div *ngIf="tab.name === 'PAC Air/Air'">
            <div
              *ngIf="
                currentKit.type_pac === undefined;
                then empty_pac;
                else valid_pac
              "
            ></div>
            <ng-template #empty_pac>
              <h1>aucun type de pac air sélectionné</h1>
            </ng-template>

            <ng-template #valid_pac>
              <h2 class="mt-5">PAC Air/Air {{ currentKit.type_pac }}</h2>

              <div *ngIf="currentKit.type_pac === 'Gainable'">
                <h4 class="mt-5">Unité extérieure</h4>
                <div class="row">
                  <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
                    <label [class.label-error]="!currentKit.marque_type_ue"
                      >Marque et type</label
                    >
                    <input
                      class="custom-input"
                      [class.input-error]="!currentKit.marque_type_ue"
                      [(ngModel)]="currentKit.marque_type_ue"
                      type="text"
                      (change)="
                        addOption(
                          tab.name,
                          'edité',
                          'marque_type_ue',
                          'PV',
                          currentKit.marque_type_ue
                        )
                      "
                    />
                  </div>

                  <div
                    class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin"
                  >
                    <div class="button-wrapper mt-1">
                      <label [class.label-error]="!n_serie_pacaa_ue"
                        >N° de série</label
                      >
                      <label
                        class="upload-label"
                        for="n_serie_pacaa_ue"
                        *ngIf="!n_serie_pacaa_ue"
                        >+ Ajouter la photo</label
                      >
                      <p class="mb-0">
                        <a
                          target="blank"
                          class="check"
                          *ngIf="n_serie_pacaa_ue"
                          [routerLink]="[
                            '/detail-piece',
                            piece_serie_pacaa_ue[0]._id
                          ]"
                          >Photos n° de série</a
                        >
                      </p>
                      <p
                        *ngIf="n_serie_pacaa_ue"
                        style="
                          color: lightgrey;
                          font-weight: 300;
                          margin-top: 0;
                          font-size: small;
                        "
                      >
                        Prise le
                        {{
                          piece_serie_pacaa_ue[0].created_at | date : "medium"
                        }}
                      </p>

                      <input
                        *ngIf="!n_serie_pacaa_ue"
                        accept=".jpg,.jpeg,.png"
                        type="file"
                        multiple
                        name="n_serie_pacaa_ue"
                        id="n_serie_pacaa_ue"
                        class="upload-box"
                        (change)="
                          selectMultiple(
                            $event,
                            'N° de serie UE PAC Air Air',
                            'n_serie_pacaa_ue'
                          )
                        "
                      />
                    </div>
                  </div>

                  <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
                    <label [class.label-error]="!currentKit.type_pose_ue"
                      >Pose</label
                    >
                    <mat-form-field>
                      <mat-select
                        [(ngModel)]="currentKit.type_pose_ue"
                        (selectionChange)="
                          addOption(
                            tab.name,
                            'edité',
                            'type_pose_ue',
                            'type_pose_ue',
                            currentKit.type_pose_ue
                          )
                        "
                      >
                        <mat-option *ngFor="let pose of poses" [value]="pose">
                          {{ pose }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <h4 class="mt-5">Unité intérieure</h4>
                <div class="row">
                  <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
                    <label [class.label-error]="!currentKit.marque_type_ui"
                      >Marque et type</label
                    >
                    <input
                      class="custom-input"
                      [class.input-error]="!currentKit.marque_type_ui"
                      [(ngModel)]="currentKit.marque_type_ui"
                      type="text"
                      (change)="
                        addOption(
                          tab.name,
                          'edité',
                          'marque_type_ui',
                          'marque_type_ui',
                          currentKit.marque_type_ui
                        )
                      "
                    />
                  </div>

                  <div
                    class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin"
                  >
                    <div class="button-wrapper mt-1">
                      <label [class.label-error]="!n_serie_pacaa_ui"
                        >N° de série</label
                      >
                      <label
                        class="upload-label"
                        for="n_serie_pacaa_ui"
                        *ngIf="!n_serie_pacaa_ui"
                        >+ Ajouter la photo</label
                      >
                      <p class="mb-0">
                        <a
                          target="blank"
                          class="check"
                          *ngIf="n_serie_pacaa_ui"
                          [routerLink]="[
                            '/detail-piece',
                            piece_serie_pacaa_ui[0]._id
                          ]"
                          >Photos n° de série</a
                        >
                      </p>
                      <p
                        *ngIf="n_serie_pacaa_ui"
                        style="
                          color: lightgrey;
                          font-weight: 300;
                          margin-top: 0;
                          font-size: small;
                        "
                      >
                        Prise le
                        {{
                          piece_serie_pacaa_ui[0].created_at | date : "medium"
                        }}
                      </p>

                      <input
                        *ngIf="!n_serie_pacaa_ui"
                        accept=".jpg,.jpeg,.png"
                        type="file"
                        multiple
                        name="n_serie_pacaa_ui"
                        id="n_serie_pacaa_ui"
                        class="upload-box"
                        (change)="
                          selectMultiple(
                            $event,
                            'N° de serie UI PAC Air Air',
                            'n_serie_pacaa_ui'
                          )
                        "
                      />
                    </div>
                  </div>
                </div>

                <h4 class="mt-5">Bouches</h4>
                <div class="row">
                  <div class="col-lg-3 col-s-6 col-xs-6">
                    <label
                      [class.label-error]="!currentKit.nbr_bouche_insuflation"
                      >Nombre de bouche d'insuflation</label
                    >
                    <div
                      class="increment"
                      [class.input-error]="!currentKit.nbr_bouche_insuflation"
                    >
                      <button
                        class="rm"
                        mat-button
                        mat-icon-button
                        matPrefix
                        aria-label="Remove"
                        (click)="
                          incrementBoucheInsuflation(
                            -_step,
                            'nbr_bouche_insuflation',
                            tab.name,
                            'edité',
                            'nbr_bouche_insuflation',
                            'nbr_bouche_insuflation',
                            currentKit.nbr_bouche_insuflation
                          )
                        "
                      >
                        <mat-icon>remove</mat-icon>
                      </button>
                      <input
                        type="number"
                        [(ngModel)]="currentKit.nbr_bouche_insuflation"
                        (change)="
                          addOption(
                            tab.name,
                            'edité',
                            'nbr_bouche_insuflation',
                            'nbr_bouche_insuflation',
                            currentKit.nbr_bouche_insuflation
                          )
                        "
                      />
                      <button
                        class="add"
                        mat-button
                        mat-icon-button
                        matSuffix
                        aria-label="Add"
                        (click)="
                          incrementBoucheInsuflation(
                            _step,
                            'nbr_bouche_insuflation',
                            tab.name,
                            'edité',
                            'nbr_bouche_insuflation',
                            'nbr_bouche_insuflation',
                            currentKit.nbr_bouche_insuflation
                          )
                        "
                      >
                        <mat-icon>add</mat-icon>
                      </button>
                    </div>
                  </div>

                  <div class="col-lg-3 col-s-6 col-xs-6">
                    <label [class.label-error]="!currentKit.nbr_bouche_reprise"
                      >Nombre de bouche de reprise</label
                    >
                    <div
                      class="increment"
                      [class.input-error]="!currentKit.nbr_bouche_reprise"
                    >
                      <button
                        class="rm"
                        mat-button
                        mat-icon-button
                        matPrefix
                        aria-label="Remove"
                        (click)="
                          incrementBoucheReprise(
                            -_step,
                            'nbr_bouche_reprise',
                            tab.name,
                            'edité',
                            'nbr_bouche_reprise',
                            'nbr_bouche_reprise',
                            currentKit.nbr_bouche_reprise
                          )
                        "
                      >
                        <mat-icon>remove</mat-icon>
                      </button>
                      <input
                        type="number"
                        [(ngModel)]="currentKit.nbr_bouche_reprise"
                        (change)="
                          addOption(
                            tab.name,
                            'edité',
                            'nbr_bouche_reprise',
                            'nbr_bouche_reprise',
                            currentKit.nbr_bouche_reprise
                          )
                        "
                      />
                      <button
                        class="add"
                        mat-button
                        mat-icon-button
                        matSuffix
                        aria-label="Add"
                        (click)="
                          incrementBoucheReprise(
                            _step,
                            'nbr_bouche_reprise',
                            tab.name,
                            'edité',
                            'nbr_bouche_reprise',
                            'nbr_bouche_reprise',
                            currentKit.nbr_bouche_reprise
                          )
                        "
                      >
                        <mat-icon>add</mat-icon>
                      </button>
                    </div>
                  </div>

                  <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
                    <label [class.label-error]="!currentKit.local_pose_ui"
                      >Désignation local de pose</label
                    >
                    <input
                      class="custom-input"
                      [class.input-error]="!currentKit.local_pose_ui"
                      [(ngModel)]="currentKit.local_pose_ui"
                      type="text"
                      (change)="
                        addOption(
                          tab.name,
                          'edité',
                          'local_pose_ui',
                          'local_pose_ui',
                          currentKit.local_pose_ui
                        )
                      "
                    />
                  </div>
                </div>
              </div>

              <div *ngIf="currentKit.type_pac === 'Split'">
                <h4 class="mt-5">Unité extérieure</h4>
                <div class="row">
                  <div
                    class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin"
                  >
                    <label [class.label-error]="!currentKit.marque_type_ue"
                      >Marque et type</label
                    >
                    <input
                      class="custom-input"
                      [class.input-error]="!currentKit.marque_type_ue"
                      [(ngModel)]="currentKit.marque_type_ue"
                      type="text"
                      (change)="
                        addOption(
                          tab.name,
                          'edité',
                          'marque_type_ue',
                          'PV',
                          currentKit.marque_type_ue
                        )
                      "
                    />
                  </div>

                  <div
                    class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin"
                  >
                    <div class="button-wrapper mt-1">
                      <label [class.label-error]="!n_serie_pacaa_ue"
                        >N° de série</label
                      >
                      <label
                        class="upload-label"
                        for="n_serie_pacaa_ue"
                        *ngIf="!n_serie_pacaa_ue"
                        >+ Ajouter la photo</label
                      >
                      <p class="mb-0">
                        <a
                          target="blank"
                          class="check"
                          *ngIf="n_serie_pacaa_ue"
                          [routerLink]="[
                            '/detail-piece',
                            piece_serie_pacaa_ue[0]._id
                          ]"
                          >Photos n° de série</a
                        >
                      </p>
                      <p
                        *ngIf="n_serie_pacaa_ue"
                        style="
                          color: lightgrey;
                          font-weight: 300;
                          margin-top: 0;
                          font-size: small;
                        "
                      >
                        Prise le
                        {{
                          piece_serie_pacaa_ue[0].created_at | date : "medium"
                        }}
                      </p>

                      <input
                        *ngIf="!n_serie_pacaa_ue"
                        accept=".jpg,.jpeg,.png"
                        type="file"
                        multiple
                        name="n_serie_pacaa_ue"
                        id="n_serie_pacaa_ue"
                        class="upload-box"
                        (change)="
                          selectMultiple(
                            $event,
                            'N° de serie UE PAC Air Air',
                            'n_serie_pacaa_ue'
                          )
                        "
                      />
                    </div>
                  </div>
                  <div
                    class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin"
                  >
                    <label [class.label-error]="!currentKit.type_pose_ue"
                      >Pose</label
                    >
                    <mat-form-field>
                      <mat-select
                        [(ngModel)]="currentKit.type_pose_ue"
                        (selectionChange)="
                          addOption(
                            tab.name,
                            'edité',
                            'type_pose_ue',
                            'type_pose_ue',
                            currentKit.type_pose_ue
                          )
                        "
                      >
                        <mat-option *ngFor="let pose of poses" [value]="pose">
                          {{ pose }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <h4 class="mt-5">Unité intérieure</h4>
                <div class="row">
                  <div
                    class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin"
                  >
                    <label [class.label-error]="!currentKit.marque_type_ui"
                      >Marque et type</label
                    >
                    <input
                      class="custom-input"
                      [class.input-error]="!currentKit.marque_type_ui"
                      [(ngModel)]="currentKit.marque_type_ui"
                      type="text"
                      (change)="
                        addOption(
                          tab.name,
                          'edité',
                          'marque_type_ui',
                          'marque_type_ui',
                          currentKit.marque_type_ui
                        )
                      "
                    />
                  </div>

                  <div
                    class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin"
                  >
                    <div class="button-wrapper mt-1">
                      <label>N° de série</label>
                      <label
                        class="upload-label"
                        for="n_serie_pacaa_ui"
                        *ngIf="!n_serie_pacaa_ui"
                        >+ Ajouter la photo</label
                      >
                      <p class="mb-0">
                        <a
                          target="blank"
                          class="check"
                          *ngIf="n_serie_pacaa_ui"
                          [routerLink]="[
                            '/detail-piece',
                            piece_serie_pacaa_ui[0]._id
                          ]"
                          >Photos n° de série</a
                        >
                      </p>
                      <p
                        *ngIf="n_serie_pacaa_ui"
                        style="
                          color: lightgrey;
                          font-weight: 300;
                          margin-top: 0;
                          font-size: small;
                        "
                      >
                        Prise le
                        {{
                          piece_serie_pacaa_ui[0].created_at | date : "medium"
                        }}
                      </p>

                      <input
                        *ngIf="!n_serie_pacaa_ui"
                        accept=".jpg,.jpeg,.png"
                        type="file"
                        multiple
                        name="n_serie_pacaa_ui"
                        id="n_serie_pacaa_ui"
                        class="upload-box"
                        (change)="
                          selectMultiple(
                            $event,
                            'N° de serie UI PAC Air Air',
                            'n_serie_pacaa_ui'
                          )
                        "
                      />
                    </div>
                  </div>

                  <div class="col-lg-3 col-s-6 col-xs-6 title-margin">
                    <label [class.label-error]="!currentKit.nbr_split"
                      >Nombre</label
                    >
                    <div
                      class="increment"
                      [class.input-error]="!currentKit.nbr_split"
                    >
                      <button
                        class="rm"
                        mat-button
                        mat-icon-button
                        matPrefix
                        aria-label="Remove"
                        (click)="
                          incrementSplitUiPac(
                            -_step,
                            'nbr_split',
                            tab.name,
                            'edité',
                            'nbr_split',
                            'nbr_split',
                            currentKit.nbr_split
                          )
                        "
                      >
                        <mat-icon>remove</mat-icon>
                      </button>
                      <input
                        type="number"
                        [(ngModel)]="currentKit.nbr_split"
                        (change)="
                          addOption(
                            tab.name,
                            'edité',
                            'nbr_split',
                            'nbr_split',
                            currentKit.nbr_split
                          )
                        "
                      />
                      <button
                        class="add"
                        mat-button
                        mat-icon-button
                        matSuffix
                        aria-label="Add"
                        (click)="
                          incrementSplitUiPac(
                            _step,
                            'nbr_split',
                            tab.name,
                            'edité',
                            'nbr_split',
                            'nbr_split',
                            currentKit.nbr_split
                          )
                        "
                      >
                        <mat-icon>add</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>

          <!-- Isolation -->

          <div *ngIf="tab.name === 'Isolation'"></div>

          <div *ngIf="tab.state === 'autre' && tab.name === 'PAC Air/air'">
            <!-- PAC A/A -->
            <div
              *ngIf="
                currentKit.type_pac === undefined;
                then empty_pac;
                else valid_pac
              "
            ></div>
            <ng-template #empty_pac>
              <h1>aucun type de pac air sélectionné</h1>
              <button>Split</button>
              <button>Gainable</button>
            </ng-template>

            <ng-template #valid_pac>
              <h2 class="mt-5">PAC Air/Air {{ currentKit.type_pac }}</h2>

              <div *ngIf="currentKit.type_pac === 'Gainable'">
                <h4 class="mt-5">Unité intérieure</h4>
                <div class="row">
                  <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
                    <label [class.label-error]="!currentKit.marque_type"
                      >Marque et type</label
                    >
                    <input
                      class="custom-input"
                      [class.input-error]="!currentKit.marque_type"
                      [(ngModel)]="currentKit.marque_type"
                      type="text"
                      (change)="
                        addOption(
                          tab.name,
                          'edité',
                          'marque_type',
                          'PV',
                          currentKit.marque_type
                        )
                      "
                    />
                  </div>

                  <div
                    class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin"
                  >
                    <label>N° de série</label>
                    <a>+ Ajouter une photo</a>
                  </div>

                  <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
                    <label [class.label-error]="!currentKit.marque_type"
                      >Désignation local de pose</label
                    >
                    <input
                      class="custom-input"
                      [class.input-error]="!currentKit.marque_type"
                      [(ngModel)]="currentKit.marque_type"
                      type="text"
                      (change)="
                        addOption(
                          tab.name,
                          'edité',
                          'marque_type',
                          'PV',
                          currentKit.marque_type
                        )
                      "
                    />
                  </div>
                </div>

                <h4 class="mt-5">Unité intérieure</h4>
                <div class="row">
                  <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
                    <label [class.label-error]="!currentKit.marque_type"
                      >Marque et type</label
                    >
                    <input
                      class="custom-input"
                      [class.input-error]="!currentKit.marque_type"
                      [(ngModel)]="currentKit.marque_type"
                      type="text"
                      (change)="
                        addOption(
                          tab.name,
                          'edité',
                          'marque_type',
                          'PV',
                          currentKit.marque_type
                        )
                      "
                    />
                  </div>

                  <div
                    class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin"
                  >
                    <label>N° de série</label>
                    <a>+ Ajouter une photo</a>
                  </div>
                </div>

                <h4 class="mt-5">Bouches</h4>
                <div class="row">
                  <div class="col-lg-3 col-s-6 col-xs-6">
                    <label>Nombre de bouche d'insuflation</label>
                    <div class="increment">
                      <button
                        class="rm"
                        mat-button
                        mat-icon-button
                        matPrefix
                        aria-label="Remove"
                        (click)="incrementValue(-_step)"
                      >
                        <mat-icon>remove</mat-icon>
                      </button>
                      <input type="number" [(ngModel)]="_value" />
                      <button
                        class="add"
                        mat-button
                        mat-icon-button
                        matSuffix
                        aria-label="Add"
                        (click)="incrementValue(_step)"
                      >
                        <mat-icon>add</mat-icon>
                      </button>
                    </div>
                  </div>

                  <div class="col-lg-3 col-s-6 col-xs-6">
                    <label>Nombre de bouche de reprise</label>
                    <div class="increment">
                      <button
                        class="rm"
                        mat-button
                        mat-icon-button
                        matPrefix
                        aria-label="Remove"
                        (click)="incrementValue(-_step)"
                      >
                        <mat-icon>remove</mat-icon>
                      </button>
                      <input type="number" [(ngModel)]="_value" />
                      <button
                        class="add"
                        mat-button
                        mat-icon-button
                        matSuffix
                        aria-label="Add"
                        (click)="incrementValue(_step)"
                      >
                        <mat-icon>add</mat-icon>
                      </button>
                    </div>
                  </div>

                  <div
                    class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin"
                  >
                    <label [class.label-error]="!currentKit.marque_type"
                      >Désignation local de pose</label
                    >
                    <input
                      class="custom-input"
                      [class.input-error]="!currentKit.marque_type"
                      [(ngModel)]="currentKit.marque_type"
                      type="text"
                      (change)="
                        addOption(
                          tab.name,
                          'edité',
                          'marque_type',
                          'PV',
                          currentKit.marque_type
                        )
                      "
                    />
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="jumbo">
  <button class="btn-secondarycrm" (click)="createDocBI()">
    + Créer un bon d'intervention
  </button>
  <button
    class="btn-secondarycrm"
    (click)="createDoc()"
    *ngIf="currentKitValid"
  >
    + Créer un nouveau document
  </button>
</div>
