import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProductService } from '@app/services/product.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})

export class AddProductComponent implements OnInit {

  addProductForm: FormGroup;
  isSubmitting: boolean = false;

  constructor(
    private fb: FormBuilder,
    private productService: ProductService,
    public dialogRef: MatDialogRef<AddProductComponent>
  ) {}

  ngOnInit(): void {
    this.addProductForm = this.fb.group({
      name: ['', Validators.required]

    });
  }

  onSubmit() {
    if (this.addProductForm.valid && !this.isSubmitting) {
      this.isSubmitting = true;
      this.productService.addProductSAV({ title: this.addProductForm.value.name })
      .subscribe(response => {
        this.productService.getProducts().subscribe(products => {
          this.productService.notifyProductsUpdate(products);
          this.dialogRef.close('added');
          this.isSubmitting = false;
        });
      }, error => {
        this.isSubmitting = false;
      });
    }
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
