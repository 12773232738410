<img *ngIf="mobile" class="notification" [routerLink]="['/infos-advs']" src="../../../assets/icons/notification.svg">

<p *ngIf="mobile" class="compteur">
  <span matBadge="{{ infosUnread.length }}" matBadgeSize="small"></span>
</p>

<img *ngIf="mobile" class="messagerie-icon" [routerLink]="['/messagerie']" src="../../../assets/bar-icons/messagerie.svg">

<p *ngIf="mobile" class="compteur-messagerie">
  <span *ngIf="countUnread" matBadge="{{ countUnread.count }}" matBadgeSize="small"></span>
</p>
<div class="task-container">
  <div class="container-fluid" *ngIf="currentUser">
    <button mat-button (click)="goBack()"><img src="../../../assets/icons/retour.svg">Retour</button>

    <div class="row gdd-row" [ngClass]="{'unroll': unroll, 'roll' : !unroll }" *ngIf="currentUser.role === 'Adv' || currentUser.role === 'Responsable ADV'">
      <div class="col-4 gdd-col">
        <div class=" gdd-title">GDD à faire <span><u>{{ countGddAfaire }}</u></span></div>
        <div class="row gdd-row-card" *ngFor="let task of gddAfaire | searchFilter: query">
          <div class="col-card">
            <app-card-gdd (childButtonEvent)="receivedTaskEdit($event)" [task]="task"></app-card-gdd>
          </div>
        </div>
      </div>

      <div class="col-4 gdd-col">
        <div class=" gdd-title">GDD en cours <span><u>{{ countGddEncours }}</u></span></div>

        <div class="row gdd-row-card" *ngFor="let task of gddEncours | searchFilter: query">
          <div class="col-card">
            <app-card-gdd (childButtonEvent)="receivedTaskEdit($event)" [task]="task"></app-card-gdd>
          </div>
        </div>
      </div>


      <div class="col-4 gdd-col">
        <div class=" gdd-title">GDD terminée <span><u>{{ countGddTermine }}</u></span></div>
          <div class="row gdd-row-card" *ngFor="let task of gddTermine | searchFilter: query">
            <div class="col-card">
              <app-card-gdd (childButtonEvent)="receivedTaskEdit($event)" [task]="task"></app-card-gdd>
            </div>
          </div>
      </div>

    </div>

    <div class="row row-button" *ngIf="currentUser.id === '5ff22a8ba0f2570004f151d0'">
      <div class="col-4 offset-4 button-add">
        <button (click)="createTaskGddDialog()" class="add-task-gdd"><mat-icon>add</mat-icon></button>
      </div>
    </div>

    <div *ngIf="currentUser.role === 'Adv' || currentUser.role === 'Responsable ADV'" (click)="unrollGdd()" class="voir-plus">Voir plus <img [ngClass]="{'reverse': unroll}" src="../../../assets/icons/arrow-select.svg"></div>



    <div class="d-flex row mt-5 ml-2">
      <div *ngIf='!mobile'>
        <mat-form-field class="recherche ml-2" style="width: 59%;">
          <input matInput #filter placeholder="Rechercher" [(ngModel)]="query" id="listSearch">
          <button mat-icon-button matSuffix>
            <mat-icon style="color: var(--tertiarycrm);">search</mat-icon>
          </button>
        </mat-form-field>
      </div>


      <div *ngIf="!mobile">
        <button *ngIf="suppreTask.length>0" class="btn-trash ml-4" (click)="supprTaskArray()">
          <img src="../../../assets/icons/trash.svg">
        </button>
      </div>

      <div *ngIf="!mobile" class="filter-tasks">
        <mat-form-field class="custom-field">
          <mat-select [(ngModel)]="selectedOption" (selectionChange)="checkTaskOfAnotherAdvs($event.value)">
            <mat-option *ngFor="let option of options" [value]="option.name">{{option.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="!mobile" style="margin-left: 4%;">
        <mat-form-field class="custom-field">
          <mat-select [(ngModel)]='filter.state'>
            <mat-option value='all'>Toutes</mat-option>
            <mat-option *ngFor="let state of states" [value]="state.name"
              [ngClass]="{'urgente': state.name === '1-Urgente', 'encours' : state.name === '2-En-cours', 'faite' : state.name === '3-Faite' }">
              {{state.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="!mobile" style="width: 13%;margin-left: 4%;">
        <button class="custom-field add-task" (click)="createTaskDialog()">+ Nouvelle tâche</button>
      </div>

      <div *ngIf="!mobile" class="filter-button">
        <mat-form-field class="custom-field add-task">

<!--           <mat-icon matPrefix style="position: absolute;
          bottom: -5px;
          left: 13px;">filter_list</mat-icon> -->
          <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg" style="position: absolute;
          bottom: 11px;
          left: 19px;">
            <path d="M7.16485 1.70505L12.2205 1.72034C12.6485 1.72034 13 1.3727 13 0.940715C13 0.509151 12.6524 0.161232 12.2205 0.161232H7.1725L5.61353 0.16137H0.779483C0.347641 0.16137 0 0.509012 0 0.940715C0 1.37256 0.347641 1.7202 0.779483 1.7202H5.62117L7.16485 1.70505Z" fill="white"/>
            <path d="M6.49236 6.26424L8.22052 6.27966C8.64847 6.27966 9 5.93202 9 5.50004C9 5.06848 8.65236 4.72056 8.22052 4.72056L6.5 4.72042L5.61353 4.72053H0.779483C0.347641 4.72053 0 5.06817 0 5.49988C0 5.93172 0.347641 6.27936 0.779483 6.27936H5.62117L6.49236 6.26424Z" fill="white"/>
            <path d="M4.37883 10.8234L6.10699 10.8389C6.53494 10.8389 6.88647 10.4912 6.88647 10.0592C6.88647 9.62768 6.53883 9.27976 6.10699 9.27976L4.38647 9.27962L3.5 9.27974H0.779483C0.347641 9.27974 0 9.62738 0 10.0591C0 10.4909 0.347641 10.8386 0.779483 10.8386H3.50764L4.37883 10.8234Z" fill="white"/>
            </svg>

          <mat-select [(ngModel)]='filterText' style="color: white;" (selectionChange)="filterByTag($event.value)">
            <mat-option value='all'>Filtre</mat-option>
            <mat-option *ngFor="let tag of tags" [value]="tag.name">
              {{tag.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="mobile" class="col-5" style="align-self: center;">
        <button  *ngIf="suppreTask.length ==0  " class="btn-secondarycrm" (click)="createTaskDialog()">+</button>
        <button *ngIf="suppreTask.length>0" class="btn-trash" (click)="supprTaskArray()">
          <img src="../../../assets/icons/trash.svg">
        </button>

      </div>

      <mat-form-field *ngIf="mobile" class="custom-field">
        <mat-select [(ngModel)]="selectedOption">
          <mat-option *ngFor="let option of options" [value]="option.name">{{option.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

   <div *ngIf="selectedOption === 'Mes tâches'">
    <h2 *ngIf="tasks && tasksWarning.length > 0">En retard</h2>

    <div class="custom-row" *ngFor="let task of tasksWarning | statePipe: filter.state | searchFilter: query">

      <div class="leftRow">
        <input [class.opacity]="currentUser.id !== task.created_by._id && this.currentUser.role != 'Admin' && this.currentUser.role != 'Adv'" type="checkbox" [(value)]='task._id' (change)="$event.target.checked? (isfrmChecked = true) : isfrmChecked = false;
        componentMethodName($event,isfrmChecked);">
        <div class="row-body">

          <div (click)="goToCase(task.caseId)">
            <strong>{{task.message}}</strong>
            <span *ngIf="task.tag && task.tag != 'CEE' && task.tag != 'MPR'" [ngClass]="{
              dossier: task.tag === 'Dossier' || task.tag === undefined,
              raccordement: task.tag === 'Raccordement',
              primexel: task.tag === 'Primexel',
              CM: task.tag === 'CM'}"> {{ task.tag }}</span>
            <span *ngIf="task.tag && task.tag === 'CEE'" class="cee"> Prime {{ task.tag | lowercase }}</span>
            <span *ngIf="task.tag && task.tag === 'MPR'" class="mpr"> Prime {{ task.tag }}</span>

            <p *ngIf='task.customerId'>Affaire {{ task.customerId.name}}<em *ngIf='task.caseId'> : {{ task.caseId.title}}</em></p>

            <div class="d-flex">
              <div class="mr-5 create">
              Tâche créée par : {{task.created_by.username}}
              </div>
              <div class="create">
                Tâche affectée à : {{task.target.username}}
              </div>
            </div>
          </div>

        <div *ngIf="currentUser.id === task.created_by._id || this.currentUser.role === 'Admin' || this.currentUser.role === 'Adv' || this.currentUser.role === 'Responsable ADV'; then thenBlockContact else elseBlockContact"></div>
        <div>
          <ng-template #thenBlockContact>
            <a class="edit" (click)="editTaskDialog(task)">Modifier</a>

          </ng-template>
          <ng-template #elseBlockContact>
            <a class="edit comm" (click)="editTaskDialog(task)">Commenter</a>

          </ng-template>
        </div>

          </div>
       </div>

       <select *ngIf="task.state != '3-Faite'" [ngClass]="{'non': task.state === '1-Urgente', 'pe' : task.state === '2-En-cours', 'oui' : task.state === '3-Faite' }" class="dot" [(ngModel)]='task.state' (change)='editState(task, task._id)'>
        <option value="1-Urgente"><span class="dot">Urgente</span>
        </option>
        <option  value="2-En-cours"><span class="dot">En cours</span>
        </option>
        <option  value="3-Faite"><span class="dot">Faite</span>
        </option>
      </select>


      <select *ngIf="task.state === '3-Faite'" [ngClass]="{'non': task.state === '1-Urgente', 'pe' : task.state === '2-En-cours' }" class="attente" [(ngModel)]='task.state' (change)='editState(task, task._id)'>
       <option value="1-Urgente"><span class="dot">Urgente</span>
       </option>
       <option  value="2-En-cours"><span class="dot">En cours</span>
       </option>
       <option  value="4-Attente"><span class="dot">En attente de confirmation</span>
       </option>
     </select>

     </div>
    <h2 *ngIf="todayTask.length > 0">Aujourd'hui</h2>

   <div class="custom-row" *ngFor="let task of todayTask | statePipe: filter.state | searchFilter: query">

    <div class="leftRow">
      <input [class.opacity]="currentUser.id !== task.created_by._id && this.currentUser.role != 'Admin' && this.currentUser.role != 'Adv'" type="checkbox" [(value)]='task._id' (change)="$event.target.checked? (isfrmChecked = true) : isfrmChecked = false;
      componentMethodName($event,isfrmChecked);">
      <div class="row-body">

        <div (click)="goToCase(task.caseId)">
        <strong>{{task.message}}</strong>
        <span *ngIf="task.tag && task.tag != 'CEE' && task.tag != 'MPR'" [ngClass]="{
          dossier: task.tag === 'Dossier' || task.tag === undefined,
          raccordement: task.tag === 'Raccordement',
          primexel: task.tag === 'Primexel',
          CM: task.tag === 'CM'}"> {{ task.tag }}</span>
        <span *ngIf="task.tag && task.tag === 'CEE'" class="cee"> Prime {{ task.tag | lowercase }}</span>
        <span *ngIf="task.tag && task.tag === 'MPR'" class="mpr"> Prime {{ task.tag }}</span>
        <p *ngIf='task.customerId'>Affaire {{ task.customerId.name}}<em *ngIf='task.caseId'> : {{ task.caseId.title}}</em></p>

        <div class="d-flex">
          <div class="mr-5 create">
          Tâche créée par : {{task.created_by.username}}
          </div>
          <div class="create">
            Tâche affectée à : {{task.target.username}}
          </div>
        </div>
      </div>

      <div *ngIf="currentUser.id === task.created_by._id || this.currentUser.role === 'Admin' || this.currentUser.role === 'Adv' || this.currentUser.role === 'Responsable ADV'; then thenBlockContact else elseBlockContact"></div>
      <div>
      <ng-template #thenBlockContact>
        <a class="edit" (click)="editTaskDialog(task)">Modifier</a>

      </ng-template>
      <ng-template #elseBlockContact>
        <a class="edit comm" (click)="editTaskDialog(task)">Commenter</a>

      </ng-template>
      </div>

        </div>
     </div>

     <select *ngIf="task.state != '3-Faite'" [ngClass]="{'non': task.state === '1-Urgente', 'pe' : task.state === '2-En-cours', 'oui' : task.state === '3-Faite' }" class="dot" [(ngModel)]='task.state' (change)='editState(task, task._id)'>
      <option value="1-Urgente"><span class="dot">Urgente</span>
      </option>
      <option  value="2-En-cours"><span class="dot">En cours</span>
      </option>
      <option  value="3-Faite"><span class="dot">Faite</span>
      </option>
    </select>

    <select *ngIf="task.state === '3-Faite'" [ngClass]="{'non': task.state === '1-Urgente', 'pe' : task.state === '2-En-cours' }" class="attente" [(ngModel)]='task.state' (change)='editState(task, task._id)'>
     <option value="1-Urgente"><span class="dot">Urgente</span>
     </option>
     <option  value="2-En-cours"><span class="dot">En cours</span>
     </option>
     <option  value="4-Attente"><span class="dot">En attente de confirmation</span>
     </option>
   </select>

   </div>

   <h2 *ngIf="tommorrowTask.length > 0">Demain</h2>
   <div class="custom-row" *ngFor="let task of tommorrowTask | statePipe: filter.state | searchFilter: query">

         <div class="leftRow">
          <input [class.opacity]="currentUser.id !== task.created_by._id && this.currentUser.role != 'Admin' && this.currentUser.role != 'Adv'" type="checkbox" [(value)]='task._id' (change)="$event.target.checked? (isfrmChecked = true) : isfrmChecked = false;
          componentMethodName($event,isfrmChecked);">
          <div class="row-body">

            <div (click)="goToCase(task.caseId)">
            <strong>{{task.message}}</strong>
            <span *ngIf="task.tag && task.tag != 'CEE' && task.tag != 'MPR'" [ngClass]="{
              dossier: task.tag === 'Dossier' || task.tag === undefined,
              raccordement: task.tag === 'Raccordement',
              primexel: task.tag === 'Primexel',
              CM: task.tag === 'CM'}"> {{ task.tag }}</span>
            <span *ngIf="task.tag && task.tag === 'CEE'" class="cee"> Prime {{ task.tag | lowercase }}</span>
            <span *ngIf="task.tag && task.tag === 'MPR'" class="mpr"> Prime {{ task.tag }}</span>
            <p *ngIf='task.customerId'>Affaire {{ task.customerId.name}}<em *ngIf='task.caseId'> : {{ task.caseId.title}}</em></p>

            <div class="d-flex">
              <div class="mr-5 create">
              Tâche créée par : {{task.created_by.username}}
              </div>
              <div class="create">
                Tâche affectée à : {{task.target.username}}
              </div>
            </div>
          </div>
          <div *ngIf="currentUser.id === task.created_by._id || this.currentUser.role === 'Admin' || this.currentUser.role === 'Adv' || this.currentUser.role === 'Responsable ADV'; then thenBlockContact else elseBlockContact"></div>
          <div>
          <ng-template #thenBlockContact>
            <a class="edit" (click)="editTaskDialog(task)">Modifier</a>

          </ng-template>
          <ng-template #elseBlockContact>
            <a class="edit comm" (click)="editTaskDialog(task)">Commenter</a>

          </ng-template>
          </div>


            </div>
         </div>

         <select *ngIf="task.state != '3-Faite'" [ngClass]="{'non': task.state === '1-Urgente', 'pe' : task.state === '2-En-cours', 'oui' : task.state === '3-Faite' }" class="dot" [(ngModel)]='task.state' (change)='editState(task, task._id)'>
          <option value="1-Urgente"><span class="dot">Urgente</span>
          </option>
          <option  value="2-En-cours"><span class="dot">En cours</span>
          </option>
          <option  value="3-Faite"><span class="dot">Faite</span>
          </option>
        </select>

        <select *ngIf="task.state === '3-Faite'" [ngClass]="{'non': task.state === '1-Urgente', 'pe' : task.state === '2-En-cours' }" class="attente" [(ngModel)]='task.state' (change)='editState(task, task._id)'>
         <option value="1-Urgente"><span class="dot">Urgente</span>
         </option>
         <option  value="2-En-cours"><span class="dot">En cours</span>
         </option>
         <option  value="4-Attente"><span class="dot">En attente de confirmation</span>
         </option>
       </select>
    </div>

   <h2 *ngIf="otherDayTask.length > 0">Tâches des autres jours </h2>
           <div class="custom-row" *ngFor="let task of otherDayTask | statePipe: filter.state | searchFilter: query">

                 <div class="leftRow">

                  <input [class.opacity]="currentUser.id !== task.created_by._id && this.currentUser.role != 'Admin' && this.currentUser.role != 'Adv'" type="checkbox" [(value)]='task._id' (change)="$event.target.checked? (isfrmChecked = true) : isfrmChecked = false;
                  componentMethodName($event,isfrmChecked);">
                  <div class="row-body">

                    <div (click)="goToCase(task.caseId)">
                      <strong>{{task.message}}</strong>
                      <span *ngIf="task.tag && task.tag != 'CEE' && task.tag != 'MPR'" [ngClass]="{
                        dossier: task.tag === 'Dossier' || task.tag === undefined,
                        raccordement: task.tag === 'Raccordement',
                        primexel: task.tag === 'Primexel',
                        CM: task.tag === 'CM'}"> {{ task.tag }}</span>
                      <span *ngIf="task.tag && task.tag === 'CEE'" class="cee"> Prime {{ task.tag | lowercase }}</span>
                      <span *ngIf="task.tag && task.tag === 'MPR'" class="mpr"> Prime {{ task.tag }}</span>
                      <p *ngIf='task.customerId'>Affaire {{ task.customerId.name}}<em *ngIf='task.caseId'> : {{ task.caseId.title}}</em></p>

                      <div class="d-flex">
                        <div class="mr-5 create">
                        Tâche créée par : {{task.created_by.username}}
                        </div>
                        <div class="create">
                          Tâche affectée à : {{task.target.username}}
                          </div>

                      </div>
                    </div>
                    <div *ngIf="currentUser.id === task.created_by._id || this.currentUser.role === 'Admin' || this.currentUser.role === 'Adv' || this.currentUser.role === 'Responsable ADV'; then thenBlockContact else elseBlockContact"></div>
                    <div>
                    <ng-template #thenBlockContact>
                      <a class="edit" (click)="editTaskDialog(task)">Modifier</a>
                    </ng-template>

                    <ng-template #elseBlockContact>
                      <a class="edit comm" (click)="editTaskDialog(task)">Commenter</a>
                    </ng-template>
                    </div>
                    </div>
                 </div>

                 <select *ngIf="task.state != '3-Faite'" [ngClass]="{'non': task.state === '1-Urgente', 'pe' : task.state === '2-En-cours', 'oui' : task.state === '3-Faite' }" class="dot" [(ngModel)]='task.state' (change)='editState(task, task._id)'>
                  <option value="1-Urgente"><span class="dot">Urgente</span>
                  </option>
                  <option  value="2-En-cours"><span class="dot">En cours</span>
                  </option>
                  <option  value="3-Faite"><span class="dot">Faite</span>
                  </option>
                </select>

                <select *ngIf="task.state === '3-Faite'" [ngClass]="{'non': task.state === '1-Urgente', 'pe' : task.state === '2-En-cours' }" class="attente" [(ngModel)]='task.state' (change)='editState(task, task._id)'>
                 <option value="1-Urgente"><span class="dot">Urgente</span>
                 </option>
                 <option  value="2-En-cours"><span class="dot">En cours</span>
                 </option>
                 <option  value="4-Attente"><span class="dot">En attente de confirmation</span>
                 </option>
               </select>
            </div>
          </div>

          <!-- TACHES QUE J'AI ASSIGNE -->
        <div *ngIf="selectedOption === 'Tâches affectées'">
          <div class="custom-row" *ngFor="let task of createdByTasks | statePipe: filter.state | searchFilter: query">

            <div class="leftRow">
             <input type="checkbox" [(value)]='task._id' (change)="$event.target.checked? (isfrmChecked = true) : isfrmChecked = false;
             componentMethodName($event,isfrmChecked);">
             <div class="row-body">

              <div (click)="goToCase(task.caseId)">
                <strong>{{task.message}}</strong>
                <span *ngIf="task.tag && task.tag != 'CEE' && task.tag != 'MPR'" [ngClass]="{
                  dossier: task.tag === 'Dossier' || task.tag === undefined,
                  raccordement: task.tag === 'Raccordement',
                  primexel: task.tag === 'Primexel',
                  CM: task.tag === 'CM'}"> {{ task.tag }}</span>
                <span *ngIf="task.tag && task.tag === 'CEE'" class="cee"> Prime {{ task.tag | lowercase }}</span>
                <span *ngIf="task.tag && task.tag === 'MPR'" class="mpr"> Prime {{ task.tag }}</span>
                <p *ngIf='task.customerId'>Affaire {{ task.customerId.name}}<em *ngIf='task.caseId'> : {{ task.caseId.title}}</em></p>

                <div class="d-flex">
                  <div class="mr-5 create">
                    Échéance : {{task.date | date: 'shortDate'}}

                  </div>
                  <div class="create">
                    Tâche affectée à : {{task.target.username}}
                    </div>
                </div>
               </div>

               <div *ngIf="currentUser.id === task.created_by._id || this.currentUser.role === 'Admin' || this.currentUser.role === 'Adv' || this.currentUser.role === 'Responsable ADV'; then thenBlockContact else elseBlockContact"></div>
               <div>
               <ng-template #thenBlockContact>
                 <a class="edit" (click)="editTaskDialog(task)">Modifier</a>
               </ng-template>

               <ng-template #elseBlockContact>
                 <a class="edit comm" (click)="editTaskDialog(task)">Commenter</a>
               </ng-template>
               </div>

               </div>
            </div>

            <select *ngIf="task.state != '4-Attente'" [ngClass]="{'non': task.state === '1-Urgente', 'pe' : task.state === '2-En-cours', 'oui' : task.state === '3-Faite' }" class="dot" [(ngModel)]='task.state' (change)='editState(task, task._id)'>
              <option value="1-Urgente"><span class="dot">Urgente</span>
              </option>
              <option  value="2-En-cours"><span class="dot">En cours</span>
              </option>
              <option  value="3-Faite"><span class="dot">Faite</span>
              </option>
            </select>

            <mat-form-field class="custom-field-confirm"  *ngIf="task.state === '4-Attente'">
              <mat-select [(ngModel)]="task.state" (selectionChange)="checkConfirm(task,task._id)">
                <mat-option value='4-Attente'>Confirmé?</mat-option>
                <mat-option value='3-Faite'>Oui</mat-option>
                <mat-option value='1-Urgente'>Non</mat-option>

              </mat-select>
            </mat-form-field>

          </div>
        </div>


        <!-- TACHES FAITES -->
        <div *ngIf="selectedOption === 'Faites'">
          <div class="custom-row" *ngFor="let task of checkTasks | statePipe: filter.state | searchFilter: query">

            <div class="leftRow">
             <input [class.opacity]="currentUser.id !== task.created_by._id && this.currentUser.role != 'Admin'" type="checkbox" [(value)]='task._id' (change)="$event.target.checked? (isfrmChecked = true) : isfrmChecked = false;
             componentMethodName($event,isfrmChecked);">
             <div class="row-body">

              <div (click)="goToCase(task.caseId)">
               <strong>{{task.message}}</strong>

               <p *ngIf='task.customerId'>Affaire {{ task.customerId.name}}<em *ngIf='task.caseId'> : {{ task.caseId.title}}</em></p>

               <div class="d-flex">
                 <div class="mr-5 create">
                  Échéance : {{task.date | date: 'shortDate'}}

                 </div>
                 <div class="create">
                   Tâche affectée à : {{task.target.username}}
                   </div>
               </div>
               </div>
               <div>
                <a class="edit" *ngIf="currentUser.id === task.created_by._id || this.currentUser.role === 'Admin' || this.currentUser.role === 'Adv' || this.currentUser.role === 'Responsable ADV'" (click)="editTaskDialog(task)">Modifier</a>
                <a class="edit comm" [class.opacity]="currentUser.id !== task.created_by._id && this.currentUser.role != 'Adv' && this.currentUser.role != 'Adv' && this.currentUser.role != 'Responsable ADV'" (click)="editTaskDialog(task)">Commenter</a>
              </div>

               </div>
            </div>

            <select *ngIf="task.state != '4-Attente'" [ngClass]="{'non': task.state === '1-Urgente', 'pe' : task.state === '2-En-cours', 'oui' : task.state === '3-Faite'}" class="dot" [(ngModel)]='task.state' (change)='editState(task, task._id)'>
             <option value="1-Urgente"><span class="dot">Urgente</span>
             </option>
             <option  value="2-En-cours"><span class="dot">En cours</span>
             </option>
             <option  value="3-Faite"><span class="dot">Faite</span>
             </option>
           </select>

           <select *ngIf="task.state === '4-Attente'" [ngClass]="{'non': task.state === '1-Urgente', 'pe' : task.state === '2-En-cours' }" class="attente" [(ngModel)]='task.state' (change)='editState(task, task._id)'>
            <option value="1-Urgente"><span class="dot">Urgente</span>
            </option>
            <option  value="2-En-cours"><span class="dot">En cours</span>
            </option>
            <option  value="4-Attente"><span class="dot">En attente de confirmation</span>
            </option>
          </select>

          </div>
        </div>

        <div *ngIf="selectedOption === 'Suivi GDD'">
          <app-table-gdd></app-table-gdd>
        </div>


</div>
