<div *ngIf="case">
  <div class="row">
  <div class="rdvs-container">

    <form [formGroup]="caseForm" class="col-2 test">
          <h5 class="mb-0">Date et heure du R1</h5>
          <form formGroupName="r1">
            <input class="custom-date" placeholder="JJ/MM/AAAA xxhxx" formControlName="date" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" (ngModelChange)="saveChange('edité','date r1',this.caseForm.value.r1.date)">
            <owl-date-time #dt1 firstDayOfWeek="1"></owl-date-time>
          </form>
    </form>

    <div class="col-2 test" *ngFor="let rdv of arrayRdvs">
      <h5 class="mb-0">Date et heure du {{ rdv.type | slice:0:5 }} <a class="delete" (click)="deleteOneRdv(rdv)">x</a></h5>
      <input class="custom-date" placeholder="JJ/MM/AAAA xxhxx" [(ngModel)]="rdv.date" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" (ngModelChange)="editRdvs('edité','date rdv',rdv.date)">
      <owl-date-time firstDayOfWeek="1" (ngModelChange)="editRdvs()" #dt2></owl-date-time>
    </div>

    <div class="col-2">
      <button class="btn-secondarycrm add-rdv" (click)="openDialog()">+ RDV</button>
    </div>

  </div>

    <div class="col-3 right-col">
      <h5>Kit vendu</h5>
    </div>

    <div class="col-2 right-col">
      <a (click)="openKitVendu()" *ngIf="v2">modifier le kit</a>
    </div>
  </div>

  <div class="row">
    <div class="col-6">

      <mat-tab-group (selectedTabChange)="currTab($event)" id="main" class="mt-3">
        <mat-tab class="custom-tab" label="Description">
          <form [formGroup]="caseForm" class="caseForm">
              <textarea class="custom-textarea" placeholder="Description" [(ngModel)]="case.description" formControlName="description" (change)="saveChange('edité','description',case.description)"></textarea>
          </form>
        </mat-tab>

        <mat-tab class="custom-tab" label="R1">
          <form [formGroup]="caseForm" class="caseForm">
            <form formGroupName="r1">
              <textarea class="custom-textarea" placeholder="Débrief" formControlName="debrief" (change)="saveChange('edité','débrief r1',this.caseForm.value.r1.debrief)"></textarea>
            </form>
          </form>
        </mat-tab>
        <mat-tab class="caseForm" *ngFor="let tab Of arrayRdvs;let i= index" [label]="tab.type| slice:0:5">
            <textarea class="custom-textarea" [(ngModel)]="tab.description" (change)="editRdvs('edité','débrief rdv',tab.description)"></textarea>
        </mat-tab>
      </mat-tab-group>
    </div>

      <div class="col-5" *ngIf="v1">
        <form [formGroup]="caseForm">
          <textarea style="min-height: 161px;" formControlName="kits_vendus" placeholder="Débrief" (change)="saveChange('edité','kit vendu',caseForm.value.kits_vendus)"></textarea>
        </form>
      </div>
       <form [formGroup]="caseForm" class="col-5" *ngIf="v2" >
      <mat-form-field class="col-10 custom-padding">
        <mat-select class="financement" [value]=products placeholder='Sélectionner le kit vendu' (selectionChange)="saveChangeKit($event.value,'edité', 'kit vendu','produit du kit vendu')" multiple>
          <mat-option class="col-10" value="Adoucisseur d'eau">Adoucisseur d'eau</mat-option>
          <mat-option class="col-10" value="Ballon thermodynamique">Ballon thermodynamique</mat-option>
          <mat-option class="col-10" value="Batterie">Batterie</mat-option>
          <mat-option class="col-10" value="Isolation">Isolation</mat-option>
          <mat-option class="col-10" value="Mise aux normes du tableau électrique">Mise aux normes du tableau électrique</mat-option>
          <mat-option class="col-10" value="PAC Air/Air">PAC Air/Air</mat-option>
          <mat-option class="col-10" value="PAC Air/Eau">PAC Air/Eau</mat-option>
          <mat-option class="col-10" value="Panneaux solaires">Panneaux solaires</mat-option>
          <mat-option class="col-10" value="Thermostat radio">Thermostat radio</mat-option>
          <mat-option class="col-10" value="VMC Double Flux">VMC Double Flux</mat-option>
          <mat-option *ngFor="let autre of autres" [value]="autre">
            {{ autre }}
          </mat-option>
        </mat-select>
      </mat-form-field>


      <app-product-list [selectedProducts]="selectedProducts"></app-product-list>
      </form>

  </div>

  <form [formGroup]="caseForm" style="margin-left: 1%;">
    <div class="row input-bottom">
      <div class="col-5">
        <h3>Financement</h3>
        <mat-form-field class="col-10 custom-padding">
          <mat-select class="financement" formControlName="financement_type" [(ngModel)]="case.financement_type" (selectionChange)="saveChange('edité','type de financement',case.financement_type)">
            <mat-option class="col-10" value="Comptant">Comptant</mat-option>
            <mat-option class="col-10" value="sofinco">Demande de financement Sofinco</mat-option>
            <mat-option class="col-10" value="cetelem">Demande de financement Cetelem</mat-option>
            <mat-option class="col-10" value="cofidis">Demande de financement Cofidis</mat-option>
          </mat-select>
        </mat-form-field>
      <br>
      <mat-form-field class="example-full-width" class="col-5 custom-padding">
        <input type="number" formControlName="price_ht" matInput placeholder="Prix HT" [(ngModel)]="case.price_ht" (change)="saveChange('edité','prix HT',case.price_ht)">
      </mat-form-field>

      <mat-form-field class="example-full-width" class="col-5 custom-padding">
        <input type="number" formControlName="price_ht" matInput formControlName="price_ttc" placeholder="Prix TTC" [(ngModel)]="case.price_ttc" (change)="saveChange('edité','prix TTC',case.price_ttc)">
      </mat-form-field>
      </div>


    <div class="col-5 offset-1">
        <form formGroupName="letter">

      <h5>Lettre de confirmation de commande <img *ngIf="case.letter.messageId != undefined" id="hover" src="../../../assets/icons/historique.svg"></h5>

      <mat-select formControlName="status" class="custom-select-state rdv" [ngClass]="case.letter.status != 'En cours' ? 'Signée' : 'Négociation'" (selectionChange)="saveChange('edité','lettre de confirmation de commande',this.caseForm.value.letter.status)" (selectionChange)="checkIdentifiantCustomer()">
        <mat-option class="Négociation" value="En cours">En cours</mat-option>
        <mat-option class="Signée" *ngFor="let lettre of lettres" [value]="lettre">
          {{lettre}}
        </mat-option>
      </mat-select>

      <h5 class="mt-3">Date d'envoie</h5>
        <input placeholder="JJ/MM/AAAA xxhxx" class="custom-date" formControlName="date" [owlDateTime]="dt5" [owlDateTimeTrigger]="dt5" (ngModelChange)="saveChange('edité','date de la lettre de confirmation de commande',this.caseForm.value.letter.date)">
        <owl-date-time #dt5></owl-date-time>
      </form>
    </div>

    </div>


  <form formGroupName="tarif">
    <div class="row">
      <div class="col-2 tarif" *ngIf='case.tarif != undefined'>
        <h5>Tarif valide ?</h5>
        <input type="radio" id="tarif-oui" name="status" formControlName="status" [(ngModel)]="case.tarif.status" value="oui" (change)="saveChangeTarif('edité','tarif valide',this.caseForm.value.tarif.status)">
        <label class="ml-0" for="tarif-oui" [ngClass]="case.tarif.status === 'oui' ? 'oui' : 'oui-empty'">
          <span class="material-icons">
          done
          </span>OUI</label>
        <input type="radio" id="tarif-non" name="status" formControlName="status" [(ngModel)]="case.tarif.status" value="non" (change)="saveChangeTarif('edité','tarif valide',this.caseForm.value.tarif.status)">
        <label for="tarif-non" [ngClass]="case.tarif.status === 'non' ? 'non' : 'non-empty'">
          <span class="material-icons">
          clear
          </span>NON</label>
      </div>
      <div class="col-4" *ngIf='case.tarif != undefined'>
        <textarea *ngIf="case && case.tarif.status === 'non' && case.tarif.accepted != 'oui'" formControlName="why" [(ngModel)]="case.tarif.why" class="custom-textarea" (change)="saveChangeTarif('edité','tarif valide raison ',this.caseForm.value.tarif.why)"></textarea>
      </div>

      <div class="offset-3 col-3 new-task">
        <button class="btn-secondarycrm" (click)="createTaskDialog()">+ Nouvelle tâche</button>
      </div>


    </div>
  </form>
</form>
 <form [formGroup]="caseForm">
  <form formGroupName="tarif">
    <div class="row" *ngIf="case.tarif != undefined && this.sRoles.user.role == 'Admin'">
      <div class="col-6 valid-tarif">
        <input type="radio" id="valid-oui" name="accepted" formControlName="accepted" [(ngModel)]="case.tarif.accepted" value="oui" (change)="saveChangeAccepted('edité','tarif valide',this.caseForm.value.tarif.accepted)">
        <label class="ml-0" for="valid-oui" [ngClass]="case.tarif.accepted === 'oui' ? 'oui' : 'oui-empty'">
          Validation du tarif
        </label>
        <input type="radio" id="valid-non" name="accepted" formControlName="accepted" [(ngModel)]="case.tarif.accepted" value="non" (change)="saveChangeAccepted('edité','tarif valide',this.caseForm.value.tarif.accepted)">
        <label for="valid-non" [ngClass]="case.tarif.accepted === 'non' ? 'non' : 'non-empty'">
          Mauvais tarif
        </label>
      </div>

    </div>
    <div class="col-3">
      <button class="btn-secondarycrm link" (click)="showTaskDialog()">Voir les tâches de l'affaire</button>
     </div>
    <div class="row" *ngIf="case.tarif != undefined && this.sRoles.user.role != 'Admin'">
      <div class="col-6 valid-tarif" *ngIf="case.tarif.status === 'non'">
        <input type="radio">
        <label class="ml-0" for="valid-oui" [ngClass]="case.tarif.accepted === 'oui' ? 'oui' : 'oui-empty'">
          Validation du tarif
        </label>
        <input type="radio">
        <label for="valid-non" [ngClass]="case.tarif.accepted === 'non' ? 'non' : 'non-empty'">
          Mauvais tarif
        </label>
      </div>
    </div>
  </form>
</form>

</div>

