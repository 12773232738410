<div class="book-container">
  <img class="logo_couleur" src="./../../../assets/book/logo_normal.jpg" alt="logo" />
  <h1>RT 2020</h1>
  <p>Un objectif principal : réduire la consommation énergétique des bâtiments à <strong>49, 34 kWh/m2 .</strong>
    <br>À titre d’exemple, <strong>une maison moyenne consomme 110 kWh/m2.</strong> L’objectif de cette réglementation
    est donc de diviser par 2 la consommation énergétique.<br><br>
    L’étiquette énergétique composée de 7 classes allant de A à G (A correspondant à la meilleure, G la plus mauvaise)
    facilitent la lecture du Diagnostic de Performance Énergétique (DPE).
  </p>
  <h3>Estimons votre DPE</h3>
  <div class="row" style="position: relative;">
    <div class="col">
      <p>Année de construction</p>
      <ngx-slider class="sliders_custom" [(value)]="anneeSlider" [options]="anneeOptions"></ngx-slider>
    </div>
    <div class="col">
      <p>Votre surface</p>
      <ngx-slider class="sliders_custom" [(value)]="surfaceSlider" [options]="surfaceOptions"></ngx-slider>

    </div>
    <div class="col">
      <p>T° actuelle du chauffage</p>
      <ngx-slider class="sliders_custom" [(value)]="temperatureSlider" [options]="temperatureOptions"></ngx-slider>
    </div>
    <button class="primary-button" (click)="suivant()">
      Suivant
      <svg width="22" height="20" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.0303 6.53057C13.3232 6.23768 13.3232 5.76281 13.0303 5.46991L8.25736 0.696943C7.96447 0.40405 7.48959 0.40405 7.1967 0.696943C6.90381 0.989837 6.90381 1.46471 7.1967 1.7576L11.4393 6.00024L7.1967 10.2429C6.90381 10.5358 6.90381 11.0107 7.1967 11.3035C7.48959 11.5964 7.96447 11.5964 8.25736 11.3035L13.0303 6.53057ZM0.5 6.75024H12.5V5.25024H0.5V6.75024Z"
          fill="white" />
      </svg>
    </button>
  </div>

  <img class="img-center slideDown" src="./../../../assets/book/p4_center.png" />
  <img class="img-right slideDown" src="./../../../assets/book/p4_right.png" />
  <img class="img-dpe" src="./../../../assets/book/part4/dpe.gif" />

</div>