import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { RolesService } from '@app/services/roles.service';
import { PicturesService } from '@app/services/pictures.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthenticationService } from '@app/_services';
import { apiUrl } from '@environments/env';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { InfosService } from '@app/services/infos.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  countUnread;
  apiUrl = apiUrl.apiUrl + '/picture/';
  //apiUrl = 'http://localhost:4000/picture/';
  user;
  mobile;
  date;
  currentPicture;
  picture;
  pictureOk;
  displayPicture;
  imgUpload;
  loading;
  message = false;
  showUnread = true;
  showRead = true;
  infos;
  infosRead = [];
  infosUnread = [];

  constructor(
    private sRoles: RolesService,
    private authenticationService: AuthenticationService,
    private sPictures: PicturesService,
    private http: HttpClient,
    public sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private router: Router,
    private sInfos: InfosService,
  ) {
    this.user = this.sRoles.user;
    this.getPictureByUserId();
  }

  ngOnInit(): void {
    if (window.screen.width <= 600) {
      this.mobile = true;
    }
    this.date = Date.now();
    this.showInfosByAdvId()
    this.getGroups();
  }

  selectPicture(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.picture = event.target.files;
      this.imgUpload = window.URL.createObjectURL(file);
    }
    this.loading = true;

    setTimeout(() => {
      this.loading = false;
      this.message = true;
      this.displayPicture = true;

      setTimeout(() => {
        this.message = false;
      }, 2000);
      this.onSubmitPicture();
    }, 1000);
  }

  onSubmitPicture() {
    const formData = new FormData();
    formData.append('userId', this.user.id);
    for (let img of this.picture) {
      formData.append('filesPieces', img);
    }
    this.http.post<any>(this.apiUrl + 'multiplePieces', formData).subscribe(
      (res) => {
        this.pictureOk = true;
        console.log(res)
        this.getPictureByUserId();
      },
      (err) => console.log(err)
    );
  }

  getPictureByUserId() {
    this.sPictures.getPicturesByUserId(this.user.id).subscribe(res => {
      this.currentPicture = res[0];
    });
  }

  goToUsersList() {
    this.dialog.closeAll();
    this.router.navigate(['admin-user-list']);
  }
  goToCaseTable() {
    this.dialog.closeAll();
    this.router.navigate(['case-table']);
  }

  logout() {
    this.dialog.closeAll();
    this.authenticationService.logout();
  }

  showInfosByAdvId() {
    this.sInfos.getInfoByAdvId(this.user.id).subscribe(res => {
      this.infos = res;
      this.infos.forEach(element => {
        if (element.vu) {
          this.infosRead.push(element)
        }
        else {
          this.infosUnread.push(element)
        }
      });
    })
  }
  async getGroups(): Promise<void> {
    try {
      const countUnread: any = await this.http.get(apiUrl.apiUrl + '/messages/count-unread').toPromise();
      this.countUnread = countUnread;
    } catch (error) { }
  }
}
