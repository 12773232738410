import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-part14',
  templateUrl: './part14.component.html',
  styleUrls: ['./part14.component.scss']
})

export class Part14Component implements OnInit {

  caseId

  nightMode = false;
  pvChecked = false;
  pacChecked = false;

  pacIsActive = false;
  voitureIsActive = false;
  pvIsActive = false;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.caseId = this.route.snapshot.params['id'];
  }

  switch() {
    this.nightMode = !this.nightMode;
    if (this.nightMode) {
      this.pvChecked = false;
    }
  }

  switchPopUp(choice) {
    switch (choice) {
      case 'pv':
        this.pvIsActive = !this.pvIsActive;
        this.pacIsActive = false;
        this.voitureIsActive = false;
        break;
      case 'pac':
        this.pacIsActive = !this.pacIsActive;
        this.pvIsActive = false;
        this.voitureIsActive = false;
        break;
      case 'voiture':
        this.voitureIsActive = !this.voitureIsActive;
        this.pvIsActive = false;
        this.pacIsActive = false;
        break;

      default:
        break;
    }
  }

}








