<h3>Selectionnez le client à qui vous voulez attribuer ces affaires</h3>
<mat-form-field class="col-6">
  <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto" [placeholder]=customerPlaceholder>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngFor="let customer of filteredOptions | async" [value]="customer" (click)="toggleCustomerSelection(customer)">
      {{customer.name}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<div *ngIf="invalidCustomer" style="color: red; margin-top: 5px;">
  Saisie non valide !
</div>

<div class="actions">
  <button mat-button (click)="confirmFusion()">Confirmer</button>
  <button mat-button (click)="close()">Annuler</button>
</div>
