<mat-horizontal-stepper [linear]="true" #stepper>
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup">
      <div>
        <h5>Champs photovoltaïque</h5>

        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
              <label>Marque et type</label>
              <input class="custom-input" type="text" formControlName="marque_type" (change)="getCurrentKitAndSaveChange($event.value,'marque_type')">
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
            <label>N° de série</label>
            <input class="custom-input" type="text" formControlName="marque_type" (change)="getCurrentKitAndSaveChange($event.value,'marque_type')">
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6">
            <label>Nombre</label>
            <div class="increment">
              <button
                class="rm"
                mat-button
                mat-icon-button
                matPrefix
                aria-label="Remove"
                (click)="incrementValueOnduleur(-_step, currentKit.type,'edité', 'module_reserve', currentKit.nbr_onduleur)">
                <mat-icon>remove</mat-icon>
              </button>
              <input type="number"[value]="currentKit.nbr_onduleur" />
              <button
                class="add"
                mat-button
                mat-icon-button
                matSuffix
                aria-label="Add"
                (click)="incrementValueOnduleur(_step, currentKit.type,'edité', 'nbr_onduleur', currentKit.nbr_onduleur)"
                >
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <div class="row my-5">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
            <label>Pose</label>
            <mat-form-field>
              <mat-select formControlName="pose" (selectionChange)="addOption(currentKit.type,'edité', 'pose', 'pose', firstFormGroup.value.pose)"  (selectionChange)="verifyForm()">
                <mat-option *ngFor="let pose of poses_pv" [value]="pose" >
                  {{pose}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
            <label>Orientation</label>
            <mat-form-field>
              <mat-select  formControlName="orientation" (selectionChange)="addOption(currentKit.type,'edité', 'orientation', 'orientation', firstFormGroup.value.orientation)">
                <mat-option *ngFor="let orientation of orientations" [value]="orientation">
                  {{ orientation }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
            <label>Déclivité</label>
            <input class="custom-input" formControlName="declivite" (change)="getCurrentKitAndSaveChange($event.value,'declivite')">
          </div>
        </div>
<!-- INTEGRATION -->
      <div *ngIf="integration">
        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Traversée toiture des câbles :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="traverse_toiture" (change)="getCurrentKitAndSaveChange($event.value,'traverse_toiture')">
              <mat-radio-button value="Col de cygne">Col de cygne</mat-radio-button>
              <mat-radio-button value="Tuile chatière" class="ml-4">Tuile chatière</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Étanchéité abergement gauche : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="etancheite_abergement_g" (change)="getCurrentKitAndSaveChange($event.value,'etancheite_abergement_g')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('etancheite_abergement_g')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Étanchéité en partie supérieure du champs : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="etancheite_superieure" (change)="getCurrentKitAndSaveChange($event.value,'etancheite_superieure')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('etancheite_superieure')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Étanchéité abergement droite : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="etancheite_abergement_d" (change)="getCurrentKitAndSaveChange($event.value,'etancheite_abergement_d')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('etancheite_abergement_d')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Étanchéité en partie inférieure du champs : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="etancheite_inferieure" (change)="getCurrentKitAndSaveChange($event.value,'etancheite_inferieure')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('etancheite_inferieure')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Étanchéité inter module : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="etancheite_inter" (change)="getCurrentKitAndSaveChange($event.value,'etancheite_inter')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('etancheite_inter')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Partie inférieure du champ</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="inferieure_champ" (change)="getCurrentKitAndSaveChange($event.value,'inferieure_champ')">
              <mat-radio-button value="à l'égout">à l'égout</mat-radio-button>
              <mat-radio-button value="Bavette etancheité" class="ml-4">Bavette etancheité</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Écran sous toiture :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="ecran_sous_toiture" (change)="getCurrentKitAndSaveChange($event.value,'ecran_sous_toiture')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('ecran_sous_toiture')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice last">
            <mat-label>Contrôle étanchéité par arrosage : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="etancheite_arrosage" (change)="getCurrentKitAndSaveChange($event.value,'etancheite_arrosage')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('etancheite_arrosage')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

<!-- AU SOL -->
      <div *ngIf="sol">
        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Fixation au sol :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="fixation" (change)="getCurrentKitAndSaveChange($event.value,'fixation')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('fixation')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Étanchéité abergement gauche : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="chassis" (change)="getCurrentKitAndSaveChange($event.value,'chassis')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('chassis')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col radio-choice last">
            <mat-label>Chassis lestés : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="ancrage" (change)="getCurrentKitAndSaveChange($event.value,'ancrage')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('ancrage')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

      </div>

<!-- SUR IMPOSITION -->
<div *ngIf="surimpo">
  <div class="row">
    <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
      <mat-label>Traversée toiture des câbles :</mat-label>
      <mat-radio-group aria-label="Select an option" formControlName="traverse_toiture" (change)="getCurrentKitAndSaveChange($event.value,'traverse_toiture')">
        <mat-radio-button value="Col de cygne">Col de cygne</mat-radio-button>
        <mat-radio-button value="Tuile chatière" class="ml-4">Tuile chatière</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
      <mat-label>Pattes de fixation conformes</mat-label>
      <mat-radio-group aria-label="Select an option" formControlName="patte_fixation" (change)="getCurrentKitAndSaveChange($event.value,'patte_fixation')">
        <mat-radio-button value="oui">Oui</mat-radio-button>
        <mat-radio-button value="non" class="ml-4" (click)="openDialog('patte_fixation')">Non</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice last">
      <mat-label>Étanchéité des pattes de fixations : </mat-label>
      <mat-radio-group aria-label="Select an option" formControlName="etancheite_fixation" (change)="getCurrentKitAndSaveChange($event.value,'etancheite_fixation')">
        <mat-radio-button value="oui">Oui</mat-radio-button>
        <mat-radio-button value="non" class="ml-4" (click)="openDialog('etancheite_fixation')">Non</mat-radio-button>
      </mat-radio-group>
    </div>

  </div>

</div>
<!-- facade -->
      <div *ngIf="facade">
        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>En brise soleil :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="brise_soleil" (change)="getCurrentKitAndSaveChange($event.value,'brise_soleil')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('brise_soleil')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col radio-choice last">
            <mat-label>Chassis sur structure porteuse :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="chassis" (change)="getCurrentKitAndSaveChange($event.value,'chassis')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('chassis')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

      </div>
      </div>
        <div>
          <button class="btn-secondarycrm" matStepperNext [style.opacity]="!firstFormGroup.valid ? '0.4' : '1'" (click)="updateData(50)">Suivant</button>
        </div>
    </form>
  </mat-step>

  <mat-step [stepControl]="secondFormGroup">
    <form [formGroup]="secondFormGroup">
        <h5>Onduleur</h5>
        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
              <label>Marque et type</label>
              <input class="custom-input" type="text" formControlName="marque_type_onduleur" (change)="getCurrentKitAndSaveChange(this.secondFormGroup.value.marque_type_onduleur,'marque_type_onduleur')">
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
            <label>N° de série</label>
            <input class="custom-input" type="text">
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
            <label>Relevé compteur d’injection</label>
            <input class="custom-input" type="text" formControlName="compteur_injection" (change)="getCurrentKitAndSaveChange(this.secondFormGroup.value.compteur_injection,'compteur_injection')">
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
              <label>Relevé compteur de sous tirage</label>
              <input class="custom-input" type="text" formControlName="compteur_sous_tirage" (change)="getCurrentKitAndSaveChange(this.secondFormGroup.value.compteur_sous_tirage,'compteur_sous_tirage')">
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
            <label>Tension CC -Vdc(V)</label>
            <input class="custom-input" type="text" formControlName="tension_cc" (change)="getCurrentKitAndSaveChange(this.secondFormGroup.value.tension_cc,'tension_cc')">
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
            <label>Tension AC -Vdc(V)</label>
            <input class="custom-input" type="text" formControlName="tension_ac" (change)="getCurrentKitAndSaveChange(this.secondFormGroup.value.tension_ac,'tension_ac')">
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Installation dans une pièce ventilée :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="install_ventilee" (change)="getCurrentKitAndSaveChange($event.value,'install_ventilee')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('install_ventilee')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Dégagement supérieur/inférieur conforme notice : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="degagement_conforme" (change)="getCurrentKitAndSaveChange($event.value,'degagement_conforme')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('degagement_conforme')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Défaut d’isolement signalé par onduleur : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="defaut_isolement" (change)="getCurrentKitAndSaveChange($event.value,'defaut_isolement')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Signalement réglementaire :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="signalement_reglementaire" (change)="getCurrentKitAndSaveChange($event.value,'signalement_reglementaire')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('signalement_reglementaire')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Câble de mise à la terre du capôt : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="mise_terre_capot" (change)="getCurrentKitAndSaveChange($event.value,'mise_terre_capot')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('mise_terre_capot')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice last">
            <mat-label>Dégagement latéraux conformes notice installation : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="degagement_lateraux_conforme" (change)="getCurrentKitAndSaveChange($event.value,'degagement_lateraux_conforme')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('degagement_lateraux_conforme')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div>
          <button class="btn-secondarycrm" matStepperNext [style.opacity]="!secondFormGroup.valid ? '0.4' : '1'" (click)="updateData(75)">Suivant</button>
        </div>
    </form>
  </mat-step>


  <mat-step [stepControl]="thirdFormGroup">
    <form [formGroup]="thirdFormGroup">
        <h5>Autre</h5>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Coffret DC : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="coffret_dc" (change)="getCurrentKitAndSaveChange($event.value,'coffret_dc')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('coffret_dc')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Parafoudre protégé :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="parafoudre_protege" (change)="getCurrentKitAndSaveChange($event.value,'parafoudre_protege')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('parafoudre_protege')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>


        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Interrupteur sectionneur :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="interrupteur_sectionneur" (change)="getCurrentKitAndSaveChange($event.value,'interrupteur_sectionneur')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('interrupteur_sectionneur')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Continuité des terres :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="continuite_terres" (change)="getCurrentKitAndSaveChange($event.value,'continuite_terres')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('continuite_terres')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Parafoudre : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="parafoudre" (change)="getCurrentKitAndSaveChange($event.value,'parafoudre')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('parafoudre')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Si coffret AC/DC présence de plaque séparation physique :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="plaque_separation" (change)="getCurrentKitAndSaveChange($event.value,'plaque_separation')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('plaque_separation')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Signalisation réglementaire :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="signalisation_reglementaire" (change)="getCurrentKitAndSaveChange($event.value,'signalisation_reglementaire')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('signalisation_reglementaire')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Coffret à hauteur réglementaire :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="coffret_reglementaire" (change)="getCurrentKitAndSaveChange($event.value,'coffret_reglementaire')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('coffret_reglementaire')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Inter différentiel 30mA :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="inter_diff" (change)="getCurrentKitAndSaveChange($event.value,'inter_diff')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('inter_diff')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Signalisation réglementaire sur cablage :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="signalisation_cablage" (change)="getCurrentKitAndSaveChange($event.value,'signalisation_cablage')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('signalisation_cablage')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Disjoncteur 20A :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="disjoncteur_20a" (change)="getCurrentKitAndSaveChange($event.value,'disjoncteur_20a')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('disjoncteur_20a')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Autoconsommation, coupure dans le tableau client :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="coupure_tableau" (change)="getCurrentKitAndSaveChange($event.value,'coupure_tableau')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('coupure_tableau')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Coupure AC inf. à 1m80 :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="coupure_ac_180" (change)="getCurrentKitAndSaveChange($event.value,'coupure_ac_180')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('coupure_ac_180')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Monitoring Internet :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="monitoring_internet" (change)="getCurrentKitAndSaveChange($event.value,'monitoring_internet')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('monitoring_internet')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Signalisation SAV Enerconfort :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="signalisation" (change)="getCurrentKitAndSaveChange($event.value,'signalisation')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('signalisation')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Compte client créé + Explication interfaces :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="compte_client" (change)="getCurrentKitAndSaveChange($event.value,'compte_client')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('compte_client')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Sité créé :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="site" (change)="getCurrentKitAndSaveChange($event.value,'site')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('site')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Nettoyage chantier :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="nettoyage" (change)="getCurrentKitAndSaveChange($event.value,'nettoyage')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('nettoyage')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice last">
            <mat-label>Connexion : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="connexion" (change)="getCurrentKitAndSaveChange($event.value,'connexion')">
              <mat-radio-button value="Ethernet">Ethernet</mat-radio-button>
              <mat-radio-button value="Wifi" class="ml-4">Wifi</mat-radio-button>
              <mat-radio-button value="CPL" class="ml-4">CPL</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>




        <div>
          <button class="btn-secondarycrm" matStepperNext [style.opacity]="!thirdFormGroup.valid ? '0.4' : '1'" (click)="updateData(100)">Suivant</button>
        </div>
    </form>
  </mat-step>

  <mat-step>

    <textarea class="custom-textarea" placeholder="Observations" [(ngModel)]="currentKit.observation" (change)="getCurrentKitAndSaveChange('observation',case.observation)"></textarea>

    <ng-template matStepLabel>Done</ng-template>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Champ photovoltaïque
        <label class="upload-label" for="upload" *ngIf="!champ_photo"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <span class="check" *ngIf="champ_photo">O</span>
        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="upload" class="upload-box" (change)="selectMultiple($event, 'Champ photovoltaïque')">
      </div>

      <div class="button-wrapper mt-3 col-lg-6">
        Toîture globale
        <label class="upload-label" for="upload2" *ngIf="!toiture"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <span class="check" *ngIf="toiture">O</span>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="upload2" class="upload-box" (change)="selectMultiple($event, 'Toîture globale')">
      </div>
    </div>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Coffret AC/DC
        <label class="upload-label" for="upload3" *ngIf="!coffret_acdc"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <span class="check" *ngIf="coffret_acdc">O</span>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="upload3" class="upload-box" (change)="selectMultiple($event, 'Coffret AC/DC')">

      </div>

      <div class="button-wrapper mt-3 col-lg-6">
        Pièces onduleurs
        <label class="upload-label" for="upload4" *ngIf="!pieces"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <span class="check" *ngIf="pieces">O</span>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="upload4" class="upload-box" (change)="selectMultiple($event, 'Pièces onduleurs')">
      </div>

    </div>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Coffret de sécurité
        <label class="upload-label" for="upload5" *ngIf="!coffret_secu"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <span class="check" *ngIf="coffret_secu">O</span>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="upload5" class="upload-box" (change)="selectMultiple($event, 'Coffret de sécurité')">
      </div>

    </div>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Onduleur
        <label class="upload-label" for="upload5" *ngIf="!onduleur"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <span class="check" *ngIf="onduleur">O</span>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="upload5" class="upload-box" (change)="selectMultiple($event, 'Onduleur')">
      </div>

    </div>
        <button class="btn-secondarycrm" (click)="updateData(100)"(click)="valid()">Confirmer</button>
  </mat-step>

</mat-horizontal-stepper>
