import { CustomersService } from '@app/services/customers.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { UsersService } from '@app/services/users.service';
import { TasksService } from '@app/services/tasks.service';
import { RolesService } from '@app/services/roles.service';
import { InfosService } from '@app/services/infos.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { CasePrimeAddComponent } from '@app/case-prime/case-prime-add/case-prime-add.component';
import { TasksGddService } from '@app/services/tasks-gdd.service';
import * as moment from 'moment';

@Component({
  selector: 'app-case-template',
  templateUrl: './case-template.component.html',
  styleUrls: ['./case-template.component.scss'],
})
export class CaseTemplateComponent implements OnInit {

  financementId = '';
  primeId = '';
  mairieId = '';
  chantierId = '';
  currentPiece = '';
  titrePiece = '';
  caseId;
  case;
  rdvs;
  message;

  piecesList;
  piecesAdd;
  pieceEdit;

  mairieList;
  mairieAdd;
  mairieEdit;

  financementList;
  financementComptant;
  financementEdit;
  financementAdd;

  primeList;
  primeEdit;
  primeAdd;

  financementOk = false;

  chantierList;
  chantierDetail;

  caseForm: FormGroup;

  tasks;
  currentUser;
  disagreed;

  options = ['Négociation', 'Prospection', 'Signée', 'Non Signée','Annulée'];
  states = ['Oui', 'Non pas trouvé', 'Non refus de visite', 'Non porte'];

  active = 'active';

  users;
  commerciaux = [];
  advs = [];

  editCustomer = false;
  date = new Date();
  actualDate
  task;

  showEditTitle = false;
  customerLog = ''
  newUserGeneratedPassword = ''
  currentProducts
  constructor(
    private route: ActivatedRoute,
    private sCases: CasesService,
    private router: Router,
    private fb: FormBuilder,
    public sUsers: UsersService,
    private sCustomer: CustomersService,
    private sTasks: TasksService,
    private sTasksGdd: TasksGddService,
    public sRoles: RolesService,
    private sInfos: InfosService,
    private sb: MatSnackBar,
    private dialog: MatDialog,

    )
    {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.caseId = this.route.snapshot.params['id'];
      this.sUsers.getAllCommerciaux().subscribe(res => {
        this.commerciaux = res['users']
      })
      this.sUsers.getAllAdv().subscribe(res => {
        this.advs = res['users']
      })
      this.showCaseInfos().then(res => {
        this.caseForm = this.fb.group({
          rdv_state: this.fb.group({
            'status': [this.case.rdv_state.status],
            'why': [this.case.rdv_state.why],
          }),
          "state" : [this.case.state],
          "commercial" : [this.case.commercial],
          "adv" : [this.case.adv],
          "datesign" : [this.case.datesign],
          "datecancel":[null],
          "title": [this.case.title],
          "qualification":[],
          "isHidden": [],
        });
        this.showTasksByMessage()

      })
      if (this.router.getCurrentNavigation().extras.state) {
        this.financementId = this.router.getCurrentNavigation().extras.state.financementId;
        this.mairieId = this.router.getCurrentNavigation().extras.state.mairieId;
        this.chantierId = this.router.getCurrentNavigation().extras.state.chantierId;
        this.primeId = this.router.getCurrentNavigation().extras.state.primeId;

        this.titrePiece = this.router.getCurrentNavigation().extras.state.titrePiece;
        this.currentPiece = this.router.getCurrentNavigation().extras.state.currentPiece;

        if (this.router.getCurrentNavigation().extras.state.value === 'piecesList') {
          this.piecesList = true;
        }
        else if (this.router.getCurrentNavigation().extras.state.value === 'piecesAdd') {
          this.piecesAdd = true;
        }
        else if (this.router.getCurrentNavigation().extras.state.value === 'pieceEdit') {
          this.pieceEdit = true;
        }
        else if (this.router.getCurrentNavigation().extras.state.value === 'financementList') {
          this.financementList = true;
        }
        else if (this.router.getCurrentNavigation().extras.state.value === 'financementComptant') {
          this.financementComptant = true;
        }
        else if (this.router.getCurrentNavigation().extras.state.value === 'rdvs') {
          this.rdvs = true;
        }
        else if (this.router.getCurrentNavigation().extras.state.value === 'financementEdit') {
          this.financementEdit = true;
        }
        else if (this.router.getCurrentNavigation().extras.state.value === 'mairieList') {
          this.mairieList = true;
        }
        else if (this.router.getCurrentNavigation().extras.state.value === 'mairieAdd') {
          this.mairieAdd = true;
        }
        else if (this.router.getCurrentNavigation().extras.state.value === 'mairieEdit') {
          this.mairieEdit = true;
        }
        else if (this.router.getCurrentNavigation().extras.state.value === 'chantierDetail') {
          this.chantierDetail = true;
        }
        else if (this.router.getCurrentNavigation().extras.state.value === 'primeList') {
          this.primeList = true;
        }
        else if (this.router.getCurrentNavigation().extras.state.value === 'primeAdd') {
          this.primeAdd = true;
        }
        else if (this.router.getCurrentNavigation().extras.state.value === 'primeEdit') {
          this.primeEdit = true;
        }
      }
      else {
        this.rdvs = true;
      }

      this.date.setHours(this.date.getHours()+1)
      this.actualDate = this.date.toISOString()
    }

  ngOnInit() {
    this.showCaseInfos();
  }

  addItem(newItem: string) {
    this.currentProducts = newItem
  }
  onVoted(agreed: boolean) {
    agreed ? this.disagreed = false : this.disagreed = true;
  }

  showCaseInfos() {
    return new Promise(resolve => {

    this.sCases.getCaseById(this.caseId).subscribe(res => {
      this.case = res[0];

      resolve(res);
        }, error => {
          console.log(error);
      });
    });
  }

  showRdvs() {
    this.rdvs = true;
    this.financementList = false;
    this.financementComptant = false;
    this.piecesList = false;
    this.financementEdit = false;
    this.pieceEdit = false;
    this.piecesAdd = false;
    this.financementAdd = false;
    this.chantierList = false;
    this.chantierDetail = false;
    this.mairieList = false;
    this.mairieAdd = false;
    this.mairieEdit = false;
    this.primeList = false
    this.primeEdit = false
    this.primeAdd = false
  }

  showPiecesList() {
    this.piecesList = true;
    this.rdvs = false;
    this.financementList = false;
    this.financementComptant = false;
    this.financementEdit = false;
    this.pieceEdit = false;
    this.piecesAdd = false;
    this.financementAdd = false;
    this.chantierList = false;
    this.chantierDetail = false;


    this.mairieList = false;
    this.mairieAdd = false;
    this.mairieEdit = false;
    this.primeList = false
    this.primeEdit = false
    this.primeAdd = false
  }
  showMairieList() {
    this.mairieList = true;
    this.piecesList = false;
    this.rdvs = false;
    this.financementList = false;
    this.financementComptant = false;
    this.financementEdit = false;
    this.pieceEdit = false;
    this.financementAdd = false;
    this.mairieAdd = false;
    this.mairieEdit = false;
    this.chantierList = false;
    this.chantierDetail = false;
    this.piecesAdd = false;
    this.primeList = false
    this.primeEdit = false
    this.primeAdd = false
  }

  showPrimeList() {
    this.mairieList = false;
    this.piecesList = false;
    this.rdvs = false;
    this.financementList = false;
    this.financementComptant = false;
    this.financementEdit = false;
    this.pieceEdit = false;
    this.financementAdd = false;
    this.mairieAdd = false;
    this.mairieEdit = false;
    this.chantierList = false;
    this.chantierDetail = false;
    this.piecesAdd = false;
    this.primeList = true;
    this.primeEdit = false;
    this.primeAdd = false;
  }

  showFinancement() {
    return new Promise(resolve => {

      this.sCases.getCaseById(this.caseId).subscribe(res => {
        this.case = res[0];
        if (this.case.financement_type === "Comptant") {
          this.financementComptant = true;
          this.financementList = false;

        }
        else if (this.case.financement_type === "sofinco" || this.case.financement_type === "cetelem" || this.case.financement_type === "cofidis") {
          this.financementComptant = false;
          this.financementList = true;
        }
        else { }

        this.rdvs = false;
        this.piecesList = false;
        this.piecesAdd = false;
        this.financementEdit = false;
        this.pieceEdit = false;
        this.financementAdd = false;
        this.chantierList = false;
        this.chantierDetail = false;
        this.mairieList = false;
        this.mairieAdd = false;
        this.mairieEdit = false;
        this.primeList = false
        this.primeEdit = false
        this.primeAdd = false
        resolve(res);
          }, error => {
            console.log(error);
        });
      });
  }

  showAddFinancement() {
    this.financementList = false;
    this.financementComptant = false;
    this.rdvs = false;
    this.piecesList = false;
    this.piecesAdd = false;
    this.pieceEdit = false;
    this.financementEdit = false;
    this.financementAdd = true;
    this.chantierList = false;
    this.chantierDetail = false;
    this.mairieList = false;
    this.mairieAdd = false;
    this.mairieEdit = false;
    this.primeList = false
    this.primeEdit = false
    this.primeAdd = false
  }
  showPrimes() {
    this.financementList = false;
    this.financementComptant = false;
    this.rdvs = false;
    this.piecesList = false;
    this.piecesAdd = false;
    this.pieceEdit = false;
    this.financementEdit = false;
    this.financementAdd = false;
    this.chantierList = false;
    this.chantierDetail = false;
    this.mairieList = false;
    this.mairieAdd = false;
    this.mairieEdit = false;
    this.primeList = true;
    this.primeEdit = false
    this.primeAdd = false
  }

  showChantier() {
    this.financementList = false;
    this.financementComptant = false;
    this.rdvs = false;
    this.piecesList = false;
    this.piecesAdd = false;
    this.pieceEdit = false;
    this.financementEdit = false;
    this.financementAdd = false;
    this.chantierList = true;
    this.chantierDetail = false;
    this.mairieList = false;
    this.mairieAdd = false;
    this.mairieEdit = false;
    this.primeList = false
    this.primeEdit = false
    this.primeAdd = false
  }

  showAddMairie() {
    this.mairieAdd = true;
    this.financementList = false;
    this.financementComptant = false;
    this.rdvs = false;
    this.piecesList = false;
    this.piecesAdd = false;
    this.pieceEdit = false;
    this.financementEdit = false;
    this.financementAdd = false;
    this.chantierList = false;
    this.chantierDetail = false
    this.mairieList = false;
    this.mairieEdit = false;
    this.primeList = false
    this.primeEdit = false
    this.primeAdd = false
  }

  showAddPiece() {
    this.mairieAdd = false;
    this.financementList = false;
    this.financementComptant = false;
    this.rdvs = false;
    this.piecesList = false;
    this.piecesAdd = true;
    this.pieceEdit = false;
    this.financementEdit = false;
    this.financementAdd = false;
    this.chantierList = false;
    this.chantierDetail = false
    this.mairieList = false;
    this.mairieEdit = false;
    this.primeList = false
    this.primeEdit = false
    this.primeAdd = false
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(CasePrimeAddComponent, {
      width: '640px',
      disableClose:true,
      data: {
        caseId: this.caseId,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  saveChange(reason,change,value) {
    if(this.case.state == "Annulée" && this.case.datesign === undefined ){
      alert("Vous ne pouvez pas passer cette affaire en annulée")
      this.showCaseInfos()
    }else{
    this.showEditTitle = false;
    if(this.case.state == "Signée"){
      if(this.caseForm.value.datesign === null) {
        this.caseForm.value.datesign = this.actualDate
      }
      this.caseForm.value.qualification = 'qualifié'
      this.caseForm.value.rdv_state.status = 'Oui'

    }else if(this.case.state == "Annulée"){

      this.caseForm.value.datecancel = this.actualDate
      this.caseForm.value.qualification = 'qualifié'
      // Notif commercial + Responsable ADV

      let commercialInfo = {
        comment : 'annulée',
        reason : 'annulée',
        created_by : this.sRoles.user.id,
        created_at : this.actualDate,
        caseId: this.caseId,
        customerId: this.case.customer._id,
        adv: this.case.commercial._id,
      }
      this.sInfos.createInfo(commercialInfo).then(res => {
        if (res['error']) {
          alert(res.error);
        }
      });
      let advInfo = {
        comment : 'annulée',
        reason : 'annulée',
        created_by : this.sRoles.user.id,
        created_at : this.actualDate,
        caseId: this.caseId,
        customerId: this.case.customer._id,
        adv: "5ff22a8ba0f2570004f151d0",
      }
      this.sInfos.createInfo(advInfo).then(res => {
        if (res['error']) {
          alert(res.error);
        }
      });
    }
    else if (this.case.state == "Négociation") {
      this.caseForm.value.qualification = 'qualifié'
    }
    else if (this.case.state == "Non Signée" && this.caseForm.value.rdv_state.status === 'Oui') {
      this.caseForm.value.qualification = 'En attente';
      this.caseForm.value.isHidden = true;
      const task =  {
        message: 'Qualifier le rdv ' + this.case.customer.name.trim(),
        commentary: '',
        state: '1-Urgente',
        date: this.actualDate ,
        created_at : this.actualDate,
        created_by : "5f76e5503926a01790428179",
        caseId: this.caseId,
        customerId: this.case.customer._id,
        // ID de Brice
        target: '5f77416ab0286e4dbc4a9d6f',
        tag: null
      }
       this.sTasks.CreateTask(task).then(res => {
        if (res['error']) {
          alert(res.error);
        }
      });
    }
    else if (this.case.state == "Non Signée" && this.caseForm.value.rdv_state.status === 'Non porte' || this.case.state == "Non Signée" && this.caseForm.value.rdv_state.status === 'Non refus visite' || this.case.state == "Non Signée" && this.caseForm.value.rdv_state.status === '') {
      this.caseForm.value.qualification = 'non qualifié'
      this.caseForm.value.isHidden = true;
    }
    else if (this.caseForm.value.rdv_state.status === 'Non porte' || this.caseForm.value.rdv_state.status === 'Non refus visite' || this.caseForm.value.rdv_state.status === 'Non pas trouvé' || this.caseForm.value.rdv_state.status === 'Non annulé') {
      this.caseForm.value.qualification = 'non qualifié'
      }
    this.sCases.editCase(this.caseForm.value, this.caseId).then(res => {
      if(res['error']) {
        return res.error;
      }
      this.showCaseInfos();
    });
  }
  }

  saveCustomerChange(){
    this.sCustomer.editCustomer(this.case.customer, this.case.customer._id).then(res => {
      if(res['error']) {
        return res.error;
      }
      this.showCaseInfos();
    });
  }
  showTasksByMessage() {
    const message = 'Qualifier le rdv ' + this.case.customer.name.trim()
    this.sTasks.getTaskByMessage(message).subscribe(res => {
    this.task = res[0];
    })
  }

  editStateTask() {
    this.task.state = "3-Faite"
    this.sTasks.editTask(this.task, this.task._id).then(res => {
      if (res['error']) {
        alert(res.error);
      }
    });
  }
  saveQualif() {
    if(this.task != undefined) {
      this.editStateTask()
    }
    this.sCases.editCase(this.case, this.caseId).then(res => {
      if(res['error']) {
        return res.error;
      }
      else {
        this.sb.open(`Qualification effectuée`, '', { duration: 2000, verticalPosition: 'top' });
      }
      this.showCaseInfos();
    });
  }

  toggleEditCustomer(Boolean){
    this.editCustomer = Boolean
  }

  taskGDdExist() {
    this.sTasksGdd.getTaskByCaseId(this.caseId).subscribe(res => {
      const taskExist = res
      taskExist.forEach(task => {
        this.sTasksGdd.deleteTask(task._id)
      });

    })
  }

  getUserCustomerLogin() {
    this.sUsers.getUserByCustomerId(this.case.customer._id).subscribe(res => {
      if(JSON.stringify(res) !='[]') {
        this.customerLog = res[0].customer_login
      }
      else {
        alert('Aucun identifiant trouvé')
      }
    })
  }

  ReinitPassword() {
    if(confirm("Êtes-vous sûr de vouloir reinitialiser le mot de passe du client ?")) {
      this.sUsers.getUserByCustomerId(this.case.customer._id).subscribe(res => {
        if(JSON.stringify(res) !='[]') {
          const pw = Math.random().toString(36).slice(-8)
          res[0].customer_password = pw
          res[0].password = pw

          this.sUsers.updateCustomerPassword(res[0],this.case.customer._id).then(res => {
            this.newUserGeneratedPassword = pw
          })
        }
        else {
          alert('Aucun identifiant trouvé')
        }
      })
    }
  }


  async generateGddTasks(advId) {
    if(this.case.adv === null) {
      let now = new Date();

      let fid = this.case.rdvs.find(o => o.type === 'Fid');

      const tasks_gdd = [
        {
          title: 'Appel de confort',
          state: 'En cours',
          caseId: this.caseId,
          customerId: this.case.customer._id,
          target: advId,
          description: '',
          commentary: [
            {
              title: 'Pas de réponse client',
              selected: false,
            },
            {
              title: 'Messagerie',
              selected: false,
            },
            {
              title: 'Mauvais numéro',
              selected: false,
            },
            {
              title: 'Dde intervention commercial',
              selected: false,
            },
            {
              title: 'Appel effectué',
              selected: false,
            }
          ],
          created_at: now,
          end_date: moment(now, "DD-MM-YYYY").add('days', 1),
          auto: true
        },
        {
          title: 'LCC',
          state: 'En cours',
          caseId: this.caseId,
          customerId: this.case.customer._id,
          target: advId,
          description: '',
                  commentary: [
            {
              title: 'Pas de réponse client',
              selected: false,
            },
            {
              title: 'Client doute',
              selected: false,
            },
            {
              title: 'Validation tarif',
              selected: false,
            },
            {
              title: 'Lettre envoyée',
              selected: false,
            },
          ],
          created_at: now,
          end_date: moment(now, "DD-MM-YYYY").add('days', 2),
          auto: true
        },
        {
          title: 'Relances commerciaux',
          state: 'En cours',
          caseId: this.caseId,
          customerId: this.case.customer._id,
          target: advId,
          description: '',
                  commentary: [
            {
              title: 'VT manquante',
              selected: false,
            },
            {
              title: 'Photos',
              selected: false,
            },
            {
              title: 'Etude de rentabilité',
              selected: false,
            },
            {
              title: 'OP',
              selected: false,
            },
            {
              title: 'RAS',
              selected: false,
            }
          ],
          created_at: now,
          end_date: fid != undefined ? moment(new Date(fid.date), "DD-MM-YYYY").add('days', 1) : moment(now, "DD-MM-YYYY").add('days', 6),
          auto: true
        },
        {
          title: 'Retours Partenaires FI',
          state: 'En cours',
          caseId: this.caseId,
          customerId: this.case.customer._id,
          target: advId,
          description: '',
                  commentary: [
            {
              title: 'Manque documents',
              selected: false,
            },
            {
              title: 'Refus - nouvelle demande',
              selected: false,
            },
            {
              title: 'Attente saisie commercial',
              selected: false,
            },
            {
              title: 'Attente retour organisme',
              selected: false,
            },
            {
              title: 'Financement comptant',
              selected: false,
            },
            {
              title: 'Acceptation',
              selected: false,
            },
          ],
          created_at: now,
          end_date: moment(now, "DD-MM-YYYY").add('days', 5),
          auto: true
        },
        {
          title: 'Date de pose',
          state: 'En cours',
          caseId: this.caseId,
          customerId: this.case.customer._id,
          target: advId,
          description: '',
                  commentary: [
            {
              title: 'Chèque manquant',
              selected: false,
            },
            {
              title: 'Solution FI',
              selected: false,
            },
            {
              title: 'Dossier bancal',
              selected: false,
            },
            {
              title: 'Travaux fixés',
              selected: false,
            },
          ],
          created_at: now,
          end_date: moment(now, "DD-MM-YYYY").add('days', 7),
          auto: true
        },
      ]

      const dp = {
        title: 'DP',
        state: 'En cours',
        caseId: this.caseId,
        customerId: this.case.customer._id,
        target: advId,
        description: '',
                commentary: [
            {
              title: 'Pas de réponse client',
              selected: false,
            },
            {
              title: 'Messagerie',
              selected: false,
            },
            {
              title: 'Mauvais numéro',
              selected: false,
            },
            {
              title: 'Dde intervention commercial',
              selected: false,
            },
            {
              title: 'Envoyée',
              selected: false,
            }
          ],
        created_at: now,
        end_date: moment(now, "DD-MM-YYYY").add('days', 1),
        auto: true
      }

      // Si Panneaux cochés => tache DP
      if(this.currentProducts.find(o => o.name === 'Panneaux solaires')) {
        tasks_gdd.push(dp)
      }

      for (const task of tasks_gdd) {
        this.sTasksGdd.create(task).then(res => {
          if (res['error']) {
            alert(res.error);
          }
        });
      }
    }
    }


}
