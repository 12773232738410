import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { apiUrl} from '@environments/env';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  url = apiUrl.apiUrl + '/user/';
  //url = 'http://localhost:4000/user/'
  headers = { 'Content-Type': 'application/json' };

  currentUser;
  users;
  commerciaux = [];
  advs = [];
  techs = [];
  responsables = [];
  compta = []
  constructor(
    private http: HttpClient,
    private _snackBar:MatSnackBar,

  ) { }

  getCurrentUser() {
    this.currentUser =  localStorage.getItem('currentUser');
  }

  getAllUsers() {
    return this.http.get(this.url + "users");
  }
  getAllUsersWithInactive() {
    return this.http.get(this.url + "users/all");
  }
  getAllTechs() {
    return this.http.get(this.url + "techs");
  }

  getAllTrueCommerciaux() {
    return this.http.get(this.url + "false/commerciaux");
  }

  getAllCommerciaux() {
    return this.http.get(this.url + "commerciaux");
  }

  getAllAdv() {
    return this.http.get(this.url + "adv");
  }

  createUser(UserForm): Promise<any> {
    return this.http
    .post(this.url + 'add', JSON.stringify(UserForm) , { headers: this.headers })
    .toPromise()
    .then(res => res as any);
  }

  createUserClient(UserForm): Promise<any> {
    return this.http
    .post(this.url + 'add/client', JSON.stringify(UserForm) , { headers: this.headers })
    .toPromise()
    .then(res => res as any);
  }

  editUser(UserForm, id): Promise<any> {
    return this.http
    .put(this.url + id, JSON.stringify(UserForm) , { headers: this.headers })
    .toPromise()
    .then(res => res as any);
  }

  getUserById(id) {
    return this.http.get(this.url + id);
  }
  getCommercialByAgency(agency) {
    return this.http.get(this.url + 'commercialagency/' + agency);
  }

  getDAbyCommercialAgency(agency) {
    return this.http.get(this.url + 'agency/' + agency);
  }

  delete(id) {
    return this.http.delete(this.url + id);
  }

  showUserRole() {
    this.commerciaux =[]
    this.advs = []
    this.techs = []
    this.responsables = []
    this.compta = []
    this.getAllUsers().subscribe(res => {
      this.users = res['users'];
      this.users.forEach(element => {

        if (element.role === 'Commercial' || element.role === 'Responsable d\'agence') {
          this.commerciaux.push(element);
        }
        else if (element.role === 'Adv' || element.role === 'Admin') {
          this.advs.push(element);
        }
        else if (element.role === 'Technicien') {
          this.techs.push(element);
        }
        else if (element.role === 'Responsable Technicien') {
          this.responsables.push(element);
        }
        else if (element.role === 'Compta') {
          this.compta.push(element);
        }
      });
      return this.commerciaux;

    });
  }
  showAdvs() {
    this.advs = []
    this.getAllUsers().subscribe(res => {
      this.users = res['users'];
      this.users.forEach(element => {

        if (element.role === 'Adv' || element.role === 'Admin') {
          this.advs.push(element);
        }
      });
      return this.advs;

    });
  }

  disconnectAllUsers() {
    return this.http.get<any>(this.url + 'users/refresh');
  }
  cleanPassword(userId) {
    return this.http.get(this.url + 'generatepw/' + userId);
  }
  getUserByCustomerId(customerId) {
    return this.http.get(this.url + 'checkCustomer/' + customerId);
  }
  updateCustomerPassword(customerForm,customerId): Promise<any> {
    console.log("customerForm",customerForm)
    this._snackBar.open('Voici le nouveau mot de passe',customerForm.password, {
      duration: 10000,
      panelClass: ['add-snackbar']
    });
    return this.http
    .put(this.url + 'changePassword/' + customerId, JSON.stringify(customerForm) , { headers: this.headers })
    .toPromise()
    .then(res => res as any);
  }
}
