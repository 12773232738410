<app-commentbar [caseId]="caseId" *ngIf="caseId"></app-commentbar>
<app-loading *ngIf="case === undefined"></app-loading>
<div *ngIf="case">
  <div class="d-flex mt-4">
    <h2>{{ case.customer.name }} - {{ case.customer.mobile }}</h2>
    <a data-toggle="modal" data-target="#exampleModal" class="show-client"(click)="toggleEditCustomer(false)">Voir les infos client</a>
  </div>
  <h4>{{ case.title }}</h4>
  <a *ngIf="case.adv" class="mr-5">{{ case.adv.username}} Adv</a> <a *ngIf="case.commercial">{{ case.commercial.username}} Commercial</a>

  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div *ngIf="!editCustomer"  class="modal-body">
          <ul>
            <h3>Mr et Mme {{case.customer.name}}</h3>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">X</button>
            <section>
            <h4>Adresse</h4>
            <li>{{ case.customer.address }}</li>
            <li>{{case.customer.cp}} {{ case.customer.city }}</li>
            </section>
            <section>
            <h4>Numéros</h4>
            <li>{{ case.customer.mobile }}</li>
            <li>{{ case.customer.fixe }}</li>
            <li> <a href="mailto:{{case.customer.mail}}">{{case.customer.mail}}</a></li>
            </section>
          </ul>
        </div>
      </div>
    </div>
  </div>



  <form [formGroup]="primeForm" class="col-12">

    <section class="verse-client">
      <mat-checkbox class="example-margin" formControlName="verse_client" (change)="saveChange('edité','versé au client',primeForm.value.verse_client ? 'oui' : 'non')">Versé au client</mat-checkbox>
    </section>

    <div class="row" *ngIf="prime.type === 'mpr'">
      <div class="col-6">
        <h5>N° de dossier</h5>
          <input formControlName="n_dossier" type="text" class="form-control mb-0 mt-1">

      </div>
    </div>
    <div class="choices mr-5 mt-4">
      <p class="m-0">Statut de la demande</p>
      <input type="radio" id="state1" name="state" formControlName="state" value="En cours" (change)="saveChange('edité',primeForm.value.type,primeForm.value.state)">
      <label for="state1" [ngClass]="this.primeForm.value.state === 'En cours' ? 'pe' : 'pe-empty'">En cours</label>

      <input type="radio" id="state3" name="state" formControlName="state" value="Complément" (change)="saveChange('edité',primeForm.value.type,primeForm.value.state)">
      <label for="state3" [ngClass]="this.primeForm.value.state === 'Complément' ? 'pe' : 'pe-empty'">Complément</label>

      <input type="radio" id="state4" name="state" formControlName="state" value="Acceptation" (change)="saveChange('edité',primeForm.value.type,primeForm.value.state)">
      <label for="state4" [ngClass]="this.primeForm.value.state === 'Acceptation' ? 'oui' : 'oui-empty'">Acceptation</label>

      <input type="radio" id="state2" name="state" formControlName="state" value="Refus" (change)="saveChange('edité',primeForm.value.type,primeForm.value.state)">
      <label for="state2" [ngClass]="this.primeForm.value.state === 'Refus' ? 'non' : 'non-empty'">Refus</label>

      <input type="radio" id="state6" name="state" formControlName="state" value="Payé" (change)="saveChange('edité',primeForm.value.type,primeForm.value.state)">
      <label for="state6" [ngClass]="this.primeForm.value.state === 'Payé' ? 'oui' : 'oui-empty'">Payé</label>
    </div>


    <div class="row">
      <div class="col-4">
        <h5>Date de saisie du dossier</h5>
      </div>

      <div class="col-4" *ngIf="this.primeForm.value.state === 'Complément' || this.primeForm.value.date_complement != null">
        <h5>Date d'envoi des compléments</h5>
      </div>

      <div class="col-4" *ngIf="this.primeForm.value.state === 'Payé' || this.primeForm.value.date_virement != null">
        <h5>Date de virement</h5>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <input class="empty-date" formControlName="date_saisie" placeholder="JJ/MM/AAAA xxhxx" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" (ngModelChange)="saveChange('edité','Date de saisie',primeForm.value.date_saisie)">
        <span class="trigger" [owlDateTimeTrigger]="dt1">
          <img src="../../../assets/icons/calendar.svg" class="calendar">
        </span>
        <owl-date-time firstDayOfWeek="1" #dt1></owl-date-time>
      </div>

      <div class="col-4" *ngIf="this.primeForm.value.state === 'Complément' || this.primeForm.value.date_complement != null">
        <input class="empty-date" formControlName="date_complement" placeholder="JJ/MM/AAAA xxhxx" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" (ngModelChange)="saveChange('edité','Date de complément',primeForm.value.date_complement)">
        <span class="trigger" [owlDateTimeTrigger]="dt2">
          <img src="../../../assets/icons/calendar.svg" class="calendar">
        </span>
        <owl-date-time firstDayOfWeek="1" #dt2></owl-date-time>
      </div>

      <div class="col-4" *ngIf="this.primeForm.value.state === 'Payé' || this.primeForm.value.date_virement != null">
        <input class="empty-date" formControlName="date_virement" placeholder="JJ/MM/AAAA xxhxx" [owlDateTime]="dt3" [owlDateTimeTrigger]="dt3" (ngModelChange)="saveChange('edité','Date de virement',primeForm.value.date_virement)">
        <span class="trigger" [owlDateTimeTrigger]="dt3">
          <img src="../../../assets/icons/calendar.svg" class="calendar">
        </span>
        <owl-date-time firstDayOfWeek="1" #dt3></owl-date-time>
      </div>
    </div>

  <div class="row mt-5">
    <div class="col-6" style="display: grid;">
      <h5 style="margin-bottom: -4%;">Le dossier est-il envoyé ?</h5>

      <mat-select style="width: 16%;" [ngClass]="this.primeForm.value.envoi === true ? 'Signée' : 'Annulée'" class="custom-select-state rdv" formControlName="envoi" (selectionChange)="saveChange('edité','dossier envoyé ?',prime.envoi)">
        <mat-option [value]=true>Oui</mat-option>
        <mat-option [value]=false>Non</mat-option>
      </mat-select>
    </div>

    <div class="col-6" style="display: grid;">
      <h5 *ngIf="prime.type === 'cee'">Date de l'appel à facturation</h5>
      <h5 *ngIf="prime.type === 'mpr'">Notification de versement</h5>
      <div>
        <input class="empty-date" formControlName="date_appel" placeholder="JJ/MM/AAAA xxhxx" [owlDateTime]="dt4" [owlDateTimeTrigger]="dt4" (ngModelChange)="saveChange('edité','date d\'appel à facturation','prime.date_appel')">
        <span class="trigger" [owlDateTimeTrigger]="dt4">
          <img src="../../../assets/icons/calendar.svg" class="calendar">
        </span>
        <owl-date-time firstDayOfWeek="1" #dt4></owl-date-time>
      </div>
      <input [ngClass]="{'disabled': prime.type === 'mpr'}" formControlName="n_appel" type="text" class="form-control appel" placeholder="N° d'appel à facturation" (change)="saveChange('edité','n° d\'appel à facturation',prime.n_appel)">
    </div>

  </div>

  <div class="row mt-5">
    <div class="col-6" style="display: grid;">
      <h5>Date d'envoi du dossier</h5>
      <div>
        <input class="empty-date" formControlName="date_envoi" placeholder="JJ/MM/AAAA xxhxx" [owlDateTime]="dt3" [owlDateTimeTrigger]="dt3" (ngModelChange)="saveChange('edité','date d\'envoi du dossier prime','prime.date_envoi')">
        <span class="trigger" [owlDateTimeTrigger]="dt3">
          <img src="../../../assets/icons/calendar.svg" class="calendar">
        </span>
        <owl-date-time firstDayOfWeek="1" #dt3></owl-date-time>
      </div>

      <input formControlName="montant" type="number" class="form-control" placeholder="Montant" (change)="saveChange('edité',primeForm.value.type,primeForm.value.state)">
    </div>
    <div class="col-6" style="display: inline-table;">
      <div style="display: flex; flex-direction: column;" >

        <div class="col-xs-12 col-lg-6 col-sm-3" style="padding: 0;" *ngIf="bill">
            <div class="facture-card">
              <img class="rm-facture" src="../../../assets/icons/delete.svg" (click)="killBill(bill._id,'supprimé','pièce',bill.title )">
              <h3>Facture {{ case.customer.name }}</h3>
              <p>Ajouté par Comptabilité</p>

            </div>
        </div>

      </div>
      <div *ngIf="!bill && prime.type === 'cee'">
        <div class="col-xs-12 col-lg-6 col-sm-3 btn-secondarycrm button-wrapper upload-bill" >
          <input accept=".jpg,.jpeg,.png,.pdf" multiple type="file" name="upload" id="upload" class="upload-box" (change)="selectMultiple($event)">
          Ajouter la facture
        </div>

      </div>
      <a href="https://api-crm.s3.eu-west-3.amazonaws.com/{{bill.caseId}}/pieces/{{billImg}}" class="col-xs-12 col-lg-6 col-sm-3 btn-secondarycrm button-wrapper upload-bill" style="z-index: 999999;" *ngIf="bill">
        Voir la facture
      </a>
    </div>
  </div>

  </form>
</div>
