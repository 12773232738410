import { Component} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { PdfsService } from '@app/services/pdfs.service';
import { ModalPrevisualizeComponent } from '../modal-previsualize/modal-previsualize.component';

@Component({
  selector: 'app-bi-visualize',
  templateUrl: './bi-visualize.component.html',
  styleUrls: ['./bi-visualize.component.scss']
})
export class BiVisualizeComponent {
  kit;
  case;
  caseId;

  currentKit;
  chantierId;
  showPdf = false;
  createDoc
  kitFilter;
  loader = false

  selectedProducts: string[] = [];


  constructor(
    private sCases: CasesService,
    private route: ActivatedRoute,
    private sPdfs: PdfsService,
    private router : Router,
    private sb: MatSnackBar,
    private dialog:MatDialog,
  ) {
    this.caseId = this.route.snapshot.params['id'];

    if(this.currentKit = this.router.getCurrentNavigation().extras.state != undefined) {
      this.chantierId = this.router.getCurrentNavigation().extras.state.chantierId
    }
    else {
      this.chantierId = JSON.parse(localStorage.getItem('chantierId'))
    }


    this.sCases.getCaseById(this.caseId).subscribe(res => {
      this.case = res[0]
      this.kit = JSON.parse(this.case.kits_vendus).filter(e => e.active && e.valid)

      this.kit = this.kit.filter(product => 
        product.name === 'Ballon thermodynamique' ||
        product.name === 'Panneaux solaires' ||
        product.name === 'PAC Air/Air' ||
        product.name === 'VMC Double Flux' ||
        product.name === 'PAC Air/Eau')
    })

    
  }

  onSelectionChange(event): void {
    this.selectedProducts = [];
    event.value.forEach(product => {
      this.selectedProducts.push(product);
    });
  }

  getCurrentKitAndSaveChange(event,key,currentKit) {
    currentKit[key] = event;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === currentKit.type);

    array[index] = currentKit

    this.case.kits_vendus = JSON.stringify(array)

    this.sCases.editCase(this.case, this.case._id)
  }



  async valid() {
    this.loader = true
    this.createDoc = await this.generateDoc();
    this.loader = false
    this.openDialog()
  }


  async generateDoc() {
    let response = await this.sPdfs.generateBi('bi',this.case,this.selectedProducts,"","",this.chantierId._id).then(res => {
      if(res === 'ok') {
        console.log(res)
      }

    })
    return response;
  }

  openDialog(): void {
    setTimeout(() => {
      const dialogRef = this.dialog.open(ModalPrevisualizeComponent, {
        width: '100vh',
        height:  '100vh',
        maxWidth: '100vh',
        maxHeight: '100vh',
        hasBackdrop: false,
        disableClose: true,
        panelClass: 'pdf-visualize',
        data: {
          titlePiece: 'bi',
          caseId: this.case._id,
          case: this.case,
          kit: this.selectedProducts,
          chantierId: this.chantierId
        }
      });
      dialogRef.afterClosed().subscribe(result => {

      });
    }, 1000);

  }

}
