import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { ChantierService } from '@app/services/chantier.service'
import { UsersService } from '@app/services/users.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ChantierAddComponent } from '../chantier-add/chantier-add.component';
import { HistoriqueService } from '@app/services/historique.service';
import { RolesService } from '@app/services/roles.service';

@Component({
  selector: 'app-chantier-list',
  templateUrl: './chantier-list.component.html',
  styleUrls: ['./chantier-list.component.scss']
})


export class ChantierListComponent implements OnInit {

  Chantier = {}
  caseId;
  chantiers;
  allchantiers;
  showValidation = false;
  case;
  date;
  tabs = ['Liste Chantier'];
  allTechniciens
  selected = new FormControl(0);
  validTech: string[] = [];
  isfrmChecked: any;
  showCross;


  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private sChantier: ChantierService,
    private sCase: CasesService,
    private sUser: UsersService,
    private dialog: MatDialog,
    private router: Router,
    private sHistoriques: HistoriqueService,
    private sRoles: RolesService,
  ) { }

  ngOnInit(): void {
    this.caseId = this.route.snapshot.params['id'];
    this.getCase()
    this.showAllCaseChantier();
    this.getAllTech()
  }

  showAllCaseChantier() {
    this.sChantier.getChantierByCaseId(this.caseId).subscribe(res => {
      this.chantiers = res
    })
  }

  getAllTech() {
    this.sUser.getAllTechs().subscribe(res => {
      this.allTechniciens = res['users']

    })
  }

  getCase() {
    this.sCase.getCaseById(this.caseId).subscribe(res => {
      this.case = res[0]
    })
  }

  showValidateChantier() {
    this.showValidation = !this.showValidation
  }
  validationChantier() {

    this.Chantier = {
      date: this.date,
      caseId: this.caseId,
      statut: "En cours",
      tech: this.validTech,
    };
    this.sChantier.createChantier(this.Chantier).then(res => {
      this.showAllCaseChantier()
      this.showValidation = false
    })

  }

  deleteChantier(id, reason, change, value) {
    if (confirm("Confirmer la suppression des travaux?")) {
      this.sCase.supprItemArray(this.chantiers, id)
      this.sChantier.delete(id).subscribe(res => {
        this.showAllCaseChantier();
      });
      this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change, value, this.sRoles.user.id).then(res => {
        if (res['error']) {
          alert(res);
        }
      });

    }
  }


  addTab(id) {
    this.tabs.push(id);
    this.selected.setValue(this.tabs.length - 1);
  }

  removeTab(index: number) {
    this.tabs.splice(index, 1);
  }


  componentMethodName(event: any, isChecked: boolean) {
    if (isChecked) {
      this.validTech.push(event.target.value)
    }
    else {
      let index = this.validTech.indexOf(event.target.value);
      this.validTech.splice(index, 1);
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ChantierAddComponent, {
      width: '640px',
      data: {
        caseId: this.caseId,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.showAllCaseChantier();
    });
  }

  goToEditChantier(chantierId) {
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['case-template', this.caseId], { state: { value: 'chantierDetail', chantierId: chantierId } });
  }

  showEdit() {
    this.showCross = !this.showCross;
  }


}
