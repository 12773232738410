import { CasesService } from './../../services/cases.service';
import { StatService } from './../../services/stat.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { RolesService } from '@app/services/roles.service';


@Component({
  selector: 'app-admin-user-stat',
  templateUrl: './admin-user-stat.component.html',
  styleUrls: ['./admin-user-stat.component.scss']
})

export class AdminUserStatComponent implements OnInit {

  commercialId
  cases
  commercialCases = [[],[],[],[],[],[],[],[],[],[],[],[]]
  goalCaseByMonth = [0,0,0,0,0,0,0,0,0,0,0,0]
  month = new Date
  actualMonth
  actualYear
  commercial
  commerciaux = []

  actualDate = new Date
  actualDay
  week = 1
  color =""

  lowLimit = 700
  highLimit = 2000
  goal = 4000
  pourcentage = 0
  months = 12
  states = [
    {name:"Signée",ca:"CA Net", active: false},
    {name:"Annulée",ca:"CA Brut", active: false},
  ]
  filter= {
    state:"all",
    title:""
  }
  constructor(
    private route: ActivatedRoute,
    private sStat : StatService,
    private sCases : CasesService,
    private _location: Location,
    private router: Router,
    private sRoles: RolesService
    ) {
      this.actualMonth = this.month.getMonth()
      this.actualYear = this.month.getFullYear()
      this.actualDay = this.actualDate.getDate()
  }

  ngOnInit(): void {

    this.commercialId = this.route.snapshot.params['id'];
    this.sStat
    this.getCommercial()
    this.sCases.getCaseByCancelState().subscribe(res =>{
      this.cases = res['cases']

      this.showAllCasesByCustomerId()
    })
  }

  showAllCasesByCustomerId(){

    this.cases.forEach(element => {
      let i = 0
      while (i< this.months) {
        i++
        if(element.datesign != null) {
          if(i ==  element.datesign.substr(5,2) && new Date(element.datesign).getFullYear() === this.actualYear){
            break
          }
        }
      }
      let m = i-1
      if(element.commercial != undefined){
        if(element.commercial._id == this.commercialId){
          if(element.datesign.substr(8,2)<= this.actualDay && new Date(element.datesign).getFullYear() === this.actualYear){

            this.goalCaseByMonth[m] += element.price_ht
            this.commercialCases[m].push(element)
          }
      }
    }
    });

  }
   getCommercial() {
     setTimeout(() => {
       this.sStat.getCommercialById(this.commercialId)
       this.commercial = this.sStat.chosenCommercial
       this.setLimitDay()
     }, 2500);
  }


   setLimitDay(){
      this.lowLimit =  this.lowLimit * this.actualDay
      this.highLimit =  this.highLimit * this.actualDay
      this.goal = this.goal* this.actualDay

      if( this.goalCaseByMonth[this.actualMonth]< this.lowLimit ){
        this.color = "#F45B69"
      }else if( this.goalCaseByMonth[this.actualMonth]>=this.lowLimit &&  this.goalCaseByMonth[this.actualMonth]<this.highLimit ){
        this.color ="#F0C808"
      }else{
        this.color="#78C091"
      }

      this.pourcentage = this.goalCaseByMonth[this.actualMonth]/this.goal*100

      this.pourcentage = Math.round(this.pourcentage)

  }
  goBack(): void {
    this._location.back();
  }

  goToCase(caseId) {
    if(this.sRoles.userRole != 'Admin') {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/commercial-template/${caseId}`])
        );
        window.open(url, '_blank');
    }
    else {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/case-template/${caseId}`])
        );
        window.open(url, '_blank');
    }

  }

}
