import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/_services';
import { Role } from '@app/_models';

@Component({
  selector: 'app-favorite',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.scss']
})
export class FavoriteComponent implements OnInit {
  mobile
  showBar = false
  user

  constructor(private authenticationService : AuthenticationService) {
    this.authenticationService.user.subscribe(x => this.user = x);
    if (window.screen.width <= 600) {
      this.mobile = true;
    }
   }

  ngOnInit(): void {
  }
  get isAdmin() {
    return this.user && this.user.role === Role.Admin;
  }
  get isAdv() {
    return this.user && this.user.role === Role.Adv;
  }
  showComment(){
    this.showBar = !this.showBar
  }
}
