import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ModalPrevisualizeComponent } from '@app/pv/modal-previsualize/modal-previsualize.component';
import { NoModalComponent } from '@app/pv/no-modal/no-modal.component';
import { CasesService } from '@app/services/cases.service';
import { PdfsService } from '@app/services/pdfs.service';
import { PiecesService } from '@app/services/pieces.service';

@Component({
  selector: 'app-recette-pacaa-gainable',
  templateUrl: './recette-pacaa-gainable.component.html',
  styleUrls: ['./recette-pacaa-gainable.component.scss']
})
export class RecettePacaaGainableComponent {

  @Input() currentKit;
  @Input() case;
  @Input() chantierId
  @Input()
  public data: any

  @Output()
  public onData: EventEmitter<any> = new EventEmitter<any>();

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourFormGroup: FormGroup;
  fiveFormGroup: FormGroup;


  tabs_bouches = []
  poses = ["au sol","sur chassis"]
  selected = new FormControl(0);

  bouche_insuflation_selected;
  bouche_reprise_selected;

  _step: number = 1;

  pieces

  tuyau
  condensat
  ue
  reseau_comble
  ui
  disjoncteur
  multipleImagesPieces
  mobile = false

  isValid
  createDoc
  loader
  @Input()

  piecesNserie
  piecesNserieUi

  piece_serie_pacaa_ue
  piece_serie_pacaa_ui

  n_serie_pacaa_ue
  n_serie_pacaa_ui



  set step(_step: number) {
    this._step = this.parseNumber(_step);
  }
  private parseNumber(num: any): number {
    return +num;
  }
  ngAfterContentInit() {
    this.checkPiece()
    this.checkPieceNSerie()
    this.checkPieceNSerieUi()

    this.updateData(20)
    this.bouche_insuflation_selected = this.currentKit.bouches_insuflation[0]
    this.bouche_reprise_selected = this.currentKit.bouches_reprise[0]

    this.firstFormGroup = this._formBuilder.group({
      marque_type_ue: [this.currentKit.marque_type_ue, Validators.required],
      type_pose_ue: [this.currentKit.type_pose_ue, Validators.required],
      dissipation_respecte: [this.currentKit.dissipation_respecte, Validators.required],
      silent_bloc: [this.currentKit.silent_bloc, Validators.required],
      norme_fixation: [this.currentKit.norme_fixation, Validators.required],
      masque_entree_sortie: [this.currentKit.masque_entree_sortie, Validators.required],
      ref_fluide_poids: [this.currentKit.ref_fluide_poids, Validators.required],
      puissance_chaud: [this.currentKit.puissance_chaud, Validators.required],
      puissance_froid: [this.currentKit.puissance_froid, Validators.required],
      mode_froid: [this.currentKit.mode_froid, Validators.required],
      mode_chaud: [this.currentKit.mode_chaud, Validators.required],
      cablage_conforme: [this.currentKit.cablage_conforme, Validators.required],

    });
    this.tabs_bouches = this.currentKit.bouches_insuflation
    this.secondFormGroup = this._formBuilder.group({
        marque_type_ui: [this.currentKit.marque_type_ui, Validators.required],
        local_pose: [this.currentKit.local_pose, Validators.required],
        install_suspension: [this.currentKit.install_suspension, Validators.required],
        install_horizontale: [this.currentKit.install_horizontale, Validators.required],
        acces_comble: [this.currentKit.acces_comble, Validators.required],
        evac_condensat: [this.currentKit.evac_condensat, Validators.required],
        fixation_gaine: [this.currentKit.fixation_gaine, Validators.required],

    });
    this.thirdFormGroup = this._formBuilder.group({
      liaison_frigo: [this.currentKit.liaison_frigo, Validators.required],
      denivele_liaison_frigo: [this.currentKit.denivele_liaison_frigo, Validators.required],
      local_telecommande: [this.currentKit.local_telecommande, Validators.required],
      tirage_liaison_frigo: [this.currentKit.tirage_liaison_frigo, Validators.required],
      mise_oeuvre: [this.currentKit.mise_oeuvre, Validators.required],
      etancheite_azote: [this.currentKit.etancheite_azote, Validators.required],
      nettoyage: [this.currentKit.nettoyage, Validators.required],
      etancheite_ue: [this.currentKit.etancheite_ue, Validators.required],
      mise_en_service: [this.currentKit.mise_en_service, Validators.required],
      disjoncteur_pac: [this.currentKit.disjoncteur_pac, Validators.required],
      telecommande: [this.currentKit.telecommande, Validators.required],
      documentation: [this.currentKit.documentation, Validators.required],
      etancheite_detecteur: [this.currentKit.etancheite_detecteur, Validators.required],
    });
    this.fourFormGroup = this._formBuilder.group({
      prog_sonde: [this.currentKit.prog_sonde, Validators.required],
      local_pose: [this.currentKit.local_pose, Validators.required],
      volet: [this.currentKit.volet, Validators.required],
      temp_chaud: [this.currentKit.temp_chaud, Validators.required],
      temp_froid: [this.currentKit.temp_froid, Validators.required],
      temp_chaud_atteint: [this.currentKit.temp_chaud_atteint, Validators.required],
      temp_froid_atteint: [this.currentKit.temp_froid_atteint, Validators.required],
    });

  }
  constructor(
    private _formBuilder: FormBuilder,
    private sCases: CasesService,
    private dialog: MatDialog,
    private sPdfs: PdfsService,
    private sPieces: PiecesService) {
      if (window.screen.width <= 600) {
        this.mobile = true;
      }
     }



  updateData(data) {
    this.onData.emit(data)
  }

  getCurrentKitAndSaveChange(event,key) {

    this.currentKit[key] = event;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === this.currentKit.type);

    array[index] = this.currentKit

    this.case.kits_vendus = JSON.stringify(array)

    this.sCases.editCase(this.case, this.case._id).then(res => console.log(res))
  }

  tabClick(event) {
    const current = this.currentKit.bouches_insuflation.filter(b => b.name === event.tab.textLabel)
    this.bouche_insuflation_selected = current[0]
    this.fourFormGroup.get('local_pose').setValue(this.bouche_insuflation_selected.local_pose)
    this.fourFormGroup.get('temp_chaud').setValue(this.bouche_insuflation_selected.temp_chaud)
    this.fourFormGroup.get('temp_froid').setValue(this.bouche_insuflation_selected.temp_froid)
    this.fourFormGroup.get('volet').setValue(this.bouche_insuflation_selected.volet)
    this.fourFormGroup.get('temp_chaud_atteint').setValue(this.bouche_insuflation_selected.temp_chaud_atteint)
    this.fourFormGroup.get('temp_froid_atteint').setValue(this.bouche_insuflation_selected.temp_froid_atteint)
  }

  editSelectedBoucheSoufflage(event, key) {

    this.bouche_insuflation_selected[key] = event;
    this.currentKit[key] = event;

    const array = JSON.parse(this.case.kits_vendus);

    const index = array.findIndex(item => item.name === this.currentKit.type);

    //array[index] = this.currentKit
    array[index].nbr_bouche_insuflation.find(x => x.name === this.bouche_insuflation_selected.name);
    let curr = array[index].nbr_bouche_insuflation.find(x => x.name === this.bouche_insuflation_selected.name)
    curr[key] = event
    this.case.kits_vendus = JSON.stringify(array)
    this.sCases.editCase(this.case, this.case._id).then(res => console.log(res))
  }

  incrementValueObturateur(step: number = 1,name,reason, option,product): void {
    this.currentKit['obturateur'] = this.currentKit['obturateur'] + step
    const newValue = this.currentKit['obturateur']
    this.addOption(name,reason, option,product, newValue)
  }

  addOption(name,reason, option,product, value) {

    let find = JSON.parse(this.case.kits_vendus).find(x => x.name === name);
    find[option] = value;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === name);
    const selected = array[index]
    this.currentKit = selected
    selected[option] = value

    this.case.kits_vendus = JSON.stringify(array)

    this.sCases.editCase(this.case, this.case._id)

  }

  openDialog(value): void {
    const dialogRef = this.dialog.open(NoModalComponent, {
      width: '640px',
      disableClose: true,
      data: {
        currentKit: this.currentKit,
        case: this.case,
        value: value
      }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  editSelectedInsuflation(event, key) {

    this.bouche_insuflation_selected[key] = event;

    this.currentKit[key] = event;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === this.currentKit.type);

    array[index].bouches_insuflation.find(x => x.name === this.bouche_insuflation_selected.name);
    let curr = array[index].bouches_insuflation.find(x => x.name === this.bouche_insuflation_selected.name)
    curr[key] = event

    this.case.kits_vendus = JSON.stringify(array)
    this.sCases.editCase(this.case, this.case._id).then(res => console.log(res))
  }

  editSelectedReprise(event, key) {

    this.bouche_reprise_selected[key] = event;
    this.currentKit[key] = event;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === this.currentKit.type);

    array[index].bouches_reprise.find(x => x.name === this.bouche_reprise_selected.name);
    let curr = array[index].bouches_reprise.find(x => x.name === this.bouche_reprise_selected.name)
    curr[key] = event

    this.case.kits_vendus = JSON.stringify(array)
    this.sCases.editCase(this.case, this.case._id).then(res => console.log(res))
  }

  test(bouche_name,value) {

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === this.currentKit.type);

    array[index].bouches_reprise.find(x => x.name === bouche_name);
    let curr = array[index].bouches_reprise.find(x => x.name === bouche_name)
    curr['local_pose'] = value
    this.case.kits_vendus = JSON.stringify(array)
    this.sCases.editCase(this.case, this.case._id).then(res => console.log(res))

  }

  selectMultiple(event, title) {
    if (event.target.files.length > 0) {
      this.multipleImagesPieces = event.target.files;
    }
    this.onSubmit(title)
  }

  onSubmit(title) {
    const form = {
      title: title,
      qualification: 'Conforme',
      caseId: this.case._id,
      created_at: new Date(),
      type: 'technicien',
      chantier: this.chantierId,
      cible: 'recette_pacaa',
      description: '',
    }
    this.sPieces.addTechPiece(form, this.multipleImagesPieces, this.case._id, this.chantierId._id)
     setTimeout(() => {
      this.checkPiece()
    }, 1100);
  }

  checkPiece() {
    this.sPieces.getPiecesByChantierIdAndCible(this.chantierId._id, 'recette_pacaa').subscribe(res => {
      this.pieces= res
      if(this.pieces.find(e => e.title === 'Tuyau et calorifugeage'))
        this.tuyau = true
      if(this.pieces.find(e => e.title === 'Sortie des condensats'))
        this.condensat = true
      if(this.pieces.find(e => e.title === 'Photo unité extérieure'))
        this.ue = true
      if(this.pieces.find(e => e.title === 'Réseau comble aéroliques'))
        this.reseau_comble = true
      if(this.pieces.find(e => e.title === 'Photo unité intérieure'))
        this.ui = true
      if(this.pieces.find(e => e.title === 'Disjoncteur'))
        this.disjoncteur = true
    })

  }

  async valid() {
    this.isValid = true
    this.loader = true
    this.createDoc = await this.generateDoc();
    this.loader = false
    this.openDialogPrevisualize()
  }

  async generateDoc() {
    let response = await this.sPdfs.generateRecette(this.chantierId,'recette_pacaa_gainable',this.case,this.currentKit,"","").then(res => {
      if(res === 'ok') {
        console.log(res)
      }
    })
    return response;
  }

  openDialogPrevisualize(): void {
    setTimeout(() => {
      const dialogRef = this.dialog.open(ModalPrevisualizeComponent, {
        width: '100vh',
        height:  '100vh',
        maxWidth: '100vh',
        maxHeight: '100vh',
        hasBackdrop: false,
        disableClose: true,
        panelClass: 'pdf-visualize',
        data: {
          titlePiece: 'recette_pacaa_gainable',
          caseId: this.case._id,
          case: this.case,
          kit: this.currentKit,
          chantierId: this.chantierId
        }
      });
      dialogRef.afterClosed().subscribe(result => {

      });
    }, 2000);

  }

  checkPieceNSerie() {
    this.sPieces.getPiecesByChantierIdAndCible(this.chantierId._id, 'n_serie_pacaa_ue').subscribe(res => {
      this.piecesNserie= res
      if(this.piecesNserie.find(e => e.title === 'N° de serie UE PAC Air Air') && this.currentKit.type === 'PAC Air/Air') {

        this.piece_serie_pacaa_ue = this.piecesNserie.filter(e => e.title === 'N° de serie UE PAC Air Air')
        this.n_serie_pacaa_ue = true
      }
      if(this.piecesNserie.find(e => e.title === 'N° de serie UI PAC Air Air') && this.currentKit.type === 'PAC Air/Air') {

        this.piece_serie_pacaa_ui = this.piecesNserie.filter(e => e.title === 'N° de serie UI PAC Air Air')
        this.n_serie_pacaa_ui = true
      }
    })
  }
    checkPieceNSerieUi() {
    this.sPieces.getPiecesByChantierIdAndCible(this.chantierId._id, 'n_serie_pacaa_ui').subscribe(res => {
      this.piecesNserieUi= res
      if(this.piecesNserieUi.find(e => e.title === 'N° de serie UI PAC Air Air') && this.currentKit.type === 'PAC Air/Air') {

        this.piece_serie_pacaa_ui = this.piecesNserieUi.filter(e => e.title === 'N° de serie UI PAC Air Air')
        this.n_serie_pacaa_ui = true
      }
    })
  }

  selectMultipleNserie(event, title:string, n_serie:string) {
    if (event.target.files.length > 0) {
      this.multipleImagesPieces = event.target.files;
    }
    this.onSubmitNserie(title, n_serie)
  }

  onSubmitNserie(title:string,n_serie:string) {
    const form = {
      title: title,
      qualification: 'Conforme',
      caseId: this.case._id,
      created_at: new Date(),
      type: 'technicien',
      chantier: this.chantierId,
      cible: n_serie,
      description: '',
    }
    this.sPieces.addTechPiece(form, this.multipleImagesPieces, this.case._id, this.chantierId._id)
    setTimeout(() => {
      this.checkPieceNSerie()
      this.checkPieceNSerieUi()
    }, 1100);
  }

}
