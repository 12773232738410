<form [formGroup]="financementForm" *ngIf="currentFinancement">

  <div class="row mt-4 mb-4">
    <div class="col-4">
      <h6>Type d'opération financière</h6>
      <div class="paiement" *ngIf="currentFinancement">
        {{currentFinancement.type_op }}
      </div>
    </div>

    <div class="col-4">
      <h6>Nom du partenaire financier</h6>
      <div class="paiement" *ngIf="currentFinancement">
        {{currentFinancement.partenaire }}
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-5">
      <h6 class="my-4">Statut de la demande financière</h6>

  <mat-select
  [ngClass]="[
  this.financementForm.value.state === 'Acceptation' || this.financementForm.value.state === 'Acompte' || this.financementForm.value.state === 'Payé' ? 'oui' : '',
  this.financementForm.value.state === 'En cours' ? 'pe' : '',
  this.financementForm.value.state === 'Complément' ? 'pe' : '',
  this.financementForm.value.state === 'Refus' ? 'non' : ''
  ]" class="custom-select-state rdv" formControlName="state" value="currenFinancement.state" (selectionChange)="saveChange('edité',currentFinancement.partenaire,this.financementForm.value.state)">
    <mat-option *ngFor="let option of options" [value]="option">
      {{option}}
    </mat-option>
  </mat-select>
  </div>

  <div class="col-5">
    <div class="choices pl-0 col-12">

      <h6 class="my-4">Sepa conforme</h6>
      <input type="radio" id="sepa1" name="sepa" formControlName="sepa" [(ngModel)]="currentFinancement.sepa" [value]=true (change)="saveChange('edité',currentFinancement.partenaire,this.financementForm.value.sepa === true ? 'sepa conforme oui' : 'sepa conforme non')">
      <label for="sepa1" class="deblocage" [ngClass]="this.financementForm.value.sepa ? 'oui' : 'oui-empty'">
        <span class="material-icons">
        done
        </span>OUI</label>

        <input type="radio" id="sepa2" name="sepa" formControlName="sepa" [(ngModel)]="currentFinancement.sepa" [value]=false (change)="saveChange('edité',currentFinancement.partenaire,this.financementForm.value.sepa === true ? 'sepa conforme oui' : 'sepa conforme non')">
        <label for="sepa2" class="deblocage" [ngClass]="!this.financementForm.value.sepa ? 'non' : 'non-empty'">
        <span class="material-icons">
          clear
          </span>NON</label>
    </div>
    </div>
</div>


<div class="row">
  <div class="col-5 mt-3">
  <h6>Date du dépôt de dossier</h6>
  <p *ngIf="currentFinancement">{{ currentFinancement.date_depot | date:'medium' }}</p>
  </div>

</div>

  <div class="row mt-5">
    <div class="choices col-4">
      <h6 class="m-0">Demande de déblocage</h6>
      <input type="radio" id="deblocage1" name="deblocage" formControlName="deblocage" [(ngModel)]="currentFinancement.deblocage" [value]=true (change)="saveChange('edité',currentFinancement.partenaire,'Demande de déblocage oui')">
      <label class="sepa" for="deblocage1" [ngClass]="this.financementForm.value.deblocage ? 'oui' : 'oui-empty'">
        <span class="material-icons">
        done
        </span>OUI</label>

        <input type="radio" id="deblocage2" name="deblocage" formControlName="deblocage" [(ngModel)]="currentFinancement.deblocage" [value]=false (change)="saveChange('edité',currentFinancement.partenaire,'Demande de déblocage non')">
        <label class="sepa" for="deblocage2" [ngClass]="!this.financementForm.value.deblocage ? 'non' : 'non-empty'">
        <span class="material-icons">
          clear
          </span>NON</label>
    </div>

    <div class="col-4" *ngIf="this.financementForm.value.deblocage">
      <h6>Acompte partenaire FI</h6>
        <mat-select
          [ngClass]="[this.financementForm.value.acompte ? 'oui' : 'non']"
          class="custom-select-state rdv" formControlName="acompte" value="currenFinancement.acompte" (selectionChange)="saveChange('edité',currentFinancement.partenaire,this.financementForm.value.acompte != '' ? 'Acompte partenaire FI oui' : 'Acomptez partenaire FI non')">
          <mat-option [value]=true>Oui</mat-option>
          <mat-option [value]=false>Non</mat-option>
        </mat-select>
    </div>

    <div class="col-4" *ngIf="this.financementForm.value.deblocage">
      <h6>Solde</h6>
      <mat-select
        [ngClass]="[this.financementForm.value.solde ? 'oui' : 'non']"
        class="custom-select-state rdv" formControlName="solde" value="currenFinancement.solde" (selectionChange)="saveChange('edité',currentFinancement.partenaire,this.financementForm.value.solde != '' ? 'Solde oui' : 'Solde non')">
        <mat-option [value]=true>Oui</mat-option>
        <mat-option [value]=false>Non</mat-option>
      </mat-select>
    </div>
  </div>

  <div class="row">
    <div *ngIf="this.financementForm.value.deblocage" class="col-4">
      <h6>Date de la demande</h6>
      <input class="custom-date" placeholder="JJ/MM/AAAA xxhxx" formControlName="date_deblocage" [(ngModel)]="currentFinancement.date_deblocage" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" (ngModelChange)="saveChange('edité date de déblocage',currentFinancement.partenaire,this.financementForm.value.date_deblocage)">
      <owl-date-time firstDayOfWeek="1" #dt1></owl-date-time>
    </div>

    <div *ngIf="this.financementForm.value.deblocage && this.financementForm.value.acompte" class="col-4">
      <h6>Date de l'acompte</h6>
      <div class="montant">
        <input class="custom-date" placeholder="JJ/MM/AAAA xxhxx" formControlName="date_acompte" [(ngModel)]="currentFinancement.date_acompte" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" (ngModelChange)="saveChange('edité la date de l\'acompte',currentFinancement.partenaire,this.financementForm.value.date_acompte)">
        <owl-date-time firstDayOfWeek="1" #dt2></owl-date-time>
        <mat-form-field class="example-full-width">
          <mat-label>Montant</mat-label>
          <input matInput formControlName="montant_acompte" [(ngModel)]="currentFinancement.montant_acompte" (change)="saveChange('edité le montant de l\acompte',currentFinancement.partenaire,this.financementForm.value.montant_acompte)">
        </mat-form-field>
      </div>

    </div>
    <div *ngIf="this.financementForm.value.deblocage && !this.financementForm.value.acompte" class="col-4"></div>

    <div *ngIf="this.financementForm.value.deblocage && this.financementForm.value.solde" class="col-4">
      <h6>Date du solde</h6>
      <div class="montant">
        <input class="custom-date" placeholder="JJ/MM/AAAA xxhxx" formControlName="date_solde" [(ngModel)]="currentFinancement.date_solde" [owlDateTime]="dt3" [owlDateTimeTrigger]="dt3" (ngModelChange)="saveChange('edité la date du solde',currentFinancement.partenaire,this.financementForm.value.date_acompte)">
        <owl-date-time firstDayOfWeek="1" #dt3></owl-date-time>
        <mat-form-field class="example-full-width">
          <mat-label>Montant</mat-label>
          <input matInput formControlName="montant_solde" [(ngModel)]="currentFinancement.montant_solde" (change)="saveChange('edité','Montant solde',this.financementForm.value.montant_solde)">
        </mat-form-field>
      </div>
    </div>
  </div>
</form>

