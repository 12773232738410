<img
style="position: relative;
left: 100%;
margin-top: -8%;
width: 6%;
cursor: pointer;;"
src="../../../assets/icons/cross.svg" (click)="closeModale()">

 <input class="title" [(ngModel)]="task.message" placeholder="Titre" required>

    <textarea class="commentary" [(ngModel)]="task.commentary" placeholder="Tâche"></textarea>

    <div *ngIf="this.currentUser.id === task.created_by || !edit.edit || this.currentUser.role === 'Admin' || this.currentUser.role === 'Adv' || this.currentUser.role === 'Responsable ADV'">

      <div class="row" *ngIf="!inCase.inCase && !edit.edit">
        <mat-form-field class="col-6">
          <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto" [(ngModel)]="customer.name" [placeholder]=customerPlaceholder>
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" >
            <mat-option *ngFor="let customer of filteredOptions | async" [value]="customer" (click)="showCasesByCustomerId(customer)">
              {{customer.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>


      <div class="row" *ngIf="!inCase.inCase && !edit.edit">
        <mat-form-field class="col-6">
          <mat-label>Sélectionner le kit vendu</mat-label>
          <mat-select name="user" [(ngModel)]="task.caseId">
            <mat-option *ngFor="let customerCase of customerCases" [value]="customerCase._id" (click)="showTargetByCaseId(customerCase)">
              {{customerCase.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="col-6">
          <mat-label>Sélectionner l'affectation</mat-label>
          <mat-select name="user" [(ngModel)]="task.target">
            <mat-option *ngFor="let user of targets" [value]="user._id">
              {{user.username}}
            </mat-option>
          </mat-select>
        </mat-form-field>

    </div>

    <div class="row">
         <mat-form-field class="col-6">
          <input matInput [matDatepicker]="picker1" class="custom-date" placeholder="Sélectionner une date" [(ngModel)]="task.date" [formControl]="date">
          <mat-datepicker-toggle matSuffix [for]="picker1" firstDayOfWeek="1"></mat-datepicker-toggle>
          <mat-datepicker #picker1 firstDayOfWeek="1"></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="col-6">
          <mat-label>Sélectionner l'importance</mat-label>
          <mat-select name="user" [(ngModel)]="task.state">
            <mat-option value="1-Urgente">Urgente</mat-option>
            <mat-option value="2-En-cours">En cours</mat-option>
          </mat-select>
        </mat-form-field>

    </div>

    <div class="row">
      <mat-form-field class="col-6">
        <mat-label>Sélectionner le tag</mat-label>
        <mat-select name="tag" [(ngModel)]="task.tag">
          <mat-option value="CEE">CEE</mat-option>
          <mat-option value="CM">CM</mat-option>
          <mat-option value="MPR">MPR</mat-option>
          <mat-option value="Raccordement">Raccordement</mat-option>
          <mat-option value="Primexel">Primexel</mat-option>
          <mat-option value="Dossier">Dossier</mat-option>

        </mat-select>
      </mat-form-field>

      <mat-form-field class="col-6" *ngIf="inCase.inCase">
        <mat-label>Sélectionner l'affectation</mat-label>
        <mat-select name="user" [(ngModel)]="task.target">
          <mat-option *ngFor="let user of targets" [value]="user._id">
            {{user.username}}
          </mat-option>
        </mat-select>
      </mat-form-field>
  </div>

</div>
  <button class="create" style="color: var(--tertiarycrm);" (click)="addTask()" *ngIf="!taskEdit && task.message != '' && task.state != '' && task.date != null">Créer la tâche</button>
  <button class="create" style="color: var(--tertiarycrm);" (click)="editTask()" *ngIf="taskEdit  && task.message != '' && task.state != '' && task.date !='' ">Modifier la tâche</button>

