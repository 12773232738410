<div class="book-container">
  <img class="logo_couleur" src="./../../../assets/book/logo_blanc.png" alt="logo" />
  <h1>Testons vos connaissances</h1>
  <div class="sub-container">
    <p class="subtitle">
      Quels sont les avantages <strong>de l’éco-rénovation ?</strong>
    </p>

    <div class="checkboxs">
      <div class="checkbox-row">
        <div class="checkbox-item">
          <label class="form__check check">
            <input class="check__input visually-hidden" type="checkbox" [(ngModel)]="reponse['Améliorer le confort']"
              (change)="checkChoice('Améliorer le confort')">
            <span class="check__box"></span>
            <span class="check__text">Améliorer le confort</span>
          </label>
        </div>
        <div class="checkbox-item">
          <label class="form__check check">
            <input class="check__input visually-hidden" type="checkbox" [(ngModel)]="reponse['Faire des économies']"
              (change)="checkChoice('Faire des économies')">
            <span class="check__box"></span>
            <span class="check__text">Faire des économies</span>
          </label>
        </div>
      </div>

      <div class="checkbox-row">
        <div class="checkbox-item">
          <label class="form__check check">
            <input class="check__input visually-hidden" type="checkbox"
              [(ngModel)]="reponse['Faire un geste pour la planète']"
              (change)="checkChoice('Faire un geste pour la planète')">
            <span class="check__box"></span>
            <span class="check__text">Faire un geste pour la planète</span>
          </label>
        </div>
        <div class="checkbox-item">
          <label class="form__check check">
            <input class="check__input visually-hidden" type="checkbox"
              [(ngModel)]="reponse['Valoriser son patrimoine']" (change)="checkChoice('Valoriser son patrimoine')">
            <span class="check__box"></span>
            <span class="check__text">Valoriser son patrimoine</span>
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="sub-container" style="width: 50%; margin: auto;">
    <button class="primary-button" (click)="suivant()" *ngIf="reponse.length > 0">
      Valider votre réponse
    </button>
  </div>
</div>