import { Component, OnInit } from '@angular/core';
import { User } from '@app/_models';
import { first } from 'rxjs/operators';
import { UsersService } from '@app/services/users.service';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AdminUserAddComponent } from '../admin-user-add/admin-user-add.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from '@app/dialogs/confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-admin-user-list',
  templateUrl: './admin-user-list.component.html',
  styleUrls: ['./admin-user-list.component.scss']
})
export class AdminUserListComponent implements OnInit {

  loading = false;
  users: User[] = [];

  gridApi;
  gridColumnApi;

  rowData;
  rowHeight
  rowStyle
  columnDefs;
  defaultColDef;

  selectedUser = {
    _id:"",
    agency: "",
    created_at: "",
    isActive: "",
    mail: "",
    refreshDesktop: "",
    refreshMobile: "",
    role: "",
    username: "",
  }

  selectedUsers;
  cleaningPasswords = []

  search = ''

  constructor(
    private sUsers: UsersService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
    ) {

    this.rowHeight = 65;
    this.rowStyle = { background: 'white' };
    this.columnDefs = [
      {
        headerName: 'ID',
        field: '_id',
        cellClass: 'cell-align',
        filter: 'agTextColumnFilter',
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
      {
        headerName: 'Nom',
        field: 'username',
        cellClass: 'cell-align',
        filter: 'agTextColumnFilter',

      },
      {
        headerName: 'mail',
        field: 'mail',
        cellClass: 'cell-align',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Role',
        field: 'role',
        cellClass: 'cell-align',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Créée le',
        field: 'created_at',
        cellClass: 'cell-align',
        filter: 'agDateColumnFilter',
        cellRenderer: (data) => {
          return data.value ? (new Date(data.value)).toLocaleDateString() : '';
        },
      },
      {
        headerName: 'Etat',
        field: 'isActive',
        cellClass: 'cell-align',
      }
  ];
    this.defaultColDef = {
      flex: 1,
      minWidth: 0,
      sortable: true,
      floatingFilter: true,
      resizable: true,
    };
  }

  ngOnInit() {
    this.showUsers();
  }
  getSelectedRowData() {
    let selectedNodes = this.gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map(node => node.data);
    this.selectedUsers = selectedData
    return selectedData;
  }
  selectAllCommActif() {
    this.gridApi.forEachNode(function (node) {
      node.setSelected(node.data.isActive && (node.data.role === 'Commercial' || node.data.role === 'Responsable d\'agence'));
    });
    setTimeout(() => {
      this.getSelectedRowData()
    }, 500);
  }
  showUsers() {
    this.loading = true;
    this.sUsers.getAllUsersWithInactive().pipe(first()).subscribe(users => {
      this.loading = false;
      this.users = users['users'];
    });
  }

  deleteUser(id) {
    if(confirm("Êtes-vous sûr de vouloir supprimer définitivement cet utilisateur?")) {
      this.sUsers.delete(id).subscribe(response => {
        this.showUsers();
      });
    }
  }

  disabled(id, state) {
    if(confirm("Êtes-vous sûr de vouloir désactiver cet utilisateur?")) {
      this.sUsers.editUser({isActive: !state},id).then(response => {
        this.showUsers();
      });
    }
  }

  getColor(role) {
    switch (role) {
      case 'Adv':
        return '#8ab0bb';
      case 'Commercial':
        return 'rgb(164,218,232)';
      case 'Responsable d\'agence':
          return 'rgb(155,217,255)';
      case 'admin':
        return 'rgb(200,191,231)';
    }

  }

  onCellValueChanged(params) {
    var colId = params.column.getId();
    if (colId === 'isActive') {
    }
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  getShortName(fullName) {
    return fullName.split(' ').map(n => n[0]).join('');
  }

  selectUser(event) {
    this.selectedUser = event.data
    document.getElementById("launch").click();
  }

  cleanPassword() {
    this.selectedUsers.forEach(user => {
      this.sUsers.cleanPassword(user._id).subscribe(res => {
        this.cleaningPasswords.push(res)
      })
    });
  }
  disconnectAllUsers() {
    this.sUsers.disconnectAllUsers().subscribe(res => {
      this._snackBar.open(res.message,'', {
        duration: 2000,
        panelClass: ['add-snackbar']
      });
    })
  }

  /////////////////// ADD ///////////////////

  userForm: FormGroup;

  addUser(): void {
    const dialogRef = this.dialog.open(AdminUserAddComponent, {
      width: '640px',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.showUsers();
    });
  }

  disconnectAll(): void {
    const message = `La prochaine fois qu'un Utilisateur rafraichira sa page, il sera déconnecté`;
    const sub_message = 'Êtes vous sûr de vouloir le faire ?'
    const dialogData = new ConfirmDialogModel("Attention", message, sub_message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      minHeight: '300px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      const result = dialogResult;
      if(result) {
        this.disconnectAllUsers()
      }
    });
  }

  confirmChangePassword(): void {
    const message = `Vous allez changer les mots de passe de tous les utilisateurs sélectionnés`;
    const sub_message = 'Êtes vous sûr de vouloir le faire ?'
    const dialogData = new ConfirmDialogModel("Attention", message, sub_message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      minHeight: '300px',
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      const result = dialogResult;
      if(result) {
        this.cleanPassword()
      }
    });
  }
}
