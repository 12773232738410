<img
style="
position: relative;
    left: 97%;
    width: 6%;
    cursor: pointer;"
src="../../../assets/icons/cross.svg" (click)="closeModale()">

<form [formGroup]="primeForm">

  <div class="row">
    <div class="col-11 mb-4">
      <mat-select class="select-prime" formControlName="type" placeholder="Selectionner le type de prime">
        <mat-option value="cee">CEE</mat-option>
        <mat-option value="mpr">MPR</mat-option>

      </mat-select>
    </div>

  </div>

  <div class="row">
    <div class="col-6">
      <input class="empty-date" formControlName="date_saisie" placeholder="Selectionner une date de saisie" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1">
      <span class="trigger" [owlDateTimeTrigger]="dt1">
        <img src="../../../assets/icons/calendar.svg" style="position: relative;left: -6%;background: white;">
      </span>
      <owl-date-time firstDayOfWeek="1" #dt1></owl-date-time>
    </div>

    <div class="col-6" *ngIf="primeForm.value.type === 'cee'">
    <mat-select class="select-products" placeholder="Sélectionner le produit" formControlName="product">
      <mat-option *ngFor="let product of products" [value]="product">
        {{ product.name }}
      </mat-option>
    </mat-select>

    </div>

    <div class="col-6" *ngIf="primeForm.value.type === 'mpr'">
      <mat-select class="select-products" placeholder="Sélectionner le produit" formControlName="product" multiple>
        <mat-option *ngFor="let product of products" [value]="product">
          {{ product.name }}
        </mat-option>
      </mat-select>
    </div>

  </div>

</form>


<div>
  <button class="btn btn-secondarycrm mb-3" (click)="addPrime()">Créer le dossier</button>
</div>

