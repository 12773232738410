<form [formGroup]="cmForm" class="d-flex" *ngIf='case' style="width:97%; margin-top: 2%;">
  <div class="left-col col-6">
    <p class="subtitle">Produits couverts</p>

    <mat-form-field>
      <mat-label>Choisir les produits</mat-label>
      <mat-select multiple [(value)]="selectedProducts" [(ngModel)]="selectedProducts" formControlName="products"
        (selectionChange)="saveChange(case)">
        <mat-option style="color: #3a3b5a;" [value]="product" *ngFor="let product of productsIndispensable">
          <strong>{{product.name}} - {{ product.price }}</strong>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <p class="warning">Attention : penser à recocher les produits présents en cas de changement</p>
    <div class="col-xs-12 col-lg-5 col-sm-3 btn-secondarycrm upload-bill" *ngIf="!contrat">
      <input accept=".jpg,.jpeg,.png,.pdf" multiple type="file" name="upload" id="upload" class="upload-box"
        (change)="selectMultiple($event,'cm')">
      + Ajouter le contrat
    </div>
    <div class="col-xs-12 col-lg-5 col-sm-3 show-contrat" *ngIf="contrat">
      <p>CM_{{ case.customer.name }}</p>
      <img src="../../../assets/icons/delete.svg" (click)="killBill(contrat._id,'supprimé','pièce',contrat.title )">
    </div>

    <p class="subtitle mt-4">Choix des formules</p>

    <div class="produits">

      <div class="products-list">
        <ul class="d-flex pl-4" *ngFor='let selected of case.cm.products'>
          <li>
            {{ selected.name }}

          </li>
          <select style="    padding: 0.5% 1%;
                  margin-top: 4%;
    height: 50px;" (change)="selectFormule($event, selected)" class="select-crm">
            <option class="col-10" value="selected">{{ selected.formule }}</option>
            <option *ngIf="selected.formule === 'Quiétude'" class="col-10" value=Indispensable>Indispensable</option>
            <option *ngIf="selected.formule === 'Indispensable'" class="col-10" value=Quiétude>Quiétude</option>
          </select>
        </ul>
      </div>
    </div>


  </div>
  <div class="right-col col-6">
    <p class="subtitle">Tarifs</p>
    <p class="montant" *ngIf="case.cm.tarif != null">{{case.cm.tarif.toFixed(2)}} €</p>
    <form formGroupName="paiement">

      <div class="choices mr-5">
        <input type="radio" id="type_paiement1" name="type_paiement" formControlName="type_paiement" value=Comptant
          (change)="saveChange(case)">
        <label for="type_paiement1" class="btn-secondarycrm"
          [ngClass]="case.cm.paiement.type_paiement === 'Comptant' ? 'active' : ''">
          Comptant</label>

        <input type="radio" id="type_paiement2" name="type_paiement" formControlName="type_paiement" value=Mensualité
          (change)="saveChange(case)">
        <label for="type_paiement2" class="btn-secondarycrm"
          [ngClass]="case.cm.paiement.type_paiement === 'Mensualité' ? 'active' : ''">
          Mensualité</label>
      </div>
    </form>

    <div *ngIf="state === 'Signée'">
      <p style="margin: 0; color:rgba(105, 106, 130, 1);">Date de signature</p>
      <form [formGroup]="caseForm">
        <input class="custom-date" placeholder="JJ/MM/AAAA xxhxx" value="case.cm.paiement.cheque1" [owlDateTime]="dt2"
          [owlDateTimeTrigger]="dt2" formControlName="datesign" value="case.datesign"
          (ngModelChange)="saveChangeDatesign(case)">
        <owl-date-time #dt2 firstDayOfWeek="1"></owl-date-time>
      </form>
    </div>
    <p class="subtitle mt-4" style="margin-bottom: 9%;">Suivi comptable</p>

    <div *ngIf="case.cm.paiement.type_paiement === 'Comptant'">
      <form [formGroup]="cmForm">
        <form formGroupName="paiement">
          <div>
            <p class="subtitle" style="margin: 0;">Date de dépôt du 1er chèque</p>

            <input class="custom-date" placeholder="JJ/MM/AAAA xxhxx" value="case.cm.paiement.cheque1"
              [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" formControlName="cheque1"
              (ngModelChange)="saveChange(case)">
            <owl-date-time #dt1 firstDayOfWeek="1"></owl-date-time>
          </div>
        </form>
      </form>
    </div>

    <div *ngIf="case.cm.paiement.type_paiement === 'Mensualité'">
      <form [formGroup]="cmForm">
        <form formGroupName="paiement">
          <div>
            <p style="margin: 0; color:rgba(105, 106, 130, 1);">Date des mensualités</p>

            <input class="custom-date" placeholder="JJ/MM/AAAA xxhxx" value="case.cm.paiement.mensualite"
              [owlDateTime]="dt3" [owlDateTimeTrigger]="dt3" formControlName="mensualite"
              (ngModelChange)="saveChange(case)">
            <owl-date-time #dt3 firstDayOfWeek="1"></owl-date-time>
          </div>

          <p class="montant mt-4">{{case.cm.paiement.montant | number }} € / mois</p>

          <div class="btn-secondarycrm button-wrapper upload-bill" *ngIf="!mandat">
            <input accept=".jpg,.jpeg,.png,.pdf" multiple type="file" name="upload" id="upload" class="upload-box"
              (change)="selectMultiple($event,'mandat du prélèvement')">
            + Ajouter le mandat du prélèvement
          </div>

          <div class="col-xs-12 col-lg-5 col-sm-3 show-contrat" *ngIf="mandat">
            <p>Mandat_{{ case.customer.name }}</p>
            <img src="../../../assets/icons/delete.svg" (click)="killBill(mandat._id,'supprimé','pièce',mandat.title )">
          </div>

        </form>
      </form>


    </div>

          <a (click)="openKitVendu()" *ngIf="v2">modifier le kit</a>

    <app-product-list [selectedProducts]="selectedProductsTransco"></app-product-list>


  </div>


</form>
<div class="row">

  <div class="col-6">
    <button class="btn-secondarycrm link" (click)="showTaskDialog()">Voir les tâches de l'affaire</button>
  </div>

  <div class=" col-6">
    <button class="btn-secondarycrm" (click)="createTaskDialog()">+ Nouvelle tâche</button>
  </div>



</div>