import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { CustomersService } from '@app/services/customers.service';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-fusion-cases-custumer',
  templateUrl: './fusion-cases-custumer.component.html',
  styleUrls: ['./fusion-cases-custumer.component.scss'],
})
export class FusionCasesCustumerComponent implements OnInit {
  customers = [];
  selectedCustomers = [];
  filteredOptions;
  invalidCustomer = false;
  customer = {
    name: '',
    id: '',
  };
  customerPlaceholder = 'Chargement...';

  myControl = new FormControl();

  constructor(
    public dialogRef: MatDialogRef<FusionCasesCustumerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customersService: CustomersService,
    private sCustomers: CustomersService,
    private sCases: CasesService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    this.loadCustomers();
  }

  ngOnInit(): void {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this._filter(name) : this.customers.slice()))
    );

    this.myControl.valueChanges.subscribe((value) => {
      const enteredCustomerName =
        typeof value === 'string' ? value : value?.name;
      const matchingCustomer = this.customers.find(
        (c) => c.name.toLowerCase() === enteredCustomerName?.toLowerCase()
      );

      if (matchingCustomer) {
        this.invalidCustomer = false;
      }
    });
  }

  loadCustomers() {
    this.customersService.getAllCustomers(9999).subscribe((res: any) => {
      this.customers = res.customers.filter(
        (customer) => customer._id !== this.data.currentCustomerId
      );
      this.customerPlaceholder = 'Sélectionner le client';
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  selectClient(client) {
    this.dialogRef.close(client);
  }

  toggleCustomerSelection(customer) {
    this.selectedCustomers = [customer];
  }

  confirmFusion() {
    const enteredCustomerName =
      typeof this.myControl.value === 'string'
        ? this.myControl.value
        : this.myControl.value.name;
    const matchingCustomer = this.customers.find(
      (c) => c.name.toLowerCase() === enteredCustomerName.toLowerCase()
    );

    if (!matchingCustomer) {
      this.invalidCustomer = true;
      return;
    } else {
      this.invalidCustomer = false;
    }

    if (confirm('Etes vous sur de vouloir effectuer cette fusion ?')) {
      const customerId = this.selectedCustomers[0]._id; // Ici je recupère l'ID du client sélectionné
      const selectedCases = this.data.selectedCases;
      this.sCases
        .updateCasesCustomer(selectedCases, customerId)
        .subscribe(() => {
          this.sCases
            .getAllCasesByCustomerId(this.data.currentCustomerId)
            .subscribe((cases) => {
              const casesArray = Array.isArray(cases)
                ? cases
                : Object.values(cases);

              if (casesArray.length === 0) {
                this.sCustomers
                  .delete(this.data.currentCustomerId)
                  .subscribe(() => {
                    this.dialogRef.close();
                    this.router.navigate(['/admin-customer-list']);
                  });
              } else {
                this.dialogRef.close();
              }
            });

          this.snackBar.open('Fusion effectuée avec succès !', ' ', {
            duration: 3000,
            panelClass: ['success-snackbar'],
            verticalPosition: 'top',
          });
        });
    }
  }

  displayFn(customer): string {
    return customer && customer.name ? customer.name : '';
  }

  private _filter(name: string) {
    const filterValue = name.toLowerCase();

    return this.customers.filter(
      (option) => option.name.toLowerCase().indexOf(filterValue) === 0
    );
  }
}
