<div class="adv-dashboard">
  <h2 class="my-3">
    Pégase vous souhaite la bienvenue,
    <strong style="color: var(--tertiarycrm)">{{ user.username }}</strong>
  </h2>

  <div class="row">
    <div class="col-8 card-top d-flex">
      <div class="left-component col-6">
        <div id="time"></div>

        <h1>{{ realtimeDate }}</h1>
        <p class="today">{{ date | date : "EEEE d MMMM" }}</p>
      </div>

      <div class="right-component col-6">
        <ul class="numbers">
          <li class="mt-4">
            <h5><em>Néros </em></h5>
          </li>
          <li class="mt-4"><u>LERIS Maxime </u>06 50 61 17 16</li>
          <li class="mt-4"><u>DUPUY Florian </u>06 98 78 13 27</li>
          <li class="mt-4"><u>MONNERVILLE Lucas </u>07 72 02 06 23</li>
          <li class="mt-4"><u>NORIEGA Tony </u>06 24 97 27 53</li>
          <li class="mt-4"><u>ABBES Zouhair </u>07 51 23 68 96</li>
          <li class="mt-4"><u>KENN KICHENIN </u>07 66 69 01 66</li>

          <li class="mt-4">
            <h5><em>Sparthios </em></h5>
          </li>
          <li class="mt-4"><u>MENARD Axel </u>06 16 58 28 94</li>
          <li class="mt-4"><u>MAGUEROT Loïc </u>06 45 15 17 58</li>

          <li class="mt-4">
            <h5><em>Inti </em></h5>
          </li>
          <li class="mt-4"><u>LAKHRIF Smail </u>07 87 17 08 45</li>
          <li class="mt-4"><u>ALVAREZ Anthony </u>06 87 10 46 05</li>
          <li class="mt-4"><u>BEAVOGUI Jean-Baptiste </u>06 28 97 97 78</li>

          <li class="mt-4">
            <h5><em>Amon </em></h5>
          </li>
          <li class="mt-4"><u>NORIEGA Olivier </u>06 27 83 16 75</li>
          <li class="mt-4"><u>LURO Antoine </u>07 71 77 27 28</li>
          <li class="mt-4"><u>MOREAU Nathan </u>06 45 62 92 69</li>
        </ul>
      </div>
    </div>

    <div class="col-4 notifs">
      <h4 class="p-4">Notifications</h4>
      <div *ngIf="loading" class="snippet pl-5" data-title=".dot-typing">
        <div class="stage">
          <div class="dot-typing"></div>
        </div>
      </div>
      <div class="pl-4" *ngFor="let info of infosUnread">
        <div *ngIf="
            info.reason && info.reason === 'pieces' && infosUnread.length > 0
          ">
          <h5 class="mt-3">
            {{ info.created_by.username }}
            <mat-icon (click)="deleteInfo(info._id)" class="trash">close</mat-icon>
          </h5>
          <p>
            à ajouté la pièce {{ info.comment }} le
            {{ info.created_at | date : "medium" }} pour le client
            <a (click)="goToCase(info.caseId._id, info, info._id)" style="text-decoration: underline; color: orangered">
              {{ info.customerId.name }}
            </a>
          </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'commentaire'">
          <h5 class="mt-3">
            {{ info.created_by.username }}
            <mat-icon (click)="deleteInfo(info._id)" class="trash">close</mat-icon>
          </h5>
          <p>
            vous a mentionné sur un commentaire le
            {{ info.created_at | date : "medium" }} sur l'affaire de
            <a (click)="goToCase(info.caseId._id, info, info._id)"
              style="text-decoration: underline; color: orangered">{{ info.customerId.name }}</a>
          </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'like'">
          <h5 class="mt-3">
            {{ info.created_by.username }}
            <mat-icon (click)="deleteInfo(info._id)" class="trash">close</mat-icon>
          </h5>
          <p>
            {{ info.comment }} votre commentaire le
            {{ info.created_at | date : "medium" }} sur l'affaire de
            <a (click)="goToCase(info.caseId._id, info, info._id)"
              style="text-decoration: underline; color: orangered">{{ info.customerId.name }}</a>
          </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'signée'">
          <h5 class="mt-3">
            {{ info.created_by.username }}
            <mat-icon (click)="deleteInfo(info._id)" class="trash">close</mat-icon>
          </h5>
          <p>
            à signé avec le client
            <a (click)="goToCase(info.caseId._id, info, info._id)"
              style="text-decoration: underline; color: orangered">{{ info.customerId.name }}</a>
          </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'qualification'">
          <h5 class="mt-3">
            {{ info.created_by.username }}
            <mat-icon (click)="deleteInfo(info._id)" class="trash">close</mat-icon>
          </h5>
          <p>
            à qualifé une pièce non conforme sur l'affaire de
            <a (click)="goToCase(info.caseId._id, info, info._id)"
              style="text-decoration: underline; color: orangered">{{ info.customerId.name }}</a>
          </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'rappelsign'">
          <h5 class="mt-3">
            Rappel
            <mat-icon (click)="deleteInfo(info._id)" class="trash">close</mat-icon>
          </h5>
          <p>
            {{ info.comment }}
            <a (click)="goToCase(info.caseId._id, info, info._id)"
              style="text-decoration: underline; color: orangered">{{ info.customerId.name }}</a>
          </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'rappelsignDA'">
          <h5 class="mt-3">
            Rappel
            <mat-icon (click)="deleteInfo(info._id)" class="trash">close</mat-icon>
          </h5>
          <p>
            {{ info.comment }}
            <a (click)="goToCase(info.caseId._id, info, info._id)"
              style="text-decoration: underline; color: orangered">{{ info.customerId.name }}</a>
          </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'rappelprospection'">
          <h5 class="mt-3">
            Rappel
            <mat-icon (click)="deleteInfo(info._id)" class="trash">close</mat-icon>
          </h5>
          <p>
            {{ info.comment }}
            <a (click)="goToCase(info.caseId._id, info, info._id)"
              style="text-decoration: underline; color: orangered">{{ info.customerId.name }}</a>
          </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'annulée'">
          <h5 class="mt-3">
            Affaire annulée
            <mat-icon (click)="deleteInfo(info._id)" class="trash">close</mat-icon>
          </h5>
          <p>
            L'affaire de
            <a (click)="goToCase(info.caseId._id, info, info._id)"
              style="text-decoration: underline; color: orangered">{{ info.customerId.name }}</a>
            est passée en {{ info.comment }}
          </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'chantier'">
          <h5 class="mt-3">
            {{ info.created_by.username
            }}<mat-icon (click)="deleteInfo(info._id)" class="trash">close</mat-icon>
          </h5>
          <p>
            a changé le {{ info.comment }} sur l'affaire
            <a (click)="goToCase(info.caseId._id, info, info._id)"
              style="text-decoration: underline; color: orangered">{{ info.customerId.name }}</a>
          </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'facture'">
          <h5 class="mt-3">
            {{ info.created_by.username
            }}<mat-icon (click)="deleteInfo(info._id)" class="trash">close</mat-icon>
          </h5>
          <p>
            Facturation de l'affaire
            <a (click)="goToCase(info.caseId._id, info, info._id)"
              style="text-decoration: underline; color: orangered">{{ info.customerId.name }}</a>
          </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'valid_tarif'">
          <h5 class="mt-3">
            {{ info.created_by.username
            }}<mat-icon (click)="deleteInfo(info._id)" class="trash">close</mat-icon>
          </h5>
          <p>
            {{ info.comment }} sur l'affaire
            <a (click)="goToCase(info.caseId._id, info, info._id)"
              style="text-decoration: underline; color: orangered">{{ info.customerId.name }}</a>
          </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'piece_manquante'">
          <h5 class="mt-3">
            {{ info.created_by.username
            }}<mat-icon (click)="deleteInfo(info._id)" class="trash">close</mat-icon>
          </h5>
          <p>
            {{ info.comment }} sur l'affaire
            <a (click)="goToCase(info.caseId._id, info, info._id)"
              style="text-decoration: underline; color: orangered">{{ info.customerId.name }}</a>
          </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'task_gdd'">
          <h5 class="mt-3">
            {{ info.created_by.username }}
            <mat-icon (click)="deleteInfo(info._id)" class="trash">close</mat-icon>
          </h5>
          <p>
            {{ info.comment }} le {{ info.created_at | date : "medium" }} sur
            l'affaire de
            <a (click)="goToCase(info.caseId._id, info, info._id)"
              style="text-decoration: underline; color: orangered">{{ info.customerId.name }}</a>
          </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'rappelmairie'">
          <h5 class="mt-3">
            Rappel
            <mat-icon (click)="deleteInfo(info._id)" class="trash">close</mat-icon>
          </h5>
          <p>
            {{ info.comment }} sur l'affaire de
            <a (click)="goToCase(info.caseId._id, info, info._id)"
              style="text-decoration: underline; color: orangered">{{ info.customerId.name }}</a>
          </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'rappelfi'">
          <h5 class="mt-3">
            Rappel
            <mat-icon (click)="deleteInfo(info._id)" class="trash">close</mat-icon>
          </h5>
          <p>
            {{ info.comment }} sur l'affaire de
            <a (click)="goToCase(info.caseId._id, info, info._id)"
              style="text-decoration: underline; color: orangered">{{ info.customerId.name }}</a>
          </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'recetteAdv'">
          <h5 class="mt-3">
            Nouvelle pièce
            <mat-icon (click)="deleteInfo(info._id)" class="trash">close</mat-icon>
          </h5>
          <p>
            {{ info.comment }} sur l'affaire de
            <a (click)="goToCase(info.caseId._id, info, info._id)"
              style="text-decoration: underline; color: orangered">{{ info.customerId.name }}</a>
          </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'bi'">
          <h5 class="mt-3">
            Nouvelle pièce
            <mat-icon (click)="deleteInfo(info._id)" class="trash">close</mat-icon>
          </h5>
          <p>
            {{ info.comment }} sur l'affaire de
            <a (click)="goToCase(info.caseId._id, info, info._id)"
              style="text-decoration: underline; color: orangered">{{ info.customerId.name }}</a>
          </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'messageClient'">
          <h5 class="mt-3">
            {{ info.customerId.name }}
            <mat-icon (click)="deleteInfo(info._id)" class="trash">close</mat-icon>
          </h5>
          <p>
            {{ info.comment }}
            <a (click)="goToCase(info.caseId._id, info, info._id)"
              style="text-decoration: underline; color: orangered">{{ info.customerId.name }}</a>
          </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'cm_birthday'">
          <h5 class="mt-3">
            Relance CM
            <mat-icon (click)="deleteInfo(info._id)" class="trash">close</mat-icon>
          </h5>
          <p>
            <a (click)="goToCase(info.caseId._id, info, info._id)" style="text-decoration: underline; color: orangered">
              {{ info.comment }}
            </a>
          </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'dossier_prime'">
          <h5 class="mt-3">
            Dossier MPR
            <mat-icon (click)="deleteInfo(info._id)" class="trash">close</mat-icon>
          </h5>
          <p>
            {{ info.comment }}
            <a (click)="goToCase(info.caseId._id, info, info._id)"
              style="text-decoration: underline; color: orangered">{{ info.customerId.name }}</a>
          </p>
        </div>
        <div *ngIf="info.reason && info.reason === 'compteRenduSav'">
          <h5 class="mt-3">
            Compte Rendu SAV
            <mat-icon (click)="deleteInfo(info._id)" class="trash">close</mat-icon>
          </h5>
          <p>
            {{ info.comment }} 
            <a (click)="goToCase(info.caseId._id, info, info._id)"
              style="text-decoration: underline; color: orangered">{{ info.customerId.name }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-8 pl-0 d-flex">
      <div class="col-6 pl-0" *ngIf="
          this.sRoles.user.id === '5ff22939e4e34600046c014f' ||
          this.sRoles.user.id == '5ff22aa6a0f2570004f151d1' ||
          this.sRoles.user.id == '61f7e5e70d8ab20004bc072e'
        ">
        <div class="tmk-component card col-12">
          <div style="margin: 20px">
            <h4>Call 2 RDV</h4>
            <div style="color: var(--tertiarycrm); font-size: calc(0% + 2vw + 2vh)">
              {{ countTmkCase }}
            </div>
            <div style="font-size: 24px">Rendez-vous à traiter</div>
            <button class="btn-secondarycrm col-6" routerLink="/super-tmk-dashboard">
              Traiter
            </button>
          </div>
        </div>
      </div>

      <div class="col-6 pl-0" *ngIf="
          this.sRoles.user.id != '5ff22939e4e34600046c014f' ||
          this.sRoles.user.id != '5ff22aa6a0f2570004f151d1' ||
          this.sRoles.user.id != '61f7e5e70d8ab20004bc072e'
        ">
        <div class="left-content card col-12">
          <h4 class="p-4">Primes</h4>
          <div class="text-center">
            <a [routerLink]="['/compta-primes-table']">Accéder au tableau des primes</a>
          </div>

          <h4 class="p-4" *ngIf="
              this.sRoles.user.id === '5ff22939e4e34600046c014f' ||
              this.sRoles.user.id == '5ff22aa6a0f2570004f151d1' ||
              this.sRoles.user.id == '61f7e5e70d8ab20004bc072e'
            ">
            Suivi des dossiers
          </h4>
          <div class="text-center" *ngIf="
              this.sRoles.user.id === '5ff22939e4e34600046c014f' ||
              this.sRoles.user.id == '5ff22aa6a0f2570004f151d1' ||
              this.sRoles.user.id == '61f7e5e70d8ab20004bc072e'
            ">
            <a [routerLink]="['/suivi-dossiers']">Accéder à mes dossiers</a>
          </div>
        </div>
      </div>

      <div class="card col-6 ml-3" *ngIf="
          this.sRoles.user.id != '5ff22939e4e34600046c014f' &&
          this.sRoles.user.id != '5ff22aa6a0f2570004f151d1' &&
          this.sRoles.user.id != '61f7e5e70d8ab20004bc072e'
        ">
        <h4 class="p-4">Suivi des dossiers</h4>
        <div class="p-4 text-center">
          <a [routerLink]="['/suivi-dossiers']">Accéder à mes dossiers</a> <br>
          <a *ngIf="this.sRoles.user.id === '6130f6effbfbb60004bcfeb3'" [routerLink]="['/table-gdd']">Suivi GDD</a>

        </div>
      </div>
    </div>
  </div>
</div>