import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { RolesService } from '@app/services/roles.service';

@Component({
  selector: 'app-edit-piece',
  templateUrl: './edit-piece.component.html',
  styleUrls: ['./edit-piece.component.scss']
})
export class EditPieceComponent implements OnInit {
  case
  recettePath = '../../../assets/icons/recette_bt.svg'
  constructor(
    @Inject(MAT_DIALOG_DATA) public currentCase: any,
    @Inject(MAT_DIALOG_DATA) public currentKit: any,
    @Inject(MAT_DIALOG_DATA) public chantierId: any,
    private dialogRef: MatDialog,
    private router: Router,
    public sRoles: RolesService,
    private sCases: CasesService
  ) {
    this.case = currentCase.case
    this.currentKit = currentKit.currentKit
    this.chantierId = chantierId.chantierId
  }

  ngOnInit(): void {

  }


  getCurrentKitAndSaveChange(choice) {
    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === this.currentKit.name);
    const selected = array[index]
    this.currentKit = selected
    selected['type_bt'] = choice
    this.case.kits_vendus = JSON.stringify(array)
    this.sCases.editCase(this.case, this.case._id)

    this.dialogRef.closeAll();

  }

}
