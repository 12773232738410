<div class="contain">
  <div class="row empty_state">
    <div *ngIf="loading" class='col-2 empty_state'>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <p class="empty_block"><span></span></p>

    </div>
    <div *ngIf="loading" class='col-2 empty_state'>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <p class="empty_block"><span></span></p>
    </div>
    <div *ngIf="loading" class='col-2 empty_state'>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <p class="empty_block"><span></span></p>
    </div>

    <div *ngIf="loading" class='col-2 empty_state'>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <p class="empty_block"><span></span></p>
    </div>

    <div *ngIf="loading" class='col-2 empty_state'>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <p class="empty_block"><span></span></p>
    </div>
    <div *ngIf="loading" class='col-2 empty_state'>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <p class="empty_block"><span></span></p>
    </div>

    <div [style.opacity]="!loading ? '' : 0" [ngClass]="prospection_active ? 'active' : ''" class='col' id="prospection" (click)="changeActive('prospection_active')">
      <em id="prospection_em3"><u class="circle"></u></em>
      <em id="prospection_em4"><u class="circle"></u></em>
      <p>Prospection <span *ngIf="prospection">{{ prospection.length }}</span></p>

    </div>
    <div [style.opacity]="!loading ? '' : 0" [ngClass]="negociation_active ? 'active' : ''" id="negociation" class='col' (click)="changeActive('negociation_active')">
      <em id="negociation_em1"><u class="circle"></u></em>
      <em id="negociation_em2"><u class="circle"></u></em>
      <em id="negociation_em3"><u class="circle"></u></em>
      <em id="negociation_em4"><u class="circle"></u></em>
      <p>Négociation <span *ngIf="negociation">{{ negociation.length }}</span></p>
    </div>
    <div [style.opacity]="!loading ? '' : 0" [ngClass]="signee_active ? 'active' : ''" class='col' id="signee" (click)="changeActive('signee_active')">
      <em id="completude_em1"><u class="circle"></u></em>
      <em id="completude_em2"><u class="circle"></u></em>
      <em id="completude_em3"><u class="circle"></u></em>
      <em id="completude_em4"><u class="circle"></u></em>

      <p>En attente <span *ngIf="attente">{{ attente.length }}</span></p>

    </div>
    <div [style.opacity]="!loading ? '' : 0" [ngClass]="travaux_active ? 'active' : ''" id="theDiv" class='col' id="travaux" (click)="changeActive('travaux_active')">
      <em id="travaux_em1"><u class="circle"></u></em>
      <em id="travaux_em2"><u class="circle"></u></em>
      <em id="travaux_em3"><u class="circle"></u></em>
      <em id="travaux_em4"><u class="circle"></u></em>
      <p [ngStyle]="{'color': completude.length > 2 ? '#F45B69' : '#E95E24' }" class="completude">À traiter <span *ngIf="completude" [ngStyle]="{'background-color': completude.length > 2 ? '#F45B69' : '#E95E24' }">{{ completude.length }}</span></p>
      <span *ngIf="completude && completude.length > 0" class="commission">Commission brut en attente : <strong>{{ sumCompletude  | currency:'EUR':"symbol" }}</strong> </span>

    </div>
    <div [style.opacity]="!loading ? '' : 0" [ngClass]="pose_active ? 'active' : ''" class='col' id="pose" (click)="changeActive('pose_active')">
      <em id="pose_em1"><u class="circle"></u></em>
      <em id="pose_em2"><u class="circle"></u></em>
      <em id="pose_em3"><u class="circle"></u></em>
      <em id="pose_em4"><u class="circle"></u></em>
      <p>Attente date de pose <span *ngIf="complete_demande_fi">{{ complete_demande_fi.length }}</span></p>
    </div>

    <div [style.opacity]="!loading ? '' : 0" [ngClass]="paye_active ? 'active' : ''" class='col' id="paye" (click)="changeActive('paye_active')">
      <em id="paye_em1"><u class="circle"></u></em>
      <em id="paye_em2"><u class="circle"></u></em>
      <p>Travaux en cours <span *ngIf="travaux_ec">{{ travaux_ec.length }}</span></p>
      <span *ngIf="travaux_ec && travaux_ec.length > 0" class="commission_travaux">Commission brut : <strong>{{ sumTravauxEc  | currency:'EUR':"symbol" }}</strong> </span>

    </div>
  </div>
</div>


  <div *ngIf="prospection_active">
    <div class="row">
      <div class="col-md-4" *ngFor="let p of prospection" [routerLink]="['/commercial-template', p._id]">
        <div class="card pb-2 pt-4 px-4">
          <div class="card-block">
            <p class="qualif" style="background-color: #06AED5;">Prospection</p>
            <h6>Nom du client</h6>
            <p class="customer">{{ p.customer?.name }}</p>

            <h6>Date du rendez-vous</h6>
            <p>{{ p.datecase |  date:'fullDate' }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

    <div  *ngIf="negociation_active">
      <div class="row mt-5">
        <div class="col-md-4" *ngFor="let n of negociation" [routerLink]="['/commercial-template', n._id]">
          <div class="card pb-2 pt-4 px-4">
            <div class="card-block">
              <p class="qualif" style="background-color: #f0c808;">Négociation</p>
              <h6>Nom du client</h6>
              <p class="customer">{{ n.customer?.name }}</p>

              <h6>Date du rendez-vous</h6>
              <p>{{ n.datecase |  date:'fullDate' }}</p>
            </div>
          </div>
        </div>
      </div>

    </div>

  <div *ngIf="signee_active">
    <div class="row mt-5">
      <div class="col-md-4" *ngFor="let a of attente" target="_blank" [routerLink]="['/commercial-template', a._id]">
        <div class="card pb-2 pt-4 px-4">
          <p class="qualif" style="background-color: #78C091; "*ngIf="a.customer.origin === 'perso' || a.customer.origin === 'commercial'; enerconfort">perso</p>
          <p class="qualif" style="background-color: #78C091;"*ngIf="a.customer.origin === 'Be energie' ||a.customer.origin === 'adv'">Enerconfort</p>
            <h6>Nom du client</h6>
            <p class="customer">{{ a.customer.name }}</p>

            <h6 class="mb-2">Kit vendu</h6>
            <div style="font-size:smaller;">
            <app-product-card [kit]="a.kits_vendus"></app-product-card>
          </div>
            <h6 class="mt-2">Pièce en attente de qualification</h6>
            <p class="piece_quali"><img src="../../../assets/yellow_dot.svg" class="mr-3"> {{ getLengths(a._id,'En attente de qualification',attente)}} pièce(s) en attente</p>

            <div class="row">
              <div class="col-6 subcard">
                <h6 class="mt-3">Montant TTC</h6>
                <p>{{ a.price_ttc | currency:'EUR':"symbol" }} - {{ a.financement_type | titlecase }}</p>
              </div>

              <div class="col-6 subcard">
                <h6 class="mt-3">Signature</h6>
                <p>{{ a.datesign |  date:'fullDate' }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>

  <div *ngIf="travaux_active">
    <div class="row mt-5">
      <div class="col-md-4" *ngFor="let a of completude" target="_blank" [routerLink]="['/commercial-template', a._id]">
        <div class="card pb-2 pt-4 px-4">

          <p class="qualif" style="background-color: #78C091; "*ngIf="a.customer.origin === 'perso' || a.customer.origin === 'commercial'; enerconfort">perso</p>
          <p class="qualif" style="background-color: #78C091;"*ngIf="a.customer.origin === 'Be energie' ||a.customer.origin === 'adv'">Enerconfort</p>

            <h6>Nom du client</h6>
            <p class="customer">{{ a.customer.name }}</p>

            <h6>Demande de complétude</h6>
            <div class="pieces">
              <p>
                <span style="background-color: #9F555C;"></span>
                {{ getLengths(a._id,'Manquante',completude)}} Pièce(s) manquante(s)
              </p>
              <p>
                <span style="background-color: #F45B69;"></span>
                {{ getLengths(a._id,'Non conforme',completude)}} Pièce(s) non conforme(s)
              </p>
            </div>

            <h6 class="mt-3">Mairie - FI</h6>
            <p>
              <span *ngIf="a.mairie != 'Aucune'">{{ a.mairie.state }}</span>
              <span *ngIf="a.mairie === 'Aucune'">aucune</span> - {{ a.financement_type | titlecase }}</p>
          </div>
        </div>
      </div>
  </div>

  <div *ngIf="pose_active">
    <div class="row mt-5">
      <div class="col-md-4" *ngFor="let p of complete_demande_fi" [routerLink]="['/commercial-template', p._id]">
        <div class="card pb-2 pt-4 px-4">
          <p class="qualif" style="background-color: #78C091; "*ngIf="p.customer.origin === 'perso' || p.customer.origin === 'commercial'; enerconfort">perso</p>
          <p class="qualif" style="background-color: #78C091;"*ngIf="p.customer.origin === 'Be energie' ||p.customer.origin === 'adv'">Enerconfort</p>

            <h6>Nom du client</h6>
            <p class="customer">{{ p.customer.name }}</p>


            <div class="row">
              <div class="col-6 subcard">
                <h6 class="mt-3">Financement</h6>
                <p *ngIf="p.financement_type === 'Comptant'">Financement comptant</p>
                <p *ngIf="p.financement_type != 'Comptant' && p.financement != undefined">{{p.financement.partenaire | titlecase }}</p>
              </div>

              <div class="col-6 subcard">
                <h6 class="mt-3" *ngIf="p.mairie != undefined">Mairie</h6>
                <p>{{ p.mairie.state }}</p>
              </div>
            </div>



          </div>
        </div>
      </div>
  </div>

  <div *ngIf="paye_active">
    <div class="row mt-5">
      <div class="col-md-4" *ngFor="let p of travaux_ec" [routerLink]="['/commercial-template', p._id]">
        <div class="card pb-2 pt-4 px-4">
          <p class="qualif" style="background-color: #78C091; "*ngIf="p.customer.origin === 'perso' || p.customer.origin === 'commercial'; enerconfort">perso</p>
          <p class="qualif" style="background-color: #78C091;"*ngIf="p.customer.origin === 'Be energie' ||p.customer.origin === 'adv'">Enerconfort</p>

            <h6>Nom du client</h6>
            <p class="customer">{{ p.customer.name }}</p>

            <h6>Date de fin de chantier prévue</h6>
            <p>{{ p.chantier.date |  date:'fullDate' }}</p>
          </div>
        </div>
      </div>
  </div>

