import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { HistoriqueService } from '@app/services/historique.service';
import { MairiesService } from '@app/services/mairies.service';
import { RolesService } from '@app/services/roles.service';

@Component({
  selector: 'app-edit-mairie',
  templateUrl: './edit-mairie.component.html',
  styleUrls: ['./edit-mairie.component.scss']
})
export class EditMairieComponent implements OnInit {

  @Input() mairieId: string;
  currentMairie;
  caseId;
  case;
  mairieForm: FormGroup;
  date_fin;
  countMairie;
  user;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public sanitizer: DomSanitizer,
    private router: Router,
    private sRoles: RolesService,
    private sMairies: MairiesService,
    private sCases: CasesService,
    private sHistoriques: HistoriqueService,

  ) {
    this.user = this.sRoles.user.role;
    this.caseId = this.route.snapshot.params['id'];

    this.sMairies.getMairiesByCaseId(this.caseId).subscribe(res => {
      this.countMairie = res;
      this.countMairie = this.countMairie.length + 1;
      this.showCurrentMairie().then(res => {

        this.mairieForm = this.fb.group({
            caseId: new FormControl(this.caseId, [Validators.required]),
            titre: new FormControl('' + this.countMairie, [Validators.required]),
            dp: new FormControl(this.currentMairie.dp, [Validators.required]),
            state: new FormControl(this.currentMairie.state, [Validators.required]),
            date_depot: new FormControl(this.currentMairie.date_depot, [Validators.required]),
          });
      });

      });
   }

  ngOnInit() {
    this.showCaseInfos();
  }
  createMairie() {
     this.sMairies.createMairie(this.mairieForm.value).then(res => {
      if (res['error']) {
        console.log(res['error']);
      }
      this.goToList();
    });
  }

  goToList() {
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['case-template', this.caseId], { state: { value: 'mairieList' } });
  }

  showCaseInfos() {
    return new Promise(resolve => {

    this.sCases.getCaseById(this.caseId).subscribe(res => {
      this.case = res[0];
      var dt = new Date(this.currentMairie.date_depot);
      if (this.case.abf === 'true') {
        dt.setMonth( dt.getMonth() + 2);
      }
      else {
        dt.setMonth( dt.getMonth() + 1);
      }
      this.date_fin = dt;
      resolve(res);
        }, error => {
          console.log(error);
      });
    });
  }
  showCurrentMairie() {
    return new Promise(resolve => {

      this.sMairies.getMairieById(this.mairieId).subscribe(res => {
        this.currentMairie = res[0];
        resolve(res);
          }, error => {
            console.log(error);
        });
      });
  }
  saveChange(reason, change, value) {
    this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change,value,this.sRoles.user.id).then(res => {
      if (res['error']) {
        alert(res);
      }
    });
    this.sMairies.editMairie(this.mairieForm.value, this.currentMairie._id).then(res => {
      console.log(res);
      if(res['error']) {
        return res.error;
      }
    });
  }
}
