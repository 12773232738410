import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { HistoriqueService } from '@app/services/historique.service';
import { PiecesService } from '@app/services/pieces.service';
import { PrimesService } from '@app/services/primes.service';
import { RolesService } from '@app/services/roles.service';
import { apiUrl} from '@environments/env';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-case-prime-edit',
  templateUrl: './case-prime-edit.component.html',
  styleUrls: ['./case-prime-edit.component.scss']
})
export class CasePrimeEditComponent implements OnInit {

  @Input() primeId: string;

  prime;
  caseId
  case
  primeForm:FormGroup
  pieces
  products = []
  checkProducts = [
    {title: 'facture', disabled : true, qualification : null},
    {title: 'Bon de commande', disabled : true, qualification : null},
    {title: 'AI Mr', disabled : true, qualification : null},
    {title: 'AI Mme', disabled : true, qualification : null},
    {title: 'CNI Mr', disabled : true, qualification : null},
    {title: 'CNI Mme', disabled : true, qualification : null},
    {title: 'Taxe foncière', disabled : true, qualification : null},
    {title: 'Acte notarié', disabled : true, qualification : null},
    {title: 'Photos complémentaires', disabled : true, qualification : null},
    {title: 'PV livraison', disabled : true, qualification : null},
  ]
  checkProductsMpr = [
    {title: 'facture', disabled : true, qualification : null},
    {title: 'Bon de commande', disabled : true, qualification : null},
    {title: 'AI Mr', disabled : true, qualification : null},
    {title: 'AI Mme', disabled : true, qualification : null}
  ]

  bill
  billImg
  multipleImagesPieces
  apiUrl = apiUrl.apiUrl + '/piece/';

  multipleProduct = false;
  constructor(
    private sPrimes: PrimesService,
    private fb: FormBuilder,
    private sCases: CasesService,
    private route: ActivatedRoute,
    private sPieces: PiecesService,
    private sRoles: RolesService,
    private sHistoriques: HistoriqueService,
    private sb: MatSnackBar,
    private http: HttpClient,

  ) {
    this.caseId = this.route.snapshot.params['id'];
    }

  ngOnInit(): void {
    this.sPrimes.getPrimeById(this.primeId).subscribe(res => {
      this.prime = res[0]
      if(JSON.stringify(this.prime.product).substring(0,1) === '[' && JSON.stringify(this.prime.product) != '[]') {
        this.multipleProduct = true;
      }
      this.getBill()
      this.primeForm = this.fb.group({
        type: [this.prime.type],
        state: [this.prime.state],
        date_saisie: [this.prime.date_saisie],
        date_complement: [this.prime.date_complement],
        date_virement: [this.prime.date_virement],
        date_envoi: [this.prime.date_envoi],
        date_appel: [this.prime.date_appel],
        n_appel: [this.prime.n_appel],
        description: [this.prime.description],
        envoi: [this.prime.envoi],
        verse_client: [this.prime.verse_client],
        montant: [this.prime.montant],
        product: [this.prime.product],
        n_dossier: [this.prime.n_dossier]
      });
      if(this.prime.type === 'cee')
        this.getPiecesForCee()
      if(this.prime.type === 'mpr')
        this.getPiecesForMpr()
    })

    this.showCaseById()
  }

  showCaseById() {
    this.sCases.getCaseById(this.caseId).subscribe(res => {
      this.case = res[0]
    })
  }

  saveChange(reason, change, value) {
     this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change,value,this.sRoles.user.id).then(res => {
      if (res['error']) {
        alert(res);
      }
    });
    this.sPrimes.editPrime(this.primeForm.value, this.primeId).then(res => {
      if(res['error']) {
        return res.error;
      }
    });
  }

  getPiecesForCee() {
    this.sPieces.getPiecesByCaseIdForCee(this.caseId).subscribe(res => {
      this.pieces = res
      this.pieces.forEach(element => {
        let find = this.checkProducts.find(x => x.title === element.title);
        if(find)
        find.qualification = element.qualification
      });
    })
  }

  getPiecesForMpr() {
    this.sPieces.getPiecesByCaseIdForMpr(this.caseId).subscribe(res => {
      this.pieces = res
      this.pieces.forEach(element => {
        let find = this.checkProductsMpr.find(x => x.title === element.title);
        if(find)
        find.qualification = element.qualification
      });
    })
  }


  selectMultiple(event, title) {
    if (event.target.files.length > 0) {
      this.multipleImagesPieces = event.target.files;
      this.onSubmitMultiple(title,'ajouté','pièce',title )
    }
  }

  onSubmitMultiple(title, reason, change,value) {
    let titleProduct
    let prods = []

    if(this.multipleProduct) {
      this.prime.product.forEach(element => {
        prods.push(element.name.replace('/', ' '))
      });
      titleProduct =  prods.toString()
    }
    else if(JSON.stringify(this.prime.product) === '[]') {
      titleProduct = new Date(this.prime.created_at).getTime()
    }
    else {
      titleProduct = this.prime.product.name.includes('/') ? this.prime.product.name.replace('/', ' ') : this.prime.product[0].name
    }
    const now = new Date().toString()
    const formData = new FormData();
    formData.append('title', 'Notification de versement '+ this.prime.type + ' ' + titleProduct);
    formData.append('description', '');
    formData.append('qualification', 'Conforme');
    formData.append('caseId', this.caseId);
    formData.append('created_at', now);

    for (let img of this.multipleImagesPieces) {
      formData.append('filesPieces', img);
    }

    this.prime.facture_done = true
    this.sPrimes.editPrime(this.prime, this.prime._id).then(res => {
      if(res['error']) {
        return res.error;
      }

    })
     this.http.post<any>(this.apiUrl + 'multiplePieces', formData).subscribe(
      (res) => {
        this.sb.open(`Notification de versement ajoutée`, '', { duration: 2000, verticalPosition: 'bottom', panelClass: ['add-snackbar'] });
        this.getBill()
      },
      (err) => console.log(err)
    );
  }
  getBill(){
    let titleProduct
    let prods = []
    if(JSON.stringify(this.prime.product) === '[]') {
      titleProduct = 'empty'
    }
    else if(this.multipleProduct) {
      this.prime.product.forEach(element => {
        prods.push(element.name.replace('/', ' '))
      });
      titleProduct =  prods
    }
    else {
      titleProduct = this.prime.product
    }

    this.sPieces.getBillPrimeByCaseId(this.prime.type,new Date(this.prime.created_at).getTime(), JSON.stringify(titleProduct), this.caseId).subscribe(res =>{
      this.bill = res[0]

      this.billImg = this.bill.image.split(',')[0]

    })
  }
  killBill(id,reason, change,value) {
    if (confirm("Confirmer la suppression?")) {
      this.sPieces.delete(id).subscribe(response => {
        this.bill = null
        this.prime.facture_done = false
        this.sPrimes.editPrime(this.prime, this.prime._id).then(res => {
          if(res['error']) {
            return res.error;
          }
          else {
            this.sb.open(`Notification de versement supprimée`, '', { duration: 2000, verticalPosition: 'bottom', panelClass: ['delete-snackbar'] });
          }
        })
      });
      this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change,value,this.sRoles.user.id).then(res => {
        if (res['error']) {
          alert(res);
        }
      });
    }
  }

}
