import { CustomStepDefinition, LabelType, Options } from '@angular-slider/ngx-slider';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PdfsService } from '@app/services/pdfs.service';

@Component({
  selector: 'app-part21',
  templateUrl: './part21.component.html',
  styleUrls: ['./part21.component.scss']
})
export class Part21Component implements OnInit {
  caseId
  anneeSlider = 0;
  book;

  reduire: number = 1200
  assainir: number = 50
  ameliorer: number = 21
  developper: number = 50

  alphabet: string = 'ABDG';
  value: any

  options4: Options = {
    stepsArray: this.alphabet.split('').map((letter: string): CustomStepDefinition => {
      return { value: this.letterToIndex(letter) };
    }),
    translate: (value: number, label: LabelType): string => {
      return this.indexToLetter(value);
    },
    ceil: 0,
  };

  indexToLetter(index: number): string {
    return this.alphabet[index];
  }

  letterToIndex(letter: string): number {
    return this.alphabet.indexOf(letter);
  }

  options: Options = {
    floor: 0,
    ceil: 3000,
    showSelectionBar: true,
    translate: function (value) {
      return value + " €";
    }
  };
  options2: Options = {
    floor: 0,
    ceil: 100,
    showSelectionBar: true,
    translate: function (value) {
      return value + " %";
    }
  };
  options3: Options = {
    floor: 0,
    ceil: 30,
    showSelectionBar: true,
    translate: function (value) {
      return value + " °C";
    }
  };

  options5: Options = {
    floor: 0,
    ceil: 100,
    showSelectionBar: true,
    translate: function (value) {
      return value + " %";
    }
  };

  constructor(private route: ActivatedRoute, private pdfServices: PdfsService) { }

  ngOnInit(): void {
    this.caseId = this.route.snapshot.params['id'];
    this.book = JSON.parse(localStorage.getItem('book'));
  }

  public generateBook() {
    this.book.reduire = this.reduire;
    this.book.assainir = this.assainir;
    this.book.ameliorer = this.ameliorer;
    this.book.developper = this.developper;
    this.book.energetique = this.value;
    console.log(this.value)
    localStorage.setItem('book', JSON.stringify(this.book));

    this.envoyerValeurs(this.book);
  }

  async envoyerValeurs(book) {
    this.pdfServices.generateBook(book).then(res => {
      this.downloadMyFile()
    })
  }

  downloadMyFile() {
    const book = JSON.parse(localStorage.getItem('book'))
    const bookHS = {
      customer: book.customer,
      annee: book.annee,
      surface: book.surface,
      caseId: book.caseId,
    }
    window.open('https://home-smart.web.app/directory-manager/?book=' + JSON.stringify(bookHS), '_blank');

    const link = document.createElement('a');
    link.setAttribute('href', 'https://pegase-api.herokuapp.com/pdf/downloadPdf');
    document.body.appendChild(link);
    link.click();
  }
}
