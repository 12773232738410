import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-part9bis',
  templateUrl: './part9bis.component.html',
  styleUrls: ['./part9bis.component.scss']
})
export class Part9BisComponent implements OnInit {
  caseId: string;
  step = 0;

  book: any;

  percentageAbsorb: number;
  percentageEco: number;
  rtSelected = '';

  constructor(private route: ActivatedRoute, private router: Router, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.caseId = this.route.snapshot.params['id'];
    this.book = JSON.parse(localStorage.getItem('book'));
    this.rtSelected = this.book.rtSelected;
    this.percentageEco = this.getRandomValue(50, 68);
    this.percentageAbsorb = this.getRandomValue(43, 72);

  }

  getRandomValue(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  changeStepAndRouting(): void {
    if (this.step === 1) {
      this.router.navigate(['/book/part10', this.caseId]);
    }
    this.step++;

  }

}
