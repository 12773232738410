import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { InfosService } from '@app/services/infos.service';
import { RolesService } from '@app/services/roles.service';
import { UsersService } from '@app/services/users.service';
import { AuthenticationService } from '@app/_services';

@Component({
  selector: 'app-infos',
  templateUrl: './infos.component.html',
  styleUrls: ['./infos.component.scss']
})
export class InfosComponent implements OnInit {

  infoForm: FormGroup;
  infos;
  infosRead = [];
  infosUnread = [];
  showPopupNotif = false;
  showUnread = true;
  showRead = false;
  user;

  constructor(
    private sInfos: InfosService,
    public sUsers: UsersService,
    public sRoles: RolesService,
    private router: Router,
    private authenticationService: AuthenticationService,

  ) {
    this.authenticationService.user.subscribe(x => this.user = x);

   }

  ngOnInit(): void {
    this.showInfosByAdvId();
    this.sUsers.showUserRole();
  }

  showInfosByAdvId() {
    this.sInfos.getInfoByAdvId(this.user.id).subscribe(res => {
      this.infos = res;

      this.infos.forEach(element => {
         if(element.vu) {
          this.infosRead.push(element)
        }
        else {
          this.infosUnread.push(element)
        }
      });
    })
  }

  showUnreadNotif() {
    this.showUnread = true;
    this.showRead = false;
  }

  showAll() {
    this.showUnread = true;
    this.showRead = true;
  }

  showPopup() {
    this.showPopupNotif = !this.showPopupNotif;
  }

  saveChange(info, infoId) {
    console.log(info)
    info.vu = !info.vu;
    this.sInfos.editInfo(info, infoId).then(res => {
      if(res['error']) {
        return res.error;
      }
        let indexNotif
        indexNotif = this.infosUnread.findIndex( notif => (notif._id == infoId  ))
        this.infosUnread.splice(indexNotif, 1)
        this.infosRead.push(info)
        this.infosRead.sort((a,b) => (a.created_at > b.created_at ) ? -1 : 1 )
    });
  }

  goToCase(id, info, infoId) {
    this.saveChange(info, infoId);
    this.router.navigate(['commercial-template', id]).then(() => {
      window.location.reload();
    });
  }

  goToChantier(info, infoId) {
    this.saveChange(info, infoId);
    this.router.navigate(['technicien-dashboard']).then(() => {
      window.location.reload();
    });
  }

  deleteInfo(id) {
    this.sInfos.delete(id).subscribe(response => {
      this.infosUnread = [];
      this.infosRead = [];
      this.showInfosByAdvId();
    });
  }
}
