import { Component, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { apiUrl } from '@environments/env';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '@app/_services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsersService } from '../../services/users.service';
import { Role } from '@app/_models';
import { GroupsCreateComponent } from '../groups-create/groups-create.component';

import { io } from 'socket.io-client';

import { ChatserviceService } from '@app/services/chatservice.service';
import { ChildToParentService } from './../../services/child-to-parent.service';

@Component({
  selector: 'app-groups-list',
  templateUrl: './groups-list.component.html',
  styleUrls: ['./groups-list.component.scss'],

})
export class GroupsListComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('myDiv') myId: ElementRef<HTMLElement>;

  @Output() messagesNo2 = new EventEmitter<number>();

  activeGroup = undefined;
  currentUser = undefined;
  channels = [];
  directs = [];
  isAdmin = false;
  isMobile = false;


  isSidenavOpen = false;
  query = '';
  users = [];
  hide_group = false;
  hide_direct = false;
  count2 = 0

  rooms = []
  roomsID = []
  socket = undefined;

  idRoom
  comeFromNotif: boolean = false



  constructor(
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    private http: HttpClient,
    private location: Location,
    private sb: MatSnackBar,
    private sUsers: UsersService,
    private chatService: ChatserviceService,
    private childToParent: ChildToParentService

  ) {
    this.authenticationService.user.subscribe((x) => {
      if (x) {
        this.currentUser = x;
        this.isAdmin = (this.currentUser && this.currentUser.role === Role.Admin);
        this.socket = io(apiUrl.apiUrl, { transports: ['websocket'], query: { token: x.token, userId: x.id.toString() }});

        this.chatService.newMessageReceived().subscribe((data:any) => {
          this.getGroups()
        })

      }
    });
    if (window.screen.width <= 600) {
      this.isMobile = true;
    }
  }

  ngOnInit(): void {
    this.getUsers();
    this.getGroups();

    this.childToParent.idRoom$.subscribe((room) => {
      this.idRoom = room
    })

    this.childToParent.comeFromNotification.subscribe((comeFrom) => {
      this.comeFromNotif = comeFrom
    })

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if(this.comeFromNotif === true) {
        this.FakeClick(this.idRoom)
      }
    }, 6000);

  }

  ngOnDestroy(): void {
    this.idRoom = ''
    this.comeFromNotif = false
  }


  FakeClick(id) {
    let element: HTMLElement = document.getElementById(id) as HTMLElement;
    element.click()
  }


  getMessage(count) {
    this.messagesNo2 = count
    this.sb.open('nouveau message','', {
      duration: 2000,
      panelClass: ['edit-snackbar']
    });

  }

  onGroupDestroy(): void {
    this.activeGroup = undefined;
    this.getGroups();
  }

  getDirectGroupTitle(group: any): string {
    const member = group.members.find(m => m._id !== this.currentUser.id);
    return member ? member.username : '(Moi)';
  }

  async getGroups(): Promise<void> {
    try {

      const groups: any = await this.http.get(apiUrl.apiUrl + '/groups').toPromise();
      this.channels = groups.filter(g => g.type === 'channel');
      this.directs = groups.filter(g => g.type === 'direct');
      this.isSidenavOpen = false;
      const countUnread: any = await this.http.get(apiUrl.apiUrl + '/messages/count-unread').toPromise();

    } catch (error) { this.sb.open(error.message, 'Ok', { duration: 5000, verticalPosition: 'top' }); }
  }


  joinAllRoom() {
      this.rooms = this.channels.concat(this.directs)
      this.roomsID = this.rooms.map(room => room._id )

      this.roomsID.forEach(id => {
        this.socket.emit('app:room:join', `groups:${id}`);
      });
  }

  getUsers(): void {
    this.sUsers.getAllUsers().subscribe((data: any) => this.users = data.users);
  }

  goBack(): void {
    this.location.back();
  }

  promptCreateGroup(type: string): void {
    const dialogRef = this.dialog.open(GroupsCreateComponent, {
      data: { type, users: this.users },
    });
    dialogRef.afterClosed().subscribe((group) => {
      if (group && group.users.includes(this.currentUser.id)) this.activeGroup = group;
      this.getGroups();
    });
  }
}
