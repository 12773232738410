import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-part7',
  templateUrl: './part7.component.html',
  styleUrls: ['./part7.component.scss']
})
export class Part7Component implements OnInit {

  caseId

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.caseId = this.route.snapshot.params['id'];
  }
}
