<div class="message" [class.message--date]="message.type === 'date-break'">
  <div class="message__date-break" *ngIf="message.type === 'date-break'">
    <span>{{ message.text }}</span>
  </div>
  <div class="message__avatar" *ngIf="message.type !== 'date-break'">
    <span>{{ getInitials() }}</span>
  </div>
  <div class="message__content" *ngIf="message.type !== 'date-break'">
    <div class="message__top">
      <div class="message__username">{{ getUsername() }}</div>
      <div class="message__date">- {{ getHour() }}</div>
    </div>
    <div class="message__text" *ngIf="message.type === 'text'">{{ message.text }}</div>
    <div class="message__files" *ngIf="message.type === 'files'">
      <div class="message__file" *ngFor="let file of message.data.files">
        <img [src]="file.url" *ngIf="file.isImage">
        <a [href]="file.url" *ngIf="!file.isImage">
          <strong>{{ file.filename }}</strong>
          <i class="material-icons">cloud_download</i>
        </a>
      </div>
    </div>
  </div>
</div>
