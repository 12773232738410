import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[appAnimatePercentage]'
})
export class AnimatePercentageDirective implements OnInit {
    @Input() targetNumber: number = 0;
    @Input() duration: number = 2000;
    @Input() delay: number = 0;

    constructor(private el: ElementRef) { }

    ngOnInit() {
        setTimeout(() => {
            this.animateNumber(0, this.targetNumber, this.duration);
        }, this.delay);
    }

    private animateNumber(start: number, end: number, duration: number) {
        let startTime: number | null = null;

        const step = (timestamp: number) => {
            if (!startTime) startTime = timestamp;
            const progress = timestamp - startTime;
            const increment = Math.min(progress / duration, 1);
            const currentValue = Math.floor(increment * (end - start) + start);
            this.el.nativeElement.innerText = `+ ${currentValue} %`;

            if (progress < duration) {
                window.requestAnimationFrame(step);
            } else {
                this.el.nativeElement.innerText = `+ ${end} %`;
            }
        };

        window.requestAnimationFrame(step);
    }
}
