import { Component, OnInit } from '@angular/core';
import { AdminCases } from '@app/_models/AdminCases';
import { CasesService } from '@app/services/cases.service';
import { Chart } from 'chart.js';
import * as moment from 'moment';
import { AdminFinancementComponent } from '../admin-financement/admin-financement.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-dashboard-modal',
  templateUrl: './admin-dashboard-modal.component.html',
  styleUrls: ['./admin-dashboard-modal.component.scss']
})
export class AdminDashboardModalComponent implements OnInit {
  selectedCard = null;

  selectedMonth;
  selectedYear: number;
  selectedFirstYear;
  months = [
    { name: 'Aucun', value: 999 },
    { name: 'Janvier', value: 0 },
    { name: 'Fevrier', value: 1 },
    { name: 'Mars', value: 2 },
    { name: 'Avril', value: 3 },
    { name: 'Mai', value: 4 },
    { name: 'Juin', value: 5 },
    { name: 'Juillet', value: 6 },
    { name: 'Aout', value: 7 },
    { name: 'Septembre', value: 8 },
    { name: 'Octobre', value: 9 },
    { name: 'Novembre', value: 10 },
    { name: 'Décembre', value: 11 }];
  years = [2021, 2022, 2023, 2024];
  adminCases: AdminCases;
  commerciaux = [];
  commerciauxFiltres = []
  agences = [];
  globales = [];
  globalesAgeces = [];
  selectedCommerciaux = [];
  selectedAgences = [];
  firstYear:AdminCases;

  selectedCount = 0;

  constructor(private sCases: CasesService, private dialogRef:MatDialogRef<AdminFinancementComponent>) {
  }

  ngOnInit(): void {
  }

  onCheckboxChange(index): void {
    if (this.selectedCommerciaux[index]) {
      this.selectedCount++;
      if (this.selectedCount > 6) {
        this.selectedCommerciaux[index] = false;
        this.selectedCount--;
      }
    } else {
      this.selectedCount--;
    }

    this.commerciauxFiltres = this.filtrerCommerciaux(Object.keys(this.selectedCommerciaux));
  }

  onSelectAgence(index): void {
    if (this.selectedAgences[index]) {
      this.selectedCount++;
      if (this.selectedCount > 6) {
        this.selectedAgences[index] = false;
        this.selectedCount--;
      }
    } else {
      this.selectedCount--;
    }
  }

  selectCard(card: string) {
    this.selectedYear = moment().year();
    this.selectedMonth = moment().month();
    const formattedDate = moment().format('YYYY-MM-DD');

   
    this.selectedCard = card;
    if(card === 'globale') {
      this.selectedMonth = 999;
      this.showCasesBySelectedDate();
    }
    else {
      this.fetchDashboardData(formattedDate);
    }
  }

  public filtrerCommerciaux(noms) {
    return this.commerciaux.filter(function (commercial) {
      return noms.includes(commercial.commercial);
    });
  }

  //  this.dialogRef.closeAll();

  showCasesBySelectedDate() {
    if (this.selectedMonth !== 999) {
      const formattedDateFinal = moment([this.selectedYear, this.selectedMonth, moment().date()]);
      const formattedDateString = formattedDateFinal.format('YYYY-MM-DD');
      this.fetchDashboardData(formattedDateString);
    }
    else {
      // ajout d'un mois random
      const formattedDateFinal = moment([this.selectedYear, 1, moment().date()]);
      const formattedDateString = formattedDateFinal.format('YYYY-MM-DD');
      this.fetchDashboardData(formattedDateString, this.selectedYear)
    }
  }

  public fetchDashboardData(date: string, year?: number) {
    this.commerciaux = [];
    this.agences = [];
    this.globales = []
    this.sCases.getCasesByDate(date, year).subscribe(cases => {
      this.adminCases = cases;
      
      this.firstYear === undefined ? this.firstYear = this.adminCases : null;
      this.selectedFirstYear === undefined ? this.selectedFirstYear = this.selectedYear : null;
      this.adminCases.agences.forEach(agence => {
        this.commerciaux.push(...agence.commerciaux);
        this.agences.push(agence)
      });
    })
  }

  public initChart(choice) {

    const commerciaux = [];
    let agences = [];
    const totauxPriceHT = [];
    let annee1 = [];
    let annee2 = [];
    let labels = []

 
    // Étape 2 : Calcul du total des price_ht pour chaque commercial
    this.commerciauxFiltres.forEach((item) => {
      const commercial = item.commercial;
      const totalPriceHT = item.affaires.reduce((acc, affaire) => acc + affaire.price_ht, 0);

      commerciaux.push(commercial);
      totauxPriceHT.push(totalPriceHT);
    });
    if(choice === 'globales') {
      const agences = {};

      this.firstYear.agences.forEach(item => {
        const agence = item.agence;
        if (!agences[agence]) {
          agences[agence] = { firstYear: item.caNetTotal, adminCases: 0 };
        } else {
          agences[agence].firstYear += item.caNetTotal;
        }
      });
      
      this.adminCases.agences.forEach(item => {
        const agence = item.agence;
        if (!agences[agence]) {
          agences[agence] = { firstYear: 0, adminCases: item.caNetTotal };
        } else {
          agences[agence].adminCases += item.caNetTotal;
        }
      });
      
      labels = Object.keys(agences);
      annee1 = labels.map(agence => agences[agence].firstYear);
      annee2 = labels.map(agence => agences[agence].adminCases);
  }

    Object.keys(this.selectedAgences).filter((agence) => {
      agences.push(agence)
    });

    const totauxCaAgences = agences.map((selectedAgence) => {
      const agence = this.agences.find((agence) => agence.agence === selectedAgence);
      return agence ? agence.caNetTotal : 0;
    });

    const dataToReturn = {
      choice: choice,
      commerciaux: commerciaux,
      totauxPriceHt: totauxPriceHT,
      agences:agences,
      totauxCaAgences: totauxCaAgences,
      annee1:annee1,
      annee2:annee2,
      selectedFirstYear: this.selectedFirstYear,
      selectedYear: this.selectedYear,
      globaleAgences: labels,

  };

  this.dialogRef.close(dataToReturn);

  }
}