import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersService } from '@app/services/users.service';

@Component({
  selector: 'app-customer-modal',
  templateUrl: './customer-modal.component.html',
  styleUrls: ['./customer-modal.component.scss']
})
export class CustomerModalComponent {

  currentCustomer
  form: FormGroup;
  result = {
    name: '',
    login: null,
    password: null,
    client: true,
    _id: null
  }
  error = ''
  choice = false

  constructor(
    public dialogRef: MatDialogRef<CustomerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private sUsers: UsersService,
    private fb: FormBuilder,
  ) {
    this.currentCustomer = data.currentCase.customer;

    this.currentCustomer.mail === '' ? this.choice = false : this.choice = true
    this.form = this.fb.group({
      'login': [this.currentCustomer.mail],
      'password': [Math.random().toString(36).slice(-8)],
      'name': [''],
    })
  };


  onNoClick(): void {
    this.dialogRef.close();
  }
  saveLogin() {

    this.result.name = this.form.value.name
    this.result.login = this.form.value.login
    this.result.password = this.form.value.password
    this.result._id = this.currentCustomer._id
    if (this.result.login === '' || this.result.password === '' || this.result.name === '') {
      this.error = "Il manque des champs pour valider la création du compte"
    }
    else {
      this.sUsers.createUserClient(this.result).then(res => {
        if (res['error']) {
          alert(res.error);
        }
        else {
          this.dialogRef.close(this.result)
        }
      });
    }

  }



}
