<img
style="
    position: relative;
    left: 98%;
    width: 5%;
    cursor: pointer;
    margin-top: -1%;"
src="../../../assets/icons/cross.svg" (click)="closeModale()">
<div class="container-form">
  <form [formGroup]="leadAffaire">
    <div class="row">
      <div formGroupName="leadClient" class="col-6">
        <input
            formControlName="name"
            placeholder="NOM Prénom"
            id="name"
            type="text"
            class="form-control">
      </div>

      <div formGroupName="leadClient" class="col-6">
        <input
            formControlName="mobile"
            placeholder="Téléphone portable"
            id="mobile"
            type="number"
            class="form-control">
      </div>

    </div>

    <div class="row">
      <div formGroupName="leadClient" class="col-6">
        <input
            formControlName="mail"
            placeholder="E-mail"
            id="mail"
            type="mail"
            class="form-control" >
      </div>

      <div formGroupName="leadClient" class="col-6">
        <input
            formControlName="address"
            placeholder="Adresse"
            id="address"
            type="text"
            class="form-control" >
      </div>

    </div>

    <div class="row">
      <div formGroupName="leadClient" class="col-6">
        <input
            formControlName="cp"
            placeholder="Code postal"
            id="cp"
            type="number"
            class="form-control"
            placeholder="Code Postal">
      </div>

      <div formGroupName="leadClient" class="col-6">
        <input
            formControlName="city"
            placeholder="Ville"
            id="city"
            type="text"
            class="form-control">
      </div>
    </div>

    <div class="row">
        <mat-form-field formGroupName="leadClient" class="col-6" style="padding-left: 23px;">
          <mat-select type="text" formControlName="situation">
            <mat-option value="Propriétaire">Propriétaire</mat-option>
            <mat-option value="Locataire">Locataire</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field formGroupName="leadClient" class="col-6" style="padding-left: 8px;">
          <mat-select type="text" formControlName="situation" placeholder="Projet">
            <mat-option value="Climatisation">Climatisation</mat-option>
            <mat-option value="Pompe à chaleur">Pompe à chaleur</mat-option>
            <mat-option value="Solaire">Solaire</mat-option>
            <mat-option value="Isolation">Isolation</mat-option>
            <mat-option value="VMC">VMC</mat-option>
            <mat-option value="Eau chaude sanitaire">Eau chaude sanitaire</mat-option>
          </mat-select>
        </mat-form-field>
    </div>

    <div class="row">
      <div class="col-12">
        <textarea
          formControlName="description"
          placeholder="Description"
          id="description"
          type="text"
          class="form-control description mb-2"></textarea>
      </div>
    </div>

    </form>


</div>
<button class="btn-secondarycrm" (click)="addCustomer('a importé','un prospect manuellement', ' ')">Créer le prospect</button>
