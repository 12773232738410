import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-part11',
  templateUrl: './part11.component.html',
  styleUrls: ['./part11.component.scss']
})
export class Part11Component implements OnInit {

  public reponses = ['Fenêtres', 'Plancher', 'Murs', 'Toiture'];
  public question = 'Quelle est la <strong>plus grosse source de déperdition de chaleur</strong> dans une maison ?';
  public img = "./../../../assets/book/p11_image-right.png";
  caseId;

  public partieSuivante = "part12"

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.caseId = this.route.snapshot.params['id'];
  }
}