<p *ngIf="cases">Total : {{ cases.length }}</p>
<mat-progress-bar *ngIf="!rowData" mode="indeterminate"></mat-progress-bar>
<div style="margin: 10px 0;">
  <button class="btn-secondarycrm" (click)="onBtnExport()">
    Export
    <img src="../../../assets/icons/export-csv.svg">

  </button>
</div>
  <ag-grid-angular *ngIf="rowData != []"
    #agGrid
    style="width: 90vw; height: 70vh; margin-bottom: 5%;"
    id="myGrid"
    class="ag-theme-alpine"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="rowData"
    [rowStyle]="rowStyle"
    (gridReady)="onGridReady($event)"
    rowSelection="single"
    [animateRows]="true"
    (cellDoubleClicked)="onSelectionChanged($event)"
    (cellClicked)="showInfos($event)"
    [pagination]="true"
    [rowHeight]="rowHeight">
  </ag-grid-angular>


  <div class="row">
    <ul class="col-4">
      Chantiers
      <li *ngFor="let chantier of chantiers">{{chantier.title}}</li>
    </ul>
    <ul class="col-4">
      Pièces
      <li *ngFor="let piece of pieces">{{piece.title}}</li>
    </ul>
    <ul class="col-4">
      Tâches
      <li *ngFor="let task of tasks">{{task.message}} - target : {{ task.target.username }} - <p>{{ task.state }}</p></li>
    </ul>

  </div>

