<div class="groups" [class.mobile]="isMobile">
  <div class="groups__sidebar">
    <div class="groups__sidebar__back" (click)="goBack()">
      <i></i> Revenir à l'accueil
    </div>
    <div class="groups__sidebar__title">
      Groupe
      <i *ngIf="isAdmin" (click)="promptCreateGroup('channel')"></i>
    </div>
    <div class="groups__sidebar__search">
      <input type="text" placeholder="Chercher un groupe" [(ngModel)]="query" />
      <i></i>
    </div>
    <div class="groups__sidebar__list">
      <div *ngFor="let group of channels" >

        <div class="groups__sidebar__item" [class.active]="activeGroup && group._id === activeGroup._id" (click)="activeGroup = group; isSidenavOpen = false ; getGroups()" id={{group._id}}>
          <i class="top"></i>
          <span>
            <span>{{ group.title }}</span>
            <div *ngIf="group.countUnread">{{ group.countUnread }}</div>

          </span>
          <i class="bottom"></i>
        </div>

      </div>
    </div>
    <div class="groups__sidebar__title">
      Message direct
      <i (click)="promptCreateGroup('direct')"></i>
    </div>
    <div class="groups__sidebar__list">

      <div class="snippet" data-title=".dot-pulse" *ngIf="directs.length == 0">
        <div class="stage">
          <div class="dot-pulse"></div>
        </div>
      </div>
      <div  *ngFor="let group of directs">
        <div class="groups__sidebar__item" [class.active]="activeGroup && group._id === activeGroup._id" (click)="activeGroup = group; isSidenavOpen = false; getGroups()" id={{group._id}}>

           <i class="top"></i>
          <span>{{ getDirectGroupTitle(group) }}
            <div *ngIf="group.countUnread">{{ group.countUnread }}</div>
          </span>
          <i class="bottom"></i>
        </div>

      </div>
    </div>
  </div>
  <div class="groups__content" *ngIf="activeGroup">
    <app-groups-detail (messagesNo)="getMessage($event)" [group]="activeGroup" [users]="users" (back)="activeGroup = undefined" (destroy)="onGroupDestroy()"></app-groups-detail>
  </div>
</div>

