
<!-- <button (click)="showAllCases()">Accéder aux dossiers</button>

<ul *ngIf="showCases">
  <li *ngFor="let case of cases">{{ case.customer }}</li>
</ul> -->

<div class="contain">
  <div class="row empty_state">
    <div *ngIf="loading" class='col-2 empty_state'>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <p class="empty_block"><span></span></p>

    </div>
    <div *ngIf="loading" class='col-2 empty_state'>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <p class="empty_block"><span></span></p>
    </div>
    <div *ngIf="loading" class='col-2 empty_state'>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <p class="empty_block"><span></span></p>
    </div>

    <div *ngIf="loading" class='col-2 empty_state'>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <p class="empty_block"><span></span></p>
    </div>

    <div *ngIf="loading" class='col-2 empty_state'>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <p class="empty_block"><span></span></p>
    </div>
    <div *ngIf="loading" class='col-2 empty_state'>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <em><u class="circle"></u></em>
      <p class="empty_block"><span></span></p>
    </div>

    <div
    matTooltip="Dossiers signés pour lesquels il y a des pièces en attente de qualification , manquantes ou non conforme"
    matTooltipClass="custom-tooltip"
    [style.opacity]="!loading ? '' : 0" [ngClass]="attente_active ? 'active' : ''" class='col' id="attente" (click)="changeActive('attente_active')">
      <em id="attente_em3"><u class="circle"></u></em>
      <em id="attente_em4"><u class="circle"></u></em>
      <p>Signé <span *ngIf="attente">{{ attente.length }}</span></p>

    </div>
    <div
    matTooltip="Dossiers pour lesquels la demande en mairie est en cours"
    matTooltipClass="custom-tooltip"
    [style.opacity]="!loading ? '' : 0" [ngClass]="mairie_active ? 'active' : ''" id="mairie" class='col' (click)="changeActive('mairie_active')">
      <em id="mairie_em1"><u class="circle"></u></em>
      <em id="mairie_em2"><u class="circle"></u></em>
      <em id="mairie_em3"><u class="circle"></u></em>
      <em id="mairie_em4"><u class="circle"></u></em>
      <p>Demande en mairie <span *ngIf="mairie">{{ mairie.length }}</span></p>
    </div>

    <div
    matTooltip="Dossiers pour lesquels la FI n'est toujours pas validée ou les chèques non récupérés"
    matTooltipClass="custom-tooltip"
    [style.opacity]="!loading ? '' : 0" [ngClass]="financement_ec_active ? 'active' : ''" id="theDiv" class='col' id="financement_ec" (click)="changeActive('financement_ec_active')">
      <em id="financement_ec_em1"><u class="circle"></u></em>
      <em id="financement_ec_em2"><u class="circle"></u></em>
      <em id="financement_ec_em3"><u class="circle"></u></em>
      <em id="financement_ec_em4"><u class="circle"></u></em>
      <p>Demande financière <span *ngIf="financement_ec">{{ financement_ec.length }}</span></p>
      <div>
        <ul class="dots_completude" *ngIf="financement_ec && financement_ec.length > 0">
          <li>{{ financement_ec_encours }}</li>
          <li>{{ financement_ec_enrefus }}</li>
        </ul>
      </div>
    </div>

    <div
    matTooltip="Dossiers pour lesquels il faut fixer une date de pose"
    matTooltipClass="custom-tooltip"
    [style.opacity]="!loading ? '' : 0" [ngClass]="attente_pose_active ? 'active' : ''" class='col' id="attente_pose" (click)="changeActive('attente_pose_active')">
      <em id="attente_pose_em1"><u class="circle"></u></em>
      <em id="attente_pose_em2"><u class="circle"></u></em>
      <em id="attente_pose_em3"><u class="circle"></u></em>
      <em id="attente_pose_em4"><u class="circle"></u></em>
      <p>Attente date de pose <span *ngIf="attente_pose">{{ attente_pose.length }}</span></p>
    </div>

    <div
    matTooltip="Dossiers pour lesquels la FI est validée et les travaux sont fixés"
    matTooltipClass="custom-tooltip"
    [style.opacity]="!loading ? '' : 0" [ngClass]="financement_valid_active ? 'active' : ''" class='col' id="financement_valid" (click)="changeActive('financement_valid_active')">
      <em id="financement_valid_em1"><u class="circle"></u></em>
      <em id="financement_valid_em2"><u class="circle"></u></em>
      <em id="financement_valid_em3"><u class="circle"></u></em>
      <em id="financement_valid_em4"><u class="circle"></u></em>
      <p>Travaux en cours <span *ngIf="financement_valid">{{ financement_valid.length }}</span></p>
    </div>

    <div
    matTooltip="Dossiers pour lesquels il faut effectuer une demande de déblocage / récupérer les chèques de fin de chantier"
    matTooltipClass="custom-tooltip"
    [style.opacity]="!loading ? '' : 0" [ngClass]="deblocage_active ? 'active' : ''" class='col' id="deblocage" (click)="changeActive('deblocage_active')">
      <em id="deblocage_em1"><u class="circle"></u></em>
      <em id="deblocage_em2"><u class="circle"></u></em>
      <p>Attente de paiement <span *ngIf="deblocage">{{ deblocage.length }}</span></p>
    </div>
  </div>
</div>


  <div *ngIf="attente_active">
    <div class="row">
      <div class="col-md-4" *ngFor="let p of attente" (click)="goToCaseTemplate(p._id)">
        <div class="card p-4">
          <div class="card-block">
            <p class="qualif" style="background-color: #78C091;">Signée</p>
            <h6>Nom du client</h6>
            <p class="customer">{{ p.customer }}</p>

            <h6 class="mb-2">Kit vendu</h6>
            <app-product-card [kit]="p.kits_vendus"></app-product-card>
            <h6 class="mt-2">Pièce en attente de qualification</h6>
            <p><img src="../../../assets/yellow_dot.svg" class="mr-3">
              <span style="background-color: yellow;"></span>
              {{ getLengths(p._id,'En attente de qualification',attente)}} Pièce(s) en attente(s)
            </p>
            <p><img src="../../../assets/red_dot.svg" class="mr-3">
              <span style="background-color: #9F555C;"></span>
              {{ getLengths(p._id,'Manquante',attente)}} Pièce(s) manquante(s)
            </p>
            <p><img src="../../../assets/red_dot.svg" class="mr-3">
              <span style="background-color: #F45B69;"></span>
              {{ getLengths(p._id,'Non conforme',attente)}} Pièce(s) non conforme(s)
            </p>

            <div class="row">
              <div class="col-6 subcard">
                <h6 class="mt-3">Date de signature</h6>
                <p>{{ p.datesign |  date:'fullDate' }}</p>
              </div>

              <div class="col-6 subcard">
                <h6 class="mt-3">Type de financement</h6>
                <p>{{ p.financement_type | titlecase }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

    <div  *ngIf="mairie_active">
      <div class="row mt-5">
        <div class="col-md-4" *ngFor="let m of mairie" (click)="goToCaseTemplate(m._id)">
          <div class="card p-4">
            <div class="card-block">
              <p class="qualif" style="background-color: #f0c808;">{{ m.mairie.state }}</p>
              <h6>Nom du client</h6>
              <p class="customer">{{ m.customer }}</p>
              <h6 class="mb-2">Kit vendu</h6>
              <app-product-card [kit]="m.kits_vendus"></app-product-card>

              <div class="row mt-2">
                <div class="col-6 subcard">
                  <h6 class="mt-3">Date de dépôt </h6>
                  <p>{{ m.mairie.date_depot | date:'fullDate' }}</p>
                </div>

                <div class="col-6 subcard">
                  <h6 class="mt-3">Date accord tacite<span matBadge="abf" matBadgeOverlap="false" *ngIf="m.abf == 'true'"></span></h6>
                  <p>{{ m.tacite | date:'fullDate' }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


  <div *ngIf="financement_ec_active">

    <div class="row mt-5">
      <div class="col-md-4" *ngFor="let a of financement_ec" target="_blank" (click)="goToCaseTemplate(a._id)">
        <div class="card p-4">
          <div class="card-block">
            <p class="qualif" [ngStyle]="{'background-color':a.financement.state === 'En cours' ? '#f5cf26' : '#f45b69' }">{{ a.financement.state }}</p>
            <h6>Nom du client</h6>
            <p class="customer">{{ a.customer }}</p>
            <h6 class="mb-2">Kit vendu</h6>
            <app-product-card [kit]="a.kits_vendus"></app-product-card>

            <h6 class="mt-3">Date de dépôt de dossier</h6>
            <p>{{ a.financement.date_depot |  date:'fullDate' }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="attente_pose_active">
    <div class="row mt-5">
      <div class="col-md-4" *ngFor="let p of attente_pose" (click)="goToCaseTemplate(p._id)">
        <div class="card p-4">
          <div class="card-block">
            <p class="qualif" style="background-color: #f5cf26;">En attente de date</p>

            <h6>Nom du client</h6>
            <p class="customer">{{ p.customer }}</p>
            <h6 class="mb-2">Kit vendu</h6>
            <app-product-card [kit]="p.kits_vendus"></app-product-card>

            <div class="row mt-2">
              <div class="col-6 subcard" *ngIf="p.mairie">
                <h6 class="mt-3">Statut mairie - Date</h6>
                <p style="color: #78C091;">{{ p.mairie.state }} - {{ p.mairie.date_depot | date:'shortDate' }}</p>
              </div>

              <div class="col-6 subcard">
                <h6 class="mt-3">Statut fi - partenaire</h6>
                <p style="color: #78C091;">{{ p.financement.state }} - {{ p.financement.partenaire | titlecase }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-md-4" *ngFor="let comptant of financement_comptant" (click)="goToCaseTemplate(comptant._id)">
        <div class="card p-4">
          <div class="card-block">
            <p class="qualif" style="background-color: #f5cf26;">Attente</p>

            <h6>Nom du client</h6>
            <p class="customer">{{ comptant.customer }}</p>
            <h6 class="mb-2">Kit vendu</h6>
            <app-product-card [kit]="comptant.kits_vendus"></app-product-card>

            <div class="row mt-2">
              <div class="col-6 subcard">
                <h6 class="mt-3">Date de signature</h6>
                <p>{{ comptant.datesign |  date:'shortDate' }}</p>
              </div>

              <div class="col-6 subcard">
                <h6 class="mt-3">TTC - Déjà payé</h6>
                <p>{{ comptant.price_ttc }} - {{comptant.financement_comptant.cheque1.montant + comptant.financement_comptant.cheque2.montant + comptant.financement_comptant.cheque3.montant}}</p>
              </div>
            </div>

            <div class="row">
              <div class="col-6 subcard">
                <h6 class="mt-3">TTC - Déjà payé</h6>
                <p>{{ comptant.price_ttc }} - {{comptant.financement_comptant.cheque1.montant + comptant.financement_comptant.cheque2.montant + comptant.financement_comptant.cheque3.montant}}</p>
              </div>

            </div>
            <span style="color: lightgrey;position: absolute;
            font-size: 0.8em;
            right: 3%;
            bottom: 2%;">comptant</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="financement_valid_active">
    <div class="row mt-5">
      <div class="col-md-4" *ngFor="let p of financement_valid" (click)="goToCaseTemplate(p._id)">
        <div class="card p-4">
          <div class="card-block">
            <p class="qualif"style="background-color: #78C091;">{{ p.financement.state }}</p>

            <h6>Nom du client</h6>
            <p class="customer">{{ p.customer }}</p>
            <h6 class="mb-2">Kit vendu</h6>
            <app-product-card [kit]="p.kits_vendus"></app-product-card>


            <div class="row mt-2">
              <div class="col-6 subcard">
                <h6 class="mt-3">Date de la demande FI</h6>
                <p>{{ p.financement.date_depot |  date:'shortDate' }}</p>
              </div>

              <div class="col-6 subcard">
                <h6 class="mt-3">Fin de chantier prévue</h6>
                <p>{{ p.chantier.date |  date:'shortDate' }}</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="deblocage_active">
    <div class="row mt-5">
      <div class="col-md-4" *ngFor="let d of deblocage" (click)="goToCaseTemplate(d._id)">
        <div class="card p-4">
          <div class="card-block">
            <p class="qualif" style="background-color: #f5cf26;">Attente</p>

            <h6>Nom du client</h6>
            <p class="customer">{{ d.customer }}</p>
            <h6 class="mb-2">Kit vendu</h6>
            <app-product-card [kit]="d.kits_vendus"></app-product-card>

            <div class="row mt-2">
              <div class="col-6 subcard">
                <h6 class="mt-3">Partenaire fi </h6>
                <p>{{ d.financement.partenaire | titlecase }}</p>
              </div>

              <div class="col-6 subcard">
                <h6 class="mt-3">Date dépôt de dossier</h6>
                <p>{{ d.financement.date_depot |  date:'shortDate' }}</p>
              </div>
            </div>

            <div class="row">
              <div class="col-6 subcard">
                <h6 class="mt-3">Date de fin de chantier </h6>
                <p>{{ d.chantier.date | date:'shortDate' }}</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-md-4" *ngFor="let c of financement_comptant_attente_paiement" (click)="goToCaseTemplate(c._id)">
        <div class="card p-4">
          <div class="card-block">
            <p class="qualif" style="background-color: #f5cf26;">Attente</p>

            <h6>Nom du client</h6>
            <p class="customer">{{ c.customer }}</p>

            <h6 class="mb-2">Kit vendu</h6>
            <app-product-card [kit]="c.kits_vendus"></app-product-card>

            <div class="row mt-2">
              <div class="col-6 subcard">
                <h6 class="mt-3">Financement </h6>
                <p>{{ c.financement.financement_type | titlecase }}</p>
              </div>

              <div class="col-6 subcard">
                <h6 class="mt-3">Date dépôt de dossier</h6>
                <p>{{ c.financement.date_depot |  date:'shortDate' }}</p>
              </div>
            </div>

            <div class="row">
              <div class="col-6 subcard">
                <h6 class="mt-3">Date de fin de chantier </h6>
                <p>{{ c.chantier.date | date:'shortDate' }}</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


  </div>
