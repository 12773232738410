<h6>Signature</h6>
<div class="row mt-3">
  <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
    <label>Nom du signataire</label>
    <input class="custom-input mb-4" type="text" [value]="data.case.customer.name">
  </div>
</div>

<div id="captureTech" #surface class="signature col-sm-7">
  <signature-pad></signature-pad>
</div>
<button (click)="dispatch()" *ngIf="!isValid" class="btn-secondarycrm">Signer</button>

<app-loading *ngIf="loading"></app-loading>
