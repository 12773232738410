<mat-horizontal-stepper [linear]="true" #stepper>
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup">
      <ng-template matStepLabel>Fill out your name</ng-template>
      <div>
        <h4>Caisson isolé</h4>
        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Marque et type</label>
            <input class="custom-input" type="text" formControlName="marque_type" (change)="getCurrentKitAndSaveChange(this.firstFormGroup.value.marque_type,'marque_type')">
            <div *ngIf="firstFormGroup.get('marque_type').invalid && firstFormGroup.get('marque_type').errors && (firstFormGroup.get('marque_type').dirty || firstFormGroup.get('marque_type').touched)">
            <small class="text-danger"
                *ngIf="firstFormGroup.get('marque_type').hasError('required')">
                Champ requis
            </small>
            </div>
          </div>

        <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
          <div class="button-wrapper mt-1">
            <label>N° de série</label>
            <label class="upload-label" for="n_serie" *ngIf="!n_serie">+ Ajouter la photo</label>
             <p class="mb-0">
              <a target="blank" class="check" *ngIf="n_serie" [routerLink]="['/detail-piece', piece_serie[0]._id]">Photos n° de série</a>
            </p>
            <p *ngIf="n_serie" style="color: lightgrey;font-weight: 300; margin-top: 0; font-size: small;">Prise le {{ piece_serie[0].created_at | date:'medium' }}</p>

            <input *ngIf="!n_serie" accept=".jpg,.jpeg,.png" type="file" multiple name="n_serie" id="n_serie" class="upload-box" (change)="selectNSerie($event, 'N° de serie vmc')">
          </div>
        </div>
        <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
          <label>Désignation du local de pose</label>
          <input class="custom-input" type="text" formControlName="local_pose" (change)="getCurrentKitAndSaveChange(this.firstFormGroup.value.local_pose,'local_pose')">
          <div *ngIf="firstFormGroup.get('local_pose').invalid && firstFormGroup.get('local_pose').errors && (firstFormGroup.get('local_pose').dirty || firstFormGroup.get('local_pose').touched)">
            <small class="text-danger"
                *ngIf="firstFormGroup.get('local_pose').hasError('required')">
                Champ requis
            </small>
          </div>
        </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Longueur de la gaine entrée air neuf</label>
            <input class="custom-input"  type="number" formControlName="gaine_entree" (change)="getCurrentKitAndSaveChange(this.firstFormGroup.value.gaine_entree,'gaine_entree')">
            <div *ngIf="firstFormGroup.get('gaine_entree').invalid && firstFormGroup.get('gaine_entree').errors && (firstFormGroup.get('gaine_entree').dirty || firstFormGroup.get('gaine_entree').touched)">
            <small class="text-danger"
                *ngIf="firstFormGroup.get('gaine_entree').hasError('required')">
                Champ requis
            </small>
          </div>
          </div>
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Longueur de la gaine rejet d'air vide</label>
            <input class="custom-input"   type="number" formControlName="gaine_rejet" (change)="getCurrentKitAndSaveChange(this.firstFormGroup.value.gaine_rejet,'gaine_rejet')">
            <div *ngIf="firstFormGroup.get('gaine_rejet').invalid && firstFormGroup.get('gaine_rejet').errors && (firstFormGroup.get('gaine_rejet').dirty || firstFormGroup.get('gaine_rejet').touched)">
            <small class="text-danger"
                *ngIf="firstFormGroup.get('gaine_rejet').hasError('required')">
                Champ requis
            </small>
          </div>
          </div>
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Distance entre air vicié et air neuf</label>
            <input class="custom-input" type="number" formControlName="distance_air" (change)="getCurrentKitAndSaveChange(this.firstFormGroup.value.distance_air,'distance_air')">
            <div *ngIf="firstFormGroup.get('distance_air').invalid && firstFormGroup.get('distance_air').errors && (firstFormGroup.get('distance_air').dirty || firstFormGroup.get('distance_air').touched)">
            <small class="text-danger"
                *ngIf="firstFormGroup.get('distance_air').hasError('required')">
                Champ requis
            </small>
          </div>
          </div>
       </div>
      <br>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Cablâge électrique conforme:</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="cablage_conforme" (change)="getCurrentKitAndSaveChange($event.value,'cablage_conforme')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('cablage_conforme')">Non</mat-radio-button>
            </mat-radio-group>
            <div *ngIf="firstFormGroup.get('cablage_conforme').invalid && firstFormGroup.get('cablage_conforme').errors && (firstFormGroup.get('cablage_conforme').dirty || firstFormGroup.get('cablage_conforme').touched)">
              <small class="text-danger"
                  *ngIf="firstFormGroup.get('cablage_conforme').hasError('required')">
                  Champ requis
              </small>
            </div>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Type de fixation de l'unité intérieure:</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="fixation_ui" (change)="getCurrentKitAndSaveChange($event.value,'fixation_ui')">
              <mat-radio-button value="Suspension">En suspension</mat-radio-button>
              <mat-radio-button value="Fermette" class="ml-4">Sur fermette/charpente avec Silent Bloc </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Evacuation des condensats:</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="evacuation_condensat" (change)="getCurrentKitAndSaveChange($event.value,'evacuation_condensat')">
              <mat-radio-button value="Gravitaire">Gravitaire</mat-radio-button>
              <mat-radio-button value="Pompe" class="ml-4">Pompe</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin last-col">
            <mat-label>Signalisation SAV Enerconfort:</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="signalisation_sav" (change)="getCurrentKitAndSaveChange($event.value,'signalisation_sav')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('signalisation_sav')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

      <div>
        <button class="btn-secondarycrm" matStepperNext [style.opacity]="!firstFormGroup.valid ? '0.4' : '1'" (click)="updateData(50)">Suivant</button>
      </div>
    </div>

    </form>
  </mat-step>


  <mat-step [stepControl]="secondFormGroup">
    <form [formGroup]="secondFormGroup">
      <div>
        <h4>Bouches d'extraction</h4>
        <mat-tab-group [selectedIndex]="selected.value" (selectedTabChange)="tabClick($event)" (selectedIndexChange)="selected.setValue($event)">
          <mat-tab *ngFor="let tab of tabs; let index = index" [label]="tab.name">

          <div class="row">
            <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
              <label>Nom de la pièce</label>
              <input class="custom-input" type="text" formControlName="piece" (change)="editSelectedBouche(this.secondFormGroup.value.piece,'piece')">
            </div>

            <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
              <label>Longueur de la gaine</label>
              <input class="custom-input" type="text" formControlName="longueur_gaine" (change)="editSelectedBouche(this.secondFormGroup.value.longueur_gaine,'longueur_gaine')">
            </div>
          </div>

          </mat-tab>
        </mat-tab-group>
      </div>
          <button class="btn-secondarycrm" matStepperNext [style.opacity]="!secondFormGroup.valid ? '0.4' : '1'" (click)="updateData(75)">Suivant</button>
    </form>
  </mat-step>

   <mat-step [stepControl]="thirdFormGroup">
    <form [formGroup]="thirdFormGroup">
      <div>
        <h4>Bouches de soufflage</h4>
        <mat-tab-group [selectedIndex]="selected.value" (selectedTabChange)="tabClickSoufflage($event)" (selectedIndexChange)="selected_soufflage.setValue($event)">
          <mat-tab *ngFor="let tab of tabs_soufflage; let index = index" [label]="tab.name">

          <div class="row mt-4">
            <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
              <label>Nom de la pièce</label>
              <input class="custom-input" type="text" formControlName="piece" (change)="editSelectedBoucheSoufflage(this.thirdFormGroup.value.piece,'piece')">
            </div>

            <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
              <label>Longueur de la gaine</label>
              <input class="custom-input" type="text" formControlName="longueur_gaine" (change)="editSelectedBoucheSoufflage(this.thirdFormGroup.value.longueur_gaine,'longueur_gaine')">
            </div>

          </div>

          </mat-tab>
        </mat-tab-group>
      </div>
        <button class="btn-secondarycrm" matStepperNext [style.opacity]="!secondFormGroup.valid ? '0.4' : '1'" (click)="updateData(100)">Suivant</button>
    </form>
  </mat-step>

  <mat-step [stepControl]="fourFormGroup">
    <form [formGroup]="fourFormGroup">
      <div>
        <h4>Autre</h4>

        <div class="row mt-3">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Obturation des entrée d'air:</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="obturation" (change)="getCurrentKitAndSaveChange($event.value,'obturation')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('obturation')">Non</mat-radio-button>
            </mat-radio-group>
            <div *ngIf="fourFormGroup.get('obturation').invalid && fourFormGroup.get('obturation').errors && (fourFormGroup.get('obturation').dirty || fourFormGroup.get('obturation').touched)">
              <small class="text-danger"
                  *ngIf="fourFormGroup.get('obturation').hasError('required')">
                  Champ requis
              </small>
              </div>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Programmation de la télécommande:</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="telecommande" (change)="getCurrentKitAndSaveChange($event.value,'telecommande')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('telecommande')">Non</mat-radio-button>
            </mat-radio-group>
            <div *ngIf="fourFormGroup.get('telecommande').invalid && fourFormGroup.get('telecommande').errors && (fourFormGroup.get('telecommande').dirty || fourFormGroup.get('telecommande').touched)">
              <small class="text-danger"
                  *ngIf="fourFormGroup.get('telecommande').hasError('required')">
                  Champ requis
              </small>
              </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Documentation française remise au client:</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="documentation" (change)="getCurrentKitAndSaveChange($event.value,'documentation')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('documentation')">Non</mat-radio-button>
            </mat-radio-group>
            <div *ngIf="fourFormGroup.get('documentation').invalid && fourFormGroup.get('documentation').errors && (fourFormGroup.get('documentation').dirty || fourFormGroup.get('documentation').touched)">
              <small class="text-danger"
                  *ngIf="fourFormGroup.get('documentation').hasError('required')">
                  Champ requis
              </small>
              </div>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Signalisation SAV Enerconfort:</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="signalisation" (change)="getCurrentKitAndSaveChange($event.value,'signalisation')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('signalisation')">Non</mat-radio-button>
            </mat-radio-group>
            <div *ngIf="fourFormGroup.get('signalisation').invalid && fourFormGroup.get('signalisation').errors && (fourFormGroup.get('signalisation').dirty || fourFormGroup.get('signalisation').touched)">
              <small class="text-danger"
                  *ngIf="fourFormGroup.get('signalisation').hasError('required')">
                  Champ requis
              </small>
              </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Nettoyage chantier:</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="nettoyage" (change)="getCurrentKitAndSaveChange($event.value,'nettoyage')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('nettoyage')">Non</mat-radio-button>
            </mat-radio-group>
            <div *ngIf="fourFormGroup.get('nettoyage').invalid && fourFormGroup.get('nettoyage').errors && (fourFormGroup.get('nettoyage').dirty || fourFormGroup.get('nettoyage').touched)">
              <small class="text-danger"
                  *ngIf="fourFormGroup.get('nettoyage').hasError('required')">
                  Champ requis
              </small>
              </div>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Mise en oeuvre expliqué au client:</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="explication" (change)="getCurrentKitAndSaveChange($event.value,'explication')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('explication')">Non</mat-radio-button>
            </mat-radio-group>
            <div *ngIf="fourFormGroup.get('explication').invalid && fourFormGroup.get('explication').errors && (fourFormGroup.get('explication').dirty || fourFormGroup.get('explication').touched)">
              <small class="text-danger"
                  *ngIf="fourFormGroup.get('explication').hasError('required')">
                  Champ requis
              </small>
              </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 last-col nbr_obtu">
            <label>Nombre d'obturateurs posés</label>
            <div class="increment">
              <button
                class="rm"
                mat-button
                mat-icon-button
                matPrefix
                aria-label="Remove"
                (click)="incrementValueObturateur(-_step, currentKit.type,'edité', 'obturateur', currentKit.obturateur)">
                <mat-icon>remove</mat-icon>
              </button>
              <input type="number"[value]="currentKit.obturateur" />
              <button
                class="add"
                mat-button
                mat-icon-button
                matSuffix
                aria-label="Add"
                (click)="incrementValueObturateur(_step, currentKit.type,'edité', 'obturateur', currentKit.obturateur)"
                >
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
        </div>


      </div>
        <button class="btn-secondarycrm" *ngIf="!mobile" matStepperNext [style.opacity]="!fourFormGroup.valid ? '0.4' : '1'" (click)="checkPieceOfCible()">Suivant</button>
        <button class="btn-secondarycrm2" *ngIf="mobile" matStepperNext [style.opacity]="!fourFormGroup.valid ? '0.4' : '1'" (click)="checkPieceOfCible()">Suivant</button>

    </form>
  </mat-step>

  <mat-step>

    <textarea class="custom-textarea" placeholder="Observations" [(ngModel)]="currentKit.observation" (change)="getCurrentKitAndSaveChange('observation',case.observation)"></textarea>

    <ng-template matStepLabel>Done</ng-template>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Unité intérieure
        <label class="upload-label" for="upload" *ngIf="!ui"><mat-icon>add</mat-icon><span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="ui"></p>
        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="upload" class="upload-box" (change)="selectMultiple($event, 'Unité intérieure VMC')">
      </div>

      <div class="button-wrapper mt-3 col-lg-6">
        Circuit gaine air vicié
        <label class="upload-label" for="upload2" *ngIf="!circuit_vicie"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="circuit_vicie"></p>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="upload2" class="upload-box" (change)="selectMultiple($event, 'Circuit gaine air vicié VMC')">
      </div>
    </div>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Disjoncteur
        <label class="upload-label" for="upload3" *ngIf="!disjoncteur"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="disjoncteur"></p>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="upload3" class="upload-box" (change)="selectMultiple($event, 'Disjoncteur VMC')">

      </div>

      <div class="button-wrapper mt-3 col-lg-6">
        Cablage électrique
        <label class="upload-label" for="upload4" *ngIf="!cablage"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="cablage"></p>



        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="upload4" class="upload-box" (change)="selectMultiple($event, 'Cablage électrique VMC')">
      </div>

    </div>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Circuit gaine air neuf
        <label class="upload-label" for="upload5" *ngIf="!circuit_neuf"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="circuit_neuf"></p>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="upload5" class="upload-box" (change)="selectMultiple($event, 'Circuit gaine air neuf VMC')">
      </div>

    </div>

    <button *ngIf="!isValid" (click)="valid()" class="btn-secondarycrm">Confirmer</button>
  </mat-step>
</mat-horizontal-stepper>
<app-loading *ngIf="loader" style="position: absolute;"></app-loading>
