<mat-horizontal-stepper [linear]="true" #stepper>
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup">
      <ng-template matStepLabel>Fill out your name</ng-template>

      <div>
        <h6>Unité extérieure</h6>
        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Marque et type</label>
            <input class="custom-input" type="text" formControlName="marque_type_ue" (change)="getCurrentKitAndSaveChange(this.firstFormGroup.value.marque_type_ue,'marque_type_ue')">
            <div *ngIf="firstFormGroup.get('marque_type_ue').invalid && firstFormGroup.get('marque_type_ue').errors && (firstFormGroup.get('marque_type_ue').dirty || firstFormGroup.get('marque_type_ue').touched)">
            <small class="text-danger"
                *ngIf="firstFormGroup.get('marque_type_ue').hasError('required')">
                Champ requis
            </small>
            </div>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <div class="button-wrapper mt-1">
              <label>N° de série</label>
              <label class="upload-label" for="n_serie_pacaa_ue" *ngIf="!n_serie_pacaa_ue">+ Ajouter la photo</label>
              <p class="mb-0">
                <a target="blank" class="check" *ngIf="n_serie_pacaa_ue" [routerLink]="['/detail-piece', piece_serie_pacaa_ue[0]._id]">Photos n° de série</a>
              </p>
              <p *ngIf="n_serie_pacaa_ue" style="color: lightgrey;font-weight: 300; margin-top: 0;
              font-size: small;">Prise le {{ piece_serie_pacaa_ue[0].created_at | date:'medium' }}</p>

              <input *ngIf="!n_serie_pacaa_ue" accept=".jpg,.jpeg,.png" multiple type="file" multiple name="n_serie_pacaa_ue" id="n_serie_pacaa_ue" class="upload-box" (change)="selectMultipleNserie($event, 'N° de serie UE PAC Air Air','n_serie_pacaa_ue')">
            </div>
          </div>

<!--           <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Pose</label>
            <mat-form-field>
              <mat-select formControlName="type_pose_ue" (selectionChange)="addOption(currentKit.type,'edité', 'type_pose_ue', 'type_pose_ue', firstFormGroup.value.type_pose_ue)">
                <mat-option *ngFor="let pose of poses" [value]="pose">
                  {{ pose }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Espace dissipation chaleur respecté : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="dissipation_respecte" (change)="getCurrentKitAndSaveChange($event.value,'dissipation_respecte')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('dissipation_respecte')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Présence de silent bloc : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="silent_bloc" (change)="getCurrentKitAndSaveChange($event.value,'silent_bloc')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('silent_bloc')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Installation sur fixation normée : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="norme_fixation" (change)="getCurrentKitAndSaveChange($event.value,'norme_fixation')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('norme_fixation')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Présence de masque entrée/ sortie d’air : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="masque_entree_sortie" (change)="getCurrentKitAndSaveChange($event.value,'masque_entree_sortie')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('masque_entree_sortie')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Référence fluide et poids</label>
            <input class="custom-input"  type="number" formControlName="ref_fluide_poids" (change)="getCurrentKitAndSaveChange(this.firstFormGroup.value.ref_fluide_poids,'ref_fluide_poids')">
            <div *ngIf="firstFormGroup.get('ref_fluide_poids').invalid && firstFormGroup.get('ref_fluide_poids').errors && (firstFormGroup.get('ref_fluide_poids').dirty || firstFormGroup.get('ref_fluide_poids').touched)">
            <small class="text-danger"
                *ngIf="firstFormGroup.get('ref_fluide_poids').hasError('required')">
                Champ requis
            </small>
          </div>
          </div>
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Puissance chaud</label>
            <input class="custom-input"   type="number" formControlName="puissance_chaud" (change)="getCurrentKitAndSaveChange(this.firstFormGroup.value.puissance_chaud,'puissance_chaud')">
            <div *ngIf="firstFormGroup.get('puissance_chaud').invalid && firstFormGroup.get('puissance_chaud').errors && (firstFormGroup.get('puissance_chaud').dirty || firstFormGroup.get('puissance_chaud').touched)">
            <small class="text-danger"
                *ngIf="firstFormGroup.get('puissance_chaud').hasError('required')">
                Champ requis
            </small>
          </div>
          </div>
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Puissance froid</label>
            <input class="custom-input" type="number" formControlName="puissance_froid" (change)="getCurrentKitAndSaveChange(this.firstFormGroup.value.puissance_froid,'puissance_froid')">
            <div *ngIf="firstFormGroup.get('puissance_froid').invalid && firstFormGroup.get('puissance_froid').errors && (firstFormGroup.get('puissance_froid').dirty || firstFormGroup.get('puissance_froid').touched)">
            <small class="text-danger"
                *ngIf="firstFormGroup.get('puissance_froid').hasError('required')">
                Champ requis
            </small>
          </div>
          </div>
       </div>

       <div class="row">
        <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
          <label>Mode froid T° fluide</label>
          <input class="custom-input"  type="number" formControlName="mode_froid" (change)="getCurrentKitAndSaveChange(this.firstFormGroup.value.mode_froid,'mode_froid')">
          <div *ngIf="firstFormGroup.get('mode_froid').invalid && firstFormGroup.get('mode_froid').errors && (firstFormGroup.get('mode_froid').dirty || firstFormGroup.get('mode_froid').touched)">
          <small class="text-danger"
              *ngIf="firstFormGroup.get('mode_froid').hasError('required')">
              Champ requis
          </small>
        </div>
        </div>
        <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
          <label>Mode chaud T° fluide</label>
          <input class="custom-input"   type="number" formControlName="mode_chaud" (change)="getCurrentKitAndSaveChange(this.firstFormGroup.value.mode_chaud,'mode_chaud')">
          <div *ngIf="firstFormGroup.get('mode_chaud').invalid && firstFormGroup.get('mode_chaud').errors && (firstFormGroup.get('mode_chaud').dirty || firstFormGroup.get('mode_chaud').touched)">
          <small class="text-danger"
              *ngIf="firstFormGroup.get('mode_chaud').hasError('required')">
              Champ requis
          </small>
        </div>
        </div>

        <div class="col-lg-3 col-s-6 col-xs-6 custom-col radio-choice last-col">
          <mat-label>Cablâge électrique conforme:</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="cablage_conforme" (change)="getCurrentKitAndSaveChange($event.value,'cablage_conforme')">
            <mat-radio-button value="oui">Oui</mat-radio-button>
            <mat-radio-button value="non" class="ml-4" (click)="openDialog('cablage_conforme')">Non</mat-radio-button>
          </mat-radio-group>
        </div>
     </div>
      <br>

      <div>
        <button class="btn-secondarycrm" matStepperNext [style.opacity]="!firstFormGroup.valid ? '0.4' : '1'" (click)="updateData(40)">Suivant</button>
      </div>
    </div>

    </form>
  </mat-step>


  <mat-step [stepControl]="secondFormGroup">
    <form [formGroup]="secondFormGroup">
      <div>
        <h6>Unité intérieure</h6>
        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
            <label>Marque et type</label>
            <input class="custom-input" type="text" formControlName="marque_type_ui" (change)="getCurrentKitAndSaveChange(this.secondFormGroup.value.marque_type_ui,'marque_type_ui')">
            <div *ngIf="secondFormGroup.get('marque_type_ui').invalid && secondFormGroup.get('marque_type_ui').errors && (secondFormGroup.get('marque_type_ui').dirty || secondFormGroup.get('marque_type_ui').touched)">
            <small class="text-danger"
                *ngIf="secondFormGroup.get('marque_type_ui').hasError('required')">
                Champ requis
            </small>
            </div>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <div class="button-wrapper mt-1">
              <label>N° de série</label>
              <label class="upload-label" for="n_serie_pacaa_ui" *ngIf="!n_serie_pacaa_ui">+ Ajouter la photo</label>
              <p class="mb-0">
                <a target="blank" class="check" *ngIf="n_serie_pacaa_ui" [routerLink]="['/detail-piece', piece_serie_pacaa_ui[0]._id]">Photos n° de série</a>
              </p>
              <p *ngIf="n_serie_pacaa_ui" style="color: lightgrey;font-weight: 300; margin-top: 0;
              font-size: small;">Prise le {{ piece_serie_pacaa_ui[0].created_at | date:'medium' }}</p>

              <input *ngIf="!n_serie_pacaa_ui" accept=".jpg,.jpeg,.png" type="file" multiple name="n_serie_pacaa_ui" id="n_serie_pacaa_ui" class="upload-box" (change)="selectMultipleNserie($event, 'N° de serie UI PAC Air Air','n_serie_pacaa_ui')">
            </div>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
            <label>Désignation local de pose</label>
            <input class="custom-input" type="text" formControlName="local_pose" (change)="getCurrentKitAndSaveChange(this.secondFormGroup.value.local_pose,'local_pose')">
            <div *ngIf="secondFormGroup.get('local_pose').invalid && secondFormGroup.get('local_pose').errors && (secondFormGroup.get('local_pose').dirty || secondFormGroup.get('local_pose').touched)">
            <small class="text-danger"
                *ngIf="secondFormGroup.get('local_pose').hasError('required')">
                Champ requis
            </small>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Installation en suspension</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="install_suspension" (change)="getCurrentKitAndSaveChange($event.value,'install_suspension')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('install_suspension')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Cablage électrique conforme: </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="acces_comble" (change)="getCurrentKitAndSaveChange($event.value,'acces_comble')">
              <mat-radio-button value="Trappe d'accès">Trappe d'accès</mat-radio-button>
              <mat-radio-button value="Toit" class="ml-4">Toit</mat-radio-button>
              <mat-radio-button value="Autre" class="ml-4">Autre</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Installation horizontale:</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="install_horizontale" (change)="getCurrentKitAndSaveChange($event.value,'install_horizontale')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('install_horizontale')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Evacuation des condensats: </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="evac_condensat" (change)="getCurrentKitAndSaveChange($event.value,'evac_condensat')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice last-col">
            <mat-label>Fixation des gaines à l'unité:</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="fixation_gaine" (change)="getCurrentKitAndSaveChange($event.value,'fixation_gaine')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('fixation_gaine')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

        </div>

    </div>
        <div>
          <button class="btn-secondarycrm" matStepperNext [style.opacity]="!secondFormGroup.valid ? '0.4' : '1'" (click)="updateData(60)">Suivant</button>
        </div>
    </form>
  </mat-step>



<mat-step [stepControl]="thirdFormGroup">
  <form [formGroup]="thirdFormGroup">
    <div>
      <h6>Autre</h6>
      <div class="row">
        <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
          <label>Longueur de la liaison frigo</label>
          <input class="custom-input" type="text" formControlName="liaison_frigo" (change)="getCurrentKitAndSaveChange(this.thirdFormGroup.value.liaison_frigo,'liaison_frigo')">
          <div *ngIf="thirdFormGroup.get('liaison_frigo').invalid && thirdFormGroup.get('liaison_frigo').errors && (thirdFormGroup.get('liaison_frigo').dirty || thirdFormGroup.get('liaison_frigo').touched)">
          <small class="text-danger"
              *ngIf="thirdFormGroup.get('liaison_frigo').hasError('required')">
              Champ requis
          </small>
          </div>
        </div>

        <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
          <label>Dénivelé(s) liaison frigo</label>
          <input class="custom-input" type="text" formControlName="denivele_liaison_frigo" (change)="getCurrentKitAndSaveChange(this.thirdFormGroup.value.denivele_liaison_frigo,'denivele_liaison_frigo')">
          <div *ngIf="thirdFormGroup.get('denivele_liaison_frigo').invalid && thirdFormGroup.get('denivele_liaison_frigo').errors && (thirdFormGroup.get('denivele_liaison_frigo').dirty || thirdFormGroup.get('denivele_liaison_frigo').touched)">
          <small class="text-danger"
              *ngIf="thirdFormGroup.get('denivele_liaison_frigo').hasError('required')">
              Champ requis
          </small>
          </div>
        </div>

        <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
          <label>Local d'installation télécommande</label>
          <input class="custom-input" type="text" formControlName="local_telecommande" (change)="getCurrentKitAndSaveChange(this.thirdFormGroup.value.local_telecommande,'local_telecommande')">
          <div *ngIf="thirdFormGroup.get('local_telecommande').invalid && thirdFormGroup.get('local_telecommande').errors && (thirdFormGroup.get('local_telecommande').dirty || thirdFormGroup.get('local_telecommande').touched)">
          <small class="text-danger"
              *ngIf="thirdFormGroup.get('local_telecommande').hasError('required')">
              Champ requis
          </small>
          </div>
        </div>


      </div>

      <div class="row">
        <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Tirage à vide liaison frigo :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="tirage_liaison_frigo" (change)="getCurrentKitAndSaveChange($event.value,'tirage_liaison_frigo')">
            <mat-radio-button value="oui">Oui</mat-radio-button>
            <mat-radio-button value="non" class="ml-4" (click)="openDialog('tirage_liaison_frigo')">Non</mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Mise en oeuvre expliquée au client</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="mise_oeuvre" (change)="getCurrentKitAndSaveChange($event.value,'mise_oeuvre')">
            <mat-radio-button value="oui">Oui</mat-radio-button>
            <mat-radio-button value="non" class="ml-4" (click)="openDialog('mise_oeuvre')">Non</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Test étanchéité à l'azote : </mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="etancheite_azote" (change)="getCurrentKitAndSaveChange($event.value,'etancheite_azote')">
            <mat-radio-button value="oui">Oui</mat-radio-button>
            <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Nettoyage chantier </mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="nettoyage" (change)="getCurrentKitAndSaveChange($event.value,'nettoyage')">
            <mat-radio-button value="oui">Oui</mat-radio-button>
            <mat-radio-button value="non" class="ml-4" (click)="openDialog('nettoyage')">Non</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Étanchéité au passage de la liaison frigorigique / bâtiment :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="etancheite_ue" (change)="getCurrentKitAndSaveChange($event.value,'etancheite_ue')">
            <mat-radio-button value="oui">Oui</mat-radio-button>
            <mat-radio-button value="non" class="ml-4" (click)="openDialog('etancheite_ue')">Non</mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Test étanchéité au détecteur : </mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="etancheite_detecteur" (change)="getCurrentKitAndSaveChange($event.value,'etancheite_detecteur')">
            <mat-radio-button value="oui">Oui</mat-radio-button>
            <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Disjoncteur PAC repéré sur le tableau électrique : </mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="disjoncteur_pac" (change)="getCurrentKitAndSaveChange($event.value,'disjoncteur_pac')">
            <mat-radio-button value="oui">Oui</mat-radio-button>
            <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Télécomande sur mur intérieur :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="telecommande" (change)="getCurrentKitAndSaveChange($event.value,'telecommande')">
            <mat-radio-button value="oui">Oui</mat-radio-button>
            <mat-radio-button value="non" class="ml-4" (click)="openDialog('telecommande')">Non</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>


      <div class="row">
        <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice last-col">
          <mat-label>Document remise au client</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="documentation" (change)="getCurrentKitAndSaveChange($event.value,'documentation')">
            <mat-radio-button value="oui">Oui</mat-radio-button>
            <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    <br>

    <div>
      <button class="btn-secondarycrm" matStepperNext [style.opacity]="!thirdFormGroup.valid ? '0.4' : '1'" (click)="updateData(80)">Suivant</button>
    </div>
  </div>
  </form>
</mat-step>


  <mat-step [stepControl]="fourFormGroup">
    <form [formGroup]="fourFormGroup">
      <div>
        <h6>Bouche d'insuflation</h6>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Programmation de la sonde</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="prog_sonde" (change)="getCurrentKitAndSaveChange($event.value,'prog_sonde')">
              <mat-radio-button value="Unité intérieure">Unité intérieure</mat-radio-button>
              <mat-radio-button value="Télécommande" class="ml-4">Télécommande</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <mat-tab-group [selectedIndex]="selected.value" (selectedTabChange)="tabClick($event)" (selectedIndexChange)="selected.setValue($event)">
          <mat-tab *ngFor="let tab of tabs_bouches; let index = index" [label]="tab.name">
          <div class="row">
            <div class="col-lg-3 col-s-6 col-xs-6 custom-col four-col">
              <label>Local de pose</label>
              <input class="custom-input" type="text" formControlName="local_pose" (change)="editSelectedInsuflation(this.fourFormGroup.value.local_pose,'local_pose')">
            </div>
            <div class="col-lg-3 col-s-6 col-xs-6 custom-col four-col">
              <label>Temperature de soufflage à la bouche - Mode chaud</label>
              <input class="custom-input" type="number" formControlName="temp_chaud" (change)="editSelectedInsuflation(this.fourFormGroup.value.temp_chaud,'temp_chaud')">
            </div>
            <div class="col-lg-3 col-s-6 col-xs-6 custom-col four-col">
              <label>Temperature de soufflage à la bouche - Mode froid</label>
              <input class="custom-input" type="number" formControlName="temp_froid" (change)="editSelectedInsuflation(this.fourFormGroup.value.temp_froid,'temp_froid')">
            </div>
          </div>


          <div class="row">
            <div class="col-lg-3 col-s-6 col-xs-6 custom-col radio-choice">
              <mat-label>Volet de réglage </mat-label>
              <mat-radio-group aria-label="Select an option" formControlName="volet" (change)="editSelectedInsuflation(this.fourFormGroup.value.volet,'volet')">
                <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
                <mat-radio-button value="Sans TK" class="ml-4">Sans TK</mat-radio-button>
                <mat-radio-button value="Avec TK" class="ml-4">Avec TK</mat-radio-button>
              </mat-radio-group>
            </div>

            <div class="col-lg-3 col-s-6 col-xs-6 custom-col radio-choice">
              <mat-label>T° de consigne mode chaud atteint ? </mat-label>
              <mat-radio-group aria-label="Select an option" formControlName="temp_chaud_atteint" (change)="editSelectedInsuflation(this.fourFormGroup.value.temp_chaud_atteint,'temp_chaud_atteint')">
                <mat-radio-button value="oui">Oui</mat-radio-button>
                <mat-radio-button value="non" class="ml-4" (click)="openDialog('temp_chaud_atteint')">Non</mat-radio-button>
              </mat-radio-group>
              <div *ngIf="fourFormGroup.get('temp_chaud_atteint').invalid && fourFormGroup.get('temp_chaud_atteint').errors && (fourFormGroup.get('temp_chaud_atteint').dirty || fourFormGroup.get('temp_chaud_atteint').touched)">
                <small class="text-danger"
                    *ngIf="fourFormGroup.get('temp_chaud_atteint').hasError('required')">
                    Champ requis
                </small>
              </div>
            </div>

            <div class="col-lg-3 col-s-6 col-xs-6 custom-col radio-choice last-col">
              <mat-label>T° de consigne mode froid atteint ? </mat-label>
              <mat-radio-group aria-label="Select an option" formControlName="temp_froid_atteint" (change)="editSelectedInsuflation(this.fourFormGroup.value.temp_froid_atteint,'temp_froid_atteint')">
                <mat-radio-button value="oui">Oui</mat-radio-button>
                <mat-radio-button value="non" class="ml-4" (click)="openDialog('temp_froid_atteint')">Non</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          </mat-tab>
        </mat-tab-group>
      </div>
        <div>
          <button class="btn-secondarycrm" matStepperNext [style.opacity]="!fourFormGroup.valid ? '0.4' : '1'" (click)="updateData(100)">Suivant</button>
        </div>
    </form>
  </mat-step>


  <mat-step>
      <div class="row">
        <div class="col-lg-3 col-s-6 col-xs-6 custom-col mt-3" *ngFor="let bouche of currentKit.bouches_reprise;let i = index;">
          <h5>{{ bouche.name }}</h5>
          <label>Local de pose</label>
          <input class="custom-input" type="text" [value]="bouche.local_pose" [(ngModel)]="bouche.local_pose" (change)="test(bouche.name,$event.target.value)">
        </div>
      </div>
        <button class="btn-secondarycrm" matStepperNext [style.opacity]="!fourFormGroup.valid ? '0.4' : '1'" (click)="updateData(100)">Suivant</button>
  </mat-step>

  <mat-step>

    <textarea class="custom-textarea" placeholder="Observations" [(ngModel)]="currentKit.observation" (change)="getCurrentKitAndSaveChange('observation',case.observation)"></textarea>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Tuyau et calorifugeage
        <label class="upload-label" for="uploadtuyau" *ngIf="!tuyau"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="tuyau"></p>
        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="uploadtuyau" class="upload-box" (change)="selectMultiple($event, 'Tuyau et calorifugeage')">
      </div>

      <div class="button-wrapper mt-3 col-lg-6">
        Sortie des condensats
        <label class="upload-label" for="uploadcondensat" *ngIf="!condensat"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="condensat"></p>
        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="uploadcondensat" class="upload-box" (change)="selectMultiple($event, 'Sortie des condensats')">
      </div>

    </div>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Photo unité extérieure

        <label class="upload-label" for="uploadue" *ngIf="!ue"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="ue"></p>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="uploadue" class="upload-box" (change)="selectMultiple($event, 'Photo unité extérieure')">
      </div>

      <div class="button-wrapper mt-3 col-lg-6">
        Réseau comble aéroliques
        <label class="upload-label" for="uploadreseau_comble" *ngIf="!reseau_comble"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="reseau_comble"></p>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="uploadreseau_comble" class="upload-box" (change)="selectMultiple($event, 'Réseau comble aéroliques')">
      </div>

    </div>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Photo unité intérieure
        <label class="upload-label" for="uploadui" *ngIf="!ui"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="ui"></p>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="uploadui" class="upload-box" (change)="selectMultiple($event, 'Photo unité intérieure')">
      </div>

    </div>


    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Disjoncteur
        <label class="upload-label" for="uploaddisjoncteur" *ngIf="!disjoncteur"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="disjoncteur"></p>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="uploaddisjoncteur" class="upload-box" (change)="selectMultiple($event, 'Disjoncteur')">
      </div>
    </div>

    <div>
      <button *ngIf="!isValid" (click)="valid()" class="btn-secondarycrm">Confirmer</button>
    </div>
  </mat-step>

</mat-horizontal-stepper>
