import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrl } from '@environments/env';

@Injectable({
  providedIn: 'root',
})
export class PdfsService {
  url = apiUrl.apiUrl + '/pdf/';
  //url = 'http://localhost:4000/pdf/';

  headers = { 'Content-Type': 'application/json' };
  headers_base64 = { 'Content-Type': 'multipart/form-data' };

  getLocal;
  getBBC;
  getDPE;
  getFacture2;
  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.getLocal = JSON.parse(localStorage.getItem('book'));
    this.getBBC = this.getLocal['BBC'];
    this.getDPE = this.getLocal['DPE'];
    this.getFacture2 = this.getLocal['facture2'];
  }

  async generateBook(lastBook) {
    return this.http
      .post(this.url + 'getBook', JSON.stringify(lastBook), {
        headers: this.headers,
      })
      .toPromise()
      .then((res) => {
        res as any;
        this.getPdfBook().then((res) => {
          window.open(
            'https://pegase-api.herokuapp.com/pdf/downloadPdf',
            '_self'
          );
        });
      });
  }

  async getPdfBook() {
    return this.http.get('https://pegase-api.herokuapp.com/pdf/downloadPdf');
  }

  async generateRecette(
    chantierId,
    cible,
    currentCase,
    currentKit,
    signature,
    signatureTech
  ) {
    const data = {
      currentCase: currentCase,
      chantierId: chantierId,
      cible: cible,
      currentKit: currentKit,
      signature: signature,
      signatureTech: signatureTech,
    };
    return this.http
      .post(this.url + 'getRecetteImg', JSON.stringify(data), {
        headers: this.headers,
      })
      .toPromise()
      .then((res) => res as any);
  }

  async generateLivraison(
    cible,
    currentCase,
    currentKit,
    signature,
    signatureTech,
    chantierId
  ) {
    const data = {
      currentCase: currentCase,
      cible: cible,
      currentKit: currentKit,
      signature: signature,
      signatureTech: signatureTech,
      chantierId: chantierId,
    };

    return this.http
      .post(this.url + 'getLivraison', JSON.stringify(data), {
        headers: this.headers,
      })
      .toPromise()
      .then((res) => res as any);
  }

  async generateBi(
    cible,
    currentCase,
    form,
    signature,
    signatureTech,
    chantierId
  ) {
    const data = {
      cible: cible,
      currentCase: currentCase,
      signature: signature,
      signatureTech: signatureTech,
      chantierId: chantierId,
      form: form,
    };

    return this.http
      .post(this.url + 'getBi', JSON.stringify(data), { headers: this.headers })
      .toPromise()
      .then((res) => res as any);
  }

  async generateBiCm(
    cible,
    currentCase,
    form,
    signature,
    signatureTech,
    chantierId,
    formulaireByType
  ) {
    const data = {
      cible: cible,
      currentCase: currentCase,
      signature: signature,
      signatureTech: signatureTech,
      chantierId: chantierId,
      form: form,
      formulaireByType,
    };

    return this.http
      .post(this.url + 'getBiCm', JSON.stringify(data), {
        headers: this.headers,
      })
      .toPromise()
      .then((res) => res as any);
  }

  async generateSignature(signature, signatureTech) {
    const data = { signature: signature, signatureTech: signatureTech };
    return this.http
      .post(this.url + 'generateSignature', JSON.stringify(data), {
        headers: this.headers,
      })
      .toPromise()
      .then((res) => res as any);
  }

  async generateReception(
    cible,
    currentCase,
    currentKit,
    signature,
    signatureTech,
    chantierId
  ) {
    const data = {
      currentCase: currentCase,
      cible: cible,
      currentKit: currentKit,
      signature: signature,
      signatureTech: signatureTech,
      chantierId: chantierId,
    };

    return this.http
      .post(this.url + 'getReception', JSON.stringify(data), {
        headers: this.headers,
      })
      .toPromise()
      .then((res) => res as any);
  }
}
