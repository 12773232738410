import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { apiUrl } from '@environments/env';
import * as ss from 'socket.io-stream/socket.io-stream.js';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  $file = undefined;

  constructor() { }

  upload(isMultiple, folder): Promise<any> {
    if (this.$file) this.$file.remove();

    this.$file = document.createElement('input');
    this.$file.setAttribute('type', 'file');
    this.$file.setAttribute('accept', '.jpg,.jpeg,.png,.pdf');
    if (isMultiple) this.$file.setAttribute('multiple', '');
    this.$file.setAttribute('style', 'visibility: hidden; position: absolute; margin: 0; padding: 0; max-width: 0; max-height: 0;');
    document.body.appendChild(this.$file);

    return new Promise((resolve, reject) => {
      this.$file.onchange = () => this._uploadMultiple(folder)
        .then(resolve)
        .catch(reject);
      this.$file.onblur = () => {
        if (Array.from(this.$file.files).length === 0) reject('No file(s) selected.');
      };
      this.$file.oncancel = () => reject('Canceled.');
      this.$file.onabort = () => reject('Aborted.');

      if (window) {
        window.onfocus = () => {
          setTimeout(() => {
            if (Array.from(this.$file.files).length === 0) reject('No file(s) selected.');
            window.onfocus = null;
          }, 1000);
        };
      }
      this.$file.click();
    });
  }

  private async _uploadMultiple(folder): Promise<any> {
    const filesToUpload = Array.from(this.$file.files);
    if (filesToUpload.length === 0) throw new Error('No file(s) selected.');

    const promises = [];
    filesToUpload.forEach((file) => promises.push(this._stream(file, folder)));
    return Promise.all(promises);
  }

  private async _stream($file, folder): Promise<any> {
    const socket = io(apiUrl.apiUrl, { transports: ['websocket'] });
    const stream = ss.createStream();
    socket.emit(`app:upload`);
    ss(socket).emit(`app:upload`, stream, { folder, filename: $file.name });

    const blobStream = ss.createBlobReadStream($file, { highWaterMark: 1024 * 200 });
    let size = 0;
    let percent = 0;
    blobStream.on('data', (chunk) => {
      size += chunk.length;
      percent = Math.floor(size / $file.size * 100);
      console.info('### Progress', percent, '%');
    });
    blobStream.pipe(stream);
    return new Promise((resolve, reject) => {
      socket.on(`app:uploaded`, resolve);
      socket.on('app:upload:error', reject);
    });
  }
}
