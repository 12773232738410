<ul *ngFor="let product of selectedProducts">
  <li>
    <h6>{{ product.name }}</h6>
    <!--   BT  -->
    <p *ngIf="product.name === 'Ballon thermodynamique'">{{ product.pose }} {{ product.capacite }} {{ product.unite
      }}<span *ngFor="let option of product.options_bt"> {{ option }}</span></p>

    <!-- Mise aux normes -->
    <p *ngIf="product.name === 'Mise aux normes du tableau électrique'">{{ product.monotri }}</p>
    <!-- PAC Air/Air -->
    <p *ngIf="product.name === 'PAC Air/Air'">{{ product.puissance }} {{ product.type_pac }} {{ product.nombre }} <span
        *ngIf="product.type_pac === 'Split'">zones</span> <span *ngIf="product.type_pac === 'Gainable'">bouches</span>
      <span *ngFor="let option of product.options_pac"> {{ option }}</span></p>
    <!-- PAC Air/Eau -->
    <p *ngIf="product.name === 'PAC Air/Eau'">{{ product.puissance }} {{ product.type_pac }} {{ product.monotri }} <span
        *ngFor="let option of product.options_pac"> {{ option }}</span></p>
    <!-- PV -->
    <p *ngIf="product.name === 'Panneaux solaires'">
      <span *ngIf="product.nombre">{{ product.nombre }} PV</span> {{ product.puissance }} {{ product.pose }} {{
      product.orientation }}
    </p>
    <!-- BATTERIE -->
    <p *ngIf="product.name === 'Batterie'">
      <span *ngIf="product.nombre">{{ product.nombre }} batteries</span> {{ product.marque }} <span
        *ngIf='product.puissance'>{{ product.puissance }} kWc</span> <span>{{ product.backup }}</span>
    </p>
    <p *ngIf="product.name === 'Isolation'">
      <span *ngIf="product.surface">{{ product.surface }} m²</span> {{ product.type_iso }}
    </p>
    <!-- Autres-->
    <p *ngIf="product.state && product.state === 'autre'">
      <span *ngFor="let option of product.options">
        <span class="subtitle">{{ option.option }} {{ option.value }} &#160;</span>
      </span>

    </p>
  </li>
</ul>