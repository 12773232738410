import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-part15',
  templateUrl: './part15.component.html',
  styleUrls: ['./part15.component.scss']
})
export class Part15Component implements OnInit {

  caseId

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.caseId = this.route.snapshot.params['id'];

  }
}
