<button  mat-raised-button color="primary" [routerLink]="['/admin-dashboard']">Retour</button>
<div *ngIf="cases">
<h2>Affecter un rendez-vous</h2>
<section class="wrapper-fostrap">
    <div class="container-fostrap">
      <div class="content">
        <div class="row">
          <div class="col-xs-12 col-lg-4 col-sm-4" *ngFor="let case of cases">
            <div class="card">
              <div class="card-content">
                <p class="little infos" *ngIf="case.customer" data-toggle="tooltip" data-placement="top">
                {{ case.customer.name }}
                </p>
                <p> RDV pris le : {{ case.datecase | date:'dd/MM/yy à hh:mm' }}</p>
                <p> RDV pour le : {{ case.r1[0].date| date:'dd/MM/yy à hh:mm' }}</p>

                <p> Qualifié </p>

               <select [(ngModel)]="case.commercial" (change)="saveChange(case, case._id)" >
                    <option *ngFor="let commercial of this.sUsers.commerciaux" [value]="commercial._id">
                      {{ commercial.username }}
                    </option>
                  </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
