import { Component, Input, OnChanges } from '@angular/core';
import { CasesService } from '@app/services/cases.service';
import { RolesService } from '@app/services/roles.service';
import * as moment from 'moment';


@Component({
  selector: 'app-suivi-affaires',
  templateUrl: './suivi-affaires.component.html',
  styleUrls: ['./suivi-affaires.component.scss']
})
export class SuiviAffairesComponent implements OnChanges {

  @Input() userId;
  prospection
  negociation

  sumSignee = 0
  sumAnomalie = 0
  sumTravaux = 0
  sumPose = 0

  sumCompletude = 0
  sumTravauxEc = 0
  loading = true;
  trackCases;
  travauxEncours
  element: HTMLElement;

  attente
  completude
  travaux_ec
  complete_demande_fi

  prospection_active = false
  negociation_active = false
  signee_active = false
  travaux_active = false
  pose_active = false
  paye_active = false

  constructor(
    public sRoles: RolesService,
    private sCases: CasesService) {
    }
   ngOnChanges() {
    document.getElementById('travaux').style.margin = '0px 0 0 0'
    this.attente = []
    this.completude = []
    this.travaux_ec = []
    this.complete_demande_fi = []
    this.loading = true;
    this.sumCompletude = 0
    this.sumTravauxEc = 0

    if(this.userId != undefined) {


    this.sCases.getCasesForCommercialTrack(this.userId).subscribe(res => {
      this.trackCases = res;
      this.prospection = this.trackCases.filter(c => c.state === 'Prospection')
      this.negociation = this.trackCases.filter(c => c.state === 'Négociation')

      if(this.prospection.length * 14 > 50) {
        document.getElementById('prospection').style.height = this.prospection.length * 14 + 'px'
        let diffProspection =  parseInt(document.getElementById('prospection').style.height) - 50
        if(this.prospection.length * 14 > 50) {
          document.getElementById('prospection').style.margin = (-diffProspection /2) + 'px 0 0 0'
        }
      }
      if( this.prospection.length * 14 > 500) {
        document.getElementById('prospection').style.height = '330px'
        document.getElementById('prospection').style.margin = '-140px 0 0 0'
      }
      if(this.negociation.length * 14 > 50) {
        document.getElementById('negociation').style.height = this.negociation.length * 14 + 'px'
        let diffNegociation =  parseInt(document.getElementById('negociation').style.height) - 50
        if(this.negociation.length * 14 > 50) {
          document.getElementById('negociation').style.margin = (-diffNegociation /2) + 'px 0 0 0'
        }
      }
    });

    this.sCases.getCasesForCommercialTrackSignee(this.userId).subscribe(res => {
      this.attente = res['attente']
      this.completude = res['completude']
      this.travaux_ec = res['travaux_ec'].filter(c => new Date(c.chantier.date) > new Date())
      this.complete_demande_fi = res['complete_demande_fi']

      this.attente = this.attente.filter((page1) => !this.complete_demande_fi.find(page2 => page1._id === page2._id ))

      const dateFrom = moment(new Date()).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');

      const ed = new Date().getTime()
      const sd = new Date(dateFrom).getTime()

      this.attente.sort((a, b) => {
        return <any>new Date(a.datesign) - <any>new Date(b.datesign);
      });
      this.completude.sort((a, b) => {
        return <any>new Date(a.datesign) - <any>new Date(b.datesign);
      });
      this.travaux_ec.sort((a, b) => {
        return <any>new Date(a.chantier.date) - <any>new Date(b.chantier.date);
      });
      this.complete_demande_fi.sort((a, b) => {
        return <any>new Date(a.datesign) - <any>new Date(b.datesign);
      });

      this.completude.map(c => this.sumCompletude += c.commission)
      this.travaux_ec.map(c => this.sumTravauxEc += c.commission)

      if(this.attente.length * 14 > 50) {
        document.getElementById('signee').style.height = this.attente.length * 15 + 'px'
        let diffCompletude =  parseInt(document.getElementById('signee').style.height) - 50
        if(this.attente.length * 14 > 50) {
          document.getElementById('signee').style.margin = (-diffCompletude /2) + 'px 0 0 0'
        }
      }
      else if(this.attente.length * 14 === 18) {
        document.getElementById('signee').style.height = ((this.attente.length * 15) * 5) + 'px'
        let diffCompletude =  parseInt(document.getElementById('signee').style.height) - 50
          document.getElementById('signee').style.margin = (-diffCompletude /2) + 'px 0 0 0'
      }

      if((this.completude.length  + 4) * 14 > 50) {
        document.getElementById('travaux').style.height = this.completude.length * 40 + 'px'
        let diffTravaux =  parseInt(document.getElementById('travaux').style.height) - 50
        if(this.completude.length * 20 > 50) {
          document.getElementById('travaux').style.margin = (-diffTravaux /2) + 'px 0 0 0'
        }
      }

      if(this.complete_demande_fi.length * 14 > 50) {
        document.getElementById('pose').style.height = this.complete_demande_fi.length * 14 + 'px'
        let diffPose =  parseInt(document.getElementById('pose').style.height) - 50
        if(this.complete_demande_fi.length * 14 > 50) {
          document.getElementById('pose').style.margin = (-diffPose /2) + 'px 0 0 0'
        }
      }
      if(this.travaux_ec.length * 14 > 50) {
        document.getElementById('paye').style.height = this.travaux_ec.length * 14 + 'px'
        let diffPaye =  parseInt(document.getElementById('paye').style.height) - 50
        if(this.travaux_ec.length * 14 > 50) {
          document.getElementById('paye').style.margin = (-diffPaye /2) + 'px 0 0 0'
        }
      }

      // si prospection < negociation, negociation gauche active
      // else prospection droite active

      if(this.prospection.length < this.negociation.length) {
        // negoc gauche
        document.getElementById('negociation_em1').style.height = (parseInt(document.getElementById('negociation').style.height) / 2) + 'px'
        document.getElementById('negociation_em2').style.height = (parseInt(document.getElementById('negociation').style.height) / 2) + 'px'
        // prospec right false
        document.getElementById("prospection_em3").style.display = "none"
        document.getElementById("prospection_em4").style.display = "none"
      }
      else {
        //prospe droite
        document.getElementById('prospection_em3').style.height = (parseInt(document.getElementById('prospection').style.height) / 2) + 'px'
        document.getElementById('prospection_em4').style.height = (parseInt(document.getElementById('prospection').style.height) / 2) + 'px'
        //negoc gauche false
        document.getElementById("negociation_em1").style.display = "none"
        document.getElementById("negociation_em2").style.display = "none"
      }
      if(this.negociation.length > (this.attente.length) + 4) {
        // negociation droite
        document.getElementById('negociation_em3').style.height = (parseInt(document.getElementById('negociation').style.height) / 2) + 'px'
        document.getElementById('negociation_em4').style.height = (parseInt(document.getElementById('negociation').style.height) / 2) + 'px'
        // completude left false
        document.getElementById("completude_em1").style.display = "none"
        document.getElementById("completude_em2").style.display = "none"
      }
      else {
        // completude gauche
        document.getElementById('completude_em1').style.height = (parseInt(document.getElementById('signee').style.height) / 2) + 'px'
        document.getElementById('completude_em2').style.height = (parseInt(document.getElementById('signee').style.height) / 2) + 'px'
        //negociation droite false
        document.getElementById('negociation_em3').style.display = "none"
        document.getElementById('negociation_em4').style.display = "none"
      }
      if(this.attente.length >= this.completude.length) {
        //completude right

        document.getElementById('completude_em3').style.height = (parseInt(document.getElementById('signee').style.height) / 2) + 'px'
        document.getElementById('completude_em4').style.height = (parseInt(document.getElementById('signee').style.height) / 2) + 'px'
        //travaux left false
        document.getElementById("travaux_em1").style.display = "none"
        document.getElementById("travaux_em2").style.display = "none"
      }
      else {
        //travaux gauche
        document.getElementById('travaux_em1').style.height = (parseInt(document.getElementById('travaux').style.height) / 2) + 'px'
        document.getElementById('travaux_em2').style.height = (parseInt(document.getElementById('travaux').style.height) / 2) + 'px'
        //completude droite false
        document.getElementById("completude_em3").style.display = "none"
        document.getElementById("completude_em4").style.display = "none"
      }
      if(this.completude.length > this.complete_demande_fi.length) {
        //travaux droite
        document.getElementById('travaux_em3').style.height = (parseInt(document.getElementById('travaux').style.height) / 2) + 'px'
        document.getElementById('travaux_em4').style.height = (parseInt(document.getElementById('travaux').style.height) / 2) + 'px'
        //pose left false
        document.getElementById("pose_em1").style.display = "none"
        document.getElementById("pose_em2").style.display = "none"
      }
      else {
        //travaux droite false
        document.getElementById("travaux_em3").style.display = "none"
        document.getElementById("travaux_em4").style.display = "none"
        //pose gauche
        document.getElementById('pose_em1').style.height = (parseInt(document.getElementById('pose').style.height) / 2) + 'px'
        document.getElementById('pose_em2').style.height = (parseInt(document.getElementById('pose').style.height) / 2) + 'px'
      }
      if(this.complete_demande_fi.length > this.travaux_ec.length) {
        //pose droite
        document.getElementById('pose_em3').style.height = (parseInt(document.getElementById('pose').style.height) / 2) + 'px'
        document.getElementById('pose_em4').style.height = (parseInt(document.getElementById('pose').style.height) / 2) + 'px'
        //paye gauche false
        document.getElementById("paye_em1").style.display = "none"
        document.getElementById("paye_em2").style.display = "none"
      }
      else {
        //paye gauche
        document.getElementById('paye_em1').style.height = (parseInt(document.getElementById('paye').style.height) / 2) + 'px'
        document.getElementById('paye_em2').style.height = (parseInt(document.getElementById('paye').style.height) / 2) + 'px'
      }
      this.loading = false
    });
  }
   }

  getLengths(caseId,piece,array) {
    let count = 0
    array.filter(c => c._id === caseId).forEach(e => {
      e.pieces.forEach(p => {
        if(p.qualification === piece) {
          count++
        }
      });
    });
    return count
  }

  changeActive(valueActive) {
    switch (valueActive) {
      case 'prospection_active':
        this.prospection_active = !this.prospection_active
        this.negociation_active = false
        this.signee_active = false
        this.travaux_active = false
        this.pose_active = false
        this.paye_active = false

      break;

      case 'negociation_active':
        this.negociation_active = !this.negociation_active
        this.prospection_active = false
        this.signee_active = false
        this.travaux_active = false
        this.pose_active = false
        this.paye_active = false

      break;

      case 'signee_active':
        this.signee_active = !this.signee_active
        this.negociation_active = false
        this.prospection_active = false
        this.travaux_active = false
        this.pose_active = false
        this.paye_active = false

      break;

      case 'travaux_active':
        this.travaux_active = !this.travaux_active
        this.signee_active = false
        this.negociation_active = false
        this.prospection_active = false
        this.pose_active = false
        this.paye_active = false

      break;

      case 'pose_active':
        this.pose_active = !this.pose_active
        this.travaux_active = false
        this.signee_active = false
        this.negociation_active = false
        this.prospection_active = false
        this.paye_active = false

      break;

      case 'paye_active':
        this.paye_active = !this.paye_active
        this.travaux_active = false
        this.signee_active = false
        this.negociation_active = false
        this.prospection_active = false
        this.pose_active = false

      break;
    }
  }
}
