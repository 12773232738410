<button (click)="toggleShowEncours()" style="opacity: 0; position: absolute; top: 0; left: 0; width:5em; height: 5em; "></button>

<button class="btn-secondarycrm" style="position: absolute;right: 49px;top: 25px;
" (click)="toggleGdd()">Suivi GDD
<mat-icon *ngIf="showGdd" style="position: absolute;
right: 11px;
top: 14px;color:#f45b69;">close</mat-icon>

</button>


<div class="icons-template">
  <a class="nav-item nav-link " style="font-size:0.8rem;">
    <img src="../../../assets/bar-icons/compta-encours.svg" *ngIf="showEncours || showCm" (click)="toggleShowEncours()">
  </a>
  <a class="nav-item nav-link " style="font-size:0.8rem;">
    <img src="../../../assets/bar-icons/compta-encours-active.svg" *ngIf="!showEncours && !showCm" (click)="toggleShowEncours()">
  </a>
  <a class="nav-item nav-link " style="font-size:0.8rem;" routerLink="/compta-cm-table">
    <img src="../../../assets/bar-icons/cm.svg">
  </a>

  <a class="nav-item nav-link " style="font-size:0.8rem;" routerLink="/compta-primes-table">
    <img src="../../../assets/bar-icons/primes.svg">
  </a>

  <a class="nav-item nav-link " style="font-size:0.8rem;" routerLink="/compta-leads">
    <img src="../../../assets/bar-icons/audit.svg">
  </a>
</div>
<h5 *ngIf="this.sRoles.userRole != 'Admin'" class="mt-5"><strong>Pégase</strong> vous souhaite la bienvenue, <i style="color: var(--tertiarycrm);" >{{this.sRoles.user.username}}</i></h5>

<div class="mt-4">
  <app-table-gdd *ngIf="showGdd"></app-table-gdd>
</div>

<div *ngIf="!showCm && !showGdd && rowData != []">

<div class="d-flex justify-content-between mt-4" *ngIf='rowData && !showFilteredResult'>
  <div>
    <h1>{{ totalCA | currency:'EUR':"symbol" }}</h1>
    <!-- <h1>{{ totalCA + cmByNumberMonth | currency:'EUR':"symbol" }}</h1>
    <p>{{cmByNumberMonth | currency:'EUR':"symbol" }} + {{totalCA | currency:'EUR':"symbol" }} </p> -->
    <p>Total Chiffre d'affaires</p>
  </div>
  <div>
    <h1>{{ billTotal  | currency:'EUR':"symbol" }}</h1>
    <p>Total des factures</p>
  </div>
  <div>
<!--     <p>totalCA -  {{totalCA}}</p>
    <p>billTotal -  {{billTotal}}</p> -->

<!--     <h1>{{ totalCA - billTotal  | currency:'EUR':"symbol" }}</h1>
 -->    <h1>{{ billTotal - totalCA  | currency:'EUR':"symbol" }}</h1>

    <p>CA restant à facturer</p>
  </div>
  <div>
    <h1>{{  totalAvoir | currency:'EUR':"symbol" }}</h1>
    <p>Avoir</p>
  </div>
  <div>
    <h1>{{ caMissing | currency:'EUR':"symbol" }}</h1>
    <p>CA Restant à encaisser </p>
  </div>
</div>

<div *ngIf="showFilteredResult" class="row filteredFi mt-4">
  <div>
    <h1>{{ filteredResult.count }}</h1>
    <p>Nombre de dossiers</p>
  </div>
  <div>
    <h1>{{filteredResult.price_ht | currency:'EUR':"symbol" }}</h1>
    <p>Chiffre d'affaire HT</p>
  </div>
  <div>
    <h1>{{filteredResult.price_ttc | currency:'EUR':"symbol" }}</h1>
    <p>Chiffre d'affaire TTC</p>
  </div>
</div>
<mat-form-field *ngIf="!showEncours">
  <mat-select multiple (selectionChange)="filterDataArray($event.value,'month')">
    <div *ngFor="let month of months" >
    <mat-option style="color: #3a3b5a;" [(value)]="month.name">
     <strong>{{month.name}}</strong>
    </mat-option>
    </div>
  </mat-select>
</mat-form-field>

<mat-form-field *ngIf="!showEncours && selectedMonth.length > 0" style="margin-left:3%;">
  <mat-select [(ngModel)]="selectedYear" (selectionChange)="filterDataArray($event.value,'year')">
    <div *ngFor="let year of years" >
    <mat-option style="color: #3a3b5a;" [(value)]="year.name">
     <strong>{{year.name}}</strong>
    </mat-option>
    </div>
  </mat-select>
</mat-form-field>

<h2 *ngIf="showEncours" style="color:var(--tertiarycrm);" >{{actualNameMonth}} <span>{{selectedYear}}</span></h2>

<mat-progress-bar *ngIf="!rowData" mode="indeterminate"></mat-progress-bar>



  <ag-grid-angular *ngIf="rowData != []"
    #agGrid
    style="width: 90vw; height: 70vh; margin-bottom: 5%;"
    id="myGrid"
    class="ag-theme-alpine"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="rowData"
    [rowStyle]="rowStyle"
    (gridReady)="onGridReady($event)"
    rowSelection="single"
    [animateRows]="true"
    (selectionChanged)="onSelectionChanged($event)"
    [rowHeight]="rowHeight"
    (filterChanged)="onFilterChanged($event)">
  </ag-grid-angular>

</div>

  <app-compta-cm-table *ngIf="showCm"></app-compta-cm-table>

