<div class="d-flex mt-4" *ngIf="case">
  <div class="choices mr-5">
    <p class="m-0">Demande en mairie nécessaire ?</p>
    <input type="radio" id="mairie1" name="mairie" [(ngModel)]="case.mairie" value=true (change)="saveChange('edité','demande en mairie',case.mairie)">
    <label for="mairie1" [ngClass]="case.mairie === 'true' ? 'oui' : 'oui-empty'">
      <span class="material-icons">
      done
      </span>OUI</label>

    <input type="radio" id="mairie2" name="mairie" [(ngModel)]="case.mairie" value=false (change)="saveChange('edité','demande en mairie',case.mairie)">
    <label for="mairie2" [ngClass]="case.mairie === 'true' ? 'non-empty' : 'non'">
      <span class="material-icons">
        clear
        </span>NON</label>
  </div>


  <div class="choices">
    <p class="m-0">Zone Architecte Bâtiment de France</p>
    <input type="radio" id="abf1" name="abf" [(ngModel)]="case.abf" value=true (change)="saveChange('edité','zone ABF',case.mairie)">
    <label for="abf1" [ngClass]=" case.abf === 'true' ? 'oui' : 'oui-empty'">
      <span class="material-icons">
      done
      </span>OUI</label>

    <input type="radio" id="abf2" name="abf" [(ngModel)]="case.abf" value=false (change)="saveChange('edité','zone ABF',case.abf)">
    <label for="abf2" [ngClass]=" case.abf === 'true' ? 'non-empty' : 'non'">
      <span class="material-icons">
        clear
        </span>NON</label>
  </div>
</div>

<a class="p-3" (click)="showEdit()" *ngIf="mairies && mairies.length != 0">Supprimer une demande de mairie</a>


  <div class="row" *ngIf="case">
    <mat-card class="col-xs-12 col-lg-3 col-sm-5" *ngFor="let mairie of mairies">
      <img class="delete" src="../../../assets/icons/delete.svg" *ngIf="showCross" (click)="deleteMairie(mairie._id,'supprimé','demande en mairie',mairie.date_depot)">

        <mat-card-content  [ngClass]="showCross ? 'disabled' : ''" (click)="showCross || goToEditFinancement(mairie._id)">
          <h4 class="demande">Demande en mairie</h4>
          <p>{{ mairie.created_at | date:'dd/MM/yy' }}</p>
          <p class="qualif-mairie"
          [ngClass]="{'attente':mairie.state === 'En cours' || 'Complément',
                      'conforme':mairie.state === 'Acceptation',
                      'nonconforme':mairie.state === 'Refus'
                    }">
            {{ mairie.state }}
           </p>
        </mat-card-content>
    </mat-card>
  </div>

