<button mat-button (click)="retour()"><img src="../../../assets/icons/retour.svg">Retour</button>
<h3 class="ml-3">{{ piece.title }}</h3>

<form [formGroup]="pieceForm" class="d-flex" style="width:20%;">
  <mat-select *ngIf="userRole === 'Admin' || userRole === 'Adv' || userRole === 'Responsable ADV' && piece.qualification != 'Manquante'"
  [ngClass]="{
    'attente':piece.qualification === 'En attente de qualification',
    'conforme':piece.qualification === 'Conforme',
    'nonconforme':piece.qualification === 'Non conforme' || piece.qualification === 'Manquante'}"
  class="custom-select-state rdv ml-3 mb-4" formControlName="qualification" [(ngModel)]="piece.qualification" value="piece.qualification" (selectionChange)="saveChangeAndNotif('edité',piece.title,piece.qualification)">
    <mat-option [ngClass]="qualification" *ngFor="let qualification of qualifications" [value]="qualification">
      {{qualification}}
    </mat-option>
  </mat-select>
  <div class="manquante ml-3" *ngIf="userRole != 'Admin' && userRole != 'Adv' && piece.qualification === 'Manquante'">Manquante</div>
</form>

<a *ngIf="userRole === 'Admin' || userRole === 'Adv' || userRole === 'Responsable ADV'" (click)="open()" >
  Ouvrir toutes les photos
</a>

<div *ngIf="userRole === 'Commercial' || userRole === 'Responsable d\'agence'" class="qualif-mairie ml-3 mt-4 mb-3"
  [ngClass]="{
  'attente':piece.qualification === 'En attente de qualification',
  'conforme':piece.qualification === 'Conforme',
  'nonconforme':piece.qualification === 'Non conforme'}">
  {{piece.qualification}}
</div>

<div *ngIf="!mobile">
  <div class="row" *ngIf="piece.qualification != 'Manquante'">
    <mat-card class="col-xs-12 col-lg-3 col-sm-2 mt-4" *ngFor="let img of arrayImg">

        <a href="https://api-crm.s3.eu-west-3.amazonaws.com/{{piece.caseId}}/pieces/{{img}}" target="_blank" rel="noopener noreferrer">
          <img *ngIf="!img.endsWith('pdf')" src="https://api-crm.s3.eu-west-3.amazonaws.com/{{piece.caseId}}/pieces/{{img}}" target="_blank" download>

          <img *ngIf="img.endsWith('pdf')" [src]="pdfImg">
          </a>
      <mat-card-content></mat-card-content>
    </mat-card>

  </div>
  <div class="row" *ngIf="piece.qualification === 'Manquante'">
    <span class="btn-secondarycrm ml-4">+ Ajouter la pièce</span>
    <input accept=".jpg,.jpeg,.png,.pdf" multiple type="file" name="upload" id="upload" class="upload-box" (change)="selectMultiple($event,'ajouté','pièce',pieceForm.value.title)">
  </div>

  <div class="row input-bottom">
    <div class="col-6">
      <h3>Commentaire:</h3>
      <textarea [(ngModel)]="piece.commentary" placeholder="Commentaire" (change)="saveChange('edité',piece.title,piece.commentary)"></textarea>
    </div>
    <div class="col-6">
      <h3>Description:</h3>
      <textarea  [(ngModel)]="piece.description" placeholder="Description"  (change)="saveChange('edité',piece.title,piece.description)"></textarea>
    </div>
  </div>
</div>

<div *ngIf="mobile">
  <div class="row" *ngIf="piece.qualification != 'Manquante'">
    <mat-card class="col-xs-12 col-lg-3 col-sm-2" *ngFor="let img of arrayImg">
      <div *ngIf="img.state != 'Manquante'">
        <a href="https://api-crm.s3.eu-west-3.amazonaws.com/{{piece.caseId}}/pieces/{{img}}" target="_blank">
        <img *ngIf="!img.endsWith('pdf')" src="https://api-crm.s3.eu-west-3.amazonaws.com/{{piece.caseId}}/pieces/{{img}}" target="_blank">
        <img *ngIf="img.endsWith('pdf')" [src]="pdfImg">
        </a>
      </div>
      <mat-card-content></mat-card-content>
    </mat-card>

    </div>

    <div class="row" *ngIf="piece.qualification === 'Manquante'">
      <span class="btn-secondarycrm ml-4">+ Ajouter une photo</span>
      <input accept=".jpg,.jpeg,.png,.pdf" multiple type="file" name="upload" id="upload" class="upload-box" (change)="selectMultiple($event)">
    </div>

    <div class="row input-bottom">
      <div class="col-12">
        <h3>Commentaire:</h3>
        <textarea [(ngModel)]="piece.commentary" placeholder="Commentaire" (change)="saveChange('edité',piece.title,piece.commentary)"></textarea>
      </div>
    </div>

    <div class="row input-bottom">
      <div class="col-12">
        <h3>Description:</h3>
        <textarea  [(ngModel)]="piece.description" placeholder="Description"  (change)="saveChange('edité',piece.title,piece.description)"></textarea>
      </div>
    </div>

  </div>
