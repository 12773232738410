import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-reponse',
  templateUrl: './card-reponse.component.html',
  styleUrls: ['./card-reponse.component.scss']
})
export class CardReponseComponent implements OnInit {
  @Input() reponse: string;
  @Input() selectedCol: string;
  @Input() index;
  constructor() { }

  ngOnInit(): void {
  }

}
