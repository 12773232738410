<div>
  <h6 class="my-5">Bon d'intervention</h6>
  
  <app-header-info [caseId]='caseId'></app-header-info>
  
  <h4>Équipements installés</h4>

  <mat-form-field>
    <mat-label>Produits</mat-label>
    <mat-select multiple (selectionChange)="onSelectionChange($event)">
      <mat-option *ngFor="let product of kit" [value]="product">{{ product.name }}</mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="selectedProducts.length > 0">
    <p>Produits sélectionnés :</p>
    <ul>
      <li *ngFor="let product of selectedProducts">{{ product.name }}</li>
    </ul>
  </div>

  <div *ngIf="kit.length === 0">
    <p>Si vous ne voyez aucun produits, le produit que vous attendiez ne respecte pas la conformité, il faut d'abord renseigner les informations
      nécessaires sur la page précédente
    </p>
  </div>
  
  <div *ngFor="let product of selectedProducts">

  <div class="pv" *ngIf="product.name === 'Panneaux solaires'">
    <h6>Panneaux photovoltaïque micro-onduleur</h6>
    <div class="row">
      <div class="col-lg-3 col-s-6 col-xs-6">Marque et type : <u> {{ product.marque_type }}</u></div>
      <div class="col-lg-3 col-s-6 col-xs-6">Nombre: <span>{{ product.nombre }}</span></div>
      <br>
    </div>
    <div class="row">
      <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
        <label>Référence PV</label>
        <input type="text" class="custom-input" [(ngModel)]="product.reference" (change)="getCurrentKitAndSaveChange(product.reference,'reference', product)">
      </div>
    </div>
  
    <div class="row">
      <div class="col-lg-3 col-s-6 col-xs-6">Marque : <u>{{ product.marque_type_onduleur }}</u></div>
      <div class="col-lg-3 col-s-6 col-xs-6">Nombre: <span>{{ product.nbr_onduleur }} micros-onduleur</span></div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
        <label>Référence Onduleur</label>
        <input type="text" class="custom-input" [(ngModel)]="product.reference_onduleur" (change)="getCurrentKitAndSaveChange(product.reference_onduleur,'reference_onduleur', product)">
      </div>
    </div>
  
  <br>
  
    <div class="row">
      <div class="col-lg-5 col-s-6 col-xs-6 custom-col radio-choice">
        <mat-label>Test de fonctionnement:</mat-label>
        <mat-radio-group aria-label="Select an option" [(ngModel)]="product.test" (change)="getCurrentKitAndSaveChange($event.value,'test', product)">
          <mat-radio-button value="oui">Oui</mat-radio-button>
          <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  
  </div>
  
  <br>

  <div class="bt" *ngIf="product.name === 'VMC Double Flux'">
  
    <h6>VMC Double Flux</h6>
    <div class="row">
      <div class="col-lg-3 col-s-6 col-xs-6">Marque et type : <u> {{ product.marque_type }}</u></div>
      <div class="col-lg-3 col-s-6 col-xs-6">Nombre:<span>1</span></div>
    </div>
  
  <br>

    <div class="row">
      <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
        <label>Référence</label>
        <input type="text" class="custom-input" [(ngModel)]="product.reference" (change)="getCurrentKitAndSaveChange(product.reference,'reference', product)">
      </div>

      <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
        <mat-label>Test de fonctionnement:</mat-label>
        <mat-radio-group aria-label="Select an option" [(ngModel)]="product.test" (change)="getCurrentKitAndSaveChange($event.value,'test', product)">
          <mat-radio-button value="oui">Oui</mat-radio-button>
          <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>

  <br>
  
  <div class="bt" *ngIf="product.name === 'Ballon thermodynamique'">
  
    <h6>Ballon thermodynamique</h6>
    <div class="row">
      <div class="col-lg-3 col-s-6 col-xs-6">Marque et type : <u> {{ product.marque_type }}</u></div>
      <div class="col-lg-3 col-s-6 col-xs-6">Capacité: <span>{{ product.capacite }}</span></div>
      <div class="col-lg-3 col-s-6 col-xs-6">Nombre:<span>1</span></div>
    </div>
  
  <br>
  
    <div class="row">
      <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
        <label>Référence BT</label>
        <input type="text" class="custom-input" [(ngModel)]="product.reference" (change)="getCurrentKitAndSaveChange(product.reference,'reference', product)">
      </div>

      <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
        <label>Détail de l'intervention</label>
        <textarea type="text" class="custom-input" [(ngModel)]="product.bt_detail" (change)="getCurrentKitAndSaveChange(product.bt_detail,'bt_detail', product)"></textarea>
      </div>
    </div>
  </div>
  
  
  <div class="pacaa" *ngIf="product.name === 'PAC Air/Air'">
    <h6>Pompe à chaleur</h6>
    <div class="row">
      <div class="col-lg-3 col-s-6 col-xs-6">Marque référence modules UE: <u> {{ product.marque_type_ue }}</u></div>
      <div class="col-lg-3 col-s-6 col-xs-6">Nombre UE:<span>1</span></div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
        <label>Référence UE</label>
        <input type="text" class="custom-input" [(ngModel)]="product.reference_ue" (change)="getCurrentKitAndSaveChange(product.reference_ue,'reference_ue', product)">
      </div>
    </div>
  
    <div class="row">
      <div class="col-lg-3 col-s-6 col-xs-6">Marque et type UI : <u> {{ product.marque_type_ui }}</u></div>
      <div class="col-lg-3 col-s-6 col-xs-6">Nombre UI:<span>{{ product.nbr_split }}</span></div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
        <label>Référence UI</label>
        <input type="text" class="custom-input" [(ngModel)]="product.reference_ui" (change)="getCurrentKitAndSaveChange(product.reference_ui,'reference_ui', product)">
      </div>
    </div>
  
  <br>
  
  </div>
  
  <div class="pacae" *ngIf="product.name === 'PAC Air/Eau'">
    <h6>Pompe à chaleur Air Eau</h6>
    <div class="row">
      <div class="col-lg-3 col-s-6 col-xs-6">Marque référence modules UE: <u> {{ product.marque_type_ue }}</u></div>
      <div class="col-lg-3 col-s-6 col-xs-6">Nombre UE:<span> 1 </span></div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
        <label>Référence UE</label>
        <input type="text" class="custom-input" [(ngModel)]="product.reference_ue" (change)="getCurrentKitAndSaveChange(product.reference_ue,'reference_ue', product)">
      </div>
    </div>
  
    <div class="row">
      <div class="col-lg-3 col-s-6 col-xs-6">Marque et type UI : <u> {{ product.marque_type_ui }}</u></div>
      <div class="col-lg-3 col-s-6 col-xs-6">Nombre UI:<span> 1 </span></div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
        <label>Référence UI</label>
        <input type="text" class="custom-input" [(ngModel)]="product.reference_ui" (change)="getCurrentKitAndSaveChange(product.reference_ui,'reference_ui', product)">
      </div>
    </div>
  <br>

  </div>
  </div>
  
  </div>
  <button class="btn-secondarycrm" *ngIf="selectedProducts.length >0" (click)="valid()">Confirmer</button>
  
  <app-loading *ngIf="loader"></app-loading>
  