import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-part3',
  templateUrl: './part3.component.html',
  styleUrls: ['./part3.component.scss'],
})

export class Part3Component implements OnInit {

  caseId
  RTSelected: string = null;

  comble;
  amelio_menuiserie;
  iso_interieure;
  renfo_aeration;
  double_vitrage;
  etancheite;
  renfo_iso;
  vmc;
  pv;
  systeme_enr;
  bt;
  pac;
  double_pv;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.caseId = this.route.snapshot.params['id'];


  }

  selectRT(selectedRT: string) {
    this.RTSelected = selectedRT;
  }

  toggleComble(comble: string) {
    this.comble = !this.comble;
  }
}


