import { Component } from '@angular/core';
import { User } from '@app/_models';
import { AuthenticationService } from '@app/_services';
import { Router } from '@angular/router';

@Component({ templateUrl: 'home.component.html' })
export class HomeComponent {
  loading = false;
  user: User;
  userFromApi: User;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    if (this.authenticationService.userSubject.value.role === 'Admin') {
      this.router.navigateByUrl('/admin-dashboard').then(() => {});
    } else if (
      this.authenticationService.userSubject.value.role === 'Commercial'
    ) {
      this.router.navigateByUrl('/commercial-dashboard').then(() => {});
    } else if (
      this.authenticationService.userSubject.value.role ===
      "Responsable d'agence"
    ) {
      this.router.navigateByUrl('/commercial-dashboard').then(() => {});
    } else if (
      this.authenticationService.userSubject.value.role === 'Tmk' ||
      this.authenticationService.userSubject.value.role === 'Beenergie'
    ) {
      this.router.navigateByUrl('/tmk-dashboard').then(() => {});
    } else if (
      this.authenticationService.userSubject.value.role === 'Superviseur TMK'
    ) {
      this.router.navigateByUrl('/super-tmk-dashboard').then(() => {});
    } else if (this.authenticationService.userSubject.value.role === 'Adv') {
      this.router.navigateByUrl('/adv-dashboard').then(() => {});
    } else if (this.authenticationService.userSubject.value.role === 'Compta') {
      this.router.navigateByUrl('/compta-dashboard').then(() => {});
    } else if (
      this.authenticationService.userSubject.value.role === 'Spectateur'
    ) {
      this.router.navigateByUrl('/spectateur-dashboard').then(() => {});
    } else if (
      this.authenticationService.userSubject.value.role === 'Technicien' ||
      this.authenticationService.userSubject.value.role ===
        'Responsable Technicien'
    ) {
      this.router.navigateByUrl('/technicien-dashboard').then(() => {});
    } else if (
      this.authenticationService.userSubject.value.role === 'Logistique'
    ) {
      this.router.navigateByUrl('/logistique-dashboard').then(() => {});
    }
  }

  ngOnInit() {}
}
