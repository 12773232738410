<div *ngIf="mobile; then thenBlockMobile else elseBlockDesktop"></div>
<p *ngIf="mobile" class="compteur">
  <span matBadge="{{ infosUnread.length }}" matBadgeSize="small"></span>
</p>
<img *ngIf="mobile" class="messagerie-icon" [routerLink]="['/messagerie']" src="../../../assets/bar-icons/messagerie.svg">
<p *ngIf="mobile" class="compteur-messagerie">
  <span *ngIf="countUnread" matBadge="{{ countUnread.count }}" matBadgeSize="small"></span>
</p>
<ng-template #thenBlockMobile>

<div class="marge-bottom" >
<img class="notification" [routerLink]="['/infos-advs']" src="../../../assets/icons/notification.svg">

<div class="d-flex justify-content-center mt-4">
  <img src="../../assets/logo.png" class="logo">
</div>


<div class="profile-card row ml-2 mr-2">
  <div class="profile-picture col-3" *ngIf="currentPicture">
    <input accept=".jpg,.jpeg,.png" type="file" name="upload" id="upload" class="upload-box" (change)="selectPicture($event)">
    <mat-spinner diameter="40" *ngIf="loading"></mat-spinner>

      <img *ngIf='!displayPicture' src="https://api-crm.s3.eu-west-3.amazonaws.com/{{currentPicture.userId}}/picture/{{currentPicture.image}}">
      <img *ngIf='displayPicture' [src]="sanitizer.bypassSecurityTrustUrl(imgUpload)">
  </div>
  <div class="profile-picture col-3" *ngIf="!currentPicture">
    <input accept=".jpg,.jpeg,.png" type="file" name="upload" id="upload" class="upload-box" (change)="selectPicture($event)">
    <mat-spinner  diameter="40" *ngIf="loading"></mat-spinner>

      <img src="../../assets/empty_pp.svg">
  </div>

  <div class="col-8 offset-1 mt-4">
    <h5 class="m-0">{{ user.username }}</h5>
    <h6 class="m-0">{{ user.role }}</h6>
    <p style="font-size: 0.7em;">{{ user.mail }}</p>
  </div>
</div>
<div *ngIf="message" class="alert-success" role="alert">
  Photo changée avec succès
</div>
<div class="row">

</div>

<div class="row mdp ml-1 mt-5 disabled">

  <div class="col-2">
    <img src="../../assets/icons/modif-mdp.svg">
  </div>
  <p class="col-7">
    Modifier mot de passe
  </p>
  <div class="col-3">
    <img src="../../assets/icons/blue-arrow.svg">
  </div>
</div>


  <a href="mailto:maxime.vasseur@enerconfort.com" class="row mdp ml-1 mt-3">


  <div class="col-2">
    <img src="../../assets/icons/signaler.svg">
  </div>
  <p class="col-7">
    Signaler un problème
  </p>
  <div class="col-3">
    <img src="../../assets/icons/blue-arrow.svg">
  </div>
</a>

<div class="text-center">
  <strong>Pégase ©</strong>
  <p class="m-0">Version : 1.0</p>
  <p class="m-0">{{ date  | date:'dd/MM/yy'}}</p>
  <a class="logout" (click)="logout()">Se déconnecter</a>
</div>


</div>
</ng-template>

<ng-template #elseBlockDesktop>

<div class="row">
  <div class="profile-picture-desk col-6" *ngIf="currentPicture">
    <input accept=".jpg,.jpeg,.png" type="file" name="upload" id="upload" class="upload-box" (change)="selectPicture($event)">
    <mat-spinner  diameter="40" *ngIf="loading"></mat-spinner>

      <img *ngIf='!displayPicture' src="https://api-crm.s3.eu-west-3.amazonaws.com/{{currentPicture.userId}}/picture/{{currentPicture.image}}">
      <img *ngIf='displayPicture' [src]="sanitizer.bypassSecurityTrustUrl(imgUpload)">
  </div>
  <div class="profile-picture-desk col-6" *ngIf="!currentPicture">
    <input accept=".jpg,.jpeg,.png" type="file" name="upload" id="upload" class="upload-box" (change)="selectPicture($event)">
    <mat-spinner  diameter="40" *ngIf="loading"></mat-spinner>

      <img src="../../assets/empty_pp.svg">
  </div>

  <div class="col-6 mt-4">
    <h2 class="m-0">{{ user.username }}</h2>
    <h4 class="m-0">{{ user.role }}</h4>
    <p>{{ user.mail }}</p>

    <div class="row mdp ml-1 mt-5 disabled">

      <div class="col-2">
        <img src="../../assets/icons/modif-mdp.svg">
      </div>
      <p class="col-6">
        Modifier mot de passe
      </p>
      <div class="col-3">
        <img src="../../assets/icons/blue-arrow.svg">
      </div>
    </div>

    <div class="row mdp ml-1 mt-3">

      <div class="col-2">
        <img src="../../assets/icons/signaler.svg">
      </div>

      <a href="mailto:maxime.vasseur@enerconfort.com" style="color: var(--primarycrm);" class="col-6">Signaler un problème</a>
      <div class="col-3">
        <img src="../../assets/icons/blue-arrow.svg">
      </div>
    </div>

    <div *ngIf="user.id === '5f76e5503926a01790428179'" class="row mdp ml-1 mt-3">
      <a (click)="goToUsersList()">
        Liste des utilisateurs
    </a>
    <a *ngIf="user.id === '5f76e5503926a01790428179'" (click)="goToCaseTable()">
      Table
    </a>
    <a  *ngIf="user.id === '5f76e5503926a01790428179'" [routerLink]="['/compta-leads']">Leads</a>
    </div>

  <div class="text-center mt-5">
    <strong>Pégase ©</strong>
    <p class="m-0">Version : 1.0</p>
    <p class="m-0">{{ date  | date:'dd/MM/yy'}}</p>
    <a class="logout" (click)="logout()">Se déconnecter</a>
  </div>

  </div>
</div>
<div *ngIf="message" class="alert alert-success" role="alert">
  Photo changée avec succès
</div>

</ng-template>

