
<app-commentbar [caseId]="caseId"></app-commentbar>

<div *ngIf="case">
<div class="info-customer">
 <h3 class="title"> {{case.title}}</h3>
 <div> {{case.customer.name}}</div>
 <div>0{{case.customer.mobile}}  0{{case.customer.fixe}}</div>
 <div> {{case.customer.address}} {{case.customer.city}} {{case.customer.cp}} </div>
   <a style="text-decoration-line: underline;" class="col-9 pl-1"  data-toggle="modal" data-target="#exampleModal">Voir les infos client</a>
</div>

  <!-- POP UP -->
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
   <div class="modal-dialog" role="document">
     <div class="modal-content">
       <div class="modal-body">
         <button type="button" class="close" data-dismiss="modal" >X</button>
         <ul>
           <h3>Mr et Mme {{case.customer.name}}</h3>
           <section>
           <h4>Adresse</h4>
           <li>{{ case.customer.address }}</li>
           <li>{{case.customer.cp}} {{ case.customer.city }}</li>
           </section>
           <section>
           <h4>Numéros</h4>
           <li>{{ case.customer.mobile }}</li>
           <li>{{ case.customer.fixe }}</li>
           <li> <a href="mailto:{{case.customer.mail}}">{{case.customer.mail}}</a></li>
           </section>
         </ul>
       </div>
     </div>
   </div>
   </div>




<div class="icons">
  <a class="nav-item nav-link " style="font-size:0.8rem;" routerLink="/compta-dashboard">
    <img src="../../../assets/bar-icons/compta-encours.svg">
  </a>
  <a class="nav-item nav-link " style="font-size:0.8rem;" routerLink="/compta-cm-table">
    <img src="../../../assets/bar-icons/cm.svg">
  </a>
</div>


<form [formGroup]="cmForm" class="d-flex mt-3 " *ngIf='case' style="width:97%">
  <div class="left-col col-6">
    <p class="subtitle">Contrat de maintenance</p>

    <div class="col-xs-12 col-lg-6 col-sm-3 show-contrat upload-bill" *ngIf="!contrat">
      Aucune pièce contrat de maintenance
     </div>

    <div class="col-xs-12 col-lg-5 col-sm-3 show-contrat" *ngIf="contrat">
      <a href="https://api-crm.s3.eu-west-3.amazonaws.com/{{contrat.caseId}}/pieces/{{contrat.image}}">
      <p>CM_{{ case.customer.name }}</p>
      </a>
     </div>
    <div class="produits mt-5">
      <ul class="pl-4 mt-1" *ngFor='let selected of case.cm.products'>
        <li>{{ selected.name }} <em>{{ selected.formule }}</em></li>
      </ul>
    </div>

    <div class="subtitle">Montant</div>
​
    <p class="montant">{{case.cm.tarif | number }} €</p>
​
    <div class="d-flex">
      <div class="mr-4">
        <label for="type_paiement1" class="btn-secondarycrm" [ngClass]="case.cm.paiement.type_paiement === 'Comptant' ? 'active' : ''">Comptant</label>
      </div>
​
      <div>
        <label for="type_paiement2" class="btn-secondarycrm" [ngClass]="case.cm.paiement.type_paiement === 'Mensualité' ? 'active' : ''">Mensualité</label>
      </div>
​
    </div>
​
    <div *ngIf="case.state === 'Signée'">
      <p class="p-0 m-0">Date de signature</p>
      <form [formGroup]="caseForm">
<!--         <mat-form-field class="col-6">
          <input matInput [matDatepicker]="picker1" class="custom-date" placeholder="JJ/MM/AAAA xxhxx" formControlName="datesign" [(ngModel)]="case.datesign" value="case.datesign" (ngModelChange)="saveChange(case)">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field> -->
          <p style="
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;color:var(--tertiarycrm)">{{case.datesign | date:'medium'}}</p>
      </form>
    </div>

    <div  *ngIf="case.cm.paiement.type_paiement != 'Comptant'">
        <p class="p-0 m-0">Date des mensualités</p>

        <form class="row" formGroupName="paiement">
        <!-- <mat-form-field class="col-6">
            <input matInput [matDatepicker]="picker1" class="custom-date" placeholder="JJ/MM/AAAA xxhxx" formControlName="mensualite" [(ngModel)]="case.cm.paiement.mensualite" value="case.cm.paiement.mensualite" (ngModelChange)="saveChange(case)">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field> -->

<!--           <input class="col-6" type='date' class="custom-date" placeholder="JJ/MM/AAAA xxhxx" formControlName="mensualite" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" [(ngModel)]="case.cm.paiement.mensualite"  value="case.cm.paiement.mensualite" (ngModelChange)="saveChange(case)">
         <owl-date-time #dt2 firstDayOfWeek="1"></owl-date-time>
 -->

          <input class="col-6" type='date' class="custom-date" placeholder="JJ/MM/AAAA xxhxx" formControlName="mensualite"  [(ngModel)]="case.cm.paiement.mensualite" value="case.cm.paiement.mensualite" (ngModelChange)="saveChange(case)">
          <p class="col-6"  style="color: var(--tertiarycrm);margin-top: 2.5%;">{{case.cm.paiement.montant | number }}</p>

        </form>

    </div>

    <div *ngIf="case.cm.paiement.type_paiement != 'Comptant'">
    </div>
  </div>

  <div class="right-col col-6">
    <p class="subtitle">Facture faite</p>

    <div style="display: inline-block;">
      <div class="card toggleBillDone card-facture " [ngClass]="bill ? 'oui' : 'oui-empty'">OUI</div>
        <div class="card toggleBillDone card-facture "  [ngClass]="!bill ? 'non' : 'non-empty'">NON</div>

      <div class="col-xs-12 col-lg-6 col-sm-3 btn-secondarycrm button-wrapper upload-bill" *ngIf="!bill">
        <input accept=".jpg,.jpeg,.png,.pdf" multiple type="file" name="upload" id="upload" class="upload-box" (change)="selectMultiple($event,'facture')">
        + Ajouter la facture
      </div>
    </div>

   <div class="col-xs-12 col-lg-5 col-sm-3 show-contrat" *ngIf="bill">
        <p>FACTURE_{{ case.customer.name }}</p>
        <img src="../../../assets/icons/delete.svg" (click)="killBill(bill._id,'supprimé','pièce',bill.title )">
      </div>

    <p class="subtitle mt-4">Suivi comptable</p>


    <div *ngIf="case.cm.paiement.type_paiement === 'Comptant'">

        <div>

          <div *ngIf="case" >
            <div  *ngIf="case.cm.paiement.arrayCheque">
            <mat-tab-group>
             <mat-tab *ngFor="let year of case.cm.paiement.arrayCheque" [label]="year.year + '/'+ (year.year+1)   " >
               <input type="date" [(ngModel)]="year.date" [ngModelOptions]="{standalone: true}"  (change)='checkCheque(year)' >
              </mat-tab>

           </mat-tab-group>
           <button *ngIf="!case.cm.ended" class="btn-tertiarycrm mt-3 mb-3" (click)="stopCM()" >Mettre fin au CM</button>
           <div class="subtitle mt-5"  *ngIf="case.cm.ended" >CM Terminé </div>
          </div>
           <div *ngIf="case.cm.paiement.arrayCheque == undefined">
            <label>Date d'encaissement du 1er chèque</label><br>
           <input type="date" [(ngModel)]="firstCheque" [ngModelOptions]="{standalone: true}" (change)='checkCheque()' >
           </div>
           </div>
 <!--          <form formGroupName="paiement" >

          <p class="subtitle mb-1">Encaissement du chèque</p>
               <input *ngIf="case.cm.cheque_encaissement" class="custom-date" placeholder="JJ/MM/AAAA xxhxx" [(ngModel)]="dateTabCheque" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" formControlName="arrayChequeYear"  value="case.cm.paiement.cheque_encaissement" (ngModelChange)="checkCheque()">
              <owl-date-time #dt2 firstDayOfWeek="1"></owl-date-time>


          <p style="margin: 0; color:rgba(105, 106, 130, 1);">Chèque déposé le {{ case.cm.paiement.cheque1 | date:'short' }}</p>
        </form> -->
        </div>

    </div>


    <div *ngIf="case.cm.paiement.type_paiement != 'Comptant'">
      <mat-tab-group class="col-layout main-body">

          <mat-tab [label]="tab.value[0].year" *ngFor="let tab of tabs | keyvalue">
              <ng-template matTabContent>
                <div class="months-buttons">
                  <button *ngFor="let array of tab.value" class="btn-secondarycrm btn-padding  mt-3"
                  [ngClass]="{
                    valid: array.state === 'Payé',
                    non: array.state === 'Impayé'
                  }"
                  (click)="showMonthSelected(array)">{{ array.name }}</button>
                </div>
              </ng-template>
          </mat-tab>

      </mat-tab-group>


<!--       <mat-tab-group>
        <mat-tab [label]="dateSignYear">

          <div class="months-buttons">
            <button *ngFor="let array of this.case.cm.years[0]" class="btn-secondarycrm btn-padding"
            [ngClass]="{
              valid: array.state === 'Payé',
              non: array.state === 'Impayé'
            }"
            (click)="showMonthSelected(array)">{{ array.name }}</button>
          </div>
        </mat-tab>

        <mat-tab [label]="dateSignYear + 1">

          <div class="months-buttons">
            <button *ngFor="let array of this.case.cm.years[1]" class="btn-secondarycrm btn-padding"
            [ngClass]="{
              valid: array.state === 'Payé',
              non: array.state === 'Impayé'
            }"
            (click)="showMonthSelected(array)">{{ array.name }}</button>
          </div>

        </mat-tab>
      </mat-tab-group> -->
<!--             <div class="months-buttons">
              <button *ngFor="let month of case.cm.paiement.months" class="btn-secondarycrm btn-padding"
              [ngClass]="{
                valid: month.state === 'Payé',
                non: month.state === 'Impayé'
              }"
              (click)="showMonthSelected(month)">{{ month.name }}</button>
            </div> -->
          <h3 *ngIf="selectedMonth" class="subtitle mt-5">{{selectedMonth}}</h3>

        <div class="row" *ngIf="selectedObject">
          <div class="col-8">
            <mat-form-field class="example-full-width" class="custom-padding">
              <input matInput type="number" placeholder="Montant" [value]=selectedObject.montant (change)="editMontant($event.target.value)">
            </mat-form-field>
          </div>
          <div class="col-2 offset-1">
           <select (change)="editState($event.target.value)" class="select-custom">
            <option *ngIf="selectedObject.state === 'en attente'" [value]=selectedObject.state>en attente</option>
            <option *ngIf="selectedObject.state === 'en attente'" value="Payé">Oui</option>
            <option *ngIf="selectedObject.state === 'en attente'" value="Impayé">Non</option>

            <option *ngIf="selectedObject.state === 'Impayé'" [value]=selectedObject.state>Non</option>
            <option *ngIf="selectedObject.state === 'Impayé'" value="Payé">Oui</option>
            <option *ngIf="selectedObject.state === 'Impayé'" value="en attente">En attente</option>

            <option *ngIf="selectedObject.state === 'Payé'" [value]=selectedObject.state>Oui</option>
            <option *ngIf="selectedObject.state === 'Payé'" value="Impayé">Non</option>
            <option *ngIf="selectedObject.state === 'Payé'" value="en attente">En attente</option>
          </select>
        </div>
        </div>
<!--
          <textarea class="comment" placeholder="Commentaire" (change)="editComment($event.target.value)"></textarea>
 -->
          <button *ngIf="!case.cm.ended"  class="btn-tertiarycrm mt-3 mb-3" (click)="stopCM()" >Mettre fin au CM</button>
          <div class="subtitle mt-5" *ngIf="case.cm.ended" > CM Terminé  </div>


    </div>

  </div>
</form>

