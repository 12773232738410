import { UsersService } from './users.service';
import { CasesService } from './cases.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class StatService {
  cases;
  allCasesSigned;

  months = 12;
  CaNetByMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  caNetAnnuel = 0;
  CaBrutByMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  caBrutAnnuel = 0;
  CaNet2020ByMonth = [
    182500, 320200, 132700, 329000, 107600, 262800, 552600, 358500, 493500,
    344600, 423700, 444800,
  ];
  CaNet2021ByMonth = [
    410877.51, 537357.45, 557863.41, 676215.25, 455483.94, 638331.03, 245864.09,
    439558.93, 774031.71, 773706.92, 612361.85, 313835.27,
  ];

  agencyArrayToDisplay = [
    {
      name: 'Néros',
      caBrut: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      caBrutAnnuel: 0,
      caNet: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      caNetAnnuel: 0,
      case: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      cancelCase: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      dataGraph: [100, 0],
      color: [
        {
          backgroundColor: ['#E95E24', '#3A3B5A'],
          borderColor: ['#E95E24', '#3A3B5A'],
        },
      ],
      dataGraphAnnuel: [100, 0],
      colorAnnuel: [
        {
          backgroundColor: ['#E95E24', '#3A3B5A'],
          borderColor: ['#E95E24', '#3A3B5A'],
        },
      ],
      rateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      newRateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      newRateCancelAnuel: 0,
      rateRound: '',
      commerciaux: [],
      disabled: false,
    },
    {
      name: 'Hernos',
      caBrut: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      caBrutAnnuel: 0,
      caNet: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      caNetAnnuel: 0,
      case: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      cancelCase: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      dataGraph: [100, 0],
      color: [
        {
          backgroundColor: ['#E95E24', '#3A3B5A'],
          borderColor: ['#E95E24', '#3A3B5A'],
        },
      ],
      dataGraphAnnuel: [100, 0],
      colorAnnuel: [
        {
          backgroundColor: ['#E95E24', '#3A3B5A'],
          borderColor: ['#E95E24', '#3A3B5A'],
        },
      ],
      rateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      newRateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      newRateCancelAnuel: 0,
      rateRound: '',
      commerciaux: [],
      disabled: false,
    },
    {
      name: 'Inti',
      caBrut: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      caBrutAnnuel: 0,
      caNet: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      caNetAnnuel: 0,
      case: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      cancelCase: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      dataGraph: [100, 0],
      color: [
        {
          backgroundColor: ['#E95E24', '#3A3B5A'],
          borderColor: ['#E95E24', '#3A3B5A'],
        },
      ],
      dataGraphAnnuel: [100, 0],
      colorAnnuel: [
        {
          backgroundColor: ['#E95E24', '#3A3B5A'],
          borderColor: ['#E95E24', '#3A3B5A'],
        },
      ],
      rateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      newRateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      newRateCancelAnuel: 0,
      rateRound: '',
      commerciaux: [],
      disabled: false,
    },
    {
      name: 'Sparthios',
      caBrut: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      caBrutAnnuel: 0,
      caNet: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      caNetAnnuel: 0,
      case: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      cancelCase: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      dataGraph: [100, 0],
      color: [
        {
          backgroundColor: ['#E95E24', '#3A3B5A'],
          borderColor: ['#E95E24', '#3A3B5A'],
        },
      ],
      dataGraphAnnuel: [100, 0],
      colorAnnuel: [
        {
          backgroundColor: ['#E95E24', '#3A3B5A'],
          borderColor: ['#E95E24', '#3A3B5A'],
        },
      ],
      rateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      newRateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      newRateCancelAnuel: 0,
      rateRound: '',
      commerciaux: [],
      disabled: false,
    },
    {
      name: 'Amon',
      caBrut: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      caBrutAnnuel: 0,
      caNet: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      caNetAnnuel: 0,
      case: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      cancelCase: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      dataGraph: [100, 0],
      color: [
        {
          backgroundColor: ['#E95E24', '#3A3B5A'],
          borderColor: ['#E95E24', '#3A3B5A'],
        },
      ],
      dataGraphAnnuel: [100, 0],
      colorAnnuel: [
        {
          backgroundColor: ['#E95E24', '#3A3B5A'],
          borderColor: ['#E95E24', '#3A3B5A'],
        },
      ],
      rateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      newRateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      newRateCancelAnuel: 0,
      rateRound: '',
      commerciaux: [],
      disabled: false,
    },
    {
      name: 'Dordogne',
      caBrut: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      caBrutAnnuel: 0,
      caNet: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      caNetAnnuel: 0,
      case: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      cancelCase: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      dataGraph: [100, 0],
      color: [
        {
          backgroundColor: ['#E95E24', '#3A3B5A'],
          borderColor: ['#E95E24', '#3A3B5A'],
        },
      ],
      dataGraphAnnuel: [100, 0],
      colorAnnuel: [
        {
          backgroundColor: ['#E95E24', '#3A3B5A'],
          borderColor: ['#E95E24', '#3A3B5A'],
        },
      ],
      rateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      newRateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      newRateCancelAnuel: 0,
      rateRound: '',
      commerciaux: [],
      disabled: false,
    },
  ];

  agencyArray = [
    {
      name: 'Néros',
      caBrut: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      caBrutAnnuel: 0,
      caNet: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      caNetAnnuel: 0,
      case: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      cancelCase: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      dataGraph: [100, 0],
      color: [
        {
          backgroundColor: ['#E95E24', '#3A3B5A'],
          borderColor: ['#E95E24', '#3A3B5A'],
        },
      ],
      dataGraphAnnuel: [100, 0],
      colorAnnuel: [
        {
          backgroundColor: ['#E95E24', '#3A3B5A'],
          borderColor: ['#E95E24', '#3A3B5A'],
        },
      ],
      rateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      newRateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      newRateCancelAnuel: 0,
      rateRound: '',
      commerciaux: [],
      disabled: false,
    },
    {
      name: 'Anakélios',
      caBrut: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      caBrutAnnuel: 0,
      caNet: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      caNetAnnuel: 0,
      case: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      cancelCase: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      dataGraph: [100, 0],
      color: [
        {
          backgroundColor: ['#E95E24', '#3A3B5A'],
          borderColor: ['#E95E24', '#3A3B5A'],
        },
      ],
      dataGraphAnnuel: [100, 0],
      colorAnnuel: [
        {
          backgroundColor: ['#E95E24', '#3A3B5A'],
          borderColor: ['#E95E24', '#3A3B5A'],
        },
      ],
      rateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      rateRound: '',
      newRateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      newRateCancelAnuel: 0,
      commerciaux: [],
      disabled: false,
    },
    {
      name: 'Hernos',
      caBrut: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      caBrutAnnuel: 0,
      caNet: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      caNetAnnuel: 0,
      case: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      cancelCase: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      dataGraph: [100, 0],
      color: [
        {
          backgroundColor: ['#E95E24', '#3A3B5A'],
          borderColor: ['#E95E24', '#3A3B5A'],
        },
      ],
      dataGraphAnnuel: [100, 0],
      colorAnnuel: [
        {
          backgroundColor: ['#E95E24', '#3A3B5A'],
          borderColor: ['#E95E24', '#3A3B5A'],
        },
      ],
      rateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      newRateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      newRateCancelAnuel: 0,
      rateRound: '',
      commerciaux: [],
      disabled: false,
    },
    {
      name: 'Inti',
      caBrut: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      caBrutAnnuel: 0,
      caNet: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      caNetAnnuel: 0,
      case: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      cancelCase: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      dataGraph: [100, 0],
      color: [
        {
          backgroundColor: ['#E95E24', '#3A3B5A'],
          borderColor: ['#E95E24', '#3A3B5A'],
        },
      ],
      dataGraphAnnuel: [100, 0],
      colorAnnuel: [
        {
          backgroundColor: ['#E95E24', '#3A3B5A'],
          borderColor: ['#E95E24', '#3A3B5A'],
        },
      ],
      rateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      newRateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      newRateCancelAnuel: 0,
      rateRound: '',
      commerciaux: [],
      disabled: false,
    },
    {
      name: 'Dordogne',
      caBrut: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      caBrutAnnuel: 0,
      caNet: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      caNetAnnuel: 0,
      case: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      cancelCase: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      dataGraph: [100, 0],
      color: [
        {
          backgroundColor: ['#E95E24', '#3A3B5A'],
          borderColor: ['#E95E24', '#3A3B5A'],
        },
      ],
      dataGraphAnnuel: [100, 0],
      colorAnnuel: [
        {
          backgroundColor: ['#E95E24', '#3A3B5A'],
          borderColor: ['#E95E24', '#3A3B5A'],
        },
      ],
      rateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      newRateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      newRateCancelAnuel: 0,
      rateRound: '',
      commerciaux: [],
      disabled: false,
    },
    {
      name: 'Amon',
      caBrut: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      caBrutAnnuel: 0,
      caNet: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      caNetAnnuel: 0,
      case: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      cancelCase: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      dataGraph: [100, 0],
      color: [
        {
          backgroundColor: ['#E95E24', '#3A3B5A'],
          borderColor: ['#E95E24', '#3A3B5A'],
        },
      ],
      dataGraphAnnuel: [100, 0],
      colorAnnuel: [
        {
          backgroundColor: ['#E95E24', '#3A3B5A'],
          borderColor: ['#E95E24', '#3A3B5A'],
        },
      ],
      rateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      newRateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      newRateCancelAnuel: 0,
      rateRound: '',
      commerciaux: [],
      disabled: false,
    },
    {
      name: 'Amon',
      caBrut: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      caBrutAnnuel: 0,
      caNet: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      caNetAnnuel: 0,
      case: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      cancelCase: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      dataGraph: [100, 0],
      color: [
        {
          backgroundColor: ['#E95E24', '#3A3B5A'],
          borderColor: ['#E95E24', '#3A3B5A'],
        },
      ],
      dataGraphAnnuel: [100, 0],
      colorAnnuel: [
        {
          backgroundColor: ['#E95E24', '#3A3B5A'],
          borderColor: ['#E95E24', '#3A3B5A'],
        },
      ],
      rateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      newRateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      newRateCancelAnuel: 0,
      rateRound: '',
      commerciaux: [],
      disabled: false,
    },
  ];

  cm = {
    name: 'CM',
    caBrut: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    caBrutAnnuel: 0,
    caNet: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    caNetAnnuel: 0,
    case: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    cancelCase: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    rateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    newRateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    newRateCancelAnuel: 0,
    rateRound: '',
    adv: [],
  };

  arrayFinancement = [
    {
      name: 'cetelem',
      caNetMonth: 0,
      caNetAnnuel: 0,
    },
    {
      name: 'sofinco',
      caNetMonth: 0,
      caNetAnnuel: 0,
    },
    {
      name: 'Autres',
      caNetMonth: 0,
      caNetAnnuel: 0,
    },
    {
      name: 'Comptant',
      caNetMonth: 0,
      caNetAnnuel: 0,
    },
  ];

  commerciauxCaBrut = [];
  commerciauxCaNet = [];
  userCaBrut = [];
  userCaNet = [];
  agencyCaNet = [];
  agencyCaBrut = [];

  cancelCase = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  cancelRate = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  rateRound = ' ';
  newCancelcase = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  newCancelcaseAnnuel = 0;

  commerciaux;

  actualMonth = new Date().getMonth();
  actualYear = new Date().getFullYear();
  month = new Date();

  chosenCommercial;
  allCases;

  waitingQualification = [];

  allCommercialCase = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  allCasesSignedByMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  calculateCaNetAnnuel = false;
  calculateCabrutAnnuel = false;
  calculateRateRDV = false;

  showCommercial;


  dataBarGraphAnnuel = [
    {
      data: [],
      label: 'Ca Net',
      stack: 'a',
      backgroundColor: '#E95E24',
      hoverBackgroundColor: '#E95E24',
    },
    {
      data: [],
      label: 'Ca brut',
      stack: 'a',
      backgroundColor: '#3A3B5A',
      hoverBackgroundColor: '#3A3B5A',
    },
  ];
  labelBarGraph = [];
  labelBarGraphAnnuel = [];
  loadDougnhut = false;
  doughnutAnnuel = false;
  barAnnuel = false;

  loading = false;

  constructor(private sCases: CasesService, public sUsers: UsersService, private http: HttpClient) { }

  launch() {
    this.actualMonth = this.month.getMonth();
    this.actualYear = this.month.getFullYear();

    this.sUsers.showUserRole();
    this.sUsers.getAllTrueCommerciaux().subscribe((res) => {
      this.commerciaux = res['users'];
      this.attributeCommerciauxToAgence();
    });
    this.sUsers.getAllAdv().subscribe((res) => {
      this.cm.adv = res['users'];
      this.cm.adv.forEach((adv) => {
        adv.case = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        adv.caNet = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        adv.caNetAnnuel = 0;
        adv.caBrut = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        adv.caBrutAnnuel = 0;
        adv.cancelCase = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        adv.rateCancel = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        adv.newRateCancelAnuel = 0;
      });
    });
  }

  generateExcel(data): void {
    const excelUrl = 'https://pegase-api.herokuapp.com/excel';

    this.http
      .post(excelUrl, data, { responseType: 'blob' })
      .subscribe(
        (response: Blob) => {
          const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'dashboard.xlsx';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        },
        (error) => {
          console.error('Erreur lors de la génération du fichier :', error);
        }
      );
  }

  resetStat() {
    return new Promise((resolve, reject) => {
      this.CaNetByMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.caNetAnnuel = 0;
      this.CaBrutByMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.caBrutAnnuel = 0;
      this.CaNet2020ByMonth = [
        182500, 320200, 132700, 329000, 107600, 262800, 552600, 358500, 493500,
        344600, 423700, 444800,
      ];
      this.CaNet2021ByMonth = [
        410877.51, 537357.45, 557863.41, 676215.25, 455483.94, 638331.03,
        245864.09, 439558.93, 774031.71, 773706.92, 612361.85, 313835.27,
      ];
      this.calculateCaNetAnnuel = false;
      this.calculateCabrutAnnuel = false;
      this.calculateRateRDV = false;

      this.arrayFinancement.forEach((element) => {
        element.caNetMonth = 0;
        element.caNetAnnuel = 0;
      });
      this.agencyArray.forEach((cases) => {
        cases.caBrut = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        cases.caBrutAnnuel = 0;
        cases.caNet = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        cases.caNetAnnuel = 0;
        cases.case = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        cases.cancelCase = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        cases.dataGraph = [100, 0];
        cases.color = [
          {
            backgroundColor: ['#E95E24', '#3A3B5A'],
            borderColor: ['#E95E24', '#3A3B5A'],
          },
        ];
        cases.dataGraphAnnuel = [100, 0];
        cases.colorAnnuel = [
          {
            backgroundColor: ['#E95E24', '#3A3B5A'],
            borderColor: ['#E95E24', '#3A3B5A'],
          },
        ];
        cases.rateCancel = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        cases.newRateCancel = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        cases.newRateCancelAnuel = 0;
        cases.rateRound = '';
        cases.commerciaux = [];
        cases.disabled = false;
      });
      this.cm = {
        name: 'CM',
        caBrut: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        caBrutAnnuel: 0,
        caNet: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        caNetAnnuel: 0,
        case: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        cancelCase: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        rateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        newRateCancel: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        newRateCancelAnuel: 0,
        rateRound: '',
        adv: [],
      };

      this.commerciauxCaBrut = [];
      this.commerciauxCaNet = [];
      this.userCaBrut = [];
      this.userCaNet = [];
      this.agencyCaNet = [];
      this.agencyCaBrut = [];
      this.cancelCase = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.cancelRate = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.rateRound = ' ';
      this.newCancelcase = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.newCancelcaseAnnuel = 0;
      this.waitingQualification = [];
      this.allCommercialCase = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.allCasesSignedByMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.launch();
      resolve('Reset Terminée');
    });
  }

  attributeCommerciauxToAgence() {
    this.commerciaux.forEach((element) => {
      element.case = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      element.caNet = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      element.caNetAnnuel = 0;
      element.caBrut = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      element.caBrutAnnuel = 0;
      element.cancelCase = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      element.rateCancel = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      element.countRdv = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      element.countRdvAnnuel = 0;
      element.countRdvQualified = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      element.countRdvQualifiedAnnuel = 0;
      element.rateRdvQualified = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      element.rateRdvQualifiedAnnuel = 0;
      element.rateRound = '';
      element.newCancelRate = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      element.newRateCancelAnuel = 0;
      let a = 0;
      while (a < this.agencyArray.length) {
        if (element.agency == this.agencyArray[a].name) {
          this.agencyArray[a].commerciaux.push(element);
          break;
        } else {
          a++;
        }
      }
    });
    Promise.all([this.showCases(), this.showAllCases()]).then((rese) => {
      this.NewCalculRatedCancelThisMonth().then((res) => {
        this.loading = true;
        this.initDougnhutGraphData();
      });
    });
  }

  showCases() {
    return new Promise((resolve, reject) => {
      this.sCases.getCaseByState().subscribe((res) => {
        this.cases = res['cases'];
        this.cases.map((x) => (x.datesign = new Date(x.datesign)));
        this.cases = this.cases.filter(
          (c) => c.datesign.getFullYear() == this.actualYear
        );
        this.calculateCAActualMonth().then((res) => {
          resolve('cases Load');
        });
      });
    });
  }

  showAllCases() {
    return new Promise((resolve, reject) => {
      this.sCases.getAllCases().subscribe((res) => {
        this.allCases = res['cases'];
        this.allCases.map((x) =>
          x.r1 != undefined ? (x.r1[0].date = new Date(x.r1[0].date)) : ''
        );
        this.allCases = this.allCases.filter(
          (c) => c.r1[0].date.getFullYear() == this.actualYear
        );
        this.getNumberofWaitingQualification();
        this.calculateRateRdvThisMonth().then((res) => {
          resolve('All Cases Load');
        });
      });
      this.showCancelCases();
    });
  }

  showCancelCases() {
    this.sCases.getCaseByCancelState().subscribe((res) => {
      this.allCasesSigned = res['cases'];
      this.allCasesSigned.map((x) => (x.datesign = new Date(x.datesign)));
      this.allCasesSigned = this.allCasesSigned.filter(
        (c) => c.datesign.getFullYear() == this.actualYear
      );
      this.calculateCABruteActualMonth().then((res) => { });
    });
  }

  launchCaNetGraph() {
    return new Promise((resolve, reject) => {
      this.sCases.getCaseByState().subscribe((res) => {
        this.cases = res['cases'];
        this.cases.map((x) => (x.datesign = new Date(x.datesign)));
        this.cases = this.cases.filter(
          (c) => c.datesign.getFullYear() == this.actualYear
        );

        this.calculateCAByMonth().then((res) => {
          resolve(this.CaNetByMonth);
        });
      });
    });
  }

  launchCaBrutGraph() {
    return new Promise((resolve, reject) => {
      this.sCases.getCaseByCancelState().subscribe((res) => {
        this.allCasesSigned = res['cases'];
        this.allCasesSigned.map((x) => (x.datesign = new Date(x.datesign)));
        this.allCasesSigned = this.allCasesSigned.filter(
          (c) => c.datesign.getFullYear() == this.actualYear
        );
        this.calculateCABruteByMonth().then((res) => {
          resolve(this.CaBrutByMonth);
        });
        this.calculateCAAnnuel();
      });
    });
  }

  calculateCAActualMonth() {
    return new Promise((resolve, reject) => {
      this.userCaNet = [];
      let m = this.actualMonth;
      let caseThisMonth;
      caseThisMonth = this.cases.filter(
        (c) =>
          c.datesign.getMonth() == this.actualMonth &&
          c.datesign.getFullYear() === this.actualYear
      );
      caseThisMonth.forEach((element) => {
        if (element.commercial != null) {
          if (element.datecancel != null || element.datesign != null) {
            let financement = this.arrayFinancement.find(
              (f) => f.name == element.financement_type
            );

            if (financement == undefined) {
              financement = this.arrayFinancement[2];
            }
            if (element.price_ht > 0) {
              financement.caNetMonth += element.price_ht;
            }

            if (element.type != 'Contrat de maintenance') {
              if (element.price_ht) {
                this.CaNetByMonth[m] += element.price_ht;
                // On filtre pour obtenir l'agence de la case
                let agency = this.agencyArray.find(
                  (a) => a.name == element.commercial.agency
                );
                if (agency != undefined) {
                  agency.caNet[m] += element.price_ht;
                  let commercial = agency.commerciaux.find(
                    (c) => c._id == element.commercial._id
                  );
                  commercial.caNet[m] += element.price_ht;
                } else if (this.cm.caBrut != undefined) {
                  this.cm.caNet[m] += element.price_ht;

                  let adv = this.cm.adv.find(
                    (a) => element.commercial._id == a._id
                  );
                  if (adv) {
                    adv.caNet[m] += element.price_ht;
                  }
                }
              }
            } else {
              this.cm.caNet[m] += element?.cm?.tarif;
              let adv = this.cm.adv.find(
                (a) => element.commercial._id == a._id
              );
              if (adv) {
                adv.caNet[m] += element?.cm?.tarif;
              }
            }
          }
        }
      });
      this.commerciaux.forEach((element) => {
        if (element.isActive == true || element.caNet[this.actualMonth] != 0)
          this.userCaNet.push(element);
      });
      this.cm.adv.forEach((element) => {
        if (element.caNet[this.actualMonth] != 0) {
          this.userCaNet.push(element);
        }
      });
      this.userCaNet.sort((a, b) =>
        a.caNet[this.actualMonth] > b.caNet[this.actualMonth] ? -1 : 1
      );
      this.agencyArray.sort((a, b) =>
        a.caNet[this.actualMonth] > b.caNet[this.actualMonth] ? -1 : 1
      );
      resolve(' CA Net Load');
    });
  }

  calculateCAByMonth() {
    return new Promise((resolve, reject) => {
      this.cases.forEach((element) => {
        if (element.commercial != null) {
          if (element.datecancel != null || element.datesign != null) {
            // m correspond a l'index du mois dans le tableaux
            let m = element.datesign.getMonth();
            if (element.type != 'Contrat de maintenance') {
              if (element.price_ht) {
                this.CaNetByMonth[m] += element.price_ht;
                // On filtre pour obtenir l'agence de la case
                let agency = this.agencyArray.find(
                  (a) => a.name == element.commercial.agency
                );
                if (agency != undefined && this.cm.caNet != undefined) {
                  agency.caNet[m] += element.price_ht;
                  let commercial = agency.commerciaux.find(
                    (c) => c._id == element.commercial._id
                  );
                  commercial.caNet[m] += element.price_ht;
                } else {
                  this.cm.caNet[m] += element.price_ht;
                  let adv = this.cm.adv.find(
                    (a) => element.commercial._id == a._id
                  );
                  adv.caNet[m] += element.price_ht;
                }
              }
            } else {
              this.cm.caNet[m] += element?.cm?.tarif;
              let adv = this.cm.adv.find(
                (a) => element.commercial._id == a._id
              );
              adv.caNet[m] += element.cm.tarif;
            }
          }
        }
      });
      this.commerciaux.forEach((element) => {
        if (element.isActive == true || element.caNet[this.actualMonth] != 0)
          this.userCaNet.push(element);
      });
      this.cm.adv.forEach((element) => {
        if (element.caNet[this.actualMonth] != 0) {
          this.userCaNet.push(element);
        }
      });
      this.userCaNet.sort((a, b) =>
        a.caNet[this.actualMonth] > b.caNet[this.actualMonth] ? -1 : 1
      );
      this.agencyArray.sort((a, b) =>
        a.caNet[this.actualMonth] > b.caNet[this.actualMonth] ? -1 : 1
      );
      resolve(' CA Net Load');
    });
  }

  calculateCAAnnuel() {
    if (!this.calculateCaNetAnnuel) {
      return new Promise((resolve, reject) => {
        this.cases.forEach((element) => {
          if (element.commercial != null) {
            if (element.datecancel != null || element.datesign != null) {
              let financement = this.arrayFinancement.find(
                (f) => f.name == element.financement_type
              );
              if (financement == undefined) {
                financement = this.arrayFinancement[2];
              }
              if (element.price_ht > 0) {
                financement.caNetAnnuel += element.price_ht;
              }
              if (element.type != 'Contrat de maintenance') {
                if (element.price_ht) {
                  this.caNetAnnuel += element.price_ht;
                  // On filtre pour obtenir l'agence de la case
                  let agency = this.agencyArray.find(
                    (a) => a.name == element.commercial.agency
                  );
                  if (agency != undefined) {
                    agency.caNetAnnuel += element.price_ht;
                    let commercial = agency.commerciaux.find(
                      (c) => c._id == element.commercial._id
                    );
                    commercial.caNetAnnuel += element.price_ht;
                  } else {
                    this.cm.caNetAnnuel += element.price_ht;
                    let adv = this.cm.adv.find(
                      (a) => element.commercial._id == a._id
                    );
                    adv.caNetAnnuel += element.price_ht;
                  }
                }
              } else {
                this.cm.caNetAnnuel += element.cm.tarif;
                let adv = this.cm.adv.find(
                  (a) => element.commercial._id == a._id
                );
                adv.caNetAnnuel += element.cm.tarif;
              }
            }
          }
        });
        this.userCaNet.sort((a, b) => (a.caNetAnnuel > b.caNetAnnuel ? -1 : 1));
        this.agencyArray.sort((a, b) =>
          a.caNetAnnuel > b.caNetAnnuel ? -1 : 1
        );
        resolve(' CA Net Load');
        this.calculateCaNetAnnuel = true;
      });
    }
  }

  calculateCABruteActualMonth() {
    return new Promise((resolve, reject) => {
      this.userCaBrut = [];
      let m = this.actualMonth;
      let allCases = this.allCasesSigned;
      allCases = this.allCasesSigned.filter(
        (c) =>
          c.datesign.getMonth() == this.actualMonth &&
          c.datesign.getFullYear() === this.actualYear
      );
      allCases.forEach((element) => {
        if (element.commercial != null) {
          if (element.datecancel != null || element.datesign != null) {
            if (element.type != 'Contrat de maintenance') {
              if (element.price_ht) {
                this.CaBrutByMonth[m] += element.price_ht;
                let agency = this.agencyArray.find(
                  (a) => a.name == element.commercial.agency
                );
                if (agency != undefined && this.cm.caBrut != undefined) {
                  agency.caBrut[m] += element.price_ht;
                  let commercial = agency.commerciaux.find(
                    (c) => element.commercial._id == c._id
                  );
                  commercial.caBrut[m] += element.price_ht;
                } else {
                  this.cm.caBrut[m] += element.price_ht;
                  let adv = this.cm.adv.find(
                    (a) => a._id == element.commercial._id
                  );
                  if (adv) {
                    adv.caBrut[m] += element.price_ht;
                  }
                }
              }
            } else {
              this.cm.caBrut[m] += element.cm.tarif;
              let adv = this.cm.adv.find(
                (a) => a._id == element.commercial._id
              );
              adv.caBrut[m] += element.cm.tarif;
            }
          }
        }
      });

      this.cm.adv.forEach((element) => {
        if (element.caBrut[this.actualMonth] != 0) {
          this.userCaBrut.push(element);
        }
      });
      this.commerciaux.forEach((element) => {
        if (element.isActive || element.caBrut[this.actualMonth] != 0)
          this.userCaBrut.push(element);
      });
      this.userCaBrut.sort((a, b) =>
        a.caBrut[this.actualMonth] > b.caBrut[this.actualMonth] ? -1 : 1
      );
      resolve('CA Brute Load');
    });
  }

  calculateCABruteByMonth() {
    return new Promise((resolve, reject) => {
      this.allCasesSigned.forEach((element) => {
        if (element.commercial != null) {
          if (element.datecancel != null || element.datesign != null) {
            let m = element.datesign.getMonth();
            if (element.type != 'Contrat de maintenance') {
              if (element.price_ht) {
                this.CaBrutByMonth[m] += element.price_ht;
                let agency = this.agencyArray.find(
                  (a) => a.name == element.commercial.agency
                );
                if (agency != undefined) {
                  agency.caBrut[m] += element.price_ht;
                  let commercial = agency.commerciaux.find(
                    (c) => element.commercial._id == c._id
                  );
                  commercial.caBrut[m] += element.price_ht;
                } else {
                  this.cm.caBrut[m] += element.price_ht;
                  let adv = this.cm.adv.find(
                    (a) => a._id == element.commercial._id
                  );
                  adv.caBrut[m] += element.price_ht;
                }
              }
            } else {
              this.cm.caBrut[m] += element.cm.tarif;
              let adv = this.cm.adv.find(
                (a) => a._id == element.commercial._id
              );
              adv.caBrut[m] += element.cm.tarif;
            }
          }
        }
      });

      this.cm.adv.forEach((element) => {
        if (element.caBrut[this.actualMonth] != 0) {
          this.userCaBrut.push(element);
        }
      });
      this.commerciaux.forEach((element) => {
        if (element.isActive || element.caBrut[this.actualMonth] != 0)
          this.userCaBrut.push(element);
      });
      this.userCaBrut.sort((a, b) =>
        a.caBrut[this.actualMonth] > b.caBrut[this.actualMonth] ? -1 : 1
      );
      resolve('CA Brute Load');
    });
  }

  calculateCABrutAnnuel() {
    if (!this.calculateCabrutAnnuel) {
      return new Promise((resolve, reject) => {
        this.allCasesSigned.forEach((element) => {
          if (element.commercial != null) {
            if (element.datecancel != null || element.datesign != null) {
              if (element.type != 'Contrat de maintenance') {
                if (element.price_ht) {
                  this.caBrutAnnuel += element.price_ht;
                  let agency = this.agencyArray.find(
                    (a) => a.name == element.commercial.agency
                  );
                  if (agency != undefined) {
                    agency.caBrutAnnuel += element.price_ht;
                    let commercial = agency.commerciaux.find(
                      (c) => element.commercial._id == c._id
                    );
                    commercial.caBrutAnnuel += element.price_ht;
                  } else {
                    this.cm.caBrutAnnuel += element.price_ht;
                    let adv = this.cm.adv.find(
                      (a) => a._id == element.commercial._id
                    );
                    adv.caBrutAnnuel += element.price_ht;
                  }
                }
              } else {
                this.cm.caBrutAnnuel += element.cm.tarif;
                let adv = this.cm.adv.find(
                  (a) => a._id == element.commercial._id
                );
                adv.caBrutAnnuel += element.cm.tarif;
              }
            }
          }
        });
        this.userCaBrut.sort((a, b) =>
          a.caBrutAnnuel > b.caBrutAnnuel ? -1 : 1
        );
        resolve('CA Brute Load');
        this.calculateCabrutAnnuel = true;
      });
    }
  }

  calculateCaNetAndBrutAnnuel() {
    this.calculateCABrutAnnuel();
    this.calculateCAAnnuel();
    this.NewCalculRatedCancelAnnuel();
    this.initDougnhutGraphDataAnnuel();
  }

  NewCalculRatedCancelThisMonth() {
    return new Promise((resolve, reject) => {
      this.agencyArray.map((element) => {
        if (
          element.caBrut[this.actualMonth] != element.caNet[this.actualMonth]
        ) {
          element.newRateCancel[this.actualMonth] =
            ((element.caBrut[this.actualMonth] -
              element.caNet[this.actualMonth]) /
              element.caBrut[this.actualMonth]) *
            100;
        } else {
          element.color[0].borderColor.pop();
        }
        if (element.name != 'CM') {
          element.commerciaux.map((commercial) => {
            if (
              commercial.caBrut[this.actualMonth] !=
              commercial.caNet[this.actualMonth]
            ) {
              commercial.newCancelRate[this.actualMonth] =
                ((commercial.caBrut[this.actualMonth] -
                  commercial.caNet[this.actualMonth]) /
                  commercial.caBrut[this.actualMonth]) *
                100;
            }
          });
        }
      });
      if (
        this.CaBrutByMonth[this.actualMonth] !=
        this.CaNetByMonth[this.actualMonth]
      ) {
        this.newCancelcase[this.actualMonth] =
          ((this.CaBrutByMonth[this.actualMonth] -
            this.CaNetByMonth[this.actualMonth]) /
            this.CaBrutByMonth[this.actualMonth]) *
          100;
      }
      resolve('');
    });
  }

  NewCalculRatedCancelAnnuel() {
    this.agencyArray.map((element) => {
      if (element.caBrutAnnuel != element.caNetAnnuel) {
        element.newRateCancelAnuel =
          ((element.caBrutAnnuel - element.caNetAnnuel) /
            element.caBrutAnnuel) *
          100;
        if (element.name != 'CM') {
          element.commerciaux.map((commercial) => {
            if (commercial.caBrutAnnuel != commercial.caNetAnnuel) {
              commercial.newRateCancelAnuel =
                ((commercial.caBrutAnnuel - commercial.caNetAnnuel) /
                  commercial.caBrtutAnnuel) *
                100;
            }
          });
        }
      }
    });
    this.newCancelcaseAnnuel =
      ((this.caBrutAnnuel - this.caNetAnnuel) / this.caBrutAnnuel) * 100;
  }

  private calculateRateRdvThisMonth() {
    return new Promise((resolve, reject) => {
      let allCase = this.allCases.filter(
        (c) => c.r1[0].date.getMonth() == this.actualMonth
      );

      allCase.forEach((element) => {
        if (element.commercial != null) {
          let m = element.r1[0].date.getMonth();
          this.commerciaux.forEach((commercial) => {
            if (element.commercial._id == commercial._id) {
              commercial.countRdv[m]++;
              if (element.qualification == 'non qualifié') {
                commercial.countRdvQualified[m]++;
              }
            }
            if (commercial.countRdvQualified[m] == 0) {
              commercial.rateRdvQualified[m] = 100;
            } else {
              commercial.rateRdvQualified[m] =
                100 -
                Math.round(
                  (commercial.countRdvQualified[m] / commercial.countRdv[m]) *
                  100
                );
            }
          });
          this.showCommercial = this.commerciaux.filter(
            (c) => c.isActive == true
          );

          this.showCommercial.sort((a, b) =>
            a.rateRdvQualified[this.actualMonth] >
              b.rateRdvQualified[this.actualMonth]
              ? -1
              : 1
          );
        }
        resolve('calculate Rate RDV Load');
      });
    });
  }

  calculateRateRdvLastMonth() {
    return new Promise((resolve, reject) => {
      let allCase = this.allCases.filter(
        (c) => c.r1[0].date.getMonth() == this.actualMonth - 1
      );
      allCase.forEach((element) => {
        if (element.commercial != null) {
          if (element.type != 'Contrat de maintenance') {
            if (element.commercial.agency != undefined) {
              let m = element.r1[0].date.getMonth();
              let commercial = this.commerciaux.find(
                (c) => element.commercial._id == c._id
              );
              commercial.countRdv[m]++;
              if (commercial.countRdvQualified[m] == 0) {
                commercial.rateRdvQualified[m] = 100;
              } else {
                commercial.rateRdvQualified[m] =
                  100 -
                  Math.round(
                    (commercial.countRdvQualified[m] / commercial.countRdv[m]) *
                    100
                  );
              }
              this.showCommercial = this.commerciaux.filter(
                (c) => c.isActive == true
              );
              this.showCommercial.sort((a, b) =>
                a.rateRdvQualified[this.actualMonth - 1] >
                  b.rateRdvQualified[this.actualMonth - 1]
                  ? -1
                  : 1
              );
            }
          }
          resolve('calculate Rate RDV Load');
        }
      });
    });
  }

  calculateRateRdvAnnuelle() {
    if (!this.calculateRateRDV) {
      return new Promise((resolve, reject) => {
        this.allCases.forEach((element) => {
          if (element.commercial != null) {
            if (element.type != 'Contrat de maintenance') {
              if (element.commercial.agency != undefined) {
                let commercial = this.commerciaux.find(
                  (c) => c._id == element.commercial._id
                );
                commercial.countRdvAnnuel++;
                if (element.qualification == 'non qualifié') {
                  commercial.countRdvQualifiedAnnuel++;
                }
                if (commercial.countRdvQualifiedAnnuel == 0) {
                  commercial.rateRdvQualifiedAnnuel = 100;
                } else {
                  commercial.rateRdvQualifiedAnnuel =
                    100 -
                    Math.round(
                      (commercial.countRdvQualifiedAnnuel /
                        commercial.countRdvAnnuel) *
                      100
                    );
                }
                this.showCommercial = this.commerciaux.filter(
                  (c) => c.isActive == true
                );
                this.showCommercial.sort((a, b) =>
                  a.rateRdvQualifiedAnnuel > b.rateRdvQualifiedAnnuel ? -1 : 1
                );
              }
            }
          }
          this.calculateRateRDV = true;
          resolve('calculate Rate RDV Load');
        });
      });
    }
  }

  getNumberofWaitingQualification() {
    this.waitingQualification = this.allCases.filter(
      (c) => c.qualification == 'En attente'
    );
  }

  getCommercialById(id) {
    this.userCaBrut.forEach((element) => {
      if (element._id == id) {
        this.chosenCommercial = element;
      }
    });
  }

  initDougnhutGraphData() {
    this.agencyArray.forEach((agency) => {
      agency.dataGraph[0] = 100 - agency.newRateCancel[this.actualMonth];
      agency.dataGraph[1] = agency.newRateCancel[this.actualMonth];
      if (agency.caNet[this.actualMonth] == 0) {
        agency.color[0].backgroundColor[0] = '#d0d0d7';
        agency.color[0].borderColor[0] = '#d0d0d7';
      }
    });
    this.loadDougnhut = true;
  }

  initDougnhutGraphDataAnnuel() {
    if (this.doughnutAnnuel == false) {
      this.agencyArray.forEach((agency) => {
        agency.dataGraphAnnuel[0] = 100 - agency.newRateCancelAnuel;
        agency.dataGraphAnnuel[1] = agency.newRateCancelAnuel;
        if (agency.caNetAnnuel == 0) {
          agency.colorAnnuel[0].backgroundColor[0] = '#d0d0d7';
          agency.colorAnnuel[0].borderColor[0] = '#d0d0d7';
        }
      });
      this.doughnutAnnuel = true;
    }
  }

}
