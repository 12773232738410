import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-part5bis',
  templateUrl: './part5bis.component.html',
  styleUrls: ['./part5bis.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: 0 })),
      transition(':enter, :leave', [
        animate('0.5s ease-in-out')
      ])
    ])
  ]
})
export class Part5bisComponent implements OnInit {

  caseId;
  public firstStep = true;
  public buttonText = "Découvrir votre DPE";
  book;
  p_description;
  h1_dpe = "Votre Estimation";
  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.caseId = this.route.snapshot.params['id'];
    this.book = JSON.parse(localStorage.getItem('book'));
    switch (this.book.dpe_estimation) {
      case 'A':
        this.p_description = "2012"
        break;
      case 'B':
        this.p_description = "2005"
        break;
      case 'D':
        this.p_description = "2000"
        break;
      case 'G':
        this.p_description = "1974"
        break;

      default:
        break;
    }

  }


  public changeStep() {
    if (this.firstStep) {
      this.firstStep = !this.firstStep;
      this.p_description = "2000";
      this.h1_dpe = "Estimation de votre DPE";
      this.buttonText = 'Suivant';
    }
    else {
      this.router.navigate(['/book/part6', this.caseId]);
    }
  }
}
