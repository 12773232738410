
<div *ngIf="chantier">
 <div class="row">
   <div class="col-4">
    <h4 class="pl-3 pt-4">Chantier Mr/Mme  {{chantier.caseId.customer.name}}</h4>

   </div>


   <form [formGroup]="chantierForm" class="col-4 d-flex pt-4">

    <p>{{ chantier.tech[0].username }}</p>
    <mat-select *ngIf="displayTech" class="col-2 mb-4 choice-com" [(ngModel)]="chantier.tech[0]" formControlName="tech1" value="tech1" (selectionChange)="saveChangeTech(chantier, chantier._id,'edité',this.chantier.title,'changement du technicien 1')">
      <mat-option *ngFor="let tech of allTechs" [value]="tech">
        {{tech.username}}
      </mat-option>
    </mat-select>

    <mat-select *ngIf="displayTech" class="col-4 mb-4 choice-com" formControlName="resp" value="resp" (selectionChange)="saveChangeTech(chantier, chantier._id,'edité',this.chantier.title,'changement du responsable')">
      <mat-option *ngFor="let resp of responsables" [value]="resp">
        {{resp.username}}
      </mat-option>
    </mat-select>
  </form>
</div>
<div *ngIf="chantier.chef" class="row ml-1">
Chef d'équipe : {{ chantier.chef }}
</div>

<tr>
  <input placeholder="JJ/MM/AAAA xxhxx" [ngClass]="chantier.date != '' ? 'custom-date' : 'empty-date'" [(ngModel)]="chantier.date" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" (ngModelChange)="saveChange(chantier, chantier._id,'edité','date chantier',chantier.date)">
  <owl-date-time firstDayOfWeek="1" #dt2></owl-date-time>
</tr>

  <mat-select *ngIf="userRole === 'Admin' || userRole === 'Adv'"
  [ngClass]="{
    'attente':chantier.statut === 'En cours',
    'conforme':chantier.statut === 'Terminé',
    'nonconforme':chantier.statut === 'Annulé'}"
  class="custom-select-state rdv" [(ngModel)]="chantier.statut" value="chantier.statut" (selectionChange)="saveChangeState(chantier, chantier._id,'edité','statut chantier',chantier.statut)">
    <mat-option [ngClass]="qualification" *ngFor="let qualification of qualifications" [value]="qualification">
      {{qualification}}
    </mat-option>
  </mat-select>

<div class="row input-bottom">
  <div class="col-10">
    <p class="mt-4 mb-1">Description</p>
    <textarea class="col-12 " [(ngModel)]="chantier.description" (change)="saveChange(chantier, chantier._id,'edité','description chantier',chantier.description)"></textarea>
  </div>

</div>

</div>

