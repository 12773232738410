  <a class="nav-item nav-link" routerLinkActive="active">
    <img *ngIf="!showNserie" class="disactive" (click)="changeNserie()" src="../../assets/bar-icons/nserie.svg">
    <img *ngIf="showNserie" (click)="changeNserie()" class="active" src="../../assets/bar-icons/nserie-active.svg">
  </a>

<app-loading *ngIf='!cases'></app-loading>

<div *ngIf="showNserie" class="body-table">
  <h3>Suivi des numéros de séries</h3>
  <h5 class="mt-4">Équipements installés</h5>
  <p>{{ series.length }} produits</p>
  <table>
    <thead>
        <tr class="head-tr">
            <th>N° de série</th>
            <th>Désignation</th>
            <th>Marque et type</th>
            <th>Date de pose</th>
            <th>Nom du client</th>

        </tr>
    </thead>

    <tbody *ngFor="let serie of series">
        <tr *ngFor="let p of serie.prod">
            <td class="first-td" *ngIf="p.name === 'VMC Double Flux'"><p class="n_serie" *ngIf="p.n_serie_caisson">{{p.n_serie_caisson}}</p></td>
            <td class="first-td" *ngIf="p.name === 'PAC Air/Eau'"><p class="n_serie" *ngIf="p.n_serie_ue">{{p.n_serie_ue}}</p></td>
            <td class="first-td" *ngIf="p.name === 'PAC Air/Air'"><p class="n_serie" *ngIf="p.n_serie_ue">{{p.n_serie_ue}}</p></td>
            <td class="first-td" *ngIf="p.name === 'Panneaux solaires'">
                <a mat-icon-button [matMenuTriggerFor]="menu">Numéros</a>
              <mat-menu #menu="matMenu">
                  <ul *ngFor="let module of p.modules">
                    <li>{{ module.numero }}</li>
                  </ul>
              </mat-menu>
              <p *ngIf="p.n_serie_caisson">{{p.n_serie_caisson}}</p>
            </td>
            <td class="first-td" *ngIf="p.name === 'Ballon thermodynamique'"><p class="n_serie" *ngIf="p.n_serie_ue">{{p.n_serie_ue}}</p></td>
            <td class="first-td" *ngIf="p.name === 'Adoucisseur d\'eau'"><p class="n_serie" *ngIf="p.n_serie">{{p.n_serie}}</p></td>
            <td class="first-td" *ngIf="p.name === 'Batterie'"><p class="n_serie" *ngIf="p.n_serie">{{p.n_serie}}</p></td>
            <td class="first-td" *ngIf="p.name === 'Mise aux normes du tableau électrique'"><p class="n_serie" *ngIf="p.n_serie">{{p.n_serie}}</p></td>
            <td class="first-td" *ngIf="p.name === 'Thermostat radio'"><p class="n_serie" *ngIf="p.n_serie">{{p.n_serie}}</p></td>
            <td class="first-td" *ngIf="p.name === 'Isolation'"><p class="n_serie" *ngIf="p.n_serie">{{p.n_serie}}</p></td>
            <td class="first-td" *ngIf="p.state === 'autre'"></td>

            <td class="pname-td">{{ p.name }}</td>

            <td class="marque-td" *ngIf="p.name === 'VMC Double Flux'"><p *ngIf="p.marque_type">{{p.marque_type}}</p></td>
            <td class="marque-td" *ngIf="p.name === 'PAC Air/Eau'"><p *ngIf="p.marque_type">{{p.marque_type}}</p></td>
            <td class="marque-td" *ngIf="p.name === 'PAC Air/Air'"><p *ngIf="p.marque_type">{{p.marque_type}}</p></td>
            <td class="marque-td" *ngIf="p.name === 'Panneaux solaires'"><p *ngIf="p.marque_type">{{p.marque_type}}</p></td>
            <td class="marque-td" *ngIf="p.name === 'Ballon thermodynamique'"><p *ngIf="p.marque_type">{{p.marque_type}}</p></td>
            <td class="marque-td" *ngIf="p.name === 'Adoucisseur d\'eau'"><p *ngIf="p.marque_type">{{p.marque_type}}</p></td>
            <td class="marque-td" *ngIf="p.name === 'Batterie'"><p *ngIf="p.marque_type">{{p.marque_type}}</p></td>
            <td class="marque-td" *ngIf="p.name === 'Mise aux normes du tableau électrique'"><p *ngIf="p.marque_type">{{p.marque_type}}</p></td>
            <td class="marque-td" *ngIf="p.name === 'Thermostat radio'"><p *ngIf="p.marque_type">{{p.marque_type}}</p></td>
            <td class="marque-td" *ngIf="p.name === 'Isolation'"><p *ngIf="p.marque_type">{{p.marque_type}}</p></td>
            <td class="marque-td" *ngIf="p.state === 'autre'"></td>

            <td class="date-td">{{ serie.chantier | date: 'shortDate' }}</td>
            <td class="last-td">
              <div><span>{{serie.customer }}</span>
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item [routerLink]="['/logistique-stock', serie.caseId]">

                    <span>Voir l'affaire</span>
                  </button>
                  <button mat-menu-item disabled>
                    <span>Signaler un incident</span>
                  </button>
                </mat-menu>
              </div>
            </td>

        </tr>
    </tbody>
  </table>
</div>

<div class="basic-container mt-5" *ngIf="!showNserie">

	<div class="example-container mat-elevation-z0">
		<div class="example-header">
      <div>
			<mat-form-field class="recherche">
        <input matInput #filter (keyup)="applyFilter($event.target.value)" placeholder="Recherche">
        <button mat-icon-button matSuffix aria-label="clear" *ngIf="filter.value" (click)="filter.value=''; applyFilter('');">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      </div>
		</div>

		<mat-table #table [dataSource]="dataSource" class="col-12">

			<ng-container matColumnDef="name">
				<mat-cell *matCellDef="let element">

          <input *ngIf="showCheck" type="checkbox" [(value)]='element._id' (change)="$event.target.checked? (isfrmChecked = true) : isfrmChecked = false;
          componentMethodName($event,isfrmChecked);">

          <strong>Mr et/ou Mme {{element.name}}</strong> </mat-cell>
			</ng-container>

			<ng-container matColumnDef="city">
				<mat-cell *matCellDef="let element">{{element.cp}} {{element.city | uppercase}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="mobile">
				<mat-cell *matCellDef="let element"  class="col-2">
          <div *ngIf="element.mobile != null" > 0{{element.mobile}}</div>
          <div *ngIf="element.mobile == null && element.fixe != null" > 0{{element.fixe}}</div>

          </mat-cell>
      </ng-container>

      <ng-container matColumnDef="mail">
				<mat-cell *matCellDef="let element" class="col-1">
          <a *ngIf="element.mail" href="mailto:{{element.mail}}"><mat-icon>email</mat-icon></a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="id">
				<mat-cell *matCellDef="let element" class="justify-content-center">
          <button class="btn-tertiarycrm" [routerLink]="['/logistique-detail', element._id]">Accès Affaire ></button>

        </mat-cell>
      </ng-container>

			<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
		</mat-table>
	</div>

</div>



