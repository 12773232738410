import { Component, OnInit } from '@angular/core';
import { CasesService } from '@app/services/cases.service';
import { UsersService } from '@app/services/users.service';

@Component({
  selector: 'app-admin-case-affectation',
  templateUrl: './admin-case-affectation.component.html',
  styleUrls: ['./admin-case-affectation.component.scss']
})
export class AdminCaseAffectationComponent implements OnInit {

  cases;
  users;
  commercial;

  constructor(

    private sCases: CasesService,
    public sUsers: UsersService,
  ) { }

  ngOnInit(): void {

    this.sUsers.showUserRole();
    this.showTmkActiveCases()

  }

  showTmkActiveCases() {
    this.sCases.getAllTmkActiveCases().subscribe(res => {
      this.cases = res['cases'];
    });
  }

  saveChange(commercial, caseId) {

    this.sCases.editCase(commercial, caseId).then(res => {
      if(res['error']) {
        return res.error;
      }
      if(confirm("Êtes-vous sûr de vouloir affecter ce commercial ?")) {
        this.showTmkActiveCases();
      }

    });
  }

}
