import { CasesService } from './../../services/cases.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomersService } from '@app/services/customers.service';
import { AuthenticationService } from '@app/_services';
import { MatTableDataSource } from '@angular/material/table';
import { CustomerInfoComponent } from '../customer-info/customer-info.component';

@Component({
  selector: 'app-spectateur-dashboard',
  templateUrl: './spectateur-dashboard.component.html',
  styleUrls: ['./spectateur-dashboard.component.scss']
})
export class SpectateurDashboardComponent implements OnInit {

  loading = true;
  customers;
  cases
  currentUser;
  count;
  dataSource;
  displayedColumns = ['name', 'city', 'mobile', 'mail', 'id'];
  showCheck = false;
  supprCustomer = []
  isfrmChecked

  constructor(
    private sCustomers: CustomersService,
    private sCases: CasesService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
  )  {

    this.authenticationService.user.subscribe(x => this.currentUser = x);
   }

  ngOnInit() {
    this.showCustomers();
    //this.showCases()
  }
  showInfos(id): void {
    const dialogRef = this.dialog.open(CustomerInfoComponent, {
      width: '40vw',
      panelClass: 'custom-dialog-container',
      data: {
        id: id,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });

  }

   showCustomers() {
    this.sCustomers.getCustomersByState().subscribe(response => {
      this.customers = response['customers'];
      this.count = response['count'];
      this.loading = false;
      this.dataSource = new MatTableDataSource(this.customers);
      this.loading = false;

     });
  }

/*    showCases() {
    this.sCases.getAllCases().subscribe(response => {
      this.cases = response['cases'];
      console.log("this.cases", this.cases)
      this.dataSource = new MatTableDataSource(this.cases);
      this.loading = false;

     });
  } */
  applyFilter(filterValue: string) {
    this.showCheck = false;
    this.supprCustomer = []
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  componentMethodName(event: any, isChecked: boolean)
  {
    if (isChecked) {
      this.supprCustomer.push(event.target.value)
    }
    else {
      let index = this.supprCustomer.indexOf(event.target.value);
      this.supprCustomer.splice(index, 1);
    }
  }

}
