import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { FinancementsService } from '@app/services/financements.service';
import { HistoriqueService } from '@app/services/historique.service';
import { RolesService } from '@app/services/roles.service';

@Component({
  selector: 'app-case-financement',
  templateUrl: './case-financement-list.component.html',
  styleUrls: ['./case-financement-list.component.scss']
})

export class CaseFinancementListComponent implements OnInit {

  case;
  caseId;
  financements;
  financementsOk = false;
  userRole;
  showCross;

  constructor(
    private route: ActivatedRoute,
    private sFinancements: FinancementsService,
    private sRoles: RolesService,
    private router: Router,
    private sCases: CasesService,
    private sHistoriques: HistoriqueService,
  ) {
    this.caseId = this.route.snapshot.params['id'];
    this.userRole = this.sRoles.user.role;
    this.showFinancements();

    }
  ngOnInit(): void {
    this.showCaseInfos();
  }

  showFinancements() {
    this.sFinancements.getFinancementsByCaseId(this.caseId).subscribe(res => {
      this.financements = res;
      this.financementsOk = true;
    });
  }
  deleteFinancement(id,reason, change,value) {
    if (confirm("Confirmer la suppression?")) {
      this.sCases.supprItemArray(this.financements, id)
      this.sFinancements.delete(id).subscribe(response => {
        this.showFinancements();
      });
      this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change,value,this.sRoles.user.id).then(res => {
        if (res['error']) {
          alert(res);
        }
      });

    }
  }

  showCaseInfos() {
    return new Promise(resolve => {

    this.sCases.getCaseById(this.caseId).subscribe(res => {
      this.case = res[0];
      resolve(res);
        }, error => {
          console.log(error);
      });
    });
  }
  goToEditFinancement(financementId) {
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['case-template', this.caseId], { state: { value: 'financementEdit', financementId: financementId }  });
  }

  saveChange(reason, change, value) {
    this.sCases.editCase(this.case, this.caseId).then(res => {
      if(res['error']) {
        return res.error;
      }
      this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change,value,this.sRoles.user.id).then(res => {
        if (res['error']) {
          alert(res);
        }
      });
    });
  }

  showEdit() {
    this.showCross = !this.showCross;
  }
}
