import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrl } from '@environments/env';

@Injectable({
  providedIn: 'root',
})
export class PiecesService {
  local_url = apiUrl.apiUrl + '/piece/';
  //local_url = 'http://localhost:4000/piece/';
  headers = { 'Content-Type': 'application/json' };
  headersGet = { 'Content-Type': 'application/pdf' };

  constructor(private http: HttpClient) {}

  getAllPieces() {
    return this.http.get(this.local_url + 'pieces', {
      headers: this.headersGet,
    });
  }

  addTechPiece(form, multipleImagesPieces, caseId, chantierId) {
    const formData = new FormData();
    formData.append('title', form.title);
    formData.append('description', form.description);
    formData.append('qualification', form.qualification);
    formData.append('caseId', caseId);
    formData.append('created_at', form.created_at);
    formData.append('type', 'technicien');
    formData.append('cible', form.cible);
    formData.append('chantier', chantierId);
    for (let img of multipleImagesPieces) {
      formData.append('filesPieces', img);
    }
    this.http.post<any>(this.local_url + 'multiplePieces', formData).subscribe(
      (res) => {
        console.log(res);
      },
      (err) => console.log(err)
    );
  }

  getPiecesByChantierIdAndCible(chantierId, cible) {
    return this.http.get(this.local_url + 'pv/' + chantierId + '/' + cible, {
      headers: this.headersGet,
    });
  }

  getPiecesByCaseId(id) {
    return this.http.get(this.local_url + id, { headers: this.headersGet });
  }
  getPiecesByCaseIdForCee(id) {
    return this.http.get(this.local_url + 'cee/' + id, {
      headers: this.headersGet,
    });
  }
  getPiecesByCaseIdForMpr(id) {
    return this.http.get(this.local_url + 'mpr/' + id, {
      headers: this.headersGet,
    });
  }
  getPiecesManquantesByCaseId(id) {
    return this.http.get(this.local_url + 'manquantes/' + id, {
      headers: this.headersGet,
    });
  }
  getPiecesByChantierId(id) {
    return this.http.get(this.local_url + 'chantier/' + id, {
      headers: this.headersGet,
    });
  }

  getTechPiecesByCaseId(id) {
    return this.http.get(this.local_url + 'tech/' + id);
  }

  getBillByCaseId(id) {
    return this.http.get(this.local_url + 'facture/' + id, {
      headers: this.headersGet,
    });
  }
  getBillPrimeByCaseId(type, created, product, id) {
    return this.http.get(
      this.local_url +
        'facture/' +
        type +
        '/' +
        created +
        '/' +
        product +
        '/' +
        id,
      { headers: this.headersGet }
    );
  }
  getCmByCaseId(id) {
    return this.http.get(this.local_url + 'cm/' + id, {
      headers: this.headersGet,
    });
  }
  getMandatByCaseId(id) {
    return this.http.get(this.local_url + 'mandat/' + id, {
      headers: this.headersGet,
    });
  }

  getLastSpecificPieceByTitle(caseId, titlePiece) {
    return this.http.get(this.local_url + caseId + '/' + titlePiece, {
      headers: this.headersGet,
    });
  }

  getPieceById(id) {
    return this.http.get(this.local_url + 'piece/' + id, {
      headers: this.headersGet,
    });
  }

  delete(id) {
    return this.http.delete(this.local_url + id);
  }

  editPiece(piece, pieceId): Promise<any> {
    return this.http
      .put(this.local_url + pieceId, JSON.stringify(piece), {
        headers: this.headers,
      })
      .toPromise()
      .then((res) => res as any);
  }
}
