import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delete-bar',
  templateUrl: './delete-bar.component.html',
  styleUrls: ['./delete-bar.component.scss']
})
export class DeleteBarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
