import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { HistoriqueService } from '@app/services/historique.service';
import { RolesService } from '@app/services/roles.service';

@Component({
  selector: 'app-case-financement-comptant',
  templateUrl: './case-financement-comptant.component.html',
  styleUrls: ['./case-financement-comptant.component.scss']
})
export class CaseFinancementComptantComponent implements OnInit {

  form: FormGroup;
  financementForm: FormGroup;
  caseId;
  case;
  financement_comptant = {};
  date_fin;
  constructor(
    private route: ActivatedRoute,
    private sCases: CasesService,
    private fb: FormBuilder,
    private sHistoriques: HistoriqueService,
    private sRoles: RolesService,
    )
    {
      this.caseId = this.route.snapshot.params['id'];
      this.showCaseInfos().then(res => {
        this.createForm();
      })

    }
   createForm() {
      this.financementForm = this.fb.group({
        cheques: '',
        choice: '',
        cheque1: this.fb.group({
          date: this.case.financement_comptant.cheque1.date,
          encaissement: this.case.financement_comptant.cheque1.encaissement,
          montant: this.case.financement_comptant.cheque1.montant,
          date_validation: this.case.financement_comptant.cheque1.date_validation,
          validation: this.case.financement_comptant.cheque1.validation,
        }),
        cheque2: this.fb.group({
          date: this.case.financement_comptant.cheque2.date,
          encaissement: this.case.financement_comptant.cheque2.encaissement,
          montant: this.case.financement_comptant.cheque2.montant,
          date_validation: this.case.financement_comptant.cheque2.date_validation,
          validation: this.case.financement_comptant.cheque2.validation,
        }),
        cheque3: this.fb.group({
          date: this.case.financement_comptant.cheque3.date,
          encaissement: this.case.financement_comptant.cheque3.encaissement,
          montant: this.case.financement_comptant.cheque3.montant,
          date_validation: this.case.financement_comptant.cheque3.date_validation,
          validation: this.case.financement_comptant.cheque3.validation,
        })
      });

    }
    ngOnInit() { }

  showCaseInfos() {
    return new Promise(resolve => {

    this.sCases.getCaseById(this.caseId).subscribe(res => {
      this.case = res[0];
      resolve(res);
        }, error => {
          console.log(error);
      });
    });
  }

  saveChange(reason, change,value) {
    this.financement_comptant = { financement_comptant : this.financementForm.value };
    this.sCases.editCase(this.financement_comptant, this.caseId).then(res => {
      if(res['error']) {
        return res.error;
      }
      this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change,value,this.sRoles.user.id).then(res => {
        if (res['error']) {
          alert(res);
        }
      });
      this.showCaseInfos();
    });
  }

}
