import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';

@Component({
  selector: 'app-table-gdd',
  templateUrl: './table-gdd.component.html',
  styleUrls: ['./table-gdd.component.scss']
})
export class TableGddComponent implements OnInit {

  // grid
  gridApi
  gridColumnApi
  rowData
  rowHeight
  rowStyle
  columnDefs
  defaultColDef
  loadingTemplate

  cases
  constructor(
    private sCases: CasesService,
    private router: Router,
  ) {

    this.loadingTemplate =
      `<span class="ag-overlay-loading-center">Chargement...</span>`;
    this.rowHeight = 65;
    this.rowStyle = { background: 'white' };
    this.columnDefs = [
      {
        headerName: 'ID',
        field: 'case._id',
        cellClass: 'cell-align',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Date de signature',
        field: 'case.datesign',
        cellClass: 'cell-align',
        filter: 'agDateColumnFilter',
        cellRenderer: (data) => {
          return data.value ? (new Date(data.value)).toLocaleDateString() : '';
        },
      },
      {
        headerName: 'Client',
        field: 'case.customer.name',
        cellClass: 'cell-align',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Adv',
        field: 'case.adv.username',
        cellClass: 'cell-align',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Début travaux',
        field: 'chantier',
        cellClass: 'cell-align',
        cellRenderer: (data) => {
          return data.value ? (new Date(data.value.date)).toLocaleDateString() + '<br>' + data.value.statut : '';
        },
      },
      {
        headerName: 'Financement',
        field: 'financement',
        cellClass: 'cell-align',
        cellRenderer: (data) => {
          return data.value != null ? (new Date(data.value)).toLocaleDateString() : '-';
        },
      },
      {
        headerName: 'Gdd',
        field: 'gdd',
        cellClass: 'cell-align',
        cellRenderer: (data) => {
          if (data.value === 0) {
            return 0
          }
          else {
            return data.value ? Math.round(data.value * 100) / 100 + ' %' : '-';
          }
        },
      },
    ];
    this.defaultColDef = {
      flex: 1,
      minWidth: 0,
      sortable: true,
      floatingFilter: true,
      resizable: true,
      pagination: true,

    };
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  ngOnInit(): void {
    this.getGddCases()

  }

  getGddCases() {
    return new Promise(resolve => {
      this.sCases.getCasesForGdd().subscribe(res => {
        this.cases = res;
        this.cases.sort((d1, d2) => new Date(d2.case.datesign).getTime() - new Date(d1.case.datesign).getTime());
        resolve(res);
      }, error => {
        console.log(error);
      });

    })
  }

  onBtnExport() {
    this.gridApi.exportDataAsCsv(this.getParams());
    //this.gridApi2.exportDataAsCsv(this.getParams());
  }
  getParams() {
    return {
      columnGroups: true,
      fileName: ('Adv_gdd'),
      processCellCallback: this.processCells,
    };
  }
  onSelectionChanged() {
    var selectedRows = this.gridApi.getSelectedRows();
    //this.router.navigate(['/case-template/', selectedRows[0].id]);
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/case-template/', selectedRows[0].case._id])
    );
    window.open(url, '_blank');
  }

  processCells(params: any) {
    if (params.value instanceof Object) {
      return new Date(params.value.date).toLocaleDateString('fr')
    }
    if (params.column.colId === 'gdd') {
      return Math.round(params.value)
    }
    if (params.column.colId === 'case.datesign') {
      return new Date(params.value).toLocaleDateString('fr')
    }
    if (params.column.colId === 'financement') {
      return params.value != '-' && params.value != null ? new Date(params.value).toLocaleDateString('fr') : ''
    }
    else {
      return params.value
    }

  }
}
