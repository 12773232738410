
<app-commentbar *ngIf="!mobile"  [caseId]="caseId"></app-commentbar>
 <div *ngIf="!mobile" class="icons">
  <a class="nav-item nav-link " style="font-size:0.8rem;" (click)="toggleShowPiece()">
  <img *ngIf="!showPiece"  class="actif" src="../../../assets/bar-icons/pieces-active.svg">
  <img *ngIf="showPiece" src="../../../assets/bar-icons/pieces.svg">
</a>
</div>
<div *ngIf="case">
<div class="info-customer">
  <h3 class="title"> {{case.title}}</h3>
  <div> {{case.customer.name}}</div>
  <div>0{{case.customer.mobile}}  0{{case.customer.fixe}}</div>
  <div> {{case.customer.address}} {{case.customer.city}} {{case.customer.cp}} </div>
    <a style="text-decoration-line: underline;" class="col-9 pl-1"  data-toggle="modal" data-target="#exampleModal">Voir les infos client</a>
 </div>

   <!-- POP UP -->
   <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="close" data-dismiss="modal" >X</button>
          <ul>
            <h3>Mr et Mme {{case.customer.name}}</h3>
            <section>
            <h4>Adresse</h4>
            <li>{{ case.customer.address }}</li>
            <li>{{case.customer.cp}} {{ case.customer.city }}</li>
            </section>
            <section>
            <h4>Numéros</h4>
            <li>{{ case.customer.mobile }}</li>
            <li>{{ case.customer.fixe }}</li>
            <li> <a href="mailto:{{case.customer.mail}}">{{case.customer.mail}}</a></li>
            </section>
          </ul>
        </div>
      </div>
    </div>
    </div>

    <div *ngIf="showPiece;  then detail else piece" ></div>


<ng-template #detail>
  <div class="container-compta" >
    <div class="col-xs-12 col-lg-6 col-sm-12 mt-5" >
<h3>Facture faite</h3>
<div class="card toggleBillDone card-facture " [ngClass]="bill ? 'oui' : 'oui-empty'"> OUI </div>
  <div class="card toggleBillDone card-facture "  [ngClass]="!bill ? 'non' : 'non-empty'"> NON </div>

  <!--DISPLAY NONE DATE -->
  <div style="opacity:0;">
    <input [ngClass]="bill ? '' : 'disabled'" class="custom-date" placeholder="JJ/MM/AAAA xxhxx" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" [ngModel]="case.billsDate" (ngModelChange)="saveChange('bills')"  >
    <owl-date-time [ngClass]="bill ? '' : 'disabled'" firstDayOfWeek="1" #dt2></owl-date-time>
  </div>

<div class="col-xs-12 col-lg-6 col-sm-3 btn-secondarycrm button-wrapper upload-bill" *ngIf="!bill">
  <input accept=".jpg,.jpeg,.png,.pdf" multiple type="file" name="upload" id="upload" class="upload-box" (change)="selectMultiple($event,'facture')">
  + Ajouter la facture
</div>
<div style="display: flex; flex-direction: column;" >

  <div class="col-xs-12 col-lg-6 col-sm-3" *ngIf="bill">
      <div class="facture-card">
        <img class="rm-facture" src="../../../assets/icons/delete.svg" (click)="killBill(bill._id,'supprimé','pièce',bill.title )">
        <h3>Facture {{ case.customer.name }}</h3>
        <p>Ajouté par Comptabilité</p>

      </div>
  </div>

<a href="https://api-crm.s3.eu-west-3.amazonaws.com/{{bill.caseId}}/pieces/{{billImg}}" class="col-xs-12 col-lg-6 col-sm-3 btn-secondarycrm button-wrapper upload-bill"  *ngIf="bill"  > Télécharger la facture</a>
</div>

    <h3 class="mt-5" >Bon de commande</h3>
    <div *ngIf="!bdc" >Il n'y a pas de bon de commande</div>
<div *ngIf="bdc">
  <div class="row" >
 <mat-card class="col-xs-12 col-lg-7 col-sm-12">
      <a href="https://api-crm.s3.eu-west-3.amazonaws.com/{{bdc.caseId}}/pieces/{{bdcImg}}">
      <img style="max-width: 250px;max-height: 190px;" *ngIf="!bdcImg.endsWith('pdf')" src="https://api-crm.s3.eu-west-3.amazonaws.com/{{bdc.caseId}}/pieces/{{bdcImg}}">
        <img  *ngIf="bdcImg.endsWith('pdf')" src="../../../assets/pieces/bdc.png">
      </a>
    </mat-card>
    </div>
  </div>
</div>

    <div  class="col-xs-12 col-lg-6 col-sm-12 mt-5"  *ngIf="case.financement_type != 'Comptant'">
      <h3>Suivi des encaissements</h3>

      <div *ngIf="case.comptant">
        <h3>Acompte comptant</h3>
        <div>Date de l'encaissement</div>
        <input class="custom-date" placeholder="JJ/MM/AAAA xxhxx" [owlDateTime]="dt6" [owlDateTimeTrigger]="dt6" [(ngModel)]="case.comptant_date" (ngModelChange)="saveChange('édité','la date d\'encaissement de l\'acompte', case.comptant_date)">
        <owl-date-time firstDayOfWeek="1" #dt6></owl-date-time>
        <mat-form-field class="example-full-width">
          <input  type="number" matInput [(ngModel)]="case.comptant_montant" (change)="saveChange('édité','le montant de l\'acompte', case.comptant_montant)">
        </mat-form-field>
      </div>

    <h3>Demande de déblocage</h3>
      <div style="display: flex;">
        <div>
          <select class="card-facture custom-select-rdv rdv select-financement " [ngClass]="!financement.deblocage ? 'non-empty' : '' "  [(ngModel)]="financement.deblocage"  value="financement.deblocage" (change)="saveChangeFinancement('edité déblocage',financement.partenaire,financement.deblocage === true ? 'oui' : 'non')">
            <option [ngValue]=true>Oui</option>
            <option [ngValue]=false>Non</option>
          </select>
        </div>
        <div class="ml-4" *ngIf="financement.deblocage">
      <div class="title-cheque" > Date de la demande</div>
      <input placeholder="JJ/MM/AAAA xxhxx" [ngClass]=" financement.date_deblocage  != '' ? 'custom-date' : 'empty-date'" [(ngModel)]="financement.date_deblocage" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1"  (ngModelChange)="saveChangeFinancement('edité la date de la demande de déblocage', financement.partenaire ,financement.date_deblocage)">
      <owl-date-time firstDayOfWeek="1" (ngModelChange)="saveChangeFinancement('edité', financement.partenaire ,financement.date_deblocage )" #dt1></owl-date-time>
      </div>
    </div>

    <div class="mt-5" style="display: flex; flex-wrap: wrap; ">
      <div>
      <h3>Acompte</h3>
          <select class="card-facture custom-select-rdv rdv select-financement " [ngClass]="!financement.acompte  ? 'non-empty' : '' "  [(ngModel)]="financement.acompte"  value="financement.acompte " (change)="saveChangeFinancement('edité', financement.partenaire ,financement.acompte === true ? 'oui' : 'non')">
            <option [ngValue]=true>Oui</option>
            <option [ngValue]=false>Non</option>
          </select>
        </div>
      <div *ngIf="financement.acompte"  class="ml-4" style="display: flex; flex-direction: column;" >
      <div class="title-cheque" >Date de l'acompte</div>
      <input placeholder="JJ/MM/AAAA xxhxx" [ngClass]=" financement.date_acompte  != '' ? 'custom-date' : 'empty-date'" [(ngModel)]="financement.date_acompte" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2"  (ngModelChange)="saveChangeFinancement('edité la date de l\'acompte', financement.partenaire ,financement.date_acompte)">
      <owl-date-time firstDayOfWeek="1" (ngModelChange)="saveChangeFinancement('edité', 'Acompte' ,financement.date_acompte)" #dt2></owl-date-time>
      <mat-form-field class="example-full-width">
        <input placeholder='Montant'  type="number" matInput [(ngModel)]="financement.montant_acompte" (change)="saveChangeFinancement('edité', 'Acompte' ,financement.montant_acompte === null ? 'vide' : financement.montant_acompte)">
      </mat-form-field>
    </div>
  </div>

  <div class="mt-5" style="display: flex; flex-wrap: wrap; ">
    <div>
      <h3>Solde</h3>
        <select class="card-facture custom-select-rdv rdv select-financement" [ngClass]="!financement.solde ? 'non-empty' : '' "  [(ngModel)]="financement.solde"  value="financement.solde " (change)="saveChangeFinancement('edité le solde', financement.partenaire ,financement.solde === true ? 'oui' : 'non')">
          <option [ngValue]=true>Oui</option>
          <option [ngValue]=false>Non</option>
        </select>
      </div>
        <div class="ml-4" style="display: flex; flex-direction: column; " *ngIf="financement.solde">
      <input placeholder="JJ/MM/AAAA xxhxx" [ngClass]=" financement.date_solde  != '' ? 'custom-date' : 'empty-date'" [(ngModel)]="financement.date_solde" [owlDateTime]="dt4" [owlDateTimeTrigger]="dt4"  (ngModelChange)="saveChangeFinancement('edité la date du solde', financement.partenaire ,financement.date_solde)">
      <owl-date-time firstDayOfWeek="1" #dt4></owl-date-time>
        <mat-form-field class="example-full-width">
          <input  placeholder='Montant'   type="number" matInput [(ngModel)]="financement.montant_solde" (change)="saveChangeFinancement('edité', financement.partenaire ,financement.montant_solde != null ? financement.montant_solde : 'vide')">
        </mat-form-field>
      </div>

  </div>
  <div class="mt-5"   style="display: flex; justify-content: space-between; " >
    <h3>Avoir</h3>

    <a style="color:var(--tertiarycrm); text-decoration: underline ;"  (click)="toggleShowAvoir()" >Supprimer des avoirs</a>
  </div>
  <div style="display: flex; flex-wrap: wrap;" >
    <div *ngFor="let avoir of avoirs" >
      <div (click)="chosenAvoir(avoir)"  class="avoir btn-secondarycrm" [ngClass]="avoir.title == avoirChoisi.title ? 'btn-tertiarycrm' : ''" >
        <img style="cursor: pointer;"  *ngIf="showSupprAvoir" (click)="supprAvoir(avoir._id)"  src='../../../assets/icons/suppr.svg'>
       {{avoir.title}}
      </div>
    </div>

    <button class="avoir btn-secondarycrm" (click)="addAvoir()" > + </button>
  </div>

  <div  style="display: flex; justify-content: space-between; "  *ngIf="avoirChoisi.title" >
      <mat-form-field (change)="editAvoir(avoirChoisi , avoirChoisi._id,('Le montant d\'' +avoirChoisi.title),(avoirChoisi.montant+'€'))">
        <mat-label>Montant</mat-label>
        <input type="number" [(ngModel)]="avoirChoisi.montant"  matInput>
      </mat-form-field>

      <input  placeholder="JJ/MM/AAAA xxhxx" [ngClass]="avoirChoisi.date != '' ? 'custom-date' : 'empty-date'"  [(ngModel)]="avoirChoisi.date"
      [owlDateTime]="dt3" [owlDateTimeTrigger]="dt3"  (ngModelChange)="editAvoir(avoirChoisi , avoirChoisi._id, ('La date d\' '+avoirChoisi.title), avoirChoisi.date)">
        <owl-date-time firstDayOfWeek="1" #dt3></owl-date-time>

      </div>

    </div>


  <div class="col-xs-12 col-lg-6 col-sm-12 mt-5" *ngIf="case.financement_type === 'Comptant' && case.financement_comptant.choice != 'Virement'">
    <form *ngIf="financementForm" [formGroup]="financementForm" class="custom-radio">
    <div class="choices mr-5">
      <h3 class="mt-5">Les 3 chèques sont-ils présents?</h3>
      <input   type="radio" id="cheques1" name="cheques" formControlName="cheques" [(ngModel)]="case.financement_comptant.cheques" (change)="saveChange('edité','cheque 1','oui')" value=true>
      <label class="card-facture"  for="cheques1" [ngClass]="this.financementForm.value.cheques === 'true' ? 'oui' : 'oui-empty'">
        OUI</label>

        <input type="radio" id="cheques2" name="cheques" formControlName="cheques" value=false  (change)="saveChange('edité','cheque 1','non')" >
        <label class="card-facture" for="cheques2" [ngClass]="this.financementForm.value.cheques === 'false' ? 'non' : 'non-empty'">
        NON</label>
    </div>

    <form *ngIf="this.financementForm" formGroupName="cheque1" class="row mt-4">

      <div  class="m-3">
      <div class="title-cheque" >Encaissement 1er chèque</div>
      <input *ngIf="financementForm.value.cheque1.validation"  placeholder="JJ/MM/AAAA xxhxx" [ngClass]="financementForm.value.cheque1.date_validation != '' ? 'custom-date' : 'empty-date'" [(ngModel)]="financementForm.value.cheque1.date_validation" formControlName="date_validation" [owlDateTime]="dt5" [owlDateTimeTrigger]="dt5"
       (ngModelChange)="checkDateChange('cheque1', case.financement_comptant.cheque1.date_validation, financementForm.value.cheque1.date_validation )">
      <owl-date-time firstDayOfWeek="1" #dt5></owl-date-time>
      <p *ngIf="case.financement_comptant.cheque1.date">Cheque déposé le {{ case.financement_comptant.cheque1.date | date:'short' }}</p>
      <p *ngIf="!case.financement_comptant.cheque1.date"></p>
      </div>
      <div>
        <mat-form-field class="example-full-width">
          <input type="number" formControlName="montant" matInput [(ngModel)]="financementForm.value.cheque1.montant" formControlName="montant" (change)="saveChange('edité le montant','cheque 1',financementForm.value.cheque1.montant)">
        </mat-form-field>
      </div>
      <div>
        <select class=" card-facture custom-select-rdv rdv" formControlName="validation" [ngClass]="!financementForm.value.cheque1.validation ? 'non-empty' : ''" value="financementForm.value.cheque1.validation" (change)="saveChange('edité','validation cheque 1',financementForm.value.cheque1.validation === true ? 'oui' : 'non')">
          <option [ngValue]=true>Oui</option>
          <option [ngValue]=false>Non</option>
        </select>
      </div>
    </form>

    <form *ngIf="this.financementForm" formGroupName="cheque2" class="row mt-4">
      <div  class="m-3" >

      <div class="title-cheque" >Encaissement 2nd chèque</div>

      <input *ngIf="financementForm.value.cheque2.validation"  placeholder="JJ/MM/AAAA xxhxx" [ngClass]="financementForm.value.cheque2.date_validation != '' ? 'custom-date' : 'empty-date'" [(ngModel)]="financementForm.value.cheque2.date_validation" formControlName="date_validation" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2"
      (ngModelChange)="checkDateChange('cheque2', this.case.financement_comptant.cheque2.date_validation, this.financementForm.value.cheque2.date_validation )">
      <owl-date-time firstDayOfWeek="1" #dt2></owl-date-time>
      <p *ngIf="case.financement_comptant.cheque2.date">Cheque déposé le {{ case.financement_comptant.cheque2.date | date:'short' }}</p>
      <p *ngIf="!case.financement_comptant.cheque2.date"></p>
      </div>

      <div>
        <mat-form-field class="example-full-width">
          <input type="number" formControlName="montant" matInput [(ngModel)]="financementForm.value.cheque2.montant" formControlName="montant" (change)="saveChange('edité le montant','cheque 2',financementForm.value.cheque2.montant)">
        </mat-form-field>
      </div>
      <div>
        <select class="card-facture custom-select-rdv rdv " formControlName="validation" [ngClass]="!financementForm.value.cheque2.validation ?  'non-empty' : ''" value="financementForm.value.cheque2.validation" (change)="saveChange('edité','encaissement cheque 2',financementForm.value.cheque2.validation === true ? 'oui' : 'non')">
          <option [ngValue]=true>Oui</option>
          <option [ngValue]=false>Non</option>
        </select>
    </div>

    </form>

    <form *ngIf="this.financementForm" formGroupName="cheque3" class="row mt-4">
      <div  class="m-3">
      <div class="title-cheque">Encaissement 3ème chèque</div>

      <input *ngIf="financementForm.value.cheque3.validation"  placeholder="JJ/MM/AAAA xxhxx" [ngClass]="financementForm.value.cheque3.date_validation != '' ? 'custom-date' : 'empty-date'" [(ngModel)]="financementForm.value.cheque3.date_validation" formControlName="date_validation" [owlDateTime]="dt3" [owlDateTimeTrigger]="dt3"
      (ngModelChange)="checkDateChange('cheque3', this.case.financement_comptant.cheque3.date_validation, this.financementForm.value.cheque3.date_validation )">
      <owl-date-time firstDayOfWeek="1" #dt3></owl-date-time>
      <p *ngIf="case.financement_comptant.cheque3.date">Cheque déposé le {{ case.financement_comptant.cheque3.date | date:'short' }}</p>
      <p *ngIf="!case.financement_comptant.cheque3.date"></p>
      </div>
      <div>
        <mat-form-field class="example-full-width">
          <input type="number" formControlName="montant" matInput [(ngModel)]="financementForm.value.cheque3.montant" formControlName="montant" (change)="saveChange('edité le montant','cheque 3',financementForm.value.cheque3.montant)">
        </mat-form-field>
      </div>

      <div>
        <select class="card-facture custom-select-rdv rdv" formControlName="validation" [ngClass]="!financementForm.value.cheque3.validation ? 'non-empty' : ''" value="case.financement_comptant.cheque3.validation" (change)="saveChange('edité','encaissement cheque 3',financementForm.value.cheque3.validation === true ? 'oui' : 'non')">
          <option [ngValue]=true>Oui</option>
          <option [ngValue]=false>Non</option>
        </select>
      </div>
    </form>
</form>
<div class="mt-5"   style="display: flex; justify-content: space-between; " >
  <h3>Avoir</h3>
  <a style="color:var(--tertiarycrm); text-decoration: underline ;"  (click)="toggleShowAvoir()" >Supprimer des avoirs</a>
</div>
<div style="display: flex; flex-wrap: wrap;" >
  <div *ngFor="let avoir of avoirs" >
    <div (click)="chosenAvoir(avoir)"  class="avoir btn-secondarycrm" [ngClass]="avoir.title == avoirChoisi.title ? 'btn-tertiarycrm' : ''" >
      <img style="cursor: pointer;"  *ngIf="showSupprAvoir" (click)="supprAvoir(avoir._id, avoir.title )"  src='../../../assets/icons/suppr.svg'>
     {{avoir.title}}
    </div>
  </div>

  <button class="avoir btn-secondarycrm" (click)="addAvoir()" > + </button>
</div>

<div  style="display: flex; justify-content: space-between; "  *ngIf="avoirChoisi.title" >
    <mat-form-field (change)="editAvoir(avoirChoisi , avoirChoisi._id,('Le montant d\'' +avoirChoisi.title),(avoirChoisi.montant+'€'))">
      <mat-label>Montant</mat-label>
      <input type="number" [(ngModel)]="avoirChoisi.montant"  matInput>
    </mat-form-field>

    <input  placeholder="JJ/MM/AAAA xxhxx" [ngClass]="avoirChoisi.date != '' ? 'custom-date' : 'empty-date'"  [(ngModel)]="avoirChoisi.date"
    [owlDateTime]="dt3" [owlDateTimeTrigger]="dt3"  (ngModelChange)="editAvoir(avoirChoisi , avoirChoisi._id, ('La date d\' '+avoirChoisi.title), avoirChoisi.date)">
      <owl-date-time firstDayOfWeek="1" #dt3></owl-date-time>

    </div>



</div>

<div class="col-xs-12 col-lg-6 col-sm-12 mt-5"  *ngIf="case.financement_type === 'Comptant' && case.financement_comptant.choice === 'Virement'">
  <form *ngIf="financementForm" [formGroup]="financementForm" class="custom-radio">
    <h3>Suivi des encaissements</h3>

  <form *ngIf="this.financementForm" formGroupName="cheque1" class="row mt-4">

    <div  class="m-3">
    <div class="title-cheque" >Encaissement du 1er virement</div>
    <input *ngIf="financementForm.value.cheque1.encaissement"  placeholder="JJ/MM/AAAA xxhxx" [ngClass]="financementForm.value.cheque1.date != '' ? 'custom-date' : 'empty-date'" [(ngModel)]="financementForm.value.cheque1.date" formControlName="date" [owlDateTime]="dt6" [owlDateTimeTrigger]="dt6"  (ngModelChange)="saveChange('edité la date d\'encaissement','virement 1',financementForm.value.cheque1.montant)">
    <owl-date-time firstDayOfWeek="1" #dt6></owl-date-time>
    </div>
    <div>
      <mat-form-field class="example-full-width">
        <input type="number" formControlName="montant" matInput [(ngModel)]="case.financement_comptant.cheque1.montant" formControlName="montant" (change)="saveChange('edité le montant','cheque 1',financementForm.value.cheque1.montant)">
      </mat-form-field>
    </div>
    <div>

      <select class=" card-facture custom-select-rdv rdv " formControlName="encaissement" [ngClass]="!financementForm.value.cheque1.encaissement ? 'non-empty' : ''" value="financementForm.value.cheque1.encaissement" (change)="saveChange('cheques')">
        <option [ngValue]=true>Oui</option>
        <option [ngValue]=false>Non</option>
      </select>
    </div>
  </form>

  <form *ngIf="this.financementForm" formGroupName="cheque2" class="row mt-4">
    <div  class="m-3" >

    <div class="title-cheque" >Encaissement du 2nd virement</div>

    <input *ngIf="financementForm.value.cheque2.encaissement"  placeholder="JJ/MM/AAAA xxhxx" [ngClass]="financementForm.value.cheque2.date != '' ? 'custom-date' : 'empty-date'" [(ngModel)]="financementForm.value.cheque2.date" formControlName="date" [owlDateTime]="dt7" [owlDateTimeTrigger]="dt7"  (ngModelChange)="saveChange('edité la date d\'encaissement','virement 2',financementForm.value.cheque2.montant)">
    <owl-date-time firstDayOfWeek="1" #dt7></owl-date-time>
    </div>

    <div>
      <mat-form-field class="example-full-width">
        <input type="number" formControlName="montant" matInput [(ngModel)]="financementForm.value.cheque2.montant" formControlName="montant" (change)="saveChange('edité le montant','cheque 2',financementForm.value.cheque2.montant)">
      </mat-form-field>
    </div>
    <div>

      <select class="card-facture custom-select-rdv rdv " formControlName="encaissement" [ngClass]="!financementForm.value.cheque2.encaissement ? 'non-empty' : ''" value="financementForm.value.cheque2.encaissement" (change)="saveChange('cheques')">
        <option [ngValue]=true>Oui</option>
        <option [ngValue]=false>Non</option>
      </select>
  </div>

  </form>

  <form *ngIf="this.financementForm" formGroupName="cheque3" class="row mt-4">
    <div  class="m-3" >
    <div class="title-cheque" >Encaissement du 3ème virement</div>

    <input *ngIf="financementForm.value.cheque3.encaissement"  placeholder="JJ/MM/AAAA xxhxx" [ngClass]="financementForm.value.cheque3.date != '' ? 'custom-date' : 'empty-date'" [(ngModel)]="financementForm.value.cheque3.date" formControlName="date" [owlDateTime]="dt8" [owlDateTimeTrigger]="dt8"  (ngModelChange)="saveChange('edité la date d\'encaissement','virement 3',financementForm.value.cheque3.montant)">
    <owl-date-time firstDayOfWeek="1" #dt8></owl-date-time>
    </div>
    <div>
      <mat-form-field class="example-full-width">
        <input type="number" formControlName="montant" matInput [(ngModel)]="financementForm.value.cheque3.montant" formControlName="montant" (change)="saveChange('edité le montant','cheque 3',financementForm.value.cheque3.montant)">
      </mat-form-field>
    </div>

    <div>
      <select class="card-facture custom-select-rdv rdv" formControlName="encaissement" [ngClass]="!financementForm.value.cheque3.encaissement ? 'non-empty' : ''" value="case.financement_comptant.cheque3.encaissement" (change)="saveChange('cheques')">
        <option [ngValue]=true>Oui</option>
        <option [ngValue]=false>Non</option>
      </select>

    </div>

  </form>
</form>
<div>
  <div  style="display: flex; justify-content: space-between; " >
    <h3>Avoir</h3>

    <a style="color:var(--tertiarycrm); text-decoration: underline ;"  (click)="toggleShowAvoir()" >Supprimer des avoirs</a>
  </div>
  <div style="display: flex; flex-wrap: wrap;" >
    <div *ngFor="let avoir of avoirs" >
      <div (click)="chosenAvoir(avoir)"  class="avoir btn-secondarycrm" [ngClass]="avoir.title == avoirChoisi.title ? 'btn-tertiarycrm' : ''" >
        <img style="cursor: pointer;"  *ngIf="showSupprAvoir" (click)="supprAvoir(avoir._id)"  src='../../../assets/icons/suppr.svg'>
       {{avoir.title}}
      </div>
    </div>

    <button class="avoir btn-secondarycrm" (click)="addAvoir()" > + </button>
  </div>

  <div style="display: flex; justify-content: space-between; " *ngIf="avoirChoisi.title" >
      <mat-form-field (change)="editAvoir(avoirChoisi , avoirChoisi._id,('Le montant d\'' +avoirChoisi.title),(avoirChoisi.montant+'€'))">
        <mat-label>Montant</mat-label>
        <input type="number" [(ngModel)]="avoirChoisi.montant"  matInput>
      </mat-form-field>

      <input  placeholder="JJ/MM/AAAA xxhxx" [ngClass]="avoirChoisi.date != '' ? 'custom-date' : 'empty-date'"  [(ngModel)]="avoirChoisi.date"
      [owlDateTime]="dt3" [owlDateTimeTrigger]="dt3"  (ngModelChange)="editAvoir(avoirChoisi , avoirChoisi._id, ('La date d\' '+avoirChoisi.title), avoirChoisi.date)">
        <owl-date-time firstDayOfWeek="1" #dt3></owl-date-time>


      </div>



  </div>

</div>

</div>
  </ng-template>
<ng-template #piece>
<app-list-piece></app-list-piece>
</ng-template>
</div>
