<div class="book-container">
  <img class="logo_couleur" src="./../../../assets/book/logo_blanc.png" alt="logo" />
  <h1>L’Etat s’engage, mais comment ?</h1>
  <div class="card-container">
    <div class="card delay-0">
      <img src="./../../../assets/book/part15/cee.png" alt="Image 1" class="card-image">
      <div class="card-content">
        <h5>Prime CEE</h5>
        <p>L’aide des Certificats d’Économies d’Énergie (CEE) couvre jusqu’à 20 % de vos travaux de rénovation
          énergétique. 
          <br>
          Le plafond est déterminé par le gouvernement. Le but principal de cette aide est de réaliser les objectifs 
          européens
          fixés d’ici à 2032, soit de produire 27 % d’énergies renouvelables.
        </p>
      </div>
    </div>

    <div class="card delay-1">
      <img src="./../../../assets/book/part15/edfoa.png" alt="Image 2" class="card-image">
      <div class="card-content">
        <h5>Prime à l’autoconsommation</h5>

        <p>La prime à l’autoconsommation photovoltaïque est versée directement par EDF Obligation d’Achat dès que vous
          avez réalisé
          votre demande de raccordement à ENEDIS.
          <br>
          Le versement de la prime était auparavant étalé sur 5 ans, aujourd’hui il se fait en une seule fois. Calculée
          en
          fonction de votre installation, elle varie entre :
          370 € pour les puissances allant jusqu'à 3kWc → 3 kWc = 1 110 €
          280 € pour les puissances de 3,1kWc jusqu'à 9kWc → 6 kWc =1 680 €
          200 € pour les puissances dépassant 9kWc → 12 kWc =2 400 €
        </p>
      </div>
    </div>

    <div class="card delay-2">
      <img src="./../../../assets/book/part15/mpr.png" alt="Image 3" class="card-image">
      <div class="card-content">
        <h5>MaPrimeRénov’</h5>

        <p>MaPrimeRénov’ est une aide gouvernementale.
          Elle réunit le Crédit d’Impôt pour la Transition Énergétique (CITE) et l’aide
          « Habiter Mieux Agilité » de l’Anah. 
          <br>
          Calculée en fonction de vos revenus fiscaux, elle permet de bénéficier jusqu’à 10 000 € d’aides.
        </p>
      </div>
    </div>
  </div>


  <button class="primary-button precedent" [routerLink]="['/book/part14', caseId]">
    <svg style="transform:rotate(-180deg);" width="22" height="20" viewBox="0 0 14 12" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0303 6.53057C13.3232 6.23768 13.3232 5.76281 13.0303 5.46991L8.25736 0.696943C7.96447 0.40405 7.48959 0.40405 7.1967 0.696943C6.90381 0.989837 6.90381 1.46471 7.1967 1.7576L11.4393 6.00024L7.1967 10.2429C6.90381 10.5358 6.90381 11.0107 7.1967 11.3035C7.48959 11.5964 7.96447 11.5964 8.25736 11.3035L13.0303 6.53057ZM0.5 6.75024H12.5V5.25024H0.5V6.75024Z"
        fill="white" />
    </svg>
    Précédent
  </button>

  <button class="primary-button suivant" [routerLink]="['/book/part16', caseId]">
    Suivant
    <svg width="22" height="20" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0303 6.53057C13.3232 6.23768 13.3232 5.76281 13.0303 5.46991L8.25736 0.696943C7.96447 0.40405 7.48959 0.40405 7.1967 0.696943C6.90381 0.989837 6.90381 1.46471 7.1967 1.7576L11.4393 6.00024L7.1967 10.2429C6.90381 10.5358 6.90381 11.0107 7.1967 11.3035C7.48959 11.5964 7.96447 11.5964 8.25736 11.3035L13.0303 6.53057ZM0.5 6.75024H12.5V5.25024H0.5V6.75024Z"
        fill="white" />
    </svg>
  </button>

</div>