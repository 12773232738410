<img *ngIf="mobile" class="notification-tech" [routerLink]="['/infos-advs']" src="../../../assets/icons/notification.svg">
<p *ngIf="mobile" class="compteur-tech">
  <span matBadge="{{ infosUnread.length }}" matBadgeSize="small"></span>
</p>

<p style="color: #3A3B5A" class="my-5 mr-5 ml-3"> <strong>Pégase </strong> vous souhaite la bienvenue, <strong style="color: var(--tertiarycrm)">{{ user.username }}</strong></p>


<section class="wrapper-fostrap"  *ngIf="user.role !='Responsable Technicien'" >
  <h5 style="color: #3A3B5A" >Chantier du jour</h5>

  <div class="container-fostrap">
    <div class="content">
      <div class="row">
        <div class=" container-travaux">
        <div class="col-xs-12 col-lg-4 col-sm-6" *ngFor="let chantier of todayChantier">
          <div class="card" [routerLink]="['/technicien-case-detail',chantier._id]" >
            <div class="card-content">
              <div style="display: flex;"><p style="color: var(--tertiarycrm); font-weight: 600;" > {{ chantier.date| date:'short' }}</p> <p style="font-weight: 600; margin-left: 5%; " >{{chantier.title}} </p></div>
              <div class="qualif-mairie" [ngClass]="{'attente':chantier.statut === 'En cours',
              'conforme':chantier.statut === 'Terminé',
              'nonconforme':chantier.statut === 'Annulé'
             }" >{{chantier.statut}} </div>
              <p style="font-size: 16px;" class="little infos" *ngIf="chantier.caseId && chantier.caseId.customer" data-toggle="tooltip" data-placement="top">
                {{ chantier.caseId.customer.name }}
             </p>
              <p style="font-size: 14px; font-weight: 300;" >{{chantier.caseId.customer.address}} {{chantier.caseId.customer.city}} {{chantier.caseId.customer.cp}} </p>
              <p class="techs">{{chantier.tech[0].username}} / {{ chantier.chef }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</section>

<div *ngIf="user.role =='Responsable Technicien'" >
<section class="wrapper-fostrap">
  <div class="container-fostrap">
    <div class="content">
      <div class="row">
        <div class="col-xs-12 col-lg-12 col-sm-12" >Matin </div>
        <div class=" container-travaux" >
        <div class="col-xs-12 col-lg-4 col-sm-6" *ngFor="let chantier of morningChantier">
          <div class="card" [routerLink]="['/technicien-case-detail',chantier._id]" >
            <div class="card-content">
              <div style="display: flex;"><p style="color: var(--tertiarycrm); font-weight: 600;" > {{ chantier.date| date:'short' }}</p> <p style="font-weight: 600; margin-left: 5%; " >{{chantier.title}} </p></div>
              <div class="qualif-mairie" [ngClass]="{'attente':chantier.statut === 'En cours',
              'conforme':chantier.statut === 'Terminé',
              'nonconforme':chantier.statut === 'Annulé'
             }" >{{chantier.statut}} </div>
              <p style="font-size: 16px;" class="little infos" *ngIf="chantier.caseId && chantier.caseId.customer" data-toggle="tooltip" data-placement="top">
                {{ chantier.caseId.customer.name }}
              </p>
              <p style="font-size: 14px; font-weight: 300;" >{{chantier.caseId.customer.address}} {{chantier.caseId.customer.city}} {{chantier.caseId.customer.cp}} </p>
              <p class="techs"> {{chantier.tech[0].username}} / {{ chantier.chef }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</section>
<section class="wrapper-fostrap">
  <div class="container-fostrap">
    <div class="content">
      <div class="row">
        <div class="col-xs-12 col-lg-12 col-sm-12" >Après-Midi</div>
        <div class=" container-travaux">
        <div class="col-xs-12 col-lg-4 col-sm-6" *ngFor="let chantier of afternoonChantier">
          <div class="card" [routerLink]="['/technicien-case-detail',chantier._id]" >
            <div class="card-content">
              <div style="display: flex;"><p style="color: var(--tertiarycrm); font-weight: 600;" > {{ chantier.date| date:'short' }}</p> <p style="font-weight: 600; margin-left: 5%; " >{{chantier.title}} </p></div>
              <div class="qualif-mairie" [ngClass]="{'attente':chantier.statut === 'En cours',
              'conforme':chantier.statut === 'Terminé',
              'nonconforme':chantier.statut === 'Annulé'
             }" >{{chantier.statut}} </div>
              <p style="font-size: 16px;" class="little infos" *ngIf="chantier.caseId && chantier.caseId.customer" data-toggle="tooltip" data-placement="top">
                {{ chantier.caseId.customer.name }}
             </p>
              <p style="font-size: 14px; font-weight: 300;" >{{chantier.caseId.customer.address}} {{chantier.caseId.customer.city}} {{chantier.caseId.customer.cp}} </p>
              <p class="techs"> {{chantier.tech[0].username}} / {{ chantier.chef }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</section>
</div>
