import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { PiecesService } from '@app/services/pieces.service';
import { HistoriqueService } from './../../services/historique.service';
import { RolesService } from '@app/services/roles.service';
import { HttpClient } from '@angular/common/http';
import { apiUrl } from '@environments/env';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { TaskCaseListComponent } from '@app/tasks/task-case-list/task-case-list.component';
import { CreateTaskComponent } from '@app/tasks/create-task/create-task.component';
import { CaseProductsComponent } from '@app/case/case-products/case-products.component';

@Component({
  selector: 'app-case-cm',
  templateUrl: './case-cm.component.html',
  styleUrls: ['./case-cm.component.scss'],
})
export class CaseCmComponent implements OnInit {
  @ViewChild('select') select: MatSelect;
  @Input() state;
  caseId;
  case;
  cmForm: FormGroup;
  caseForm: FormGroup;
  apiUrl = apiUrl.apiUrl + '/piece/';

  multipleImagesPieces;
  contrat;
  mandat;
  options = ['Négociation', 'Prospection', 'Signée', 'Non Signée', 'Annulée'];
  selectedProductsTransco;
  v1;
  v2;
  autres = [];
  productsQuietude = [
    {
      id: 1,
      name: 'Panneaux solaires en autoconsommation',
      price: 28.95,
      checked: false,
      formule: 'Quiétude',
    },
    {
      id: 2,
      name: "Panneaux solaires en autoconsommation avec système de gestion d'energie",
      price: 35.95,
      checked: false,
      formule: 'Quiétude',
    },
    {
      id: 3,
      name: "PAC Air/Air jusqu'à 8 bouches",
      price: 38.49,
      checked: false,
      formule: 'Quiétude',
    },
    {
      id: 4,
      name: 'PAC Air/Eau',
      price: 38.49,
      checked: false,
      formule: 'Quiétude',
    },
    {
      id: 5,
      name: 'Panneaux solaires raccordés au réseau de distribution < / = 3Kw',
      price: 35.95,
      checked: false,
      formule: 'Quiétude',
    },
    {
      id: 6,
      name: 'Panneaux solaires raccordés au réseau de distribution entre 3Kw et 9 Kw',
      price: 47.99,
      checked: false,
      formule: 'Quiétude',
    },
    {
      id: 7,
      name: 'PAC Air/Air + VMC Double flux offert',
      price: 42.99,
      checked: false,
      formule: 'Quiétude',
    },
    {
      id: 8,
      name: 'VMC Double flux',
      price: 28.95,
      checked: false,
      formule: 'Quiétude',
    },
    {
      id: 9,
      name: 'Ballon thermodynamique',
      price: 38.49,
      checked: false,
      formule: 'Quiétude',
    },
  ];

  productsIndispensable = [
    {
      id: 1,
      name: 'Panneaux solaires en autoconsommation',
      price: 14.88,
      checked: false,
      formule: 'Indispensable',
    },
    {
      id: 2,
      name: "Panneaux solaires en autoconsommation avec système de gestion d'energie",
      price: 20.99,
      checked: false,
      formule: 'Indispensable',
    },
    {
      id: 3,
      name: "PAC Air/Air jusqu'à 8 bouches",
      price: 19.99,
      checked: false,
      formule: 'Indispensable',
    },
    {
      id: 4,
      name: 'PAC Air/Eau',
      price: 19.99,
      checked: false,
      formule: 'Indispensable',
    },
    {
      id: 5,
      name: 'Panneaux solaires raccordés au réseau de distribution < / = 3Kw',
      price: 20.99,
      checked: false,
      formule: 'Indispensable',
    },
    {
      id: 6,
      name: 'Panneaux solaires raccordés au réseau de distribution entre 3Kw et 9 Kw',
      price: 28.99,
      checked: false,
      formule: 'Indispensable',
    },
    {
      id: 7,
      name: 'PAC Air/Air + VMC Double flux offert',
      price: 24.99,
      checked: false,
      formule: 'Indispensable',
    },
    {
      id: 8,
      name: 'VMC Double flux',
      price: 14.88,
      checked: false,
      formule: 'Indispensable',
    },
    {
      id: 9,
      name: 'Ballon thermodynamique',
      price: 19.99,
      checked: false,
      formule: 'Indispensable',
    },
  ];

  selectedProducts = [];
  allSelected = false;
  showSelected = false;
  products = [];

  constructor(
    private route: ActivatedRoute,
    private sCases: CasesService,
    private fb: FormBuilder,
    private sRoles: RolesService,
    private sHistoriques: HistoriqueService,
    private http: HttpClient,
    private sPieces: PiecesService,
    private sb: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.caseId = this.route.snapshot.params['id'];
    this.showCaseInfos().then((res) => {
      if (this.case.kits_vendus != undefined) {
        if (this.case.kits_vendus.startsWith('[')) this.v2 = true;
        else {
          this.v1 = true;
          this.products = this.case.kits_vendus;
        }

        if (
          this.case.kits_vendus != null &&
          this.case.kits_vendus.startsWith('[')
        ) {
          JSON.parse(this.case.kits_vendus).forEach((element) => {
            if (element.active) {
              this.products.push(element.name);
            }
          });
          const array1 = this.products;
          const parseKits = JSON.parse(this.case.kits_vendus);

          parseKits.forEach((element) => {
            // si un produit du kit est dans le tableau
            if (array1.includes(element.name)) {
              // on vient éditer l'object associé dans products en active true
              let result = parseKits.filter((e) => e.name === element.name)[0];

              if (!result.active) {
                parseKits.filter((e) => e.name === element.name)[0].active =
                  true;
              }
            } else {
              parseKits.filter((e) => e.name === element.name)[0].active =
                false;
            }
          });
          this.selectedProductsTransco = JSON.stringify(parseKits);
          this.selectedProductsTransco = JSON.parse(
            this.selectedProductsTransco
          ).filter((res) => res.active);

          this.products.forEach((element) => {
            if (
              element != "Adoucisseur d'eau" &&
              element != 'Ballon thermodynamique' &&
              element != 'Batterie' &&
              element != 'Mise aux normes du tableau électrique' &&
              element != 'PAC Air/Air' &&
              element != 'PAC Air/Eau' &&
              element != 'Panneaux solaires' &&
              element != 'Thermostat radio' &&
              element != 'Isolation' &&
              element != 'VMC Double Flux'
            ) {
              this.autres.push(element);
            }
          });
        } else {
          this.products = this.case.kits_vendus;
        }
      } else {
        this.v1 = true;
      }

      (this.caseForm = this.fb.group({
        state: new FormControl(this.state),
        datesign: new FormControl(this.case.datesign),
      })),
        (this.cmForm = this.fb.group({
          formule: new FormControl(this.case.cm.formule),
          products: new FormControl([this.case.cm.products]),
          paiement: this.fb.group({
            type_paiement: new FormControl(this.case.cm.paiement.type_paiement),
            cheque1: new FormControl(this.case.cm.paiement.cheque1),
            mensualite: new FormControl(this.case.cm.paiement.mensualite),
            montant: new FormControl(this.case.cm.paiement.montant),
            cheque_depose: new FormControl(this.case.cm.paiement.cheque_depose),
            months: new FormControl([this.case.cm.paiement.months]),
          }),
          tarif: new FormControl(this.case.cm.tarif),
          years: new FormControl(this.case.cm.years),
        }));
      this.state = this.case.state;
    });
  }

  ngOnInit(): void {
    this.getCm();
    this.getMandat();
  }
  showCaseInfos() {
    return new Promise((resolve) => {
      this.sCases.getCaseById(this.caseId).subscribe(
        (res) => {
          this.case = res[0];
          this.selectedProducts = this.case.cm.products;
          resolve(res);
          this.showSelected = true;
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  selectFormule(event, selectedProduct) {
    if (selectedProduct.formule === 'Indispensable') {
      selectedProduct = this.productsQuietude.find(
        (element) => element.id == selectedProduct.id
      );
    } else {
      const found = this.productsIndispensable.find(
        (element) => element.id == selectedProduct.id
      );
      selectedProduct = found;
    }
    this.selectedProducts.push(selectedProduct);

    let index = this.case.cm.products.findIndex(function (o) {
      return o.id === selectedProduct.id;
    });
    if (index !== -1) this.selectedProducts.splice(index, 1);

    this.cmForm.value.tarif = 0;
    this.cmForm.value.paiement.montant = 0;
    if (this.selectedProducts) {
      this.selectedProducts.forEach((element) => {
        this.cmForm.value.tarif += element.price * 12;
        this.cmForm.value.paiement.montant += element.price;
      });
    }

    this.case.cm = this.cmForm.value;
    this.sCases.editCase(this.case, this.caseId).then((res) => {
      if (res['error']) {
        return res.error;
      }
    });
  }

  saveChangeDatesign(currentCase) {
    this.case.datesign = this.caseForm.value.datesign;
    this.case.state = this.state;
    let arrayYears = [
      [
        {
          index: 1,
          name: 'Janvier',
          state: 'en attente',
          montant: 0,
          comment: '',
          year: new Date(currentCase.datesign).getFullYear() + 1,
        },
        {
          index: 2,
          name: 'Février',
          state: 'en attente',
          montant: 0,
          comment: '',
          year: new Date(currentCase.datesign).getFullYear() + 1,
        },
        {
          index: 3,
          name: 'Mars',
          state: 'en attente',
          montant: 0,
          comment: '',
          year: new Date(currentCase.datesign).getFullYear() + 1,
        },
        {
          index: 4,
          name: 'Avril',
          state: 'en attente',
          montant: 0,
          comment: '',
          year: new Date(currentCase.datesign).getFullYear() + 1,
        },
        {
          index: 5,
          name: 'Mai',
          state: 'en attente',
          montant: 0,
          comment: '',
          year: new Date(currentCase.datesign).getFullYear() + 1,
        },
        {
          index: 6,
          name: 'Juin',
          state: 'en attente',
          montant: 0,
          comment: '',
          year: new Date(currentCase.datesign).getFullYear() + 1,
        },
        {
          index: 7,
          name: 'Juillet',
          state: 'en attente',
          montant: 0,
          comment: '',
          year: new Date(currentCase.datesign).getFullYear() + 1,
        },
        {
          index: 8,
          name: 'Août',
          state: 'en attente',
          montant: 0,
          comment: '',
          year: new Date(currentCase.datesign).getFullYear() + 1,
        },
        {
          index: 9,
          name: 'Septembre',
          state: 'en attente',
          montant: 0,
          comment: '',
          year: new Date(currentCase.datesign).getFullYear() + 1,
        },
        {
          index: 10,
          name: 'Octobre',
          state: 'en attente',
          montant: 0,
          comment: '',
          year: new Date(currentCase.datesign).getFullYear() + 1,
        },
        {
          index: 11,
          name: 'Novembre',
          state: 'en attente',
          montant: 0,
          comment: '',
          year: new Date(currentCase.datesign).getFullYear() + 1,
        },
        {
          index: 12,
          name: 'Décembre',
          state: 'en attente',
          montant: 0,
          comment: '',
          year: new Date(currentCase.datesign).getFullYear() + 1,
        },
      ],
    ];
    const startMonth = new Date(this.case.datesign).getMonth() + 1;
    let months = [
      {
        index: 1,
        name: 'Janvier',
        state: 'en attente',
        montant: 0,
        comment: '',
        year: new Date(currentCase.datesign).getFullYear(),
      },
      {
        index: 2,
        name: 'Février',
        state: 'en attente',
        montant: 0,
        comment: '',
        year: new Date(currentCase.datesign).getFullYear(),
      },
      {
        index: 3,
        name: 'Mars',
        state: 'en attente',
        montant: 0,
        comment: '',
        year: new Date(currentCase.datesign).getFullYear(),
      },
      {
        index: 4,
        name: 'Avril',
        state: 'en attente',
        montant: 0,
        comment: '',
        year: new Date(currentCase.datesign).getFullYear(),
      },
      {
        index: 5,
        name: 'Mai',
        state: 'en attente',
        montant: 0,
        comment: '',
        year: new Date(currentCase.datesign).getFullYear(),
      },
      {
        index: 6,
        name: 'Juin',
        state: 'en attente',
        montant: 0,
        comment: '',
        year: new Date(currentCase.datesign).getFullYear(),
      },
      {
        index: 7,
        name: 'Juillet',
        state: 'en attente',
        montant: 0,
        comment: '',
        year: new Date(currentCase.datesign).getFullYear(),
      },
      {
        index: 8,
        name: 'Août',
        state: 'en attente',
        montant: 0,
        comment: '',
        year: new Date(currentCase.datesign).getFullYear(),
      },
      {
        index: 9,
        name: 'Septembre',
        state: 'en attente',
        montant: 0,
        comment: '',
        year: new Date(currentCase.datesign).getFullYear(),
      },
      {
        index: 10,
        name: 'Octobre',
        state: 'en attente',
        montant: 0,
        comment: '',
        year: new Date(currentCase.datesign).getFullYear(),
      },
      {
        index: 11,
        name: 'Novembre',
        state: 'en attente',
        montant: 0,
        comment: '',
        year: new Date(currentCase.datesign).getFullYear(),
      },
      {
        index: 12,
        name: 'Décembre',
        state: 'en attente',
        montant: 0,
        comment: '',
        year: new Date(currentCase.datesign).getFullYear(),
      },
    ];
    let i = 0;
    while (i < months.length) {
      if (startMonth == months[i].index) {
        break;
      } else {
        months.splice(i, 1);
      }
    }

    arrayYears.push(months);
    this.case.cm.years = arrayYears.reverse();
    this.cmForm.value.years = this.case.cm.years;
    if (this.case.state === 'Terminé') {
      if (this.caseForm.value.datesign === null) {
        this.case.datesign = new Date();
      }
    }
    this.cmForm.value.tarif = 0;
    this.cmForm.value.paiement.montant = 0;
    if (this.selectedProducts) {
      this.selectedProducts.forEach((element) => {
        this.cmForm.value.tarif += element.price * 12;
        this.cmForm.value.paiement.montant += element.price;
      });
    }
    this.sCases.editCase(this.case, this.caseId).then((res) => {
      if (res['error']) {
        return res.error;
      }
    });
  }

  saveChange(currentCase) {
    this.case.state = this.state;
    if (this.case.state === 'Terminé') {
      if (this.caseForm.value.datesign === null) {
        this.case.datesign = new Date();
      }
    }
    this.cmForm.value.tarif = 0;
    this.cmForm.value.paiement.montant = 0;
    if (this.selectedProducts) {
      this.selectedProducts.forEach((element) => {
        this.cmForm.value.tarif += element.price * 12;
        this.cmForm.value.paiement.montant += element.price;
      });
    }

    this.cmForm.value.years = this.case.cm.years;
    this.case.cm = this.cmForm.value;

    // pour le tableau de produit, si selectedProduct.name === panneaux solaire x || ||

    currentCase.kits_vendus = this.case.kits_vendus;

    const kits_vendus = JSON.parse(this.case.kits_vendus);
    this.selectedProducts.forEach((product) => {
      switch (product.name) {
        case 'Panneaux solaires en autoconsommation':
        case "Panneaux solaires en autoconsommation avec système de gestion d'energie":
        case 'Panneaux solaires raccordés au réseau de distribution < / = 3Kw':
        case 'Panneaux solaires raccordés au réseau de distribution entre 3Kw et 9 Kw':
          kits_vendus.forEach((k) => {
            if (k.name === 'Panneaux solaires') {
              k.active = true;
            }
          });
          break;
        case "PAC Air/Air jusqu'à 8 bouches":
        case 'PAC Air/Air + VMC Double flux offert':
          kits_vendus.forEach((k) => {
            if (k.name === 'PAC Air/Air') {
              k.type_pac = 'Gainable';
              k.active = true;
            }
            if (k.name === 'VMC Double Flux') {
              k.active = true;
            }
          });
          break;
        case 'VMC Double flux':
          kits_vendus.forEach((k) => {
            if (k.name === 'VMC Double Flux') {
              k.active = true;
            }
          });
          break;
        case 'PAC Air/Eau':
          kits_vendus.forEach((k) => {
            if (k.name === 'PAC Air/Eau') {
              k.active = true;
            }
          });
          break;
        case 'Ballon thermodynamique':
          kits_vendus.forEach((k) => {
            if (k.name === 'Ballon thermodynamique') {
              k.active = true;
            }
          });
          break;
      }
    });
    currentCase.kits_vendus = JSON.stringify(kits_vendus);
    this.sCases.editCase(currentCase, this.caseId).then((res) => {
      if (res['error']) {
        return res.error;
      }
    });
  }

  selectMultiple(event, title) {
    if (event.target.files.length > 0) {
      this.multipleImagesPieces = event.target.files;
      this.onSubmitMultiple(title, 'ajouté', 'pièce', title);
    }
  }

  onSubmitMultiple(title, reason, change, value) {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', '');
    formData.append('qualification', 'Conforme');
    formData.append('caseId', this.caseId);
    for (let img of this.multipleImagesPieces) {
      formData.append('filesPieces', img);
    }
    this.sCases.editCase(this.case, this.caseId).then((res) => {
      if (res['error']) {
        return res.error;
      }
    });
    this.http.post<any>(this.apiUrl + 'multiplePieces', formData).subscribe(
      (res) => {
        this.getCm();
        this.getMandat();
        this.sb.open(`Pièce ajoutée`, '', {
          duration: 2000,
          verticalPosition: 'bottom',
          panelClass: ['add-snackbar'],
        });
      },
      (err) => console.log(err)
    );

    this.sHistoriques
      .createHistorique(
        this.caseId,
        this.case.customer._id,
        reason,
        change,
        value,
        this.sRoles.user.id
      )
      .then((res) => {
        if (res['error']) {
          alert(res);
        } else {
        }
      });
  }
  getCm() {
    this.sPieces.getCmByCaseId(this.caseId).subscribe((res) => {
      this.contrat = res[0];
    });
  }
  getMandat() {
    this.sPieces.getMandatByCaseId(this.caseId).subscribe((res) => {
      this.mandat = res[0];
    });
  }

  killBill(id, reason, change, value) {
    if (confirm('Confirmer la suppression?')) {
      this.sPieces.delete(id).subscribe((response) => {
        this.contrat = null;
        this.sb.open(`Facture supprimée`, '', {
          duration: 2000,
          verticalPosition: 'bottom',
          panelClass: ['delete-snackbar'],
        });
        this.getCm();
        this.getMandat();
      });
      this.sHistoriques
        .createHistorique(
          this.caseId,
          this.case.customer._id,
          reason,
          change,
          value,
          this.sRoles.user.id
        )
        .then((res) => {
          if (res['error']) {
            alert(res);
          }
        });
    }
  }

  createTaskDialog(): void {
    const dialogRef = this.dialog.open(CreateTaskComponent, {
      width: '40%',
      height: '80%',

      data: {
        caseId: this.caseId,
        customerId: this.case.customer._id,
        inCase: true,
        edit: false,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  showTaskDialog(): void {
    const dialogRef = this.dialog.open(TaskCaseListComponent, {
      width: '60%',
      height: '80%',
      data: {
        currentCase: this.case,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  openKitVendu(): void {
    const dialogRef = this.dialog.open(CaseProductsComponent, {
      width: '640px',
      disableClose: true,
      data: {
        currentCase: this.case,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.selectedProductsTransco = this.case.kits_vendus;
      this.selectedProductsTransco = JSON.parse(
        this.selectedProductsTransco
      ).filter((res) => res.active);
    });
  }
}
