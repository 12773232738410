import { CasesService } from './../../services/cases.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomersService } from '@app/services/customers.service';
import { AuthenticationService } from '@app/_services';
import { TmkCasesComponent } from '@app/tmk/tmk-cases/tmk-cases.component';
import { MatTableDataSource } from '@angular/material/table';
import { UsersService } from '@app/services/users.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
  trigger,
  transition,
  style,
  animate,
  query,
  stagger,
} from '@angular/animations';
import { FormControl } from '@angular/forms';

const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(
      ':enter',
      [
        style({ opacity: 0 }),
        stagger('60ms', animate('300ms ease-out', style({ opacity: 1 }))),
      ],
      { optional: true }
    ),
  ]),
]);

@Component({
  selector: 'app-admin-customer-list',
  templateUrl: './admin-customer-list.component.html',
  styleUrls: ['./admin-customer-list.component.scss'],
  animations: [listAnimation],
})
export class AdminCustomerListComponent implements OnInit {
  public searchParams = {
    name: new FormControl(''),
    mobile: new FormControl(''),
    cp: new FormControl(''),
  };

  customers;
  currentUser;
  dataSource;
  displayedColumns = ['name', 'city', 'mobile', 'mail', 'id'];
  showCheck = false;
  supprCustomer = [];
  isfrmChecked;
  userIsActive;
  constructor(
    private sCustomers: CustomersService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private sCases: CasesService,
    private sUsers: UsersService
  ) {
    this.authenticationService.user.subscribe((x) => (this.currentUser = x));
    this.sUsers.getUserById(this.currentUser.id).subscribe((res) => {
      this.userIsActive = res[0].isActive;
    });
  }

  ngOnInit() {
    this.showCustomers();
    this.searchParams.name.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe(() => this.search());

    this.searchParams.mobile.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe(() => this.search());

    this.searchParams.cp.valueChanges
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe(() => this.search());
  }

  search() {
    const params = {
      name: this.searchParams.name.value,
      mobile: this.searchParams.mobile.value,
      cp: this.searchParams.cp.value,
    };

    this.sCustomers.searchCustomers(params).subscribe((customers) => {
      this.dataSource = customers;
    });
  }

  research(name) {
    if (name != '') {
      this.sCustomers.searchCustomer(name).then((res) => {
        this.dataSource = new MatTableDataSource(res);
      });
    } else {
      this.showCustomers();
    }
  }

  showCustomers() {
    this.sCustomers.getAllCustomers(50).subscribe((response) => {
      this.customers = response['customers'];
      this.dataSource = new MatTableDataSource(this.customers);
    });
  }

  applyFilter(filterValue: string) {
    this.showCheck = false;
    this.supprCustomer = [];
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  supprCustommerArray() {
    if (
      confirm(
        'Si vous supprimez ce client, vous ne pourrez plus le récupérer. Etes vous sur de vouloir le faire ?'
      )
    ) {
      this.supprCustomer.forEach((element) => {
        this.deleteCustomer(element);
        this.sCases.deleteCaseCustomer(element).subscribe((response) => {});
      });
      this.showCheck = false;
      this.supprCustomer = [];
    }
  }

  deleteCustomer(id) {
    this.sCustomers.delete(id).subscribe((response) => {
      this.showCustomers();
    });
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(TmkCasesComponent, {
      width: '640px',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.showCustomers();
    });
  }

  showEdit() {
    this.showCheck = !this.showCheck;
  }

  selectCustomers(event: any, isChecked: boolean) {
    if (isChecked) {
      this.supprCustomer.push(event.target.value);
    } else {
      let index = this.supprCustomer.indexOf(event.target.value);
      this.supprCustomer.splice(index, 1);
    }
  }
}
