<button mat-button (click)="goBack()"><img src="../../../assets/icons/retour.svg">Retour</button>
<h3 class="ml-3">Liste des pièces</h3>
<h1></h1>
<br>
<div class="row">
    <mat-card class="col-xs-12 col-lg-3 col-sm-2" *ngFor="let piece of pieces"
        (click)="showCross || goToDetail(piece._id)">
        <div>
            <img src="../../../assets/pieces/img-piece.png" class="card-img-top" alt="pieces">
            <p class="qualif" [ngClass]="{'attente':piece.qualification === 'En attente de qualification',
              'conforme':piece.qualification === 'Conforme',
              'nonconforme':piece.qualification === 'Non conforme'
            }">{{ piece.qualification }}</p>
            <mat-card-content>
                <p class="title">{{ piece.title }}</p>
                <h1 style="position: absolute;
    font-size: 0.7em;
    right: 2%;
    bottom: -2%;"> {{piece.created_at |date: 'short'}}</h1>
                <p class="description" *ngIf="piece.description && !piece.commentary">{{ piece.description }}</p>
                <p class="description" style="opacity:0;" *ngIf="piece.description === null">v</p>
                <p class="description" *ngIf="piece.commentary != ''">{{piece.commentary}}</p>
            </mat-card-content>
        </div>
    </mat-card>

    <p *ngIf="pieces?.length === 0" class="ml-3">Aucune pièce présente pour cette affaire</p>
</div>