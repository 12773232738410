<app-sidebar-commercial
[caseId]="caseId"
[add]="add"
 *ngIf="userRole === 'Commercial' || userRole === 'Responsable d\'agence'">
</app-sidebar-commercial>

<div class="resize-content" *ngIf="!mobile">
<form [formGroup]="pieceForm">

<div *ngIf="userRole != 'Technicien'  && userRole != 'Responsable Technicien' " class="custom-radio mt-5">
    <input type="radio" (click)="validPiece(false)" id="radio1" name="title" formControlName="title" value="BS Mme M-2">
    <label [ngClass]="this.pieceForm.value.title == 'BS Mme M-2' ? 'active' : ''" for="radio1">BS Mme M-2</label>

    <input type="radio" (click)="validPiece(false)" id="radio2" name="title" formControlName="title" value="BS Mme M-1">
    <label [ngClass]="this.pieceForm.value.title == 'BS Mme M-1' ? 'active' : ''"  for="radio2">BS Mme M-1</label>

    <input type="radio" (click)="validPiece(false)" id="radio3" name="title" formControlName="title" value="BS Mme M">
    <label [ngClass]="this.pieceForm.value.title == 'BS Mme M' ? 'active' : ''"  for="radio3">BS Mme M</label>

    <input type="radio" (click)="validPiece(false)" id="radio4" name="title" formControlName="title" value="BS Mr M-2">
    <label [ngClass]="this.pieceForm.value.title == 'BS Mr M-2' ? 'active' : ''"  for="radio4">BS Mr M-2</label>

    <input type="radio" (click)="validPiece(false)" id="radio5" name="title" formControlName="title" value="BS Mr M-1">
    <label   [ngClass]="this.pieceForm.value.title == 'BS Mr M-1' ? 'active' : ''" for="radio5">BS Mr M-1</label>

    <input type="radio" (click)="validPiece(false)" id="radio6" name="title" formControlName="title" value="BS Mr M">
    <label [ngClass]="this.pieceForm.value.title == 'BS Mr M' ? 'active' : ''" for="radio6">BS Mr M</label>

    <input type="radio" (click)="validPiece(false)" id="radio7" name="title" formControlName="title" value="Photo disjoncteur">
    <label [ngClass]="this.pieceForm.value.title == 'Photo disjoncteur' ? 'active' : ''"  for="radio7">Photo disjoncteur</label>

    <input type="radio" (click)="validPiece(false)" id="radio8" name="title" formControlName="title" value="Photo compteur">
    <label [ngClass]="this.pieceForm.value.title == 'Photo compteur' ? 'active' : ''"  for="radio8">Photo compteur</label>

    <input type="radio" (click)="validPiece(false)" id="radio9" name="title" formControlName="title" value="Photo maison de loin">
    <label [ngClass]="this.pieceForm.value.title == 'Photo maison de loin' ? 'active' : ''"  for="radio9">Photo maison de loin</label>

    <input type="radio" (click)="validPiece(false)" id="radio99" name="title" formControlName="title" value="Photo combles">
    <label [ngClass]="this.pieceForm.value.title == 'Photo combles' ? 'active' : ''"  for="radio99">Photo combles</label>

    <input type="radio" (click)="validPiece(false)" id="radio9999" name="title" formControlName="title" value="Photo des 4 côtés de la maison">
    <label [ngClass]="this.pieceForm.value.title == 'Photo des 4 côtés de la maison' ? 'active' : ''"  for="radio9999">Photo des 4 côtés de la maison</label>
    <input type="radio" (click)="validPiece(false)" id="radio99999" name="title" formControlName="title" value="Photo des radiateurs">
    <label [ngClass]="this.pieceForm.value.title == 'Photo des radiateurs' ? 'active' : ''"  for="radio99999">Photo des radiateurs</label>
    

    <input type="radio" (click)="validPiece(false)" id="radio10" name="title" formControlName="title" value="Photo toîture">
    <label [ngClass]="this.pieceForm.value.title == 'Photo toîture' ? 'active' : ''"  for="radio10">Photo toîture</label>

    <input type="radio" (click)="validPiece(false)" id="radio11" name="title" formControlName="title" value="Photo UE PAC">
    <label [ngClass]="this.pieceForm.value.title == 'Photo UE PAC' ? 'active' : ''"  for="radio11">Photo UE PAC</label>

    <input type="radio" (click)="validPiece(false)" id="radio38" name="title" formControlName="title" value="Photo UI">
    <label [ngClass]="this.pieceForm.value.title == 'Photo UI' ? 'active' : ''"  for="radio38">Photo UI</label>

    <input type="radio" (click)="validPiece(false)" id="radio39" name="title" formControlName="title" value="Photos complémentaires">
    <label [ngClass]="this.pieceForm.value.title == 'Photos complémentaires' ? 'active' : ''"  for="radio39">Photos complémentaires</label>

    <input type="radio" (click)="validPiece(false)" id="radio12" name="title" formControlName="title" value="VT">
    <label [ngClass]="this.pieceForm.value.title == 'VT' ? 'active' : ''"   for="radio12">VT</label>

    <input type="radio" (click)="validPiece(false)" id="radio13" name="title" formControlName="title" value="RIB">
    <label [ngClass]="this.pieceForm.value.title == 'RIB' ? 'active' : ''"  for="radio13">RIB</label>

    <input type="radio" (click)="validPiece(false)" id="radio14" name="title" formControlName="title" value="AI Mr">
    <label [ngClass]="this.pieceForm.value.title == 'AI Mr' ? 'active' : ''"  for="radio14">AI Mr</label>

    <input type="radio" (click)="validPiece(false)" id="radio15" name="title" formControlName="title" value="AI Mme">
    <label [ngClass]="this.pieceForm.value.title == 'AI Mme' ? 'active' : ''"  for="radio15">AI Mme</label>

    <input type="radio" (click)="validPiece(false)" id="radio16" name="title" formControlName="title" value="CNI Mme">
    <label [ngClass]="this.pieceForm.value.title == 'CNI Mme' ? 'active' : ''"   for="radio16">CNI Mme</label>

    <input type="radio" (click)="validPiece(false)" id="radio17" name="title" formControlName="title" value="CNI Mr">
    <label  [ngClass]="this.pieceForm.value.title == 'CNI Mr' ? 'active' : ''"   for="radio17">CNI Mr</label>

    <input type="radio" (click)="validPiece(false)" id="radio18" name="title" formControlName="title" value="CAF">
    <label [ngClass]="this.pieceForm.value.title == 'CAF' ? 'active' : ''"  for="radio18">CAF</label>

    <input type="radio" (click)="validPiece(false)" id="radio19" name="title" formControlName="title" value="Plan de masse">
    <label [ngClass]="this.pieceForm.value.title == 'Plan de masse' ? 'active' : ''" for="radio19">Plan de masse</label>

    <input type="radio" (click)="validPiece(false)" id="radio33" name="title" formControlName="title" value="Photo nouveau BT">
    <label [ngClass]="this.pieceForm.value.title == 'Photo nouveau BT' ? 'active' : ''"  for="radio33">Photo nouveau BT</label>

    <input type="radio" (click)="validPiece(false)" id="radio34" name="title" formControlName="title" value="8 zones photos bouches">
    <label [ngClass]="this.pieceForm.value.title == '8 zones photos bouches' ? 'active' : ''"  for="radio34">8 zones photos bouches</label>

    <input type="radio" (click)="validPiece(false)" id="radio35" name="title" formControlName="title" value="10 photos plafonds">
    <label [ngClass]="this.pieceForm.value.title == '10 photos plafonds' ? 'active' : ''" for="radio35">10 photos plafonds</label>

    <input type="radio" (click)="validPiece(false)" id="radio36" name="title" formControlName="title" value="Photo ancien BT">
    <label [ngClass]="this.pieceForm.value.title == 'Photo ancien BT' ? 'active' : ''" for="radio36">Photo ancien BT</label>

    <input type="radio" (click)="validPiece(false)" id="radio21" name="title" formControlName="title" value="Acte notarié">
    <label [ngClass]="this.pieceForm.value.title == 'Acte notarié' ? 'active' : ''"  for="radio21">Acte notarié</label>

    <input type="radio" (click)="validPiece(false)" id="radio22" name="title" formControlName="title" value="Taxe foncière">
    <label [ngClass]="this.pieceForm.value.title == 'Taxe foncière' ? 'active' : ''" for="radio22">Taxe foncière</label>

    <input type="radio" (click)="validPiece(false)" id="radio23" name="title" formControlName="title" value="Justif dom -3mois">
    <label [ngClass]="this.pieceForm.value.title == 'Justif dom -3mois' ? 'active' : ''" for="radio23">Justif dom -3mois</label>

    <input type="radio" (click)="validPiece(false)" id="radio24" name="title" formControlName="title" value="Facture EDF">
    <label [ngClass]="this.pieceForm.value.title == 'Facture EDF' ? 'active' : ''" for="radio24">Facture EDF</label>

    <input type="radio" (click)="validPiece(false)" id="radio25" name="title" formControlName="title" value="Etude de rentabilité">
    <label [ngClass]="this.pieceForm.value.title == 'Etude de rentabilité' ? 'active' : ''" for="radio25">Etude de rentabilité</label>

    <input type="radio" (click)="validPiece(false)" id="radio26" name="title" formControlName="title" value="Bon de commande">
    <label [ngClass]="this.pieceForm.value.title == 'Bon de commande' ? 'active' : ''"  for="radio26">Bon de commande</label>

    <input type="radio" (click)="validPiece(false)" id="radio20" name="title" formControlName="title" value="Offre de prêt">
    <label [ngClass]="this.pieceForm.value.title == 'Offre de prêt' ? 'active' : ''"  for="radio20">Offre de prêt</label>

    <input type="radio" (click)="validPiece(false)" id="radio27" name="title" formControlName="title" value="Mandat MPR">
    <label [ngClass]="this.pieceForm.value.title == 'Mandat MPR' ? 'active' : ''"  for="radio27">Mandat MPR</label>

    <input type="radio" (click)="validPiece(false)" id="radio32" name="title" formControlName="title" value="Borderau d'inter">
    <label [ngClass]="this.pieceForm.value.title == 'Borderau d\'inter' ? 'active' : ''"  for="radio32">Borderau d'inter</label>

    <input type="radio" (click)="validPiece(false)" id="radio29" name="title" formControlName="title" value="PV livraison">
    <label [ngClass]="this.pieceForm.value.title == 'PV livraison' ? 'active' : ''"  for="radio29">PV livraison</label>

    <input type="radio" (click)="validPiece(false)" id="radio30" name="title" formControlName="title" value="PV réception">
    <label [ngClass]="this.pieceForm.value.title == 'PV réception' ? 'active' : ''"  for="radio30">PV réception</label>

    <input type="radio" (click)="validPiece(false)" id="radio31" name="title" formControlName="title" value="Recette">
    <label [ngClass]="this.pieceForm.value.title == 'Recette' ? 'active' : ''" for="radio31">Recette</label>

    <input type="radio" (click)="validPiece(false)" id="radio311" name="title" formControlName="title" value="CNO">
    <label [ngClass]="this.pieceForm.value.title == 'CNO' ? 'active' : ''" for="radio311">CNO</label>

    <input type="radio" (click)="validPiece(false)" id="radio312" name="title" formControlName="title" value="Consuel">
    <label [ngClass]="this.pieceForm.value.title == 'Consuel' ? 'active' : ''" for="radio312">Consuel</label>

    <input type="radio" (click)="validPiece(false)" id="radio313" name="title" formControlName="title" value="Marquage pénétration de la gaine">
    <label [ngClass]="this.pieceForm.value.title == 'Marquage pénétration de la gaine' ? 'active' : ''" for="radio313">Marquage pénétration de la gaine</label>

    <input *ngIf="userRole === 'Admin' || userRole === 'Compta'" type="radio" (click)="validPiece(false)" id="radio314" name="title" formControlName="title" value="Devis">
    <label *ngIf="userRole === 'Admin' || userRole === 'Compta'" [ngClass]="this.pieceForm.value.title == 'Devis' ? 'active' : ''" for="radio314">Devis</label>

    <input type="radio" (click)="validPiece(true)" id="radio28" data-toggle="modal" data-target="#otherPiece"  name="title" formControlName="title" [(value)]="otherPiece">
    <label [ngClass]="otherChosen ? 'active' : ''"for="radio28">{{otherPiece}}</label>
</div>

<div *ngIf="userRole == 'Technicien' || userRole == 'Responsable Technicien' " class="custom-radio mt-5">
  <input type="radio" (change)="validPiece(false)" id="radio30" name="title" formControlName="title" value="PV réception">
  <label [ngClass]="this.pieceForm.value.title == 'PV réception' ? 'active' : ''"  for="radio30">PV réception</label>

  <input type="radio" (change)="validPiece(false)" id="radio29" name="title" formControlName="title" value="PV livraison">
  <label [ngClass]="this.pieceForm.value.title == 'PV livraison' ? 'active' : ''"  for="radio29">PV livraison</label>

  <input type="radio" (change)="validPiece(false)" id="radioTech2" name="title" formControlName="title" value="Bon d'inter">
  <label [ngClass]="this.pieceForm.value.title == 'Bon d\'inter' ? 'active' : ''" for="radioTech2">Bon d'inter</label>

  <input type="radio" (change)="validPiece(false)" id="radioTech3" name="title" formControlName="title" value="Bon d'inter Cm">
  <label [ngClass]="this.pieceForm.value.title == 'Bon d\'inter Cm' ? 'active' : ''" for="radioTech3">Bon d'inter CM</label>

  <input type="radio" (click)="validPiece(false)" id="radioTech1" name="title" formControlName="title" value="Audit">
  <label [ngClass]="this.pieceForm.value.title == 'Audit' ? 'active' : ''" for="radioTech1">Audit</label>

  <input type="radio" (change)="validPiece(false)" id="radio31" name="title" formControlName="title" value="Recette">
  <label [ngClass]="this.pieceForm.value.title == 'Recette' ? 'active' : ''" for="radio31">Recette</label>

  <input type="radio" (click)="validPiece(false)" id="radioTech4" name="title" formControlName="title" value="Mandat BAF">
  <label [ngClass]="this.pieceForm.value.title == 'Mandat BAF' ? 'active' : ''" for="radioTech4">Mandat BAF</label>

  <input type="radio" (click)="validPiece(false)" id="radioTech5" name="title" formControlName="title" value="Document Prime">
  <label [ngClass]="this.pieceForm.value.title == 'Document Prime' ? 'active' : ''" for="radioTech5">Document Prime</label>

  <input type="radio" (click)="validPiece(false)" id="radio7" name="title" formControlName="title" value="Photo disjoncteur">
  <label [ngClass]="this.pieceForm.value.title == 'Photo disjoncteur' ? 'active' : ''"  for="radio7">Photo disjoncteur</label>

  <input type="radio" (click)="validPiece(false)" id="radio8" name="title" formControlName="title" value="Photo compteur">
  <label [ngClass]="this.pieceForm.value.title == 'Photo compteur' ? 'active' : ''"  for="radio8">Photo compteur</label>

  <input type="radio" (click)="validPiece(false)" id="radio9" name="title" formControlName="title" value="Photo maison de loin">
  <label [ngClass]="this.pieceForm.value.title == 'Photo maison de loin' ? 'active' : ''"  for="radio9">Photo maison de loin</label>

  <input type="radio" (click)="validPiece(false)" id="radio99" name="title" formControlName="title" value="Photo combles">
  <label [ngClass]="this.pieceForm.value.title == 'Photo combles' ? 'active' : ''"  for="radio99">Photo combles</label>

  <input type="radio" (click)="validPiece(false)" id="radio10" name="title" formControlName="title" value="Photo toîture">
  <label [ngClass]="this.pieceForm.value.title == 'Photo toîture' ? 'active' : ''"  for="radio10">Photo toîture</label>

  <input type="radio" (click)="validPiece(false)" id="radio11" name="title" formControlName="title" value="Photo UE PAC">
  <label [ngClass]="this.pieceForm.value.title == 'Photo UE PAC' ? 'active' : ''"  for="radio11">Photo UE PAC</label>

  <input type="radio" (click)="validPiece(false)" id="radio38" name="title" formControlName="title" value="Photo UI">
  <label [ngClass]="this.pieceForm.value.title == 'Photo UI' ? 'active' : ''"  for="radio38">Photo UI</label>

  <input type="radio" (click)="validPiece(false)" id="radio39" name="title" formControlName="title" value="Photos complémentaires">
  <label [ngClass]="this.pieceForm.value.title == 'Photos complémentaires' ? 'active' : ''"  for="radio39">Photos complémentaires</label>

</div>

<div class="mt-5">
  <label>Description</label>

  <div class="col-5 custom-textarea">
        <textarea matInput formControlName="description"></textarea>
  </div>

  <div class="button-wrapper mt-3">
    <span class="label btn-secondarycrm">Ajouter des photos +</span>
    <input accept=".jpg,.jpeg,.png,.pdf" multiple type="file" name="upload" id="upload" class="upload-box" (change)="selectMultiple($event)">
    <p *ngIf="multipleImagesPieces">{{ multipleImagesPieces.length }} document(s) ajouté(s)</p>
  </div>

</div>

<div class="custom-radio mt-4" *ngIf="userRole === 'Admin' || userRole === 'Adv'">
  <input type="radio"  id="qualif1" name="qualification" formControlName="qualification" value="En attente de qualification">
  <label  [ngClass]="this.pieceForm.value.qualification == 'En attente de qualification' ? 'active' : ''" for="qualif1">En attente de qualification</label>

  <input type="radio"  id="qualif2" name="qualification" formControlName="qualification" value="Non conforme">
  <label  [ngClass]="this.pieceForm.value.qualification == 'Non conforme' ? 'active' : ''" for="qualif2">Non conforme</label>

  <input type="radio"  id="qualif3" name="qualification" formControlName="qualification" value="Conforme">
  <label  [ngClass]="this.pieceForm.value.qualification == 'Conforme' ? 'active' : ''" for="qualif3">Conforme</label>

</div>


<div class="modal fade" id="otherPiece" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <button type="button" (click)="validPiece(true)" class="btn btn-secondarycrm"data-dismiss="modal">Valider</button>
        <mat-form-field class="example-full-width">
          <mat-label>Autre pièce</mat-label>
          <input matInput formControlName="title">
        </mat-form-field>

      </div>
    </div>
  </div>
</div>

</form>
<button class="btn btn-secondarycrm" (click)="onSubmitMultiple('ajouté','pièce',pieceForm.value.title)">Créer la pièce</button>

</div>




<div *ngIf="mobile">
  <img *ngIf="mobile" class="messagerie-icon" [routerLink]="['/messagerie']" src="../../../assets/bar-icons/messagerie.svg">
  <p *ngIf="mobile" class="compteur-messagerie">
    <span *ngIf="countUnread" matBadge="{{ countUnread.count }}" matBadgeSize="small"></span>
  </p>
  <form class="example-form" [formGroup]="pieceForm">

      <mat-form-field *ngIf="userRole != 'Technicien' && userRole != 'Responsable Technicien' "  class="example-full-width col-9 mt-6">
        <input type="text"
               class="input-search"
               placeholder="Choisir votre pièce"
               aria-label="Number"
               matInput
               formControlName="title"
               [matAutocomplete]="auto">
        <button mat-button matSuffix mat-icon-button>
          <mat-icon class="search">search</mat-icon>
        </button>
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{option}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <div class="custom-radio tech" *ngIf="userRole == 'Technicien' || userRole == 'Responsable Technicien'">

        <input type="radio" (change)="validPiece(false)" id="radio30" name="title" formControlName="title" value="PV réception">
        <label [ngClass]="this.pieceForm.value.title == 'PV réception' ? 'active' : ''"  for="radio30">PV réception</label>


        <input type="radio" (change)="validPiece(false)" id="radio29" name="title" formControlName="title" value="PV livraison">
        <label [ngClass]="this.pieceForm.value.title == 'PV livraison' ? 'active' : ''"  for="radio29">PV livraison</label>

        <input type="radio" (change)="validPiece(false)" id="radioTech2" name="title" formControlName="title" value="Bon d'inter">
        <label [ngClass]="this.pieceForm.value.title == 'Bon d\'inter' ? 'active' : ''" for="radioTech2">Bon d'inter</label>

        <input type="radio" (change)="validPiece(false)" id="radioTech3" name="title" formControlName="title" value="Bon d'inter Cm">
        <label [ngClass]="this.pieceForm.value.title == 'Bon d\'inter Cm' ? 'active' : ''" for="radioTech3">Bon d'inter CM</label>

        <input type="radio" (change)="validPiece(false)" id="radioTech1" name="title" formControlName="title" value="Audit">
        <label [ngClass]="this.pieceForm.value.title == 'Audit' ? 'active' : ''" for="radioTech1">Audit</label>

        <input type="radio" (change)="validPiece(false)" id="radio31" name="title" formControlName="title" value="Recette">
        <label [ngClass]="this.pieceForm.value.title == 'Recette' ? 'active' : ''" for="radio31">Recette</label>

        <input type="radio" (change)="validPiece(false)" id="radioTech4" name="title" formControlName="title" value="Mandat BAF">
        <label [ngClass]="this.pieceForm.value.title == 'Mandat BAF' ? 'active' : ''" for="radioTech4">Mandat BAF</label>

        <input type="radio" (change)="validPiece(false)" id="radioTech5" name="title" formControlName="title" value="Document Prime">
        <label [ngClass]="this.pieceForm.value.title == 'Document Prime' ? 'active' : ''" for="radioTech5">Document Prime</label>

        <input type="radio" (click)="validPiece(false)" id="radio7" name="title" formControlName="title" value="Photo disjoncteur">
        <label [ngClass]="this.pieceForm.value.title == 'Photo disjoncteur' ? 'active' : ''"  for="radio7">Photo disjoncteur</label>

        <input type="radio" (click)="validPiece(false)" id="radio8" name="title" formControlName="title" value="Photo compteur">
        <label [ngClass]="this.pieceForm.value.title == 'Photo compteur' ? 'active' : ''"  for="radio8">Photo compteur</label>

        <input type="radio" (click)="validPiece(false)" id="radio9" name="title" formControlName="title" value="Photo maison de loin">
        <label [ngClass]="this.pieceForm.value.title == 'Photo maison de loin' ? 'active' : ''"  for="radio9">Photo maison de loin</label>

        <input type="radio" (click)="validPiece(false)" id="radio99" name="title" formControlName="title" value="Photo combles">
        <label [ngClass]="this.pieceForm.value.title == 'Photo combles' ? 'active' : ''"  for="radio99">Photo combles</label>

        <input type="radio" (click)="validPiece(false)" id="radio10" name="title" formControlName="title" value="Photo toîture">
        <label [ngClass]="this.pieceForm.value.title == 'Photo toîture' ? 'active' : ''"  for="radio10">Photo toîture</label>

        <input type="radio" (click)="validPiece(false)" id="radio11" name="title" formControlName="title" value="Photo UE PAC">
        <label [ngClass]="this.pieceForm.value.title == 'Photo UE PAC' ? 'active' : ''"  for="radio11">Photo UE PAC</label>

        <input type="radio" (click)="validPiece(false)" id="radio38" name="title" formControlName="title" value="Photo UI">
        <label [ngClass]="this.pieceForm.value.title == 'Photo UI' ? 'active' : ''"  for="radio38">Photo UI</label>

        <input type="radio" (click)="validPiece(false)" id="radio39" name="title" formControlName="title" value="Photos complémentaires">
        <label [ngClass]="this.pieceForm.value.title == 'Photos complémentaires' ? 'active' : ''"  for="radio39">Photos complémentaires</label>
      </div>



  <div class="offset-1 col-10 mt-3 d-flex text-center">
    <span class="col-12 btn-secondarycrm">+ Ajouter une photo</span>
    <input accept=".jpg,.jpeg,.png,.pdf" multiple type="file" name="upload" id="upload" class="upload-box" (change)="selectMultiple($event)">
  </div>
  <p class="offset-1 col-10 count" *ngIf="multipleImagesPieces">{{ multipleImagesPieces.length }} document(s) ajouté(s)</p>

  <div class="mt-5">
    <label class="pl-3">Description</label>
    <div class="col-12 custom-textarea">
          <textarea matInput formControlName="description"></textarea>
    </div>

  </div>
  </form>

  <div class="col-10">
    <button class="btn-secondarycrm mt-5 offset-6 col-8" *ngIf="this.timeOutLoader && this.pieceForm.valid && multipleImagesPieces" (click)="onSubmitMultiple('ajouté','pièce',pieceForm.value.title)">Créer la pièce</button>
    <mat-spinner *ngIf="!this.timeOutLoader" ></mat-spinner>
  </div>
</div>
