import { Component, OnInit } from '@angular/core';
import { CasesService } from '@app/services/cases.service';
import { RolesService } from '@app/services/roles.service';
import { UsersService } from '@app/services/users.service';

@Component({
  selector: 'app-suivi-dossiers',
  templateUrl: './suivi-dossiers.component.html',
  styleUrls: ['./suivi-dossiers.component.scss']
})
export class SuiviDossiersComponent implements OnInit {

  user
  advs;
  selectedId
  cases

  constructor(
    public sRoles: RolesService,
    private sUsers: UsersService,
    private sCases:CasesService,
    ) {
      this.user = this.sRoles.user;
      this.sUsers.getAllAdv().subscribe(res => {
        this.advs = res['users'].filter(a => a.isActive)
      })
      this.selectedId = this.user.id
   }

  ngOnInit(): void { }


  showAdv(event) {
    this.selectedId = event
  }
}
