import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AdminCases } from '@app/_models/AdminCases';
import { AdminDashboardModalComponent } from '../admin-dashboard-modal/admin-dashboard-modal.component';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-admin-suivi-annuel',
  templateUrl: './admin-suivi-annuel.component.html',
  styleUrls: ['./admin-suivi-annuel.component.scss']
})
export class AdminSuiviAnnuelComponent implements OnInit {

  adminCases: AdminCases;
  chartData;
  public chart: Chart;
  public chartBarStacked: Chart;
  public choice;

  labels: Array<any>;
  data: Array<number>;
  annee1:Array<number>;
  annee2:Array<number>;
  selectedFirstYear:number;
  selectedYear:number;

  constructor(
    private router: Router,
    private dialog: MatDialog
  ) {

    if (this.router.getCurrentNavigation().extras.state) {

      this.adminCases = this.router.getCurrentNavigation().extras?.state?.adminCases;
    }
    this.openDialog();
  }

  ngOnInit(): void {
  }
  goBack() {
    this.router.navigate(['/admin-dashboard'])
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(AdminDashboardModalComponent, {
      width: '40%',
      height: '50vh',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (this.chart) {
        this.chart.destroy();
      }
      else if (this.chartBarStacked) {
        this.chartBarStacked.destroy();
      }
      this.choice = result.choice
      if (result) {
        if (result.choice === 'commerciaux') {
          this.labels = result.commerciaux
          this.data = result.totauxPriceHt
        }
        else if (result.choice === 'agences') {
          this.labels = result.agences
          this.data = result.totauxCaAgences
        }
        else if (result.choice === 'globales') {
          this.annee1 = result.annee1;
          this.annee2 = result.annee2;

          this.labels = result.globaleAgences;
          this.selectedFirstYear = result.selectedFirstYear;
          this.selectedYear = result.selectedYear;

          var chartData = {
            labels: result.globaleAgences,
            datasets: [
              {
                label: result.selectedFirstYear,
                data: result.annee1,
                backgroundColor: 'rgba(0, 128, 0, 0.6)' // Vert
              },
              {
                label: result.selectedYear,
                data: result.annee2,
                backgroundColor: 'rgba(255, 0, 0, 0.6)' // Rouge
              }
            ]
          };

          this.chartBarStacked = new Chart("barStacked", {
            type: 'bar',
            data: chartData,
          });

        }
        this.chartData = result;
        this.chart = new Chart("canvas", {
          type: "bar",
          data: {
            labels: this.labels,
            datasets: [
              {
                data: this.data,
                backgroundColor: [
                  "rgba(255, 99, 132, 0.2)",
                  "rgba(54, 162, 235, 0.2)",
                  "rgba(255, 206, 86, 0.2)",
                  "rgba(75, 192, 192, 0.2)",
                  "rgba(153, 102, 255, 0.2)",
                  "rgba(255, 159, 64, 0.2)"
                ],
                borderColor: [
                  "rgba(255, 99, 132, 1)",
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                  "rgba(75, 192, 192, 1)",
                  "rgba(153, 102, 255, 1)",
                  "rgba(255, 159, 64, 1)"
                ],
                borderWidth: 1
              }
            ]
          },
          options: {
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true
                  }
                }
              ],
            }
          }
        });

      }
    });
  }

}
