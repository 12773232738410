import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CasesService } from '@app/services/cases.service';

@Component({
  selector: 'app-no-modal',
  templateUrl: './no-modal.component.html',
  styleUrls: ['./no-modal.component.scss']
})
export class NoModalComponent implements OnInit {

  why = ''
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sCases: CasesService,
    private dialogRef: MatDialog) {
  }

  ngOnInit(): void {
  }

  saveChange() {
    const newValue = this.data.value + '_why'
    this.data.currentKit[newValue] = this.why

    const array = JSON.parse(this.data.case.kits_vendus);
    const index = array.findIndex(item => item.name === this.data.currentKit.type);

    array[index] = this.data.currentKit

    this.data.case.kits_vendus = JSON.stringify(array)

    this.sCases.editCase(this.data.case, this.data.case._id).then(res => this.closeModale())
  }

  closeModale() {
    this.dialogRef.closeAll();
  }
}
