import { FormArray } from '@angular/forms';
import { Component, OnChanges, OnDestroy, Input, Output, ElementRef, ViewChild, EventEmitter, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiUrl } from '@environments/env';
import { format, isSameDay } from 'date-fns';
import { fr } from 'date-fns/locale';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { GroupsCreateComponent } from '../groups-create/groups-create.component';
import { AuthenticationService } from '@app/_services';
import { FileService } from '@app/services/file.service';
import { Role } from '@app/_models';
import { io } from 'socket.io-client';


// Notifications



@Component({
  selector: 'app-groups-detail',
  templateUrl: './groups-detail.component.html',
  styleUrls: ['./groups-detail.component.scss'],
})
export class GroupsDetailComponent implements OnChanges, OnDestroy {
  @Input() group: any;
  @Input() users: any = [];
  @Output() back = new EventEmitter();
  @Output() destroy = new EventEmitter();
  @Output() messagesNo = new EventEmitter<number>();
  @ViewChild('content') $content: ElementRef;
  currentUser = undefined;
  isAdmin = false;
  isAttaching = false;
  isLoading = false;
  isMobile = false;
  isSending = false;
  isSendNotification = false;
  messages = [];
  newMessagesCount = 0;
  skip = 0;
  socket = undefined;
  text = '';
  counter = 0


  constructor(
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    private sFile: FileService,
    private http: HttpClient,
    private sb: MatSnackBar,
    private zone: NgZone,


  ) {

    // console.log('GROUPE == ' + this.group)

    this.authenticationService.user.subscribe(x => {
      if (x) {
        this.currentUser = x;
        this.isAdmin = (this.currentUser && this.currentUser.role === Role.Admin || this.currentUser.role === Role.RespAdv || this.currentUser.role === Role.Adv);
        this.socket = io(apiUrl.apiUrl, { transports: ['websocket'], query: { token: x.token, userId: x.id.toString() }});
      }
    });
    if (window.screen.width <= 600) {
      this.isMobile = true;
    }
  }

  ngOnChanges(changes): void {
    if (changes.group.currentValue !== changes.group.previousValue) {
      this.isAttaching = false;
      this.isLoading = false;
      this.isSending = false;
      this.isSendNotification = false;
      this.messages = [];

      this.newMessagesCount = 0;
      this.skip = 0;
      this.disconnect();
      if (changes.group.currentValue) this.connect();
    }
  }

  ngOnDestroy(): void {
    this.disconnect();
  }

  // Sockets methods

  connect(): void {


    this.socket.emit('app:room:join', `groups:${this.group._id}`);
    this.socket.on('messages:index', (data: any[]) => {
      this.isLoading = false;
      this.newMessagesCount = data.length;
      const countMessages = this.messages.length;
      const messages = this.skip === 0 ? [...data] : [...data, ...this.messages];
      this.messages = this.insertDayBreaks(messages);
      if (!countMessages) this.toBottom();
    });
    this.socket.on('messages:index:error', (data) => {
      console.info('messages:index:error', data);
      this.isLoading = false;
      this.sb.open(`Une erreur s'est produite.`, 'Ok', { duration: 5000, verticalPosition: 'top' });
    });
    this.socket.on('messages:new', (data) => {
      if (data.groupId !== this.group._id) return undefined;
      this.messages = [...this.messages, data];
      this.toBottom();
      this.socket.emit('messages:read', data);
    });


    // NOTIFICATIONS
    // this.socket.on('messages:new:notif', (msg) => {
    //   // this.sb.open(msg,'', {
    //   //   duration: 2000,
    //   //   panelClass: ['edit-snackbar']
    //   // });


    // });


    this.socket.on('messages:new:success', (data) => {
      this.isSending = false;
      this.text = '';
      this.isSendNotification = false;
      this.toBottom();
    });
    this.socket.on('messages:new:error', (data) => {
      console.info('messages:new:error', data);
      this.isSending = false;
      this.isSendNotification = false;
      this.sb.open(`Une erreur s'est produite.`, 'Ok', { duration: 5000, verticalPosition: 'top' });
    });
    this.isLoading = true;
    this.socket.emit(`messages:index`, { groupId: this.group._id, skip: this.skip });
  }

  disconnect(): void {
    this.socket.off('messages:index');
    this.socket.off('messages:index:error');
    this.socket.off('messages:new');
    this.socket.off('messages:new:success');
    this.socket.off('messages:new:error');
    this.socket.emit('app:room:leave', `groups:${this.group._id}`);
  }

  loadmore(): void {
    this.skip += 50;
    this.isLoading = true;
    this.socket.emit('messages:index', { groupId: this.group._id, skip: this.skip });
  }

  // Components methods

  async attach(): Promise<void> {
    try {
      this.isAttaching = true;
      const files = await this.sFile.upload(true, `groups/${this.group._id}`);
      this.socket.emit('messages:store', {
        groupId: this.group._id,
        type: 'files',
        data: { files },
      });
    } catch (error) {
      if (error !== 'No file(s) selected.') this.sb.open(typeof error.message === 'string' ? error.message : JSON.stringify(error), 'Ok', { duration: 5000, verticalPosition: 'top' });
    } finally {
      this.isAttaching = false;
    }
  }

  goBack(): void {
    this.back.emit();
  }

  getGroupTitle(): string {
    if (!this.group) return '';
    if (this.group.title) return this.group.title;
    const member = this.group.members.find(m => m._id !== this.currentUser.id);
    return member ? member.username : '(Moi)';
  }

  async groupArchive(): Promise<void> {
    if (!confirm(`Êtes-vous sûr de vouloir archiver le groupe ?`)) return undefined;
    try {
      this.disconnect();
      await this.http.patch(apiUrl.apiUrl + '/groups/' + this.group._id + '/archive', {}).toPromise();
      this.destroy.emit();
    } catch (error) { this.sb.open(error.message, 'Ok', { duration: 5000, verticalPosition: 'top' }); }
  }

  async groupDestroy(): Promise<void> {
    if (!confirm(`Êtes-vous sûr de vouloir supprimer le groupe ?`)) return undefined;
    try {
      this.disconnect();
      await this.http.delete(apiUrl.apiUrl + '/groups/' + this.group._id).toPromise();
      this.destroy.emit();
    } catch (error) { this.sb.open(error.message, 'Ok', { duration: 5000, verticalPosition: 'top' }); }
  }

  promptUsers(): void {
    const dialogRef = this.dialog.open(GroupsCreateComponent, {
      data: { type: 'users', group: this.group, users: this.users },
    });
    dialogRef.afterClosed().subscribe((group) => {
      if (group) this.group = group;
    });
  }

  send(): any {
    if (!this.text) return this.sb.open(`Veuillez saisir du text.`, 'Ok', { duration: 5000, verticalPosition: 'top' });
    this.isSending = true;
    this.socket.emit('messages:store', {
      groupId: this.group._id,
      type: 'text',
      text: this.text,
      created_at: new Date(),
      isSendNotification: (this.isAdmin && this.isSendNotification),
    });


  }

  private insertDayBreaks(messages: any[]): any[] {
    const ms = [];
    messages.filter(m => ['text', 'files'].includes(m.type))
      .forEach((m) => {
        const prevM = ms[ms.length - 1];
        if (!prevM) ms.push({ type: 'date-break', text: format(new Date(m.created_at), 'd MMMM', { locale: fr }) });
        if (prevM && !isSameDay(new Date(m.created_at), new Date(prevM.created_at))) ms.push({ type: 'date-break', text: format(new Date(m.created_at), 'd MMMM', { locale: fr }) });
        ms.push(m);
      });
    return ms;
  }

  private toBottom(): void {
    this.zone.run(() => {
      setTimeout(() => {
        this.$content.nativeElement.scroll({
          top: this.$content.nativeElement.scrollHeight,
          behavior: 'smooth',
        });
      }, 300);
    });
  }
}
