<div class="body">
  <img src="../../assets/rosace.png" alt="rosace" class="rosace">
  <div class="content_logo">
    <img src="../../assets/logo.png" alt="logo" class="logo">
  </div>
  <div class="content" [formGroup]="loginForm">
    <div class="card">
    <h2>Connectez-vous</h2>
    <div class="id">
      <label for="Identifiant">Identifiant</label>
      <input type="text" formControlName="mail" name="" value="" placeholder="maxime.vasseur@e...">
    </div>
    <div class="mdp">
      <label for="Mot de passe">Mot de passe</label>
      <input type="password" formControlName="password" name="" value="" placeholder="...........">
    </div>
    <div class="button">
      <button (click)="onSubmit()">Se connecter</button>
    </div>
    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>

    </div>
  </div>
</div>
