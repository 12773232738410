import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { HistoriqueService } from '@app/services/historique.service';
import { PiecesService } from '@app/services/pieces.service';
import { RolesService } from '@app/services/roles.service';
import { apiUrl} from '@environments/env';
import { Location } from '@angular/common'

@Component({
  selector: 'app-compta-cm-detail',
  templateUrl: './compta-cm-detail.component.html',
  styleUrls: ['./compta-cm-detail.component.scss']
})
export class ComptaCmDetailComponent implements OnInit {

  @ViewChild('select') select: MatSelect;
  bill;
  billImg;
  caseId;
  case;
  cmForm : FormGroup;
  caseForm: FormGroup;
  arrayCheque : FormArray;
  apiUrl = apiUrl.apiUrl + '/piece/';

  multipleImagesPieces;
  contrat;
  mandat;
  options = ['Négociation', 'Prospection', 'Signée', 'Non Signée','Annulée'];
  allSelected = false;
  showSelected = false;
  totalTarif;
  totalMontant;
  selectedMonth;
  selectedObject;
  selectedIndex;
  selectedYear;
  dateSignYear;


  arrayChequeYear =[]
  firstCheque

  today = new Date
  monthNow

  test = ""


  public tabs: any = {};

  constructor(
    private route: ActivatedRoute,
    private sCases: CasesService,
    private fb: FormBuilder,
    private sRoles: RolesService,
    private sHistoriques: HistoriqueService,
    private http: HttpClient,
    private sPieces: PiecesService,
    private sb: MatSnackBar,
    private location : Location,

  ) {
    this.caseId = this.route.snapshot.params['id'];
this.monthNow = this.today.getMonth()
     console.log(this.monthNow)
   }

  ngOnInit(): void {
    this.showCaseInfos()
    this.getCm();
    this.getBill()
  }

  showCaseInfos() {
    return new Promise(resolve => {
    this.sCases.getCaseById(this.caseId).subscribe(res => {
      this.case = res[0];
      if( this.case.cm.paiement.arrayChequeYear != undefined ){
   this.case.cm.paiement.arrayChequeYear.map( x => {if(x.date != null){
    x.date = new Date(x.date).toISOString().split('T')[0]
   }
 })
}
       console.log("currentCase", this.case)
      resolve(res);
      this.caseForm = this.fb.group({
        state : this.case.state,
        datesign: this.case.datesign,
      }),
      this.cmForm = this.fb.group({
        formule : this.case.cm.formule,
        products: [this.case.cm.products],
        paiement: this.fb.group({
        type_paiement:this.case.cm.paiement.type_paiement,
        cheque1: this.case.cm.paiement.cheque1,
        mensualite: this.case.cm.paiement.mensualite,
        arrayCheque : [this.case.cm.paiement.arrayChequeYear],
        montant: this.case.cm.paiement.montant,
        months: [this.case.cm.paiement.months]
        }),
        years: [this.case.cm.years],
        tarif: this.case.cm.tarif
      });
      this.tabs = this.case.cm.years

      this.showSelected = true;
      this.dateSignYear = new Date(this.case.datesign).getFullYear()
      console.log("icicicicic", this.case.cm.years)

        }, error => {
          console.log(error);
      });
    });

  }


  saveChange(currentCase, reason , change,value) {
    if(this.case.state === 'Terminé') {
      if(this.caseForm.value.datesign === null) {
        this.case.datesign = new Date()
      }
    }

    this.sCases.editCase(currentCase, this.caseId).then(res => {
      if(res['error']) {
        return res.error;
      }
      else {
        this.sHistoriques.createHistorique(this.caseId, this.case.customer._id,reason,change,value,this.sRoles.user.id).then(res => {
          if (res['error']) {
            alert(res);
          }
          else {
          }
        });
        console.log("case edit ",res)
      }
    });
  }

  selectMultiple(event, title) {
    if (event.target.files.length > 0) {
      this.multipleImagesPieces = event.target.files;
      this.onSubmitMultiple(title,'ajouté','pièce',title )
    }
  }

  getBill(){
    this.sPieces.getBillByCaseId(this.caseId).subscribe(res =>{
      this.bill = res[0]
      this.billImg = this.bill ? this.bill.image.split(',')[0] : null
    })
  }
  onSubmitMultiple(title, reason, change,value) {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', '');
    formData.append('qualification', 'Conforme');
    formData.append('caseId', this.caseId);
    for (let img of this.multipleImagesPieces) {
      formData.append('filesPieces', img);
    }
     this.http.post<any>(this.apiUrl + 'multiplePieces', formData).subscribe(
      (res) => {
        this.getCm()
        this.getBill()
        this.sb.open(`Pièce ajoutée`, '', { duration: 2000, verticalPosition: 'bottom', panelClass: ['add-snackbar'] });

      },
      (err) => console.log(err)
    );

     this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change,value,this.sRoles.user.id).then(res => {
      if (res['error']) {
        alert(res);
      }
      else {
      }
    });
  }
  getCm(){
    this.sPieces.getCmByCaseId(this.caseId).subscribe(res =>{
      this.contrat = res[0]
    })
  }

  killBill(id,reason, change,value) {
    if (confirm("Confirmer la suppression?")) {
      this.sPieces.delete(id).subscribe(response => {
        console.log("Suppression effectuée");
        this.bill= null
        this.sb.open(`Facture supprimée`, '', { duration: 2000, verticalPosition: 'bottom', panelClass: ['delete-snackbar'] });
        this.getBill()
      });
      this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change,value,this.sRoles.user.id).then(res => {
        if (res['error']) {
          alert(res);
        }
      });
    }
  }

  showMonthSelected(month) {
    this.selectedMonth = month.name + ' ' + month.year
    this.selectedObject = month
    this.selectedYear = this.case.cm.years.findIndex(i => this.selectedObject.year === i[0].year )
    this.selectedIndex = this.case.cm.years[this.selectedYear].findIndex(i => this.selectedObject.index === i.index)
    console.log(this.selectedIndex)
    //this.selectedObject = this.case.cm.paiement.months.find(i => i.name === this.selectedMonth);
    console.log(this.selectedObject)

  }

  editState(value) {
    this.selectedObject.state = value
    if(this.selectedObject.state != 'Payé') {
      this.selectedObject.montant = 0
    }

    if(this.selectedObject.state === "Payé" &&
    new Date(this.case.datesign).getMonth() + 1 === this.selectedObject.index &&
    new Date(this.case.datesign).getFullYear() != this.selectedObject.year) {

      const caseLength = this.case.cm.years.length
      if(this.selectedObject.year >= this.case.cm.years[caseLength -1][0].year) {

        let arrayYears = [
          {index: 1, name: 'Janvier',state: 'en attente',montant: 0, comment: '', year: this.selectedObject.year + 1},
          {index: 2, name: 'Février',state: 'en attente',montant: 0, comment: '', year: this.selectedObject.year + 1},
          {index: 3, name: 'Mars',state: 'en attente',montant: 0, comment: '', year: this.selectedObject.year + 1},
          {index: 4, name: 'Avril',state: 'en attente',montant: 0, comment: '', year: this.selectedObject.year + 1},
          {index: 5, name: 'Mai',state: 'en attente',montant: 0, comment: '', year: this.selectedObject.year + 1},
          {index: 6, name: 'Juin',state: 'en attente',montant: 0, comment: '', year: this.selectedObject.year + 1},
          {index: 7, name: 'Juillet',state: 'en attente',montant: 0, comment: '', year: this.selectedObject.year + 1},
          {index: 8, name: 'Août',state: 'en attente',montant: 0, comment: '', year: this.selectedObject.year + 1},
          {index: 9, name: 'Septembre',state: 'en attente',montant: 0, comment: '', year: this.selectedObject.year + 1},
          {index: 10, name: 'Octobre',state: 'en attente',montant: 0, comment: '', year: this.selectedObject.year + 1},
          {index: 11, name: 'Novembre',state: 'en attente',montant: 0, comment: '', year: this.selectedObject.year + 1},
          {index: 12, name: 'Décembre',state: 'en attente',montant: 0, comment: '', year: this.selectedObject.year + 1},
        ]
        this.case.cm.years.push(arrayYears)
      }
    }else{
      this.case.cm.years[this.selectedYear][this.selectedIndex] = this.selectedObject
    }
    console.log("before edit case", this.case)
     this.sCases.editCase(this.case, this.caseId).then(res => {
      if(res['error']) {
        return res.error;
      }
      else {
        console.log("case edit ",res)
        this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, 'edité', this.selectedObject.name,value,this.sRoles.user.id).then(res => {
          if (res['error']) {
            alert(res);
          }
        });
      }
    });

  }
  editComment(value) {
    console.log(value)
    this.selectedObject.comment = value
    this.sCases.editCase(this.case, this.caseId).then(res => {
      if(res['error']) {
        return res.error;
      }
      else {
        console.log("case edit ",res)
        this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, 'edité','commentaire',value,this.sRoles.user.id).then(res => {
          if (res['error']) {
            alert(res);
          }
        });
      }
    });
  }

  editMontant(value) {
    console.log(" this.selectedObject.montant", this.selectedObject.montant)
    console.log("this.case", this.case)
    this.selectedObject.montant = parseFloat(value)
    this.sCases.editCase(this.case, this.caseId).then(res => {
      if(res['error']) {
        return res.error;
      }
      else {
        console.log("case edit ",res)
        this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, 'edité', 'montant chèque' ,value,this.sRoles.user.id).then(res => {
          if (res['error']) {
            alert(res);
          }
        });
      }
    });
  }

  checkCheque(object){
    let value
    if(object == undefined){
        console.log(object)

        let chequeDate = new Date(this.firstCheque)
        let year = chequeDate.getFullYear()

        this.case.datesign = new Date(this.case.datesign)
        if(chequeDate.getMonth() < this.case.datesign.getMonth()){
          year --
      }
      if(this.arrayChequeYear.length ==0){
        let objectCheque = {
          date : this.firstCheque,
          year : year,
          index: 1
        };

        let objectChequeNextYear = {
          year : year+1,
          index: 2,
          date : null
        }
        this.case.cm.paiement.arrayCheque = []
        this.case.cm.paiement.arrayCheque.push(objectCheque,objectChequeNextYear)

      }
      value = this.firstCheque
    }
    else if(object.index == this.case.cm.paiement.arrayCheque.length ){

      let objectChequeNextYear = {
        year : object.year+1,
        index: object.index+1,
        date : null
      }

      let arrayYears = [
        {index: 1, name: 'Janvier',state: 'en attente',montant: 0, comment: '', year: object.year+1},
        {index: 2, name: 'Février',state: 'en attente',montant: 0, comment: '', year: object.year+1},
        {index: 3, name: 'Mars',state: 'en attente',montant: 0, comment: '', year: object.year+1},
        {index: 4, name: 'Avril',state: 'en attente',montant: 0, comment: '', year: object.year+1},
        {index: 5, name: 'Mai',state: 'en attente',montant: 0, comment: '', year: object.year+1},
        {index: 6, name: 'Juin',state: 'en attente',montant: 0, comment: '', year: object.year+1},
        {index: 7, name: 'Juillet',state: 'en attente',montant: 0, comment: '', year:object.year+1},
        {index: 8, name: 'Août',state: 'en attente',montant: 0, comment: '', year: object.year+1},
        {index: 9, name: 'Septembre',state: 'en attente',montant: 0, comment: '', year: object.year+1},
        {index: 10, name: 'Octobre',state: 'en attente',montant: 0, comment: '', year:object.year+1},
        {index: 11, name: 'Novembre',state: 'en attente',montant: 0, comment: '', year:object.year+1},
        {index: 12, name: 'Décembre',state: 'en attente',montant: 0, comment: '', year: object.year+1},
      ]


      this.case.cm.years.push(arrayYears)
      this.case.cm.paiement.arrayCheque.push(objectChequeNextYear)
      value = object.date
    }

    this.cmForm.value.paiement.arrayCheque = this.case.cm.paiement.arrayCheque
    this.saveChange(this.case,'edité','date d\'encaissement',value)
  }


     stopCM(){
      let monthSign = new Date(this.case.datesign).getMonth() +1
     this.case.cm.years[this.case.cm.years.length-1].forEach(month => {
       if(month.index >= monthSign){
         month.state = "Terminé"

      }
     });
    if(this.case.cm.paiement.type_paiement == "Mensualité"){0
      this.tabs[this.tabs.length - 1 ] = this.case.cm.years[this.case.cm.years.length - 1].slice(0, monthSign - 1)
    }else{
      this.case.cm.paiement.arrayCheque.splice(-1,1)
    }
    this.case.cm.ended = true
    this.saveChange(this.case,'edité','statut cm',"terminé")
    }

}
