import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UsersService } from '@app/services/users.service';

@Component({
  selector: 'app-admin-user-add',
  templateUrl: './admin-user-add.component.html',
  styleUrls: ['./admin-user-add.component.scss']
})
export class AdminUserAddComponent implements OnInit {

  userForm: FormGroup;
  role;

  roles = [
    {name: 'Admin'},
    {name: "Responsable d'agence"},
    {name: 'Commercial'},
    {name: 'Responsable ADV'},
    {name: 'Adv'},
    {name: 'Technicien'},
    {name: 'Responsable Technicien'},
    {name: 'Auditeur'},
    {name: 'Superviseur TMK'},
    {name: 'Tmk'},
    {name: 'Compta'},
    {name: 'Spectateur'},
    {name: 'Logistique'},
    {name: 'Rh'}
  ];

  agencies = [
    {name: 'Néros'},
    {name: 'Inti'},
    {name: 'Sparthios'},
    {name: 'Amon'},
    {name: 'Dordogne'},

  ];

  sectors = [
    {name: 'Bordeaux'},
    {name: 'Seignosse'},
    {name: 'Landes'},
    {name: 'Dordogne'},
  ];
  constructor(
    private sUsers: UsersService,
    private dialogRef: MatDialog,
    private fb: FormBuilder,

  ) {
    this.userForm = this.fb.group({
      'username' : [null, Validators.required],
      'password' : [null, Validators.required],
      'mail' : [null, Validators.compose([Validators.required, Validators.email])],
      'role' : [null, Validators.required],
      'agency' : [null],
      'sector': [null],
      'ceasy_login': ['']
    });
   }

  ngOnInit(): void { }

  addUser() {
     this.sUsers.createUser(this.userForm.value).then(res => {
      if (res['error']) {
        alert(res.error);
      }
      else {
        this.closeModale();
      }
    });
  }

  closeModale()
  {
    this.dialogRef.closeAll();
  }

  setAgencySectorValidators() {
    const agencyControl = this.userForm.get('agency');
    const sectorControl = this.userForm.get('sector');

    this.userForm.get('role').valueChanges
      .subscribe(role => {
        if (role === 'Commercial' || role === "Responsable d'agence") {
          agencyControl.setValidators([Validators.required]);
          sectorControl.setValidators([Validators.required]);
        }
        else {
          agencyControl.setValidators(null);
          sectorControl.setValidators(null);
          this.userForm.get('agency').setValue(null);
          this.userForm.get('sector').setValue(null);
        }
      });
  }

}
