import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-part16',
  templateUrl: './part16.component.html',
  styleUrls: ['./part16.component.scss']
})

export class Part16Component implements OnInit {
  caseId

  reponse: string[] = [];
  book;
  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.caseId = this.route.snapshot.params['id'];
    this.book = JSON.parse(localStorage.getItem('book'));
  }

  public checkChoice(choice: string): void {
    const index = this.reponse.indexOf(choice);

    if (index === -1) {
      this.reponse.push(choice);
    } else {
      this.reponse.splice(index, 1);
    }
  }

  suivant() {
    this.book.question5.reponse = this.reponse;
    this.book.question5.valid = this.arraysEqual(this.book.question5.reponse, this.book.question5.bonneReponse);
    this.book.pourcentageReussite = this.calculateSuccessPercentage();

    localStorage.setItem('book', JSON.stringify(this.book));
    this.router.navigate(['/book/part17', this.caseId]);

  }
  arraysEqual(arr1: any[], arr2: any[]): boolean {
    if (arr1.length !== arr2.length) return false;
    let sortedArr1 = arr1.slice().sort();
    let sortedArr2 = arr2.slice().sort();
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) return false;
    }
    return true;
  }

  calculateSuccessPercentage(): number {
    const totalQuestions = 5;
    let validCount = 0;

    if (this.book.question1.valid) validCount++;
    if (this.book.question2.valid) validCount++;
    if (this.book.question3.valid) validCount++;
    if (this.book.question4.valid) validCount++;
    if (this.book.question5.valid) validCount++;

    let percentage = (validCount / totalQuestions) * 100;
    return percentage;
  }
}