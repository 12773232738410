import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrl} from '@environments/env';

@Injectable({
  providedIn: 'root'
})
export class MairiesService {

  local_url = apiUrl.apiUrl + '/mairie/';

  headers = { 'Content-Type': 'application/json' };

  constructor(
    private http: HttpClient,

  ) { }

  getAllMairies() {
    return this.http.get(this.local_url + 'mairies');
  }

  getMairiesByCaseId(id) {
    return this.http.get(this.local_url + id);
  }
  getMairieById(id) {
    return this.http.get(this.local_url + 'mairie/' + id);
  }

  delete(id) {
    return this.http.delete(this.local_url + id);
  }

  editMairie(mairie, mairieId): Promise<any> {
    return this.http
    .put(this.local_url + mairieId, JSON.stringify(mairie) , { headers: this.headers })
    .toPromise()
    .then(res => res as any);
  }

  createMairie(mairieForm): Promise<any> {
    return this.http
      .post(this.local_url + 'add', JSON.stringify(mairieForm), { headers: this.headers })
      .toPromise()
      .then(res => res as any);
  }
}
