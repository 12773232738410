 <div class="text-center">
  <h1>Attention</h1>
    <p>Vous allez créer les identifiants du client pour son espace personnel</p>
    <p>Ces informations sont à reporter sur la LCC</p>

  <p *ngIf="currentCustomer.mail != '';else emptyMail">Le client disposant d'une adresse mail, celle ci servira d'identifiant pour son compte</p>

  <ng-template #emptyMail>
    <p>Aucune adresse mail n'est trouvée pour le client, ses identifiants seront donc son nom et son prénom (ex : darrietfabien)</p>
  </ng-template>

  <form [formGroup]="form">
    <label style="float: left;">NOM Prénom</label>
    <input
    formControlName="name"
    id="name"
    type="text"
    class="form-control mb-4 pb-4 pt-4"
    placeholder="Nom Prénom">

    <label style="float: left;">Mail</label>
    <input
    formControlName="login"
    id="title"
    type="text"
    class="form-control mb-4 pb-4 pt-4"
    placeholder="identifiant" [readonly]=choice>

    <label style="float: left;">Mot de passe</label>
    <input
    formControlName="password"
    type="text"
    class="form-control mb-4 pb-4 pt-4">
  </form>

  <p *ngIf="error != ''" style="color: #e11f31;">{{error}}</p>

  <div class="btn-validate">
    <button class="btn-secondarycrm" (click)="onNoClick()">Non</button>
    <button class="btn-secondarycrm" (click)="saveLogin()">Oui</button>
  </div>
</div>
