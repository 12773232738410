import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { CustomersService } from '@app/services/customers.service';
import { PiecesService } from '@app/services/pieces.service';

@Component({
  selector: 'app-logistique-detail',
  templateUrl: './logistique-detail.component.html',
  styleUrls: ['./logistique-detail.component.scss']
})
export class LogistiqueDetailComponent implements OnInit {
  customerId
  customer;
  cases;
  arrayPiece: any;

  pieces;
  constructor(
    private sCases: CasesService,
    private sCustomers: CustomersService,
    private sPieces: PiecesService,
    private route: ActivatedRoute,
    private router: Router,
    private sb: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.customerId = this.route.snapshot.params['id'];
    this.showCases()
    this.showCustomer()
  }

  showCustomer() {
    this.sCustomers.getCustomerById(this.customerId).subscribe(res => {
      this.customer = res[0]
    })
  }
  showCases() {
    this.sCases.getAllCasesByCustomerId(this.customerId).subscribe(res => {
      this.cases = res
      this.cases = this.cases.filter(e => e.state === 'Signée')
    })
  }

  showPieces(caseId) {
    this.sPieces.getLastSpecificPieceByTitle(caseId, 'VT').subscribe(res => {
      this.pieces = res[0];
      if(this.pieces && this.pieces._id != undefined) {
        setTimeout(() => {
          this.router.navigate(['detail-piece', this.pieces._id])
        }, 500);
      }
      else {
        this.sb.open(`Aucune VT Conforme trouvée`, '', { duration: 2000, verticalPosition: 'bottom', panelClass: ['delete-snackbar'] });
      }

    });
  }

  goToDetailPiece(pieceId){
    this.router.navigate(['detail-piece', pieceId],)
  }

}
