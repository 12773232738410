<div>
  <h6 class="my-5">Bon d'intervention</h6>

  <app-header-info [caseId]='caseId'></app-header-info>

  <h4>Équipements installés</h4>

  <mat-form-field>
    <mat-label>Produits</mat-label>
    <mat-select (selectionChange)="onSelectionChange($event)">
      <mat-option *ngFor="let product of kit" [value]="product">{{ product.name }}</mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="kit && kit.length === 0">
    <p>Si vous ne voyez aucun produits, le produit que vous attendiez ne respecte pas la conformité, il faut d'abord
      renseigner les informations
      nécessaires sur la page précédente
    </p>
  </div>

  <div class="pv" *ngIf="selectedProduct?.name === 'Panneaux solaires'">
    <form [formGroup]="formPv" (ngSubmit)="onSubmitPv()">

      <!-- Heures -->
      <div class="row">
        <!-- Heure d'arrivée -->
        <div class="col-lg-5 col-s-6 col-xs-6 custom-col">
          <div class="form-group">
            <label for="heure_arrivee">Heure d'arrivée :</label>
            <div class="d-flex">
              <input type="number" class="form-control" min="0" max="23" formControlName="heureArriveeHeure"
                [ngClass]="formPv.get('heureArriveeHeure').errors?.outOfRange ? 'outOfRange' : ''" placeholder="Heure">
              <input type="number" class="form-control ml-2" min="0" max="59" formControlName="heureArriveeMinute"
                [ngClass]="formPv.get('heureArriveeMinute').errors?.outOfRange ? 'outOfRange' : ''"
                placeholder="Minute">
            </div>

          </div>

        </div>
        <!-- Heure de départ -->
        <div class="col-lg-5 col-s-6 col-xs-6 custom-col">
          <div class="form-group">
            <label for="heure_arrivee">Heure de départ :</label>
            <div class="d-flex">
              <input type="number" class="form-control" min="0" max="23" formControlName="heureDepartHeure"
                [ngClass]="formPv.get('heureDepartHeure').errors?.outOfRange ? 'outOfRange' : ''" placeholder="Heure">

              <input type="number" class="form-control ml-2" min="0" max="59" formControlName="heureDepartMinute"
                [ngClass]="formPv.get('heureDepartMinute').errors?.outOfRange ? 'outOfRange' : ''" placeholder="Minute">

            </div>
          </div>
        </div>
      </div>
      <!-- Nettoyage des panneaux solaires -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Nettoyage des panneaux solaires :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="nettoyagePanneaux">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngIf="formPv.get('nettoyagePanneaux').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_nettoyage_before" *ngIf="!pav_nettoyage_panneaux">+ Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_nettoyage_panneaux">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_nettoyage_panneaux" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_nettoyage_before" id="upload_nettoyage_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant nettoyage panneaux solaires')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_nettoyage_after" *ngIf="!pap_nettoyage_panneaux">+ Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_nettoyage_panneaux">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_nettoyage_panneaux" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_nettoyage_after" id="upload_nettoyage_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après nettoyage panneaux solaires')">
          </div>
        </div>
      </div>

      <!-- Vérification visuelle de l'état des modules sans démontage -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Vérification visuelle de l'état des modules sans démontage :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="etatModules">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngIf="formPv.get('etatModules').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_etatModules_before" *ngIf="!pav_etat_modules">+ Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_etat_modules">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_etat_modules" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_etatModules_before" id="upload_etatModules_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant état des modules')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_etatModules_after" *ngIf="!pap_etat_modules">+ Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_etat_modules">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_etat_modules" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_etatModules_after" id="upload_etatModules_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après état des modules')">
          </div>
        </div>
      </div>

      <!-- Vérification de l'état du système de montage et des abergements -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Vérification de l'état du système de montage et des abergements :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="etatSystemeMontage">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngIf="formPv.get('etatSystemeMontage').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_etatSystemeMontage_before" *ngIf="!pav_systeme_montage">+ Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_systeme_montage">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_systeme_montage" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_etatSystemeMontage_before" id="upload_etatSystemeMontage_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant état du système de montage')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_etatSystemeMontage_after" *ngIf="!pap_systeme_montage">+ Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_systeme_montage">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_systeme_montage" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_etatSystemeMontage_after" id="upload_etatSystemeMontage_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après état du système de montage')">
          </div>
        </div>
      </div>

      <!-- Vérification visuelle de l'absence de trace d’infiltration d’eau sous la structure -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Vérification visuelle de l'absence de trace d’infiltration d’eau sous la structure :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="absenceInfiltration">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngIf="formPv.get('absenceInfiltration').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_absenceInfiltration_before" *ngIf="!pav_absence_infiltration">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_absence_infiltration">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_absence_infiltration" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_absenceInfiltration_before" id="upload_absenceInfiltration_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant absence infiltration')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_absenceInfiltration_after" *ngIf="!pap_absence_infiltration">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_absence_infiltration">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_absence_infiltration" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_absenceInfiltration_after" id="upload_absenceInfiltration_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après absence infiltration')">
          </div>
        </div>
      </div>

      <!-- Vérification des serrages de connexions des câbles AC et DC -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Vérification des serrages de connexions des câbles AC et DC :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="serrageAcDc">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button> </mat-radio-group>
        </div>
        <div *ngIf="formPv.get('serrageAcDc').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_serrageAcDc_before" *ngIf="!pav_serrage_ac_dc">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_serrage_ac_dc">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_serrage_ac_dc" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_serrageAcDc_before" id="upload_serrageAcDc_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant serrage de connexions AC et DC')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_serrageAcDc_after" *ngIf="!pap_serrage_ac_dc">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_serrage_ac_dc">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_serrage_ac_dc" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_serrageAcDc_after" id="upload_serrageAcDc_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après serrage de connexions AC et DC')">
          </div>
        </div>
      </div>

      <!-- Vérification des continuités -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Vérification des continuités :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="v_continuites">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button> </mat-radio-group>
        </div>
        <div *ngIf="formPv.get('v_continuites').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_v_continuites_before" *ngIf="!pav_v_continuites">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_v_continuites">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_v_continuites" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_v_continuites_before" id="upload_v_continuites_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Vérification des continuités')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_v_continuites_after" *ngIf="!pap_v_continuites">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_v_continuites">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_v_continuites" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_v_continuites_after" id="upload_v_continuites_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après Vérification des continuités')">
          </div>
        </div>
      </div>
      <!-- Vérification des tableaux électriques, resserrage des bornes -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Vérification des tableaux électriques, resserrage des bornes :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="v_tableau_electrique">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button> </mat-radio-group>
        </div>
        <div *ngIf="formPv.get('v_tableau_electrique').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_v_tableau_electrique_before" *ngIf="!pav_v_tableau_electrique">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_v_tableau_electrique">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_v_tableau_electrique" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_v_tableau_electrique_before" id="upload_v_tableau_electrique_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant des tableaux électriques, resserrage des bornes')">
          </div>
        </div>
      </div>

      <!-- Nettoyage des filtres et grilles de ventilation des onduleurs (si onduleur) -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Nettoyage des filtres et grilles de ventilation des onduleurs (si onduleur) :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="nettoyage_filtres">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button> </mat-radio-group>
        </div>
        <div *ngIf="formPv.get('nettoyage_filtres').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_nettoyage_filtres_before" *ngIf="!pav_nettoyage_filtres">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_nettoyage_filtres">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_nettoyage_filtres" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_nettoyage_filtres_before" id="upload_nettoyage_filtres_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Nettoyage des filtres et grilles de ventilation des onduleurs (si onduleur)')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_nettoyage_filtres_after" *ngIf="!pap_nettoyage_filtres">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_nettoyage_filtres">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_nettoyage_filtres" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_nettoyage_filtres_after" id="upload_nettoyage_filtres_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après Nettoyage des filtres et grilles de ventilation des onduleurs (si onduleur)')">
          </div>
        </div>
      </div>

      <!-- Vérification de bon usage du local électrique (absence de produit inflammable, atmosphère corrosive et poussiéreuse) -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Vérification de bon usage du local électrique (absence de produit inflammable, atmosphère corrosive
            et
            poussiéreuse)</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="v_usage_local">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button> </mat-radio-group>
        </div>
        <div *ngIf="formPv.get('v_usage_local').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_v_usage_local_before" *ngIf="!pav_v_usage_local">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_v_usage_local">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_v_usage_local" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_v_usage_local_before" id="upload_v_usage_local_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Vérification de bon usage du local électrique (absence de produit inflammable, atmosphère corrosive et poussiéreuse)')">
          </div>
        </div>
      </div>

      <!-- Vérification des parafoudres AC et DC (si existants) -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Vérification des parafoudres AC et DC (si existants)</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="v_parafoudres">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button> </mat-radio-group>
        </div>
        <div *ngIf="formPv.get('v_parafoudres').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_v_parafoudres_before" *ngIf="!pav_v_parafoudres">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_v_parafoudres">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_v_parafoudres" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_v_parafoudres_before" id="upload_v_parafoudres_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Vérification des parafoudres AC et DC (si existants)')">
          </div>
        </div>
      </div>

      <!-- Vérification du fonctionnement sans mode erreur des micros onduleurs ou optimiseur et onduleurs centrale après remise en service -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Vérification du fonctionnement sans mode erreur des micros onduleurs ou optimiseur et onduleurs
            centrale après remise en service :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="v_mode_erreur_onduleur">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button> </mat-radio-group>
        </div>
        <div *ngIf="formPv.get('v_mode_erreur_onduleur').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_v_mode_erreur_onduleur_before" *ngIf="!pap_v_mode_erreur_onduleur">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_v_mode_erreur_onduleur">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_v_mode_erreur_onduleur" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_v_mode_erreur_onduleur_before" id="upload_v_mode_erreur_onduleur_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo après vérification des micros onduleurs')">
          </div>
        </div>
      </div>

      <!-- Vérification du système d’ouverture du tableau électrique  -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Vérification du système d’ouverture du tableau électrique :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="v_systeme_ouverture_tableau">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button> </mat-radio-group>
        </div>
        <div *ngIf="formPv.get('v_systeme_ouverture_tableau').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_v_systeme_ouverture_tableau_before"
              *ngIf="!pav_v_systeme_ouverture_tableau">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_v_systeme_ouverture_tableau">Photo ajoutée <mat-icon>check</mat-icon>
            </p>
            <input *ngIf="!pav_v_systeme_ouverture_tableau" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_v_systeme_ouverture_tableau_before" id="upload_v_systeme_ouverture_tableau_before"
              class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Vérification du système d’ouverture du tableau électrique')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_v_systeme_ouverture_tableau_after"
              *ngIf="!pap_v_systeme_ouverture_tableau">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_v_systeme_ouverture_tableau">Photo ajoutée <mat-icon>check</mat-icon>
            </p>
            <input *ngIf="!pap_v_systeme_ouverture_tableau" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_v_systeme_ouverture_tableau_after" id="upload_v_systeme_ouverture_tableau_after"
              class="upload-box"
              (change)="selectPhotos($event, 'Photo après Vérification du système d’ouverture du tableau électrique')">
          </div>
        </div>
      </div>
      <!-- Vérification du bon fonctionnement du ou des dispositifs de coupure d’urgence  -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Vérification du bon fonctionnement du ou des dispositifs de coupure d’urgence :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="v_coupure_urgence">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button> </mat-radio-group>
        </div>
        <div *ngIf="formPv.get('v_coupure_urgence').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_v_coupure_urgence_before" *ngIf="!pav_v_coupure_urgence">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_v_coupure_urgence">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_v_coupure_urgence" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_v_coupure_urgence_before" id="upload_v_coupure_urgence_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Vérification du bon fonctionnement du ou des dispositifs de coupure d’urgence')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_v_coupure_urgence_after" *ngIf="!pap_v_coupure_urgence">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_v_coupure_urgence">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_v_coupure_urgence" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_v_coupure_urgence_after" id="upload_v_coupure_urgence_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après Vérification du bon fonctionnement du ou des dispositifs de coupure d’urgence')">
          </div>
        </div>
      </div>
      <!-- Remplacement ou pose d'étiquette signalétiques réglementairese  -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Remplacement ou pose d'étiquette signalétiques réglementairese :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="etiquettes">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button> </mat-radio-group>
        </div>
        <div *ngIf="formPv.get('etiquettes').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_etiquettes_before" *ngIf="!pav_etiquettes">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_etiquettes">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_etiquettes" accept=".jpg,.jpeg,.png" type="file" multiple name="upload_etiquettes_before"
              id="upload_etiquettes_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Remplacement ou pose étiquette signalétiques réglementairese')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_etiquettes_after" *ngIf="!pap_etiquettes">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_etiquettes">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_etiquettes" accept=".jpg,.jpeg,.png" type="file" multiple name="upload_etiquettes_after"
              id="upload_etiquettes_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après Remplacement ou pose étiquette signalétiques réglementaires')">
          </div>
        </div>
      </div>
      <!-- Reconnexion passerelle si non connectée à internet  -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Reconnexion passerelle si non connectée à internet :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="reconnexion_passerelle">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button> </mat-radio-group>
        </div>
        <div *ngIf="formPv.get('reconnexion_passerelle').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_reconnexion_passerelle_before" *ngIf="!pav_reconnexion_passerelle">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_reconnexion_passerelle">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_reconnexion_passerelle" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_reconnexion_passerelle_before" id="upload_reconnexion_passerelle_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Reconnexion passerelle si non connectée à interne')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_reconnexion_passerelle_after" *ngIf="!pap_reconnexion_passerelle">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_reconnexion_passerelle">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_reconnexion_passerelle" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_reconnexion_passerelle_after" id="upload_reconnexion_passerelle_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après Reconnexion passerelle si non connectée à interne')">
          </div>
        </div>
      </div>

      <!-- Équipement en bon état  -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Équipement en bon état :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="equipement_ok">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Nouvelle intervention nécessaire :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="nouvelle_inter">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="form-group" *ngIf="formPv.get('nouvelle_inter').value">
        <label for="nouvelle_inter_cause">Cause de la nouvelle intervention :</label>
        <input id="nouvelle_inter_cause" type="text" formControlName="nouvelle_inter_cause" class="form-control">
      </div>

      <div class="form-group">
        <label for="commentaire">Commentaire :</label>
        <textarea id="commentaire" formControlName="commentaire" class="form-control"></textarea>
      </div>

      <button type="submit" class="btn-secondarycrm" [disabled]="formPv.invalid">Soumettre</button>
    </form>
  </div>

  <div class="bt" *ngIf="selectedProduct?.name === 'VMC Double Flux'">
    <form [formGroup]="formVmc" (ngSubmit)="onSubmitVmc()">

      <!-- Heures -->
      <div class="row">
        <!-- Heure d'arrivée -->
        <div class="col-lg-5 col-s-6 col-xs-6 custom-col">
          <div class="form-group">
            <label for="heure_arrivee">Heure d'arrivée :</label>
            <div class="d-flex">
              <input type="number" class="form-control" min="0" max="23" formControlName="heureArriveeHeure"
                [ngClass]="formVmc.get('heureArriveeHeure').errors?.outOfRange ? 'outOfRange' : ''" placeholder="Heure">
              <input type="number" class="form-control ml-2" min="0" max="59" formControlName="heureArriveeMinute"
                [ngClass]="formVmc.get('heureArriveeMinute').errors?.outOfRange ? 'outOfRange' : ''"
                placeholder="Minute">
            </div>

          </div>

        </div>
        <!-- Heure de départ -->
        <div class="col-lg-5 col-s-6 col-xs-6 custom-col">
          <div class="form-group">
            <label for="heure_arrivee">Heure de départ :</label>
            <div class="d-flex">
              <input type="number" class="form-control" min="0" max="23" formControlName="heureDepartHeure"
                [ngClass]="formVmc.get('heureDepartHeure').errors?.outOfRange ? 'outOfRange' : ''" placeholder="Heure">

              <input type="number" class="form-control ml-2" min="0" max="59" formControlName="heureDepartMinute"
                [ngClass]="formVmc.get('heureDepartMinute').errors?.outOfRange ? 'outOfRange' : ''"
                placeholder="Minute">

            </div>
          </div>
        </div>
      </div>

      <!-- Nettoyage effectué -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Nettoyage effectué:</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="nettoyage">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>
        <!-- Photos Nettoyage -->
        <div *ngIf="formVmc.get('nettoyage').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload" *ngIf="!pav_nettoyage">+ Ajouter la photo</label>
            <p class="photo_ajoutee" *ngIf="pav_nettoyage">Photo
              ajoutée <mat-icon>check</mat-icon></p>

            <input *ngIf="!pav_nettoyage" accept=".jpg,.jpeg,.png" type="file" multiple name="upload" id="upload"
              class="upload-box" (change)="selectPhotos($event, 'Photo avant nettoyage VMC')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload" *ngIf="!pap_nettoyage">+ Ajouter la photo</label>
            <p class="photo_ajoutee" *ngIf="pap_nettoyage">Photo
              ajoutée <mat-icon>check</mat-icon></p>

            <input *ngIf="!pap_nettoyage" accept=".jpg,.jpeg,.png" type="file" multiple name="upload" id="upload"
              class="upload-box" (change)="selectPhotos($event, 'Photo après nettoyage VMC')" style="max-width: 75%;">
          </div>
        </div>
      </div>

      <!-- Vérification évacuation des condensats -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Vérification évacuation des condensats:</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="v_evac_condensat">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>

        <!-- Photos Évacuation des condensats -->
        <div *ngIf="formVmc.get('v_evac_condensat').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_evap_before" *ngIf="!pav_evac_condensat">+ Ajouter la photo</label>
            <p class="photo_ajoutee" *ngIf="pav_evac_condensat">Photo ajoutée <mat-icon>check</mat-icon></p>

            <input *ngIf="!pav_evac_condensat" accept=".jpg,.jpeg,.png" type="file" multiple name="upload_evap_before"
              id="upload_evap_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant évacuation condensats')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_evap_after" *ngIf="!pap_evac_condensat">+ Ajouter la photo</label>
            <p class="photo_ajoutee" *ngIf="pap_evac_condensat">Photo ajoutée <mat-icon>check</mat-icon></p>

            <input *ngIf="!pap_evac_condensat" accept=".jpg,.jpeg,.png" type="file" multiple name="upload_evap_after"
              id="upload_evap_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après évacuation condensats')" style="max-width: 75%;">
          </div>
        </div>
      </div>

      <!-- Vérification chapeau de toit -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Vérification chapeau de toit :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="v_chapeau_toit">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>

        <!-- Photos Chapeau de toit -->
        <div *ngIf="formVmc.get('v_chapeau_toit').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_chapeau_before" *ngIf="!pav_chapeau_toit">+ Ajouter la photo</label>
            <p class="photo_ajoutee" *ngIf="pav_chapeau_toit">Photo ajoutée <mat-icon>check</mat-icon></p>

            <input *ngIf="!pav_chapeau_toit" accept=".jpg,.jpeg,.png" type="file" multiple name="upload_chapeau_before"
              id="upload_chapeau_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant chapeau de toit')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_chapeau_after" *ngIf="!pap_chapeau_toit">+ Ajouter la photo</label>
            <p class="photo_ajoutee" *ngIf="pap_chapeau_toit">Photo ajoutée <mat-icon>check</mat-icon></p>

            <input *ngIf="!pap_chapeau_toit" accept=".jpg,.jpeg,.png" type="file" multiple name="upload_chapeau_after"
              id="upload_chapeau_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après chapeau de toit')" style="max-width: 75%;">
          </div>
        </div>
      </div>

      <!--Contrôle alimentation électrique -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Contrôle alimentation électrique :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="c_alim_elec">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>

        <!-- Photos Alimentation électrique -->
        <div *ngIf="formVmc.get('c_alim_elec').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo:</label>
            <label class="upload-label" for="upload_alim_before" *ngIf="!pap_alim_elec">+ Ajouter la photo</label>
            <p class="photo_ajoutee" *ngIf="pap_alim_elec">Photo ajoutée <mat-icon>check</mat-icon></p>

            <input *ngIf="!pap_alim_elec" accept=".jpg,.jpeg,.png" type="file" multiple name="upload_alim_before"
              id="upload_alim_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo après alimentation électrique')">
          </div>
        </div>
      </div>
      <!--Contrôle état des gaines -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Contrôle état des gaines :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="c_etat_gaine">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>

        <!-- Photos État des gaines -->
        <div *ngIf="formVmc.get('c_etat_gaine').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo:</label>
            <label class="upload-label" for="upload_gaine_before" *ngIf="!pap_etat_gaine">+ Ajouter la photo</label>
            <p class="photo_ajoutee" *ngIf="pap_etat_gaine">Photo ajoutée <mat-icon>check</mat-icon></p>

            <input *ngIf="!pap_etat_gaine" accept=".jpg,.jpeg,.png" type="file" multiple name="upload_gaine_before"
              id="upload_gaine_before" class="upload-box" (change)="selectPhotos($event, 'Photo état des gaines')">
          </div>
        </div>
      </div>

      <!-- Vérification piles télécommande -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Vérification piles télécommande :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="v_piles_telecommande">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>

        <!-- Photos Piles télécommande -->
        <div *ngIf="formVmc.get('v_piles_telecommande').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo:</label>
            <label class="upload-label" for="upload_piles_before" *ngIf="!pap_piles_telecommande">+ Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_piles_telecommande">Photo ajoutée <mat-icon>check</mat-icon></p>

            <input *ngIf="!pap_piles_telecommande" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_piles_before" id="upload_piles_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo après piles télécommande')">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Équipement en bon état :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="equipement_ok">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Nouvelle intervention nécessaire :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="nouvelle_inter">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="form-group" *ngIf="formVmc.get('nouvelle_inter').value">
        <label for="nouvelle_inter_cause">Cause de la nouvelle intervention :</label>
        <input id="nouvelle_inter_cause" type="text" formControlName="nouvelle_inter_cause" class="form-control">
      </div>

      <div class="form-group">
        <label for="commentaire">Commentaire :</label>
        <textarea id="commentaire" formControlName="commentaire" class="form-control"></textarea>
      </div>


      <button type="submit" class="btn-secondarycrm" [disabled]="formVmc.invalid">Soumettre</button>

    </form>
  </div>

  <div class="bt" *ngIf="selectedProduct?.name === 'Ballon thermodynamique'">
    <form [formGroup]="formBt" (ngSubmit)="onSubmitBt()">

      <!-- Heures -->
      <div class="row">
        <!-- Heure d'arrivée -->
        <div class="col-lg-5 col-s-6 col-xs-6 custom-col">
          <div class="form-group">
            <label for="heure_arrivee">Heure d'arrivée :</label>
            <div class="d-flex">
              <input type="number" class="form-control" min="0" max="23" formControlName="heureArriveeHeure"
                [ngClass]="formBt.get('heureArriveeHeure').errors?.outOfRange ? 'outOfRange' : ''" placeholder="Heure">
              <input type="number" class="form-control ml-2" min="0" max="59" formControlName="heureArriveeMinute"
                [ngClass]="formBt.get('heureArriveeMinute').errors?.outOfRange ? 'outOfRange' : ''"
                placeholder="Minute">
            </div>
          </div>
        </div>
        <!-- Heure de départ -->
        <div class="col-lg-5 col-s-6 col-xs-6 custom-col">
          <div class="form-group">
            <label for="heure_arrivee">Heure de départ :</label>
            <div class="d-flex">
              <input type="number" class="form-control" min="0" max="23" formControlName="heureDepartHeure"
                [ngClass]="formBt.get('heureDepartHeure').errors?.outOfRange ? 'outOfRange' : ''" placeholder="Heure">

              <input type="number" class="form-control ml-2" min="0" max="59" formControlName="heureDepartMinute"
                [ngClass]="formBt.get('heureDepartMinute').errors?.outOfRange ? 'outOfRange' : ''" placeholder="Minute">
            </div>
          </div>
        </div>
      </div>

      <!-- Contrôle du vase d’expansion (si installé)   -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Contrôle du vase d’expansion (si installé) :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="vaseExpansion">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non </mat-radio-button>

          </mat-radio-group>
        </div>
        <div *ngIf="formBt.get('vaseExpansion').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_vaseExpansion_before" *ngIf="!pav_vaseExpansion">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_vaseExpansion">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_vaseExpansion" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_vaseExpansion_before" id="upload_vaseExpansion_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Contrôle du vase d’expansion (si installé)')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_vaseExpansion_after" *ngIf="!pap_vaseExpansion">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_vaseExpansion">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_vaseExpansion" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_vaseExpansion_after" id="upload_vaseExpansion_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après Contrôle du vase d’expansion (si installé)')">
          </div>
        </div>
      </div>
      <!-- Contrôle du groupe extérieur   -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Contrôle du groupe extérieur :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="groupeExterieur">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button> </mat-radio-group>
        </div>
        <div *ngIf="formBt.get('groupeExterieur').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_groupeExterieur_before" *ngIf="!pav_groupeExterieur">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_groupeExterieur">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_groupeExterieur" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_groupeExterieur_before" id="upload_groupeExterieur_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Contrôle du groupe extérieur')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_groupeExterieur_after" *ngIf="!pap_groupeExterieur">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_groupeExterieur">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_groupeExterieur" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_groupeExterieur_after" id="upload_groupeExterieur_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après Contrôle du groupe extérieur')">
          </div>
        </div>
      </div>
      <!-- Contrôle et/ou changement du joint de bride   -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Contrôle et/ou changement du joint de bride :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="jointBride">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button> </mat-radio-group>
        </div>
        <div *ngIf="formBt.get('jointBride').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_jointBride_before" *ngIf="!pav_jointBride">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_jointBride">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_jointBride" accept=".jpg,.jpeg,.png" type="file" multiple name="upload_jointBride_before"
              id="upload_jointBride_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Contrôle et/ou changement du joint de bride')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_jointBride_after" *ngIf="!pap_jointBride">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_jointBride">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_jointBride" accept=".jpg,.jpeg,.png" type="file" multiple name="upload_jointBride_after"
              id="upload_jointBride_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après Contrôle et/ou changement du joint de bride')">
          </div>
        </div>
      </div>
      <!-- Contrôle des paramétrages    -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Contrôle des paramétrages :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="parametrages">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button> </mat-radio-group>
        </div>
        <div *ngIf="formBt.get('parametrages').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_parametrages_before" *ngIf="!pav_parametrages">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_parametrages">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_parametrages" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_parametrages_before" id="upload_parametrages_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Contrôle des paramétrages')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_parametrages_after" *ngIf="!pap_parametrages">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_parametrages">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_parametrages" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_parametrages_after" id="upload_parametrages_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après Contrôle des paramétrages')">
          </div>
        </div>
      </div>
      <!-- Contrôle de l’alimentation et resserrage des connexion    -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Contrôle de l’alimentation et resserrage des connexion :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="alimentationConnexions">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button> </mat-radio-group>
        </div>
        <div *ngIf="formBt.get('alimentationConnexions').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_alimentationConnexions_before" *ngIf="!pav_alimentationConnexions">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_alimentationConnexions">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_alimentationConnexions" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_alimentationConnexions_before" id="upload_alimentationConnexions_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Contrôle de l’alimentation et resserrage des connexion')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_alimentationConnexions_after" *ngIf="!pap_alimentationConnexions">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_alimentationConnexions">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_alimentationConnexions" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_alimentationConnexions_after" id="upload_alimentationConnexions_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après Contrôle de l’alimentation et resserrage des connexion')">
          </div>
        </div>
      </div>
      <!-- Contrôle de fuite au niveau des raccordements    -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Contrôle de fuite au niveau des raccordements :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="fuiteRaccordements">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button> </mat-radio-group>
        </div>
        <div *ngIf="formBt.get('fuiteRaccordements').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:uiteRaccordements0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_fuiteRaccordements_after" *ngIf="!pap_fuiteRaccordements">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_fuiteRaccordements">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_fuiteRaccordements" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_fuiteRaccordements_after" id="upload_fuiteRaccordements_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après Contrôle de fuite au niveau des raccordements')">
          </div>
        </div>
      </div>
      <!-- Inspection des robinets et des canalisations (humidité et rouille)    -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Inspection des robinets et des canalisations (humidité et rouille) :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="inspectionRobinets">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button> </mat-radio-group>
        </div>
        <div *ngIf="formBt.get('inspectionRobinets').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_inspectionRobinets_after" *ngIf="!pap_inspectionRobinets">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_inspectionRobinets">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_inspectionRobinets" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_inspectionRobinets_after" id="upload_inspectionRobinets_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après Inspection des robinets et des canalisations (humidité et rouille)')">
          </div>
        </div>
      </div>
      <!-- Peigner l'échangeur de l’UE    -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Peigner l'échangeur de l’UE :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="peignerEchangeur">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button> </mat-radio-group>
        </div>
        <div *ngIf="formBt.get('peignerEchangeur').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_peignerEchangeur_before" *ngIf="!pav_peignerEchangeur">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_peignerEchangeur">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_peignerEchangeur" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_peignerEchangeur_before" id="upload_peignerEchangeur_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Peigner échangeur de UE')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_peignerEchangeur_after" *ngIf="!pap_peignerEchangeur">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_peignerEchangeur">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_peignerEchangeur" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_peignerEchangeur_after" id="upload_peignerEchangeur_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après Peigner échangeur UE')">
          </div>
        </div>
      </div>
      <!-- Nettoyage de l’UE avec une bombe  -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Nettoyage de l’UE avec une bombe :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="nettoyageUE">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button> </mat-radio-group>
        </div>
        <div *ngIf="formBt.get('nettoyageUE').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_nettoyageUE_before" *ngIf="!pav_nettoyageUE">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_nettoyageUE">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_nettoyageUE" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_nettoyageUE_before" id="upload_nettoyageUE_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Nettoyage de l’UE avec une bombe')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_nettoyageUE_after" *ngIf="!pap_nettoyageUE">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_nettoyageUE">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_nettoyageUE" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_nettoyageUE_after" id="upload_nettoyageUE_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après Nettoyage de l’UE avec une bombe')">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Équipement en bon état :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="equipement_ok">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Nouvelle intervention nécessaire :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="nouvelle_inter">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Mise à jour de l’attestation de fluide frigorigène :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="maj_fluide">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- Cause de la nouvelle intervention  -->
      <div class="form-group" *ngIf="formBt.get('nouvelle_inter').value">
        <label for="nouvelle_inter_cause">Cause de la nouvelle intervention :</label>
        <input id="nouvelle_inter_cause" type="text" formControlName="nouvelle_inter_cause" class="form-control">
      </div>

      <div class="form-group">
        <label for="commentaire">Commentaire :</label>
        <textarea id="commentaire" formControlName="commentaire" class="form-control"></textarea>
      </div>


      <button type="submit" class="btn-secondarycrm" [disabled]="formBt.invalid">Soumettre</button>
    </form>
  </div>


  <div class="pacaa" *ngIf="selectedProduct?.name === 'PAC Air/Air'">
    <form [formGroup]="formPacaa" (ngSubmit)="onSubmitPacaa()">

      <!-- Heures -->
      <div class="row">
        <!-- Heure d'arrivée -->
        <div class="col-lg-5 col-s-6 col-xs-6 custom-col">
          <div class="form-group">
            <label for="heure_arrivee">Heure d'arrivée :</label>
            <div class="d-flex">
              <input type="number" class="form-control" min="0" max="23" formControlName="heureArriveeHeure"
                [ngClass]="formPacaa.get('heureArriveeHeure').errors?.outOfRange ? 'outOfRange' : ''"
                placeholder="Heure">
              <input type="number" class="form-control ml-2" min="0" max="59" formControlName="heureArriveeMinute"
                [ngClass]="formPacaa.get('heureArriveeMinute').errors?.outOfRange ? 'outOfRange' : ''"
                placeholder="Minute">
            </div>

          </div>

        </div>
        <!-- Heure de départ -->
        <div class="col-lg-5 col-s-6 col-xs-6 custom-col">
          <div class="form-group">
            <label for="heure_arrivee">Heure de départ :</label>
            <div class="d-flex">
              <input type="number" class="form-control" min="0" max="23" formControlName="heureDepartHeure"
                [ngClass]="formPacaa.get('heureDepartHeure').errors?.outOfRange ? 'outOfRange' : ''"
                placeholder="Heure">

              <input type="number" class="form-control ml-2" min="0" max="59" formControlName="heureDepartMinute"
                [ngClass]="formPacaa.get('heureDepartMinute').errors?.outOfRange ? 'outOfRange' : ''"
                placeholder="Minute">

            </div>
          </div>
        </div>
      </div>

      <!-- Verification et controle général des unites interieures et extérieures -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Verification et controle général des unites interieures et extérieures :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="v_general_ui_ue">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non </mat-radio-button>

          </mat-radio-group>
        </div>
        <div *ngIf="formPacaa.get('v_general_ui_ue').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_v_general_ui_ue_before" *ngIf="!pav_v_general_ui_ue">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_v_general_ui_ue">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_v_general_ui_ue" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_v_general_ui_ue_before" id="upload_v_general_ui_ue_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Verification et controle général des unites interieures et extérieures')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_v_general_ui_ue_after" *ngIf="!pap_v_general_ui_ue">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_v_general_ui_ue">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_v_general_ui_ue" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_v_general_ui_ue_after" id="upload_v_general_ui_ue_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après Verification et controle général des unites interieures et extérieures')">
          </div>
        </div>
      </div>

      <!-- Vérification des fixations et supports -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Vérification des fixations et supports :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="v_fixations">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non </mat-radio-button>

          </mat-radio-group>
        </div>
        <div *ngIf="formPacaa.get('v_fixations').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_v_fixations_before" *ngIf="!pav_v_fixations">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_v_fixations">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_v_fixations" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_v_fixations_before" id="upload_v_fixations_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Vérification des fixations et supports')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_v_fixations_after" *ngIf="!pap_v_fixations">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_v_fixations">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_v_fixations" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_v_fixations_after" id="upload_v_fixations_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après Vérification des fixations et supports')">
          </div>
        </div>
      </div>

      <!-- Remplacement des piles télécommande -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Remplacement des piles télécommande :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="remplacement_piles">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <!-- Contrôle du condenseur et du filtre (nettoyage si nécessaire) -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Contrôle du condenseur et du filtre (nettoyage si nécessaire) :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="c_condenseur_filtre">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non </mat-radio-button>

          </mat-radio-group>
        </div>
      </div>
      <!-- Vérification fonctionnelle du dispositif d’inversion cycle -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Vérification fonctionnelle du dispositif d’inversion cycle :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="v_inversion_cycle">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non </mat-radio-button>

          </mat-radio-group>
        </div>
      </div>
      <!-- Contrôle d'étanchéité des circuits frigorifique -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Contrôle d'étanchéité des circuits frigorifique :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="c_etancheite_circuit_frigorifique">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- Verification de l’etat et de la nature des liaisons frigorifiques -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Verification de l’etat et de la nature des liaisons frigorifiques :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="v_etat_liaison_frigorifique">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non </mat-radio-button>

          </mat-radio-group>
        </div>
        <div *ngIf="formPacaa.get('v_etat_liaison_frigorifique').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_v_etat_liaison_frigorifique_before"
              *ngIf="!pav_v_etat_liaison_frigorifique">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_v_etat_liaison_frigorifique">Photo ajoutée <mat-icon>check</mat-icon>
            </p>
            <input *ngIf="!pav_v_etat_liaison_frigorifique" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_v_etat_liaison_frigorifique_before" id="upload_v_etat_liaison_frigorifique_before"
              class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Verification de l’etat et de la nature des liaisons frigorifiques')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_v_etat_liaison_frigorifique_after"
              *ngIf="!pap_v_etat_liaison_frigorifique">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_v_etat_liaison_frigorifique">Photo ajoutée <mat-icon>check</mat-icon>
            </p>
            <input *ngIf="!pap_v_etat_liaison_frigorifique" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_v_etat_liaison_frigorifique_after" id="upload_v_etat_liaison_frigorifique_after"
              class="upload-box"
              (change)="selectPhotos($event, 'Photo après Verification de l’etat et de la nature des liaisons frigorifiques')">
          </div>
        </div>
      </div>
      <!-- Dépoussiérage et nettoyage des unités interieures et exterieures -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Dépoussiérage et nettoyage des unités interieures et exterieures :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="depoussierage_ui_ue">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non </mat-radio-button>

          </mat-radio-group>
        </div>
        <div *ngIf="formPacaa.get('depoussierage_ui_ue').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_depoussierage_ui_ue_before" *ngIf="!pav_depoussierage_ui_ue">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_depoussierage_ui_ue">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_depoussierage_ui_ue" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_depoussierage_ui_ue_before" id="upload_depoussierage_ui_ue_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Dépoussiérage et nettoyage des unités interieures et exterieures')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_depoussierage_ui_ue_after" *ngIf="!pap_depoussierage_ui_ue">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_depoussierage_ui_ue">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_depoussierage_ui_ue" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_depoussierage_ui_ue_after" id="upload_depoussierage_ui_ue_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après Dépoussiérage et nettoyage des unités interieures et exterieures')">
          </div>
        </div>
      </div>
      <!-- Contrôle et nettoyages des filtres des unités intérieures (voir remplacement) -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Contrôle et nettoyages des filtres des unités intérieures (voir remplacement) :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="nettoyage_filtre_ui">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non </mat-radio-button>

          </mat-radio-group>
        </div>
        <div *ngIf="formPacaa.get('nettoyage_filtre_ui').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_nettoyage_filtre_ui_before" *ngIf="!pav_nettoyage_filtre_ui">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_nettoyage_filtre_ui">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_nettoyage_filtre_ui" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_nettoyage_filtre_ui_before" id="upload_nettoyage_filtre_ui_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Contrôle et nettoyages des filtres des unités intérieures (voir remplacement)')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_nettoyage_filtre_ui_after" *ngIf="!pap_nettoyage_filtre_ui">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_nettoyage_filtre_ui">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_nettoyage_filtre_ui" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_nettoyage_filtre_ui_after" id="upload_nettoyage_filtre_ui_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après Contrôle et nettoyages des filtres des unités intérieures (voir remplacement)')">
          </div>
        </div>
      </div>
      <!-- Vérification de l'écoulement des eaux de condensats et nettoyage du bac à condensats -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Vérification de l'écoulement des eaux de condensats et nettoyage du bac à condensats :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="v_ecoulement_eau_nettoyage_bac_condensat">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- Désinfection des évaporateurs et des filtres (désinfectant bactéricides) -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Désinfection des évaporateurs et des filtres (désinfectant bactéricides) :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="desinfection_evapo_filtre">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non </mat-radio-button>

          </mat-radio-group>
        </div>
        <div *ngIf="formPacaa.get('desinfection_evapo_filtre').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_desinfection_evapo_filtre_before"
              *ngIf="!pav_desinfection_evapo_filtre">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_desinfection_evapo_filtre">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_desinfection_evapo_filtre" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_desinfection_evapo_filtre_before" id="upload_desinfection_evapo_filtre_before"
              class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Désinfection des évaporateurs et des filtres (désinfectant bactéricides)')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_desinfection_evapo_filtre_after"
              *ngIf="!pap_desinfection_evapo_filtre">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_desinfection_evapo_filtre">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_desinfection_evapo_filtre" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_desinfection_evapo_filtre_after" id="upload_desinfection_evapo_filtre_after"
              class="upload-box"
              (change)="selectPhotos($event, 'Photo après Désinfection des évaporateurs et des filtres (désinfectant bactéricides)')">
          </div>
        </div>
      </div>

      <!-- Contrôle de l'évaporateur et du filtre (Nettoyage si nécessaire) -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Contrôle de l'évaporateur et du filtre (Nettoyage si nécessaire) :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="c_evaporateur_filtre">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non </mat-radio-button>

          </mat-radio-group>
        </div>
        <div *ngIf="formPacaa.get('c_evaporateur_filtre').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_c_evaporateur_filtre_before" *ngIf="!pav_c_evaporateur_filtre">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_c_evaporateur_filtre">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_c_evaporateur_filtre" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_c_evaporateur_filtre_before" id="upload_c_evaporateur_filtre_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Contrôle de l\'évaporateur et du filtre (Nettoyage si nécessaire)')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_c_evaporateur_filtre_after" *ngIf="!pap_c_evaporateur_filtre">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_c_evaporateur_filtre">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_c_evaporateur_filtre" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_c_evaporateur_filtre_after" id="upload_c_evaporateur_filtre_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après Contrôle de l\'évaporateur et du filtre (Nettoyage si nécessaire)')">
          </div>
        </div>
      </div>



      <!-- Peigner l’echangeur -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Peigner l’echangeur :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="peignerEchangeur_pacaa">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non </mat-radio-button>

          </mat-radio-group>
        </div>
        <div *ngIf="formPacaa.get('peignerEchangeur_pacaa').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_peignerEchangeur_pacaa_before" *ngIf="!pav_peignerEchangeur_pacaa">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_peignerEchangeur_pacaa">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_peignerEchangeur_pacaa" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_peignerEchangeur_pacaa_before" id="upload_peignerEchangeur_pacaa_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Peigner l’echangeur')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_peignerEchangeur_pacaa_after" *ngIf="!pap_peignerEchangeur_pacaa">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_peignerEchangeur_pacaa">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_peignerEchangeur_pacaa" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_peignerEchangeur_pacaa_after" id="upload_peignerEchangeur_pacaa_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après Peigner l’echangeur')">
          </div>
        </div>
      </div>
      <!-- Équipement en bon état -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Équipement en bon état :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="equipement_ok">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Nouvelle intervention nécessaire :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="nouvelle_inter">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <!-- Mise à jour de l’attestation de fluide frigorigène  -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Mise à jour de l’attestation de fluide frigorigène :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="maj_fluide">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- Cause de la nouvelle intervention  -->
      <div class="form-group" *ngIf="formPacaa.get('nouvelle_inter').value">
        <label for="nouvelle_inter_cause">Cause de la nouvelle intervention :</label>
        <input id="nouvelle_inter_cause" type="text" formControlName="nouvelle_inter_cause" class="form-control">
      </div>

      <div class="form-group">
        <label for="commentaire">Commentaire :</label>
        <textarea id="commentaire" formControlName="commentaire" class="form-control"></textarea>
      </div>

      <button type="submit" class="btn-secondarycrm" [disabled]="formPacaa.invalid">Soumettre</button>
    </form>

  </div>

  <div class="pacae" *ngIf="selectedProduct?.name === 'PAC Air/Eau'">
    <form [formGroup]="formPacae" (ngSubmit)="onSubmitPacae()">

      <!-- Heures -->
      <div class="row">
        <!-- Heure d'arrivée -->
        <div class="col-lg-5 col-s-6 col-xs-6 custom-col">
          <div class="form-group">
            <label for="heure_arrivee">Heure d'arrivée :</label>
            <div class="d-flex">
              <input type="number" class="form-control" min="0" max="23" formControlName="heureArriveeHeure"
                [ngClass]="formPacae.get('heureArriveeHeure').errors?.outOfRange ? 'outOfRange' : ''"
                placeholder="Heure">
              <input type="number" class="form-control ml-2" min="0" max="59" formControlName="heureArriveeMinute"
                [ngClass]="formPacae.get('heureArriveeMinute').errors?.outOfRange ? 'outOfRange' : ''"
                placeholder="Minute">
            </div>

          </div>

        </div>
        <!-- Heure de départ -->
        <div class="col-lg-5 col-s-6 col-xs-6 custom-col">
          <div class="form-group">
            <label for="heure_arrivee">Heure de départ :</label>
            <div class="d-flex">
              <input type="number" class="form-control" min="0" max="23" formControlName="heureDepartHeure"
                [ngClass]="formPacae.get('heureDepartHeure').errors?.outOfRange ? 'outOfRange' : ''"
                placeholder="Heure">

              <input type="number" class="form-control ml-2" min="0" max="59" formControlName="heureDepartMinute"
                [ngClass]="formPacae.get('heureDepartMinute').errors?.outOfRange ? 'outOfRange' : ''"
                placeholder="Minute">

            </div>
          </div>
        </div>
      </div>

      <!-- Contrôle d'étanchéité du circuit frigorifique -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Contrôle d'étanchéité du circuit frigorifique :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="circuitFrigorifique">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- Contrôle des tensions unité intérieure / unité extérieure -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Contrôle des tensions unité intérieure / unité extérieure :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="tensionsUnite">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non </mat-radio-button>

          </mat-radio-group>
        </div>
        <div *ngIf="formPacae.get('tensionsUnite').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_tensionsUnite_before" *ngIf="!pav_tensionsUnite">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_tensionsUnite">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_tensionsUnite" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_tensionsUnite_before" id="upload_tensionsUnite_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Contrôle des tensions unité intérieure / unité extérieure')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_tensionsUnite_after" *ngIf="!pap_tensionsUnite">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_tensionsUnite">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_tensionsUnite" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_tensionsUnite_after" id="upload_tensionsUnite_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après Contrôle des tensions unité intérieure / unité extérieure')">
          </div>
        </div>
      </div>
      <!-- Nettoyage des filtres à tamis et/ou pot à boue (si installé) -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Nettoyage des filtres à tamis et/ou pot à boue
            (si installé) :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="nettoyageFiltres">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non </mat-radio-button>

          </mat-radio-group>
        </div>
        <div *ngIf="formPacae.get('nettoyageFiltres').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_nettoyageFiltres_before" *ngIf="!pav_nettoyageFiltres">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_nettoyageFiltres">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_nettoyageFiltres" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_nettoyageFiltres_before" id="upload_nettoyageFiltres_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Nettoyage des filtres à tamis et/ou pot à boue (si installé)')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_nettoyageFiltres_after" *ngIf="!pap_nettoyageFiltres">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_nettoyageFiltres">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_nettoyageFiltres" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_nettoyageFiltres_after" id="upload_nettoyageFiltres_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après Nettoyage des filtres à tamis et/ou pot à boue (si installé)')">
          </div>
        </div>
      </div>

      <!-- Contrôle des paramètres des télécommandes -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Contrôle des paramètres des télécommandes :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="parametresTelecommandes">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>


      <!-- Nettoyage des échangeurs unité intérieure / unité extérieure -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Nettoyage des échangeurs unité intérieure / unité extérieure :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="nettoyageEchangeurs">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non </mat-radio-button>

          </mat-radio-group>
        </div>
        <div *ngIf="formPacae.get('nettoyageEchangeurs').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_nettoyageEchangeurs_before" *ngIf="!pav_nettoyageEchangeurs">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_nettoyageEchangeurs">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_nettoyageEchangeurs" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_nettoyageEchangeurs_before" id="upload_nettoyageEchangeurs_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Nettoyage des échangeurs unité intérieure / unité extérieure')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_nettoyageEchangeurs_after" *ngIf="!pap_nettoyageEchangeurs">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_nettoyageEchangeurs">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_nettoyageEchangeurs" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_nettoyageEchangeurs_after" id="upload_nettoyageEchangeurs_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après Nettoyage des échangeurs unité intérieure / unité extérieure')">
          </div>
        </div>
      </div>

      <!-- Verification de l’etat et de la nature des liaisons frigorifiques -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Verification de l’etat et de la nature des liaisons frigorifiques :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="verificationLiaisons">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non </mat-radio-button>

          </mat-radio-group>
        </div>
        <div *ngIf="formPacae.get('verificationLiaisons').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_verificationLiaisons_before" *ngIf="!pav_verificationLiaisons">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_verificationLiaisons">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_verificationLiaisons" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_verificationLiaisons_before" id="upload_verificationLiaisons_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Verification de l’etat et de la nature des liaisons frigorifiques')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_verificationLiaisons_after" *ngIf="!pap_verificationLiaisons">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_verificationLiaisons">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_verificationLiaisons" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_verificationLiaisons_after" id="upload_verificationLiaisons_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après Verification de l’etat et de la nature des liaisons frigorifiques')">
          </div>
        </div>
      </div>

      <!-- Vérification du module hydraulique -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Vérification du module hydraulique :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="moduleHydraulique">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <!-- Contrôle de la pression et purge des radiateurs -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Contrôle de la pression et purge des radiateurs :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="pressionPurge">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non </mat-radio-button>

          </mat-radio-group>
        </div>
        <div *ngIf="formPacae.get('pressionPurge').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_pressionPurge_before" *ngIf="!pav_pressionPurge">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_pressionPurge">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_pressionPurge" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_pressionPurge_before" id="upload_pressionPurge_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Contrôle de la pression et purge des radiateurs')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_pressionPurge_after" *ngIf="!pap_pressionPurge">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_pressionPurge">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_pressionPurge" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_pressionPurge_after" id="upload_pressionPurge_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après Contrôle de la pression et purge des radiateurs')">
          </div>
        </div>
      </div>

      <!-- Vérification fuite -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Vérification fuite :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="verificationFuite">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non </mat-radio-button>

          </mat-radio-group>
        </div>
        <div *ngIf="formPacae.get('verificationFuite').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_verificationFuite_before" *ngIf="!pav_verificationFuite">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_verificationFuite">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_verificationFuite" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_verificationFuite_before" id="upload_verificationFuite_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Vérification fuite')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_verificationFuite_after" *ngIf="!pap_verificationFuite">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_verificationFuite">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_verificationFuite" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_verificationFuite_after" id="upload_verificationFuite_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après Vérification fuite')">
          </div>
        </div>
      </div>

      <!-- Peigner l’echangeur -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Peigner l’echangeur :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="peignerEchangeur">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non </mat-radio-button>

          </mat-radio-group>
        </div>
        <div *ngIf="formPacae.get('peignerEchangeur').value" class="col-lg-5 col-s-6 col-xs-6 custom-col"
          style="display: flex; padding-left: 0;padding-top:0.5rem;">
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo avant:</label>
            <label class="upload-label" for="upload_peignerEchangeur_before" *ngIf="!pav_peignerEchangeur_pacae">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pav_peignerEchangeur_pacae">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pav_peignerEchangeur_pacae" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_peignerEchangeur_before" id="upload_peignerEchangeur_before" class="upload-box"
              (change)="selectPhotos($event, 'Photo avant Peigner l’echangeur')">
          </div>
          <div class="col-lg-6 col-sm-6 custom-col radio-choice" style="margin: 0;">
            <label>Photo après:</label>
            <label class="upload-label" for="upload_peignerEchangeur_after" *ngIf="!pap_peignerEchangeur_pacae">+
              Ajouter la
              photo</label>
            <p class="photo_ajoutee" *ngIf="pap_peignerEchangeur_pacae">Photo ajoutée <mat-icon>check</mat-icon></p>
            <input *ngIf="!pap_peignerEchangeur_pacae" accept=".jpg,.jpeg,.png" type="file" multiple
              name="upload_peignerEchangeur_after" id="upload_peignerEchangeur_after" class="upload-box"
              (change)="selectPhotos($event, 'Photo après Peigner l’echangeur')">
          </div>
        </div>
      </div>
      <!-- Équipement en bon état -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Équipement en bon état :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="equipement_ok">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Nouvelle intervention nécessaire :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="nouvelle_inter">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <!-- Mise à jour de l’attestation de fluide frigorigène  -->
      <div class="row">
        <div class="col-lg-7 col-s-6 col-xs-6 custom-col radio-choice">
          <mat-label>Mise à jour de l’attestation de fluide frigorigène :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="maj_fluide">
            <mat-radio-button [value]="true">Oui</mat-radio-button>
            <mat-radio-button [value]="false" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- Cause de la nouvelle intervention  -->
      <div class="form-group" *ngIf="formPacae.get('nouvelle_inter').value">
        <label for="nouvelle_inter_cause">Cause de la nouvelle intervention :</label>
        <input id="nouvelle_inter_cause" type="text" formControlName="nouvelle_inter_cause" class="form-control">
      </div>

      <div class="form-group">
        <label for="commentaire">Commentaire :</label>
        <textarea id="commentaire" formControlName="commentaire" class="form-control"></textarea>
      </div>

      <button type="submit" class="btn-secondarycrm" [disabled]="formPacae.invalid">Soumettre</button>
    </form>
  </div>
</div>

<app-loading *ngIf="loader"></app-loading>