import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ModalPrevisualizeComponent } from '@app/pv/modal-previsualize/modal-previsualize.component';
import { NoModalComponent } from '@app/pv/no-modal/no-modal.component';
import { CasesService } from '@app/services/cases.service';
import { ChantierService } from '@app/services/chantier.service';
import { PdfsService } from '@app/services/pdfs.service';
import { PiecesService } from '@app/services/pieces.service';

@Component({
  selector: 'app-recette-pv-micro-onduleur',
  templateUrl: './recette-pv-micro-onduleur.component.html',
  styleUrls: ['./recette-pv-micro-onduleur.component.scss']
})
export class RecettePvMicroOnduleurComponent {

  @Input() currentKit;
  @Input() case;
  @Input() chantierId
  @Input()
  public data: any

  @Output()
  public onData: EventEmitter<any> = new EventEmitter<any>();

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourFormGroup: FormGroup;
  surimpo = false
  sol = false
  facade = false
  integration = false
  poses_pv = ["Sol","Façade","Intégration","Sur imposition"]
  orientations = ["Portrait","Paysage"]

  champ_photo = false
  toiture = false
  coffret_acdc = false
  pieces = false
  coffret_secu = false
  onduleur = false

  mobile = false

  multipleImagesPieces
  lastChantier
  _step: number = 1;

  createDoc
  loader
  recetteChoice
  @Input()
  isValid = false

  piecesNserie
  piece_serie_pv
  n_serie_pv

  piece_serie_ondu
  n_serie_ondu

  set step(_step: number) {
    this._step = this.parseNumber(_step);
  }
  private parseNumber(num: any): number {
    return +num;
  }
  ngAfterContentInit() {
    this.updateData(25)
    this.verifyForm()
    this.checkPieceNSerie()

    this.secondFormGroup = this._formBuilder.group({
      marque_type_onduleur: [this.currentKit.marque_type_onduleur, Validators.required],
      compteur_injection: [this.currentKit.compteur_injection, Validators.required],
      compteur_sous_tirage: [this.currentKit.compteur_sous_tirage, Validators.required],
      type_relais: [this.currentKit.type_relais, Validators.required],
      nbr_relais: [this.currentKit.nbr_relais],
      hauteur_coffret: [this.currentKit.hauteur_coffret, Validators.required],
      parafoudre: [this.currentKit.parafoudre, Validators.required],
      coupure_ac: [this.currentKit.coupure_ac, Validators.required],
      signalement_reglementaire: [this.currentKit.signalement_reglementaire, Validators.required],
      inter_diff: [this.currentKit.inter_diff, Validators.required],
      continuite_terre: [this.currentKit.continuite_terre, Validators.required],
      disjoncteur: [this.currentKit.disjoncteur, Validators.required],

    });

    this.thirdFormGroup = this._formBuilder.group({
      raccordement_electrique: [this.currentKit.raccordement_electrique, Validators.required],
      connexion: [this.currentKit.connexion, Validators.required],
      proximite_tableau: [this.currentKit.proximite_tableau, Validators.required],
      site: [this.currentKit.site, Validators.required],
      monitoring_internet: [this.currentKit.monitoring_internet, Validators.required],
      compte_client: [this.currentKit.compte_client, Validators.required],
    });

    this.fourFormGroup = this._formBuilder.group({
      nettoyage_champs: [this.currentKit.nettoyage_champs, Validators.required],
      documentation: [this.currentKit.documentation, Validators.required],
      signalisation_agcp: [this.currentKit.signalisation_agcp, Validators.required],
      signalisation: [this.currentKit.signalisation, Validators.required],
      autoconso_coupure: [this.currentKit.autoconso_coupure, Validators.required],
      nettoyage: [this.currentKit.nettoyage, Validators.required],
    });
    setTimeout(() => {
      this.getLastChantier()
    }, 2000);
  }

  constructor(
    private _formBuilder: FormBuilder,
    private sCases: CasesService,
    private dialog:MatDialog,
    private sPdfs: PdfsService,
    private sPieces: PiecesService,
    private sChantiers: ChantierService
  ) {
    if (window.screen.width <= 1100) {
      this.mobile = true;
    }
   }

  updateData(data) {
    this.onData.emit(data)
  }

  getLastChantier(){
    this.sChantiers.getLastChantier(this.case._id).subscribe(res => {
      this.lastChantier = res[0];
      this.checkPieceOfCible()
    })
  }
  verifyForm() {
    if(this.currentKit.pose === 'Sur imposition') {
      this.initSurImpositionForm()
      this.surimpo = true
      this.sol = false
      this.facade = false
      this.integration = false
    }
    if(this.currentKit.pose === 'Intégration') {
      this.initIntegrationsitionForm()
      this.integration = true
      this.surimpo = false
      this.sol = false
      this.facade = false
    }
    if(this.currentKit.pose === 'Sol') {
      this.initSolForm()
      this.sol = true
      this.facade = false
      this.surimpo = false
      this.integration = false
    }
    if(this.currentKit.pose === 'Façade') {
      this.initFacadeForm()
      this.facade = true
      this.sol = false
      this.surimpo = false
      this.integration = false
    }

  }
  initIntegrationsitionForm(): void {
    this.firstFormGroup = this._formBuilder.group({
      marque_type: [this.currentKit.marque_type, Validators.required],
      pose: [this.currentKit.pose, Validators.required],
      orientation: [this.currentKit.orientation, Validators.required],
      declivite: [this.currentKit.declivite, Validators.required],
      traverse_toiture: [this.currentKit.traverse_toiture, Validators.required],
      etancheite_abergement_g: [this.currentKit.etancheite_abergement_g, Validators.required],
      etancheite_superieure: [this.currentKit.etancheite_superieure, Validators.required],
      etancheite_abergement_d: [this.currentKit.etancheite_abergement_d, Validators.required],
      etancheite_inferieure: [this.currentKit.etancheite_inferieure, Validators.required],
      etancheite_inter: [this.currentKit.etancheite_inter, Validators.required],
      inferieure_champ: [this.currentKit.inferieure_champ, Validators.required],
      ecran_sous_toiture: [this.currentKit.ecran_sous_toiture, Validators.required],
      etancheite_arrosage: [this.currentKit.etancheite_arrosage, Validators.required],
     });
  }

  initSolForm(): void {
    this.firstFormGroup = this._formBuilder.group({
      marque_type: [this.currentKit.marque_type, Validators.required],
      pose: [this.currentKit.pose, Validators.required],
      orientation: [this.currentKit.orientation, Validators.required],
      declivite: [this.currentKit.declivite, Validators.required],
      ancrage: [this.currentKit.ancrage, Validators.required],
      traverse_toiture: [this.currentKit.traverse_toiture, Validators.required],
      etancheite_abergement_g: [this.currentKit.etancheite_abergement_g, Validators.required],
      etancheite_superieure: [this.currentKit.etancheite_superieure, Validators.required],
      etancheite_abergement_d: [this.currentKit.etancheite_abergement_d, Validators.required],
      etancheite_inferieure: [this.currentKit.etancheite_inferieure, Validators.required],
      etancheite_inter: [this.currentKit.etancheite_inter, Validators.required],
      inferieure_champ: [this.currentKit.inferieure_champ, Validators.required],
      ecran_sous_toiture: [this.currentKit.ecran_sous_toiture, Validators.required],
      etancheite_arrosage: [this.currentKit.etancheite_arrosage, Validators.required],
     });
  }
  initFacadeForm(): void {
    this.firstFormGroup = this._formBuilder.group({
      marque_type: [this.currentKit.marque_type, Validators.required],
      pose: [this.currentKit.pose, Validators.required],
      orientation: [this.currentKit.orientation, Validators.required],
      declivite: [this.currentKit.declivite, Validators.required],
      brise_soleil: [this.currentKit.brise_soleil, Validators.required],
      chassis: [this.currentKit.chassis, Validators.required],
      traverse_toiture: [this.currentKit.traverse_toiture, Validators.required],
      etancheite_abergement_g: [this.currentKit.etancheite_abergement_g, Validators.required],
      etancheite_superieure: [this.currentKit.etancheite_superieure, Validators.required],
      etancheite_abergement_d: [this.currentKit.etancheite_abergement_d, Validators.required],
      etancheite_inferieure: [this.currentKit.etancheite_inferieure, Validators.required],
      etancheite_inter: [this.currentKit.etancheite_inter, Validators.required],
      inferieure_champ: [this.currentKit.inferieure_champ, Validators.required],
      ecran_sous_toiture: [this.currentKit.ecran_sous_toiture, Validators.required],
      etancheite_arrosage: [this.currentKit.etancheite_arrosage, Validators.required],
     });
  }

  initSurImpositionForm(): void {
    this.firstFormGroup = this._formBuilder.group({
      marque_type: [this.currentKit.marque_type, Validators.required],
      pose: [this.currentKit.pose, Validators.required],
      orientation: [this.currentKit.orientation, Validators.required],
      declivite: [this.currentKit.declivite, Validators.required],
      traverse_toiture: [this.currentKit.traverse_toiture, Validators.required],
      patte_fixation: [this.currentKit.patte_fixation, Validators.required],
      etancheite_fixation: [this.currentKit.etancheite_fixation, Validators.required],
      etancheite_abergement_g: [this.currentKit.etancheite_abergement_g, Validators.required],
      etancheite_superieure: [this.currentKit.etancheite_superieure, Validators.required],
      etancheite_abergement_d: [this.currentKit.etancheite_abergement_d, Validators.required],
      etancheite_inferieure: [this.currentKit.etancheite_inferieure, Validators.required],
      etancheite_inter: [this.currentKit.etancheite_inter, Validators.required],
      inferieure_champ: [this.currentKit.inferieure_champ, Validators.required],
      ecran_sous_toiture: [this.currentKit.ecran_sous_toiture, Validators.required],
      etancheite_arrosage: [this.currentKit.etancheite_arrosage, Validators.required],
     });
  }

  getCurrentKitAndSaveChange(event,key) {

    this.currentKit[key] = event;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === this.currentKit.type);

    array[index] = this.currentKit

    this.case.kits_vendus = JSON.stringify(array)
    this.sCases.editCase(this.case, this.case._id).then(res => console.log(res))
  }


  incrementValueOnduleur(step: number = 1,name,reason, option,product): void {

    this.currentKit['nbr_onduleur'] = this.currentKit['nbr_onduleur'] + step

    const newValue = this.currentKit['nbr_onduleur']

    this.addOption(name,reason, option,product, newValue)
  }

  incrementValueRelais(step: number = 1,name,reason, option,product): void {

    this.currentKit['nbr_relais'] = this.currentKit['nbr_relais'] + step


    const newValue = this.currentKit['nbr_relais']

    this.addOption(name,reason, option,product, newValue)
  }

  addOption(name,reason, option,product, value) {

    let find = JSON.parse(this.case.kits_vendus).find(x => x.name === name);
    find[option] = value;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === name);
    const selected = array[index]
    this.currentKit = selected
    selected[option] = value

    this.case.kits_vendus = JSON.stringify(array)
    this.sCases.editCase(this.case, this.case._id).then(res => console.log(res))

  }

  openDialog(value): void {
    const dialogRef = this.dialog.open(NoModalComponent, {
      width: '640px',
      disableClose: true,
      data: {
        currentKit: this.currentKit,
        case: this.case,
        value: value
      }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  async valid() {
    this.isValid = true
    this.loader = true
    this.createDoc = await this.generateDoc();
    setTimeout(() => {
      this.loader = false
      this.openDialogPrevisualize()
    }, 2000);

  }

  async generateDoc() {

    if(this.currentKit.pose === 'Façade') {
      this.recetteChoice = 'recette_pv_micro_onduleur_facade'
    }
    else if(this.currentKit.pose === 'Sol') {
      this.recetteChoice = 'recette_pv_micro_onduleur_sol'

    }
    else if(this.currentKit.pose === 'Sur imposition') {
      this.recetteChoice = 'recette_pv_micro_onduleur_surimposition'

    }
    else {
      this.recetteChoice = 'recette_pv_micro_onduleur_integration'
    }

    let response = await this.sPdfs.generateRecette(this.chantierId,this.recetteChoice,this.case,this.currentKit,"","").then(res => {
      if(res === 'ok') {
        console.log(res)
      }
    })
    return response;
  }

  openDialogPrevisualize(): void {
    setTimeout(() => {
      const dialogRef = this.dialog.open(ModalPrevisualizeComponent, {
        width: '100vh',
        height:  '100vh',
        maxWidth: '100vh',
        maxHeight: '100vh',
        hasBackdrop: false,
        disableClose: true,
        panelClass: 'pdf-visualize',
        data: {
          titlePiece: this.recetteChoice,
          caseId: this.case._id,
          case: this.case,
          kit: this.currentKit,
          chantierId: this.chantierId
        }
      });
      dialogRef.afterClosed().subscribe(result => {

      });
    }, 1000);

  }


  selectMultiple(event, title) {
    if (event.target.files.length > 0) {
      this.multipleImagesPieces = event.target.files;
    }
    this.onSubmit(title)
  }

  onSubmit(title) {
    const form = {
      title: title,
      qualification: 'Conforme',
      caseId: this.case._id,
      created_at: new Date(),
      type: 'technicien',
      chantier: this.chantierId,
      cible: 'recette_pv_micro_onduleur',
      description: '',
    }
    this.sPieces.addTechPiece(form, this.multipleImagesPieces, this.case._id, this.lastChantier._id)

    setTimeout(() => {
      this.checkPieceOfCible()
    }, 1100);
  }

  checkPieceOfCible() {
    this.sPieces.getPiecesByChantierIdAndCible(this.lastChantier._id, 'recette_pv_micro_onduleur').subscribe(res => {
      const pieces:any = res
      if(pieces.find(e => e.title === 'Champ photovoltaïque'))
        this.champ_photo = true
      if(pieces.find(e => e.title === 'Toîture globale'))
        this.toiture = true
      if(pieces.find(e => e.title === 'Coffret AC/DC'))
        this.coffret_acdc = true
      if(pieces.find(e => e.title === 'Pièces onduleurs'))
        this.pieces = true
      if(pieces.find(e => e.title === 'Coffret de sécurité'))
        this.coffret_secu = true
      if(pieces.find(e => e.title === 'Onduleur'))
        this.onduleur = true
    })

  }
  checkPieceNSerie() {
    this.sPieces.getPiecesByChantierIdAndCible(this.chantierId, 'n_serie_pv').subscribe(res => {
      this.piecesNserie= res
      if(this.piecesNserie.find(e => e.title === 'N° de serie panneaux solaires') && this.currentKit.type === "Panneaux solaires") {
        this.piece_serie_pv = this.piecesNserie.filter(e => e.title === 'N° de serie panneaux solaires')
        this.n_serie_pv = true
      }
      if(this.piecesNserie.find(e => e.title === 'N° de serie onduleurs') && this.currentKit.type === "Panneaux solaires") {
        this.piece_serie_ondu = this.piecesNserie.filter(e => e.title === 'N° de serie onduleurs')
        this.n_serie_ondu = true
      }
    })
  }

  selectMultipleNserie(event, title:string, n_serie:string) {
    if (event.target.files.length > 0) {
      this.multipleImagesPieces = event.target.files;
    }
    this.onSubmitNserie(title, n_serie)
  }

  onSubmitNserie(title:string,n_serie:string) {
    const form = {
      title: title,
      qualification: 'Conforme',
      caseId: this.case._id,
      created_at: new Date(),
      type: 'technicien',
      chantier: this.chantierId,
      cible: n_serie,
      description: '',
    }
    this.sPieces.addTechPiece(form, this.multipleImagesPieces, this.case._id, this.chantierId)
    setTimeout(() => {
      this.checkPieceNSerie()
    }, 1100);
  }
}
