import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-part12',
  templateUrl: './part12.component.html',
  styleUrls: ['./part12.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('600ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('600ms ease-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class Part12Component implements OnInit {

  @ViewChild('myCanvas')
  public canvas: ElementRef;
  public context: CanvasRenderingContext2D;
  public chartType: string = 'line';
  public chartData;
  public chartLabels: any[];
  public chartColors;
  public chartOptions: any;

  cheminee = false
  toiture = false
  pont = false
  ouverture = false
  murs = false
  plancher = false

  chemineeValid = false
  toitureValid = false
  pontValid = false
  ouvertureValid = false
  mursValid = false
  plancherValid = false

  totalPourcentage = 0

  arrayOrderChoice = []
  activedStep = 0

  caseId

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.caseId = this.route.snapshot.params['id'];


    this.chartData = [
      { data: [0], label: '', pointRadius: 0 },
      { data: [20, 43, 45, 49, 55, 58], label: '', pointRadius: 0 },
    ];
    this.chartLabels = ['1', '2', '3', '4', '5', '6'];
    this.chartColors = [
      {
        borderColor: '#73C097',
        backgroundColor: 'transparent',
      },
      {
        borderColor: '#FFAA33',
        backgroundColor: 'transparent',
      },

    ];
    this.chartOptions = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              fontColor: "#031966",
              fontSize: 16,
              stepSize: 20,
              max: 100,
              display: true,
              callback: function (value, index, values) {
                return value + '%'
              }
            },
            gridLines: {
              zeroLineColor: 'rgba(252, 117, 3, 0.0)',
              color: 'rgba(0, 0, 0, 0)',
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              zeroLineColor: 'rgba(252, 117, 3, 0.0)',
            },
            ticks: {
              display: false,
              beginAtZero: true,
            },
          },
        ],
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      legend: {
        display: false,
      },
    };
  }

  toggleFunction(pourcentage, toggle) {
    this.checkPourcentage(pourcentage, toggle)
  }

  checkPourcentage(pourcentage, toggle) {
    const arraySum = this.chartData[0].data[this.chartData[0].data.length - 1]
    switch (toggle) {
      case 'cheminee':
        this.cheminee = !this.cheminee
        this.cheminee ? this.totalPourcentage += pourcentage : this.totalPourcentage -= pourcentage
        this.arrayOrderChoice.push({ name: 'cheminee', score: 20, order: 3 })
        if (this.cheminee && this.activedStep === 2) {
          this.chemineeValid = true
          this.activedStep = 3
        }
        if (this.cheminee) {
          this.chartData[0].data.push(20 + (arraySum))
        }
        else {
          const index = this.chartData[0].data.indexOf(20)
          if (index != undefined && index > -1) {
            this.chartData[0].data.splice(index, 1);
          }
          else {
            this.chartData[0].data.pop(20 + arraySum)
          }
        }
        break;

      case 'toiture':
        this.toiture = !this.toiture
        this.toiture ? this.totalPourcentage += pourcentage : this.totalPourcentage -= pourcentage
        this.arrayOrderChoice.push({ name: 'toiture', score: 30, order: 1 })

        if (this.toiture) {
          this.toitureValid = true
          this.activedStep = 1
          this.chartData[0].data.push(30 + (arraySum))
        }
        else {
          const index = this.chartData[0].data.indexOf(30)
          if (index != undefined && index > -1) {
            this.chartData[0].data.splice(index, 1);
          }
          else {
            this.chartData[0].data.pop(30 + arraySum)
          }
        }
        break;

      case 'pont':
        this.pont = !this.pont
        this.pont ? this.totalPourcentage += pourcentage : this.totalPourcentage -= pourcentage
        this.arrayOrderChoice.push({ name: 'pont', score: 5, order: 6 })

        if (this.pont && this.activedStep === 5) {
          this.pontValid = true
          this.activedStep = 6
        }
        if (this.pont) {
          this.chartData[0].data.push(5 + (arraySum))
        }
        else {
          const index = this.chartData[0].data.indexOf(5)
          if (index != undefined && index > -1) {
            this.chartData[0].data.splice(index, 1);
          }
          else {
            this.chartData[0].data.pop(5 + arraySum)
          }
        }
        break;

      case 'ouverture':
        this.ouverture = !this.ouverture
        this.ouverture ? this.totalPourcentage += pourcentage : this.totalPourcentage -= pourcentage
        this.arrayOrderChoice.push({ name: 'ouverture', score: 15, order: 2 })

        if (this.ouverture && this.activedStep === 1) {
          this.ouvertureValid = true
          this.activedStep = 2
        }
        if (this.ouverture) {
          this.chartData[0].data.push(15 + (arraySum))
        }
        else {
          const index = this.chartData[0].data.indexOf(15)
          if (index != undefined && index > -1) {
            this.chartData[0].data.splice(index, 1);
          }
          else {
            this.chartData[0].data.pop(15 + arraySum)
          }
        }
        break;

      case 'murs':
        this.murs = !this.murs
        this.murs ? this.totalPourcentage += pourcentage : this.totalPourcentage -= pourcentage
        this.arrayOrderChoice.push({ name: 'murs', score: 20, order: 4 })
        if (this.murs && this.activedStep === 3) {
          this.mursValid = true
          this.activedStep = 4
        }
        if (this.murs) {
          this.chartData[0].data.push(20 + (arraySum))
        }
        else {
          const index = this.chartData[0].data.indexOf(20)
          if (index != undefined && index > -1) {
            this.chartData[0].data.splice(index, 1);
          }
          else {
            this.chartData[0].data.pop(20 + arraySum)
          }

        }
        break;

      case 'plancher':
        this.plancher = !this.plancher
        this.plancher ? this.totalPourcentage += pourcentage : this.totalPourcentage -= pourcentage
        this.arrayOrderChoice.push({ name: 'plancher', score: 10, order: 5 })

        if (this.plancher && this.activedStep === 4) {
          this.plancherValid = true
          this.activedStep = 5

        }
        if (this.plancher) {
          this.chartData[0].data.push(10 + (arraySum))
        }
        else {
          const index = this.chartData[0].data.indexOf(10)
          if (index != undefined && index > -1) {
            this.chartData[0].data.splice(index, 1);
          }
          else {
            this.chartData[0].data.pop(10 + arraySum)
          }

        }
        break;

    }
  }
}
