<div class="icons-template">
  <a class="nav-item nav-link " style="font-size:0.8rem;">
    <img src="../../../assets/bar-icons/compta-encours.svg"  routerLink="/compta-dashboard" >
  </a>

  <a class="nav-item nav-link " style="font-size:0.8rem;" routerLink="/compta-cm-table">
    <img src="../../../assets/bar-icons/cm.svg"  >
  </a>

  <a class="nav-item nav-link " style="font-size:0.8rem;">
    <img src="../../../assets/bar-icons/primes-active.svg">
  </a>

  <a class="nav-item nav-link " style="font-size:0.8rem;" routerLink="/compta-leads">
    <img src="../../../assets/bar-icons/audit.svg">
  </a>
</div>


<h1 class="mt-5">Suivi prime CEE & maprimerenov</h1>

<h1 class="mt-5">{{ sum | currency:'EUR':"symbol" }} </h1>
<p>Montant de prime restant à encaisser</p>

<ag-grid-angular *ngIf="primes != []"
#agGrid
style="width: 90vw; height: 70vh; margin-bottom: 5%;"
id="myGrid"
class="ag-theme-alpine"
[columnDefs]="columnDefs"
[defaultColDef]="defaultColDef"
[rowData]="primes"
[rowStyle]="rowStyle"
rowSelection="single"
[animateRows]="true"
(gridReady)="onGridReady($event)"
(cellDoubleClicked)="onSelectionChanged($event)"
[rowHeight]="rowHeight">
</ag-grid-angular>
