import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-part13',
  templateUrl: './part13.component.html',
  styleUrls: ['./part13.component.scss']
})
export class Part13Component implements OnInit {

  caseId
  reponse: string[] = [];
  book;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.caseId = this.route.snapshot.params['id'];
    this.book = JSON.parse(localStorage.getItem('book'));

  }

  public checkChoice(choice: string): void {
    const index = this.reponse.indexOf(choice);

    if (index === -1) {
      this.reponse.push(choice);
    } else {
      this.reponse.splice(index, 1);
    }
  }


  public suivant() {
    this.book.question4.reponse = this.reponse;
    this.book.question4.valid = this.arraysEqual(this.book.question4.reponse, this.book.question4.bonneReponse);
    localStorage.setItem('book', JSON.stringify(this.book));
    this.router.navigate(['/book/part14', this.caseId]);
  }

  arraysEqual(arr1: any[], arr2: any[]): boolean {
    if (arr1.length !== arr2.length) return false;
    let sortedArr1 = arr1.slice().sort();
    let sortedArr2 = arr2.slice().sort();
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) return false;
    }
    return true;
  }
}
