import { Component, Input, OnInit } from '@angular/core';
import { CasesService } from '@app/services/cases.service';
import { ChantierService } from '@app/services/chantier.service';

@Component({
  selector: 'app-header-info',
  templateUrl: './header-info.component.html',
  styleUrls: ['./header-info.component.scss']
})
export class HeaderInfoComponent {
  @Input() caseId;

  case;
  constructor(
    private sChantiers: ChantierService,
    private sCases: CasesService
    ) {}

  ngAfterContentInit(): void {
    console.log("this.case",this.caseId)
    this.getCaseById()
  }


  getCaseById(){
    this.sCases.getCaseById(this.caseId).subscribe(res => {
      this.case = res[0]
    })
  }
}
