
<img *ngIf="mobile" class="notification" [routerLink]="['/infos-advs']" src="../../../assets/icons/notification.svg">
<p *ngIf="mobile" class="compteur">
  <span matBadge="{{ infosUnread.length }}" matBadgeSize="small"></span>
</p>
<img *ngIf="mobile" class="messagerie-icon" [routerLink]="['/messagerie']" src="../../../assets/bar-icons/messagerie.svg">
<p *ngIf="mobile" class="compteur-messagerie">
  <span *ngIf="countUnread" matBadge="{{ countUnread.count }}" matBadgeSize="small"></span>
</p>

<div class="marginOff mt-5">
  <div class="container-fluid" *ngIf="currentUser">
    <button mat-button [routerLink]="['/commercial-customer-list']"><img src="../../../assets/icons/retour.svg">Retour</button>

  <div class="container-button">
    <button class="filter" *ngFor="let state of states" [ngClass]="!state.active ? 'filter'+state.name : 'filter'+state.name+'Disabled'" (click)="setFilter(state.name)">{{state.name}}</button>
   </div>

   <div class="row mt-3 mb-3">
    <mat-select
    *ngIf="mobile" class="rdv" [(ngModel)]='filter.state'>
      <mat-option value='all'>Tous</mat-option>
      <mat-option *ngFor="let state of states" [value]="state.name">{{state.name}}</mat-option>
    </mat-select>
  </div>

  <div *ngIf="currentUser.role === 'Responsable d\'agence'">
    <h6>Commerciaux de l'agence</h6>
    <div class="row ml-1">
      <div *ngFor='let commercial of commerciaux' [ngClass]="this.currentSelected === this.commercial._id ? 'active' : ''" class="little-card col-2" (click)="showCasesByCommercial(commercial._id)">
          <h3>{{ commercial.username }}</h3>
      </div>

    </div>

    <div class="row">
      <div class="col-xs-5 col-lg-4 col-sm-4" *ngFor="let case of commercialCases | statePipe: filter.state ">
          <div class="card" (click)="goToCommercialTemplate(case._id)">
              <div class="card-content">
                <p class="mb-0"><strong>Nom du client</strong></p>
                <p> {{ case.customer?.name }}  </p>
                <p class="mb-0"><strong>CA HT</strong></p>
                <p> {{ case.price_ht | currency:'EUR':'symbol' }}  </p>
                <p *ngIf="case.state == 'Signée'" class="mb-0"><strong>Kit vendu</strong></p>
                <app-product-card *ngIf="case.state == 'Signée'" [kit]="case.kits_vendus"></app-product-card>
                <p *ngIf="case.state != 'Signée'" class="mb-0"><strong>Titre</strong></p>
                <p *ngIf="case.state != 'Signée'">{{case.title}}</p>
                <p class="state" [ngClass]="case.state">{{ case.state }}</p>
            </div>
        </div>
    </div>
  </div>
  </div>
  <app-loading *ngIf="cases === undefined"></app-loading>
  <section class="wrapper-fostrap" *ngIf="userIsActive">
    <div class="container-fostrap">
        <div class="content">
                <div class="row">
                    <div class="col-xs-5 col-lg-4 col-sm-4" *ngFor="let case of cases | statePipe: filter.state">
                        <div class="card" (click)="goToCommercialTemplate(case._id)">
                            <div class="card-content">
                              <p class="mb-0"><strong>Nom du client</strong></p>
                              <p> {{ case.customer?.name }}  </p>
                              <p class="mb-0"><strong>CA HT</strong></p>
                              <p> {{ case.price_ht | currency:'EUR':'symbol' }}  </p>
                              <p *ngIf="case.state == 'Signée'" class="mb-0"><strong>Kit vendu</strong></p>
                              <!-- <p *ngIf="case.state == 'Signée'">{{ case.kits_vendus }}</p> -->
                              <app-product-card *ngIf="case.state == 'Signée'" [kit]="case.kits_vendus"></app-product-card>
                              <p *ngIf="case.state != 'Signée'" class="mb-0"><strong>Titre</strong></p>
                              <p *ngIf="case.state != 'Signée'">{{case.title}}</p>
                              <p class="state" [ngClass]="case.state">{{ case.state }}</p>
                          </div>
                      </div>
                  </div>
                </div>
          </div>
    </div>
  </section>

  </div>
</div>
