import { StatService } from './../../services/stat.service';
import { Component, OnInit } from '@angular/core';
import { CasesService } from '@app/services/cases.service';
import { InfosService } from '@app/services/infos.service';
import { RolesService } from '@app/services/roles.service';
import { TasksService } from '@app/services/tasks.service';
import { HttpClient } from '@angular/common/http';
import { apiUrl } from '@environments/env';
import { UsersService } from '@app/services/users.service';
import { AdminCases } from '@app/_models/AdminCases';
import * as moment from 'moment';
import { PicturesService } from '@app/services/pictures.service';

@Component({
  selector: 'app-commercial-dashboard',
  templateUrl: './commercial-dashboard.component.html',
  styleUrls: ['./commercial-dashboard.component.scss']
})
export class CommercialDashboardComponent implements OnInit {
  countUnread;

  user;
  cases;
  trackCases;
  otherRdv;
  arrayRdvs;
  tasks;

  commercialHT = 0;
  commercialTTC = 0;
  mobile;

  showUnread = true;
  showRead = true;
  infos;
  infosRead = [];
  infosUnread = [];

  commercial

  actualMonth
  month = new Date

  lowLimit = 700
  highLimit = 2000
  goal = 4000
  pourcentage = 0

  actualDate = new Date
  actualDay
  months = 12
  monthName
  chosenAgency

  goalCaseByMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  color

  monthCases;
  casesSigne;
  casesAnnule;
  caNet = 0
  caBrut = 0
  tauxAnnule = 0
  currentUser

  messages = []

  commerciaux;
  selectedId;

  agenceCases;
  commerciauxZero = [];
  filteredCommerciaux;
  usersPictures = [];
  imageSrc;


  cumul = false;
  isListVisible = false;
  commercialStates: { [key: string]: boolean } = {};
  toggle = false;
  constructor(
    public sRoles: RolesService,
    private sCases: CasesService,
    private sTasks: TasksService,
    private sInfos: InfosService,
    public sStat: StatService,
    private http: HttpClient,
    private sUsers: UsersService,
    private sPictures: PicturesService
  ) {

    this.actualMonth = this.month.getMonth()
    this.monthName = new Date().toLocaleString('default', { month: 'long' });
    this.actualDay = this.actualDate.getDate()
    this.user = this.sRoles.user;
    //this.getCommercial()
    this.selectedId = this.user.id

    this.showCases();
    this.search()

    if (this.user.role != 'Commercial') {
      this.showCommerciaux()
    }
  }

  ngOnInit(): void {
    if (window.screen.width <= 600) {
      this.mobile = true;
    }
    this.showTasks();
    this.showInfosByAdvId()
    this.getGroups();
  }

  async showCommerciaux() {
    await this.sUsers.getUserById(this.user.id).subscribe(res => {
      this.sUsers.getCommercialByAgency(res[0].agency).subscribe(res => {
        this.commerciaux = res['user'].filter(a => a.isActive)
      })
    })
  }

  showCases() {
    this.sCases.getAllCasesSigneAndAnnuleByCommercialId(this.user.id).subscribe(res => {
      this.cases = res;
      const casesMonth = this.cases.filter(c => new Date(c.datesign).getUTCMonth() === new Date().getUTCMonth() && new Date(c.datesign).getUTCFullYear() === new Date().getUTCFullYear())

      const caTT = casesMonth.reduce(function (prev, cur) {
        return prev + cur.price_ht;
      }, 0);

      this.casesSigne = this.cases.filter(c => new Date(c.datesign).getUTCMonth() === new Date().getUTCMonth() && (c.datecancel === null || c.datecancel === undefined) && new Date(c.datesign).getUTCFullYear() === new Date().getUTCFullYear())
      this.caNet = this.casesSigne.reduce(function (prev, cur) {
        return prev + cur.price_ht;
      }, 0);

      this.casesAnnule = this.cases.filter(c => (c.datecancel != null || c.datecancel != undefined) && new Date(c.datecancel).getUTCMonth() === new Date().getUTCMonth() && new Date(c.datesign).getUTCMonth() === new Date().getUTCMonth() && new Date(c.datesign).getUTCFullYear() === new Date().getUTCFullYear())
      this.caBrut = this.casesAnnule.reduce(function (prev, cur) {
        return prev + cur.price_ht;
      }, 0);

      this.tauxAnnule = (100 - (this.caNet * 100) / caTT);
      this.initCompteur()

    });
  }

  showComm(event) {
    this.selectedId = event
  }
  initCompteur() {
    this.casesSigne.forEach(element => {
      let i = 0
      while (i < this.months) {
        i++
        if (i == element.datesign.substr(5, 2)) {
          break
        }
      }
      let m = i - 1

      if (element.datesign.substr(8, 2) <= this.actualDay) {
        this.goalCaseByMonth[m] += element.price_ht
      }
    });

    this.lowLimit = this.lowLimit * this.actualDay
    this.highLimit = this.highLimit * this.actualDay
    this.goal = this.goal * this.actualDay

    if (this.goalCaseByMonth[this.actualMonth] < this.lowLimit) {
      this.color = "#F45B69"
    } else if (this.goalCaseByMonth[this.actualMonth] >= this.lowLimit && this.goalCaseByMonth[this.actualMonth] < this.highLimit) {
      this.color = "#F0C808"
    } else {
      this.color = "#78C091"
    }
    this.pourcentage = this.goalCaseByMonth[this.actualMonth] / this.goal * 100
    this.pourcentage = Math.round(this.pourcentage)

  }


  search() {
    this.sCases.getAllCasesByCommercialId(this.user.id).subscribe(res => {
      this.cases = res;
      let allCases;
      allCases = res
      const today = new Date();
      today.setHours(1, 0, 0, 0);
      const startDate = today.toISOString();

      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);

      const endDate = tomorrow.toISOString();
      const totalResult = [];
      allCases.filter(function (obj) {

        if (obj.r1[0].date >= startDate && obj.r1[0].date <= endDate) {
          let rdv = {
            "rdv": obj.r1[0],
            "customer": obj.customer
          }
          totalResult.push(rdv);
        }
        return obj.r1[0].date >= startDate && obj.r1[0].date <= endDate;
      });


      var result2 = allCases.filter(function (obj) {
        for (let index = 0; index < obj.rdvs.length; index++) {
          let element = obj.rdvs[index];

          if (obj.rdvs[index].date >= startDate && obj.rdvs[index].date <= endDate) {
            let rdv = {
              "rdv": obj.rdvs[index],
              "customer": obj.customer
            }
            totalResult.push(rdv);

          }
          return element.date >= startDate && element.date <= endDate;
        }
      });

      this.arrayRdvs = totalResult

    })
  }


  showTasks() {
    return new Promise(resolve => {
      this.sTasks.getTaskByTargetId(this.user.id).subscribe(res => {
        this.tasks = res;
        resolve(res);
      }, error => {
        console.log(error);
      });
    })
  }
  showInfosByAdvId() {
    this.sInfos.getInfoByAdvId(this.user.id).subscribe(res => {
      this.infos = res;
      this.infos.forEach(element => {
        if (element.vu) {
          this.infosRead.push(element)
        }
        else {
          this.infosUnread.push(element)
        }
      });
    })
  }
  goToAdminDashboard() {
    //this.router.navigate(['admin-dashboard'])
    this.cumul = !this.cumul;
    const formattedDate = moment().format('YYYY-MM-DD');

    this.fetchDashboardData(formattedDate)
  }

  public fetchDashboardData(date: string, year?: number) {
    const token = this.user.token;
    const [header, payload, signature] = token.split('.');

    const agency = JSON.parse(decodeURIComponent(escape(atob(payload)))).agency;
    this.sUsers.getAllCommerciaux().subscribe(res => {
      // Récupère les commerciaux actifs qui n'ont fait aucun CA 
      this.commerciauxZero = res['users'].filter(commercial => commercial.agency === agency);
      this.sCases.getCasesByDate(date, year).subscribe(cases => {


        this.agenceCases = cases.agences.filter(agence => agence.agence === agency)[0];

        const existingCommercials = new Set(this.agenceCases.commerciaux.map(item => item.commercial));

        // Filtrez les objets en supprimant ceux dont le username existe déjà dans existingCommercials
        this.commerciauxZero = this.commerciauxZero.filter(item => !item.username || !existingCommercials.has(item.username));

        res['users'].filter(commercial => commercial.agency === agency).forEach((element) => {
          this.sPictures.getPicturesByUserId(element._id).subscribe((res) => {
            this.usersPictures.push({
              id: element._id,
              picture: res,
            });
          });
        });
      })
    })

  }

  async getGroups(): Promise<void> {
    try {
      const countUnread: any = await this.http.get(apiUrl.apiUrl + '/messages/count-unread').toPromise();
      this.countUnread = countUnread;
    } catch (error) { }
  }
  getProfil(created_by) {
    let profil: any = this.usersPictures.filter((p) => p.id === created_by);
    if (profil) {
      profil = profil[0];
      if (profil && profil.picture[0]) {
        profil =
          'https://api-crm.s3.eu-west-3.amazonaws.com/' +
          created_by +
          '/picture/' +
          profil.picture[0].image;
      } else {
        profil = this.imageSrc;
      }
    } else {
      profil = this.imageSrc;
    }
    return profil;
  }

  getShortName(fullName) {
    return fullName
      .split(' ')
      .map((n) => n[0])
      .join('');
  }
  getFirstName(fullName) {
    return fullName.split(' ')[0];
  }
  getLastName(fullName) {
    return fullName.split(' ')[1];
  }


  toggleList(commercialId: string) {
    this.toggle = !this.toggleList;
    this.commercialStates[commercialId] = !this.commercialStates[commercialId];
  }


}
