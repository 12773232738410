import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-part1',
  templateUrl: './part1.component.html',
  styleUrls: ['./part1.component.scss']
})
export class Part1Component implements OnInit {

  public reponses = ['15%', '25%', '40%', '70%'];
  public question = "À combien s’élève <strong>l’augmentation des tarifs de l’électricité</strong> sur ces dix dernières années ?";

  public img = "./../../../assets/book/p1_image-right.png";
  caseId;

  public partieSuivante = "part2"

  constructor(private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.caseId = this.route.snapshot.params['id'];
  }


}
