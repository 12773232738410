<div class="body-modal">
  <h3>Mr et/ou Mme {{ currentCustomer.name }}</h3>

  <h6 class="mt-4">Adresse</h6>
  <p>{{ currentCustomer.address }}</p>
  <p>{{ currentCustomer.cp }} {{ currentCustomer.city }}</p>

  <h6 class="mt-4">Numéros</h6>
  <p *ngIf="currentCustomer.mobile">0{{ currentCustomer.mobile }}</p>
  <p *ngIf="currentCustomer.fixe">0{{ currentCustomer.fixe }}</p>
  <a class="mail" href="mailto:case.customer.mail">{{ currentCustomer.mail }}</a>

  <h6 class="mt-4">Description</h6>

  <p>{{ case.description }}</p>
</div>
