<div *ngIf="selectedCard === null"
  style="display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 16px; grid-template-rows: 1fr; width: 100%; height: 100%;">
  <!-- Card 1 -->
  <div class="card" style="grid-column: 1; grid-row: 1;" (click)="selectCard('commercial')">
    <div class="card-body text-center"
      style="display: flex; flex-direction: column; justify-content: center; height: 100%;">
      <div style="display: flex; flex-direction: column; align-items: center;">
        <img src="./../../../assets/icons/commercial.png" alt="Image de la carte" class="centered-image">
        <h5 class="card-title mt-3">Comparer <br>les commerciaux</h5>
      </div>
    </div>
  </div>


  <!-- Card 2 -->
  <div class="card" style="grid-column: 2; grid-row: 1;" (click)="selectCard('agence')">
    <div class="card-body text-center">
      <img src="./../../../assets/icons/agence.png" alt="Image de la carte" class="centered-image">

      <h5 class="card-title mt-3">Comparer <br>les agences</h5>
    </div>
  </div>

  <!-- Card 3 -->
  <div class="card" style="grid-column: 3; grid-row: 1;" (click)="selectCard('globale')">
    <div class="card-body text-center">
      <img src="./../../../assets/icons/globale.png" alt="Image de la carte" class="centered-image">

      <h5 class="card-title mt-3">Comparaison<br> globale</h5>
    </div>
  </div>
</div>

<div *ngIf="selectedCard !== null && selectedCard !== 'globale'" class="mb-3">
  <h2>Selectionner une période</h2>
  <div style="display: flex;justify-content: space-around;">
    <div style="width: 32%;">
      <mat-form-field>
        <mat-label>{{selectedMonth.value}}</mat-label>
        <mat-select [(ngModel)]="selectedMonth" (selectionChange)="showCasesBySelectedDate()">
          <mat-option *ngFor="let month of months" [value]="month.value">{{ month.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div style="width: 32%;">
      <mat-form-field>
        <mat-select [(ngModel)]="selectedYear" (selectionChange)="showCasesBySelectedDate()">
          <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>

<div *ngIf="selectedCard === 'commercial'" class="mt-3 mb-3">
  <h2 class="mb-3">Sélectionner vos commerciaux</h2>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div *ngFor="let commercial of commerciaux; let i = index" class="col-md-4">
            <mat-checkbox class="custom-checkbox" [(ngModel)]="selectedCommerciaux[commercial.commercial]"
              (change)="onCheckboxChange(commercial.commercial)"
              [disabled]="selectedCount >= 6 && !selectedCommerciaux[commercial.commercial]">
              {{ commercial.commercial }}
            </mat-checkbox>
          </div>
        </div>
        <button (click)="initChart('commerciaux')" *ngIf="selectedCount > 1" class="btn-secondarycrm"
          style="float: right;">Valider</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="selectedCard === 'agence'" class="mt-5">
  <h2 class="mb-3">Selectionner vos agences</h2>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div *ngFor="let agence of agences; let i = index" class="col-md-4">
            <mat-checkbox class="custom-checkbox" [(ngModel)]="selectedAgences[agence.agence]"
              (change)="onSelectAgence(agence.agence)"
              [disabled]="selectedCount >= 6 && !selectedAgences[agence.agence]"> {{ agence.agence }}
            </mat-checkbox>

          </div>
        </div>
        <button (click)="initChart('agences')" *ngIf="selectedCount > 1" class="btn-secondarycrm"
          style="float: right;">Valider</button>      </div>

    </div>
  </div>
</div>

<div *ngIf="selectedCard === 'globale'" class="mt-5">
  <h2>Selectionner une année</h2>
  <div style="display: flex;justify-content: space-around;">

    <div style="width: 32%;">
      <mat-form-field>
        <mat-select [(ngModel)]="selectedYear" (selectionChange)="showCasesBySelectedDate()">
          <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <h2>Selectionner une seconde année</h2>
  <div style="display: flex;justify-content: space-around;">

    <div style="width: 32%;">
      <mat-form-field>
        <mat-select [(ngModel)]="selectedYear" (selectionChange)="showCasesBySelectedDate()">
          <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </div>
  <button (click)="initChart('globales')" class="btn-secondarycrm"
  style="float: right;">Valider</button> 
</div>