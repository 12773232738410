import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class ChildToParentService {

  constructor() { }

  public idRoom$: BehaviorSubject<string> = new BehaviorSubject('');
  public comeFromNotification: BehaviorSubject<boolean> = new BehaviorSubject(false);
}
