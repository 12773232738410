<div class="icons-template">
  <a class="nav-item nav-link " style="font-size:0.8rem;">
  <img src="../../../assets/bar-icons/compta-encours.svg" (click)="goToEncours()">
</a>
  <a class="nav-item nav-link " style="font-size:0.8rem;" (click)="toggleCm()" *ngIf="!showCm">
  <img src="../../../assets/bar-icons/audit.svg">
</a>
<a class="nav-item nav-link " style="font-size:0.8rem;" (click)="toggleCm()" *ngIf="showCm">
  <img src="../../../assets/bar-icons/cm.svg">
</a>
</div>

<app-compta-dashboard *ngIf="!showCm"></app-compta-dashboard>
<app-compta-cm-table *ngIf="showCm"></app-compta-cm-table>
