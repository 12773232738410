import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';

@Component({
  selector: 'app-compta-leads',
  templateUrl: './compta-leads.component.html',
  styleUrls: ['./compta-leads.component.scss']
})
export class ComptaLeadsComponent implements OnInit {

  cases;
  gridApi;
  gridColumnApi;

  rowData;
  rowHeight
  rowStyle
  columnDefs;
  defaultColDef;

  chantiers
  pieces
  tasks
  total = 0
  constructor(
    private sCases: CasesService,
    private router: Router,
  ) {
    this.rowHeight = 65;
    this.rowStyle = { background: 'white' };
    this.columnDefs = [
      {
        headerName: 'ID',
        field: '_id',
        width: 5,
        minWidth: 5,
        maxWidth: 5,
        cellClass: 'transparent',
      },
      {
        headerName: 'Client',
        field: 'customer.name',
        cellClass: 'cell-align',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'prix HT',
        field: 'price_ht',
        cellClass: 'cell-align',
        cellRenderer: (data) => {
          return data.value ? data.value + ' €' : '';
        },
      },
      {
        headerName: 'Date de signature',
        field: 'datesign',
        cellClass: 'cell-align',
        cellRenderer: (data) => {
          return data.value ? (new Date(data.value)).toLocaleDateString() : '';
        },
      },
      {
        headerName: 'Commercial',
        field: 'commercial.username',
        cellClass: 'cell-align',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Adv',
        field: 'adv.username',
        cellClass: 'cell-align',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Origine',
        field: 'customer.origin',
        cellClass: 'cell-align',
        filter: 'agTextColumnFilter',
        cellRenderer: params => {
          let eIconGui = document.createElement('span');
            if(params.value === 'Commercial' || params.value === 'perso') {
              return  eIconGui.innerHTML = '<img src="assets/icons/user.svg">'
            }
            else {
              return  eIconGui.innerHTML = '<img src="assets/icons/beenergie.svg">'
          }
        }
      },
  ];
  this.defaultColDef = {
    flex: 1,
    sortable: true,
    floatingFilter: true,
    pagination: true,
  };
}
  ngOnInit(): void {
    this.showAllCases()
  }

  showAllCases() {
    this.sCases.getCaseByState().subscribe(res => {
      this.cases = res['cases'];
      this.rowData = this.cases.filter(c => c.price_ttc > 800 && (c.title != 'CM')).sort((a, b) => {
        return <any>new Date(b.datesign) - <any>new Date(a.datesign);
      });

    })
  }
  onSelectionChanged() {
    var selectedRows = this.gridApi.getSelectedRows();
     const url = this.router.serializeUrl(
      this.router.createUrlTree(['/case-template/', selectedRows[0]._id])
    );
    window.open(url, '_blank');
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
}
