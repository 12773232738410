<img
style="position: relative;
left: 100%;
margin-top: -8%;
width: 6%;
cursor: pointer;;"
src="../../../assets/icons/cross.svg" (click)="closeModale()">

<input class="title" [(ngModel)]="task.title" placeholder="Titre" required *ngIf="!editMode">

<h1>{{ task.title }}</h1>
 <p *ngIf="task.caseId && task.customerId" >Affaire {{ task.customerId?.name }}</p>


 <div class="row mt-3 mb-4" *ngIf="editMode">
  <div class="col-2 hours">
    {{ differenceHours | number: '1.0-0' }}
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="clock">
      <path d="M7.01477 0.34375C3.26868 0.34375 0.233521 3.37891 0.233521 7.125C0.233521 10.8711 3.26868 13.9062 7.01477 13.9062C10.7609 13.9062 13.796 10.8711 13.796 7.125C13.796 3.37891 10.7609 0.34375 7.01477 0.34375ZM12.921 7.125C12.921 10.3789 10.2687 13.0312 7.01477 13.0312C3.76086 13.0312 1.10852 10.4062 1.10852 7.125C1.10852 3.89844 3.73352 1.21875 7.01477 1.21875C10.2413 1.21875 12.921 3.87109 12.921 7.125ZM8.8468 9.55859C8.98352 9.66797 9.20227 9.61328 9.2843 9.47656L9.5304 9.17578C9.63977 9.01172 9.58508 8.82031 9.44836 8.71094L7.5343 7.31641V3.29688C7.5343 3.13281 7.37024 2.96875 7.20618 2.96875H6.82336C6.63196 2.96875 6.49524 3.13281 6.49524 3.29688V7.67188C6.49524 7.78125 6.52258 7.86328 6.60461 7.94531L8.8468 9.55859Z" fill="#A5A5A5"/>
    </svg>

  </div>
  <div class="col-9 pl-0">
    <div class="progress">
      <div class="bar"
      [ngStyle]="{width: progressValue}">
      </div>
    </div>
  </div>
</div>

<form *ngIf="editMode">
  <mat-form-field class="custom-field">
    <mat-select class="custom-select-rdv" name="choice" [(ngModel)]="selectedValue" (selectionChange)="changeState(task, $event.value)" (selectionChange)="checkCommentary()"  [disabled]="task.state == 'Terminé'">
      <mat-option *ngFor="let com of task.commentary" [value]="com">
        {{com.title}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>


<textarea class="commentary" [(ngModel)]="task.description" [placeholder]=descriptionPlaceholder [readOnly]="task.state == 'Terminé'"></textarea>


<div class="row" *ngIf="!editMode">
  <mat-form-field class="col-6">
    <mat-label>Nom du client</mat-label>
    <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto" [(ngModel)]="customer.name">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" >
      <mat-option *ngFor="let customer of filteredOptions | async" [value]="customer" (click)="showCasesByCustomerId(customer)">
        {{customer.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field class="col-6">
    <mat-label>Kit vendu</mat-label>
    <mat-select name="user" [(ngModel)]="task.caseId">
      <mat-option *ngFor="let customerCase of customerCases" [value]="customerCase._id" (click)="showAdvOfCase(customerCase)">
        {{customerCase.title}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="row">
  <mat-form-field class="col-6">
    <mat-label>Affectation</mat-label>
    <mat-select name="adv" [(ngModel)]="task.target" [disabled]="currentUser.id != '5ff22a8ba0f2570004f151d0'">
      <mat-option *ngFor="let adv of advs" [value]="adv._id" disabled>
        {{adv.username}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="col-6">
    <input
        [(ngModel)]="task.end_date"
        id="date"
        class="form-control empty-date"
        placeholder="Echéance"
        [min]="task.created_at"
        [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" required>
        <owl-date-time  [disabled]="currentUser.id != '5ff22a8ba0f2570004f151d0'" firstDayOfWeek="1" #dt2></owl-date-time>
  </div>
</div>

<div class="row">
  <button class="btn-secondarycrm" (click)="addTask()" *ngIf="!taskEdit && task.target != '' && task.end_date != ''">Créer la tâche</button>
  <button class="btn-secondarycrm" (click)="editTask()" *ngIf="taskEdit && task.target != '' && task.end_date != '' && commentaryIsValid">Editer</button>
</div>

