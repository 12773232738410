<form [formGroup]="mairieForm">

    <div class="choices mr-5">
      <p class="m-0">Statut de la demande</p>
      <input type="radio" id="state1" name="state" formControlName="state" value="En cours">
      <label for="state1" [ngClass]="this.mairieForm.value.state === 'En cours' ? 'pe' : 'pe-empty'">En cours</label>

      <input type="radio" id="state2" name="state" formControlName="state" value="Refus">
      <label for="state2" [ngClass]="this.mairieForm.value.state === 'Refus' ? 'non' : 'non-empty'">Refus</label>

      <input type="radio" id="state3" name="state" formControlName="state" value="Complément">
      <label for="state3" [ngClass]="this.mairieForm.value.state === 'Complément' ? 'pe' : 'pe-empty'">Complément</label>

      <input type="radio" id="state4" name="state" formControlName="state" value="Acceptation">
      <label for="state4" [ngClass]="this.mairieForm.value.state === 'Acceptation' ? 'oui' : 'oui-empty'">Acceptation</label>
    </div>

    <div class="choices mr-5">
      <p class="m-0">Demande préalable conforme</p>
      <input type="radio" id="dp1" name="dp" formControlName="dp" value=true>
      <label for="dp1" [ngClass]="this.mairieForm.value.dp === 'true' ? 'oui' : 'oui-empty'">
        <span class="material-icons">
        done
        </span>OUI</label>

        <input type="radio" id="dp2" name="dp" formControlName="dp" value=false>
        <label for="dp2" [ngClass]="this.mairieForm.value.dp === 'false' ? 'non' : 'non-empty'">
        <span class="material-icons">
          clear
          </span>NON</label>
    </div>

    <div>
      <div>
      <h6 class="m-0">Date de dépot de dossier</h6>
      <input placeholder="JJ/MM/AAAA xxhxx" [ngClass]="this.mairieForm.value.date_depot != '' ? 'custom-date' : 'empty-date'" formControlName="date_depot" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" >
      <owl-date-time firstDayOfWeek="1" #dt2></owl-date-time>
      </div>
    </div>

  </form>
<button class="btn-secondarycrm" *ngIf="mairieForm.valid" (click)="createMairie('ajouté','demande en mairie',mairieForm.value.date_depot)">Créer la demande</button>
