<div class="icons-template">
  <a class="nav-item nav-link " style="font-size:0.8rem;">
    <img src="../../../assets/bar-icons/compta-encours.svg"  routerLink="/compta-dashboard" >
  </a>

  <a class="nav-item nav-link " style="font-size:0.8rem;">
    <img src="../../../assets/bar-icons/cm-active.svg"  >
  </a>

  <a class="nav-item nav-link " style="font-size:0.8rem;"  routerLink="/compta-primes-table" >
    <img src="../../../assets/bar-icons/primes.svg">
  </a>

  <a class="nav-item nav-link " style="font-size:0.8rem;" routerLink="/compta-leads">
    <img src="../../../assets/bar-icons/audit.svg">
  </a>
</div>



<div class="d-flex mt-4" *ngIf='rowData'>
  <div>
    <h1>{{ annuelCa | currency:'EUR':"symbol" }}</h1>
    <p>Total CA annuel des CM (Total des montants)</p>
  </div>
  <div class="ml-5">
    <h1>{{ totalMonth  | currency:'EUR':"symbol" }}</h1>
    <p>Total du montant / mois</p>
  </div>
</div>

<mat-form-field>
  <mat-label>Année</mat-label>
  <mat-select [(ngModel)]="selectedYear" (selectionChange)="test()">
    <mat-option value="2019" >2019</mat-option>
    <mat-option value="2020">2020</mat-option>
    <mat-option value="2021">2021</mat-option>
    <mat-option value="2022">2022</mat-option>
    <mat-option value="2023">2023</mat-option>

  </mat-select>
</mat-form-field>


<h2 *ngIf="showEncours" style="color:var(--tertiarycrm);" >{{actualNameMonth}}</h2>

<mat-progress-bar *ngIf="!rowData" mode="indeterminate"></mat-progress-bar>

  <ag-grid-angular *ngIf="rowData != []"
    #agGrid
    style="width: 90vw; height: 70vh; margin-bottom: 5%;"
    id="myGrid"
    class="ag-theme-alpine"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="rowData"
    [rowStyle]="rowStyle"
    rowSelection="single"
    [animateRows]="true"
    (gridReady)="onGridReady($event)"
    (selectionChanged)="onSelectionChanged($event)"
    [rowHeight]="rowHeight">
  </ag-grid-angular>
