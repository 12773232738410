import { Component, OnInit } from '@angular/core';
import { CasesService } from '@app/services/cases.service';
import { RolesService } from '@app/services/roles.service';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Router } from '@angular/router';
import { NgModel } from '@angular/forms';
import { AuthenticationService } from './../../_services/authentication.service';

@Component({
  selector: 'app-compta-cm-table',
  templateUrl: './compta-cm-table.component.html',
  styleUrls: ['./compta-cm-table.component.scss']
})
export class ComptaCmTableComponent implements OnInit {

  gridApi;
  gridColumnApi;

  columnDefs;
  defaultColDef;
  rowData;
  rowStyle;
  cases;
  comptaCases;
  rowHeight

  sousTt;
  allSousTt
  caNetTt;
  allCaNetTt
  annuelCa;
  totalMonth

  months=[
    {name:"Janvier", checked:false, number:"01" },
    {name:"Février", checked:false, number: "02"},
    {name:"Mars", checked:false, number:"03" },
    {name:"Avril",checked:false,number:"04"},
    {name:"Mai", checked:false, number:"05"},
    {name:"Juin", checked:false,number:"06" },
    {name:"Juillet", checked:false,number:"07"},
    {name:"Aout", checked:false,number:"08"},
    {name:"Septembre", checked:false,number:"09"},
    {name:"Octobre",checked:false,number:"10" },
    {name:"Novembre",checked:false,number:"11"},
    {name:"Decembre",checked:false,number:"12"},
  ]
  actualMonth

  selectedMonth=[];
  indexDeselected;
  checkedAll = true

  checkMonthSelect
  mobile
  showEncours = true

  actualNameMonth =""
  user

  selectedYear

  constructor(
    private sCases: CasesService,
    public sRoles: RolesService,
    private router: Router,
    private AuthenticationService : AuthenticationService
    ) {

      if (window.screen.width <= 600) {
        this.mobile = true;
      }

    this.rowHeight = 65;
    this.rowStyle = { background: 'white' };

    this.columnDefs = [
      {
        headerName: 'Datesign',
        field: "datesign",
        cellClass: 'cell-align',
        maxWidth: 300,
        minWidth: 250,
        filter: 'agTextColumnFilter',
        cellRenderer: (data) => {
          return data.value ? (new Date(data.value)).toLocaleDateString() : ''

        },
      },
      {
        headerName: 'Nom',
        field: "name",
        cellClass: 'cell-align',
        maxWidth: 300,
        minWidth: 250,
        filter: 'agTextColumnFilter',

      },
      {
        headerName: "Type de paiement",
        field: "paiement.type_paiement",
        filter: 'agTextColumnFilter',
        cellClass: 'cell-align',
        minWidth: 150,
        cellRenderer: (data) => {
          return data.value ?  data.value.charAt(0).toUpperCase() + data.value.slice(1) :""
        },
      },
      {
        headerName: "Montant",
        field: "tarif",
        filter: 'agTextColumnFilter',
        cellClass: 'cell-align',
        minWidth: 150,
        cellRenderer: (data) => {
          return data.value ? data.value + '€' : '-'
        },
      },
      {
        headerName: "Montant/mois",
        field: "paiement.montant",
        filter: 'agTextColumnFilter',
        cellClass: 'cell-align',
        minWidth: 150,
        cellRenderer: (data) => {
          return data.value ? data.value + '€' : '-'
        },
      },
      {
        headerName: "Mois de facture",
        field: "facture_month",
        filter: 'agTextColumnFilter',
        cellClass: 'cell-align',
        minWidth: 150,
        cellRenderer: (data) => {

          return data.value ? data.value : 'pas date de signature'

        },
      },
      {
        headerName: "Janvier",
        field: "arrayTable1",
        filter: 'agTextColumnFilter',
        cellClass: 'cell-align',
        minWidth: 100,
        cellStyle: data => {
          if (data.value.state === 'Not Started' || data.value.state === 'Terminé') {
            return {backgroundColor: '#f5fbfd'};
          }
          return null;
        },
         cellRenderer: (data) => {
          return   data.value.state != 'Not Started' && data.value.state != 'Terminé'  ? data.value.montant + ' €' : ''
        },
      },
      {
        headerName: "Février",
        field: "arrayTable2",
        filter: 'agTextColumnFilter',
        cellClass: 'cell-align',
        minWidth: 100,
        cellStyle: data => {
          if (data.value.state === 'Not Started' || data.value.state === 'Terminé') {
            return {backgroundColor: '#f5fbfd'};
          }
          return null;
        },
         cellRenderer: (data) => {
          return   data.value.state != 'Not Started' && data.value.state != 'Terminé'  ? data.value.montant + ' €' : ''
        },
      },
      {
        headerName: "Mars",
        field: "arrayTable3",
        filter: 'agTextColumnFilter',
        cellClass: 'cell-align',
        minWidth: 100,
        cellStyle: data => {
          if (data.value.state === 'Not Started' || data.value.state === 'Terminé') {
            return {backgroundColor: '#f5fbfd'};
          }
          return null;
        },
         cellRenderer: (data) => {
          return   data.value.state != 'Not Started' && data.value.state != 'Terminé'  ? data.value.montant + ' €' : ''
        },
      },
      {
        headerName: "Avril",
        field: "arrayTable4",
        filter: 'agTextColumnFilter',
        cellClass: 'cell-align',
        minWidth: 100,
        cellStyle: data => {
          if (data.value.state === 'Not Started' || data.value.state === 'Terminé') {
            return {backgroundColor: '#f5fbfd'};
          }
          return null;
        },
         cellRenderer: (data) => {
          return   data.value.state != 'Not Started' && data.value.state != 'Terminé'  ? data.value.montant + ' €' : ''
        },
            },
      {
        headerName: "Mai",
        field: "arrayTable5",
        filter: 'agTextColumnFilter',
        cellClass: 'cell-align',
        minWidth: 100,
        cellStyle: data => {
          if (data.value.state === 'Not Started' || data.value.state === 'Terminé') {
            return {backgroundColor: '#f5fbfd'};
          }
          return null;
        },
         cellRenderer: (data) => {
          return   data.value.state != 'Not Started' && data.value.state != 'Terminé'  ? data.value.montant + ' €' : ''
        },
      },
      {
        headerName: "Juin",
        field: "arrayTable6",
        filter: 'agTextColumnFilter',
        cellClass: 'cell-align',
        minWidth: 100,
        cellStyle: data => {
          if (data.value.state === 'Not Started' || data.value.state === 'Terminé') {
            return {backgroundColor: '#f5fbfd'};
          }
          return null;
        },
         cellRenderer: (data) => {
          return   data.value.state != 'Not Started' && data.value.state != 'Terminé'  ? data.value.montant + ' €' : ''
        },
      },
      {
        headerName: "Juillet",
        field: "arrayTable7",
        filter: 'agTextColumnFilter',
        cellClass: 'cell-align',
        minWidth: 100,
        cellStyle: data => {
          if (data.value.state === 'Not Started' || data.value.state === 'Terminé') {
            return {backgroundColor: '#f5fbfd'};
          }
          return null;
        },
         cellRenderer: (data) => {
          return   data.value.state != 'Not Started' && data.value.state != 'Terminé'  ? data.value.montant + ' €' : ''
        },
      },
      {
        headerName: "Août",
        field: "arrayTable8",
        filter: 'agTextColumnFilter',
        cellClass: 'cell-align',
        minWidth: 100,
        cellStyle: data => {
          if (data.value.state === 'Not Started' || data.value.state === 'Terminé') {
            return {backgroundColor: '#f5fbfd'};
          }
          return null;
        },
         cellRenderer: (data) => {
          return   data.value.state != 'Not Started' && data.value.state != 'Terminé'  ? data.value.montant + ' €' : ''
        },
      },
      {
        headerName: "Septembre",
        field: "arrayTable9",
        filter: 'agTextColumnFilter',
        cellClass: 'cell-align',
        minWidth: 100,
        cellStyle: data => {
          if (data.value.state === 'Not Started' || data.value.state === 'Terminé') {
            return {backgroundColor: '#f5fbfd'};
          }
          return null;
        },
         cellRenderer: (data) => {
          return   data.value.state != 'Not Started' && data.value.state != 'Terminé'  ? data.value.montant + ' €' : ''
        },
      },
      {
        headerName: "Octobre",
        field: "arrayTable10",
        filter: 'agTextColumnFilter',
        cellClass: 'cell-align',
        minWidth: 100,
        cellStyle: data => {
          if (data.value.state === 'Not Started' || data.value.state === 'Terminé') {
            return {backgroundColor: '#f5fbfd'};
          }
          return null;
        },
         cellRenderer: (data) => {
          return   data.value.state != 'Not Started' && data.value.state != 'Terminé'  ? data.value.montant + ' €' : ''
        },
      },
      {
        headerName: "Novembre",
        field: "arrayTable11",
        filter: 'agTextColumnFilter',
        cellClass: 'cell-align',
        minWidth: 100,
        cellStyle: data => {
          if (data.value.state === 'Not Started' || data.value.state === 'Terminé') {
              return {backgroundColor: '#f5fbfd'};
          }
          return null;
        },
         cellRenderer: (data) => {
          return   data.value.state != 'Not Started' && data.value.state != 'Terminé'  ? data.value.montant + ' €' : ''
        },
      },
      {
        headerName: "Décembre",
        field: "arrayTable12",
        filter: 'agTextColumnFilter',
        cellClass: 'cell-align',
        minWidth: 100,
        cellStyle: data => {

          if (data.value.state === 'Not Started' || data.value.state === 'Terminé') {
            return {backgroundColor: '#f5fbfd'};
          }
          return null;
        },
         cellRenderer: (data) => {
          return   data.value.state != 'Not Started' && data.value.state != 'Terminé'  ? data.value.montant + ' €' : ''
        },
      },
      {
        headerName: "Restant dû",
        field: "rest",
        filter: 'agTextColumnFilter',
        cellClass: 'cell-align',
        minWidth: 150,
        cellStyle: data => {
          if (data.value && data.value.state === 'Impayé') {
              return {backgroundColor: 'red'};
          }
          return null;
        },
         cellRenderer: (data) => {
          return data.value  ? data.value + ' €' : '-';
        },
      },
      {
        headerName: "Commentaire",
        field: "",
        filter: 'agTextColumnFilter',
        cellClass: 'cell-align',
        minWidth: 150,
      },
    ];

    this.defaultColDef = {
      flex: 1,
      minWidth: 0,
      sortable: true,
      floatingFilter: true,
      resizable: true,
    };

    this.actualMonth = new Date().getMonth()+1
    this.AuthenticationService.user.subscribe(x => this.user = x);
    this.showCm()

  }

  ngOnInit(): void {
  }

  test(){
    this.sCases.getAllCm(this.selectedYear).subscribe(res => {
      this.cases = res['newData'];
    this.annuelCa = res['annuelCa']
    this.totalMonth = res['totalMonth']
    this.rowData = this.cases
    this.getCaseMensuelle()
    })
  }

  showCm() {
    this.sCases.getAllCm(new Date().getFullYear()).subscribe(res => {
    this.cases = res['newData'];
    this.annuelCa = res['annuelCa']
    this.totalMonth = res['totalMonth']
    this.rowData = this.cases
    this.getCaseMensuelle()

    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onSelectionChanged() {
    var selectedRows = this.gridApi.getSelectedRows();
    this.router.navigate(['/compta-cm-detail/', selectedRows[0].id]);
  }

  recalculCa(){
    this.caNetTt = 0
    this.sousTt = 0
    this.rowData.forEach(element => {
    this.caNetTt += element.price_ttc
    });
  }

  deselectAll(select) {
    select.update.emit([]);

    this.months.forEach(element => {
      element.checked = false
    });
    this.selectedMonth =[]
    this.rowData = this.cases
    this.sousTt = this.allSousTt
  }

  toggleShowEncours(){
    this.showEncours = !this.showEncours
    if(!this.showEncours){
      this.selectedMonth =[]
      this.rowData = this.cases
      this.caNetTt = this.allCaNetTt
      this.sousTt = this.allSousTt
    }else{
      this.getCaseMensuelle()

    }
    this.recalculCa()

  }

  goHome(){

    if(this.user.role == "Compta" ){
      this.toggleShowEncours()
    }else if(this.user.role == "Adv"){
      this.router.navigate(['adv-dashboard']).then(() => {
        window.location.reload();
      });;
    }else if(this.user.role == "Admin"){
      this.router.navigate(['admin-dashboard']).then(() => {
        window.location.reload();
      });
    }
  }

  getCaseMensuelle(){
    this.rowData = this.cases
   //this.rowData = this.rowData.filter(data => data.datesign.substr(5,2) ==  this.actualMonth)
   this.recalculCa()
  }

}
