import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrl} from '@environments/env';

@Injectable({
  providedIn: 'root'
})
export class PrimesService {

  url = apiUrl.apiUrl + '/prime/';
  //url = 'http://localhost:4000/prime/'

  headers = { 'Content-Type': 'application/json' };

  constructor(
    private http: HttpClient,

  ) { }

  getAllPrimes() {
    return this.http.get(this.url);
  }

  getPrimesByCaseId(id) {
    return this.http.get(this.url + 'case/' + id);
  }
  getPrimeById(id) {
    return this.http.get(this.url + 'prime/' + id);
  }

  delete(id) {
    return this.http.delete(this.url + id);
  }

  editPrime(prime, primeId): Promise<any> {
    return this.http
    .put(this.url + primeId, JSON.stringify(prime) , { headers: this.headers })
    .toPromise()
    .then(res => res as any);
  }

  createPrime(primeForm): Promise<any> {
    return this.http
      .post(this.url + 'add', JSON.stringify(primeForm), { headers: this.headers })
      .toPromise()
      .then(res => res as any);
  }
}
