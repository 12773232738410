import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CasesService } from '@app/services/cases.service';
import { ChantierService } from '@app/services/chantier.service';
import { HistoriqueService } from '@app/services/historique.service';
import { RolesService } from '@app/services/roles.service';
import { UsersService } from '@app/services/users.service';

@Component({
  selector: 'app-chantier-add',
  templateUrl: './chantier-add.component.html',
  styleUrls: ['./chantier-add.component.scss']
})
export class ChantierAddComponent implements OnInit {


  Chantier={}
  chantiers;
  case;
  title;
  date;
  infos;
  type = 'installation'
  tech1 = [];
  tech2 = [];
  resp = [];
  allTechniciens;
  responsables = [];
  techs = [];
  selected = new FormControl(0);
  validTech: string[] = [];
  isfrmChecked:any;

  clickedNumbers = []

  arrayChantier = [];
  constructor(
    private sChantier: ChantierService,
    private sCase: CasesService,
    private sUser: UsersService,
    private dialogRef: MatDialog,
    private sHistoriques: HistoriqueService,
    private sRoles: RolesService,
    @Inject(MAT_DIALOG_DATA) public caseId: any,

  ) { }

  ngOnInit(): void {
    this.getCase()
    this.showAllCaseChantier();
    this.getAllTech()
  }

  showAllCaseChantier() {
    this.sChantier.getChantierByCaseId(this.caseId.caseId).subscribe(res => {
      this.chantiers = res
  })
}

getAllTech(){
    this.sUser.getAllTechs().subscribe(res =>{
      this.allTechniciens = res['users'];
      res['users'].forEach(element => {
        if(element.role === "Responsable Technicien") {
          this.responsables.push(element)
        }
        else {
          this.techs.push(element)
        }
      });
    })
}

getCase(){
  this.sCase.getCaseById(this.caseId.caseId).subscribe(res => {
    this.case = res[0]
  })
}

 getValue(value) {
    this.clickedNumbers.push(value);
}

validationChantier(reason, change,value) {

  this.arrayChantier.push(this.tech1,this.resp)
  this.Chantier = {
    type: this.type,
    date: this.date,
    caseId: this.caseId.caseId,
    statut: "En cours",
    tech: this.arrayChantier,
    title: this.title,
    infos: this.infos,
  };
  console.log(this.Chantier)
  this.sChantier.createChantier(this.Chantier).then(res => {
  this.showAllCaseChantier();
  this.closeModale();
  })
  this.sHistoriques.createHistorique(this.caseId.caseId, this.case.customer._id, reason, change,value,this.sRoles.user.id).then(res => {
    if (res['error']) {
      alert(res);
    }
  });
}

componentMethodName(event: any, isChecked: boolean) {
    if (isChecked) {
      this.validTech.push(event.target.value)
    }
    else {
      let index = this.validTech.indexOf(event.target.value);
      this.validTech.splice(index, 1);
    }
  }

  closeModale()
  {
    this.dialogRef.closeAll();
  }

}
