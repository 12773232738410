import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-part10',
  templateUrl: './part10.component.html',
  styleUrls: ['./part10.component.scss']
})

export class Part10Component implements OnInit {

  @ViewChild('myCanvas')
  public canvas: ElementRef;
  public context: CanvasRenderingContext2D;
  public chartType: string = 'line';
  public chartData;
  public chartLabels: any[];
  public chartColors;
  public chartOptions: any;

  step = 0;
  textHeader = "Pour minimiser sa consommation en énergie votre maison a besoin d’une complémentarité de solutions.";
  selectedSpans: number[] = [];

  isChecked = false;
  isChecked2 = false;
  isChecked3 = false;
  isChecked4 = false;

  isGaz = false;
  isElec = false;

  all = [1, 1.15, 1.3, 1.45, 1.55, 1.65, 1.75, 1.85, 1.8, 1.7, 1.6];
  medium = [1, 1.35, 1.5, 1.65, 1.75, 1.85, 1.95, 2, 1.95, 1.90, 1.85];
  allClean = [1, 1.45, 1.6, 1.75, 1.9, 2.1, 2.25, 2.35, 2.30, 2.25, 2.2];

  lineArray = [];
  caseId

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.caseId = this.route.snapshot.params['id'];

    this.chartData = [
      {
        data: [],
        label: 'Anthracnose',
        fill: false,
      },
    ];
    this.chartLabels = [
      '2024',
      '2025',
      '2026',
      '2027',
      '2028',
      '2029',
      '2030',
      '2031',
      '2032',
      '2033',
      '2034',
    ];
    this.chartColors = [
      {
        borderColor: '#FC7503',
      },
    ];
    this.chartOptions = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              //stepSize: 6,
              max: 3,
              display: false,
            },
            gridLines: {
              zeroLineColor: 'rgba(252, 117, 3, 0.5)',
              color: 'rgba(0, 0, 0, 0)',
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              zeroLineColor: 'rgba(252, 117, 3, 0.5)',
            },
            ticks: {
              fontColor: 'rgba(252, 117, 3, 0.5)',
              maxTicksLimit: 1,
            },
          },
        ],
      },
      elements: {
        point: {
          radius: 0,
        },
      },

      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
    };

    const getLocal = JSON.parse(localStorage.getItem('book'))
    const getData2 = (getLocal['facture2'])


    if (getData2?.electricité > 1) {
      this.isElec = true;
    }

    if (getData2?.gaz > 1) {
      this.isGaz = true;
    }
    this.choiceline()

  }


  switchVmc(e) {
    this.choiceline()
  }


  switchIsolation(e) {
    this.choiceline()

  }
  switchPAC(e) {
    this.choiceline()
  }

  switchPanneau(e) {
    this.choiceline()
  }

  choiceline() {

    if (this.isChecked && this.isChecked2 && this.isChecked3 && this.isChecked4) {
      this.chartColors[0].borderColor = 'rgba(158, 151, 88, 1)';
      this.chartData[0].data = this.all
    }
    else if (!this.isChecked && !this.isChecked2 && !this.isChecked3 && !this.isChecked4) {
      this.chartColors[0].borderColor = 'rgba(252, 117, 3, 1)';
      this.chartData[0].data = this.allClean
    }
    else if (!this.isChecked2 || !this.isChecked4) {
      this.chartColors[0].borderColor = 'rgba(111, 206, 219, 1)';
      this.chartData[0].data = this.medium
    }

  }

  public changeStepAndRouting() {
    this.step++;
    if (this.step === 1) {
      this.textHeader = "En effet, coupler plusieurs solutions d’énergie va générer une synergie et former un cercle vertueux : L’isolation, la ventilation et le chauffage sont étroitement liés pour permettre à la fois un réel confort, des économies et une atmosphère intérieure saine.";
    }
    if (this.step === 3) {
      this.router.navigate(['/book/part11', this.caseId]);
    }

  }

  public changeStepPrecedent() {
    this.step--;
    if (this.step < 0) {
      this.router.navigate(['/book/part9', this.caseId]);
    }
  }

  public selectSpan(spanNumber: number): void {
    const index = this.selectedSpans.indexOf(spanNumber);

    if (index === -1) {
      this.selectedSpans.push(spanNumber);
    } else {
      this.selectedSpans.splice(index, 1);
    }
  }

  isSelected(spanNumber: number): boolean {
    return this.selectedSpans.includes(spanNumber);
  }

}





