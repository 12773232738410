<img class="logo_couleur" src="./../../../assets/book/logo_blanc.png" alt="logo" />

<div class="book-container">
    <img src="./../../../assets/book/part21_bg.png" class="bg" />
    <h1>Quels sont vos objectifs ?</h1>
    <p>Dites-nous tout, nous sommes à l’écoute de votre projet !</p>

    <div class="card-sliders">
        <div class="row">
            <div class="col">
                <p>Réduire vos factures</p>
                <ngx-slider class="sliders_custom" [(value)]="reduire" [options]="options"></ngx-slider>

            </div>
            <div class="col">
                <p>Améliorer votre confort</p>
                <ngx-slider class="sliders_custom" [(value)]="ameliorer" [options]="options3"></ngx-slider>

            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>Développer votre autonomie</p>
                <ngx-slider class="sliders_custom" [(value)]="developper" [options]="options5"></ngx-slider>

            </div>
            <div class="col">
                <p>Assainir votre air intérieur</p>
                <ngx-slider class="sliders_custom" [(value)]="assainir" [options]="options2"></ngx-slider>

            </div>
        </div>
        <div class="row">
            <div class="col">
                <p>Augmenter votre classe énergétique</p>
                <ngx-slider class="sliders_custom" [(value)]="value" [options]="options4"></ngx-slider>

            </div>
            <div class="col" style="opacity: 0;">
                <p>vide</p>
                <ngx-slider class="sliders_custom" [(value)]="value" [options]="options4"></ngx-slider>

            </div>
        </div>
    </div>
</div>
<button class="primary-button precedent" [routerLink]="['/book/part20', caseId]">
    <svg style="transform:rotate(-180deg);" width="22" height="20" viewBox="0 0 14 12" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.0303 6.53057C13.3232 6.23768 13.3232 5.76281 13.0303 5.46991L8.25736 0.696943C7.96447 0.40405 7.48959 0.40405 7.1967 0.696943C6.90381 0.989837 6.90381 1.46471 7.1967 1.7576L11.4393 6.00024L7.1967 10.2429C6.90381 10.5358 6.90381 11.0107 7.1967 11.3035C7.48959 11.5964 7.96447 11.5964 8.25736 11.3035L13.0303 6.53057ZM0.5 6.75024H12.5V5.25024H0.5V6.75024Z"
            fill="white" />
    </svg>
    Précédent
</button>
<button class="primary-button suivant" (click)="generateBook()">
    Terminer
    <svg width="22" height="20" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.0303 6.53057C13.3232 6.23768 13.3232 5.76281 13.0303 5.46991L8.25736 0.696943C7.96447 0.40405 7.48959 0.40405 7.1967 0.696943C6.90381 0.989837 6.90381 1.46471 7.1967 1.7576L11.4393 6.00024L7.1967 10.2429C6.90381 10.5358 6.90381 11.0107 7.1967 11.3035C7.48959 11.5964 7.96447 11.5964 8.25736 11.3035L13.0303 6.53057ZM0.5 6.75024H12.5V5.25024H0.5V6.75024Z"
            fill="white" />
    </svg>
</button>