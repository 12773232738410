<div style="padding: 8% 3%;">
<div>
  <div class="d-flex">
    <h2 *ngIf="case.customer">{{ case.customer.name }} - {{ case.customer.mobile }}</h2>
  </div>
    <h2>{{ case.title }}</h2>

  <div class="d-flex comadv">
    <p *ngIf="case.adv">{{ case.adv.username }}</p>
    <p class="space"> - </p>
    <p *ngIf="case.commercial">{{ case.commercial.username }}</p>
  </div>

  <form [formGroup]="caseForm" class="d-flex">
    <form formGroupName="rdv_state">
      <select class="custom-select-rdv rdv" formControlName="status" [ngClass]="case.rdv_state.status != 'Oui' ? 'non-empty' : ''" [(ngModel)]="case.rdv_state.status" value="case.rdv_state.status">
        <option value="" disabled>RDV fait ?</option>
        <option value="Oui">Oui</option>
        <option value="Non pas trouvé">Non pas trouvé</option>
        <option value="Non refus visite">Non refus visite</option>
        <option value="Non porte">Non porte</option>
        <option value="Non annulé">Non annulé</option>
      </select>
    </form>

    <mat-select [ngClass]="case.state" class="custom-select-state rdv" formControlName="state" [(ngModel)]="case.state" value="case.state">
      <mat-option class="{{option}}-select" *ngFor="let option of options" [value]="option">
        {{option}}
      </mat-option>
    </mat-select>
  </form>
</div>



<div class="row mt-4">
  <div class="rdvs-container">
  <div class="col-6">
    <p class="mb-0">Date et heure du R1</p>
    <p>{{ case.r1[0].date | date:'short' }}</p>
  </div>
  <div class="col-6" *ngFor="let rdv of case.rdvs">
    <p class="mb-0">Date et heure du {{ rdv.type | slice:0:5 }}</p>
    <p>{{ rdv.date | date:'short' }}</p>
  </div>
  </div>
</div>


<div class="row">
  <div class="col-12">

    <mat-tab-group id="main" class="mt-3">
      <mat-tab class="custom-tab" label="Description">
        <div>
            <textarea class="custom-textarea" placeholder="Description" [(ngModel)]="case.description"></textarea>
        </div>
      </mat-tab>

      <mat-tab class="custom-tab" label="R1">
        <div class="caseForm">
          <div>
            <textarea class="custom-textarea" placeholder="Débrief" [(ngModel)]="case.r1[0].debrief"></textarea>
          </div>
        </div>
      </mat-tab>
      <mat-tab class="caseForm" *ngFor="let tab Of case.rdvs;let i= index" [label]="tab.type| slice:0:5">
          <textarea class="custom-textarea" [(ngModel)]="tab.description"></textarea>
      </mat-tab>
    </mat-tab-group>

  </div>
</div>

<div class="custom-radio mt-4">
  <h6><strong>Le RDV est-il qualifié ?</strong></h6>
  <input type="radio" id="oui" name="oui" [(ngModel)]="case.qualification" value="qualifié" (change)="saveChange(case, case._id)">
  <label class="ml-0" for="oui" [ngClass]="case.qualification === 'qualifié' ? 'oui' : 'oui-empty'">
    <span class="material-icons">
    done
    </span>OUI</label>
  <input type="radio" id="non" name="non" [(ngModel)]="case.qualification" value="non qualifié" (change)="saveChange(case, case._id)">
  <label for="non" [ngClass]="case.qualification === 'non qualifié' ? 'non' : 'non-empty'">
    <span class="material-icons">
    clear
    </span>NON</label>
</div>
</div>
