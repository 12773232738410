import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CasesService } from '@app/services/cases.service';
import { CustomersService } from '@app/services/customers.service';
import { HistoriqueService } from '@app/services/historique.service';
import { InfosService } from '@app/services/infos.service';
import { TasksGddService } from '@app/services/tasks-gdd.service';
import { UsersService } from '@app/services/users.service';
import { AddBarComponent } from '@app/snackbars/add-bar/add-bar.component';
import { EditBarComponent } from '@app/snackbars/edit-bar/edit-bar.component';
import { AuthenticationService } from '@app/_services';
import * as moment from 'moment';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-create-task-gdd',
  templateUrl: './create-task-gdd.component.html',
  styleUrls: ['./create-task-gdd.component.scss']
})
export class CreateTaskGddComponent implements OnInit {

  task = {
    _id: '',
    title: '',
    commentary: [{
      title: 'Faite',
      selected: false
    }],
    description: '',
    state: 'En cours',
    created_at: new Date(),
    target: '',
    end_date: '',
    auto: null,
    created_by: undefined,
    caseId: undefined,
    customerId: undefined
  }
  advs = []
  taskEdit = false
  currentUser
  editMode = false

  differenceHours
  progressValue
  selectedValue;

  myControl = new FormControl();
  customers = [];
  customerCases;
  filteredOptions;

  customer = {
    name: '',
    id: '',
  }
  case
  descriptionValid = false
  commentaryIsValid = false
  descriptionPlaceholder = ''
  constructor(
    private dialogRef: MatDialog,
    public dialogRef2: MatDialogRef<CreateTaskGddComponent>,
    private sUsers: UsersService,
    private authenticationService: AuthenticationService,
    private sTasksGdd: TasksGddService,
    private sHistoriques: HistoriqueService,
    private sCustomers: CustomersService,
    private sCases: CasesService,
    private _snackBar: MatSnackBar,
    private sInfos: InfosService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.authenticationService.user.subscribe(x => this.currentUser = x);
    this.editMode = data.edit

    if (this.editMode) {
      this.taskEdit = true

      this.task.title = data.task.title,
        this.task.auto = data.task.auto,
        this.task.commentary = data.task.commentary
      this.task.state = data.task.state
      this.task.end_date = data.task.end_date
      this.task._id = this.data.task._id,
        this.task.target = data.task.target;
      this.task.caseId = data.task.caseId
      this.task.customerId = data.task.customerId
      this.task.created_at = data.task.created_at
    }
    else {
      this.task.auto = false
    }

    this.selectedValue = this.task.commentary.filter(c => c.selected)[0]
    if (this.task.title === 'LCC') {
      this.descriptionPlaceholder = 'Renseigner le numéro d\'envoi (ex: 3X 006 200 1385 2)'
    }
    else if (this.task.title === 'Appel de confort') {
      this.descriptionPlaceholder = "Renseigner le débrief de l'appel"
    }

  }

  ngOnInit(): void {
    this.sUsers.getAllAdv().subscribe(res => {
      this.advs = res['users'].filter(adv => adv.isActive);
    });
    this.getHoursTasks(this.task.created_at, this.task.end_date)
    this.getAllCustomers()


  }

  closeModale() {
    this.dialogRef2.close('close');
  }

  addTask() {
    this.task.created_by = this.currentUser.id
    this.sTasksGdd.create(this.task).then(res => {
      console.log(res);
      if (res['error']) {
        alert(res.error);
      }
      else {
        let info = {
          comment: 'vous a affecté une tâche GDD',
          reason: 'task_gdd',
          created_by: this.currentUser.id,
          created_at: new Date(),
          caseId: this.task.caseId,
          customerId: this.task.customerId,
          adv: this.task.target
        }
        this.sInfos.createInfo(info).then(res => {
          console.log(res);
          if (res['error']) {
            alert(res.error);
          }
        });
        this.dialogRef.closeAll();
        this._snackBar.openFromComponent(AddBarComponent, { duration: 2000, panelClass: ['add-snackbar'] });
      }
    });
  }

  editTask() {
    this.task.state = 'Terminé'
    this.sTasksGdd.editTask(this.task, this.task._id).then(res => {
      console.log(res);
      if (res['error']) {
        alert(res.error);
      }
      else {
        this.dialogRef.closeAll();
        this._snackBar.openFromComponent(EditBarComponent, { duration: 2000, panelClass: ['edit-snackbar'] });
      }
    });

  }


  changeState(task, com) {

    const index = task.commentary.find(item => item.title === com.title);
    const currentTask = index

    task.commentary.forEach(element => {
      element.selected = false
    });
    currentTask.selected = !com.selected

  }

  getHoursTasks(created_at, end_date) {
    let duration = moment.duration(moment(created_at).diff(moment(end_date)));
    let hours = duration.asHours();

    this.differenceHours = Math.abs(hours)

    let actualDuration = moment.duration(moment(created_at).diff(new Date()));
    let actualHours = actualDuration.asHours();
    let actualTaskHour = Math.abs(actualHours)

    this.progressValue = (100 * actualTaskHour) / this.differenceHours + '%';
  }


  getAllCustomers() {
    this.sCustomers.getAllCustomers(1000).subscribe(res => {
      this.customers = res['customers'];
      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filter(name) : this.customers.slice())
        );
    });
  }
  private _filter(name: string) {
    const filterValue = name.toLowerCase();

    return this.customers.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
  displayFn(user): string {
    return user && user.name ? user.name : '';
  }
  showCasesByCustomerId(customer) {
    this.task.customerId = customer._id;
    this.sCases.getAllCasesByCustomerId(customer._id).subscribe(res => {
      this.customerCases = res;
    });
  }

  showAdvOfCase(customerCase) {
    this.sCases.getCaseById(customerCase._id).subscribe(res => {
      this.case = res[0];
      this.task.target = this.case.adv._id
    });
  }

  checkCommentary() {
    const result = this.task.commentary.map(t => t.selected)
    const found = result.find(f => f);
    found ? this.commentaryIsValid = true : this.commentaryIsValid = false;
  }

}
