<div class="book-container">
  <img src="./../../../assets/book/logo_blanc.png" alt="logo" />
  <h1>Testons vos connaissances</h1>
  <div class="sub-container">
    <p class="subtitle">
      Sélectionnez dans la liste les équipements permettant de <strong>produire de l’énergie :</strong>
    </p>

    <div class="checkboxs">
      <div class="checkbox-row">
        <div class="checkbox-item" (change)="checkChoice('Pompe à chaleur')" [(ngModel)]="reponse['Pompe à chaleur']">
          <label class="form__check check">
            <input class="check__input visually-hidden" type="checkbox">
            <span class="check__box"></span>
            <span class="check__text">Pompe à chaleur</span>
          </label>
        </div>
        <div class="checkbox-item" (change)="checkChoice('Ballon thermodynamique')"
          [(ngModel)]="reponse['Ballon thermodynamique']">
          <label class="form__check check">
            <input class="check__input visually-hidden" type="checkbox">
            <span class="check__box"></span>
            <span class="check__text">Ballon thermodynamique</span>
          </label>
        </div>
      </div>

      <div class="checkbox-row">
        <div class="checkbox-item" (change)="checkChoice('Voiture électrique')"
          [(ngModel)]="reponse['Voiture électrique']">
          <label class="form__check check">
            <input class="check__input visually-hidden" type="checkbox">
            <span class="check__box"></span>
            <span class="check__text">Voiture électrique</span>
          </label>
        </div>
        <div class="checkbox-item" (change)="checkChoice('Autoconsommation')" [(ngModel)]="reponse['Autoconsommation']">
          <label class="form__check check">
            <input class="check__input visually-hidden" type="checkbox">
            <span class="check__box"></span>
            <span class="check__text">Autoconsommation</span>
          </label>
        </div>
      </div>
    </div>
  </div>


  <div class="sub-container" style="    width: 50%;
    margin: auto;
    ">
    <button class="primary-button" (click)="suivant()" *ngIf="reponse.length > 0">
      Valider votre réponse
    </button>
  </div>
</div>