<h5 class="recette">Recette {{ currentKit.name }}</h5>
<app-header-info [caseId]='caseId'></app-header-info>
<app-progressbar [data]="data" (onData)="updateData($event)"></app-progressbar>
<div *ngIf="currentKit">
  <app-recette-vmc *ngIf="currentKit.type === 'VMC Double Flux'" [data]="data" (onData)="updateData($event)" [chantierId]='chantierId' [currentKit]='currentKit' [case]='case'></app-recette-vmc>
  <app-recette-adoucisseur *ngIf="currentKit.type === 'Adoucisseur d\'eau'" [data]="data" (onData)="updateData($event)" [chantierId]='chantierId' [currentKit]='currentKit' [case]='case'></app-recette-adoucisseur>
  <app-recette-mante *ngIf="currentKit.type === 'Mise aux normes du tableau électrique'" [data]="data" [chantierId]='chantierId' (onData)="updateData($event)" [currentKit]='currentKit' [case]='case'></app-recette-mante>
  <app-recette-bt-monobloc *ngIf="currentKit.type === 'Ballon thermodynamique' && currentKit.type_bt === 'monobloc'" [chantierId]='chantierId' [data]="data" (onData)="updateData($event)" [currentKit]='currentKit' [case]='case'></app-recette-bt-monobloc>
  <app-recette-bt-split *ngIf="currentKit.type === 'Ballon thermodynamique' && currentKit.type_bt === 'split'" [chantierId]='chantierId' [data]="data" (onData)="updateData($event)" [currentKit]='currentKit' [case]='case'></app-recette-bt-split>
  <app-recette-pacaa-split *ngIf="currentKit.type === 'PAC Air/Air' && currentKit.type_pac === 'Split'" [chantierId]='chantierId' [data]="data" (onData)="updateData($event)" [currentKit]='currentKit' [case]='case'></app-recette-pacaa-split>
  <app-recette-pacaa-gainable *ngIf="currentKit.type === 'PAC Air/Air' && currentKit.type_pac === 'Gainable'" [chantierId]='chantierId' [data]="data" (onData)="updateData($event)" [currentKit]='currentKit' [case]='case'></app-recette-pacaa-gainable>
  <app-recette-pacae *ngIf="currentKit.type === 'PAC Air/Eau'" [chantierId]='chantierId' [data]="data" (onData)="updateData($event)" [currentKit]='currentKit' [case]='case'></app-recette-pacae>
  <app-recette-pv-micro-onduleur *ngIf="currentKit.type === 'Panneaux solaires' && currentKit.onduleur === 'micro onduleur'" [chantierId]='chantierId' [data]="data" (onData)="updateData($event)" [currentKit]='currentKit' [case]='case'></app-recette-pv-micro-onduleur>
  <app-recette-pv-onduleur *ngIf="currentKit.type === 'Panneaux solaires' && currentKit.onduleur === 'onduleur'" [chantierId]='chantierId' [data]="data" (onData)="updateData($event)" [currentKit]='currentKit' [case]='case'></app-recette-pv-onduleur>
</div>
