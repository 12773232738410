<div style="margin-left: -2rem;
margin-right: -14rem; margin-top: 2rem;">

  <div style="display: flex;align-items: baseline;justify-content: space-between;">
    <div style="margin-top: 1.5em; cursor: pointer;" (click)="goBack()">
      <span>
        < Retour </span>
    </div>
    <button class="btn-secondarycrm" (click)="openDialog()">Nouvelle comparaison</button>
  </div>



  <div style="display: flex; justify-content: center; align-items: center;margin-left: -2rem;">
    <canvas id="canvas" *ngIf="choice !== 'globales'">{{ chart }}</canvas>
    <canvas id="barStacked" *ngIf="choice === 'globales'">{{ chartBarStacked }}</canvas>
  </div>

  <div style="margin-left: 1rem;" *ngIf="choice !== 'globales'">
    <div *ngFor="let label of labels; let i = index">
      <h2>{{ label }}</h2> <span>{{ data[i] | currency:'EUR':"symbol" }}</span>
    </div>
  </div>

  <div style="margin-left: 1rem;" *ngIf="choice === 'globales'">
    <div *ngFor="let label of labels; let i = index">
      <h2>{{ label }}</h2> 
      <span><strong>{{ selectedYear }}</strong> - {{ annee1[i] }}</span><br>
      <span><strong>{{ selectedFirstYear }}</strong> - {{ annee2[i] }}</span>

    </div>
  </div>
</div>