import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {

  @Input() kit;
  v2
  v1
  products = []
  selectedProducts;
  autres = []
  constructor() { }

  ngOnInit(): void {
    this.checkKit()
  }
  checkKit() {
    if(this.kit != undefined) {
      if(this.kit.startsWith('['))
        this.v2 = true
      else
        this.v1 = true
      if(this.kit != null && this.kit.startsWith('[')) {
        JSON.parse(this.kit).forEach(element => {
          if(element.active) {
            this.products.push(element.name)
          }
        });
        const array1 = this.products;
        const parseKits = JSON.parse(this.kit)

        parseKits.forEach(element => {
        // si un produit du kit est dans le tableau
        if(array1.includes(element.name)) {
          // on vient éditer l'object associé dans products en active true
          let result = parseKits.filter(e => e.name === element.name)[0]

          if(!result.active) {
            parseKits.filter(e => e.name === element.name)[0].active = true
          }
        }
        else {
          parseKits.filter(e => e.name === element.name)[0].active = false
        }
        });
        this.selectedProducts = JSON.stringify(parseKits)
        this.selectedProducts = JSON.parse(this.selectedProducts).filter(res => res.active)

        this.products.forEach(element => {
          if(element != 'Adoucisseur d`\'eau' &&
              element != 'Ballon thermodynamique' &&
              element != 'Batterie' &&
              element != 'Mise aux normes du tableau électrique' &&
              element != 'PAC Air/Air' &&
              element != 'PAC Air/Eau' &&
              element != 'Panneaux solaires' &&
              element != 'Thermostat radio' &&
              element != 'Isolation' &&
              element != 'VMC Double Flux') {
                this.autres.push(element)
              }
        })
        ////////////////////////////////////
      }
      else {
        this.products === this.kit
      }
    }
    else {
      this.v1 = true
    }
  }


}
