import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { AuthenticationService } from '@app/_services';
import { InfosService } from '@app/services/infos.service';
import { UsersService } from '@app/services/users.service';
import { HttpClient } from '@angular/common/http';
import { apiUrl } from '@environments/env';

@Component({
  selector: 'app-commercial-case-list',
  templateUrl: './commercial-case-list.component.html',
  styleUrls: ['./commercial-case-list.component.scss'],
})
export class CommercialCaseListComponent implements OnInit {
  countUnread;

  customerId: string;
  currentUser;
  cases;
  commercialCases;
  cardActive = false;
  currentChoice;
  showCurrentUserCases = false;
  states = [
    { name: 'Signée', active: false },
    { name: 'Prospection', active: false },
    { name: 'Négociation', active: false },
  ];

  filter = {
    state: 'all',
    title: '',
  };
  commerciaux = [];
  stateValue = 'all';
  mobile;
  showUnread = true;
  showRead = true;
  infos;
  infosRead = [];
  infosUnread = [];
  currentAgency;
  currentSelected;
  userIsActive;

  constructor(
    private authenticationService: AuthenticationService,
    private sCases: CasesService,
    private sInfos: InfosService,
    private router: Router,
    private sUsers: UsersService,
    private http: HttpClient
  ) {
    this.authenticationService.user.subscribe((x) => (this.currentUser = x));
    if (this.currentUser.role != 'Admin') {
      this.showCommercialById();
    }
    this.sUsers.getUserById(this.currentUser.id).subscribe((res) => {
      this.userIsActive = res[0].isActive;
    });
  }

  ngOnInit(): void {
    if (window.screen.width <= 600) {
      this.mobile = true;
    }
    this.showCasesByCommercialId();
    this.showInfosByAdvId();
    this.getGroups();
  }

  showCasesByCommercialId() {

    if(this.currentUser.role == "Admin"){
      this.sCases.getAllCasesForAdmin().subscribe(res => {
        this.cases = res['cases'];
      });
    } else {
      this.sCases
        .getIsHiddenFalseByCommercialId(this.currentUser.id)
        .subscribe((res) => {
          this.cases = res;
        });
    }
  }
  showCasesByCommercial(id) {
    if (id === this.currentSelected) {
      this.commercialCases = [];
      this.currentSelected = null;
    } else {
      this.commercialCases = [];
      this.currentSelected = id;
      this.sCases.getAllCasesByCommercialId(id).subscribe(res => {
      this.commercialCases = res;
      });
    }
  }
  showCommercialByAgency(agency) {
    this.sUsers.getCommercialByAgency(agency).subscribe((res) => {
      res['user']
        .filter((r) => r.isActive)
        .forEach((element) => {
          if (
            element.role != "Responsable d'agence" &&
            element.username != 'test'
          ) {
            this.commerciaux.push(element);
          }
        });
    });
  }
  showCommercialById() {
    this.sUsers.getUserById(this.currentUser.id).subscribe((res) => {
      this.currentAgency = res[0].agency;
      this.showCommercialByAgency(this.currentAgency);
    });
  }

  setFilter(value) {
    if (this.filter.state == value) {
      this.filter.state = 'all';
      this.states.forEach((element) => {
        element.active = false;
      });
    } else {
      this.filter.state = value;
      this.states.forEach((element) => {
        if (value == element.name) {
          element.active = true;
        } else {
          element.active = false;
        }
      });
    }
  }

  showInfosByAdvId() {
    this.sInfos.getInfoByAdvId(this.currentUser.id).subscribe((res) => {
      this.infos = res;
      this.infos.forEach((element) => {
        if (element.vu) {
          this.infosRead.push(element);
        } else {
          this.infosUnread.push(element);
        }
      });
    });
  }
  goToCommercialTemplate(id) {
    this.router.navigate(['commercial-template', id]).then(() => {
      window.location.reload();
    });
  }
  async getGroups(): Promise<void> {
    try {
      const countUnread: any = await this.http
        .get(apiUrl.apiUrl + '/messages/count-unread')
        .toPromise();
      this.countUnread = countUnread;
    } catch (error) {}
  }
}
