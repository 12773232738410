import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CasesService } from '@app/services/cases.service';
import { TasksService } from '@app/services/tasks.service';

@Component({
  selector: 'app-admin-qualification-detail',
  templateUrl: './admin-qualification-detail.component.html',
  styleUrls: ['./admin-qualification-detail.component.scss']
})
export class AdminQualificationDetailComponent implements OnInit {
  caseForm: FormGroup;
  case;
  options = ['Négociation', 'Prospection', 'Signée', 'Non Signée', 'Annulée'];
  curTab: any = 0;
  task;
  constructor(
    private sCases: CasesService,
    private dialogRef: MatDialog,
    private fb: FormBuilder,
    private sTasks: TasksService,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
    this.case = data.case;
    this.caseForm = this.fb.group({
      rdv_state: this.fb.group({
        'status': [this.case.rdv_state.status],
        'why': [this.case.rdv_state.why],
      }),
      "state": [this.case.rdv_state.state],
      "commercial": [this.case.commercial],
      "adv": [this.case.adv],
      "datesign": [null],
      "datecancel": [null],
      "qualification": [],
    });
  }

  ngOnInit(): void {
    this.showTasksByTargetId()
  }
  currTab(event) {
    this.curTab = event.index;
  }
  saveChange(currentCase, caseId) {
    this.editStateTask()
    this.sCases.editCase(currentCase, caseId).then(res => {
      if (res['error']) {
        return res.error;
      }
      this.closeModale();
    });
  }

  showTasksByTargetId() {
    const message = 'Qualifier le rdv ' + this.case.customer.name.trim()
    this.sTasks.getTaskByMessage(message).subscribe(res => {
      this.task = res[0];
    })
  }

  editStateTask() {
    this.task.state = "3-Faite"
    this.sTasks.editTask(this.task, this.task._id).then(res => {
      if (res['error']) {
        alert(res.error);
      }
    });
  }

  closeModale() {
    this.dialogRef.closeAll();
  }
}
