import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-part18',
  templateUrl: './part18.component.html',
  styleUrls: ['./part18.component.scss']
})
export class Part18Component implements OnInit {

  caseId

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.caseId = this.route.snapshot.params['id'];
  }
}
