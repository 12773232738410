import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrl } from '@environments/env';

@Injectable({
  providedIn: 'root'
})
export class PicturesService {

  local_url = apiUrl.apiUrl + '/picture/';
  //local_url = 'http://localhost:4000/picture/'
  headers = { 'Content-Type': 'application/json' };
  headersGet ={ 'Content-Type': 'application/pdf' };

  constructor(
    private http: HttpClient,

  ) { }

  getAllPictures() {
    return this.http.get(this.local_url + '');
  }

  getPicturesByUserId(id) {
    return this.http.get(this.local_url + id  );
  }

  getTechPicturesByCaseId(id) {
    return this.http.get(this.local_url +'tech/' + id);
  }


  getPictureById(id) {
    return this.http.get(this.local_url + 'picture/' + id);
  }

  delete(id) {
    return this.http.delete(this.local_url + id);
  }


  editPicture(picture, pictureId): Promise<any> {
    return this.http
    .put(this.local_url + pictureId, JSON.stringify(picture) , { headers: this.headers })
    .toPromise()
    .then(res => res as any);
  }
}
