import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { RolesService } from '@app/services/roles.service';
import { HistoriqueService } from './../../services/historique.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-case-historique',
  templateUrl: './case-historique.component.html',
  styleUrls: ['./case-historique.component.scss']
})
export class CaseHistoriqueComponent implements OnInit {

  historiques;
  caseId;
  case;
  userId;
  supprReason = [];
  isfrmChecked;
  choiceSelected;
  searchForm:FormGroup;

  constructor(
    private sHistoriques: HistoriqueService,
    private route: ActivatedRoute,
    private sCases: CasesService,
    private sRoles: RolesService,
    private fb:FormBuilder,
    private _location:Location
  ) {
    this.caseId = this.route.snapshot.params['id'];
    this.userId = this.sRoles.user.id;
    this.searchForm = this.fb.group({
      address: [],
      name: [],
      })
   }

  ngOnInit(): void {
    this.showHistorique();
    this.showCaseInfos();
  }

  showCaseInfos() {
    return new Promise(resolve => {

    this.sCases.getCaseById(this.caseId).subscribe(res => {
      this.case = res[0];
      resolve(res);
        }, error => {
          console.log(error);
      });
    });
  }

  showHistorique() {
    return new Promise(resolve => {
      this.sHistoriques.getHistoriqueByCaseId(this.caseId).subscribe(res => {
        this.historiques = res;
        resolve(res);
      }, error => {
        console.log(error);
      });

      })
  }

  saveChange(reason, change, value) {
  this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change,value,this.userId).then(res => {
    if (res['error']) {
      alert(res);
    }
  });
 }

 componentMethodName(event: any, isChecked: boolean)
 {
   if (isChecked) {
    var item = this.historiques.filter(item => item.reason.includes(event.target.value));
    this.historiques = item;
    this.choiceSelected = event.target.value
   }
   else {
     this.showHistorique()
     this.choiceSelected = ''
   }
 }

 filterByRole(event: any, isChecked: boolean)
 {
   if (isChecked) {
    var item = this.historiques.filter(item => item.created_by.role.includes(event.target.value));
    this.historiques = item;
    this.choiceSelected = event.target.value
   }
   else {
     this.showHistorique()
     this.choiceSelected = ''
   }
 }

 goBack() {
  this._location.back();
}

}
