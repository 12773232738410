<button style="display:none;" id="launch" data-toggle="modal" data-target="#exampleModal"></button>
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="banner" [ngStyle]="{'background':getColor(selectedUser.role)}"></div>

      <div style="background:white;border-radius: 0 0 4px 4px;">
            <div class="round">
              <p>{{getShortName(selectedUser.username)}} </p>
            </div>
              <h4 class="card-title">
                  <p> {{selectedUser.username}}</p>
              </h4>
              <p class="text-center">{{selectedUser.role}}</p>
              <br>
              <br>
              <p>{{ selectedUser.created_at | date:'dd/MM/yy' }}</p>

              <div class="row buttons" style="justify-content: center;">
                <button [matTooltip]=selectedUser.mail><mat-icon>email</mat-icon></button>
                <button matTooltip="Utilisateur désactivé" style="background: #f45b69;color: #76262d;" *ngIf="!selectedUser.isActive" (click)="disabled(selectedUser._id,selectedUser.isActive)"><mat-icon>cancel</mat-icon></button>
                <button matTooltip="Utilisateur activé" style="background: #78C091;color: #4b7c5c;" *ngIf="selectedUser.isActive" (click)="disabled(selectedUser._id,selectedUser.isActive)"><mat-icon>check</mat-icon></button>
                <button matTooltip="supprimer l'utilisateur" (click)="deleteUser(selectedUser._id)"><mat-icon>delete</mat-icon></button>
              </div>
      </div>
    </div>
  </div>
</div>

<div class="row mt-5 ml-1 mb-3 justify-content-between">
    <button mat-raised-button class="btn-action" style="color:#78C091;" (click)="addUser()">
      <mat-icon>add</mat-icon>
      Utilisateur</button>

    <button mat-raised-button class="btn-action" style="color:#78C091;" (click)="getSelectedRowData()">
            <mat-icon>done</mat-icon>Valider la sélection
    </button>
    <button mat-raised-button class="btn-action" style="color:#3a3b5a;" (click)="selectAllCommActif()">
            <mat-icon>supervised_user_circle</mat-icon>Commerciaux
    </button>
    <button mat-raised-button class="btn-action" style="color:#3a3b5a;" [disabled]="selectedUsers === undefined ||selectedUsers.length === 0" (click)="confirmChangePassword()">
            <mat-icon>vpn_key</mat-icon>Recréer mot de passe
    </button>
    <button mat-raised-button class="btn-action" style="color:#3a3b5a;" (click)="disconnectAll()">
            <mat-icon>power_settings_new</mat-icon>Déconnecter la sélection
    </button>
</div>

<table class="table" *ngIf="cleaningPasswords.length != 0">
  <thead>
    <tr>
      <th scope="col">Email</th>
      <th scope="col">Mot de passe</th>
    </tr>
  </thead>
  <tbody *ngFor="let user of cleaningPasswords">
    <tr>
      <td>{{ user.user }}</td>
      <td>{{ user.password }}</td>
    </tr>
  </tbody>
</table>

<ag-grid-angular
#agGrid
style="width: 75vw; height: 70vh; margin-bottom: 5%;"
id="myGrid"
class="ag-theme-alpine"
[columnDefs]="columnDefs"
[defaultColDef]="defaultColDef"
[rowData]="users"
[rowStyle]="rowStyle"
(gridReady)="onGridReady($event)"
rowSelection="single"
[animateRows]="true"
(cellDoubleClicked)="selectUser($event)"
[rowHeight]="rowHeight"
rowSelection="multiple">
</ag-grid-angular>

