import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-part8',
  templateUrl: './part8.component.html',
  styleUrls: ['./part8.component.scss']
})
export class Part8Component implements OnInit {

  caseId
  firstStep = true;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.caseId = this.route.snapshot.params['id'];
  }

  public changeStepAndRouting() {
    if (this.firstStep) {
      this.firstStep = !this.firstStep;
    }
    else {
      this.router.navigate(['/book/part9', this.caseId]);

    }
  }
}
