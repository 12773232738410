import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ModalPrevisualizeComponent } from '@app/pv/modal-previsualize/modal-previsualize.component';
import { NoModalComponent } from '@app/pv/no-modal/no-modal.component';
import { CasesService } from '@app/services/cases.service';
import { PdfsService } from '@app/services/pdfs.service';
import { PiecesService } from '@app/services/pieces.service';

@Component({
  selector: 'app-recette-mante',
  templateUrl: './recette-mante.component.html',
  styleUrls: ['./recette-mante.component.scss']
})
export class RecetteManteComponent {

  @Input() currentKit;
  @Input() case;
  @Input() chantierId
  @Input()
  public data: any

  @Output()
  public onData: EventEmitter<any> = new EventEmitter<any>();

  firstFormGroup: FormGroup;

  _step: number = 1;
  techs = ["Général - entre 1m et 1,80m","Handicapé - entre 0,90m et 1,30m","Personage agée - entre 1m et 1,30m"]

  isValid
  loader
  createDoc
  pieces
  multipleImagesPieces
  mobile = false
  mante = false

  @Input()
  set step(_step: number) {
    this._step = this.parseNumber(_step);
  }
  private parseNumber(num: any): number {
    return +num;
  }
  ngAfterContentInit() {
    this.updateData(100)
    this.firstFormGroup = this._formBuilder.group({
       local_pose: [this.currentKit.local_pose, Validators.required],
       inter_differentiel: [this.currentKit.inter_differentiel],
       module_reserve: [this.currentKit.module_reserve],
       hauteur_compteur: [this.currentKit.hauteur_compteur, Validators.required],
       prise_service: [this.currentKit.prise_service, Validators.required],
       serrage_cable: [this.currentKit.serrage_cable, Validators.required],
       raccord_tableau: [this.currentKit.raccord_tableau, Validators.required],
       reperage: [this.currentKit.reperage, Validators.required],
       nettoyage: [this.currentKit.nettoyage, Validators.required],
       signalisation: [this.currentKit.signalisation, Validators.required],
       plaque_protege: [this.currentKit.plaque_protege, Validators.required],
       linge_protege: [this.currentKit.linge_protege, Validators.required],
       prise_vehicule_protege: [this.currentKit.prise_vehicule_protege, Validators.required],
       abonnement_reseau: [this.currentKit.abonnement_reseau, Validators.required],
       puissance_abonnement_reseau: [this.currentKit.puissance_abonnement_reseau, Validators.required],
       circuit_prises: [this.currentKit.circuit_prises, Validators.required],
       circuit_eclairage: [this.currentKit.circuit_eclairage, Validators.required],
      });

  }


  constructor(
    private _formBuilder: FormBuilder,
    private sCases: CasesService,
    private dialog:MatDialog,
    private sPdfs: PdfsService,
    private sPieces: PiecesService
  ) {
    if (window.screen.width <= 600) {
      this.mobile = true;
    }
   }

  updateData(data) {
    this.onData.emit(data)
  }

  getCurrentKitAndSaveChange(event,key) {

    this.currentKit[key] = event;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === this.currentKit.type);

    array[index] = this.currentKit

    this.case.kits_vendus = JSON.stringify(array)
    this.sCases.editCase(this.case, this.case._id).then(res => console.log(res))
  }

  incrementValueDiff(step: number = 1,name,reason, option,product): void {

    this.currentKit['inter_differentiel'] = this.currentKit['inter_differentiel'] + step
    console.log(this.currentKit)
    const newValue = this.currentKit['inter_differentiel']
    this.firstFormGroup.value.inter_differentiel =  newValue;
    
    this.addOption(name,reason, option,product, newValue)
  }

  incrementValueModule(step: number = 1,name,reason, option,product): void {

    this.currentKit['module_reserve'] = this.currentKit['module_reserve'] + step

    const newValue = this.currentKit['module_reserve']
    this.firstFormGroup.value.module_reserve =  newValue;

    this.addOption(name,reason, option,product, newValue)
  }

  addOption(name,reason, option,product, value) {

    let find = JSON.parse(this.case.kits_vendus).find(x => x.name === name);
    find[option] = value;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === name);
    const selected = array[index]
    this.currentKit = selected
    selected[option] = value

    this.case.kits_vendus = JSON.stringify(array)
    this.sCases.editCase(this.case, this.case._id).then(res => console.log(res))

  }

  openDialog(value): void {
    const dialogRef = this.dialog.open(NoModalComponent, {
      width: '640px',
      disableClose: true,
      data: {
        currentKit: this.currentKit,
        case: this.case,
        value: value
      }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }


  async valid() {
    this.isValid = true
    this.loader = true
    this.createDoc = await this.generateDoc();
    this.loader = false
    this.openDialogPrevisualize()
  }


  async generateDoc() {
    let response = await this.sPdfs.generateRecette(this.chantierId,'recette_tableau_electrique',this.case,this.currentKit,"","").then(res => {
      if(res === 'ok') {
        console.log(res)
      }
    })
    return response;
  }

  openDialogPrevisualize(): void {
    setTimeout(() => {
      const dialogRef = this.dialog.open(ModalPrevisualizeComponent, {
        width: '100vh',
        height:  '100vh',
        maxWidth: '100vh',
        maxHeight: '100vh',
        hasBackdrop: false,
        disableClose: true,
        panelClass: 'pdf-visualize',
        data: {
          titlePiece: 'recette_tableau_electrique',
          caseId: this.case._id,
          case: this.case,
          kit: this.currentKit,
          chantierId: this.chantierId
        }
      });
      dialogRef.afterClosed().subscribe(result => {

      });
    }, 2000);

  }
  selectMultiple(event, title) {
    if (event.target.files.length > 0) {
      this.multipleImagesPieces = event.target.files;
      console.log(this.multipleImagesPieces);
    }
    this.onSubmit(title)
  }
  onSubmit(title) {
    const form = {
      title: title,
      qualification: 'Conforme',
      caseId: this.case._id,
      created_at: new Date(),
      type: 'technicien',
      chantier: this.chantierId._id,
      cible: 'recette_tableau_electrique',
      description: '',
    }
    this.sPieces.addTechPiece(form, this.multipleImagesPieces, this.case._id, this.chantierId._id)
     setTimeout(() => {
      this.checkPiece()
    }, 1100);
  }
  checkPiece() {
    this.sPieces.getPiecesByChantierIdAndCible(this.chantierId._id, 'recette_tableau_electrique').subscribe(res => {
      console.log(res)
      this.pieces= res
      if(this.pieces.find(e => e.title === 'Tableau electrique'))
        this.mante = true
    })

  }

}
