import { Component, OnInit } from '@angular/core';
import { ChantierService } from '@app/services/chantier.service';
import { InfosService } from '@app/services/infos.service';
import { RolesService } from '@app/services/roles.service';
import { AuthenticationService } from '@app/_services';

@Component({
  selector: 'app-technicien-dashboard',
  templateUrl: './technicien-dashboard.component.html',
  styleUrls: ['./technicien-dashboard.component.scss']
})
export class TechnicienDashboardComponent implements OnInit {
  chantiers;
  user;
  mobile;
  todayChantier =[];
  morningChantier =[];
  afternoonChantier =[];
  infos;
  infosRead = [];
  infosUnread = [];
  attenteChantier

  constructor(
    private authenticationService: AuthenticationService,
    private sChantier: ChantierService,
    private sInfos: InfosService,
    private sRoles: RolesService
  ) {
    if (window.screen.width <= 600) {
      this.mobile = true;
    }

    this.authenticationService.user.subscribe(x => this.user = x);
  }

  ngOnInit(): void {
    this.showAllTechChantier()
    this.showInfosByAdvId()
  }

  showAllTechChantier() {
    this.sChantier.getAllChantierByTechId(this.user.id).subscribe(res => {
      this.chantiers = res
      this.chantiers.sort((a, b) => (a.date < b.date) ? -1 : 1)

      this.getDayChantier()
    });
  }

  getDayChantier(){
  this.todayChantier =[]
  const today = new Date()
  const dayNow = today.getDate()
  const monthNow = today.getMonth()+1

  this.chantiers.forEach(element => {
    if(element.date != undefined || null) {
      let taskMonth = element.date.substr(5,2)
      let taskDay = element.date.substr(8,2)
      if(monthNow == taskMonth && dayNow == taskDay){
        this.todayChantier.push(element)
        if(element.date.substr(11,2) < 12  ){
          this.morningChantier.push(element)
        }else{
          this.afternoonChantier.push(element)
        }
      }
    }

    this.morningChantier = this.morningChantier.filter(res =>( new Date(res.date).getFullYear()) === new Date().getFullYear())
    this.afternoonChantier = this.afternoonChantier.filter(res =>( new Date(res.date).getFullYear()) === new Date().getFullYear())

    });
  }

  showInfosByAdvId() {
    this.sInfos.getInfoByAdvId(this.sRoles.user.id).subscribe(res => {
      this.infos = res;
      this.infos.forEach(element => {
         if(element.vu) {
          this.infosRead.push(element)
        }
        else {
          this.infosUnread.push(element)
        }
      });
    })
  }

}
