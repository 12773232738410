
<form [formGroup]="tmkAffaire">

<img *ngIf="currentRole != 'Tmk' && currentRole != 'Superviseur TMK' && currentRole != 'Beenergie'"
style="
position: relative;
    left: 97%;
    width: 6%;
    cursor: pointer;"
src="../../../assets/icons/cross.svg" (click)="closeModale()">
<div class="row mt-5 " >
  <div class="col-12">
    <input
      formControlName="title"
      id="title"
      type="text"
      class="form-control mb-4 pb-4 pt-4"
      placeholder="Titre" required>
    <div class="alert alert-danger" *ngIf="!tmkAffaire.get('title').valid && tmkAffaire.get('title').touched">
      <div *ngIf="!tmkAffaire.get('title').valid && tmkAffaire.get('title').touched">Un titre est requis</div>
    </div>
  </div>
</div>

<div class="row">
  <div formGroupName="tmkClient" class="col-6">
    <input
        formControlName="name"
        id="name"
        type="text"
        class="form-control"
        placeholder="NOM Prénom" required>
    <div class="alert alert-danger" *ngIf="!tmkAffaire.get('tmkClient.name').valid && tmkAffaire.get('tmkClient.name').touched">
      <div *ngIf="!tmkAffaire.get('tmkClient.name').valid && tmkAffaire.get('tmkClient.name').touched">Un nom est requis</div>
    </div>
  </div>

  <div formGroupName="tmkClient" class="col-6">
    <input
        formControlName="address"
        id="address"
        type="text"
        class="form-control"
        placeholder="Adresse" required>
    <div class="alert alert-danger" *ngIf="!tmkAffaire.get('tmkClient.address').valid && tmkAffaire.get('tmkClient.address').touched">
      <div *ngIf="!tmkAffaire.get('tmkClient.address').valid && tmkAffaire.get('tmkClient.address').touched">Une Adresse est requise</div>
    </div>
  </div>
</div>

<div class="row">
<div formGroupName="tmkClient" class="col-6">
  <input
      formControlName="cp"
      id="cp"
      type="number"
      class="form-control"
      placeholder="Code Postal" required>
  <div class="alert alert-danger" *ngIf="!tmkAffaire.get('tmkClient.cp').valid && tmkAffaire.get('tmkClient.cp').touched">
    <div *ngIf="!tmkAffaire.get('tmkClient.cp').valid && tmkAffaire.get('tmkClient.cp').touched">Un Code Postal est requis</div>
  </div>
</div>

<div formGroupName="tmkClient" class="col-6">
  <input
      formControlName="city"
      id="city"
      type="text"
      class="form-control"
      placeholder="Ville" required>
  <div class="alert alert-danger" *ngIf="!tmkAffaire.get('tmkClient.city').valid && tmkAffaire.get('tmkClient.city').touched">
    <div *ngIf="!tmkAffaire.get('tmkClient.city').valid && tmkAffaire.get('tmkClient.city').touched">Une Ville est requise</div>
  </div>
</div>
</div>

<div class="row">
<div formGroupName="tmkClient" class="col-6">
  <input
      formControlName="fixe"
      id="fixe"
      type="number"
      class="form-control"
      placeholder="Téléphone fixe">
</div>

<div formGroupName="tmkClient" class="col-6">
  <input
      formControlName="mobile"
      id="mobile"
      type="number"
      class="form-control"
      placeholder="Téléphone portable" required
      (change)="comparePhone()">
  <div class="alert alert-danger" *ngIf="!tmkAffaire.get('tmkClient.mobile').valid && tmkAffaire.get('tmkClient.mobile').touched">
    <div *ngIf="!tmkAffaire.get('tmkClient.mobile').valid && tmkAffaire.get('tmkClient.mobile').touched">Un téléphone portable est requis</div>
  </div>
</div>
</div>

<div class="row">
<div formGroupName="tmkClient" class="col-6">
  <input
      formControlName="mail"
      id="mail"
      type="mail"
      class="form-control"
      placeholder="E-mail">
</div>

<div formGroupName="r1" class="col-6">
  <input
      formControlName="date"
      id="date"
      class="form-control empty-date"
      placeholder="Date et heure du RDV "
      [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" required>
      <owl-date-time firstDayOfWeek="1" #dt1></owl-date-time>
  <div class="alert alert-danger" *ngIf="!tmkAffaire.get('r1.date').valid && tmkAffaire.get('r1.date').touched">
    <div *ngIf="!tmkAffaire.get('r1.date').valid && tmkAffaire.get('r1.date').touched">Une date de rdv est requise</div>
  </div>
</div>
</div>

<div class="row">
  <div class="col-12">
    <textarea
      formControlName="description"
      id="description"
      type="text"
      class="form-control description mb-2"
      [ngClass]="currentRole === 'Adv' || 'Admin' ? 'form-control description-adv' : 'form-control description'"
      placeholder="Débrief" required></textarea>
  </div>
</div>

      <div *ngIf="showOrigin && (currentRole != 'Tmk' && currentRole != 'Superviseur TMK' && currentRole != 'Beenergie')" class="row" formGroupName="tmkClient">
        <div class="form-group col-6">
          <select formControlName="origin" required class="form-control select" id="exampleFormControlSelect1">
            <option hidden selected value="Origine du contact">Origine du contact</option>
            <option value="adv">Adv</option>
            <option value="commercial">Commercial</option>
            <option value="google">Google</option>
            <option value="Lead LG PAC">Lead LG PAC</option>
            <option value="Lead ROI">Lead ROI</option>
            <option value="parrainage">Parrainage</option>
          </select>
        </div>
      </div>


      <div class="row">
        <div class="basic-container col-6 " tabindex="-1" #focusMe>
          <mat-form-field class="example-full-width" *ngIf="currentRole === 'Adv' || currentRole === 'Admin' || currentRole === 'Responsable ADV'">
            <mat-select type="text" placeholder="Choisir le commercial" matInput formControlName="commercial">
              <mat-option *ngFor="let commercial of this.sUsers.commerciaux" [value]="commercial._id">
                {{ commercial.username }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

        <div *ngIf="currentRole === 'Tmk' || currentRole === 'Superviseur TMK' || currentRole === 'Beenergie'">
        <input class="form-control empty-date col-4 "  formControlName="datecase" placeholder="Rdv pris le"  [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" required  >
        <owl-date-time firstDayOfWeek="1" #dt2></owl-date-time>
       <div class="alert alert-danger mt-3" *ngIf="!tmkAffaire.get('datecase').valid && tmkAffaire.get('datecase').touched">Une
          Date et heure de rendez-vous
          est requise</div>
        </div>


<div class="row">
  <div class="col-6 offset-5">
    <button class="btn-secondarycrm" *ngIf="this.tmkAffaire.value.r1.date != ''
    && ( this.tmkAffaire.value.tmkClient.mobile != '' || this.tmkAffaire.value.tmkClient.fixe != '' || this.tmkAffaire.value.tmkClient.fixe != null ) && this.tmkAffaire.value.tmkClient.name != ''
    && this.tmkAffaire.value.tmkClient.city != ''
    && this.tmkAffaire.value.tmkClient.cp != '' && this.tmkAffaire.value.tmkClient.address != ''
    && this.tmkAffaire.value.title != '' && (this.tmkAffaire.value.datecase != '' || (this.currentRole != 'Tmk' && this.currentRole != 'Superviseur TMK' && this.currentRole != 'Beenergie')) && mobileValid" (click)="addCustomer()">Créer le client</button>
  </div>
</div>
</form>
