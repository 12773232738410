<mat-horizontal-stepper [linear]="true" #stepper>
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup">
      <ng-template matStepLabel>Fill out your name</ng-template>

      <div>
        <h6>Ballon thermodynamique</h6>
        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Marque et type</label>
            <input class="custom-input" type="text" formControlName="marque_type" (change)="getCurrentKitAndSaveChange(this.firstFormGroup.value.marque_type,'marque_type')">
            <div *ngIf="firstFormGroup.get('marque_type').invalid && firstFormGroup.get('marque_type').errors && (firstFormGroup.get('marque_type').dirty || firstFormGroup.get('marque_type').touched)">
            <small class="text-danger"
                *ngIf="firstFormGroup.get('marque_type').hasError('required')">
                Champ requis
            </small>
            </div>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <div class="button-wrapper mt-1">
              <label>N° de série</label>
              <label class="upload-label" for="upload" *ngIf="!n_serie_bt">+ Ajouter la photo</label>
              <p class="mb-0">
                <a target="blank" class="check" *ngIf="n_serie_bt" [routerLink]="['/detail-piece', piece_serie_bt[0]._id]">Photos n° de série</a>
              </p>
              <p *ngIf="n_serie_bt" style="color: lightgrey;font-weight: 300; margin-top: 0;
              font-size: small;">Prise le {{ piece_serie_bt[0].created_at | date:'medium' }}</p>

              <input *ngIf="!n_serie_bt" accept=".jpg,.jpeg,.png" multiple type="file" multiple name="upload" id="upload" class="upload-box" (change)="selectMultiple($event, 'N° de serie ballon thermodynamique','n_serie_bt')">
            </div>
          </div>
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Volume en litre</label>
            <mat-form-field>
              <mat-select formControlName="capacite" (selectionChange)="addOption(currentKit.type,'edité', 'capacite', 'capacite', firstFormGroup.value.capacite)">
                <mat-option *ngFor="let capacity of capacities" [value]="capacity">
                  {{capacity}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>


        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Local de pose</label>
            <input class="custom-input" type="text" formControlName="local_pose" (change)="getCurrentKitAndSaveChange(this.firstFormGroup.value.local_pose,'local_pose')">
            <div *ngIf="firstFormGroup.get('local_pose').invalid && firstFormGroup.get('local_pose').errors && (firstFormGroup.get('local_pose').dirty || firstFormGroup.get('local_pose').touched)">
            <small class="text-danger"
                *ngIf="firstFormGroup.get('local_pose').hasError('required')">
                Champ requis
            </small>
            </div>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Pièce hors gel:</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="hors_gel" (change)="getCurrentKitAndSaveChange($event.value,'hors_gel')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Tuyauterie calorifugées :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="tuyauterie" (change)="getCurrentKitAndSaveChange($event.value,'tuyauterie')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('tuyauterie')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Bouclage avec robinet thermostatique :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="bouclage" (change)="getCurrentKitAndSaveChange($event.value,'bouclage')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('bouclage')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Installation sur chassis normé</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="chassis" (change)="getCurrentKitAndSaveChange($event.value,'chassis')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('chassis')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Câblage électrique conforme : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="cablage" (change)="getCurrentKitAndSaveChange($event.value,'cablage')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('cablage')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Groupe de sécurité piquage à froid :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="groupe_secu" (change)="getCurrentKitAndSaveChange($event.value,'groupe_secu')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('groupe_secu')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Programmation du BT sur les HC :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="prog_ballon" (change)="getCurrentKitAndSaveChange($event.value,'prog_ballon')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>

        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Présence de fuite d’eau (raccordement froid et chaud)</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="fuite" (change)="getCurrentKitAndSaveChange($event.value,'fuite')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Raccordement condensats en réseau évacuation</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="raccordement_condensat" (change)="getCurrentKitAndSaveChange($event.value,'raccordement_condensat')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>

        </div>

      <div>
        <button class="btn-secondarycrm" matStepperNext [style.opacity]="!firstFormGroup.valid ? '0.4' : '1'" (click)="updateData(66)">Suivant</button>
      </div>
    </div>
    </form>
  </mat-step>






  <mat-step [stepControl]="secondFormGroup">
    <form [formGroup]="secondFormGroup">
      <div>
        <h6>Unité extérieure</h6>
        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <div class="button-wrapper mt-1">
              <label>N° de série</label>
              <label class="upload-label" for="upload" *ngIf="!n_serie_ue_bt">+ Ajouter la photo</label>
<!--               <p class="mb-0">
                <a target="blank" class="check" *ngIf="n_serie_ue_bt" [routerLink]="['/detail-piece', piece_serie_ue_bt[0]._id]">Photos n° de série</a>
              </p>
              <p *ngIf="n_serie_bt" style="color: lightgrey;font-weight: 300; margin-top: 0;
              font-size: small;">Prise le {{ piece_serie_ue_bt[0].created_at | date:'medium' }}</p> -->

              <input *ngIf="!n_serie_ue_bt" accept=".jpg,.jpeg,.png" type="file" multiple name="upload" id="upload" class="upload-box" (change)="selectMultiple($event, 'N° de serie ballon thermodynamique','n_serie_ue_bt')">
            </div>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Pose</label>
            <mat-form-field>
              <mat-select formControlName="type_pose_ue" (selectionChange)="addOption(currentKit.type,'edité', 'type_pose_ue', 'capacite', secondFormGroup.value.type_pose_ue)">
                <mat-option *ngFor="let pose of poses" [value]="pose">
                  {{pose}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin"  *ngIf="this.secondFormGroup.value.pose_ue === 'sur chassis'">
            <mat-label>Installation sur chassis normé : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="chassis_ue" (change)="getCurrentKitAndSaveChange($event.value,'chassis_ue')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('chassis_ue')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Étanchéité au passage de la liaison frigo/bâtiment :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="etancheite_ue" (change)="getCurrentKitAndSaveChange($event.value,'etancheite_ue')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('etancheite_ue')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Présence de silent bloc :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="silent_bloc_ue" (change)="getCurrentKitAndSaveChange($event.value,'silent_bloc_ue')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('silent_bloc_ue')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Étiquette charge fluide renseigné : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="etiquette_charge_ue" (change)="getCurrentKitAndSaveChange($event.value,'etiquette_charge_ue')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('etiquette_charge_ue')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Espace de dissipation chaleur respecté :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="espace_chaleur_ue" (change)="getCurrentKitAndSaveChange($event.value,'espace_chaleur_ue')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('espace_chaleur_ue')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin last-col">
            <mat-label>Cablage électrique conforme :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="cablage_conforme_ue" (change)="getCurrentKitAndSaveChange($event.value,'cablage_conforme_ue')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>

        </div>

      <div>
        <button class="btn-secondarycrm" matStepperNext [style.opacity]="!secondFormGroup.valid ? '0.4' : '1'" (click)="updateData(100)">Suivant</button>

      </div>
    </div>
    </form>
  </mat-step>

  <mat-step [stepControl]="thirdFormGroup">
    <form [formGroup]="thirdFormGroup">
      <div>
        <h6>Autre</h6>
        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Longueur de la liaison frigo</label>
            <input class="custom-input" type="text" formControlName="liaison_frigo" (change)="getCurrentKitAndSaveChange(this.thirdFormGroup.value.liaison_frigo,'liaison_frigo')">
            <div *ngIf="thirdFormGroup.get('liaison_frigo').invalid && thirdFormGroup.get('liaison_frigo').errors && (thirdFormGroup.get('liaison_frigo').dirty || thirdFormGroup.get('liaison_frigo').touched)">
            <small class="text-danger"
                *ngIf="thirdFormGroup.get('liaison_frigo').hasError('required')">
                Champ requis
            </small>
            </div>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Dénivelé(s) liaison frigo</label>
            <input class="custom-input" type="text" formControlName="denivele_liaison_frigo" (change)="getCurrentKitAndSaveChange(this.thirdFormGroup.value.denivele_liaison_frigo,'denivele_liaison_frigo')">
            <div *ngIf="thirdFormGroup.get('denivele_liaison_frigo').invalid && thirdFormGroup.get('denivele_liaison_frigo').errors && (thirdFormGroup.get('denivele_liaison_frigo').dirty || thirdFormGroup.get('denivele_liaison_frigo').touched)">
            <small class="text-danger"
                *ngIf="thirdFormGroup.get('denivele_liaison_frigo').hasError('required')">
                Champ requis
            </small>
            </div>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Référence du fluide</label>
            <input class="custom-input" type="text" formControlName="reference_fluide" (change)="getCurrentKitAndSaveChange(this.thirdFormGroup.value.reference_fluide,'reference_fluide')">
            <div *ngIf="thirdFormGroup.get('reference_fluide').invalid && thirdFormGroup.get('reference_fluide').errors && (thirdFormGroup.get('reference_fluide').dirty || thirdFormGroup.get('reference_fluide').touched)">
            <small class="text-danger"
                *ngIf="thirdFormGroup.get('reference_fluide').hasError('required')">
                Champ requis
            </small>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Poids de charge</label>
            <input class="custom-input" type="text" formControlName="poids_charge" (change)="getCurrentKitAndSaveChange(this.thirdFormGroup.value.poids_charge,'poids_charge')">
            <div *ngIf="thirdFormGroup.get('poids_charge').invalid && thirdFormGroup.get('poids_charge').errors && (thirdFormGroup.get('poids_charge').dirty || thirdFormGroup.get('poids_charge').touched)">
            <small class="text-danger"
                *ngIf="thirdFormGroup.get('poids_charge').hasError('required')">
                Champ requis
            </small>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Test étanchéité fluide frigorigène: </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="etancheite" (change)="getCurrentKitAndSaveChange($event.value,'etancheite')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Mise en oeuvre expliquée au client</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="mise_oeuvre" (change)="getCurrentKitAndSaveChange($event.value,'mise_oeuvre')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('mise_oeuvre')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Tirage à vide liaison frigo :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="tirage_liaison_frigo" (change)="getCurrentKitAndSaveChange($event.value,'tirage_liaison_frigo')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('tirage_liaison_frigo')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Nettoyage chantier </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="nettoyage" (change)="getCurrentKitAndSaveChange($event.value,'nettoyage')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('nettoyage')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Disjoncteur repéré sur le tableau électrique : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="disjoncteur_repere" (change)="getCurrentKitAndSaveChange($event.value,'disjoncteur_repere')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('disjoncteur_repere')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Fiche d’intervention créée</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="fiche_inter" (change)="getCurrentKitAndSaveChange($event.value,'fiche_inter')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('fiche_inter')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

        </div>
        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Raccordement sur contacteur J+N :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="raccordement_contacteur" (change)="getCurrentKitAndSaveChange($event.value,'raccordement_contacteur')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('raccordement_contacteur')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
  
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin last-col">
            <mat-label>Signalisation SAV Enerconfort:</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="signalisation_sav" (change)="getCurrentKitAndSaveChange($event.value,'signalisation_sav')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('signalisation_sav')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
  
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Document remise au client</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="documentation" (change)="getCurrentKitAndSaveChange($event.value,'documentation')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin last-col">
            <mat-label>Étiquette marquage société et SAV :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="etiquette" (change)="getCurrentKitAndSaveChange($event.value,'etiquette')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('etiquette')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

        </div>

      <div>
        <button class="btn-secondarycrm" matStepperNext [style.opacity]="!thirdFormGroup.valid ? '0.4' : '1'" (click)="updateData(100)">Suivant</button>
      </div>
    </div>
    </form>
  </mat-step>

  <mat-step>

    <textarea class="custom-textarea" placeholder="Observations" [(ngModel)]="currentKit.observation" (change)="getCurrentKitAndSaveChange('observation',case.observation)"></textarea>

    <ng-template matStepLabel>Done</ng-template>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Ballon thermodynamique
        <label class="upload-label" for="bt" *ngIf="!bt"><mat-icon>add</mat-icon><span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="bt"></p>
        <input accept=".jpg,.jpeg,.png" multiple type="file" name="bt" id="bt" class="upload-box" (change)="selectMultiple($event, 'Ballon thermodynamique')">
      </div>

      <div class="button-wrapper mt-3 col-lg-6">
        Passage en batiment et liaison
        <label class="upload-label" for="passage" *ngIf="!passage"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="passage"></p>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="passage" id="passage" class="upload-box" (change)="selectMultiple($event, 'Passage en batiment et liaison')">
      </div>
    </div>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Unité extérieure
        <label class="upload-label" for="ue_bt" *ngIf="!ue_bt"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="ue_bt"></p>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="ue_bt" class="upload-box" (change)="selectMultiple($event, 'Unité extérieure BT')">

      </div>
    </div>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Disjoncteur
        <label class="upload-label" for="disjoncteur" *ngIf="!disjoncteur"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="disjoncteur"></p>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="disjoncteur" class="upload-box" (change)="selectMultiple($event, 'Disjoncteur')">

      </div>
    </div>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Liaison frigorifique
        <label class="upload-label" for="liaison_f" *ngIf="!liaison_f"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="liaison_f"></p>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="liaison_f" class="upload-box" (change)="selectMultiple($event, 'Liaison frigorifique')">
      </div>

    </div>

    <button *ngIf="!isValid" (click)="valid()" class="btn-secondarycrm">Confirmer</button>
  </mat-step>

</mat-horizontal-stepper>
