<mat-horizontal-stepper [linear]="true" #stepper>
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup">
      <ng-template matStepLabel>Fill out your name</ng-template>

      <div>
        <h6>Ballon thermodynamique</h6>
        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Marque et type</label>
            <input class="custom-input" type="text" formControlName="marque_type" (change)="getCurrentKitAndSaveChange(this.firstFormGroup.value.marque_type,'marque_type')">
            <div *ngIf="firstFormGroup.get('marque_type').invalid && firstFormGroup.get('marque_type').errors && (firstFormGroup.get('marque_type').dirty || firstFormGroup.get('marque_type').touched)">
            <small class="text-danger"
                *ngIf="firstFormGroup.get('marque_type').hasError('required')">
                Champ requis
            </small>
            </div>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <div class="button-wrapper mt-1">
              <label>N° de série</label>
              <label class="upload-label" for="n_serie_bt" *ngIf="!n_serie_bt">+ Ajouter la photo</label>
              <p class="mb-0">
                <a target="blank" class="check" *ngIf="n_serie_bt" [routerLink]="['/detail-piece', piece_serie_bt[0]._id]">Photos n° de série</a>
              </p>
              <p *ngIf="n_serie_bt" style="color: lightgrey;font-weight: 300; margin-top: 0;
              font-size: small;">Prise le {{ piece_serie_bt[0].created_at | date:'medium' }}</p>

              <input *ngIf="!n_serie_bt" accept=".jpg,.jpeg,.png" type="file" multiple name="n_serie_bt" id="n_serie_bt" class="upload-box" (change)="selectMultipleNserie($event, 'N° de serie ballon thermodynamique','n_serie_bt')">
            </div>
          </div>
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
            <label>Volume en litre</label>
            <mat-form-field>
              <mat-select formControlName="capacite" (selectionChange)="addOption(currentKit.type,'edité', 'capacite', 'capacite', firstFormGroup.value.capacite)">
                <mat-option *ngFor="let capacity of capacities" [value]="capacity">
                  {{capacity}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>


        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Local de pose</label>
            <input class="custom-input" type="text" formControlName="local_pose" (change)="getCurrentKitAndSaveChange(this.firstFormGroup.value.local_pose,'local_pose')">
            <div *ngIf="firstFormGroup.get('local_pose').invalid && firstFormGroup.get('local_pose').errors && (firstFormGroup.get('local_pose').dirty || firstFormGroup.get('local_pose').touched)">
            <small class="text-danger"
                *ngIf="firstFormGroup.get('local_pose').hasError('required')">
                Champ requis
            </small>
            </div>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
            <label>Volume de la pièce</label>
            <input class="custom-input" type="text" formControlName="volume" (change)="getCurrentKitAndSaveChange(this.firstFormGroup.value.volume,'volume')">
            <div *ngIf="firstFormGroup.get('volume').invalid && firstFormGroup.get('volume').errors && (firstFormGroup.get('volume').dirty || firstFormGroup.get('volume').touched)">
            <small class="text-danger"
                *ngIf="firstFormGroup.get('volume').hasError('required')">
                Champ requis
            </small>
            </div>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Pièce hors gel:</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="hors_gel" (change)="getCurrentKitAndSaveChange($event.value,'hors_gel')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Gaine entrée d'air:</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="gaine_entree_air" (change)="getCurrentKitAndSaveChange($event.value,'gaine_entree_air')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Longueur gaine entrée d'air</label>
            <input class="custom-input" type="text" formControlName="longueur_gaine_entree" (change)="getCurrentKitAndSaveChange(this.firstFormGroup.value.longueur_gaine_entree,'longueur_gaine_entree')">
            <div *ngIf="firstFormGroup.get('longueur_gaine_entree').invalid && firstFormGroup.get('longueur_gaine_entree').errors && (firstFormGroup.get('longueur_gaine_entree').dirty || firstFormGroup.get('longueur_gaine_entree').touched)">
            <small class="text-danger"
                *ngIf="firstFormGroup.get('longueur_gaine_entree').hasError('required')">
                Champ requis
            </small>
            </div>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6">
            <label>Nombre de coude</label>
            <div class="increment">
              <button
                class="rm"
                mat-button
                mat-icon-button
                matPrefix
                aria-label="Remove"
                (click)="incrementValueCoudeEntree(-_step, currentKit.type,'edité', 'coude_entree_air', currentKit.coude_entree_air)">
                <mat-icon>remove</mat-icon>
              </button>
              <input type="number"[value]="currentKit.coude_entree_air" />
              <button
                class="add"
                mat-button
                mat-icon-button
                matSuffix
                aria-label="Add"
                (click)="incrementValueCoudeEntree(_step, currentKit.type,'edité', 'coude_entree_air', currentKit.coude_entree_air)"
                >
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col radio-choice">
            <mat-label>Gaine sortie d'air:</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="gaine_sortie_air" (change)="getCurrentKitAndSaveChange($event.value,'gaine_sortie_air')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
            <label>Longueur gaine sortie d'air</label>
            <input class="custom-input" type="text" formControlName="longueur_gaine_sortie" (change)="getCurrentKitAndSaveChange(this.firstFormGroup.value.longueur_gaine_sortie,'longueur_gaine_sortie')">
            <div *ngIf="firstFormGroup.get('longueur_gaine_sortie').invalid && firstFormGroup.get('longueur_gaine_sortie').errors && (firstFormGroup.get('longueur_gaine_sortie').dirty || firstFormGroup.get('longueur_gaine_sortie').touched)">
            <small class="text-danger"
                *ngIf="firstFormGroup.get('longueur_gaine_sortie').hasError('required')">
                Champ requis
            </small>
            </div>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 last-col">
            <label>Nombre de coude</label>
            <div class="increment">
              <button
                class="rm"
                mat-button
                mat-icon-button
                matPrefix
                aria-label="Remove"
                (click)="incrementValueCoudeSortie(-_step, currentKit.type,'edité', 'coude_sortie_air', currentKit.coude_sortie_air)">
                <mat-icon>remove</mat-icon>
              </button>
              <input type="number"[value]="currentKit.coude_sortie_air" />
              <button
                class="add"
                mat-button
                mat-icon-button
                matSuffix
                aria-label="Add"
                (click)="incrementValueCoudeSortie(_step, currentKit.type,'edité', 'coude_sortie_air', currentKit.coude_sortie_air)"
                >
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
        </div>

      <div>
        <button class="btn-secondarycrm" matStepperNext [style.opacity]="!firstFormGroup.valid ? '0.4' : '1'" (click)="updateData(66)">Suivant</button>
      </div>
    </div>
    </form>
  </mat-step>



  <mat-step [stepControl]="secondFormGroup">
    <form [formGroup]="secondFormGroup">

      <div class="row">
        <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
          <mat-label>Gaine conforme:</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="gaine_conforme" (change)="getCurrentKitAndSaveChange($event.value,'gaine_conforme')">
            <mat-radio-button value="oui">Oui</mat-radio-button>
            <mat-radio-button value="non" class="ml-4" (click)="openDialog('gaine_conforme')">Non</mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
          <mat-label>Bouclage avec robinet thermostatique :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="bouclage" (change)="getCurrentKitAndSaveChange($event.value,'bouclage')">
            <mat-radio-button value="oui">Oui</mat-radio-button>
            <mat-radio-button value="non" class="ml-4" (click)="openDialog('bouclage')">Non</mat-radio-button>
          </mat-radio-group>
        </div>

      </div>

      <div class="row">
        <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
          <mat-label>Groupe de sécurité piquage à froid :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="groupe_secu" (change)="getCurrentKitAndSaveChange($event.value,'groupe_secu')">
            <mat-radio-button value="oui">Oui</mat-radio-button>
            <mat-radio-button value="non" class="ml-4" (click)="openDialog('groupe_secu')">Non</mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
          <mat-label>Câblage électrique conforme : </mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="cablage" (change)="getCurrentKitAndSaveChange($event.value,'cablage')">
            <mat-radio-button value="oui">Oui</mat-radio-button>
            <mat-radio-button value="non" class="ml-4" (click)="openDialog('cablage')">Non</mat-radio-button>
          </mat-radio-group>
        </div>

      </div>

      <div class="row">
        <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
          <mat-label>Présence de fuite d’eau</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="fuite" (change)="getCurrentKitAndSaveChange($event.value,'fuite')">
            <mat-radio-button value="oui">Oui</mat-radio-button>
            <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
          <mat-label>Programmation du Ballon :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="prog_ballon" (change)="getCurrentKitAndSaveChange($event.value,'prog_ballon')">
            <mat-radio-button value="Hc">Hc</mat-radio-button>
            <mat-radio-button value="solaire" class="ml-4">Solaire</mat-radio-button>
          </mat-radio-group>
        </div>

      </div>

      <div class="row">
        <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
          <mat-label>Tuyauterie calorifugées :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="tuyauterie" (change)="getCurrentKitAndSaveChange($event.value,'tuyauterie')">
            <mat-radio-button value="oui">Oui</mat-radio-button>
            <mat-radio-button value="non" class="ml-4" (click)="openDialog('tuyauterie')">Non</mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
          <mat-label>Mise en service effectuée :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="mise_en_service" (change)="getCurrentKitAndSaveChange($event.value,'mise_en_service')">
            <mat-radio-button value="oui">Oui</mat-radio-button>
            <mat-radio-button value="non" class="ml-4" (click)="openDialog('mise_en_service')">Non</mat-radio-button>
          </mat-radio-group>
        </div>

      </div>

      <div class="row">
        <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
          <mat-label>Document remise au client</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="documentation" (change)="getCurrentKitAndSaveChange($event.value,'documentation')">
            <mat-radio-button value="Documentation technique">Documentation technique</mat-radio-button>
            <mat-radio-button value="Fiche d’intervention" class="ml-4">Fiche d’intervention</mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
          <mat-label>Mise en oeuvre expliquée au client : </mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="mise_en_oeuvre" (change)="getCurrentKitAndSaveChange($event.value,'mise_en_oeuvre')">
            <mat-radio-button value="oui">Oui</mat-radio-button>
            <mat-radio-button value="non" class="ml-4" (click)="openDialog('mise_en_oeuvre')">Non</mat-radio-button>
          </mat-radio-group>
        </div>

      </div>

      <div class="row">
        <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
          <mat-label>Nettoyage chantier :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="nettoyage" (change)="getCurrentKitAndSaveChange($event.value,'nettoyage')">
            <mat-radio-button value="oui">Oui</mat-radio-button>
            <mat-radio-button value="non" class="ml-4" (click)="openDialog('nettoyage')">Non</mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
          <mat-label>Étiquette marquage société et SAV :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="etiquette" (change)="getCurrentKitAndSaveChange($event.value,'etiquette')">
            <mat-radio-button value="oui">Oui</mat-radio-button>
            <mat-radio-button value="non" class="ml-4" (click)="openDialog('etiquette')">Non</mat-radio-button>
          </mat-radio-group>
        </div>

      </div>

      <div class="row">
        <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
          <mat-label>Disjoncteur repéré au tableau électrique : </mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="disjoncteur_repere" (change)="getCurrentKitAndSaveChange($event.value,'disjoncteur_repere')">
            <mat-radio-button value="oui">Oui</mat-radio-button>
            <mat-radio-button value="non" class="ml-4" (click)="openDialog('disjoncteur_repere')">Non</mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice last-col">
          <mat-label>Raccordement condensat au réseau :</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="raccordement_condensat" (change)="getCurrentKitAndSaveChange($event.value,'raccordement_condensat')">
            <mat-radio-button value="oui">Oui</mat-radio-button>
            <mat-radio-button value="non" class="ml-4" (click)="openDialog('raccordement_condensat')">Non</mat-radio-button>
          </mat-radio-group>
        </div>

      </div>

      <div>
          <button class="btn-secondarycrm" matStepperNext [style.opacity]="!secondFormGroup.valid ? '0.4' : '1'" (click)="updateData(100)">Suivant</button>
      </div>
    </form>
  </mat-step>

  <mat-step>

    <textarea class="custom-textarea" placeholder="Observations" [(ngModel)]="currentKit.observation" (change)="getCurrentKitAndSaveChange('observation',case.observation)"></textarea>

    <ng-template matStepLabel>Done</ng-template>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Ballon thermodynamique
        <label class="upload-label" for="bt" *ngIf="!bt"><mat-icon>add</mat-icon><span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="bt"></p>
        <input accept=".jpg,.jpeg,.png" multiple type="file" name="bt" id="bt" class="upload-box" (change)="selectMultiple($event, 'Ballon thermodynamique')">
      </div>

      <div class="button-wrapper mt-3 col-lg-6">
        Passage en batiment et liaison
        <label class="upload-label" for="passage" *ngIf="!passage"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="passage"></p>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="passage" id="passage" class="upload-box" (change)="selectMultiple($event, 'Passage en batiment et liaison')">
      </div>
    </div>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Disjoncteur
        <label class="upload-label" for="disjoncteur" *ngIf="!disjoncteur"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="disjoncteur"></p>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="disjoncteur" class="upload-box" (change)="selectMultiple($event, 'Disjoncteur')">

      </div>
    </div>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Liaison frigorifique
        <label class="upload-label" for="liaison_f" *ngIf="!liaison_f"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="liaison_f"></p>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="liaison_f" class="upload-box" (change)="selectMultiple($event, 'Liaison frigorifique')">
      </div>

    </div>

    <button *ngIf="!isValid" (click)="valid()" class="btn-secondarycrm">Confirmer</button>
  </mat-step>

</mat-horizontal-stepper>
