<h4>Sélectionner les pièces manquantes</h4>

<form [formGroup]="myForm" class="custom-radio" style="font-size:small;">
	<div *ngFor="let option of options">
		<input [id]=option.title type="checkbox" (change)="onChange(option.title, $event.target.checked)">
    <label [for]=option.title>{{ option.title }}</label>
	</div>
</form>

<button (click)="onSubmitMultiple()" class="btn-secondarycrm">Créer les pièces</button>
