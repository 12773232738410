import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HistoriqueService } from '@app/services/historique.service';
import { TasksGddService } from '@app/services/tasks-gdd.service';
import { TasksService } from '@app/services/tasks.service';
import { DeleteBarComponent } from '@app/snackbars/delete-bar/delete-bar.component';
import { AuthenticationService } from '@app/_services';
import * as moment from 'moment';
import { CreateTaskGddComponent } from '../create-task-gdd/create-task-gdd.component';
import { CreateTaskComponent } from '../create-task/create-task.component';

@Component({
  selector: 'app-task-case-list',
  templateUrl: './task-case-list.component.html',
  styleUrls: ['./task-case-list.component.scss']
})
export class TaskCaseListComponent implements OnInit {

  currentUser;
  uncheckedTasks = [];
  checkedTasks = [];
  suppreTask = [];
  tasksWarning = [];
  isfrmChecked:any;
  trash = true;
  case
  tasksGdd

  unroll = false
  differenceHours;

  constructor(
    private sTasks: TasksService,
    private dialog: MatDialog,
    private _snackBar:MatSnackBar,
    private authenticationService: AuthenticationService,
    private sHistoriques: HistoriqueService,
    private sTasksGdd: TasksGddService,
    @Inject(MAT_DIALOG_DATA) public currentCase,

    ) {
      this.authenticationService.user.subscribe(x => this.currentUser = x);
      this.case = currentCase.currentCase
     }

  ngOnInit(): void {
    this.showTasksByCase();
    this.showTasksGddByCase()
  }
  showTasksByCase() {
    this.checkedTasks = [];
    this.uncheckedTasks = [];
    this.tasksWarning = []
    const today = new Date();
    today.setHours(1, 0, 0, 0);

    const dayNow = today.getDate()-1
    const monthNow = today.getMonth()+1
    const yearNow = today.getFullYear()


    return new Promise(resolve => {
      this.sTasks.getTaskByCaseId(this.case._id).subscribe(res => {
        const tasks:any = res;
      tasks.forEach(element => {

        if(element.date != undefined || null) {
          let taskMonth = element.date.substr(5,2)
          let taskDay = element.date.substr(8,2)
          let taskYear = element.date.substr(0,4);

          if(monthNow == taskMonth && dayNow > taskDay && element.state != '3-Faite') {
            this.tasksWarning.push(element)
          }
          else if(monthNow > taskMonth || monthNow < taskMonth && taskYear < yearNow && element.state != '3-Faite') {
            this.tasksWarning.push(element)
          }
          else {
            this.uncheckedTasks.push(element)
          }
        }

      });
        resolve(res);
      }, error => {
        console.log(error);
      });

      })
  }

  componentMethodName(event: any, isChecked: boolean)
  {
    if (isChecked) {
      this.suppreTask.push(event.target.value)
    }
    else {
      let index = this.suppreTask.indexOf(event.target.value);
      this.suppreTask.splice(index, 1);
    }
  }

  editTaskDialog(task): void {
    const dialogRef = this.dialog.open(CreateTaskComponent, {
      width: '40%',
      height:'80%',
      data :{
             task : task,
             edit: true,
             inCase: true,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.showTasksByCase();
    });
  }
  editTask(task ,taskId){
    this.sTasks.editTask(task, taskId).then(res => {
      if (res['error']){
        return res.error;
      }else{

        this.sHistoriques.createHistorique(task.caseId._id, task.customerId._id, "edité", "Le statut de la tâche", task.message  ,this.currentUser.id).then(res => {
        if (res['error']) {
          alert(res);
        }
      });
        this.showTasksByCase();
      }
    })
  }

  supprTaskArray(){
    if ( confirm( "Si vous supprimez cette tâche, vous ne pourrez plus la récupérer. Etes vous sur de vouloir le faire ?" ) ) {
  this.suppreTask.forEach(element => {
    this.deleteTask(element)
    this.openSnackBar()
    });
  }
  }

  deleteTask(id) {
    this.sTasks.deleteTask(id).subscribe(response => {
      this.sHistoriques.createHistorique(this.case._id, this.case.customer._id, "supprimé", " une tâche", "-"  ,this.currentUser.id).then(res => {
        if (res['error']) {
          alert(res);
        }
      });
      this.showTasksByCase();
      this.trash = false
    });
  }
  openSnackBar() {
    this._snackBar.openFromComponent(DeleteBarComponent, {
      duration: 2000,
      panelClass: ['delete-snackbar']
    });
  }

  showTasksGddByCase() {
    this.sTasksGdd.getTaskByCaseId(this.case._id).subscribe(res => {
      this.tasksGdd = res
    })
  }
  unrollGdd() {
    this.unroll = !this.unroll
  }

  editTaskGddDialog(task): void {
    if(task.state != 'Terminé' && this.currentUser.id === task.target) {
      const dialogRef = this.dialog.open(CreateTaskGddComponent, {
        width: '30vw',
        minHeight: 'calc(60vh - 90px)',
        height : 'auto',
        disableClose:true,
        data :{
               edit: true,
               task : task,
        }
      });
    }
  }

}
