<mat-horizontal-stepper [linear]="true" #stepper>
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup">
      <ng-template matStepLabel>Fill out your name</ng-template>

      <div>
        <h5>Adoucisseur d'eau</h5>
        <div class="row mt-3">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
            <label>Marque et type</label>
            <input class="custom-input" type="text" formControlName="marque_type" (change)="getCurrentKitAndSaveChange(this.firstFormGroup.value.marque_type,'marque_type')">
            <div *ngIf="firstFormGroup.get('marque_type').invalid && firstFormGroup.get('marque_type').errors && (firstFormGroup.get('marque_type').dirty || firstFormGroup.get('marque_type').touched)">
            <small class="text-danger"
                *ngIf="firstFormGroup.get('marque_type').hasError('required')">
                Champ requis
            </small>
            </div>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <div class="button-wrapper mt-1">
              <label>N° de série</label>
              <label class="upload-label" for="upload" *ngIf="!n_serie">+ Ajouter la photo</label>
              <p class="mb-0">
                <a target="blank" class="check" *ngIf="n_serie" [routerLink]="['/detail-piece', piecesNserie[0]._id]">Photos n° de série</a>
              </p>
              <p *ngIf="n_serie" style="color: lightgrey;font-weight: 300; margin-top: 0; font-size: small;">Prise le {{ piecesNserie[0].created_at | date:'medium' }}</p>

              <input *ngIf="!n_serie" accept=".jpg,.jpeg,.png" type="file" multiple name="upload" id="upload" class="upload-box" (change)="selectNSerie($event, 'N° de serie adoucisseur')">
            </div>
          </div>
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
          <label>Pression en bar</label>
          <input class="custom-input" type="text" formControlName="pression_bar" (change)="getCurrentKitAndSaveChange(this.firstFormGroup.value.pression_bar,'pression_bar')">
          <div *ngIf="firstFormGroup.get('pression_bar').invalid && firstFormGroup.get('pression_bar').errors && (firstFormGroup.get('pression_bar').dirty || firstFormGroup.get('pression_bar').touched)">
            <small class="text-danger"
                *ngIf="firstFormGroup.get('pression_bar').hasError('required')">
                Champ requis
            </small>
          </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Disconnecteur en amont</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="disconnecteur_amont" (change)="getCurrentKitAndSaveChange($event.value,'disconnecteur_amont')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('disconnecteur_amont')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Siphon en aval</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="siphon_aval" (change)="getCurrentKitAndSaveChange($event.value,'siphon_aval')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('siphon_aval')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Réducteur de pression tarés 3Bars:</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="reducteur_tare" (change)="getCurrentKitAndSaveChange($event.value,'reducteur_tare')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('reducteur_tare')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Vanne d'isolement en amont:</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="isolement_amont" (change)="getCurrentKitAndSaveChange($event.value,'isolement_amont')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('isolement_amont')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Clapet anti-retour en amont:</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="clapet" (change)="getCurrentKitAndSaveChange($event.value,'clapet')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('clapet')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Vanne d'isolement en aval:</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="isolement_aval" (change)="getCurrentKitAndSaveChange($event.value,'isolement_aval')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('isolement_aval')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Documentation française remise au client:</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="documentation" (change)="getCurrentKitAndSaveChange($event.value,'documentation')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('documentation')">Non</mat-radio-button>
            </mat-radio-group>
            <div *ngIf="firstFormGroup.get('documentation').invalid && firstFormGroup.get('documentation').errors && (firstFormGroup.get('documentation').dirty || firstFormGroup.get('documentation').touched)">
              <small class="text-danger"
                  *ngIf="firstFormGroup.get('documentation').hasError('required')">
                  Champ requis
              </small>
              </div>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Mise en oeuvre expliqué au client:</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="explication" (change)="getCurrentKitAndSaveChange($event.value,'explication')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('explication')">Non</mat-radio-button>
            </mat-radio-group>
            <div *ngIf="firstFormGroup.get('explication').invalid && firstFormGroup.get('explication').errors && (firstFormGroup.get('explication').dirty || firstFormGroup.get('explication').touched)">
              <small class="text-danger"
                  *ngIf="firstFormGroup.get('explication').hasError('required')">
                  Champ requis
              </small>
              </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice last-col">
            <mat-label>Nettoyage chantier:</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="nettoyage" (change)="getCurrentKitAndSaveChange($event.value,'nettoyage')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('nettoyage')">Non</mat-radio-button>
            </mat-radio-group>
            <div *ngIf="firstFormGroup.get('nettoyage').invalid && firstFormGroup.get('nettoyage').errors && (firstFormGroup.get('nettoyage').dirty || firstFormGroup.get('nettoyage').touched)">
              <small class="text-danger"
                  *ngIf="firstFormGroup.get('nettoyage').hasError('required')">
                  Champ requis
              </small>
              </div>
          </div>
        </div>

      <div>
        <button class="btn-secondarycrm" matStepperNext [style.opacity]="!firstFormGroup.valid ? '0.4' : '1'" (click)="updateData(100)">Suivant</button>
      </div>
    </div>
    </form>
  </mat-step>

  <mat-step>

    <textarea class="custom-textarea" placeholder="Observations" [(ngModel)]="currentKit.observation" (change)="getCurrentKitAndSaveChange('observation',case.observation)"></textarea>

    <ng-template matStepLabel>Done</ng-template>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Etiquette test PH
        <label class="upload-label" for="etiquette" *ngIf="!etiquette"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>

        <p class="check" *ngIf="etiquette"></p>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="etiquette" id="etiquette" class="upload-box" (change)="selectMultiple($event, 'Etiquette test PH')">
      </div>
    </div>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Filtre adoucisseur
        <label class="upload-label" for="filtre" *ngIf="!filtre"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>

        <p class="check" *ngIf="filtre"></p>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="filtre" id="filtre" class="upload-box" (change)="selectMultiple($event, 'Filtre adoucisseur')">
      </div>
    </div>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Disjoncteur
        <label class="upload-label" for="disjoncteur" *ngIf="!disjoncteur"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>

        <p class="check" *ngIf="disjoncteur"></p>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="disjoncteur" id="disjoncteur" class="upload-box" (change)="selectMultiple($event, 'Disjoncteur')">
      </div>
    </div>

    <!-- <button *ngIf="!isValid" (click)="valid()" class="btn-secondarycrm">Confirmer</button> -->
    <button (click)="valid()" class="btn-secondarycrm">Confirmer</button>

  </mat-step>

</mat-horizontal-stepper>
