import { AfterContentInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { PdfsService } from '@app/services/pdfs.service';
import { ModalPrevisualizeComponent } from '../modal-previsualize/modal-previsualize.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PiecesService } from '@app/services/pieces.service';

@Component({
  selector: 'app-bi-visualize-cm',
  templateUrl: './bi-visualize-cm.component.html',
  styleUrls: ['./bi-visualize-cm.component.scss'],
})
export class BiVisualizeCmComponent implements AfterContentInit, OnInit {
  kit;
  case;
  caseId;

  currentKit;
  chantierId;
  showPdf = false;
  createDoc;
  kitFilter;
  loader = false;

  formSelected;
  formVmc: FormGroup;
  formPv: FormGroup;
  formBt: FormGroup;
  formPacaa: FormGroup;
  formPacae: FormGroup;

  selectedProduct;

  multipleImagesPieces;
  heureArriveeHeure: number = null;
  heureArriveeMinute: number = null;
  heureDepartHeure: number = null;
  heureDepartMinute: number = null;

  // Photos VMC
  pav_nettoyage = false;
  pap_nettoyage = false;
  pav_evac_condensat = false;
  pap_evac_condensat = false;
  pav_chapeau_toit = false;
  pap_chapeau_toit = false;
  pap_alim_elec = false;
  pap_etat_gaine = false;
  pap_piles_telecommande = false;

  // Photos PV
  pav_nettoyage_panneaux = false;
  pap_nettoyage_panneaux = false;
  pav_etat_modules = false;
  pap_etat_modules = false;
  pav_systeme_montage = false;
  pap_systeme_montage = false;
  pav_absence_infiltration = false;
  pap_absence_infiltration = false;
  pav_serrage_ac_dc = false;
  pap_serrage_ac_dc = false;
  pav_v_continuites = false;
  pap_v_continuites = false;
  pav_v_tableau_electrique = false;
  pav_nettoyage_filtres = false;
  pap_nettoyage_filtres = false;
  pav_v_usage_local = false;
  pav_v_parafoudres = false;
  pap_v_mode_erreur_onduleur = false;
  pav_v_systeme_ouverture_tableau = false;
  pap_v_systeme_ouverture_tableau = false;
  pav_v_coupure_urgence = false;
  pap_v_coupure_urgence = false;
  pav_etiquettes = false;
  pap_etiquettes = false;
  pav_reconnexion_passerelle = false;
  pap_reconnexion_passerelle = false;

  // Photos BT
  pav_vaseExpansion = false;
  pap_vaseExpansion = false;
  pav_groupeExterieur = false;
  pap_groupeExterieur = false;
  pav_jointBride = false;
  pap_jointBride = false;
  pav_parametrages = false;
  pap_parametrages = false;
  pav_alimentationConnexions = false;
  pap_alimentationConnexions = false;
  pap_fuiteRaccordements = false;
  pap_inspectionRobinets = false;
  pav_peignerEchangeur = false;
  pap_peignerEchangeur = false;
  pav_nettoyageUE = false;
  pap_nettoyageUE = false;

  // Photo PACAE

  pav_tensionsUnite = false;
  pap_tensionsUnite = false;
  pav_nettoyageFiltres = false;
  pap_nettoyageFiltres = false;
  pav_nettoyageEchangeurs = false;
  pap_nettoyageEchangeurs = false;
  pav_verificationLiaisons = false;
  pap_verificationLiaisons = false;
  pav_pressionPurge = false;
  pap_pressionPurge = false;
  pav_verificationFuite = false;
  pap_verificationFuite = false;
  pav_peignerEchangeur_pacae = false;
  pap_peignerEchangeur_pacae = false;

  // Photo PACAA

  pav_v_general_ui_ue = false;
  pap_v_general_ui_ue = false;
  pav_v_fixations = false;
  pap_v_fixations = false;

  pav_v_etat_liaison_frigorifique = false;
  pap_v_etat_liaison_frigorifique = false;
  pav_depoussierage_ui_ue = false;
  pap_depoussierage_ui_ue = false;
  pav_nettoyage_filtre_ui = false;
  pap_nettoyage_filtre_ui = false;
  pav_desinfection_evapo_filtre = false;
  pap_desinfection_evapo_filtre = false;
  pav_c_evaporateur_filtre = false;
  pap_c_evaporateur_filtre = false;
  pav_peignerEchangeur_pacaa = false;
  pap_peignerEchangeur_pacaa = false;

  constructor(
    private sCases: CasesService,
    private route: ActivatedRoute,
    private sPdfs: PdfsService,
    private router: Router,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private sPieces: PiecesService
  ) {
    this.caseId = this.route.snapshot.params['id'];

    if (
      (this.currentKit =
        this.router.getCurrentNavigation().extras.state != undefined)
    ) {
      this.chantierId =
        this.router.getCurrentNavigation().extras.state.chantierId;
    } else {
      this.chantierId = JSON.parse(localStorage.getItem('chantierId'));
    }

    this.sCases.getCaseById(this.caseId).subscribe((res) => {
      this.case = res[0];
      this.kit = JSON.parse(this.case.kits_vendus).filter(
        (e) => e.active && e.valid
      );

      this.kit = this.kit.filter(
        (product) =>
          product.name === 'Ballon thermodynamique' ||
          product.name === 'Panneaux solaires' ||
          product.name === 'PAC Air/Air' ||
          product.name === 'VMC Double Flux' ||
          product.name === 'PAC Air/Eau'
      );
    });
    this.formBt = this.fb.group({
      heureArriveeHeure: [
        null,
        [Validators.required, Validators.min(0), Validators.max(23)],
      ],
      heureArriveeMinute: [
        null,
        [Validators.required, Validators.min(0), Validators.max(59)],
      ],
      heureDepartHeure: [
        null,
        [Validators.required, Validators.min(0), Validators.max(23)],
      ],
      heureDepartMinute: [
        null,
        [Validators.required, Validators.min(0), Validators.max(59)],
      ],
      vaseExpansion: [null],
      groupeExterieur: [null],
      jointBride: [null],
      parametrages: [null],
      alimentationConnexions: [null],
      fuiteRaccordements: [null],
      inspectionRobinets: [null],
      peignerEchangeur: [null],
      nettoyageUE: [null],

      equipement_ok: [null],
      nouvelle_inter: [null],
      nouvelle_inter_cause: [null],
      commentaire: [null],
      maj_fluide: [null],

      pav_vaseExpansion: [this.pav_vaseExpansion],
      pap_vaseExpansion: [this.pap_vaseExpansion],
      pav_groupeExterieur: [this.pav_groupeExterieur],
      pap_groupeExterieur: [this.pap_groupeExterieur],
      pav_jointBride: [this.pav_jointBride],
      pap_jointBride: [this.pap_jointBride],
      pav_parametrages: [this.pav_parametrages],
      pap_parametrages: [this.pap_parametrages],
      pav_alimentationConnexions: [this.pav_alimentationConnexions],
      pap_alimentationConnexions: [this.pap_alimentationConnexions],
      pap_fuiteRaccordements: [this.pap_fuiteRaccordements],
      pap_inspectionRobinets: [this.pap_inspectionRobinets],
      pav_peignerEchangeur: [this.pav_peignerEchangeur],
      pap_peignerEchangeur: [this.pap_peignerEchangeur],
      pav_nettoyageUE: [this.pav_nettoyageUE],
      pap_nettoyageUE: [this.pap_nettoyageUE],
    });
    this.formVmc = this.fb.group({
      heureArriveeHeure: [
        null,
        [Validators.required, Validators.min(0), Validators.max(23)],
      ],
      heureArriveeMinute: [
        null,
        [Validators.required, Validators.min(0), Validators.max(59)],
      ],
      heureDepartHeure: [
        null,
        [Validators.required, Validators.min(0), Validators.max(23)],
      ],
      heureDepartMinute: [
        null,
        [Validators.required, Validators.min(0), Validators.max(59)],
      ],
      nettoyage: [null],
      v_evac_condensat: [null],
      v_chapeau_toit: [null],
      c_alim_elec: [null],
      c_etat_gaine: [null],
      v_piles_telecommande: [null],
      equipement_ok: [null],
      nouvelle_inter: [null],
      nouvelle_inter_cause: [null],
      commentaire: [null],
      pav_nettoyage: [this.pav_nettoyage],
      pap_nettoyage: [this.pap_nettoyage],
      pav_evac_condensat: [this.pav_evac_condensat],
      pap_evac_condensat: [this.pap_evac_condensat],
      pav_chapeau_toit: [this.pav_chapeau_toit],
      pap_chapeau_toit: [this.pap_chapeau_toit],
      pap_alim_elec: [this.pap_alim_elec],
      pap_etat_gaine: [this.pap_etat_gaine],
      pap_piles_telecommande: [this.pap_piles_telecommande],
    });
    this.formPv = this.fb.group({
      heureArriveeHeure: [
        null,
        [Validators.required, Validators.min(0), Validators.max(23)],
      ],
      heureArriveeMinute: [
        null,
        [Validators.required, Validators.min(0), Validators.max(59)],
      ],
      heureDepartHeure: [
        null,
        [Validators.required, Validators.min(0), Validators.max(23)],
      ],
      heureDepartMinute: [
        null,
        [Validators.required, Validators.min(0), Validators.max(59)],
      ],
      nettoyagePanneaux: [null, Validators.required],
      etatModules: [null, Validators.required],
      etatSystemeMontage: [null, Validators.required],
      absenceInfiltration: [null, Validators.required],
      serrageAcDc: [null, Validators.required],
      v_continuites: [null],
      v_tableau_electrique: [null],
      nettoyage_filtres: [null],
      v_usage_local: [null],
      v_parafoudres: [null],
      v_mode_erreur_onduleur: [null],
      v_systeme_ouverture_tableau: [null],
      v_coupure_urgence: [null],
      etiquettes: [null],
      reconnexion_passerelle: [null],

      equipement_ok: [null],
      nouvelle_inter: [null],
      nouvelle_inter_cause: [null],
      commentaire: [null],

      pav_nettoyage_panneaux: [this.pav_nettoyage_panneaux],
      pap_nettoyage_panneaux: [this.pap_nettoyage_panneaux],
      pav_etat_modules: [this.pav_etat_modules],
      pap_etat_modules: [this.pap_etat_modules],
      pav_systeme_montage: [this.pav_systeme_montage],
      pap_systeme_montage: [this.pap_systeme_montage],
      pav_absence_infiltration: [this.pav_absence_infiltration],
      pap_absence_infiltration: [this.pap_absence_infiltration],
      pav_serrage_ac_dc: [this.pav_serrage_ac_dc],
      pap_serrage_ac_dc: [this.pap_serrage_ac_dc],

      pav_v_continuites: [this.pav_v_continuites],
      pap_v_continuites: [this.pap_v_continuites],

      pav_v_tableau_electrique: [this.pav_v_tableau_electrique],

      pav_nettoyage_filtres: [this.pav_nettoyage_filtres],
      pap_nettoyage_filtres: [this.pap_nettoyage_filtres],

      pav_v_usage_local: [this.pav_v_usage_local],

      pav_v_parafoudres: [this.pav_v_parafoudres],

      pap_v_mode_erreur_onduleur: [this.pap_v_mode_erreur_onduleur],

      pav_v_systeme_ouverture_tableau: [this.pav_v_systeme_ouverture_tableau],
      pap_v_systeme_ouverture_tableau: [this.pap_v_systeme_ouverture_tableau],

      pav_v_coupure_urgence: [this.pav_v_coupure_urgence],
      pap_v_coupure_urgence: [this.pap_v_coupure_urgence],

      pav_etiquettes: [this.pav_etiquettes],
      pap_etiquettes: [this.pap_etiquettes],

      pav_reconnexion_passerelle: [this.pav_reconnexion_passerelle],
      pap_reconnexion_passerelle: [this.pap_reconnexion_passerelle],
    });

    this.formPacae = this.fb.group({
      heureArriveeHeure: [
        null,
        [Validators.required, Validators.min(0), Validators.max(23)],
      ],
      heureArriveeMinute: [
        null,
        [Validators.required, Validators.min(0), Validators.max(59)],
      ],
      heureDepartHeure: [
        null,
        [Validators.required, Validators.min(0), Validators.max(23)],
      ],
      heureDepartMinute: [
        null,
        [Validators.required, Validators.min(0), Validators.max(59)],
      ],
      circuitFrigorifique: [null, Validators.required],
      tensionsUnite: [null, Validators.required],
      nettoyageFiltres: [null, Validators.required],
      parametresTelecommandes: [null, Validators.required],
      nettoyageEchangeurs: [null, Validators.required],
      verificationLiaisons: [null, Validators.required],
      moduleHydraulique: [null, Validators.required],
      pressionPurge: [null, Validators.required],
      verificationFuite: [null, Validators.required],
      peignerEchangeur: [null, Validators.required],

      equipement_ok: [null],
      nouvelle_inter: [null],
      nouvelle_inter_cause: [null],
      commentaire: [null],
      maj_fluide: [null],

      pav_tensionsUnite: [this.pav_tensionsUnite],
      pap_tensionsUnite: [this.pap_tensionsUnite],

      pav_nettoyageFiltres: [this.pav_nettoyageFiltres],
      pap_nettoyageFiltres: [this.pap_nettoyageFiltres],

      pav_nettoyageEchangeurs: [this.pav_nettoyageEchangeurs],
      pap_nettoyageEchangeurs: [this.pap_nettoyageEchangeurs],

      pav_verificationLiaisons: [this.pav_verificationLiaisons],
      pap_verificationLiaisons: [this.pap_verificationLiaisons],

      pav_pressionPurge: [this.pav_pressionPurge],
      pap_pressionPurge: [this.pap_pressionPurge],

      pav_verificationFuite: [this.pav_verificationFuite],
      pap_verificationFuite: [this.pap_verificationFuite],

      pav_peignerEchangeur: [this.pav_peignerEchangeur_pacae],
      pap_peignerEchangeur: [this.pap_peignerEchangeur_pacae],
    });

    this.formPacaa = this.fb.group({
      heureArriveeHeure: [
        null,
        [Validators.required, Validators.min(0), Validators.max(23)],
      ],
      heureArriveeMinute: [
        null,
        [Validators.required, Validators.min(0), Validators.max(59)],
      ],
      heureDepartHeure: [
        null,
        [Validators.required, Validators.min(0), Validators.max(23)],
      ],
      heureDepartMinute: [
        null,
        [Validators.required, Validators.min(0), Validators.max(59)],
      ],

      v_general_ui_ue: [null, Validators.required],
      v_fixations: [null, Validators.required],
      remplacement_piles: [null, Validators.required],
      c_condenseur_filtre: [null, Validators.required],
      v_inversion_cycle: [null, Validators.required],
      c_etancheite_circuit_frigorifique: [null, Validators.required],
      v_etat_liaison_frigorifique: [null, Validators.required],
      depoussierage_ui_ue: [null, Validators.required],
      nettoyage_filtre_ui: [null, Validators.required],
      v_ecoulement_eau_nettoyage_bac_condensat: [null, Validators.required],
      desinfection_evapo_filtre: [null, Validators.required],
      c_evaporateur_filtre: [null, Validators.required],
      peignerEchangeur_pacaa: [null, Validators.required],

      equipement_ok: [null],
      nouvelle_inter: [null],
      nouvelle_inter_cause: [null],
      commentaire: [null],
      maj_fluide: [null],

      pav_v_general_ui_ue: [this.pav_v_general_ui_ue],
      pap_v_general_ui_ue: [this.pap_v_general_ui_ue],
      pav_v_fixations: [this.pav_v_fixations],
      pap_v_fixations: [this.pap_v_fixations],
      pav_v_etat_liaison_frigorifique: [this.pav_v_etat_liaison_frigorifique],
      pap_v_etat_liaison_frigorifique: [this.pap_v_etat_liaison_frigorifique],
      pav_depoussierage_ui_ue: [this.pav_depoussierage_ui_ue],
      pap_depoussierage_ui_ue: [this.pap_depoussierage_ui_ue],
      pav_nettoyage_filtre_ui: [this.pav_nettoyage_filtre_ui],
      pap_nettoyage_filtre_ui: [this.pap_nettoyage_filtre_ui],
      pav_desinfection_evapo_filtre: [this.pav_desinfection_evapo_filtre],
      pap_desinfection_evapo_filtre: [this.pap_desinfection_evapo_filtre],
      pav_c_evaporateur_filtre: [this.pav_c_evaporateur_filtre],
      pap_c_evaporateur_filtre: [this.pap_c_evaporateur_filtre],
      pav_peignerEchangeur: [this.pav_peignerEchangeur],
      pap_peignerEchangeur_pacaa: [this.pap_peignerEchangeur_pacaa],
    });
  }

  ngOnInit(): void {
    this.formVmc.get('heureDepartHeure').valueChanges.subscribe((value) => {
      if (value < 0 || value > 23) {
        this.formVmc.get('heureDepartHeure').setErrors({ outOfRange: true });
      }
    });

    this.formVmc.get('heureDepartMinute').valueChanges.subscribe((value) => {
      if (value < 0 || value > 59) {
        this.formVmc.get('heureDepartMinute').setErrors({ outOfRange: true });
      }
    });

    this.formVmc.get('heureArriveeHeure').valueChanges.subscribe((value) => {
      if (value < 0 || value > 23) {
        this.formVmc.get('heureArriveeHeure').setErrors({ outOfRange: true });
      }
    });

    this.formVmc.get('heureArriveeMinute').valueChanges.subscribe((value) => {
      if (value < 0 || value > 59) {
        this.formVmc.get('heureArriveeMinute').setErrors({ outOfRange: true });
      }
    });

    this.formPv.get('heureDepartHeure').valueChanges.subscribe((value) => {
      if (value < 0 || value > 23) {
        this.formPv.get('heureDepartHeure').setErrors({ outOfRange: true });
      }
    });
    this.formPv.get('heureDepartMinute').valueChanges.subscribe((value) => {
      if (value < 0 || value > 59) {
        this.formPv.get('heureDepartMinute').setErrors({ outOfRange: true });
      }
    });
    this.formPv.get('heureArriveeHeure').valueChanges.subscribe((value) => {
      if (value < 0 || value > 23) {
        this.formPv.get('heureArriveeHeure').setErrors({ outOfRange: true });
      }
    });
    this.formPv.get('heureArriveeMinute').valueChanges.subscribe((value) => {
      if (value < 0 || value > 59) {
        this.formPv.get('heureArriveeMinute').setErrors({ outOfRange: true });
      }
    });

    this.formBt.get('heureDepartHeure').valueChanges.subscribe((value) => {
      if (value < 0 || value > 23) {
        this.formBt.get('heureDepartHeure').setErrors({ outOfRange: true });
      }
    });
    this.formBt.get('heureDepartMinute').valueChanges.subscribe((value) => {
      if (value < 0 || value > 59) {
        this.formBt.get('heureDepartMinute').setErrors({ outOfRange: true });
      }
    });
    this.formBt.get('heureArriveeHeure').valueChanges.subscribe((value) => {
      if (value < 0 || value > 23) {
        this.formBt.get('heureArriveeHeure').setErrors({ outOfRange: true });
      }
    });
    this.formBt.get('heureArriveeMinute').valueChanges.subscribe((value) => {
      if (value < 0 || value > 59) {
        this.formBt.get('heureArriveeMinute').setErrors({ outOfRange: true });
      }
    });
    this.formPacaa.get('heureDepartMinute').valueChanges.subscribe((value) => {
      if (value < 0 || value > 59) {
        this.formPacaa.get('heureDepartMinute').setErrors({ outOfRange: true });
      }
    });
    this.formPacaa.get('heureDepartHeure').valueChanges.subscribe((value) => {
      if (value < 0 || value > 23) {
        this.formPacaa.get('heureDepartHeure').setErrors({ outOfRange: true });
      }
    });
    this.formPacaa.get('heureArriveeMinute').valueChanges.subscribe((value) => {
      if (value < 0 || value > 59) {
        this.formPacaa
          .get('heureArriveeMinute')
          .setErrors({ outOfRange: true });
      }
    });
    this.formPacaa.get('heureArriveeHeure').valueChanges.subscribe((value) => {
      if (value < 0 || value > 23) {
        this.formPacaa.get('heureArriveeHeure').setErrors({ outOfRange: true });
      }
    });
    this.formPacae.get('heureArriveeHeure').valueChanges.subscribe((value) => {
      if (value < 0 || value > 23) {
        this.formPacae.get('heureArriveeHeure').setErrors({ outOfRange: true });
      }
    });
    this.formPacae.get('heureArriveeMinute').valueChanges.subscribe((value) => {
      if (value < 0 || value > 23) {
        this.formPacae
          .get('heureArriveeMinute')
          .setErrors({ outOfRange: true });
      }
    });
    this.formPacae.get('heureDepartHeure').valueChanges.subscribe((value) => {
      if (value < 0 || value > 23) {
        this.formPacae.get('heureDepartHeure').setErrors({ outOfRange: true });
      }
    });
    this.formPacae.get('heureDepartMinute').valueChanges.subscribe((value) => {
      if (value < 0 || value > 23) {
        this.formPacae.get('heureDepartMinute').setErrors({ outOfRange: true });
      }
    });
  }

  onSelectionChange(event): void {
    this.selectedProduct = event.value;
    this.checkPiecePhotosAVAP();
    if (this.selectedProduct?.name === 'VMC Double Flux') {
      this.formSelected = this.formVmc.value;
      this.setVmcValidators();
    } else if (this.selectedProduct?.name === 'Panneaux solaires') {
      this.formSelected = this.formPv.value;
      this.setPvValidators();
    } else if (this.selectedProduct?.name === 'Ballon thermodynamique') {
      this.formSelected = this.formBt.value;
      this.setBtValidators();
    } else if (this.selectedProduct?.name === 'PAC Air/Air') {
      this.formSelected = this.formPacaa.value;
      this.setPacaaValidators();
    } else if (this.selectedProduct?.name === 'PAC Air/Eau') {
      this.formSelected = this.formPacae.value;
      this.setPacaeValidators();
    } else {
      this.clearVmcValidators();
      this.clearPvValidators();
      this.clearPacaaValidators();
      this.clearPacaeValidators();
      this.clearBtValidators();
    }
  }

  getCurrentKitAndSaveChange(event, key, currentKit) {
    currentKit[key] = event;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex((item) => item.name === currentKit.type);

    array[index] = currentKit;

    this.case.kits_vendus = JSON.stringify(array);

    this.sCases.editCase(this.case, this.case._id);
  }

  async valid() {
    this.loader = true;
    this.createDoc = await this.generateDoc();
    this.openDialog();
  }

  async generateDoc() {
    if (this.selectedProduct?.name === 'VMC Double Flux') {
      this.formSelected = this.formVmc.value;
    } else if (this.selectedProduct?.name === 'Panneaux solaires') {
      this.formSelected = this.formPv.value;
    } else if (this.selectedProduct?.name === 'Ballon thermodynamique') {
      this.formSelected = this.formBt.value;
    } else if (this.selectedProduct?.name === 'PAC Air/Air') {
      this.formSelected = this.formPacaa.value;
    } else if (this.selectedProduct?.name === 'PAC Air/Eau') {
      this.formSelected = this.formPacae.value;
    }
    let response = await this.sPdfs
      .generateBiCm(
        'bi_cm',
        this.case,
        this.selectedProduct,
        '',
        '',
        this.chantierId,
        this.formSelected
      )
      .then((res) => {
        if (res === 'ok') {
          console.log(res);
        }
        this.loader = false;
      });
    return response;
  }

  openDialog(): void {
    setTimeout(() => {
      const dialogRef = this.dialog.open(ModalPrevisualizeComponent, {
        width: '100vh',
        height: '100vh',
        maxWidth: '100vh',
        maxHeight: '100vh',
        hasBackdrop: false,
        disableClose: true,
        panelClass: 'pdf-visualize',
        data: {
          titlePiece: 'bi_cm',
          caseId: this.case._id,
          case: this.case,
          kit: this.selectedProduct,
          chantierId: this.chantierId,
          form: this.formSelected,
          formulaireByType: this.formSelected,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {});
    }, 1000);
  }

  setVmcValidators(): void {
    this.formVmc.get('nettoyage').setValidators([Validators.required]);
    this.formVmc.get('v_evac_condensat').setValidators([Validators.required]);
    this.formVmc.get('v_chapeau_toit').setValidators([Validators.required]);
    this.formVmc.get('c_alim_elec').setValidators([Validators.required]);
    this.formVmc.get('c_etat_gaine').setValidators([Validators.required]);
    this.formVmc
      .get('v_piles_telecommande')
      .setValidators([Validators.required]);
    this.formVmc.get('equipement_ok').setValidators([Validators.required]);

    this.formVmc.get('nouvelle_inter_cause');
    this.formVmc.get('nettoyage').updateValueAndValidity();
    this.formVmc.get('v_evac_condensat').updateValueAndValidity();
    this.formVmc.get('v_chapeau_toit').updateValueAndValidity();
    this.formVmc.get('c_alim_elec').updateValueAndValidity();
    this.formVmc.get('c_etat_gaine').updateValueAndValidity();
    this.formVmc.get('v_piles_telecommande').updateValueAndValidity();
    this.formVmc.get('equipement_ok').updateValueAndValidity();
  }

  setPacaeValidators(): void {
    this.formPacae
      .get('circuitFrigorifique')
      .setValidators([Validators.required]);
    this.formPacae.get('tensionsUnite').setValidators([Validators.required]);
    this.formPacae.get('nettoyageFiltres').setValidators([Validators.required]);
    this.formPacae
      .get('parametresTelecommandes')
      .setValidators([Validators.required]);
    this.formPacae
      .get('nettoyageEchangeurs')
      .setValidators([Validators.required]);
    this.formPacae
      .get('verificationLiaisons')
      .setValidators([Validators.required]);
    this.formPacae
      .get('moduleHydraulique')
      .setValidators([Validators.required]);
    this.formPacae.get('pressionPurge').setValidators([Validators.required]);
    this.formPacae
      .get('verificationFuite')
      .setValidators([Validators.required]);
    this.formPacae.get('peignerEchangeur').setValidators([Validators.required]);

    // Mettre à jour la validité des valeurs des champs
    this.formPacae.get('circuitFrigorifique').updateValueAndValidity();
    this.formPacae.get('tensionsUnite').updateValueAndValidity();
    this.formPacae.get('nettoyageFiltres').updateValueAndValidity();
    this.formPacae.get('parametresTelecommandes').updateValueAndValidity();
    this.formPacae.get('nettoyageEchangeurs').updateValueAndValidity();
    this.formPacae.get('verificationLiaisons').updateValueAndValidity();
    this.formPacae.get('moduleHydraulique').updateValueAndValidity();
    this.formPacae.get('pressionPurge').updateValueAndValidity();
    this.formPacae.get('verificationFuite').updateValueAndValidity();
    this.formPacae.get('peignerEchangeur').updateValueAndValidity();
  }
  clearPacaeValidators(): void {
    this.formPacae.get('circuitFrigorifique').clearValidators();
    this.formPacae.get('tensionsUnite').clearValidators();
    this.formPacae.get('nettoyageFiltres').clearValidators();
    this.formPacae.get('parametresTelecommandes').clearValidators();
    this.formPacae.get('nettoyageEchangeurs').clearValidators();
    this.formPacae.get('verificationLiaisons').clearValidators();
    this.formPacae.get('moduleHydraulique').clearValidators();
    this.formPacae.get('pressionPurge').clearValidators();
    this.formPacae.get('verificationFuite').clearValidators();
    this.formPacae.get('peignerEchangeur').clearValidators();
  }
  setPacaaValidators(): void {
    this.formPacaa.get('v_general_ui_ue').setValidators([Validators.required]);
    this.formPacaa.get('v_fixations').setValidators([Validators.required]);
    this.formPacaa
      .get('remplacement_piles')
      .setValidators([Validators.required]);
    this.formPacaa
      .get('c_condenseur_filtre')
      .setValidators([Validators.required]);
    this.formPacaa
      .get('v_inversion_cycle')
      .setValidators([Validators.required]);
    this.formPacaa
      .get('c_etancheite_circuit_frigorifique')
      .setValidators([Validators.required]);
    this.formPacaa
      .get('v_etat_liaison_frigorifique')
      .setValidators([Validators.required]);
    this.formPacaa
      .get('depoussierage_ui_ue')
      .setValidators([Validators.required]);
    this.formPacaa
      .get('nettoyage_filtre_ui')
      .setValidators([Validators.required]);
    this.formPacaa
      .get('v_ecoulement_eau_nettoyage_bac_condensat')
      .setValidators([Validators.required]);
    this.formPacaa
      .get('desinfection_evapo_filtre')
      .setValidators([Validators.required]);
    this.formPacaa
      .get('c_evaporateur_filtre')
      .setValidators([Validators.required]);
    this.formPacaa
      .get('peignerEchangeur_pacaa')
      .setValidators([Validators.required]);
    this.formPacaa.get('equipement_ok').setValidators([Validators.required]);
    this.formPacaa.get('nouvelle_inter').setValidators([Validators.required]);
    this.formPacaa.get('maj_fluide').setValidators([Validators.required]);

    // Mettre à jour la validité des valeurs des champs
    this.formPacaa.get('v_general_ui_ue').updateValueAndValidity();
    this.formPacaa.get('v_fixations').updateValueAndValidity();
    this.formPacaa.get('remplacement_piles').updateValueAndValidity();
    this.formPacaa.get('c_condenseur_filtre').updateValueAndValidity();
    this.formPacaa.get('v_inversion_cycle').updateValueAndValidity();
    this.formPacaa
      .get('c_etancheite_circuit_frigorifique')
      .updateValueAndValidity();
    this.formPacaa.get('v_etat_liaison_frigorifique').updateValueAndValidity();
    this.formPacaa.get('depoussierage_ui_ue').updateValueAndValidity();
    this.formPacaa.get('nettoyage_filtre_ui').updateValueAndValidity();
    this.formPacaa
      .get('v_ecoulement_eau_nettoyage_bac_condensat')
      .updateValueAndValidity();
    this.formPacaa.get('desinfection_evapo_filtre').updateValueAndValidity();
    this.formPacaa.get('c_evaporateur_filtre').updateValueAndValidity();
    this.formPacaa.get('peignerEchangeur_pacaa').updateValueAndValidity();
    this.formPacaa.get('equipement_ok').updateValueAndValidity();
    this.formPacaa.get('nouvelle_inter').updateValueAndValidity();
    this.formPacaa.get('maj_fluide').updateValueAndValidity();
  }
  clearPacaaValidators(): void {
    this.formPacaa.get('v_general_ui_ue').clearValidators();
    this.formPacaa.get('v_fixations').clearValidators();
    this.formPacaa.get('remplacement_piles').clearValidators();
    this.formPacaa.get('c_condenseur_filtre').clearValidators();
    this.formPacaa.get('v_inversion_cycle').clearValidators();
    this.formPacaa.get('c_etancheite_circuit_frigorifique').clearValidators();
    this.formPacaa.get('v_etat_liaison_frigorifique').clearValidators();
    this.formPacaa.get('depoussierage_ui_ue').clearValidators();
    this.formPacaa.get('nettoyage_filtre_ui').clearValidators();
    this.formPacaa
      .get('v_ecoulement_eau_nettoyage_bac_condensat')
      .clearValidators();
    this.formPacaa.get('desinfection_evapo_filtre').clearValidators();
    this.formPacaa.get('c_evaporateur_filtre').clearValidators();
    this.formPacaa.get('peignerEchangeur_pacaa').clearValidators();
    this.formPacaa.get('equipement_ok').clearValidators();
    this.formPacaa.get('nouvelle_inter').clearValidators();
    this.formPacaa.get('maj_fluide').clearValidators();
  }
  clearVmcValidators(): void {
    this.formVmc.get('nouvelle_inter_cause').clearValidators();
    this.formVmc.get('nettoyage').clearValidators();
    this.formVmc.get('v_evac_condensat').clearValidators();
    this.formVmc.get('v_chapeau_toit').clearValidators();
    this.formVmc.get('c_alim_elec').clearValidators();
    this.formVmc.get('c_etat_gaine').clearValidators();
    this.formVmc.get('v_piles_telecommande').clearValidators();
    this.formVmc.get('equipement_ok').clearValidators();
  }
  setPvValidators(): void {
    this.formVmc.get('etatModules').setValidators([Validators.required]);
    this.formVmc.get('nettoyagePanneaux').setValidators([Validators.required]);
    this.formVmc.get('etatSystemeMontage').setValidators([Validators.required]);
    this.formVmc
      .get('absenceInfiltration')
      .setValidators([Validators.required]);
    this.formVmc.get('serrageAcDc').setValidators([Validators.required]);
    this.formVmc.get('v_continuites').setValidators([Validators.required]);
    this.formVmc
      .get('v_tableau_electrique')
      .setValidators([Validators.required]);
    this.formVmc.get('nettoyage_filtres').setValidators([Validators.required]);
    this.formVmc.get('v_usage_local').setValidators([Validators.required]);
    this.formVmc.get('v_parafoudres').setValidators([Validators.required]);
    this.formVmc
      .get('v_mode_erreur_onduleur')
      .setValidators([Validators.required]);
    this.formVmc
      .get('v_systeme_ouverture_tableau')
      .setValidators([Validators.required]);
    this.formVmc.get('v_coupure_urgence').setValidators([Validators.required]);
    this.formVmc.get('etiquettes').setValidators([Validators.required]);
    this.formVmc
      .get('reconnexion_passerelle')
      .setValidators([Validators.required]);
    this.formVmc.get('equipement_ok').setValidators([Validators.required]);
    this.formVmc.get('nouvelle_inter').setValidators([Validators.required]);

    this.formVmc.get('etatModules').updateValueAndValidity();
    this.formVmc.get('nettoyagePanneaux').updateValueAndValidity();
    this.formVmc.get('etatSystemeMontage').updateValueAndValidity();
    this.formVmc.get('absenceInfiltration').updateValueAndValidity();
    this.formVmc.get('serrageAcDc').updateValueAndValidity();
    this.formVmc.get('v_continuites').updateValueAndValidity();
    this.formVmc.get('v_tableau_electrique').updateValueAndValidity();
    this.formVmc.get('nettoyage_filtres').updateValueAndValidity();
    this.formVmc.get('v_usage_local').updateValueAndValidity();
    this.formVmc.get('v_parafoudres').updateValueAndValidity();
    this.formVmc.get('v_mode_erreur_onduleur').updateValueAndValidity();
    this.formVmc.get('v_systeme_ouverture_tableau').updateValueAndValidity();
    this.formVmc.get('v_coupure_urgence').updateValueAndValidity();
    this.formVmc.get('etiquettes').updateValueAndValidity();
    this.formVmc.get('reconnexion_passerelle').updateValueAndValidity();
    this.formVmc.get('equipement_ok').updateValueAndValidity();
    this.formVmc.get('nouvelle_inter').updateValueAndValidity();
  }

  setBtValidators(): void {
    this.formBt.get('vaseExpansion').setValidators([Validators.required]);
    this.formBt.get('groupeExterieur').setValidators([Validators.required]);
    this.formBt.get('jointBride').setValidators([Validators.required]);
    this.formBt.get('parametrages').setValidators([Validators.required]);
    this.formBt
      .get('alimentationConnexions')
      .setValidators([Validators.required]);
    this.formBt.get('fuiteRaccordements').setValidators([Validators.required]);
    this.formBt.get('inspectionRobinets').setValidators([Validators.required]);
    this.formBt.get('peignerEchangeur').setValidators([Validators.required]);
    this.formBt.get('nettoyageUE').setValidators([Validators.required]);

    // Mettre à jour la validité des valeurs des champs
    this.formBt.get('vaseExpansion').updateValueAndValidity();
    this.formBt.get('groupeExterieur').updateValueAndValidity();
    this.formBt.get('jointBride').updateValueAndValidity();
    this.formBt.get('parametrages').updateValueAndValidity();
    this.formBt.get('alimentationConnexions').updateValueAndValidity();
    this.formBt.get('fuiteRaccordements').updateValueAndValidity();
    this.formBt.get('inspectionRobinets').updateValueAndValidity();
    this.formBt.get('peignerEchangeur').updateValueAndValidity();
    this.formBt.get('nettoyageUE').updateValueAndValidity();
  }
  clearBtValidators(): void {
    this.formBt.get('vaseExpansion').clearValidators();
    this.formBt.get('groupeExterieur').clearValidators();
    this.formBt.get('jointBride').clearValidators();
    this.formBt.get('parametrages').clearValidators();
    this.formBt.get('alimentationConnexions').clearValidators();
    this.formBt.get('fuiteRaccordements').clearValidators();
    this.formBt.get('inspectionRobinets').clearValidators();
    this.formBt.get('peignerEchangeur').clearValidators();
    this.formBt.get('nettoyageUE').clearValidators();
  }
  clearPvValidators(): void {
    this.formVmc.get('etatModules').clearValidators();
    this.formVmc.get('nettoyagePanneaux').clearValidators();
    this.formVmc.get('etatSystemeMontage').clearValidators();
    this.formVmc.get('absenceInfiltration').clearValidators();
    this.formVmc.get('serrageAcDc').clearValidators();
    this.formVmc.get('v_continuites').clearValidators();
    this.formVmc.get('v_tableau_electrique').clearValidators();
    this.formVmc.get('nettoyage_filtres').clearValidators();
    this.formVmc.get('v_usage_local').clearValidators();
    this.formVmc.get('v_parafoudres').clearValidators();
    this.formVmc.get('v_mode_erreur_onduleur').clearValidators();
    this.formVmc.get('v_systeme_ouverture_tableau').updateValueAndValidity();
    this.formVmc.get('v_coupure_urgence').clearValidators();
    this.formVmc.get('etiquettes').clearValidators();
    this.formVmc.get('reconnexion_passerelle').clearValidators();
    this.formVmc.get('equipement_ok').clearValidators();
    this.formVmc.get('nouvelle_inter').clearValidators();
  }
  ngAfterContentInit() {
    this.checkPiecePhotosAVAP();
  }

  onSubmitVmc(): void {
    if (this.formVmc.invalid) {
      return;
    }
    this.valid();
  }
  onSubmitPv(): void {
    if (this.formPv.invalid) {
      return;
    }
    this.valid();
  }
  onSubmitPacaa(): void {
    if (this.formPacaa.invalid) {
      return;
    }
    this.valid();
  }
  onSubmitPacae(): void {
    if (this.formPacae.invalid) {
      return;
    }
    this.valid();
  }
  onSubmitBt(): void {
    if (this.formBt.invalid) {
      return;
    }
    this.valid();
  }
  checkPiecePhotosAVAP() {
    this.sPieces
      .getPiecesByChantierIdAndCible(this.chantierId._id, 'photo_avant_apres')
      .subscribe((res) => {
        const piece: any = res;
        if (this.selectedProduct?.name === 'VMC Double Flux') {
          this.checkPiecesVmc(piece);
        }
        if (this.selectedProduct?.name === 'Panneaux solaires') {
          this.checkPiecesPv(piece);
        }
        if (this.selectedProduct?.name === 'Ballon thermodynamique') {
          this.checkPiecesBt(piece);
        }
        if (this.selectedProduct?.name === 'PAC Air/Air') {
          this.checkPiecesPacaa(piece);
        }
        if (this.selectedProduct?.name === 'PAC Air/Eau') {
          this.checkPiecesPacae(piece);
        }
      });
  }

  selectPhotos(event, title) {
    if (event.target.files.length > 0) {
      this.multipleImagesPieces = event.target.files;
    }
    this.onSubmitPhoto(title);
  }

  onSubmitPhoto(title) {
    const form = {
      title: title,
      qualification: 'Conforme',
      caseId: this.case._id,
      created_at: new Date(),
      type: 'technicien',
      chantier: this.chantierId._id,
      cible: 'photo_avant_apres',
      description: '',
    };
    this.sPieces.addTechPiece(
      form,
      this.multipleImagesPieces,
      this.case._id,
      this.chantierId._id
    );
    setTimeout(() => {
      this.checkPiecePhotosAVAP();
    }, 1100);
  }

  checkPiecesPv(piece) {
    this.pav_nettoyage_panneaux = piece.find(
      (e) => e.title === 'Photo avant nettoyage panneaux solaires'
    )
      ? true
      : false;
    this.pap_nettoyage_panneaux = piece.find(
      (e) => e.title === 'Photo après nettoyage panneaux solaires'
    )
      ? true
      : false;

    this.pav_etat_modules = piece.find(
      (e) => e.title === 'Photo avant état des modules'
    )
      ? true
      : false;
    this.pap_etat_modules = piece.find(
      (e) => e.title === 'Photo après état des modules'
    )
      ? true
      : false;

    this.pav_systeme_montage = piece.find(
      (e) => e.title === 'Photo avant état du système de montage'
    )
      ? true
      : false;
    this.pap_systeme_montage = piece.find(
      (e) => e.title === 'Photo après état du système de montage'
    )
      ? true
      : false;

    this.pav_absence_infiltration = piece.find(
      (e) => e.title === 'Photo avant absence infiltration'
    )
      ? true
      : false;
    this.pap_absence_infiltration = piece.find(
      (e) => e.title === 'Photo après absence infiltration'
    )
      ? true
      : false;
    this.pav_serrage_ac_dc = piece.find(
      (e) => e.title === 'Photo avant serrage de connexions AC et DC'
    )
      ? true
      : false;
    this.pap_serrage_ac_dc = piece.find(
      (e) => e.title === 'Photo après serrage de connexions AC et DC'
    )
      ? true
      : false;

    this.pav_v_continuites = piece.find(
      (e) => e.title === 'Photo avant Vérification des continuités'
    )
      ? true
      : false;
    this.pap_v_continuites = piece.find(
      (e) => e.title === 'Photo après Vérification des continuités'
    )
      ? true
      : false;

    this.pav_v_usage_local = piece.find(
      (e) =>
        e.title ===
        'Photo avant Vérification de bon usage du local électrique (absence de produit inflammable, atmosphère corrosive et poussiéreuse)'
    )
      ? true
      : false;

    //

    this.pav_nettoyage_filtres = piece.find(
      (e) =>
        e.title ===
        'Photo avant Nettoyage des filtres et grilles de ventilation des onduleurs (si onduleur)'
    )
      ? true
      : false;

    this.pap_nettoyage_filtres = piece.find(
      (e) =>
        e.title ===
        'Photo après Nettoyage des filtres et grilles de ventilation des onduleurs (si onduleur)'
    )
      ? true
      : false;

    this.pav_v_tableau_electrique = piece.find(
      (e) =>
        e.title ===
        'Photo avant des tableaux électriques, resserrage des bornes'
    )
      ? true
      : false;

    this.pav_v_coupure_urgence = piece.find(
      (e) =>
        e.title ===
        'Photo avant Vérification du bon fonctionnement du ou des dispositifs de coupure d’urgence'
    )
      ? true
      : false;

    this.pap_v_coupure_urgence = piece.find(
      (e) =>
        e.title ===
        'Photo après Vérification du bon fonctionnement du ou des dispositifs de coupure d’urgence'
    )
      ? true
      : false;

    this.pav_v_systeme_ouverture_tableau = piece.find(
      (e) =>
        e.title ===
        'Photo avant Vérification du système d’ouverture du tableau électrique'
    )
      ? true
      : false;

    this.pap_v_systeme_ouverture_tableau = piece.find(
      (e) =>
        e.title ===
        'Photo après Vérification du système d’ouverture du tableau électrique'
    )
      ? true
      : false;

    this.pav_etiquettes = piece.find(
      (e) =>
        e.title ===
        'Photo avant Remplacement ou pose étiquette signalétiques réglementaires'
    )
      ? true
      : false;

    this.pap_etiquettes = piece.find(
      (e) =>
        e.title ===
        'Photo après Remplacement ou pose étiquette signalétiques réglementaires'
    )
      ? true
      : false;

    this.pav_reconnexion_passerelle = piece.find(
      (e) =>
        e.title ===
        'Photo avant Reconnexion passerelle si non connectée à interne'
    )
      ? true
      : false;

    this.pap_reconnexion_passerelle = piece.find(
      (e) =>
        e.title ===
        'Photo après Reconnexion passerelle si non connectée à interne'
    )
      ? true
      : false;

    this.pav_v_parafoudres = piece.find(
      (e) =>
        e.title ===
        'Photo avant Vérification des parafoudres AC et DC (si existants)'
    )
      ? true
      : false;

    this.pap_v_mode_erreur_onduleur = piece.find(
      (e) => e.title === 'Photo après vérification des micros onduleurs'
    )
      ? true
      : false;

    this.formPv.patchValue({
      pav_nettoyage_panneaux: this.pav_nettoyage_panneaux,
      pap_nettoyage_panneaux: this.pap_nettoyage_panneaux,
      pav_etat_modules: this.pav_etat_modules,
      pap_etat_modules: this.pap_etat_modules,
      pav_systeme_montage: this.pav_systeme_montage,
      pap_systeme_montage: this.pap_systeme_montage,
      pav_absence_infiltration: this.pav_absence_infiltration,
      pap_absence_infiltration: this.pap_absence_infiltration,
      pav_serrage_ac_dc: this.pav_serrage_ac_dc,
      pap_serrage_ac_dc: this.pap_serrage_ac_dc,
      pav_v_continuites: this.pav_v_continuites,
      pap_v_continuites: this.pap_v_continuites,
      pav_v_tableau_electrique: this.pav_v_tableau_electrique,
      pav_nettoyage_filtres: this.pav_nettoyage_filtres,
      pap_nettoyage_filtres: this.pap_nettoyage_filtres,
      pav_v_usage_local: this.pav_v_usage_local,
      pav_v_parafoudres: this.pav_v_parafoudres,
      pap_v_mode_erreur_onduleur: this.pap_v_mode_erreur_onduleur,
      pav_v_systeme_ouverture_tableau: this.pav_v_systeme_ouverture_tableau,
      pap_v_systeme_ouverture_tableau: this.pap_v_systeme_ouverture_tableau,
      pav_v_coupure_urgence: this.pav_v_coupure_urgence,
      pap_v_coupure_urgence: this.pap_v_coupure_urgence,
      pav_etiquettes: this.pav_etiquettes,
      pap_etiquettes: this.pap_etiquettes,
      pav_reconnexion_passerelle: this.pav_reconnexion_passerelle,
      pap_reconnexion_passerelle: this.pap_reconnexion_passerelle,
    });
  }
  checkPiecesBt(piece) {
    this.pav_vaseExpansion = piece.find(
      (e) =>
        e.title === 'Photo avant Contrôle du vase d’expansion (si installé)'
    )
      ? true
      : false;
    this.pap_vaseExpansion = piece.find(
      (e) =>
        e.title === 'Photo après Contrôle du vase d’expansion (si installé)'
    )
      ? true
      : false;
    this.pav_groupeExterieur = piece.find(
      (e) => e.title === 'Photo avant Contrôle du groupe extérieur'
    )
      ? true
      : false;
    this.pap_groupeExterieur = piece.find(
      (e) => e.title === 'Photo après Contrôle du groupe extérieur'
    )
      ? true
      : false;
    this.pav_jointBride = piece.find(
      (e) =>
        e.title === 'Photo avant Contrôle et/ou changement du joint de bride'
    )
      ? true
      : false;
    this.pap_jointBride = piece.find(
      (e) =>
        e.title === 'Photo après Contrôle et/ou changement du joint de bride'
    )
      ? true
      : false;
    this.pav_parametrages = piece.find(
      (e) => e.title === 'Photo avant Contrôle des paramétrages'
    )
      ? true
      : false;
    this.pap_parametrages = piece.find(
      (e) => e.title === 'Photo après Contrôle des paramétrages'
    )
      ? true
      : false;
    this.pav_alimentationConnexions = piece.find(
      (e) =>
        e.title ===
        'Photo avant Contrôle de l’alimentation et resserrage des connexion'
    )
      ? true
      : false;
    this.pap_alimentationConnexions = piece.find(
      (e) =>
        e.title ===
        'Photo après Contrôle de l’alimentation et resserrage des connexion'
    )
      ? true
      : false;

    this.pap_fuiteRaccordements = piece.find(
      (e) =>
        e.title === 'Photo après Contrôle de fuite au niveau des raccordements'
    )
      ? true
      : false;
    this.pap_inspectionRobinets = piece.find(
      (e) =>
        e.title ===
        'Photo après Inspection des robinets et des canalisations (humidité et rouille)'
    )
      ? true
      : false;

    this.pav_peignerEchangeur = piece.find(
      (e) => e.title === 'Photo avant Peigner échangeur de UE'
    )
      ? true
      : false;
    this.pap_peignerEchangeur = piece.find(
      (e) => e.title === 'Photo après Peigner échangeur UE'
    )
      ? true
      : false;

    this.pav_nettoyageUE = piece.find(
      (e) => e.title === 'Photo avant Nettoyage de l’UE avec une bombe'
    )
      ? true
      : false;
    this.pap_nettoyageUE = piece.find(
      (e) => e.title === 'Photo après Nettoyage de l’UE avec une bombe'
    )
      ? true
      : false;

    this.formBt.patchValue({
      pav_vaseExpansion: this.pav_vaseExpansion,
      pap_vaseExpansion: this.pap_vaseExpansion,
      pav_groupeExterieur: this.pav_groupeExterieur,
      pap_groupeExterieur: this.pap_groupeExterieur,
      pav_jointBride: this.pav_jointBride,
      pap_jointBride: this.pap_jointBride,
      pav_parametrages: this.pav_parametrages,
      pap_parametrages: this.pap_parametrages,
      pav_alimentationConnexions: this.pav_alimentationConnexions,
      pap_alimentationConnexions: this.pap_alimentationConnexions,
      pap_fuiteRaccordements: this.pap_fuiteRaccordements,
      pap_inspectionRobinets: this.pap_inspectionRobinets,
      pav_peignerEchangeur: this.pav_peignerEchangeur,
      pap_peignerEchangeur: this.pap_peignerEchangeur,
      pav_nettoyageUE: this.pav_nettoyageUE,
      pap_nettoyageUE: this.pap_nettoyageUE,
    });
  }
  checkPiecesPacaa(piece) {
    this.pav_v_general_ui_ue = piece.find(
      (e) =>
        e.title ===
        'Photo avant Verification et controle général des unites interieures et extérieures'
    )
      ? true
      : false;
    this.pap_v_general_ui_ue = piece.find(
      (e) =>
        e.title ===
        'Photo après Verification et controle général des unites interieures et extérieures'
    )
      ? true
      : false;
    this.pav_v_fixations = piece.find(
      (e) => e.title === 'Photo avant Vérification des fixations et supports'
    )
      ? true
      : false;
    this.pap_v_fixations = piece.find(
      (e) => e.title === 'Photo après Vérification des fixations et supports'
    )
      ? true
      : false;

    this.pav_v_etat_liaison_frigorifique = piece.find(
      (e) =>
        e.title ===
        'Photo avant Verification de l’etat et de la nature des liaisons frigorifiques'
    )
      ? true
      : false;
    this.pap_v_etat_liaison_frigorifique = piece.find(
      (e) =>
        e.title ===
        'Photo après Verification de l’etat et de la nature des liaisons frigorifiques'
    )
      ? true
      : false;

    this.pav_depoussierage_ui_ue = piece.find(
      (e) =>
        e.title ===
        'Photo avant Dépoussiérage et nettoyage des unités interieures et exterieures'
    )
      ? true
      : false;
    this.pap_depoussierage_ui_ue = piece.find(
      (e) =>
        e.title ===
        'Photo après Dépoussiérage et nettoyage des unités interieures et exterieures'
    )
      ? true
      : false;
    this.pav_nettoyage_filtre_ui = piece.find(
      (e) =>
        e.title ===
        'Photo avant Contrôle et nettoyages des filtres des unités intérieures (voir remplacement)'
    )
      ? true
      : false;
    this.pap_nettoyage_filtre_ui = piece.find(
      (e) =>
        e.title ===
        'Photo après Contrôle et nettoyages des filtres des unités intérieures (voir remplacement)'
    )
      ? true
      : false;
    this.pav_desinfection_evapo_filtre = piece.find(
      (e) =>
        e.title ===
        'Photo avant Désinfection des évaporateurs et des filtres (désinfectant bactéricides)'
    )
      ? true
      : false;
    this.pap_desinfection_evapo_filtre = piece.find(
      (e) =>
        e.title ===
        'Photo après Désinfection des évaporateurs et des filtres (désinfectant bactéricides)'
    )
      ? true
      : false;

    this.pav_c_evaporateur_filtre = piece.find(
      (e) =>
        e.title ===
        "Photo avant Contrôle de l'évaporateur et du filtre (Nettoyage si nécessaire)"
    )
      ? true
      : false;
    this.pap_c_evaporateur_filtre = piece.find(
      (e) =>
        e.title ===
        "Photo après Contrôle de l'évaporateur et du filtre (Nettoyage si nécessaire)"
    )
      ? true
      : false;

    this.pav_peignerEchangeur_pacaa = piece.find(
      (e) => e.title === 'Photo avant Peigner l’echangeur'
    )
      ? true
      : false;
    this.pap_peignerEchangeur_pacaa = piece.find(
      (e) => e.title === 'Photo après Peigner l’echangeur'
    )
      ? true
      : false;

    this.formPacaa.patchValue({
      pav_v_general_ui_ue: this.pav_v_general_ui_ue,
      pap_v_general_ui_ue: this.pap_v_general_ui_ue,
      pav_v_fixations: this.pav_v_fixations,
      pap_v_fixations: this.pap_v_fixations,
      pav_v_etat_liaison_frigorifique: this.pav_v_etat_liaison_frigorifique,
      pap_v_etat_liaison_frigorifique: this.pap_v_etat_liaison_frigorifique,
      pav_depoussierage_ui_ue: this.pav_depoussierage_ui_ue,
      pap_depoussierage_ui_ue: this.pap_depoussierage_ui_ue,
      pav_nettoyage_filtre_ui: this.pav_nettoyage_filtre_ui,
      pap_nettoyage_filtre_ui: this.pap_nettoyage_filtre_ui,
      pav_desinfection_evapo_filtre: this.pav_desinfection_evapo_filtre,
      pap_desinfection_evapo_filtre: this.pap_desinfection_evapo_filtre,
      pav_c_evaporateur_filtre: this.pav_c_evaporateur_filtre,
      pap_c_evaporateur_filtre: this.pap_c_evaporateur_filtre,
      pav_peignerEchangeur_pacaa: this.pav_peignerEchangeur_pacaa,
      pap_peignerEchangeur_pacaa: this.pap_peignerEchangeur_pacaa,
    });
  }
  checkPiecesPacae(piece) {
    this.pav_tensionsUnite = piece.find(
      (e) =>
        e.title ===
        'Photo avant Contrôle des tensions unité intérieure / unité extérieure'
    )
      ? true
      : false;
    this.pap_tensionsUnite = piece.find(
      (e) =>
        e.title ===
        'Photo après Contrôle des tensions unité intérieure / unité extérieure'
    )
      ? true
      : false;

    this.pav_nettoyageFiltres = piece.find(
      (e) =>
        e.title ===
        'Photo avant Nettoyage des filtres à tamis et/ou pot à boue (si installé)'
    )
      ? true
      : false;
    this.pap_nettoyageFiltres = piece.find(
      (e) =>
        e.title ===
        'Photo après Nettoyage des filtres à tamis et/ou pot à boue (si installé)'
    )
      ? true
      : false;

    this.pav_nettoyageEchangeurs = piece.find(
      (e) =>
        e.title ===
        'Photo avant Nettoyage des échangeurs unité intérieure / unité extérieure'
    )
      ? true
      : false;
    this.pap_nettoyageEchangeurs = piece.find(
      (e) =>
        e.title ===
        'Photo après Nettoyage des échangeurs unité intérieure / unité extérieure'
    )
      ? true
      : false;

    this.pav_verificationLiaisons = piece.find(
      (e) =>
        e.title ===
        'Photo avant Verification de l’etat et de la nature des liaisons frigorifiques'
    )
      ? true
      : false;
    this.pap_verificationLiaisons = piece.find(
      (e) =>
        e.title ===
        'Photo après Verification de l’etat et de la nature des liaisons frigorifiques'
    )
      ? true
      : false;
    this.pav_pressionPurge = piece.find(
      (e) =>
        e.title ===
        'Photo avant Contrôle de la pression et purge des radiateurs'
    )
      ? true
      : false;
    this.pap_pressionPurge = piece.find(
      (e) =>
        e.title ===
        'Photo après Contrôle de la pression et purge des radiateurs'
    )
      ? true
      : false;
    this.pav_verificationFuite = piece.find(
      (e) => e.title === 'Photo avant Vérification fuite'
    )
      ? true
      : false;
    this.pap_verificationFuite = piece.find(
      (e) => e.title === 'Photo après Vérification fuite'
    )
      ? true
      : false;

    this.pav_peignerEchangeur_pacae = piece.find(
      (e) => e.title === 'Photo avant Peigner l’echangeur'
    )
      ? true
      : false;
    this.pap_peignerEchangeur_pacae = piece.find(
      (e) => e.title === 'Photo après Peigner l’echangeur'
    )
      ? true
      : false;
    this.formPacae.patchValue({
      pav_tensionsUnite: this.pav_tensionsUnite,
      pap_tensionsUnite: this.pap_tensionsUnite,
      pav_nettoyageFiltres: this.pav_nettoyageFiltres,
      pap_nettoyageFiltres: this.pap_nettoyageFiltres,
      pav_nettoyageEchangeurs: this.pav_nettoyageEchangeurs,
      pap_nettoyageEchangeurs: this.pap_nettoyageEchangeurs,
      pav_verificationLiaisons: this.pav_verificationLiaisons,
      pap_verificationLiaisons: this.pap_verificationLiaisons,
      pav_pressionPurge: this.pav_pressionPurge,
      pap_pressionPurge: this.pap_pressionPurge,
      pav_verificationFuite: this.pav_verificationFuite,
      pap_verificationFuite: this.pap_verificationFuite,
      pav_peignerEchangeur_pacae: this.pav_peignerEchangeur_pacae,
      pap_peignerEchangeur_pacae: this.pap_peignerEchangeur_pacae,
    });
  }

  checkPiecesVmc(piece) {
    // Nettoyage
    this.pav_nettoyage = piece.find(
      (e) => e.title === 'Photo avant nettoyage VMC'
    )
      ? true
      : false;
    this.pap_nettoyage = piece.find(
      (e) => e.title === 'Photo après nettoyage VMC'
    )
      ? true
      : false;

    // Evacuation des condensats
    this.pav_evac_condensat = piece.find(
      (e) => e.title === 'Photo avant évacuation condensats'
    )
      ? true
      : false;
    this.pap_evac_condensat = piece.find(
      (e) => e.title === 'Photo après évacuation condensats'
    )
      ? true
      : false;

    // Chapeau de toit
    this.pav_chapeau_toit = piece.find(
      (e) => e.title === 'Photo avant chapeau de toit'
    )
      ? true
      : false;
    this.pap_chapeau_toit = piece.find(
      (e) => e.title === 'Photo après chapeau de toit'
    )
      ? true
      : false;

    // Alimentation électrique
    this.pap_alim_elec = piece.find(
      (e) => e.title === 'Photo après alimentation électrique'
    )
      ? true
      : false;

    // Etat des gaines
    this.pap_etat_gaine = piece.find((e) => e.title === 'Photo état des gaines')
      ? true
      : false;

    // Piles télécommande
    this.pap_piles_telecommande = piece.find(
      (e) => e.title === 'Photo après piles télécommande'
    )
      ? true
      : false;

    this.formVmc.patchValue({
      pav_nettoyage: this.pav_nettoyage,
      pap_nettoyage: this.pap_nettoyage,
      pav_evac_condensat: this.pav_evac_condensat,
      pap_evac_condensat: this.pap_evac_condensat,
      pav_chapeau_toit: this.pav_chapeau_toit,
      pap_chapeau_toit: this.pap_chapeau_toit,
      pap_alim_elec: this.pap_alim_elec,
      pap_etat_gaine: this.pap_etat_gaine,

      pap_piles_telecommande: this.pap_piles_telecommande,
    });
  }
}
