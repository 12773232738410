import { Component, Input, OnInit } from '@angular/core';
import { CasesService } from '@app/services/cases.service';
import { RolesService } from '@app/services/roles.service';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Router } from '@angular/router';
import { NgModel } from '@angular/forms';
import { AuthenticationService } from './../../_services/authentication.service';

@Component({
  selector: 'app-compta-dashboard',
  templateUrl: './compta-dashboard.component.html',
  styleUrls: ['./compta-dashboard.component.scss'],
})
export class ComptaDashboardComponent implements OnInit {
  gridApi;
  gridColumnApi;

  columnDefs;
  defaultColDef;
  rowData;
  rowStyle;
  cases;
  comptaCases;
  rowHeight;

  sousTt;
  allSousTt;
  caNetTt;
  allCaNetTt;
  totalCA;
  totalFacture;

  months = [
    { name: 'Janvier', checked: false, number: '01' },
    { name: 'Février', checked: false, number: '02' },
    { name: 'Mars', checked: false, number: '03' },
    { name: 'Avril', checked: false, number: '04' },
    { name: 'Mai', checked: false, number: '05' },
    { name: 'Juin', checked: false, number: '06' },
    { name: 'Juillet', checked: false, number: '07' },
    { name: 'Aout', checked: false, number: '08' },
    { name: 'Septembre', checked: false, number: '09' },
    { name: 'Octobre', checked: false, number: '10' },
    { name: 'Novembre', checked: false, number: '11' },
    { name: 'Decembre', checked: false, number: '12' },
  ];

  years = [
    { name: 2020, checked: false },
    { name: 2021, checked: false },
    { name: 2022, checked: false },
    { name: 2023, checked: false },
  ];
  actualMonth;
  selectedYear = new Date().getFullYear();
  selectedMonth = [];

  mobile;
  showEncours = true;

  billTotal = 0;
  billMissing = 0;
  caMissing = 0;

  actualNameMonth = '';
  actualYear;

  user;

  totalAvoir = 0;
  showCm = false;

  totalCaCM = 0;
  totalCmByMonth = 0;
  cmByNumberMonth = 0;
  totalAllCa = 0;
  cms = [];
  numberToShow;
  filteredResult = {
    count: null,
    price_ht: null,
    price_ttc: null,
  };
  showFilteredResult = false;

  showGdd = false;
  constructor(
    private sCases: CasesService,
    public sRoles: RolesService,
    private router: Router,
    private AuthenticationService: AuthenticationService
  ) {
    if (window.screen.width <= 600) {
      this.mobile = true;
    }
    //this.showCases()
    this.showComptaCases();

    this.rowHeight = 65;

    this.rowStyle = { background: 'white' };

    this.columnDefs = [
      {
        headerName: 'Nom',
        field: 'name',
        cellClass: 'cell-align',
        maxWidth: 300,
        minWidth: 250,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Type de paiement',
        field: 'financement_type',
        filter: 'agTextColumnFilter',
        cellClass: 'cell-align',
        minWidth: 150,
        cellRenderer: (data) => {
          return data.value.charAt(0).toUpperCase() + data.value.slice(1);
        },
      },
      {
        headerName: 'Etat travaux',
        field: 'chantier',
        cellClass: 'cell-align',
        minWidth: 180,
        cellRenderer: (data) => {
          if (data.value === undefined || data.value === null) {
            return '-';
          }
          if (data.value.firstDate === data.value.lastDate) {
            if (new Date() > new Date(data.value.lastDate)) {
              return (
                data.value.statut +
                '<br>' +
                '<em class="date">' +
                new Date(data.value.lastDate).toLocaleDateString() +
                '</em>'
              );
            } else if (new Date() === new Date(data.value.lastDate)) {
              return (
                'En cours' +
                '<br>' +
                '<em class="date">' +
                new Date(data.value.lastDate).toLocaleDateString() +
                '</em>'
              );
            } else if (new Date() < new Date(data.value.lastDate)) {
              return (
                'Planifié' +
                '<br>' +
                '<em class="date">' +
                new Date(data.value.lastDate).toLocaleDateString() +
                '</em>'
              );
            }
          } else {
            if (
              new Date() > new Date(data.value.firstDate) &&
              new Date() < new Date(data.value.lastDate)
            ) {
              return (
                data.value.statut +
                '<br>' +
                '<em class="date">' +
                new Date(data.value.firstDate).toLocaleDateString() +
                ' au ' +
                new Date(data.value.lastDate).toLocaleDateString() +
                '</em>'
              );
            } else if (
              new Date() > new Date(data.value.firstDate) &&
              new Date() > new Date(data.value.lastDate)
            ) {
              return (
                data.value.statut +
                '<br>' +
                '<em class="date">' +
                new Date(data.value.firstDate).toLocaleDateString() +
                ' au ' +
                new Date(data.value.lastDate).toLocaleDateString() +
                '</em>'
              );
            } else {
              return (
                'Planifié' +
                '<br>' +
                '<em class="date">' +
                new Date(data.value.firstDate).toLocaleDateString() +
                ' au ' +
                new Date(data.value.lastDate).toLocaleDateString() +
                '</em>'
              );
            }
          }
        },
      },
      {
        headerName: 'Facture',
        field: 'billAdd',
        cellClass: 'cell-align',
        minWidth: 150,
        cellStyle: cellStyle,
        cellRenderer: (data) => {
          if (data.value === 'ok') {
            return 'Faite';
          } else {
            return data.value;
          }
        },
      },
      {
        headerName: 'Montant HT',
        field: 'price_ht',
        filter: 'agNumberColumnFilter',
        cellClass: 'cell-align',
        cellRenderer: (data) => {
          return data.value ? data.value + ' € ' : '';
        },
        minWidth: 140,
      },
      {
        headerName: 'Montant TTC',
        field: 'price_ttc',
        filter: 'agNumberColumnFilter',
        cellClass: 'cell-align',
        minWidth: 140,
        cellRenderer: (data) => {
          return data.value ? data.value + ' € ' : '';
        },
      },
      {
        headerName: 'Acompte client',
        field: 'acompte_client',
        cellClass: 'cell-align',
        cellRenderer: (data) => {
          if (data.value.showAcompte1) {
            return data.value.montant
              ? data.value.montant +
                  ' € ' +
                  '<br>' +
                  '<em class="date">' +
                  new Date(data.value.date).toLocaleDateString() +
                  '</em>'
              : '-';
          }
        },
        minWidth: 150,
      },
      {
        headerName: 'Acompte client 2',
        field: 'acompte_client2',
        cellClass: 'cell-align',
        cellRenderer: (data) => {
          if (data.value.showAcompte2) {
            return data.value.montant
              ? data.value.montant +
                  ' € ' +
                  '<br>' +
                  '<em class="date">' +
                  new Date(data.value.date).toLocaleDateString() +
                  '</em>'
              : '-';
          }
        },
        minWidth: 150,
        maxWidth: 150,
      },
      {
        headerName: 'Date demande de Déblocage',
        field: 'deblocage',
        cellClass: 'cell-align',
        minWidth: 180,
        cellRenderer: (data) => {
          return data.value ? new Date(data.value).toLocaleDateString() : '-';
        },
      },
      {
        headerName: 'Acompte FI',
        field: 'acompte_fi',
        cellClass: 'cell-align',
        minWidth: 130,
        cellRenderer: (data) => {
          if (data.value === undefined || data.value === null) {
            return '-';
          } else {
            return data.value.montant != undefined && data.value.montant != null
              ? data.value.montant +
                  ' € ' +
                  '<br>' +
                  '<em class="date">' +
                  new Date(data.value.date).toLocaleDateString() +
                  '</em>'
              : '-';
          }
        },
      },
      {
        headerName: 'Date et montant du solde',
        field: 'solde',
        cellClass: 'cell-align',
        minWidth: 180,
        cellRenderer: (data) => {
          if (data.value.show) {
            if (data.value.montant === 0) {
              return data.value.montant + ' € ' + '<br>' + '<em class="date">';
            } else {
              return (
                data.value.montant +
                ' € ' +
                '<br>' +
                '<em class="date">' +
                new Date(data.value.date).toLocaleDateString() +
                '</em>'
              );
            }
          } else {
            return '-';
          }
        },
      },
      {
        headerName: 'Reste dû',
        field: 'reste',
        cellClass: 'cell-align',
        minWidth: 100,
        maxWidth: 100,
        cellRenderer: (data) => {
          return data.value.show ? data.value.montant + ' € ' : '-';
        },
      },
      {
        headerName: 'Avoir',
        field: 'avoir',
        cellClass: 'cell-align',
        minWidth: 140,
        cellRenderer: (data) => {
          return data.value ? data.value + ' € ' : '-';
        },
      },
    ];

    this.defaultColDef = {
      flex: 1,
      minWidth: 0,
      sortable: true,
      floatingFilter: true,
      resizable: true,
    };

    function cellStyle(params) {
      var color = numberToColor(params.value);
      return { color: color };
    }
    function numberToColor(val) {
      if (val === 'A faire') {
        return '#F45B69';
      } else {
        return '#78C091';
      }
    }
    this.actualMonth = new Date().getMonth() + 1;
    this.actualYear = new Date().getFullYear();
    this.AuthenticationService.user.subscribe((x) => (this.user = x));
  }

  ngOnInit(): void {
    this.getActuelMonth();
  }

  showComptaCases() {
    this.sCases.getComptaCases().subscribe((res) => {
      this.cases = res['newData'].filter(
        (data) =>
          new Date(data.chantier.firstDate).getFullYear() === this.selectedYear
      );
      this.totalCA = res['totalCA']
      //this.totalFacture = res['totalFacture']
      this.cases.sort((a, b) =>
        a.chantier.firstDate < b.chantier.firstDate
          ? -1
          : a.chantier.firstDate > b.chantier.firstDate
          ? 1
          : 0
      );
      this.rowData = this.cases;
      this.allCaNetTt = res['caNetTt'];
      this.caNetTt = this.allCaNetTt;

      /*     this.sCases.getAllCm(new Date().getFullYear()).subscribe(res => {

      this.totalCmByMonth = res['totalMonth']
      this.totalCaCM = res['annuelCa']
      this.cms = res['newData']

      this.getCaseMensuelle()

    }) */
          this.goHome();

    });
  }

  onSelectionChanged() {
    var selectedRows = this.gridApi.getSelectedRows();
    this.router.navigate(['/compta-case-detail/', selectedRows[0].id]);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  getActuelMonth() {
    let i = 0;
    while (i < this.months.length) {
      if (this.actualMonth == this.months[i].number) {
        this.actualNameMonth = this.months[i].name;
      }
      i++;
    }
  }

  filterDataArray(event, type) {
    this.cmByNumberMonth = 0;

    this.rowData = this.cases;
    if (type === 'month') {
      this.months.forEach((month) => {
        // si le mois n'est pas dans le tableau months
        if (event.indexOf(month.name) != -1) {
          if (!month.checked) {
            this.selectedMonth.push(month.number);
            month.checked = true;
          }
        } else if (month.checked) {
          month.checked = !month.checked;
          const indexDeselected = this.selectedMonth.indexOf(month.number);
          this.selectedMonth.splice(indexDeselected, 1);
        }
      });
      if (this.selectedMonth.length != 0) {
        this.rowData = this.rowData.filter(
          (data) =>
            this.selectedMonth.indexOf(data.chantier.firstDate.substr(5, 2)) !=
              -1 &&
            new Date(data.chantier.firstDate).getFullYear() ===
              this.selectedYear
        );
      }
    } else {
      this.selectedYear = event;
      this.showComptaCases();
      this.rowData = this.rowData.filter(
        (data) =>
          this.selectedMonth.indexOf(data.chantier.firstDate.substr(5, 2)) !=
            -1 &&
          new Date(data.chantier.firstDate).getFullYear() === this.selectedYear
      );
    }

    this.recalculCa();
    this.calculateBill();
    this.calculateCMByMonth();
  }

  recalculCa() {
    this.caNetTt = 0;
    this.sousTt = 0;
    this.totalAvoir = 0;

    this.rowData.forEach((element) => {
      if (element.chantier.statut === 'Terminé') {
        this.sousTt += element.price_ttc;
      }
      this.caNetTt += element.price_ttc;

      if (element.avoir) {
        this.totalAvoir += element.avoir;
      }
    });

    this.totalCA = this.sousTt;
  }

  calculateBill() {
    this.billMissing = 0;
    this.caMissing = 0;
    this.billTotal = 0;
    this.rowData.forEach((element) => {
      if (element.billAdd != 'A faire') {
        console.log(element.price_ttc)
        this.billTotal += element.price_ttc;
      } else {
                console.log(element.price_ttc);
        this.billMissing += element.price_ttc;
      }

      if (element.reste.show) {
        this.caMissing += element.reste.montant;
      }
    });
  }

  calculateCMByMonth() {
    this.cmByNumberMonth = 0;
    this.cms.forEach((cm) => {
      if (cm.paiement.type_paiement == 'Comptant') {
        this.selectedMonth.forEach((element) => {
          if (cm.facture_month == element) {
            this.cmByNumberMonth += cm.tarif;
          }
        });
      } else {
        cm.datesign = new Date(cm.datesign);
        this.selectedMonth.forEach((element) => {
          if (cm.datesign.getFullYear() == this.actualYear) {
            if (cm.facture_month <= element) {
              this.cmByNumberMonth += cm.paiement.montant;
            }
          } else {
            if (cm.ended) {
              if (element < cm.facture_month) {
                this.cmByNumberMonth += cm.paiement.montant;
              }
            } else {
              this.cmByNumberMonth += cm.paiement.montant;
            }
          }
        });
      }
    });
  }

  calculCMAllMonth() {
    this.cmByNumberMonth = 0;
    let cmsBeforeActualMonth = this.cms.filter(
      (x) =>
        x.facture_month <= this.actualMonth ||
        new Date(x.datesign).getFullYear() < this.actualYear - 1
    );
    cmsBeforeActualMonth.sort((a, b) => (a.datesign > b.datesign ? -1 : 1));
    cmsBeforeActualMonth.forEach((cm) => {
      if (cm.paiement.type_paiement == 'Comptant') {
        if (cm.facture_month <= this.actualMonth) {
          this.cmByNumberMonth += cm.tarif;
        }
      } else {
        if (new Date(cm.datesign).getFullYear() == this.actualYear - 1) {
          this.cmByNumberMonth +=
            cm.paiement.montant * (this.actualMonth - cm.facture_month + 1);
        } else {
          if (cm.ended) {
            if (cm.facture_month <= this.actualMonth) {
              this.cmByNumberMonth += cm.paiement.montant * cm.facture_month;
            } else {
              this.cmByNumberMonth += cm.paiement.montant * this.actualMonth;
            }
          }
          this.cmByNumberMonth += cm.paiement.montant * this.actualMonth;
        }
      }
    });
  }

  calculCMActualMonth() {
    this.cmByNumberMonth = 0;
    let cmsActualMonth = this.cms.filter(
      (x) =>
        x.facture_month <= this.actualMonth ||
        new Date(x.datesign).getFullYear() < this.actualYear - 1
    );
    cmsActualMonth.forEach((cm) => {
      if (cm.paiement.type_paiement == 'Comptant') {
        if (cm.facture_month == this.actualMonth) {
          this.cmByNumberMonth += cm.tarif;
        }
      } else {
        if (cm.ended) {
          if (cm.year[cm.year.length - 1][0].year > this.actualYear - 1) {
            this.cmByNumberMonth += cm.paiement.montant;
          } else {
            if (cm.facture_month > this.actualMonth) {
              this.cmByNumberMonth += cm.paiement.montant;
            }
          }
        } else {
          this.cmByNumberMonth += cm.paiement.montant;
        }
      }
    });
  }

  toggleShowEncours() {
    this.showEncours = !this.showEncours;
    this.showCm = false;
    if (!this.showEncours) {
      this.selectedMonth = [];
      this.calculCMAllMonth();
      this.rowData = this.cases;
      this.caNetTt = this.allCaNetTt;
      this.calculateBill();
    } else {
      this.getCaseMensuelle();
    }
    this.recalculCa();
  }

  goHome() {
    if (this.user.role == 'Compta') {
      this.showEncours = false;

      this.selectedMonth = [];
      this.rowData = this.cases;
      this.caNetTt = this.allCaNetTt;
      this.calculateBill();
      this.recalculCa();
    } else if (this.user.role == 'Adv') {
      this.router.navigate(['adv-dashboard']).then(() => {
        window.location.reload();
      });
    } else if (this.user.role == 'Admin') {
      this.router.navigate(['admin-dashboard']).then(() => {
        window.location.reload();
      });
    }
  }

  getCaseMensuelle() {
    this.rowData = this.cases;
    this.rowData = this.rowData.filter(
      (data) => data.chantier.firstDate.substr(5, 2) == this.actualMonth
    );
    this.recalculCa();
    this.calculateBill();
    this.calculCMActualMonth();
  }
  toggleCm() {
    this.showCm = !this.showCm;
    this.showEncours = !this.showEncours;
  }

  onFilterChanged() {
    let filteredResult = {
      count: 0,
      price_ht: 0,
      price_ttc: 0,
    };
    this.gridApi.forEachNodeAfterFilter((element) => {
      filteredResult.price_ht += element.data.price_ht;
      filteredResult.price_ttc += element.data.price_ttc;
      filteredResult.count++;
    });
    this.filteredResult = filteredResult;
    this.filteredResult.count === this.rowData.length
      ? (this.showFilteredResult = false)
      : (this.showFilteredResult = true);
  }

  toggleGdd() {
    this.showGdd = !this.showGdd;
  }
}
