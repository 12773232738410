import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { PiecesService } from '@app/services/pieces.service';
import { SignModalComponent } from '@app/sign-modal/sign-modal.component';

@Component({
  selector: 'app-modal-previsualize',
  templateUrl: './modal-previsualize.component.html',
  styleUrls: ['./modal-previsualize.component.scss'],
})
export class ModalPrevisualizeComponent implements OnInit {
  pdfSrc = '';
  pdfSrctest =
    'https://api-crm.s3.eu-west-3.amazonaws.com/619fb0db0637bf000482e8d6/pieces/recette_vmc.pdf';
  img;
  titlePieceName;
  voir = false;
  skipSignature: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sPieces: PiecesService,
    private router: Router,
    private sb: MatSnackBar,
    private dialogRef: MatDialog
  ) {
    this.data = data;
    this.getLastPiece();
    this.skipSignature =
      JSON.parse(localStorage.getItem('skipSignature')) || false;
  }

  ngOnInit(): void {}

  onClick() {
    this.voir = !this.voir;
  }
  getLastPiece() {
    if (this.data.titlePiece === 'recette_vmc') {
      this.titlePieceName = 'Recette VMC';
    } else if (
      this.data.titlePiece === 'recette_pv_micro_onduleur_facade' ||
      this.data.titlePiece === 'recette_pv_micro_onduleur_sol' ||
      this.data.titlePiece === 'recette_pv_micro_onduleur_surimposition' ||
      this.data.titlePiece === 'recette_pv_micro_onduleur_integration' ||
      this.data.titlePiece === 'recette_pv_onduleur_facade' ||
      this.data.titlePiece === 'recette_pv_onduleur_sol' ||
      this.data.titlePiece === 'recette_pv_onduleur_surimposition' ||
      this.data.titlePiece === 'recette_pv_onduleur_integration'
    ) {
      this.titlePieceName = 'Recette Panneaux Solaires';
    } else if (this.data.titlePiece === 'recette_pacae') {
      this.titlePieceName = 'Recette PAC Air Eau';
    } else if (
      this.data.titlePiece === 'recette_pacaa_gainable' ||
      this.data.titlePiece === 'recette_pacaa_split'
    ) {
      this.titlePieceName = 'Recette PAC Air Air';
    } else if (
      this.data.titlePiece === 'recette_bt_monobloc' ||
      this.data.titlePiece === 'recette_bt_split'
    ) {
      this.titlePieceName = 'Recette BT';
    } else if (this.data.titlePiece === 'recette_adoucisseur') {
      this.titlePieceName = 'Recette Adoucisseur';
    } else if (this.data.titlePiece === 'recette_tableau_electrique') {
      this.titlePieceName = 'Recette Tableau electrique';
    } else if (
      this.data.titlePiece === 'bi' ||
      this.data.titlePiece === 'bi_cm'
    ) {
      this.titlePieceName = "Bon d'intervention";
    } else {
      this.titlePieceName = this.data.titlePiece;
    }
    this.sPieces
      .getLastSpecificPieceByTitle(this.data.caseId, this.titlePieceName)
      .subscribe((res) => {
        this.img = res[0];
        this.pdfSrc =
          'https://api-crm.s3.eu-west-3.amazonaws.com/' +
          this.data.caseId +
          '/pieces/' +
          this.img.image;

        setTimeout(() => {
          document.getElementById('pdf').click();
        }, 1000);
      });
  }

  openDialog(): void {
    if (!this.skipSignature) {
      this.sPieces.delete(this.img._id).subscribe((response) => {
        console.log('Suppression effectuée');
      });
      const dialogRef = this.dialogRef.open(SignModalComponent, {
        disableClose: true,
        panelClass: 'sign-modal',
        data: {
          titlePiece: this.data.titlePiece,
          caseId: this.data.caseId,
          kit: this.data.kit,
          case: this.data.case,
          chantierId: this.data.chantierId,
          form: this.data.form,
          formulaireByType: this.data.formulaireByType,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {});
    } else {
      this.closeModale();
      this.sb.open(`Document généré avec succès`, '', {
        duration: 2000,
        verticalPosition: 'bottom',
        panelClass: ['add-snackbar'],
      });
      this.router.navigate(['technicien-case-detail', this.data.chantierId]);
    }
  }
  closeModale() {
    this.dialogRef.closeAll();
  }
}
