import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { CasesService } from '@app/services/cases.service';
import { HistoriqueService } from '@app/services/historique.service';
import { RolesService } from '@app/services/roles.service';

@Component({
  selector: 'app-case-products',
  templateUrl: './case-products.component.html',
  styleUrls: ['./case-products.component.scss']
})
export class CaseProductsComponent implements OnInit {
  case
  tabs;
  currentKit = {
    nombre: '',
    puissance: '',
    orientation: '',
    capacite: '',
    unite: '',
    pose: '',
    marque: '',
    monotri: '',
    monitoring: '',
    options_bt: [],
    options_pac: [],
    type_pac: '',
    type_iso: '',
    options: [],
    name: '',
    surface: '',
    backup: ''
  }

  selectedUnite;
  selectedPose;
  selectedMonotri;
  selectedTypePac;
  selectedTypeIso;
  selectedBackup;
  options_bt = []
  options_pac = []

  isDalle;
  isMonitoring
  isGainage;
  isThermo;
  isDepose;

  empForm: FormGroup;
  add = false
  constructor(
    @Inject(MAT_DIALOG_DATA) public currentCase: any,
    private dialogRef: MatDialog,
    private sCases: CasesService,
    private sHistoriques: HistoriqueService,
    private sRoles: RolesService,
    private fb: FormBuilder,
    private sb: MatSnackBar,
  ) {
    this.case = currentCase.currentCase
    this.tabs = JSON.parse(this.case.kits_vendus).filter(k => k.active)

    const kit = JSON.parse(this.case.kits_vendus).filter(r => r.active)
    const index = kit.findIndex(item => item.name === kit[0].name);
    const selected = kit[index]
    this.currentKit = selected

    if (this.currentKit.options_bt && this.currentKit.options_bt.includes('Dalle béton'))
      this.isDalle = true;
    if (this.currentKit.options_bt && this.currentKit.options_bt.includes('Gainage du ballon'))
      this.isGainage = true;
    if (this.currentKit.options_bt && this.currentKit.options_bt.includes('Dépose chaudière'))
      this.isDepose = true;

    if (this.currentKit.options_pac && this.currentKit.options_pac.includes('Thermostat radio'))
      this.isThermo = true;
    if (this.currentKit.options_pac && this.currentKit.options_pac.includes('Dalle béton'))
      this.isDalle = true;
    if (this.currentKit.options_pac && this.currentKit.options_pac.includes('Dépose chaudière'))
      this.isDepose = true;

    if (this.currentKit.monitoring)
      this.isMonitoring = true;

    this.selectedUnite = this.currentKit.unite
    this.selectedBackup = this.currentKit.backup

    this.selectedPose = this.currentKit.pose
    this.selectedMonotri = this.currentKit.monotri
    this.selectedTypePac = this.currentKit.type_pac
    this.selectedTypeIso = this.currentKit.type_iso,

      this.empForm = this.fb.group({
        products: this.fb.array([]),
      })
  }

  ngOnInit(): void { }

  addOption(name, reason, option, product, value) {

    let find = JSON.parse(this.case.kits_vendus).find(x => x.name === name);
    find[option] = value;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === name);
    const selected = array[index]
    this.currentKit = selected
    selected[option] = value

    this.case.kits_vendus = JSON.stringify(array)

    this.sCases.editCase(this.case, this.case._id)
    this.sHistoriques.createHistorique(this.case._id, this.case.customer._id, reason, (option + ' ' + product), value, this.sRoles.user.id).then(res => {
      if (res['error']) {
        alert(res);
      }
    });

  }
  changeIsMonitoring() {
    if (this.isMonitoring)
      this.isMonitoring = false
    else
      this.isMonitoring = true;

  }

  addOptionRadio(name, reason, option, product, selection) {

    let find = JSON.parse(this.case.kits_vendus).find(x => x.name === name);
    find[option] = selection;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === name);
    const selected = array[index]
    this.currentKit = selected
    selected[option] = selection

    this.case.kits_vendus = JSON.stringify(array)
    this.sCases.editCase(this.case, this.case._id)
    this.sHistoriques.createHistorique(this.case._id, this.case.customer._id, reason, (option + ' ' + product), selection, this.sRoles.user.id).then(res => {
      if (res['error']) {
        alert(res);
      }
    });
  }

  addOptionBt(name, event, option) {

    let find = JSON.parse(this.case.kits_vendus).find(x => x.name === name);
    find[option] = this.options_bt;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === name);
    const selected = array[index]
    this.currentKit = selected
    selected[option] = this.options_bt

    if (this.currentKit.options_bt && !this.currentKit.options_bt.includes(event.source.value)) {
      this.options_bt.push(event.source.value)
    }
    else {
      var ind = this.options_bt.indexOf(event.source.value);
      if (index !== -1) {
        this.options_bt.splice(ind, 1);
      }
    }

    this.currentKit.options_bt = this.options_bt
    this.case.kits_vendus = JSON.stringify(array)

    this.sCases.editCase(this.case, this.case._id).then(res => {
      if (res['error']) {
        return res.error;
      }
    });
  }

  addOptionPac(name, event, option) {

    let find = JSON.parse(this.case.kits_vendus).find(x => x.name === name);
    find[option] = this.options_pac;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === name);
    const selected = array[index]
    this.currentKit = selected
    selected[option] = this.options_pac

    if (!this.currentKit.options_pac.includes(event.source.value)) {
      this.options_pac.push(event.source.value)
    }
    else {
      var ind = this.options_pac.indexOf(event.source.value);
      if (index !== -1) {
        this.options_pac.splice(ind, 1);
      }
    }

    this.currentKit.options_pac = this.options_pac
    this.case.kits_vendus = JSON.stringify(array)

    this.sCases.editCase(this.case, this.case._id)
  }

  changeCurrentKit(changeEvent: MatTabChangeEvent) {
    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === changeEvent.tab.textLabel);
    const selected = array[index]
    this.currentKit = selected
    this.selectedUnite = this.currentKit.unite
    this.selectedBackup = this.currentKit.backup
    this.selectedPose = this.currentKit.pose
    this.selectedTypePac = this.currentKit.type_pac
    this.selectedMonotri = this.currentKit.monotri
    this.selectedTypeIso = this.currentKit.type_iso
    this.options_bt = this.currentKit.options_bt
    if (this.currentKit.options_bt && this.currentKit.options_bt.includes('Dalle béton'))
      this.isDalle = true;
    if (this.currentKit.options_bt && this.currentKit.options_bt.includes('Gainage du ballon'))
      this.isGainage = true;
    if (this.currentKit.options_bt && this.currentKit.options_bt.includes('Dépose chaudière'))
      this.isDepose = true;
    if (this.currentKit.options_pac && this.currentKit.options_pac.includes('Thermostat radio'))
      this.isThermo = true;
    if (this.currentKit.options_pac && this.currentKit.options_pac.includes('Dépose chaudière'))
      this.isDepose = true;
    if (this.currentKit.options_pac && this.currentKit.options_pac.includes('Dalle béton'))
      this.isDalle = true;
    if (this.currentKit.monitoring)
      this.isMonitoring = true;
  }

  closeModale() { this.dialogRef.closeAll(); }


  products(): FormArray {
    return this.empForm.get("products") as FormArray
  }

  newProduct(): FormGroup {
    return this.fb.group({
      name: '',
      state: 'autre',
      active: true,
      options: this.fb.array([])
    })
  }

  addProduct() {
    this.add = true
    this.products().push(this.newProduct());
  }

  productOptions(empIndex: number): FormArray {
    return this.products().at(empIndex).get("options") as FormArray
  }

  newOption(): FormGroup {
    return this.fb.group({
      option: '',
      value: '',
    })
  }

  addProductOption(empIndex: number) {
    this.productOptions(empIndex).push(this.newOption());
  }

  removeProductOption(empIndex: number, optionIndex: number) {
    this.productOptions(empIndex).removeAt(optionIndex);
  }

  rmProduct() {
    const kit = JSON.parse(this.case.kits_vendus)

    kit.splice(kit.findIndex(({ name }) => name === this.currentKit.name), 1);

    this.case.kits_vendus = JSON.stringify(kit)
    this.sCases.editCase(this.case, this.case._id).then(res => {
      this.closeModale()
      this.sb.open(`Produit supprimé`, '', { duration: 2000, verticalPosition: 'bottom', panelClass: ['delete-snackbar'] });

    });
  }

  onSubmit() {
    const kit = JSON.parse(this.case.kits_vendus)
    kit.push(this.empForm.value.products[0])
    this.case.kits_vendus = JSON.stringify(kit)
    this.sCases.editCase(this.case, this.case._id).then(res => {
      if (res['error']) {
        return res.error;
      }
      else {
        this.closeModale()
        this.sb.open(`Produit ajouté`, '', { duration: 2000, verticalPosition: 'bottom', panelClass: ['add-snackbar'] });
      }
    });
  }

}
