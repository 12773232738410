import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ModalPrevisualizeComponent } from '@app/pv/modal-previsualize/modal-previsualize.component';
import { NoModalComponent } from '@app/pv/no-modal/no-modal.component';
import { CasesService } from '@app/services/cases.service';
import { PdfsService } from '@app/services/pdfs.service';
import { PiecesService } from '@app/services/pieces.service';

@Component({
  selector: 'app-recette-adoucisseur',
  templateUrl: './recette-adoucisseur.component.html',
  styleUrls: ['./recette-adoucisseur.component.scss']
})
export class RecetteAdoucisseurComponent {
  @Input() currentKit;
  @Input() case;
  @Input() chantierId
  @Input()
  public data: any

  @Output()
  public onData: EventEmitter<any> = new EventEmitter<any>();

  firstFormGroup: FormGroup;
  pieces
  piecesNserie
  n_serie = false
  multipleImagesPieces

  disjoncteur = false
  etiquette = false
  filtre = false

  isValid
  loader
  createDoc
  mobile = false

  constructor(
    private _formBuilder: FormBuilder,
    private sCases: CasesService,
    private dialog: MatDialog,
    private sPdfs: PdfsService,
    private sPieces: PiecesService) {
    if (window.screen.width <= 600) {
      this.mobile = true;
    }
  }


  getCurrentKitAndSaveChange(event, key) {

    this.currentKit[key] = event;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === this.currentKit.type);

    array[index] = this.currentKit

    this.case.kits_vendus = JSON.stringify(array)

    this.sCases.editCase(this.case, this.case._id).then(res => console.log(res))
  }
  ngAfterContentInit() {
    this.updateData(50)
    this.checkPieceNSerie()
    this.checkPiece()
    this.firstFormGroup = this._formBuilder.group({
      marque_type: [this.currentKit.marque_type, Validators.required],
      pression_bar: [this.currentKit.pression_bar, Validators.required],
      disconnecteur_amont: [this.currentKit.disconnecteur_amont, Validators.required],
      reducteur_tare: [this.currentKit.reducteur_tare, Validators.required],
      isolement_amont: [this.currentKit.isolement_amont, Validators.required],
      clapet: [this.currentKit.clapet, Validators.required],
      siphon_aval: [this.currentKit.siphon_aval, Validators.required],
      isolement_aval: [this.currentKit.isolement_aval, Validators.required],
      documentation: [this.currentKit.documentation, Validators.required],
      explication: [this.currentKit.explication, Validators.required],
      nettoyage: [this.currentKit.nettoyage, Validators.required],
      filtre: [this.currentKit.filtre, Validators.required],
    });
  }
  updateData(data) {
    this.onData.emit(data)
  }
  openDialog(value): void {
    const dialogRef = this.dialog.open(NoModalComponent, {
      width: '640px',
      disableClose: true,
      data: {
        currentKit: this.currentKit,
        case: this.case,
        value: value
      }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  createRecette() {
    /* s */
  }

  selectNSerie(event, title) {
    if (event.target.files.length > 0) {
      this.multipleImagesPieces = event.target.files;
    }
    this.onSubmitNSerie(title)
  }

  selectMultiple(event, title) {
    if (event.target.files.length > 0) {
      this.multipleImagesPieces = event.target.files;
    }
    this.onSubmit(title)
  }

  onSubmitNSerie(title) {
    const form = {
      title: title,
      qualification: 'Conforme',
      caseId: this.case._id,
      created_at: new Date(),
      type: 'technicien',
      chantier: this.chantierId._id,
      cible: 'n_serie_adoucisseur',
      description: '',
    }
    this.sPieces.addTechPiece(form, this.multipleImagesPieces, this.case._id, this.chantierId._id)
    setTimeout(() => {
      this.checkPieceNSerie()
    }, 1100);
  }

  onSubmit(title) {
    const form = {
      title: title,
      qualification: 'Conforme',
      caseId: this.case._id,
      created_at: new Date(),
      type: 'technicien',
      chantier: this.chantierId._id,
      cible: 'recette_adoucisseur',
      description: '',
    }
    this.sPieces.addTechPiece(form, this.multipleImagesPieces, this.case._id, this.chantierId._id)
    setTimeout(() => {
      this.checkPiece()
    }, 1100);
  }
  checkPieceNSerie() {
    this.sPieces.getPiecesByChantierIdAndCible(this.chantierId._id, 'n_serie_adoucisseur').subscribe(res => {
      this.piecesNserie = res
      if (this.piecesNserie.find(e => e.title === 'N° de serie adoucisseur') && this.currentKit.type === 'Adoucisseur d\'eau')
        this.n_serie = true
    })

  }

  checkPiece() {
    this.sPieces.getPiecesByChantierIdAndCible(this.chantierId._id, 'recette_adoucisseur').subscribe(res => {
      this.pieces = res
      if (this.pieces.find(e => e.title === 'Etiquette test PH'))
        this.etiquette = true
      if (this.pieces.find(e => e.title === 'Filtre adoucisseur'))
        this.filtre = true
      if (this.pieces.find(e => e.title === 'Disjoncteur'))
        this.disjoncteur = true
    })

  }

  async valid() {
    this.isValid = true
    this.loader = true
    this.createDoc = await this.generateDoc();
    this.loader = false
    this.openDialogPrevisualize()
  }


  async generateDoc() {
    let response = await this.sPdfs.generateRecette(this.chantierId, 'recette_adoucisseur', this.case, this.currentKit, "", "").then(res => {
      if (res === 'ok') {
      }
    })
    return response;
  }

  openDialogPrevisualize(): void {
    setTimeout(() => {
      const dialogRef = this.dialog.open(ModalPrevisualizeComponent, {
        width: '100vh',
        height: '100vh',
        maxWidth: '100vh',
        maxHeight: '100vh',
        hasBackdrop: false,
        disableClose: true,
        panelClass: 'pdf-visualize',
        data: {
          titlePiece: 'recette_adoucisseur',
          caseId: this.case._id,
          case: this.case,
          kit: this.currentKit,
          chantierId: this.chantierId
        }
      });
      dialogRef.afterClosed().subscribe(result => {

      });
    }, 2000);

  }


}
