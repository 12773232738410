import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PicturesService } from '@app/services/pictures.service';
import { TasksGddService } from '@app/services/tasks-gdd.service';
import { UsersService } from '@app/services/users.service';
import { AuthenticationService } from '@app/_services';
import * as moment from 'moment';
import { CreateTaskGddComponent } from '../create-task-gdd/create-task-gdd.component';

@Component({
  selector: 'app-card-gdd',
  templateUrl: './card-gdd.component.html',
  styleUrls: ['./card-gdd.component.scss']
})
export class CardGddComponent implements OnInit {

  @Input() task;

  usersPictures = []
  imageSrc = 'assets/empty_pp.svg'
  loading = false
  differenceHours

  progressValue;
  selectedValue

  currentUser
  @Output() taskEdit = new EventEmitter<any>();

  @Output() childButtonEvent = new EventEmitter();

  constructor(
    private sUsers: UsersService,
    private sPictures: PicturesService,
    private dialog: MatDialog,
    private sTasksGdd: TasksGddService,
    private authenticationService: AuthenticationService,

  ) {
    this.authenticationService.user.subscribe(x => this.currentUser = x);

    this.showAdvs()
   }

  ngOnInit(): void {
    this.getHoursTasks(this.task.created_at,this.task.end_date)
    this.selectedValue =  this.task.commentary.filter(c => c.selected)[0]

  }

  clickHandler() {
    this.childButtonEvent.emit(this.task);
  }
  getProfil(created_by) {
    let profil:any = this.usersPictures.filter(p => p.id === created_by)
    if(profil) {
      profil = profil[0]
      if(profil && profil.picture[0]) {
        profil = 'https://api-crm.s3.eu-west-3.amazonaws.com/'+ created_by +'/picture/' +  profil.picture[0].image
      }
      else {
        profil = this.imageSrc
      }
    }
    else {
      profil = this.imageSrc
    }
    return  profil
  }

  showAdvs(){
    this.sUsers.getAllAdv().subscribe(res => {
      const advs = res['users'].filter(res => res.isActive)

      advs.forEach(element => {
        this.sPictures.getPicturesByUserId(element._id).subscribe(res => {
          this.usersPictures.push({
            id : element._id,
            picture: res
          })
        })
      });
      setTimeout(() => {
        this.loading = true
      }, 1000);
    })
  }

  getHoursTasks(created_at,end_date) {
    let duration = moment.duration(moment(created_at).diff(moment(end_date)));
    let hours = duration.asHours();

    this.differenceHours = Math.abs(hours)

    let actualDuration = moment.duration(moment(created_at).diff(new Date()));
    let actualHours = actualDuration.asHours();

    let actualTaskHour = Math.abs(actualHours)

    this.progressValue = (100 * actualTaskHour) / this.differenceHours + '%';
  }

  editTaskGddDialog(task): void {
    if(task.state != 'Terminé') {
      const dialogRef = this.dialog.open(CreateTaskGddComponent, {
        width: '30vw',
        minHeight: 'calc(60vh - 90px)',
        height : 'auto',
        disableClose:true,

        data :{
               edit: true,
               task : task,
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result === undefined)
          this.clickHandler()
      });
    }

  }

  changeState(task, com) {

    const index = task.commentary.find(item => item.title === com.title);
    const currentTask = index

    task.commentary.forEach(element => {
      element.selected = false
    });
    currentTask.selected = !com.selected

    this.task.state = 'Terminé'
    this.editTask()

  }
  editTask() {
    this.sTasksGdd.editTask(this.task, this.task._id).then(res => {
      console.log(res)
    });

  }
}
