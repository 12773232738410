<h6>Signature</h6>
<div class="row mt-3">
  <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
    <label>Signature Technicien</label>
    <input class="custom-input mb-4" type="text" [(ngModel)]="newChef" [value]="this.data.chantierId.chef" (change)="saveChangeChef(this.data.chantierId.chef,this.data.chantierId._id,'edité','le chef d\'équipe')">
  </div>
</div>


<div id="capture" #surface class="signature col-sm-7">
  <signature-pad></signature-pad>
</div>
<button (click)="signature()" *ngIf="!isValid" class="btn-secondarycrm">Signer</button>

<app-loading *ngIf="loading"></app-loading>
