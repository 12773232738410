<app-loading *ngIf="!products"></app-loading>
<div class="sav-dashboard">
  <div>
  <button class="btn-secondarycrm ml-3 mb-4" (click)="openAddProductDialog()">Ajouter un produit</button>
  </div>
  <div class="products-display">
    <div *ngFor="let product of products" class="product-item">
      {{ product.title }}

    </div>
  </div>
</div>
