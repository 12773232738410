<div class="book-container">
  <img class="logo_couleur" src="./../../../assets/book/logo_normal.jpg" alt="logo" />
  <img class="img-center slideDown" src="./../../../assets/book/part4bis/p4bis_center.png" />
  <img class="img-left slideDown" src="./../../../assets/book/part4bis/p4bis_left.png" />
  <img class="img-dpe" src="./../../../assets/book/part4/dpe.gif" />

  <div class="right-half">
    <p>Quelle est votre source de chauffage et d’eau chaude sanitaire principale ?</p>

    <div class="row-half">
      <span class="span-item" (click)="selectSpan(1)"
        [ngClass]="{ 'span-selected': reponse.includes(1)}">Électricité</span>
      <span class="span-item" (click)="selectSpan(2)" [ngClass]="{ 'span-selected': reponse.includes(2) }">Gaz</span>
    </div>
    <div class="row-half">
      <span class="span-item" (click)="selectSpan(3)" [ngClass]="{ 'span-selected': reponse.includes(3) }">Fioul</span>
      <span class="span-item" (click)="selectSpan(4)" [ngClass]="{ 'span-selected': reponse.includes(4) }">Bois</span>
    </div>


    <p>Saisissez le montant de :</p>
    <div class="input_container_scroll">
      <div class="_form_input" id="form1" *ngIf="reponse.includes(1)">
        <input type="number" placeholder="Votre facture d'électricité" id="elec" name="elec" [(ngModel)]="elecData"
          required>
      </div>
      <div class="_form_input" id="form2" *ngIf="reponse.includes(2)">
        <input type="number" id="gaz" placeholder="Votre facture gaz" name="gaz" [(ngModel)]="gazData" required>
      </div>
      <div class="_form_input" id="form4" *ngIf="reponse.includes(3)">
        <input type="number" id="fioul" placeholder="Votre facture fioul" name="fioul" [(ngModel)]="fioulData" required>
      </div>
      <div class="_form_input" id="form3" *ngIf="reponse.includes(4)">
        <input type="number" id="bois" placeholder="Votre facture bois" name="bois" [(ngModel)]="boisData" required>
      </div>

      <div class="buttons">
        <button class="primary-button-precedent" [routerLink]="['/book/part4', caseId]">
          <svg style="transform:rotate(-180deg);" width="22" height="20" viewBox="0 0 14 12" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.0303 6.53057C13.3232 6.23768 13.3232 5.76281 13.0303 5.46991L8.25736 0.696943C7.96447 0.40405 7.48959 0.40405 7.1967 0.696943C6.90381 0.989837 6.90381 1.46471 7.1967 1.7576L11.4393 6.00024L7.1967 10.2429C6.90381 10.5358 6.90381 11.0107 7.1967 11.3035C7.48959 11.5964 7.96447 11.5964 8.25736 11.3035L13.0303 6.53057ZM0.5 6.75024H12.5V5.25024H0.5V6.75024Z"
              fill="white" />
          </svg>
          Précédent
        </button>
        <button class="primary-button" (click)="!isButtonDisabled() && suivant()"
          [ngClass]="{ 'disabled': isButtonDisabled() }">
          Suivant
          <svg width="22" height="20" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.0303 6.53057C13.3232 6.23768 13.3232 5.76281 13.0303 5.46991L8.25736 0.696943C7.96447 0.40405 7.48959 0.40405 7.1967 0.696943C6.90381 0.989837 6.90381 1.46471 7.1967 1.7576L11.4393 6.00024L7.1967 10.2429C6.90381 10.5358 6.90381 11.0107 7.1967 11.3035C7.48959 11.5964 7.96447 11.5964 8.25736 11.3035L13.0303 6.53057ZM0.5 6.75024H12.5V5.25024H0.5V6.75024Z"
              fill="white" />
          </svg>
        </button>
      </div>
    </div>
  </div>

</div>