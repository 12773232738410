import { TasksService } from './../../services/tasks.service';
import { TasksGddService } from './../../services/tasks-gdd.service';

import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/_services';
import { CreateTaskComponent } from '../create-task/create-task.component';
import { Location } from '@angular/common';
import { InfosService } from '@app/services/infos.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeleteBarComponent } from '@app/snackbars/delete-bar/delete-bar.component';

import { HttpClient } from '@angular/common/http';
import { apiUrl } from '@environments/env';
import { HistoriqueService } from '@app/services/historique.service';
import { CreateTaskGddComponent } from '../create-task-gdd/create-task-gdd.component';
import { UsersService } from '@app/services/users.service';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
})
export class TaskListComponent implements OnInit {
  countUnread;
  tasks;
  tasksWarning = [];

  tasksGdd;
  gddEncours;
  countGddEncours;
  gddTermine;
  countGddTermine;
  gddAfaire;
  countGddAfaire;

  currentUser;
  cases;
  states = [
    { name: '1-Urgente', active: false },
    { name: '2-En-cours', active: false },
    { name: '3-Faite', active: false },
  ];

  tags = [
    { name: 'CEE', active: false },
    { name: 'CM', active: false },
    { name: 'Dossier', active: false },
    { name: 'MPR', active: false },
    { name: 'Primexel', active: false },
    { name: 'Raccordement', active: false },
  ];

  options = [
    { name: 'Mes tâches' },
    { name: 'Tâches affectées' },
    { name: 'Faites' },
  ];

  filterText = 'all';
  filter = {
    state: 'all',
    title: '',
  };

  trashtrue = true;

  suppreTask = [];
  isfrmChecked: any;

  mobile = false;
  todayTask = [];
  otherDayTask = [];
  tommorrowTask = [];

  showUnread = true;
  showRead = true;
  infos;
  infosRead = [];
  infosUnread = [];
  createdByTasks = [];
  checkTasks;
  selectedOption = 'Mes tâches';

  unroll = false;

  public searchFilter: any = '';
  query;
  advs = [];

  constructor(
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private sTasks: TasksService,
    private sTasksGdd: TasksGddService,
    private _location: Location,
    private sInfos: InfosService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private http: HttpClient,
    private sb: MatSnackBar,
    private sHistoriques: HistoriqueService,
    private sUsers: UsersService
  ) {
    this.authenticationService.user.subscribe((x) => (this.currentUser = x));
    if (this.currentUser.id === '5ff22a8ba0f2570004f151d0') {
      this.options.push({ name: 'Suivi GDD' });
    }
    if (this.currentUser.id === '6135c7036457510004c2eef1') {
      this.sUsers.getAllAdv().subscribe((res) => {
        this.advs = res['users'];
        this.advs
          .filter((adv) => adv.isActive)
          .forEach((element) => {
            this.options.push({ name: element.username });
          });
      });
    }
    if (window.screen.width <= 600) {
      this.mobile = true;
    }
  }

  ngOnInit(): void {
    this.showTasksByTargetId(this.currentUser.id);
    this.showTasksCreatedBy();

    if (this.mobile) {
      this.showInfosByAdvId();
    }
    if (this.currentUser.id === '5ff22a8ba0f2570004f151d0') {
      this.showTasksGdd();
    } else {
      this.showTasksGddByTargetId();
    }
    this.getGroups();
  }

  showTasksCreatedBy() {
    return new Promise((resolve) => {
      this.sTasks.getTaskByCreatedById(this.currentUser.id).subscribe(
        (res) => {
          const array: any = res;
          array.forEach((element) => {
            if (element.target) {
              if (element.target._id != this.currentUser.id) {
                this.createdByTasks.push(element);
              }
            }
          });
          resolve(res);
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }

  showInfosByAdvId() {
    this.sInfos.getInfoByAdvId(this.currentUser.id).subscribe((res) => {
      this.infos = res;
      this.infos.forEach((element) => {
        if (!element.vu) {
          this.infosUnread.push(element);
        }
      });
    });
  }

  setFilter(value) {
    if (this.filter.state == value) {
      this.filter.state = 'all';
      this.states.forEach((element) => {
        element.active = false;
      });
    } else {
      this.filter.state = value;

      this.states.forEach((element) => {
        if (value == element.name) {
          element.active = true;
        } else {
          element.active = false;
        }
      });
    }
  }

  showCheckedTasksByTargetId(id) {
    return new Promise((resolve) => {
      this.sTasks.getCheckedTaskByTargetId(id).subscribe(
        (res) => {
          this.checkTasks = res;
          resolve(res);
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }

  showTasksByTargetId(id) {
    this.trashtrue = true;
    this.suppreTask = [];
    return new Promise((resolve) => {
      this.sTasks.getTaskByTargetId(id).subscribe(
        (res) => {
          this.tasks = res;
          resolve(res);
          this.checkTodayTask(id);
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }

  showTasksGddByTargetId() {
    return new Promise((resolve) => {
      this.sTasksGdd.getTaskGddByTargetId(this.currentUser.id).subscribe(
        (res) => {
          this.tasksGdd = res;

          let now = new Date();
          now = new Date(now.setDate(now.getDate() - 30));

          this.tasksGdd = this.tasksGdd.filter(
            (res) => new Date(res.created_at) >= now
          );

          this.gddEncours = this.tasksGdd.filter((t) => t.state === 'En cours');

          this.gddTermine = this.tasksGdd.filter((t) => t.state === 'Terminé');
          this.gddAfaire = this.tasksGdd.filter((t) => t.state === 'A faire');

          this.countGddEncours = this.gddEncours.length;

          this.countGddTermine = this.gddTermine.length;
          this.countGddAfaire = this.gddAfaire.length;
          resolve(res);
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }

  showTasksGdd() {
    return new Promise((resolve) => {
      this.sTasksGdd.getAllTasks().subscribe(
        (res) => {
          this.tasksGdd = res;
          let now = new Date();
          now = new Date(now.setDate(now.getDate() - 30));

          this.tasksGdd = this.tasksGdd.filter(
            (res) => new Date(res.created_at) >= now
          );

          this.gddEncours = this.tasksGdd.filter((t) => t.state === 'En cours');

          this.gddTermine = this.tasksGdd.filter((t) => t.state === 'Terminé');
          this.gddAfaire = this.tasksGdd.filter((t) => t.state === 'A faire');

          this.countGddEncours = this.gddEncours.length;

          this.countGddTermine = this.gddTermine.length;
          this.countGddAfaire = this.gddAfaire.length;
          resolve(res);
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }

  checkTodayTask(id) {
    this.todayTask = [];
    this.otherDayTask = [];
    this.tommorrowTask = [];
    this.tasksWarning = [];
    const today = new Date();
    today.setHours(1, 0, 0, 0);

    let dayNow = today.getDate();
    if (dayNow == 0) {
      dayNow = 1;
    }

    const monthNow = today.getMonth() + 1;
    const yearNow = today.getFullYear();
    this.tasks.forEach((element) => {
      if (element.date != undefined || null) {
        let taskMonth = element.date.substr(5, 2);
        let taskDay = element.date.substr(8, 2);
        let taskYear = element.date.substr(0, 4);

        if (yearNow == taskYear) {
          if (monthNow == taskMonth && dayNow == taskDay) {
            this.todayTask.push(element);
          } else if (monthNow == taskMonth && dayNow + 1 == taskDay) {
            this.tommorrowTask.push(element);
          } else if (monthNow == taskMonth && dayNow > taskDay) {
            this.tasksWarning.push(element);
          } else if (
            monthNow > taskMonth ||
            (monthNow < taskMonth && taskYear < yearNow)
          ) {
            this.tasksWarning.push(element);
          } else {
            this.otherDayTask.push(element);
          }
        } else if (taskYear < yearNow) {
          this.tasksWarning.push(element);
        } else {
          this.otherDayTask.push(element);
        }
      }
    });

    this.showCheckedTasksByTargetId(id);
  }

  createTaskGddDialog(): void {
    const dialogRef = this.dialog.open(CreateTaskGddComponent, {
      width: '40vw',
      height: '80%',
      disableClose: true,
      data: {
        edit: false,
      },
    });
  }
  editTaskGddDialog(task): void {
    const dialogRef2 = this.dialog.open(CreateTaskGddComponent, {
      width: '80vw',
      minHeight: 'calc(60vh - 90px)',
      height: 'auto',
      panelClass: 'myClass',
      disableClose: true,
      data: {
        edit: true,
        task: task,
      },
    });
  }

  createTaskDialog(task): void {
    const dialogRef = this.dialog.open(CreateTaskComponent, {
      width: '40vw',
      height: '80%',
      disableClose: true,
      data: {
        task: task,
        inCase: false,
        edit: false,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.showTasksByTargetId(this.currentUser.id);
    });
  }

  editTaskDialog(task): void {
    const dialogRef = this.dialog.open(CreateTaskComponent, {
      width: '40%',
      height: '80%',
      data: {
        task: task,
        edit: true,
        inCase: false,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.showTasksByTargetId(this.currentUser.id);
    });
  }

  deleteTask(task) {
    this.sTasks.deleteTask(task._id).subscribe((response) => {
      this.sHistoriques
        .createHistorique(
          task.caseId,
          task.customerId,
          'supprimé',
          'tâches',
          task.message,
          this.currentUser.id
        )
        .then((res) => {
          if (res['error']) {
            alert(res);
          }
        });
      this.showTasksByTargetId(this.currentUser.id);
      this.trashtrue = false;
    });
  }

  componentMethodName(event: any, isChecked: boolean) {
    if (isChecked) {
      this.suppreTask.push(event.target.value);
    } else {
      let index = this.suppreTask.indexOf(event.target.value);
      this.suppreTask.splice(index, 1);
    }
  }

  supprTaskArray() {
    if (
      confirm(
        'Si vous supprimez cette tâche, vous ne pourrez plus la récupérer. Etes vous sur de vouloir le faire ?'
      )
    ) {
      this.suppreTask.forEach((element) => {
        this.sTasks.getTaskBYId(element).subscribe((res) => {
          this.deleteTask(res[0]);
        });
        this.deleteSnackBar();
      });
    }
  }
  editTask(task, taskId) {
    this.sTasks.editTask(task, taskId).then((res) => {
      if (res['error']) {
        return res.error;
      } else {
        this.sHistoriques
          .createHistorique(
            task.caseId._id,
            task.customerId._id,
            'édité',
            'la tâche',
            task.message,
            this.currentUser.id
          )
          .then((res) => {
            if (res['error']) {
              alert(res);
            }
          });
        this.showTasksByTargetId(this.currentUser.id);
      }
    });
  }

  editState(task, taskId) {
    task.updated_at = new Date();

    if (task.state === '3-Faite') {
      if (task.created_by._id === this.currentUser.id) {
        task.state = '3-Faite';
      }
      if (
        (task.created_by._id != this.currentUser.id &&
          task.target.role === 'Admin') ||
        task.target.role === 'Responsable ADV' ||
        task.target.role === 'Adv'
      ) {
        task.state = '3-Faite';
      }
      if (task.created_by._id != this.currentUser.id) {
        this.sb.open(
          `Votre tâche a été mise en attente avant d'être confirmée`,
          '',
          {
            duration: 3000,
            verticalPosition: 'bottom',
            panelClass: ['info-snackbar'],
          }
        );

        task.state = '4-Attente';
      }
    }
    this.sTasks.editTask(task, taskId).then((res) => {
      if (res['error']) {
        return res.error;
      } else {
        this.sHistoriques
          .createHistorique(
            task.caseId._id,
            task.customerId._id,
            'edité',
            ' le statut de la tâche',
            task.message,
            this.currentUser ? this.currentUser.id : null
          )
          .then((res) => {
            if (res['error']) {
              alert(res);
            }
          });
        this.showTasksByTargetId(this.currentUser.id);
      }
    });
  }
  goBack() {
    this._location.back();
  }

  goToCase(caseId) {
    if (
      this.currentUser.role === 'Adv' ||
      this.currentUser.role === 'Admin' ||
      this.currentUser.role === 'Responsable ADV'
    ) {
      if (caseId.type === 'Vente' || caseId.type === undefined) {
        this.router.navigate(['case-template', caseId._id]);
      } else {
        this.router.navigate(['case-cm-template', caseId._id]);
      }
    } else if (
      this.currentUser.role === 'Commercial' ||
      this.currentUser.role === "Responsable d'agence"
    ) {
      this.router.navigate(['commercial-template', caseId._id]);
    } else if (this.currentUser.role === 'Compta') {
      this.router.navigate(['compta-case-detail', caseId._id]);
    }
  }

  deleteSnackBar() {
    this._snackBar.openFromComponent(DeleteBarComponent, {
      duration: 2000,
      panelClass: ['delete-snackbar'],
    });
  }
  async getGroups(): Promise<void> {
    try {
      const countUnread: any = await this.http
        .get(apiUrl.apiUrl + '/messages/count-unread')
        .toPromise();
      this.countUnread = countUnread;
    } catch (error) {}
  }

  checkConfirm(task, taskId) {
    this.sTasks.editTask(task, taskId).then((res) => {
      if (res['error']) {
        return res.error;
      } else {
        this.showTasksByTargetId(this.currentUser.id);
      }
    });
  }

  receivedTaskEdit(p) {
    const removeIndexEncours = this.gddEncours.findIndex(
      (item) => item.title === p.title
    );

    if (removeIndexEncours != -1) {
      this.gddEncours.splice(removeIndexEncours, 1);
      this.countGddEncours--;
    }

    const removeIndexAfaire = this.gddAfaire.findIndex(
      (item) => item.title === p.title
    );

    if (removeIndexAfaire != -1) {
      this.gddAfaire.splice(removeIndexAfaire, 1);
    }

    this.gddTermine.push(p);
    this.countGddTermine++;
  }
  unrollGdd() {
    this.unroll = !this.unroll;
  }

  checkTaskOfAnotherAdvs(event) {
    let adv = [];
    if (event === 'Mes tâches') {
      adv = this.advs.filter((res) => res._id === this.currentUser.id);
    } else {
      adv = this.advs.filter((res) => res.username === event);
    }
    this.showTasksByTargetId(adv[0]._id);
    this.selectedOption = 'Mes tâches';
  }
  filterByTag(event) {
    this.checkTodayTask(this.currentUser.id);
    if (event != 'all') {
      if (this.selectedOption === 'Mes tâches') {
        this.tasksWarning = this.tasksWarning.filter(
          (res) => res.tag === event
        );
        this.todayTask = this.todayTask.filter((res) => res.tag === event);
        this.tommorrowTask = this.tommorrowTask.filter(
          (res) => res.tag === event
        );
        this.otherDayTask = this.otherDayTask.filter(
          (res) => res.tag === event
        );
      }
      if (this.selectedOption === 'Tâches affectées') {
        this.createdByTasks = this.createdByTasks.filter(
          (res) => res.tag === event
        );
      }
    }
  }
}
