import { StatfinancementService } from './../../services/statfinancement.service';
import { ActivatedRoute } from '@angular/router';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-admin-financement',
  templateUrl: './admin-financement.component.html',
  styleUrls: ['./admin-financement.component.scss']
})
export class AdminFinancementComponent {

  sofinco = [];
  cetelem = [];
  autres = [];
  comptant = [];
  result = {};
  total = 0;
  constructor(
    private dialogRef: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.total = data.financements.filter(fi => fi.organisme === data.selectedPartenaire)[0].price_ht

    for (const agence of data.agences) {
      const agenceName = agence.agence; // Obtenez le nom de l'agence

      // Initialisez un objet pour stocker les totaux par type de financement
      this.result[agenceName] = {
        nomAgence: agenceName,
        commerciaux: [],
        totalByFinancementType: {
          sofinco: 0, // Initialisez les totaux à 0 pour chaque type de financement
          cofidis: 0,
          cetelem: 0,
          comptant: 0
        }
      };

      for (const commercial of agence.commerciaux) {
        const commercialName = commercial.commercial; // Obtenez le nom du commercial
        const affairesSofinco = [];
        const affairesCofidis = [];
        const affairesCetelem = [];
        const affairesComptant = [];
        let totalSofinco = 0;
        let totalCofidis = 0;
        let totalCetelem = 0;
        let totalComptant = 0;

        for (const affaire of commercial.affaires) {
          // Ajoutez le price_ht de l'affaire au total approprié en fonction du financement_type
          if (affaire.financement_type === 'sofinco') {
            affairesSofinco.push(affaire);
            totalSofinco += affaire.price_ht;
          } else if (affaire.financement_type === 'cofidis') {
            affairesCofidis.push(affaire);
            totalCofidis += affaire.price_ht;
          } else if (affaire.financement_type === 'cetelem') {
            affairesCetelem.push(affaire);
            totalCetelem += affaire.price_ht;
          } else if (affaire.financement_type === 'Comptant') {
            affairesComptant.push(affaire);
            totalComptant += affaire.price_ht;
          }
        }

        // Ajoutez le commercial et ses affaires aux totaux appropriés
        this.result[agenceName].commerciaux.push({
          nomCommercial: commercialName,
          affairesSofinco: affairesSofinco,
          totalSofinco: totalSofinco,
          affairesCofidis: affairesCofidis,
          totalCofidis: totalCofidis,
          affairesCetelem: affairesCetelem,
          totalCetelem: totalCetelem,
          affairesComptant: affairesComptant,
          totalComptant: totalComptant
        });

        // Ajoutez les totaux au niveau de l'agence
        this.result[agenceName].totalByFinancementType.cetelem += totalCetelem;
        this.result[agenceName].totalByFinancementType.sofinco += totalSofinco;
        this.result[agenceName].totalByFinancementType.cofidis += totalCofidis;
        this.result[agenceName].totalByFinancementType.comptant += totalComptant;
      }
    }
  }

  closeModale() {
    this.dialogRef.closeAll();
  }

}
