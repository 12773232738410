import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '@app/_services';

@Component({
  selector: 'login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
})

export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    error = '';

    constructor(
        public formBuilder: FormBuilder,
        public router: Router,
        public authenticationService: AuthenticationService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.userValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            mail: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    get f() { return this.loginForm.controls; }

    onSubmit() {

        this.submitted = true;

        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.mail.value.toLowerCase(), this.f.password.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    if (this.authenticationService.userSubject.value.role === 'Admin' && this.authenticationService.userSubject.value.mail != 'communication2@enerconfort.com') {
                      this.router.navigateByUrl('/admin-dashboard').then(() => {
                        window.location.reload();
                      });
                    }
                    else if (this.authenticationService.userSubject.value.role === 'User') {
                      this.router.navigateByUrl('/user-dashboard').then(() => {
                        window.location.reload();
                      });;
                    }
                    else if (this.authenticationService.userSubject.value.role === 'Commercial') {
                      this.router.navigateByUrl('/commercial-dashboard').then(() => {
                      window.location.reload();
                      });
                    }
                    else if (this.authenticationService.userSubject.value.role === 'Responsable d\'agence') {
                      this.router.navigateByUrl('/commercial-dashboard').then(() => {
                      window.location.reload();
                      });
                    }
                    else if (this.authenticationService.userSubject.value.role === 'Tmk' || this.authenticationService.userSubject.value.role === 'Beenergie') {
                      this.router.navigateByUrl('/tmk-dashboard').then(() => {
                      window.location.reload();
                      });
                    }
                    else if (this.authenticationService.userSubject.value.role === 'Superviseur TMK') {
                      this.router.navigateByUrl('/super-tmk-dashboard').then(() => {
                      window.location.reload();
                      });
                    }
                    else if (this.authenticationService.userSubject.value.role === 'Adv') {
                      this.router.navigateByUrl('/adv-dashboard').then(() => {
                      window.location.reload();
                      });
                    }
                    else if (this.authenticationService.userSubject.value.role === 'Compta') {
                      this.router.navigateByUrl('/compta-dashboard').then(() => {
                      window.location.reload();
                      });
                    }
                    else if (this.authenticationService.userSubject.value.role === 'Spectateur') {
                      this.router.navigateByUrl('/spectateur-dashboard').then(() => {
                      window.location.reload();
                      });
                    }

                    else if (this.authenticationService.userSubject.value.role === 'Logistique') {
                      this.router.navigateByUrl('/logistique-dashboard').then(() => {
                        window.location.reload();
                      });
                    }
                    else if (this.authenticationService.userSubject.value.role === 'Technicien' || this.authenticationService.userSubject.value.role === 'Responsable Technicien' ) {
                      this.router.navigateByUrl('/technicien-dashboard').then(() => {
                        window.location.reload();
                      });
                    }

                },
                error: error => {
                    this.error = "Identifiant ou mot de passe incorrect";
                    this.loading = false;
                }
            });
    }

}
