import { Options } from '@angular-slider/ngx-slider';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-part4',
  templateUrl: './part4.component.html',
  styleUrls: ['./part4.component.scss']
})
export class Part4Component implements OnInit {

  caseId
  anneeSlider = 1950;
  surfaceSlider = 0;
  temperatureSlider = 0;
  book;

  anneeOptions: Options = {
    floor: 1850,
    ceil: 2024,
    step: 1,
    minRange: 1600,
    maxRange: 2024,
    showSelectionBar: true,
  };

  surfaceOptions: Options = {
    floor: 50,
    ceil: 500,
    step: 10,
    minRange: 50,
    maxRange: 500,
    showSelectionBar: true,
    translate: (value: number): string => value + " m2",
  };

  temperatureOptions: Options = {
    floor: 15,
    ceil: 28,
    minRange: 15,
    maxRange: 28,
    showSelectionBar: true,
    translate: (value: number): string => value + "°C",
  };
  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.caseId = this.route.snapshot.params['id'];
    this.book = JSON.parse(localStorage.getItem('book'));
  }

  public suivant() {
    this.book.annee = this.anneeSlider;
    this.book.surface = this.surfaceSlider;
    this.book.temperature = this.temperatureSlider;

    localStorage.setItem('book', JSON.stringify(this.book));
    this.router.navigate(['/book/part4bis', this.caseId]);
  }
}

