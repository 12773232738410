import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { HistoriqueService } from '@app/services/historique.service';
import { MairiesService } from '@app/services/mairies.service';
import { RolesService } from '@app/services/roles.service';

@Component({
  selector: 'app-list-mairie',
  templateUrl: './list-mairie.component.html',
  styleUrls: ['./list-mairie.component.scss']
})
export class ListMairieComponent implements OnInit {

  case;
  caseId;
  mairies;
  userRole;
  agreed;
  showCross;
  @Output() voted = new EventEmitter<boolean>();

  constructor(
    private route: ActivatedRoute,
    private sMairies: MairiesService,
    private sRoles: RolesService,
    private router: Router,
    private sCases: CasesService,
    private sHistoriques: HistoriqueService,
  ) {
    this.caseId = this.route.snapshot.params['id'];
    this.userRole = this.sRoles.user.role;
    this.showMairies();

    }
  ngOnInit(): void {
    this.showCaseInfos();
  }


  vote() {
    this.case.mairie === 'true' ? this.agreed = false : this.agreed = true;
    this.voted.emit(this.agreed);
  }

  showMairies() {
    this.sMairies.getMairiesByCaseId(this.caseId).subscribe(res => {
      this.mairies = res;
    });
  }
  deleteMairie(id,reason, change,value) {
    if (confirm("Confirmer la suppression?")) {
      this.sMairies.delete(id);
      this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change,value,this.sRoles.user.id).then(res => {
        if (res['error']) {
          alert(res);
        }
      });
    }
    this.showMairies();

  }

  goToEditFinancement(mairieId) {
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['case-template', this.caseId], { state: { value: 'mairieEdit', mairieId: mairieId }  });
  }

  showCaseInfos() {
    return new Promise(resolve => {

    this.sCases.getCaseById(this.caseId).subscribe(res => {
      this.case = res[0];
      this.vote();
      resolve(res);
        }, error => {
          console.log(error);
      });
    });
  }
  showEdit() {
    this.showCross = !this.showCross;
  }

  saveChange(reason, change, value) {
    if (this.case.mairie === 'false') {
      this.case.abf = 'false';
    }
    this.vote();
    this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change,value,this.sRoles.user.id).then(res => {
      console.log(res);
      if (res['error']) {
        alert(res);
      }
    });
    this.sCases.editCase(this.case, this.caseId).then(res => {
      if(res['error']) {
        return res.error;
      }
    });
  }
}
