import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiUrl } from '@environments/env';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { RolesService } from '@app/services/roles.service';

@Component({
  selector: 'app-groups-create',
  templateUrl: './groups-create.component.html',
  styleUrls: ['./groups-create.component.scss']
})
export class GroupsCreateComponent implements OnInit {
  filteredOptions: Observable<string[]>;
  group: any = {
    type: 'channel',
    title: '',
    users: [],
  };
  isLoading = false;
  myControl = new FormControl();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialoRef: MatDialogRef<any>,
    private http: HttpClient,
    private sb: MatSnackBar,
    private sRoles: RolesService
  ) {}

  ngOnInit(): void {
    if (this.data.group) this.group = this.data.group;
    else this.group.type = this.data.type;
    this.filteredOptions = this.myControl.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
    if (this.group.type === 'channel' && !this.group.users.includes(this.sRoles.user.id)) this.group.users.push(this.sRoles.user.id)

  }

  addUser(user): void {

    if (!this.group.users.includes(user._id)) this.group.users.push(user._id);
  }

  displayFn(user): string {
    return user ? user.username : '';
  }

  getUser(userId: string): string {
    return this.data.users.find(u => u._id === userId);


  }

  removeUser(userId: string): void {
    this.group.users.splice(this.group.users.indexOf(userId), 1);
  }

  save(): any {
    if ((this.group.type === 'channel' && !this.group.title) || !this.group.users.length) return this.sb.open(`Veuillez remplir tous les champs.`, 'Ok', { duration: 5000, verticalPosition: 'top' });
    this.isLoading = true;

    if (this.group._id) {
      return this.http.patch(`${apiUrl.apiUrl}/groups/${this.group._id}/users`, this.group).toPromise()
        .then((data) => {
          this.sb.open(`Les utilisateurs de ce groupe sont à jour.`, 'Ok', { duration: 5000, verticalPosition: 'top' });
          this.dialoRef.close(data);
        })
        .catch((error) => this.sb.open(error.message, 'Ok', { duration: 5000, verticalPosition: 'top' }))
        .finally(() => this.isLoading = false);
    }

    this.http.post(apiUrl.apiUrl + '/groups', this.group).toPromise()
      .then((data) => {
        this.sb.open(`Votre groupe a été créé`, 'Ok', { duration: 5000, verticalPosition: 'top' });
        this.dialoRef.close(data);
      })
      .catch((error) => this.sb.open(error.message, 'Ok', { duration: 5000, verticalPosition: 'top' }))
      .finally(() => this.isLoading = false);
  }

  private _filter(value: any): string[] {
    const testValue = value.username ? value.username.toLowerCase() : value.toLowerCase();
    const users = this.data.users.filter(u => !this.group.users.includes(u._id));
    return users.filter(u => u.username.toLowerCase().indexOf(testValue) > -1);
  }
}
