import { AvoirService } from './../../services/avoir.service';
import { InfosService } from './../../services/infos.service';
import { TasksService } from './../../services/tasks.service';
import { FinancementsService } from './../../services/financements.service';
import { PiecesService } from '@app/services/pieces.service';
import { HistoriqueService } from './../../services/historique.service';
import { RolesService } from '@app/services/roles.service';
import { CasesService } from './../../services/cases.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { apiUrl } from '@environments/env';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-compta-case-detail',
  templateUrl: './compta-case-detail.component.html',
  styleUrls: ['./compta-case-detail.component.scss']
})
export class ComptaCaseDetailComponent implements OnInit {

  caseId;
  case;
  showPiece = true;
  mobile
  form = {}
  multipleImagesPieces;
  piecesOk = false;
  apiUrl = apiUrl.apiUrl + '/piece/';

  financementForm: FormGroup;
  billForm: FormGroup;
  pieceBillForm: FormGroup
  bill;
  financements;
  financement;
  bdc;
  bdcImg;
  billImg
  pieces
  task
  res

  avoirs
  avoirChoisi = {
    _id: "",
    title: "",
    montant: 0,
    date: "",
  }

  showSupprAvoir = false
  dataLoad = false

  constructor(
    private route: ActivatedRoute,
    private sCases: CasesService,
    private fb: FormBuilder,
    private sRoles: RolesService,
    private sHistoriques: HistoriqueService,
    private http: HttpClient,
    private sPiece: PiecesService,
    private sFinancement: FinancementsService,
    private sb: MatSnackBar,
    private sTasks: TasksService,
    private sInfos: InfosService,
    private sAvoir: AvoirService
  ) {
    if (window.screen.width <= 600) {
      this.mobile = true;
    }
    this.caseId = this.route.snapshot.params['id'];
    this.sCases.getCaseById(this.caseId).subscribe(res => {
      this.case = res[0]
      if (this.case.financement_type === 'Comptant') {
        this.createForm();

      }
    })
    let now = new Date();
    this.pieceBillForm = this.fb.group({
      title: new FormControl('', [Validators.required]),
      qualification: new FormControl('Conforme'),
      created_by: new FormControl(this.sRoles.user.id),
      created_at: new FormControl(now.setHours(now.getHours())),
      updated_at: new FormControl(),
    });
    this.getBill()
    this.getFinancement()
    this.getBDC()
    this.getPiece()
    this.getAvoir()
  }

  ngOnInit(): void {
  }

  getBill() {
    this.sPiece.getBillByCaseId(this.caseId).subscribe(res => {
      this.bill = res[0]
      this.billImg = this.bill.image.split(',')[0]
    })
  }

  getBDC() {
    this.sPiece.getLastSpecificPieceByTitle(this.caseId, 'Bon de commande').subscribe(res => {
      this.bdc = res[0]
      this.bdcImg = this.bdc.image.split(',')[0]
    })
  }

  getFinancement() {
    this.sFinancement.getFinancementsByCaseId(this.caseId).subscribe(res => {
      let i = 0
      this.financements = res

      while (i < this.financements.length) {
        if (this.financements[i].state == 'Acceptation' || this.financements[i].state == 'Acompte' || this.financements[i].state == 'Payé') {
          this.financement = this.financements[i]
          break
        }
        i++
      }
      this.dataLoad = true
    })
  }

  getPiece() {
    this.sPiece.getPiecesByCaseId(this.caseId).subscribe(res => {
      this.pieces = res
    })
  }


  createForm() {
    this.financementForm = this.fb.group({
      cheques: '',
      choice: this.case.financement_comptant.choice ? this.case.financement_comptant.choice : null,
      cheque1: this.fb.group({
        date: this.case.financement_comptant.cheque1.date,
        encaissement: this.case.financement_comptant.cheque1.encaissement,
        montant: this.case.financement_comptant.cheque1.montant,
        date_validation: this.case.financement_comptant.cheque1.date_validation,
        validation: this.case.financement_comptant.cheque1.validation,
      }),
      cheque2: this.fb.group({
        date: this.case.financement_comptant.cheque2.date,
        encaissement: this.case.financement_comptant.cheque2.encaissement,
        montant: this.case.financement_comptant.cheque2.montant,
        date_validation: this.case.financement_comptant.cheque2.date_validation,
        validation: this.case.financement_comptant.cheque2.validation ? this.case.financement_comptant.cheque2.validation : false,
      }),
      cheque3: this.fb.group({
        date: this.case.financement_comptant.cheque3.date,
        encaissement: this.case.financement_comptant.cheque3.encaissement,
        montant: this.case.financement_comptant.cheque3.montant,
        date_validation: this.case.financement_comptant.cheque3.date_validation,
        validation: this.case.financement_comptant.cheque3.validation ? this.case.financement_comptant.cheque3.validation : false,
      })
    });
  }


  toggleShowPiece() {
    this.showPiece = !this.showPiece
  }

  checkDateChange(cheque, modele, value) {
    if (value != modele) {
      this.saveChange('edité la date d\'encaissement', cheque, value)
    }
  }


  saveChange(reason, change, value) {
    this.case.financement_comptant = this.financementForm.value
    this.sCases.editCase(this.case, this.caseId).then(res => {
      if (res['error']) {
        return res.error;
      }
      this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change, value, this.sRoles.user.id).then(res => {
        if (res['error']) {
          alert(res);
        }
      });
    });
  }

  selectMultiple(event, title) {
    if (event.target.files.length > 0) {
      this.multipleImagesPieces = event.target.files;
      this.onSubmitMultiple(title, 'ajouté', 'pièce', title)
    }
  }

  onSubmitMultiple(title, reason, change, value) {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', this.pieceBillForm.value.description);
    formData.append('qualification', this.pieceBillForm.value.qualification);
    formData.append('caseId', this.caseId);
    formData.append('created_at', this.pieceBillForm.value.created_at);
    for (let img of this.multipleImagesPieces) {
      formData.append('filesPieces', img);
    }
    this.case.billAdd = 'ok'
    this.sCases.editCase(this.case, this.caseId).then(res => {
      if (res['error']) {
        return res.error;
      }

    })
    this.http.post<any>(this.apiUrl + 'multiplePieces', formData).subscribe(
      (res) => {
        this.piecesOk = true;
        this.getBill()
        this.sb.open(`Facture ajoutée`, '', { duration: 2000, verticalPosition: 'bottom', panelClass: ['add-snackbar'] });
        this.showTasksByMessage()

      },
      (err) => console.log(err)
    );

    const advs = [this.case.adv._id, "5ff22a8ba0f2570004f151d0"]
    advs.forEach(element => {
      let comment = {
        comment: 'Facturation de l\'affaire : ',
        reason: 'facture',
        created_by: this.sRoles.user.id,
        created_at: new Date(),
        caseId: this.case._id,
        customerId: this.case.customer._id,
        adv: element

      }

      this.sInfos.createInfo(comment).then(res => {
        if (res['error']) {
          alert(res.error);
        }
      }).catch(err => {
        console.log(err)
      });
    });

    this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change, value, this.sRoles.user.id).then(res => {
      if (res['error']) {
        alert(res);
      }
      else {
      }
    });

  }

  showTasksByMessage() {
    const message = 'Facturer le chantier de ' + this.case.customer.name.trim()
    this.sTasks.getTaskByMessage(message).subscribe(res => {
      this.task = res[0];
      this.task.state = "3-Faite"
      this.sTasks.editTask(this.task, this.task._id).then(res => {
      })

    })
  }


  killBill(id, reason, change, value) {
    if (confirm("Confirmer la suppression?")) {
      this.sPiece.delete(id).subscribe(response => {
        this.bill = null
        this.case.billAdd = "false"
        this.sCases.editCase(this.case, this.caseId).then(res => {
          if (res['error']) {
            return res.error;
          }
          else {
            this.sb.open(`Facture supprimée`, '', { duration: 2000, verticalPosition: 'bottom', panelClass: ['delete-snackbar'] });
          }
        })
      });
      this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change, value, this.sRoles.user.id).then(res => {
        if (res['error']) {
          alert(res);
        }
      });
    }
  }

  saveChangeFinancement(reason, change, value) {
    this.sFinancement.editFinancement(this.financement, this.financement._id).then(res => {
      console.log(res)
    })
    this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change, value, this.sRoles.user.id).then(res => {
      console.log(res);
      if (res['error']) {
        alert(res);
      }
    });
  }

  addAvoir() {
    let avoir = {
      title: "Avoir n°" + (this.avoirs.length + 1),
      montant: 0,
      date: "",
      caseId: this.caseId,
    }
    this.sAvoir.createAvoir(avoir).then(res => {
      console.log(res)
      this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, "ajouté", "Avoir n°", this.avoirs.length + 1, this.sRoles.user.id).then(res => {
        this.getAvoir()
        if (res['error']) {
          alert(res);
        }
      });
    })
  }

  getAvoir() {
    this.sAvoir.getAvoirByCaseId(this.caseId).subscribe(res => {
      this.avoirs = res
      if (this.avoirs.length != 0) {
        this.avoirChoisi = res[0]
      }


    })
  }

  chosenAvoir(avoir) {
    this.avoirChoisi = avoir
  }


  editAvoir(avoirForm, id, change, value) {
    this.sAvoir.editAvoir(avoirForm, id).then(res => {
      this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, "édité", change, value, this.sRoles.user.id).then(res => {
        console.log(res)
      })
    })

  }

  supprAvoir(id, title) {
    this.sAvoir.delete(id).subscribe(res => {
      console.log(res)
      this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, "supprimé", "avoir", title, this.sRoles.user.id).then(res => {
        console.log(res)
        this.getAvoir()
        if (res['error']) {
          alert(res);
        }
      });
    })
  }

  toggleShowAvoir() {
    this.showSupprAvoir = !this.showSupprAvoir
  }


}
