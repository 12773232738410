import { Component, OnInit } from '@angular/core';
import { PiecesService } from '@app/services/pieces.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-technicien-case-list-pieces',
  templateUrl: './technicien-case-list-pieces.component.html',
  styleUrls: ['./technicien-case-list-pieces.component.scss'],
})
export class TechnicienCaseListPiecesComponent implements OnInit {
  pieces;
  caseId;

  constructor(
    private sPieces: PiecesService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {
    this.caseId = this.route.snapshot.params['id'];
  }

  ngOnInit(): void {
    this.sPieces.getTechPiecesByCaseId(this.caseId).subscribe((pieces) => {
      this.pieces = pieces;
    });
  }

  goToDetail(currentPiece) {
    this.router.navigate(['detail-piece', currentPiece]);
  }

  goBack() {
    this.location.back();
  }
}
