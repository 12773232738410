import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.scss']
})
export class ProgressbarComponent implements OnInit {

  @Input()
  public data: any

  @Output()
  public onData: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  updateData(data) {
    this.onData.emit(data)
  }
  ngOnInit(): void {
  }

}
