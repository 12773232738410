import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CasesService } from '@app/services/cases.service';

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss']
})
export class BookComponent implements OnInit {

  caseId
  case
  constructor(private route: ActivatedRoute, private sCases: CasesService) { }

  ngOnInit(): void {
    localStorage.removeItem('book');
    this.caseId = this.route.snapshot.params['id'];
    this.showCaseById(this.caseId)

  }

  showCaseById(caseId) {
    return new Promise(resolve => {

      this.sCases.getCaseById(caseId).subscribe(res => {
        this.case = res[0];
        resolve(res);
        this.saveData()
      }, error => {
        console.log(error);
      });
    });
  }
  saveData() {
    let eventObject = {
      customer: this.case.customer,
      caseId: this.caseId,
      question1: { reponse: null, bonneReponse: "70%" },
      question2: {
        reponse: null, bonneReponse: "Habitat", valid: false,
      },
      question3: { reponse: null, bonneReponse: "Toiture", valid: false, },
      question4: { reponse: null, bonneReponse: ["Pompe à chaleur", "Ballon thermodynamique"], valid: false, },
      question5: {
        reponse: null, bonneReponse: ["Améliorer le confort", "Faire des économies"], valid: false,
      },
      equipements: [],
      pourcentageReussite: null,
      annee: null,
      surface: null,
      temperature: null,
      facture_electricite: null,
      facture_gaz: null,
      facture_fioul: null,
      facture_bois: null,
      dpe_estimation: null,
      reduire: null,
      assainir: null,
      ameliorer: null,
      developper: null,
      energetique: null,

    }

    localStorage.setItem('book', JSON.stringify(eventObject));
  }

}
