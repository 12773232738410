import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NoModalComponent } from '@app/pv/no-modal/no-modal.component';
import { CasesService } from '@app/services/cases.service';
import { ChantierService } from '@app/services/chantier.service';
import { PiecesService } from '@app/services/pieces.service';
import { PdfsService } from '@app/services/pdfs.service';
import { ModalPrevisualizeComponent } from '@app/pv/modal-previsualize/modal-previsualize.component';
@Component({
  selector: 'app-recette-vmc',
  templateUrl: './recette-vmc.component.html',
  styleUrls: ['./recette-vmc.component.scss']
})
export class RecetteVmcComponent {

  @Input() currentKit;
  @Input() case;
  @Input() chantierId;
  @Input()
  public data: any

  @Output()
  public onData: EventEmitter<any> = new EventEmitter<any>();

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourFormGroup: FormGroup;

  tabs = [];
  tabs_soufflage = []
  selected = new FormControl(0);
  selected_soufflage = new FormControl(0);

  bouches_extraction_selected;
  bouches_soufflage_selected;

  _step: number = 1;
  multipleImagesPieces
  lastChantier

  ui = false
  circuit_vicie = false
  disjoncteur = false
  cablage = false
  circuit_neuf = false
  mobile = false
  n_serie = false
  piecesNserie;
  piece_serie:any;
  loader
  createDoc
  isValid = false

  @Input()
  set step(_step: number) {
    this._step = this.parseNumber(_step);
  }
  private parseNumber(num: any): number {
    return +num;
  }
  ngAfterContentInit() {

    this.updateData(25)
    this.checkPieceNSerie()
    this.checkPieceOfCible()
    this.bouches_extraction_selected = this.currentKit.bouches_extraction[0]
    this.bouches_soufflage_selected = this.currentKit.bouches_soufflage[0]
    this.firstFormGroup = this._formBuilder.group({
      marque_type: [this.currentKit.marque_type, Validators.required],
      local_pose: [this.currentKit.local_pose, Validators.required],
      gaine_entree: [this.currentKit.gaine_entree, Validators.required],
      gaine_rejet: [this.currentKit.gaine_rejet, Validators.required],
      distance_air: [this.currentKit.distance_air, Validators.required],
      cablage_conforme: [this.currentKit.cablage_conforme, Validators.required],
      fixation_ui: [this.currentKit.fixation_ui, Validators.required],
      evacuation_condensat: [this.currentKit.evacuation_condensat, Validators.required],
      signalisation_sav: [this.currentKit.signalisation_sav, Validators.required],

    });
      this.tabs = this.currentKit.bouches_extraction
      this.tabs_soufflage = this.currentKit.bouches_soufflage
      this.secondFormGroup = this._formBuilder.group({
        piece: [this.bouches_extraction_selected.piece, Validators.required],
        longueur_gaine:[this.bouches_extraction_selected.longueur_gaine, Validators.required],
        debit:[this.bouches_extraction_selected.debit, Validators.required],
      });
      this.thirdFormGroup = this._formBuilder.group({
        piece: [this.bouches_soufflage_selected.piece, Validators.required],
        longueur_gaine:[this.bouches_soufflage_selected.longueur_gaine, Validators.required],
        debit:[this.bouches_soufflage_selected.debit, Validators.required],
      });
      this.fourFormGroup = this._formBuilder.group({
        obturation: [this.currentKit.obturation, Validators.required],
        telecommande: [this.currentKit.telecommande, Validators.required],
        documentation: [this.currentKit.documentation, Validators.required],
        signalisation: [this.currentKit.signalisation, Validators.required],
        nettoyage: [this.currentKit.nettoyage, Validators.required],
        explication: [this.currentKit.explication, Validators.required],
        obturateur: [this.currentKit.obturateur]
      });
      setTimeout(() => {
        this.getLastChantier()
      }, 2000);
  }

updateData(data) {
  this.onData.emit(data)
  console.log(data)
}

  constructor(
    private _formBuilder: FormBuilder,
    private sCases: CasesService,
    private dialog: MatDialog,
    private sPieces: PiecesService,
    private sChantiers: ChantierService,
    private sPdfs: PdfsService) {

      if (window.screen.width <= 600) {
        this.mobile = true;
      }
     }


    getLastChantier(){
      this.sChantiers.getLastChantier(this.case._id).subscribe(res => {
        this.lastChantier = res[0];
        this.checkPieceOfCible()
      })
    }
  getCurrentKitAndSaveChange(event,key) {

    this.currentKit[key] = event;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === this.currentKit.type);

    array[index] = this.currentKit

    this.case.kits_vendus = JSON.stringify(array)

    this.sCases.editCase(this.case, this.case._id).then(res => console.log(res))
  }

  tabClick(event) {
    const current = this.currentKit.bouches_extraction.filter(b => b.name === event.tab.textLabel)
    this.bouches_extraction_selected = current[0]
    this.secondFormGroup.get('piece').setValue(this.bouches_extraction_selected.piece)
    this.secondFormGroup.get('longueur_gaine').setValue(this.bouches_extraction_selected.longueur_gaine)
    this.secondFormGroup.get('debit').setValue(this.bouches_extraction_selected.debit)

  }
  tabClickSoufflage(event) {
    const current = this.currentKit.bouches_soufflage.filter(b => b.name === event.tab.textLabel)
    this.bouches_soufflage_selected = current[0]
    this.thirdFormGroup.get('piece').setValue(this.bouches_soufflage_selected.piece)
    this.thirdFormGroup.get('longueur_gaine').setValue(this.bouches_soufflage_selected.longueur_gaine)
    this.thirdFormGroup.get('debit').setValue(this.bouches_soufflage_selected.debit)
  }

  editSelectedBouche(event, key) {

    this.bouches_extraction_selected[key] = event;
    this.currentKit[key] = event;
    const array = JSON.parse(this.case.kits_vendus);

    const index = array.findIndex(item => item.name === this.currentKit.type);

    array[index].bouches_extraction.find(x => x.name === this.bouches_extraction_selected.name);
    console.log(array[index].bouches_extraction.find(x => x.name === this.bouches_extraction_selected.name))
    let curr = array[index].bouches_extraction.find(x => x.name === this.bouches_extraction_selected.name)
    curr[key] = event
    console.log(array)
    this.case.kits_vendus = JSON.stringify(array)
    this.sCases.editCase(this.case, this.case._id).then(res => console.log(res))
  }

  editSelectedBoucheSoufflage(event, key) {

    this.bouches_soufflage_selected[key] = event;
    this.currentKit[key] = event;

    console.log("this.bouches_soufflage_selected",this.bouches_soufflage_selected)
    const array = JSON.parse(this.case.kits_vendus);
    console.log("array",array)

    const index = array.findIndex(item => item.name === this.currentKit.type);
    console.log("index",index)
    console.log("array[index]",array[index])
    console.log("array[index].bouches_soufflage",array[index].bouches_soufflage)
    //array[index] = this.currentKit
    array[index].bouches_soufflage.find(x => x.name === this.bouches_soufflage_selected.name);
    console.log(array[index].bouches_soufflage.find(x => x.name === this.bouches_soufflage_selected.name))
    let curr = array[index].bouches_soufflage.find(x => x.name === this.bouches_soufflage_selected.name)
    curr[key] = event
    console.log("curr",curr)
    console.log(array)
    this.case.kits_vendus = JSON.stringify(array)
    this.sCases.editCase(this.case, this.case._id).then(res => console.log(res))
  }

  incrementValueObturateur(step: number = 1,name,reason, option,product): void {
// TODO CHECK SI PAS DE KEY OBTURATEUR
    this.currentKit['obturateur'] = this.currentKit['obturateur'] + step
    console.log("this.currentKit['obturateur']",this.currentKit['obturateur'])
    console.log("this.currentKit",this.currentKit)

    const newValue = this.currentKit['obturateur']
    console.log("this.currentKit",this.currentKit)


    this.addOption(name,reason, option,product, newValue)
  }

  addOption(name,reason, option,product, value) {

    let find = JSON.parse(this.case.kits_vendus).find(x => x.name === name);
    console.log(find)
    find[option] = value;

    console.log(find , "after")
    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === name);
    const selected = array[index]
    console.log("selected", selected)
    this.currentKit = selected
    selected[option] = value

    this.case.kits_vendus = JSON.stringify(array)

    this.sCases.editCase(this.case, this.case._id)

  }

  openDialog(value): void {
    const dialogRef = this.dialog.open(NoModalComponent, {
      width: '640px',
      disableClose: true,
      data: {
        currentKit: this.currentKit,
        case: this.case,
        value: value
      }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }
  async valid() {
    this.isValid = true
    this.loader = true
    await this.generateDoc();
    this.openDialogPrevisualize()
  }


  async generateDoc() {
    let response = await this.sPdfs.generateRecette(this.chantierId._id,'recette_vmc',this.case,this.currentKit,"","").then(res => {
      if(res === 'ok') {
        console.log(res)

      }
    })
    return response;
  }

  openDialogPrevisualize(): void {
    setTimeout(() => {
      this.loader = false
      const dialogRef = this.dialog.open(ModalPrevisualizeComponent, {
        width: '100vh',
        height:  '100vh',
        maxWidth: '100vh',
        maxHeight: '100vh',
        hasBackdrop: false,
        disableClose: true,
        panelClass: 'pdf-visualize',
        data: {
          titlePiece: 'recette_vmc',
          caseId: this.case._id,
          case: this.case,
          kit: this.currentKit,
          chantierId: this.chantierId._id
        }
      });
      dialogRef.afterClosed().subscribe(result => {

      });
    }, 4000);

  }

   selectMultiple(event, title) {
    if (event.target.files.length > 0) {
      this.multipleImagesPieces = event.target.files;
      console.log(this.multipleImagesPieces);
    }
    this.onSubmit(title)
  }

  checkPieceOfCible() {
    this.sPieces.getPiecesByChantierIdAndCible(this.chantierId._id, 'recette_vmc').subscribe(res => {
      console.log(res)
      const pieces:any = res
      if(pieces.find(e => e.title === 'Unité intérieure VMC'))
        this.ui = true
      if(pieces.find(e => e.title === 'Circuit gaine air vicié VMC'))
        this.circuit_vicie = true
      if(pieces.find(e => e.title === 'Disjoncteur VMC'))
        this.disjoncteur = true
      if(pieces.find(e => e.title === 'Cablage électrique VMC'))
        this.cablage = true
      if(pieces.find(e => e.title === 'Circuit gaine air neuf VMC'))
        this.circuit_neuf = true
    })
  }

  onSubmit(title) {
    const form = {
      title: title,
      qualification: 'Conforme',
      caseId: this.case._id,
      created_at: new Date(),
      type: 'technicien',
      chantier: this.chantierId._id,
      cible: 'recette_vmc',
      description: '',
    }
    this.sPieces.addTechPiece(form, this.multipleImagesPieces, this.case._id, this.chantierId._id)
     setTimeout(() => {
      this.checkPieceOfCible()
    }, 3000);
  }

  onSubmitNSerie(title) {
    const form = {
      title: title,
      qualification: 'Conforme',
      caseId: this.case._id,
      created_at: new Date(),
      type: 'technicien',
      chantier: this.chantierId._id,
      cible: 'n_serie_vmc',
      description: '',
    }
    this.sPieces.addTechPiece(form, this.multipleImagesPieces, this.case._id, this.chantierId._id)
    setTimeout(() => {
      this.checkPieceNSerie()
    }, 1100);
  }

  selectNSerie(event, title) {
    if (event.target.files.length > 0) {
      this.multipleImagesPieces = event.target.files;
      console.log(this.multipleImagesPieces);
    }
    this.onSubmitNSerie(title)
  }

  checkPieceNSerie() {
    this.sPieces.getPiecesByChantierIdAndCible(this.chantierId._id, 'n_serie_vmc').subscribe(res => {
      this.piecesNserie = res
      console.log("this.piecesNserie", this.piecesNserie)

      if(this.piecesNserie.find(e => e.title === 'N° de serie vmc') && this.currentKit.type === 'VMC Double Flux') {
        console.log("this.piecesNserie.find(e => e.title === 'N° de serie vmc') && this.currentKit.type === 'VMC Double Flux'")
        this.piece_serie = this.piecesNserie.filter(e => e.title === 'N° de serie vmc')
        this.n_serie = true
      }
    })
  }

}
