import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HistoriqueService } from '@app/services/historique.service';
import { PrimesService } from '@app/services/primes.service';

import { RolesService } from '@app/services/roles.service';

@Component({
  selector: 'app-case-prime-list',
  templateUrl: './case-prime-list.component.html',
  styleUrls: ['./case-prime-list.component.scss']
})
export class CasePrimeListComponent implements OnInit {
  caseId;
  userRole;
  primes;
  showCross = false;

  constructor(
    private route: ActivatedRoute,
    private sRoles: RolesService,
    private router: Router,
    private sHistoriques: HistoriqueService,
    private sPrimes: PrimesService,

  ) {
    this.caseId = this.route.snapshot.params['id'];
    this.userRole = this.sRoles.user.role;
   }

  ngOnInit(): void {
    this.showAllPrimes()

  }

  showAllPrimes() {
    this.sPrimes.getPrimesByCaseId(this.caseId).subscribe(res => {
      this.primes = res
    })
  }


  goToEditPrime(primeId) {
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['case-template', this.caseId], { state: { value: 'primeEdit', primeId: primeId }  });
  }

  deletePrime(id) {
    if (confirm("Confirmer la suppression?")) {
      this.sPrimes.delete(id).subscribe(res => {
        this.showAllPrimes()
      })
    }
  }

  showEdit() {
    this.showCross = !this.showCross;
  }

  getFirstName(product) {
    let prods = []
    let result
    if(JSON.stringify(product).substring(0,1) === '[') {
      product.forEach(element => {
        prods.push(element.name)
      });
      result =  prods.toString()
    }
    else {
      result = product.name
    }
    return result;
  }
}
