import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-part4bis',
  templateUrl: './part4bis.component.html',
  styleUrls: ['./part4bis.component.scss']
})
export class Part4bisComponent implements OnInit {

  caseId
  elecData: number | null = null;
  gazData: number | null = null;
  boisData: number | null = null;
  fioulData: number | null = null;

  reponse = [];

  book;
  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.caseId = this.route.snapshot.params['id'];
    this.book = JSON.parse(localStorage.getItem('book'));
  }

  public selectSpan(spanNumber: number): void {
    const index = this.reponse.indexOf(spanNumber);

    if (index === -1) {
      this.reponse.push(spanNumber);
    } else {
      this.reponse.splice(index, 1);
    }
  }

  public isButtonDisabled(): boolean {
    return (
      (this.reponse.includes(1) && this.elecData === null) ||
      (this.reponse.includes(2) && this.gazData === null) ||
      (this.reponse.includes(3) && this.fioulData === null) ||
      (this.reponse.includes(4) && this.boisData === null) ||
      this.reponse.length === 0
    );
  }

  public suivant() {
    if (this.elecData) {
      this.book.facture_electricite = this.elecData;
    }
    if (this.gazData) {
      this.book.facture_gaz = this.gazData;
    }
    if (this.boisData) {
      this.book.facture_bois = this.boisData;
    }
    if (this.fioulData) {
      this.book.facture_fioul = this.fioulData;
    }
    localStorage.setItem('book', JSON.stringify(this.book));
    this.router.navigate(['/book/part5', this.caseId]);
  }

}
