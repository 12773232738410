import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-part20',
  templateUrl: './part20.component.html',
  styleUrls: ['./part20.component.scss']
})
export class Part20Component implements OnInit {

  caseId
  count: number = 0;
  endValue: number = 90;
  duration: number = 2000;
  book;
  showConfettis = true;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.fadeOutConfettis();
    }, 4000);
    this.caseId = this.route.snapshot.params['id'];
    this.book = JSON.parse(localStorage.getItem('book'));
    this.endValue = this.book.pourcentageReussite ? this.book.pourcentageReussite : 0;
    this.animateCounter(0, this.endValue, this.duration);
  }

  fadeOutConfettis() {
    const confettisElement = document.getElementById('confettis');
    if (confettisElement) {
      confettisElement.classList.add('fade-out');
      setTimeout(() => {
        this.showConfettis = false;
      }, 1000);
    }
  }
  animateCounter(start: number, end: number, duration: number): void {
    const startTime = performance.now();

    const step = (currentTime: number) => {
      const progress = Math.min((currentTime - startTime) / duration, 1);
      this.count = Math.floor(progress * (end - start) + start);

      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  }

}
