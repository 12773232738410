import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { RolesService } from '@app/services/roles.service';

@Component({
  selector: 'app-suivi-dossiers-detail',
  templateUrl: './suivi-dossiers-detail.component.html',
  styleUrls: ['./suivi-dossiers-detail.component.scss']
})
export class SuiviDossiersDetailComponent implements OnChanges {

  @Input() userId;
  cases;

  loading = true;

  element: HTMLElement;

  attente
  mairie
  financement_ec
  financement_valid
  attente_pose
  deblocage
  financement_comptant
  financement_comptant_attente_paiement

  financement_ec_encours = 0
  financement_ec_enrefus = 0

  attente_active = false
  mairie_active = false
  financement_ec_active = false
  financement_valid_active = false
  attente_pose_active = false
  deblocage_active = false

  showCases = false
  constructor(
    private sCases:CasesService,
    public sRoles: RolesService,
    private router: Router
    ) { }

  ngOnChanges(): void {
    this.loading = true;
    this.sCases.getAllCasesByAdvId(this.userId).subscribe(res => {
      this.cases = res['newData']
      this.financement_comptant = res['comptant_attente_pose']
      this.financement_comptant_attente_paiement = res['comptant_attente_paiement']

      this.attente = res['newData'].filter(c => c.pieces.length != 0).sort((a, b) => {
        return <any>new Date(a.datesign) - <any>new Date(b.datesign);
      });
      this.deblocage = res['deblocage'].sort((a, b) => {
        return <any>new Date(a.chantier.date) - <any>new Date(b.chantier.date);
      });

      this.mairie = res['mairie'].sort((a, b) => {
        return <any>new Date(a.mairie.date_depot) - <any>new Date(b.mairie.date_depot);
      });
      this.financement_ec = res['financement'].sort((a, b) => {
        return <any>new Date(a.financement.date_depot) - <any>new Date(b.financement.date_depot);
      });
      this.financement_ec_encours = this.financement_ec.filter(f => f.financement.state === 'En cours').length
      this.financement_ec_enrefus = this.financement_ec.filter(f => f.financement.state === 'Refus').length

      this.financement_valid = res['financement_valid'].sort((a, b) => {
        return <any>new Date(a.financement.date_depot) - <any>new Date(b.financement.date_depot);
      });
      this.attente_pose = res['chantier']

      if(this.attente.length * 5 > 35) {
        document.getElementById('attente').style.height = this.attente.length * 5 + 'px'
        let diffAttente =  parseInt(document.getElementById('attente').style.height) - 35
        if(this.attente.length * 5 > 35) {
          document.getElementById('attente').style.margin = (-diffAttente /2) + 'px 0 0 0'
        }
      }
      if( this.attente.length * 5 > 350) {
        document.getElementById('attente').style.height = '330px'
        document.getElementById('attente').style.margin = '-140px 0 0 0'
      }

      if(this.mairie.length * 5 > 35) {
        document.getElementById('mairie').style.height = this.mairie.length * 5 + 'px'
        let diffMairie =  parseInt(document.getElementById('mairie').style.height) - 35
        if(this.mairie.length * 5 > 35) {
          document.getElementById('mairie').style.margin = (-diffMairie /2) + 'px 0 0 0'
        }
      }
      if(this.financement_ec.length * 5 > 35) {
        document.getElementById('financement_ec').style.height = this.financement_ec.length * 5 + 'px'
        let diffFinancement_ec =  parseInt(document.getElementById('financement_ec').style.height) - 35
        if(this.financement_ec.length * 5 > 35) {
          document.getElementById('financement_ec').style.margin = (-diffFinancement_ec /2) + 'px 0 0 0'
        }
      }
      if(this.attente_pose.length * 5 > 35) {
        document.getElementById('attente_pose').style.height = this.attente_pose.length * 5 + 'px'
        let diffattente_pose =  parseInt(document.getElementById('attente_pose').style.height) - 35
        if(this.attente_pose.length * 5 > 35) {
          document.getElementById('attente_pose').style.margin = (-diffattente_pose /2) + 'px 0 0 0'
        }
      }
      if(this.financement_valid.length * 5 > 35) {
        document.getElementById('financement_valid').style.height = this.financement_valid.length * 5 + 'px'
        let diffFinancement_valid =  parseInt(document.getElementById('financement_valid').style.height) - 35
        if(this.financement_valid.length * 5 > 35) {
          document.getElementById('financement_valid').style.margin = (-diffFinancement_valid /2) + 'px 0 0 0'
        }
      }
      if(this.deblocage.length * 5 > 35) {
        document.getElementById('deblocage').style.height = this.deblocage.length * 5 + 'px'
        let diffdeblocage =  parseInt(document.getElementById('deblocage').style.height) - 35
        if(this.deblocage.length * 5 > 35) {
          document.getElementById('deblocage').style.margin = (-diffdeblocage /2) + 'px 0 0 0'
        }
      }

      if(this.attente.length < this.mairie.length) {
        // negoc gauche
        document.getElementById('mairie_em1').style.height = (parseInt(document.getElementById('mairie').style.height) / 2) + 'px'
        document.getElementById('mairie_em2').style.height = (parseInt(document.getElementById('mairie').style.height) / 2) + 'px'
        // prospec right false
        document.getElementById("attente_em3").style.display = "none"
        document.getElementById("attente_em4").style.display = "none"
      }
      else {
        //prospe droite
        document.getElementById('attente_em3').style.height = (parseInt(document.getElementById('attente').style.height) / 2) + 'px'
        document.getElementById('attente_em4').style.height = (parseInt(document.getElementById('attente').style.height) / 2) + 'px'
        //negoc gauche false
        document.getElementById("mairie_em1").style.display = "none"
        document.getElementById("mairie_em2").style.display = "none"
      }

      if(this.mairie.length > this.financement_ec.length) {
        // negociation droite
        document.getElementById('mairie_em3').style.height = (parseInt(document.getElementById('mairie').style.height) / 2) + 'px'
        document.getElementById('mairie_em4').style.height = (parseInt(document.getElementById('mairie').style.height) / 2) + 'px'
        // completude left false
        document.getElementById("financement_ec_em1").style.display = "none"
        document.getElementById("financement_ec_em2").style.display = "none"
      }
      else {
        // completude gauche
        document.getElementById('financement_ec_em1').style.height = (parseInt(document.getElementById('financement_ec').style.height) / 2) + 'px'
        document.getElementById('financement_ec_em2').style.height = (parseInt(document.getElementById('financement_ec').style.height) / 2) + 'px'
        //negociation droite false
        document.getElementById('mairie_em3').style.display = "none"
        document.getElementById('mairie_em4').style.display = "none"
      }

      if(this.financement_ec.length > this.attente_pose.length) {
        // negociation droite
        document.getElementById('financement_ec_em3').style.height = (parseInt(document.getElementById('mairie').style.height) / 2) + 'px'
        document.getElementById('financement_ec_em4').style.height = (parseInt(document.getElementById('mairie').style.height) / 2) + 'px'
        // completude left false
        document.getElementById("attente_pose_em1").style.display = "none"
        document.getElementById("attente_pose_em2").style.display = "none"
      }
      else {
        // completude gauche
        document.getElementById('attente_pose_em1').style.height = (parseInt(document.getElementById('attente_pose').style.height) / 2) + 'px'
        document.getElementById('attente_pose_em2').style.height = (parseInt(document.getElementById('attente_pose').style.height) / 2) + 'px'
        //negociation droite false
        document.getElementById('financement_ec_em3').style.display = "none"
        document.getElementById('financement_ec_em4').style.display = "none"
      }
      if(this.attente_pose.length > this.financement_valid.length) {
        document.getElementById('attente_pose_em3').style.height = (parseInt(document.getElementById('attente_pose').style.height) / 2) + 'px'
        document.getElementById('attente_pose_em4').style.height = (parseInt(document.getElementById('attente_pose').style.height) / 2) + 'px'

        document.getElementById("financement_valid_em1").style.display = "none"
        document.getElementById("financement_valid_em2").style.display = "none"
      }
      else {

        document.getElementById('financement_valid_em1').style.height = (parseInt(document.getElementById('financement_valid').style.height) / 2) + 'px'
        document.getElementById('financement_valid_em2').style.height = (parseInt(document.getElementById('financement_valid').style.height) / 2) + 'px'
      }

      if(this.financement_valid.length > this.deblocage.length) {
        document.getElementById('financement_valid_em3').style.height = (parseInt(document.getElementById('financement_valid').style.height) / 2) + 'px'
        document.getElementById('financement_valid_em4').style.height = (parseInt(document.getElementById('financement_valid').style.height) / 2) + 'px'

        document.getElementById("deblocage_em1").style.display = "none"
        document.getElementById("deblocage_em2").style.display = "none"
      }
      else {

        document.getElementById('deblocage_em1').style.height = (parseInt(document.getElementById('deblocage').style.height) / 2) + 'px'
        document.getElementById('deblocage_em2').style.height = (parseInt(document.getElementById('deblocage').style.height) / 2) + 'px'
      }
      this.loading = false

    })
  }

  showAllCases() {
    this.showCases = !this.showCases
  }
  getLengths(caseId,piece,array) {
    let count = 0
    array.filter(c => c._id === caseId).forEach(e => {
      e.pieces.forEach(p => {
        if(p.qualification === piece) {
          count++
        }
      });
    });
    return count
  }

  changeActive(valueActive) {
    switch (valueActive) {
      case 'attente_active':
        this.attente_active = !this.attente_active
        this.mairie_active = false
        this.financement_ec_active = false
        this.financement_valid_active = false
        this.attente_pose_active = false
        this.deblocage_active = false
      break;

      case 'financement_ec_active':
        this.financement_ec_active = !this.financement_ec_active
        this.attente_active = false
        this.mairie_active = false
        this.financement_valid_active = false
        this.attente_pose_active = false
        this.deblocage_active = false
      break;

      case 'financement_valid_active':
        this.financement_valid_active = !this.financement_valid_active
        this.attente_active = false
        this.mairie_active = false
        this.financement_ec_active = false
        this.attente_pose_active = false
        this.deblocage_active = false
      break;

      case 'mairie_active':
        this.mairie_active = !this.mairie_active
        this.attente_active = false
        this.financement_ec_active = false
        this.financement_valid_active = false
        this.attente_pose_active = false
        this.deblocage_active = false
      break;

      case 'attente_pose_active':
        this.attente_pose_active = !this.attente_pose_active
        this.mairie_active = false
        this.attente_active = false
        this.financement_ec_active = false
        this.financement_valid_active = false
        this.deblocage_active = false
      break;

      case 'deblocage_active':
        this.deblocage_active = !this.deblocage_active
        this.attente_pose_active = false
        this.mairie_active = false
        this.attente_active = false
        this.financement_ec_active = false
        this.financement_valid_active = false
      break;
    }
  }

  goToCaseTemplate(id) {
    const url = this.router.serializeUrl(
    this.router.createUrlTree([`/case-template/${id}`])
    );
    window.open(url, '_blank');
  }
}
