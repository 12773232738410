import { CasesService } from './../../services/cases.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomersService } from '@app/services/customers.service';
import { AuthenticationService } from '@app/_services';
import { MatTableDataSource } from '@angular/material/table';
import { CustomerInfoComponent } from '@app/spectateur/customer-info/customer-info.component';
import { LogistiqueDetailComponent } from '../logistique-detail/logistique-detail.component';

@Component({
  selector: 'app-logistique-dashboard',
  templateUrl: './logistique-dashboard.component.html',
  styleUrls: ['./logistique-dashboard.component.scss']
})
export class LogistiqueDashboardComponent implements OnInit {

  loading = true;
  customers;
  cases
  series;
  currentUser;
  count;
  dataSource;
  displayedColumns = ['name', 'city', 'mobile', 'mail', 'id'];

  showCheck = false;
  supprCustomer = []
  isfrmChecked
  dataSourceSerie;
  showNserie = false;

  constructor(
    private sCases: CasesService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
  )  {

    this.authenticationService.user.subscribe(x => this.currentUser = x);
   }

  ngOnInit() {
    this.showCases()
    this.showCasesSerie()
  }
  showInfos(id): void {
    const dialogRef = this.dialog.open(LogistiqueDetailComponent, {
      width: '40vw',
      panelClass: 'custom-dialog-container',
      data: {
        id: id,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });

  }


   showCases() {
    this.sCases.getCaseWithChantier().subscribe(response => {
      this.cases = response;
      var dataArr = this.cases.map(item=>{
        return [item._id,item]
    }); // creates array of array
    var maparr = new Map(dataArr); // create key value pair from array of array

    var result = [...maparr.values()];//converting back to array from mapobject
      this.dataSource = new MatTableDataSource(result);
      this.loading = false;
     });
  }

  showCasesSerie() {
    this.sCases.getCaseWithChantierForNSerie().subscribe(response => {
      this.series = response;
      const series:any = response
      var dataArr = series.map(item=>{
        return [item._id,item]
    });

      this.dataSourceSerie = new MatTableDataSource(series);

      this.loading = false;
     });
  }

  applyFilter(filterValue: string) {
    this.showCheck = false;
    this.supprCustomer = []
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  componentMethodName(event: any, isChecked: boolean)
  {
    if (isChecked) {
      this.supprCustomer.push(event.target.value)
    }
    else {
      let index = this.supprCustomer.indexOf(event.target.value);
      this.supprCustomer.splice(index, 1);
    }
  }
  changeNserie() {
    this.showNserie = !this.showNserie
  }

}
