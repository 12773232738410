import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { HistoriqueService } from '@app/services/historique.service';
import { PiecesService } from '@app/services/pieces.service';
import { RolesService } from '@app/services/roles.service';
import { ManquantePieceComponent } from '../manquante-piece/manquante-piece.component';

@Component({
  selector: 'app-list-piece',
  templateUrl: './list-piece.component.html',
  styleUrls: ['./list-piece.component.scss']
})
export class ListPieceComponent implements OnInit {

  list = true;
  caseId;
  case;
  pieces;
  piecesManquantes;
  userRole;
  caseForm;
  showCross = false;

  constructor(
    private route: ActivatedRoute,
    private sPieces: PiecesService,
    public sRoles: RolesService,
    private sCases: CasesService,
    private router: Router,
    private sHistoriques: HistoriqueService,
    private dialog: MatDialog,
    private sb: MatSnackBar,

  ) {
    this.caseId = this.route.snapshot.params['id'];
    this.showPiecesManquantes()
    this.showPieces();
    this.showCaseById();

    }
  ngOnInit(): void {
    this.userRole = this.sRoles.user.role;

  }

  showPieces() {
    this.sPieces.getPiecesByCaseId(this.caseId).subscribe(res => {
      this.pieces = res;
      if(this.userRole === 'Commercial' || this.userRole === 'Responsable d\'agence') {
        this.pieces = this.pieces.filter(piece => piece.title !== 'Devis')
      }
    });
  }

  showPiecesManquantes() {
    this.sPieces.getPiecesManquantesByCaseId(this.caseId).subscribe(res => {
      this.piecesManquantes = res;
    });
  }

  showEdit() {
    this.showCross = !this.showCross;
  }


  deletePiece(id,reason, change,value) {
    if (confirm("Confirmer la suppression?")) {
      this.sCases.supprItemArray(this.pieces, id)
      this.sPieces.delete(id).subscribe(response => {
        this.sb.open(`Pièce supprimée`, '', { duration: 2000, verticalPosition: 'bottom', panelClass: ['delete-snackbar'] });

        this.pieces.slice(id)
        this.showPiecesManquantes()
      });
      this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change,value,this.sRoles.user.id).then(res => {
        if (res['error']) {
          alert(res);
        }
      });
    }
  }

  saveChange(piece, pieceId) {

    this.sPieces.editPiece(piece, pieceId).then(res => {
      if(res['error']) {
        return res.error;
      }
      this.showPieces();
    });
  }
  saveCase() {
    this.sCases.editCase(this.caseForm.value, this.caseId).then(res => {
      if(res['error']) {
        return res.error;
      }
      this.showCaseById();
    });
  }

  goToDetail(currentPiece) {
    if (this.userRole === 'Adv' || this.userRole === 'Admin') {
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['case-template', this.caseId], { state: { value: 'pieceEdit', currentPiece: currentPiece }  });
    }
    else if (this.userRole === 'Commercial' || this.userRole === 'Responsable d\'agence') {
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['commercial-template', this.caseId], { state: { value: 'pieceEdit', currentPiece: currentPiece, mobileChoice: true }  });
    }
    else if (this.userRole === 'Compta') {
      const url = this.router.createUrlTree(['detail-piece/', currentPiece])
      window.open(url.toString(), '_blank')
    }
  }


  showCaseById() {
    return new Promise(resolve => {

    this.sCases.getCaseById(this.caseId).subscribe(res => {
      this.case = res[0];
      resolve(res);
        }, error => {
          console.log(error);
      });
    });
  }
  addPieceManquante() {
    const dialogRef = this.dialog.open(ManquantePieceComponent, {
      width: '80vw',
      data :{
        caseId: this.caseId,
 }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.showCaseById();
      this.showPiecesManquantes()

    });
  }
}
