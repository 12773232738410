<div *ngIf="mobile" [ngClass]=" showBar ? 'commentOff' : 'commentOn'">
  <img (click)="showComment()" src="../../../assets/icons/languette.svg">
  <app-commentbar class="mobile-commentBar" [caseId]="caseId"></app-commentbar>


</div>
<app-commentbar class="mobile-commentBar" *ngIf="!mobile && !piecesAdd && !piecesList"
  [caseId]="caseId"></app-commentbar>


<app-loading *ngIf="!case"></app-loading>

<div class="custom-content">
  <button *ngIf="mobile" class="mt-6 pl-1" mat-button [routerLink]="['/commercial-customer-list']"><img
      src="../../../assets/icons/retour.svg">Retour</button>

  <img *ngIf="mobile" class="messagerie-icon" [routerLink]="['/messagerie']"
    src="../../../assets/bar-icons/messagerie.svg">
  <p *ngIf="mobile" class="compteur-messagerie">
    <span matBadge="{{ infosUnread.length }}" matBadgeSize="small"></span>
  </p>

  <img *ngIf="mobile" class="notification" [routerLink]="['/infos-advs']" src="../../../assets/icons/notification.svg">
  <p *ngIf="mobile" class="compteur">
    <span matBadge="{{ infosUnread.length }}" matBadgeSize="small"></span>
  </p>
  <img *ngIf="mobile" class="messagerie-icon" [routerLink]="['/messagerie']"
    src="../../../assets/bar-icons/messagerie.svg">
  <p *ngIf="mobile" class="compteur-messagerie">
    <span *ngIf="countUnread" matBadge="{{ countUnread.count }}" matBadgeSize="small"></span>
  </p>
  <div class="icons" *ngIf='case'>
    <a class="nav-item nav-link" [ngClass]=" rdvs ? 'active' : ''" style="font-size:0.8rem;" (click)="showRdvs()">
      <img src="../../../assets/bar-icons/rdvs.svg">

    </a>
    <a *ngIf="case.state != 'Prospection'" class="nav-item nav-link" style="font-size:0.8rem;"
      (click)="showPiecesList()">
      <img *ngIf="piecesList || piecesAdd " class="actif" src="../../../assets/bar-icons/pieces-active.svg">
      <img *ngIf="!piecesList && !piecesAdd" src="../../../assets/bar-icons/pieces.svg">
    </a>
  </div>

  <div *ngIf="case && !mobileChoice && !piecesAdd">
    <h1 class="case-title col-9 pl-1">{{ case.title }}</h1>
    <div class="d-flex">
      <h2 class="case-title col-12 pl-1">{{ case.customer.name | uppercase }} - {{ case.customer.mobile }} <a
          *ngIf="!mobile" class="infos" data-toggle="modal" data-target="#exampleModal1">Voir les infos client</a></h2>

    </div>
    <p class="commercial-infos col-9 pl-1">{{ case.commercial.username }}</p>
    <a class="col-9 pl-1" *ngIf="mobile" data-toggle="modal" data-target="#exampleModal1">Voir les infos client</a>

    <!-- POP UP -->
    <div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel1"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">X</button>
            <ul>
              <h3>Mr et Mme {{case.customer.name}}</h3>
              <section>
                <h4>Adresse</h4>
                <li>{{ case.customer.address }}</li>
                <li>{{case.customer.cp}} {{ case.customer.city }}</li>
              </section>
              <section>
                <h4>Numéros</h4>
                <li>{{ case.customer.mobile }}</li>
                <li>{{ case.customer.fixe }}</li>
                <li> <a href="mailto:{{case.customer.mail}}">{{case.customer.mail}}</a></li>
              </section>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <form [formGroup]="caseForm" class="d-flex form-states col-12 pl-1">
      <select class="custom-select-state rdv" *ngIf="!caseSignee" [ngClass]="case.state" formControlName="state"
        [(ngModel)]="case.state" value="case.state" (change)="changeState('edité','statut',case.state)">
        <option value="Prospection">Prospection</option>
        <option value="Négociation">Négociation</option>
        <option value="Signée">Signée</option>
        <option value="Non Signée">Non Signée</option>
        <option value="Annulée" style="display: none;">Annulée</option>
      </select>

      <div class=" custom-select-state rdv Signée" *ngIf="caseSignee">Signée</div>

      <form formGroupName="rdv_state" *ngIf="!caseSignee">
        <select class="custom-select-rdv rdv" [ngClass]="case.rdv_state.status != 'Oui' ? 'non-empty' : ''"
          formControlName="status" [(ngModel)]="case.rdv_state.status" value="case.rdv_state.status"
          (change)="saveChange('edité','rdv fait',case.rdv_state.status)">
          <option value="">RDV fait ?</option>
          <option value="Oui">Oui</option>
          <option value="Non pas trouvé">Non pas trouvé</option>
          <option value="Non refus visite">Non refus visite</option>
          <option value="Non porte">Non porte</option>
          <option value="Non annulé">Non annulé</option>
        </select>
      </form>

      <div class="dateSign" *ngIf="case.state == 'Annulée'"> Date d'annulation {{case.datecancel | date:'dd/MM/yy '}}
      </div>
      <div *ngIf="!mobile"><button class="btn-secondarycrm ml-4" style="padding: 1.7vh 3vw;"
          [routerLink]="['/book', case._id]">Commencer le book</button></div>

    </form>
  </div>


  <app-commercial-case-detail [events]="eventsSubject.asObservable()" [statut]="case.state"
    *ngIf="case && rdvs"></app-commercial-case-detail>
  <app-list-piece *ngIf="piecesList"></app-list-piece>
  <app-pieces-add *ngIf="piecesAdd"></app-pieces-add>
  <app-detail-piece [currentPiece]="currentPiece" *ngIf="pieceEdit"></app-detail-piece>
  <div class="col-10" *ngIf="case && (case.state === 'Négociation' || case.state === 'Signée')">
    <button *ngIf="piecesList" class="btn-secondarycrm" (click)="showPiecesAdd()">+ Nouvelle pièce</button>
  </div>

</div>

<!-- Button trigger modal -->
<button style="display: none;" type="button" id="openModalButton" class="btn btn-primary" data-toggle="modal"
  data-target="#exampleModal">
  Launch demo modal
</button>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Attention</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Pensez à entrer votre Fid
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="emitEventToChild()" aria-label="Close"
          data-dismiss="modal">Ajouter le rdv fid</button>
      </div>
    </div>
  </div>
</div>