<div *ngIf="v2" class="scroll">
  <div *ngFor="let product of selectedProducts">
        <!--   BT  -->
      <span *ngIf="product.name === 'Ballon thermodynamique'">{{ product.name }} {{ product.pose }} {{ product.capacite }} {{ product.unite }}
        <span *ngFor="let option of product.options_bt"> {{ option }}</span>
        </span>
        <!-- Mise aux normes -->
        <span *ngIf="product.name === 'Mise aux normes du tableau électrique'">{{ product.name }} {{ product.monotri }}</span>
          <!-- PAC Air/Air -->
        <span *ngIf="product.name === 'PAC Air/Air'">{{ product.name }} {{ product.puissance }} {{ product.type_pac }} {{ product.nombre }}
          <span *ngIf="product.type_pac != 'Gainable'">zones</span>
          <span *ngIf="product.type_pac === 'Gainable'">bouches</span>
          <span *ngFor="let option of product.options_pac"> {{ option }}</span>
        </span>
          <!-- PAC Air/Eau -->
        <span *ngIf="product.name === 'PAC Air/Eau'">{{ product.name }} {{ product.type_pac }} {{ product.monotri }}
          <span *ngFor="let option of product.options_pac"> {{ option }}</span>
        </span>
          <!-- PV -->
        <span *ngIf="product.name === 'Panneaux solaires'">
          <span *ngIf="product.nombre">{{ product.nombre }} {{ product.name }}</span> {{ product.puissance }} {{ product.pose }} {{ product.orientation }}
        </span>
        <!-- BATTERIE -->
        <span *ngIf="product.name === 'Batterie'">
          <span *ngIf="product.nombre">{{ product.nombre }}</span> {{ product.name }} {{ product.marque }} <span *ngIf='product.puissance'>{{ product.puissance }} kWc</span>
        </span>
        <span *ngIf="product.name === 'Isolation'">
          <span *ngIf="product.surface">{{ product.name }} {{ product.surface }} m²</span> {{ product.type_iso }}
        </span>
        <span *ngIf="product.name === 'VMC Double Flux'">
          <span>{{ product.name }}</span>
        </span>
        <span *ngIf="product.name === 'Adoucisseur d\'eau'">
          <span>{{ product.name }}</span>
        </span>
        <!-- Autres-->
        <span *ngIf="product.state && product.state === 'autre'">
          <span *ngFor="let option of product.options">
            <span class="subtitle">{{ product.name }} {{ option.option }} {{ option.value }}  &#160;</span>
          </span>
        </span>
  </div>
</div>
<div *ngIf="v1" class="scroll">
  {{ this.kit }}
</div>
