<a class="p-3" (click)="addPieceManquante()"
  *ngIf="this.sRoles.userRole === 'Adv' || this.sRoles.userRole === 'Responsable ADV' || this.sRoles.userRole === 'Admin'">Pièce
  manquante</a>

<div class="row">

  <mat-card style="opacity: 0.4;" class="col-xs-12 col-lg-3 col-sm-2" *ngFor="let piece of piecesManquantes"
    (click)="showCross || goToDetail(piece._id)">
    <img class="delete" src="../../../assets/icons/delete.svg"
      *ngIf="showCross && this.sRoles.userRole !== 'Commercial' && this.sRoles.userRole !== 'Responsable d\'agence'"
      (click)="deletePiece(piece._id,'supprimé','pièce',piece.title)">
    <div [ngClass]="showCross ? 'disabled' : ''">
      <img src="../../../assets/pieces/img-piece.png" class="card-img-top" alt="pieces">
      <p class="qualif" [ngClass]="{'nonconforme':piece.qualification === 'Manquante'}"
        style="opacity: 0.4;color: lightgray;">{{ piece.qualification }}</p>
      <mat-card-content>
        <p class="title" style="color: black;">{{ piece.title }}</p>
        <h1 style="position: absolute;
      font-size: 0.7em;
      right: 2%;
      bottom: -2%;"> {{piece.created_at |date: 'short'}}</h1>
        <p style="opacity: 0.4;" class="description" *ngIf="piece.description && !piece.commentary">{{ piece.description
          }}</p>
        <p class="description" style="opacity:0;" *ngIf="piece.description === null">v</p>
        <p class="description" *ngIf="piece.commentary != ''">{{piece.commentary}}</p>


      </mat-card-content>
    </div>
  </mat-card>

  <mat-card class="col-xs-12 col-lg-3 col-sm-2" *ngFor="let piece of pieces"
    (click)="showCross || goToDetail(piece._id)">
    <img class="delete" src="../../../assets/icons/delete.svg" *ngIf="showCross"
      (click)="deletePiece(piece._id,'supprimé','pièce',piece.title)">
    <div [ngClass]="showCross ? 'disabled' : ''">
      <img src="../../../assets/pieces/img-piece.png" class="card-img-top" alt="pieces">
      <p class="qualif" [ngClass]="{'attente':piece.qualification === 'En attente de qualification',
              'conforme':piece.qualification === 'Conforme',
              'nonconforme':piece.qualification === 'Non conforme'
            }">{{ piece.qualification }}</p>
      <mat-card-content>
        <p class="title">{{ piece.title }}</p>
        <h1 style="position: absolute;
    font-size: 0.7em;
    right: 2%;
    bottom: -2%;"> {{piece.created_at |date: 'short'}}</h1>
        <p class="description" *ngIf="piece.description && !piece.commentary">{{ piece.description }}</p>
        <p class="description" style="opacity:0;" *ngIf="piece.description === null">v</p>
        <p class="description" *ngIf="piece.commentary != ''">{{piece.commentary}}</p>


      </mat-card-content>
    </div>
  </mat-card>
</div>
<a [ngClass]="this.sRoles.userRole === 'Compta' ? 'd-flex justify-content-start' : 'd-flex justify-content-end'"
  (click)="showEdit()"
  *ngIf="this.sRoles.userRole !== 'Commercial' && this.sRoles.userRole !== 'Responsable d\'agence' && (pieces.length > 0 || piecesManquantes.length > 0)">Supprimer
  une pièce</a>