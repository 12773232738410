<section class="wrapper-fostrap">

  <div class="row mt-5">
    <mat-card class="col-xs-12 col-lg-2 col-sm-5" *ngFor="let prime of primes">
      <img class="delete" src="../../../../assets/icons/delete.svg"  *ngIf="showCross" (click)="deletePrime(prime._id)">

        <mat-card-content (click)="showCross || goToEditPrime(prime._id)">
          <h4 class="demande">Dossier {{ prime.type | uppercase }} </h4>
          <p class="name">{{ getFirstName(prime.product) }}</p>
          <p class="price">{{ prime.montant }} <span>euros</span> </p>
          <p>{{ prime.date_saisie | date:'dd/MM/yy' }}</p>
          <p class="qualif-card"
          [ngClass]="{'attente':prime.state === 'En cours' || 'Complément',
                      'conforme':prime.state === 'Acceptation' || 'Acompte' || 'Payé',
                      'nonconforme':prime.state === 'Refus'
                    }">
            {{ prime.state }}
          </p>
        </mat-card-content>
    </mat-card>
  </div>
</section>
<a class="delete-prime" (click)="showEdit()">Supprimer une demande</a>
