import { Component, Input, OnInit } from '@angular/core';
import { FormGroup,FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { PiecesService } from '@app/services/pieces.service';
import { HistoriqueService } from './../../services/historique.service';
import { RolesService } from '@app/services/roles.service';
import { HttpClient } from '@angular/common/http';
import { apiUrl} from '@environments/env';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsersService } from '@app/services/users.service';
import { CustomersService } from '@app/services/customers.service';

@Component({
  selector: 'app-case-cm-template',
  templateUrl: './case-cm-template.component.html',
  styleUrls: ['./case-cm-template.component.scss']
})
export class CaseCmTemplateComponent implements OnInit {
  state = null
  caseId;
  case;
  cmForm : FormGroup;
  caseForm: FormGroup;
  apiUrl = apiUrl.apiUrl + '/piece/';

  multipleImagesPieces;
  contrat;
  options = ['Prospection', 'Signée', 'Non Signée'];

  piecesList
  piecesAdd
  pieceEdit
  chantierList
  chantierDetail
  rdvs
  titrePiece = '';
  chantierId = '';
  currentPiece = '';
  editCustomer = false;
  showEditTitle = false;
  arrayAdvs = []
  constructor(
    private route: ActivatedRoute,
    private sCases: CasesService,
    private fb: FormBuilder,
    public sRoles: RolesService,
    private router: Router,
    public sUsers: UsersService,
    private sCustomers: CustomersService

  ) {
    this.caseId = this.route.snapshot.params['id'];
    if (this.router.getCurrentNavigation().extras.state) {
      this.chantierId = this.router.getCurrentNavigation().extras.state.chantierId;
      this.currentPiece = this.router.getCurrentNavigation().extras.state.currentPiece;

      if (this.router.getCurrentNavigation().extras.state.value === 'piecesList') {
        this.piecesList = true;
      }
      else if (this.router.getCurrentNavigation().extras.state.value === 'piecesAdd') {
        this.piecesAdd = true;
      }
      else if (this.router.getCurrentNavigation().extras.state.value === 'pieceEdit') {
        this.pieceEdit = true;
      }
      else if (this.router.getCurrentNavigation().extras.state.value === 'rdvs') {
        this.rdvs = true;
      }
      else if (this.router.getCurrentNavigation().extras.state.value === 'chantierDetail') {
        this.chantierDetail = true;
      }
    }
    else {
      this.rdvs = true;
    }
   }

  ngOnInit(): void {
    this.showCaseInfos()
    this.sUsers.getAllAdv().subscribe(res => {
      let advs = res['users'];
      this.arrayAdvs = advs.sort((a, b) => a.index - b.index);

    })

  }
  showCaseInfos() {
    return new Promise(resolve => {
    this.sCases.getCaseById(this.caseId).subscribe(res => {
      this.case = res[0];
      resolve(res);
      this.caseForm = this.fb.group({
        state : this.case.state,
        datesign: this.case.datesign,
        commercial: this.case.commercial
      });
        }, error => {
          console.log(error);
      });
      this.state = this.case.state
    });
  }
  saveChange(currentCase) {
    if(this.case.state === 'Terminé') {
      if(this.caseForm.value.datesign === null) {
        this.case.datesign = new Date()
      }
    }
    this.sCases.editCase(currentCase, this.caseId).then(res => {
      if(res['error']) {
        return res.error;
      }
      else {
      }
      this.showCaseInfos()
    });
  }

  showChantier() {
    this.piecesList = false;
    this.piecesAdd = false;
    this.pieceEdit = false;
    this.chantierList = true;
    this.chantierDetail = false;
    this.rdvs = false;
  }

  showAddPiece() {
    this.piecesList = false;
    this.piecesAdd = true;
    this.pieceEdit = false;
    this.chantierList = false;
    this.chantierDetail = false
    this.rdvs = false;
  }

  showPiecesList() {
    this.piecesList = true;
    this.pieceEdit = false;
    this.piecesAdd = false;
    this.chantierList = false;
    this.chantierDetail = false;
    this.rdvs = false;
  }

  toggleEditCustomer(Boolean){
    this.editCustomer = Boolean
  }
  saveCustomerChange(){
    this.sCustomers.editCustomer(this.case.customer, this.case.customer._id).then(res => {
      if(res['error']) {
        return res.error;
      }
      this.showCaseInfos();
    });
  }
}
