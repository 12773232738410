<div class="d-flex mt-4" *ngIf="case">
  <div class="choices mr-5">
    <p class="m-0">Paiement comptant en plus ?</p>
    <input type="radio" id="comptant1" name="comptant" [(ngModel)]="case.comptant" [value]=true (change)="saveChange('edité','paiement comptant en plus',case.comptant === true ? 'oui' : 'non')">
    <label for="comptant1" [ngClass]="case.comptant ? 'oui' : 'oui-empty'">
      <span class="material-icons">
      done
      </span>OUI</label>

    <input type="radio" id="comptant2" name="comptant" [(ngModel)]="case.comptant" [value]=false (change)="saveChange('edité','paiement comptant en plus',case.comptant === true ? 'oui' : 'non')">
    <label for="comptant2" [ngClass]="case.comptant ? 'non-empty' : 'non'">
      <span class="material-icons">
        clear
        </span>NON</label>
  </div>
  <div *ngIf="case.comptant" style='padding: 2% 0% 0% 1%; width: 9%;'>
    <mat-form-field style="width: 70%;">
      <input type="number" matInput placeholder="Montant" [(ngModel)]="case.comptant_montant" (change)="saveChange('edité','montant paiement comptant en plus',case.comptant_montant === null ? 'vide' : case.comptant_montant)">
    </mat-form-field>
  </div>

<div *ngIf="case.comptant" style='padding: 3% 3% 0% 3%;'>
    <input class="custom-date" placeholder="JJ/MM/AAAA xxhxx" [owlDateTime]="dt1" [(ngModel)]="case.comptant_date" [owlDateTimeTrigger]="dt1" (ngModelChange)="saveChange('edité','date paiement comptant en plus',case.comptant_date)">
    <owl-date-time firstDayOfWeek="1" #dt1></owl-date-time>
  </div>
</div>

<a class="p-3" (click)="showEdit()" *ngIf="financementsOk && financements.length > 0">Supprimer un financement</a>

<section *ngIf="financementsOk" class="wrapper-fostrap">
    <div class="row">
      <mat-card class="col-xs-12 col-lg-2 col-sm-5" *ngFor="let financement of financements" (click)="showCross || goToEditFinancement(financement._id)">
        <img class="delete" src="../../../../assets/icons/delete.svg" *ngIf="showCross" (click)="deleteFinancement(financement._id,'supprimé','demande de financement',financement.partenaire)">

          <mat-card-content    [ngClass]="showCross ? 'disabled' : ''" >
            <h4 class="demande">OP {{ financement.partenaire }}</h4>
            <p>{{ financement.created_at | date:'dd/MM/yy' }}</p>
            <p class="qualif-mairie"
            [ngClass]="{'attente':financement.state === 'En cours' || 'Complément',
                        'conforme':financement.state === 'Acceptation' || 'Acompte' || 'Payé',
                        'nonconforme':financement.state === 'Refus'
                      }">
              {{ financement.state }}
             </p>
          </mat-card-content>
      </mat-card>
    </div>

</section>


