import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrl} from '@environments/env';

@Injectable({
  providedIn: 'root'
})
export class HistoriqueService {
  url = apiUrl.apiUrl + '/historique/';
  //url = 'http://localhost:4000/historique/'
  headers = { 'Content-Type': 'application/json' };

  constructor(
    private http: HttpClient,
  ) { }

  getHistoriqueByCaseId(id) {
    return this.http.get(this.url + id);
  }

  getImportLeads() {
    return this.http.get(this.url + 'lead/communication');
  }


  createHistorique(caseId, customerId, reason,change,value,created_by): Promise<any> {

    let today = new Date()
    const histo =  {
      "caseId": caseId,
      "reason": reason,
      "change": change,
      "value" : value,
      "created_by": created_by,
      "created_at": today,
      "customerId": customerId
    }

    return this.http
      .post(this.url + 'add', JSON.stringify(histo), { headers: this.headers })
      .toPromise()
      .then(res => res as any);
  }

  createHistoriqueLead(caseId, customerId, reason,change,value,created_by): Promise<any> {

    let today = new Date()
    const histoLEAD =  {
      "caseId": caseId,
      "reason": reason,
      "change": change,
      "value" : value,
      "created_by": created_by,
      "created_at": today,
      "customerId": customerId,
      "lead": true
    }

    console.log("HISTO LEAD == ", histoLEAD)

    return this.http
      .post(this.url + 'add', JSON.stringify(histoLEAD), { headers: this.headers })
      .toPromise()
      .then(res => res as any);
  }

  delete(id) {
    return this.http.delete(this.url + id);
  }


}
