import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrl } from '@environments/env';

@Injectable({
  providedIn: 'root'
})
export class TasksGddService {

url = apiUrl.apiUrl + '/taskgdd/';
//url = 'http://localhost:4000/taskgdd/'

  headers = { 'Content-Type': 'application/json' };


  constructor(
    private http: HttpClient,
  ) { }

  create(TaskForm): Promise<any> {
    return this.http
      .post(this.url + 'add', JSON.stringify(TaskForm), { headers: this.headers })
      .toPromise()
      .then(res => res as any);
  }

  getAllTasks(){
    return this.http.get(this.url+ '')
  }

  getTaskById(id){
    return this.http.get(this.url + id)
  }
  getTaskGddByTargetId(Id){
    return this.http.get(this.url +'target/' + Id)
  }

  getTaskByCaseId(caseId){
    return this.http.get<any[]>(this.url +'case/' + caseId)
  }

  editTask(Task, id): Promise<any> {
    return this.http
      .put(this.url + id, JSON.stringify(Task), { headers: this.headers })
      .toPromise()
      .then(res => res as any);
  }

  deleteTask(id){
    return this.http.delete(this.url + id)
  }
}
