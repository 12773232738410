import { Role } from './../_models/role';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/_services';
import { RolesService } from '@app/services/roles.service';
import { PicturesService } from '@app/services/pictures.service';
import { HttpClient } from '@angular/common/http';
import { apiUrl } from '@environments/env';
import { TasksService } from '@app/services/tasks.service';
@Component({
  selector: 'app-bottombar',
  templateUrl: './bottombar.component.html',
  styleUrls: ['./bottombar.component.scss'],
})
export class BottombarComponent implements OnInit {
  user;
  currentPicture;
  showPicture = false;
  countUnread;

  tasks;
  constructor(
    private authenticationService: AuthenticationService,
    public sRoles: RolesService,
    private router: Router,
    private sPictures: PicturesService,
    private http: HttpClient,
    private sTasks: TasksService
  ) {
    this.authenticationService.user.subscribe((x) => (this.user = x));
    this.showTasksByTargetId();
  }

  get isCommercial() {
    return this.user && this.user.role === Role.Commercial;
  }
  get isRespCommercial() {
    return this.user && this.user.role === Role.RespCommercial;
  }

  get isTechnicien() {
    return this.user && this.user.role === Role.Technicien;
  }

  get isResponsableTech() {
    return this.user && this.user.role === Role.RespTechnicien;
  }

  get isAdmin() {
    return this.user && this.user.role === Role.Admin;
  }

  ngOnInit(): void {
    this.getPictureByUserId();
    this.getGroups();
  }

  logout() {
    this.authenticationService.logout();
  }

  getPictureByUserId() {
    this.sPictures.getPicturesByUserId(this.user.id).subscribe((res) => {
      const pp = res[0];
      if (pp != undefined) {
        this.currentPicture = pp.image;
      }
      if (this.currentPicture) {
        if (this.currentPicture.length > 0) {
          this.showPicture = true;
        }
      }
    });
  }

  goToCaseList() {
    this.router.navigate(['commercial-case-list']);
  }

  goToCustomerList() {
    this.router.navigate(['admin-customer-list']);
  }

  goToTasks() {
    this.router.navigate(['task-list']).then(() => {
      window.location.reload();
    });
  }

  goHome() {
    if (this.user.role === "Responsable d'agence") {
      this.router.navigate(['commercial-dashboard']);
    } else if (
      this.user.role === 'Responsable Technicien' ||
      this.user.role === 'Technicien'
    ) {
      this.router.navigate(['technicien-dashboard']);
    } else {
      this.router.navigate(['/' + this.user.role.toLowerCase() + '-dashboard']);
    }
  }
  async getGroups(): Promise<void> {
    try {
      const countUnread: any = await this.http
        .get(apiUrl.apiUrl + '/messages/count-unread')
        .toPromise();
      this.countUnread = countUnread;
    } catch (error) {}
  }

  showTasksByTargetId() {
    return new Promise((resolve) => {
      this.sTasks.getTaskByTargetId(this.user.id).subscribe(
        (res) => {
          this.tasks = res;
          resolve(res);
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }
}
