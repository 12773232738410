<img *ngIf="mobile" class="notification" [routerLink]="['/infos-advs']" src="../../../assets/icons/notification.svg">
<p *ngIf="mobile" class="compteur">
  <span matBadge="{{ infosUnread.length }}" matBadgeSize="small"></span>
</p>
<img *ngIf="mobile" class="messagerie-icon" [routerLink]="['/messagerie']"
  src="../../../assets/bar-icons/messagerie.svg">
<p *ngIf="mobile" class="compteur-messagerie">
  <span *ngIf="countUnread" matBadge="{{ countUnread.count }}" matBadgeSize="small"></span>
</p>

<ul class="d-flex custom-tab mt-5" *ngIf="!mobile && user.role === 'Responsable d\'agence' || user.role === 'Admin'">
  <li *ngFor="let commercial of commerciaux" [ngClass]="selectedId === commercial._id ? 'active-li' : '' "
    [value]=commercial.username (click)="showComm(commercial._id)">{{ commercial.username }}</li>
</ul>

<app-suivi-affaires *ngIf="!mobile" [userId]="selectedId"></app-suivi-affaires>

<div class="container-dashboard" *ngIf="!cumul">
  <div class="row bienvenue">
    <div class="col-9">
      <h4><strong>Pégase</strong> vous souhaite la bienvenue, <i>{{this.sRoles.user.username}}</i></h4>
    </div>
  </div>

  <div class="row">
    <div class="area">
      <h4>Votre performance</h4>

      <div class="row" style="height: 100%;">
        <div class="col-5 mt-5 pl-4 container-perf">
          <p class="month">{{ monthName | titlecase }}</p>
          <p>CA brut: {{ caNet + caBrut | currency:'EUR':true }}</p>
          <p>CA net: {{ caNet | currency:'EUR':true }}</p>
          <p>Taux d'annulation: {{ tauxAnnule | number:'2.1-2'}} %</p>
        </div>

        <div class="offset-1 col-5 mt-2 ">
          <round-progress *ngIf="pourcentage" class="graph" [current]="pourcentage" [max]='100' [semicircle]="true"
            [rounded]="true" [color]="color" [background]="'#B3DAE7'"></round-progress>
          <round-progress *ngIf="pourcentage === 0" class="graph" [current]="0" [max]='100' [semicircle]="true"
            [rounded]="true" [color]="color" [background]="'#B3DAE7'"></round-progress>
        </div>

      </div>
    </div>
    <div class="journey">
      <h4>Votre journée</h4>
      <div class="content-task">
        <div *ngFor="let rdv of arrayRdvs | sort:{ property: 'date', descending: false }" class="container-rdv">
          <p *ngIf="rdv.rdv.date" class="hour">{{ rdv.rdv.date | date:'shortTime' }}</p>
          <ul>
            <li *ngIf="rdv.rdv.type">{{ rdv.rdv.type }} {{ rdv.customer.name }}</li>
            <li *ngIf="!rdv.rdv.type">R1 {{ rdv.customer.name }}</li>
            <p>{{ rdv.customer.cp }} {{ rdv.customer.city }}</p>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="row three-card">
    <div class="sub-col" [ngClass]="user.role === 'Responsable d\'agence' ? 'da' : 'comm'" style="text-align: center;">
      <h4 (click)="goToAdminDashboard()">Cumul agence</h4>
      <a (click)="goToAdminDashboard()">Voir le détail de l'agence</a>
    </div>

    <div class="sub-col">
      <h4>Tâches</h4>
      <div class="content-task">
        <ul *ngFor="let task of tasks" class="row tasks-list">
          <li class="col-10">{{task.message}}</li>
          <li class="dot-task" [class.urgente]="task.state === '1-Urgente'"
            [class.encours]="task.state === '2-En-cours'" [class.faite]="task.state === '3-Faite'">
          </li>
        </ul>
      </div>
    </div>
    <div class="sub-col">
      <h4>Notifications</h4>
      <div class="content-task">
        <div *ngFor="let info of infosUnread">
          <div *ngIf="info.reason && info.reason === 'pieces'">
            <h5 class="mt-3">{{ info.created_by.username }}</h5>
            <p> à ajouté la pièce {{ info.comment }} le {{ info.created_at | date:'medium' }} pour le client <a
                *ngIf="info.caseId && info.customerId" [routerLink]="['/commercial-template/', info.caseId._id]"
                style="text-decoration: underline; color: orangered; font-size: 0.8rem;">{{ info.customerId.name }}</a>
            </p>
          </div>
          <div *ngIf="info.reason && info.reason === 'commentaire'">
            <h5 class="mt-3">{{ info.created_by.username }}</h5>
            <p>vous a mentionné sur un commentaire le {{ info.created_at | date:'medium' }} sur l'affaire de <a
                *ngIf="info.caseId && info.customerId" [routerLink]="['/commercial-template/', info.caseId._id]"
                style="text-decoration: underline; color: orangered;font-size: 0.8rem;">{{ info.customerId.name }}</a>
            </p>
          </div>
          <div *ngIf="info.reason && info.reason === 'like'">
            <h5 class="mt-3">{{ info.created_by.username }}</h5>
            <p>{{info.comment}} votre commentaire le {{ info.created_at | date:'medium' }} sur l'affaire de <a
                *ngIf="info.caseId && info.customerId" [routerLink]="['/commercial-template/', info.caseId._id]"
                style="text-decoration: underline; color: orangered;font-size: 0.8rem;">{{ info.customerId.name }}</a>
            </p>
          </div>
          <div *ngIf="info.reason && info.reason === 'signée'">
            <h5 class="mt-3">{{ info.created_by.username }}</h5>
            <p> à signé avec le client <a *ngIf="info.caseId && info.customerId"
                [routerLink]="['/commercial-template/', info.caseId._id]"
                style="text-decoration: underline; color: orangered;font-size: 0.8rem;">{{ info.customerId?.name }}</a>
            </p>
          </div>
          <div *ngIf="info.reason && info.reason === 'qualification'">
            <h5 class="mt-3">{{ info.created_by.username }}</h5>
            <p>à qualifé une pièce non conforme sur l'affaire de <a *ngIf="info.caseId && info.customerId"
                [routerLink]="['/commercial-template/', info.caseId._id]"
                style="text-decoration: underline; color: orangered;font-size: 0.8rem;">{{ info.customerId?.name }}</a>
              le {{ info.created_at | date:'medium' }} </p>
          </div>
          <div *ngIf="info.reason && info.reason === 'rappelsign'">
            <h5 class="mt-3">Rappel</h5>
            <p>{{ info.comment }} <a *ngIf="info.caseId && info.customerId"
                [routerLink]="['/commercial-template/', info.caseId._id]"
                style="text-decoration: underline; color: orangered;font-size: 0.8rem;">{{ info.customerId?.name }}</a>
            </p>
          </div>
          <div *ngIf="info.reason && info.reason === 'rappelsignDA'">
            <h5 class="mt-3">Rappel</h5>
            <p>{{ info.comment }} <a *ngIf="info.caseId && info.customerId"
                [routerLink]="['/commercial-template/', info.caseId._id]"
                style="text-decoration: underline; color: orangered;font-size: 0.8rem;">{{ info.customerId?.name }}</a>
            </p>
          </div>
          <div *ngIf="info.reason && info.reason === 'rappelprospection'">
            <h5 class="mt-3">Rappel</h5>
            <p>{{ info.comment }} <a *ngIf="info.caseId && info.customerId"
                [routerLink]="['/commercial-template/', info.caseId._id]"
                style="text-decoration: underline; color: orangered;font-size: 0.8rem;">{{ info.customerId?.name }}</a>
            </p>
          </div>
          <div *ngIf="info.reason && info.reason === 'annulée'">
            <h5>Affaire annulée</h5>
            <p>L'affaire de <a *ngIf="info.caseId && info.customerId"
                [routerLink]="['/commercial-template/', info.caseId._id]"
                style="text-decoration: underline; color: orangered;font-size: 0.8rem;">{{ info.customerId?.name }}</a>
              est passée en {{ info.comment }} </p>
          </div>
          <div *ngIf="info.reason && info.reason === 'piece_manquante'">
            <h5 class="mt-3">{{ info.created_by.username }}</h5>
            <p> {{ info.comment }} sur l'affaire <a *ngIf="info.caseId && info.customerId"
                [routerLink]="['/commercial-template/', info.caseId._id]"
                style="text-decoration: underline; color: orangered;font-size: 0.8rem;">{{ info.customerId?.name }}</a>
            </p>
          </div>
          <div *ngIf="info.reason && info.reason === 'rappelpiece'">
            <h5 class="mt-3">Rappel</h5>
            <p>{{ info.comment }} <a *ngIf="info.caseId && info.customerId"
                [routerLink]="['/commercial-template/', info.caseId._id]"
                style="text-decoration: underline; color: orangered;font-size: 0.8rem;">{{ info.customerId?.name }}</a>
            </p>
          </div>
          <div *ngIf="info.reason && info.reason === 'rappelpieceDA'">
            <h5 class="mt-3">Rappel</h5>
            <p>{{ info.comment }} <a *ngIf="info.caseId && info.customerId"
                [routerLink]="['/commercial-template/', info.caseId._id]"
                style="text-decoration: underline; color: orangered;font-size: 0.8rem;">{{ info.customerId?.name }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="cumul" style="width:120%;">
  <button mat-button (click)="cumul = !cumul"><img src="../../../assets/icons/retour.svg"
      style="margin-right:12%;">Retour</button>

  <div class="card global p-4">
    <div *ngIf="user.role == 'Admin' || user.role === 'Adv'" (click)="showAgency()"><img
        src="../../assets/icons/retour.svg">Retour</div>
    <div>
      <h3 class="perf-agence mb-4">Performance de l'agence {{agenceCases.agence}}</h3>
      <div class="row">
        <div class="col-lg-2 col-6">
          <span class="chiffre-agence">{{agenceCases.caBrutTotal | currency:'EUR':"symbol" }}</span>
        </div>
        <div class="col-lg-2 col-6">
          <span class="chiffre-agence">{{agenceCases.caNetTotal | currency:'EUR':"symbol"}}</span>
        </div>
        <div>
          <span class="chiffre-agence annulation">{{agenceCases.taux_annulation | number:'2.1-2'}}
            %</span>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-2 col-6">
          <p class="subtitle-agence">Chiffre d'affaire brut</p>
        </div>
        <div class="col-lg-2 col-6">
          <p class="subtitle-agence">Chiffre d'affaire net</p>
        </div>
        <div>
          <p class="subtitle-agence annulation">Taux d'annulation</p>
        </div>
      </div>
    </div>
  </div>

  <div class="row">

    <div *ngFor="let commercial of agenceCases.commerciaux" class="col-xs-5 col-lg-4 col-sm-12 col-m-12">
      <div class="card">
        <div class="card-content agency">
          <img class="pp" src="{{getProfil(commercial._id)}}">

          <div class="offset-4" *ngIf="commercial?.commercial">
            <h3>{{getLastName(commercial.commercial)}}</h3>
            <h4>{{getFirstName(commercial.commercial)}}</h4>
          </div>

          <div class="offset-4">
            <span>CA Brut: </span> {{ commercial.caBrut | currency:'EUR':"symbol" }}
          </div>
          <div class="offset-4">
            <span>CA net: </span> {{ commercial.caNet | currency:'EUR':"symbol" }}
          </div>
          <div class="offset-4">
            <span>Taux d'annulation: </span>{{ commercial.taux_annulation | number:'2.1-2'}} %
          </div>
          <div class="d-flex justify-content-end" style="cursor: pointer;" (click)="toggleList(commercial._id)">
            <mat-icon *ngIf="!commercialStates[commercial._id]">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="commercialStates[commercial._id]">keyboard_arrow_up</mat-icon>
            ({{commercial.affaires.length}})
          </div>
        </div>
        <ul class="list" *ngIf="commercialStates[commercial._id]"
          [ngClass]="{'expanded': commercialStates[commercial._id]}">
          <li *ngFor="let case of commercial.affaires" class="pb-2 pl-4">
            <b style="color:#212529;">{{case.customer}}</b>
            <br>
            signée le: {{case.datesign | date:'shortDate' }}
            <br>
            {{case.price_ht | currency:'EUR':"symbol" }}
          </li>
        </ul>
      </div>
    </div>

    <div *ngFor="let commercial of commerciauxZero" class="col-xs-5 col-lg-4 col-sm-12 col-m-12"
      [routerLink]="['/admin-user-stat',commercial._id]">
      <div class="card">
        <div class="card-content agency">
          <img class="pp" src="{{getProfil(commercial._id)}}">

          <div class="offset-4" *ngIf="commercial?.username">
            <h3>{{getLastName(commercial.username)}}</h3>
            <h4>{{getFirstName(commercial.username)}}</h4>
          </div>

          <div class="offset-4">
            <span>CA Brut: </span> {{ 0 | currency:'EUR':"symbol" }}
          </div>
          <div class="offset-4">
            <span>CA net: </span> {{ 0 | currency:'EUR':"symbol" }}
          </div>
          <div class="offset-4">
            <span>Taux d'annulation: </span>{{ 0 | number:'2.1-2' }} %
          </div>
        </div>
      </div>
    </div>

  </div>
</div>