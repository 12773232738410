import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrl } from '@environments/env';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CasesService {

  url = apiUrl.apiUrl + '/case/';
  //url = 'http://localhost:4000/case/'

  headers = { 'Content-Type': 'application/json' };
  cases;

  constructor(
    private http: HttpClient,
  ) { }

  getCasesByDate(date: string, year?: number): Observable<any> {
    let params: any = { date };

    if (year) {
      params.year = year;
    }
    return this.http.get(this.url + 'cases/byDate', { params });
  }

  getCasesByAdv(date: string, year?: number): Observable<any> {
  let params: any = { date };
  if (year) {
    params.year = year;
  }
  return this.http.get(this.url + 'cases/byAdv', { params });
  }


  getAllCases() {
    return this.http.get(this.url + "cases");
  }
  getAllCasesForAdmin() {
    return this.http.get(this.url + "cases/admin");
  }
  /*   getAllCm() {
      return this.http.get(this.url + "cases/compta/cm");
    } */
  getAllCm(year) {
    return this.http.get(this.url + "cases/compta/cm/" + year);
  }
  getComptaCases() {
    return this.http.get(this.url + "cases/compta")
  }
  getAllCasesByCommercialId(id) {
    return this.http.get(this.url + 'commercial/' + id);
  }
  getCasesForCommercialTrackSignee(id) {
    return this.http.get(this.url + 'commercial/track/signee/' + id);
  }
  getCasesForCommercialTrack(id) {
    return this.http.get(this.url + 'commercial/track/' + id);
  }
  getAllCasesSigneAndAnnuleByCommercialId(id) {
    return this.http.get(this.url + 'commercial/state/' + id);
  }
  getIsHiddenFalseByCommercialId(id) {
    return this.http.get(this.url + 'commercial/hidden/' + id);
  }

  getAllCasesByCustomerId(id) {
    return this.http.get(this.url + 'customer/' + id);
  }
  getAllCasesByAdvId(id) {
    return this.http.get(this.url + 'adv/' + id);
  }

  getAllCasesByAgency(agency) {
    return this.http.get(this.url + 'agency/' + agency);

  }

  getAllCasesByTmkId(id) {
    return this.http.get(this.url + 'tmk/' + id);
  }

  getAllCasesByCustomerAndCommercialId(id, commercialId) {
    return this.http.get(this.url + 'customer/' + id + '/' + commercialId);
  }

  getAllTmkCases() {
    return this.http.get(this.url + "tmkcases")
  }

  getAllTmkActiveCases() {
    return this.http.get(this.url + "tmkcases/affectation")
  }

  getCaseById(id) {
    return this.http.get(this.url + id);
  }

  createCase(CaseForm): Promise<any> {
    return this.http
      .post(this.url + 'add', JSON.stringify(CaseForm), { headers: this.headers })
      .toPromise()
      .then(res => res as any);
  }

  editCase(CaseForm, id): Promise<any> {
    return this.http
      .put(this.url + id, JSON.stringify(CaseForm), { headers: this.headers })
      .toPromise()
      .then(res => res as any);
  }

  // COMMISSIONS COMMERCIAUX
  addValueInCase(CaseForm, id): Promise<any> {
    return this.http
      .put(this.url + 'addForced/' + id, JSON.stringify(CaseForm), { headers: this.headers })
      .toPromise()
      .then(res => res as any);
  }

  deleteValueInCase(CaseForm, id): Promise<any> {
    return this.http
      .put(this.url + 'rmForced/' + id, JSON.stringify(CaseForm), { headers: this.headers })
      .toPromise()
      .then(res => res as any);
  }

  editForcedFiInCase(CaseForm, id): Promise<any> {
    return this.http
      .put(this.url + 'editAnnuleFi/' + id, JSON.stringify(CaseForm), { headers: this.headers })
      .toPromise()
      .then(res => res as any);
  }


  addRdv(RdvForm, id): Promise<any> {
    return this.http
      .put(this.url + 'rdv/' + id, JSON.stringify(RdvForm), { headers: this.headers })
      .toPromise()
      .then(res => res as any);
  }

  addLikeInComment(arrayComments, caseId) {
    return this.http
      .put(this.url + 'comments/like/' + caseId, JSON.stringify(arrayComments), { headers: this.headers })
      .toPromise()
      .then(res => res as any);
  }

  deleteRdv(rdvToDelete, id): Promise<any> {
    return this.http
      .put(this.url + 'rdv/delete/' + id, JSON.stringify(rdvToDelete), { headers: this.headers })
      .toPromise()
      .then(res => res as any);
  }
  delete(id) {
    return this.http.delete(this.url + id);
  }
  deleteCaseCustomer(id) {
    return this.http.delete(this.url + "customer/" + id);
  }

  addComment(CommentForm, id): Promise<any> {
    return this.http
      .put(this.url + 'comments/' + id, JSON.stringify(CommentForm), { headers: this.headers })
      .toPromise()
      .then(res => res as any);
  }

  deleteComment(commentToDelete, id): Promise<any> {
    return this.http
      .put(this.url + 'comment/delete/' + id, JSON.stringify(commentToDelete), { headers: this.headers })
      .toPromise()
      .then(res => res as any);
  }
  getCaseByState() {
    return this.http.get(this.url + "cases/signee")
  }
  getCaseByCancelState() {
    return this.http.get(this.url + "cases/annulee")
  }

  getAdminTableCases() {
    return this.http.get(this.url + "cases/table");
  }

  supprItemArray(array, id) {
    for (let index = 0; index < array.length; index++) {
      if (array[index]._id == id) {
        array.splice(index, 1)
      }
    }
  }

  getCaseWithChantier() {
    return this.http.get(this.url + "cases/chantiers/");
  }

  getCasesByFinancement(name) {
    return this.http.get(this.url + "financement/" + name);
  }

  getCancelCasesByFinancement(name) {
    return this.http.get(this.url + "financement/cancel/" + name);
  }
  getCaseWithChantierForNSerie() {
    return this.http.get(this.url + "cases/chantiers/serie");
  }

  getCasesForGdd() {
    return this.http.get(this.url + "cases/gdd");
  }

  updateCasesCustomer(cases, customerId): Observable<any> {
    const casesIds = cases.map(c => c._id);
    return this.http.put(this.url + 'cases/update-customer', { casesIds, customerId }, { headers: this.headers });
  }

}

