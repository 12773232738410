
<h3 *ngIf="tasksWarning.length > 0">Tâches GDD</h3>
      <div>
        <div>
          <div class="tsk" *ngFor="let tsk of tasksGdd">
            <div class="row" (click)="editTaskGddDialog(tsk)">
              <div class="col-2">
                <p class="mb-0" style="font-size: 1.9vh;">{{ tsk.title }}</p>
              </div>
              <div class="col-8 text-center">
                <span style="color: #a5a5a5ab;">Date limite {{ tsk.end_date | date:'short' }} </span>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="clock">
                  <path d="M7.01477 0.34375C3.26868 0.34375 0.233521 3.37891 0.233521 7.125C0.233521 10.8711 3.26868 13.9062 7.01477 13.9062C10.7609 13.9062 13.796 10.8711 13.796 7.125C13.796 3.37891 10.7609 0.34375 7.01477 0.34375ZM12.921 7.125C12.921 10.3789 10.2687 13.0312 7.01477 13.0312C3.76086 13.0312 1.10852 10.4062 1.10852 7.125C1.10852 3.89844 3.73352 1.21875 7.01477 1.21875C10.2413 1.21875 12.921 3.87109 12.921 7.125ZM8.8468 9.55859C8.98352 9.66797 9.20227 9.61328 9.2843 9.47656L9.5304 9.17578C9.63977 9.01172 9.58508 8.82031 9.44836 8.71094L7.5343 7.31641V3.29688C7.5343 3.13281 7.37024 2.96875 7.20618 2.96875H6.82336C6.63196 2.96875 6.49524 3.13281 6.49524 3.29688V7.67188C6.49524 7.78125 6.52258 7.86328 6.60461 7.94531L8.8468 9.55859Z" fill="#A5A5A5"/>
                </svg>
              </div>

              <div class="col-2 text-right" style="font-size: 1.9vh;">
                <span class="state" [ngClass]="{'pe': tsk.state === 'En cours', 'oui' : tsk.state === 'Terminé'}">{{ tsk.state }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

  <div class="task-body">
      <h3 *ngIf="tasksWarning.length > 0">Tâches en retard</h3>
      <button *ngIf="suppreTask.length>0" class="btn-trash ml-4" (click)="supprTaskArray()">
        <img src="../../../assets/icons/trash.svg">
      </button>

      <div class="custom-row" *ngFor="let task of tasksWarning">

        <div class="leftRow">
          <input type="checkbox" [(value)]='task._id' (change)="$event.target.checked? (isfrmChecked = true) : isfrmChecked = false;
          componentMethodName($event,isfrmChecked);">
          <div class="row-body">

            <strong>{{task.message}}</strong>
            <span *ngIf="task.tag && task.tag != 'CEE' && task.tag != 'MPR'" [ngClass]="{
              dossier: task.tag === 'Dossier' || task.tag === undefined,
              raccordement: task.tag === 'Raccordement',
              primexel: task.tag === 'Primexel',
              CM: task.tag === 'CM'}"> {{ task.tag }}</span>
            <span *ngIf="task.tag && task.tag === 'CEE'" class="cee"> Prime {{ task.tag | lowercase }}</span>
            <span *ngIf="task.tag && task.tag === 'MPR'" class="mpr"> Prime {{ task.tag }}</span>
            <div class="d-flex">
              <div class="mr-5">
              Tâche créée par : {{task.created_by.username}}
              </div>
              <div>
                Tâche affectée à : {{task.target.username}}
              </div>
              <div>
                <a class="edit" *ngIf="currentUser.id === task.created_by._id || this.currentUser.role === 'Admin'" (click)="editTaskDialog(task)">Modifier</a>
                <a class="edit" *ngIf="currentUser.id !== task.created_by._id && this.currentUser.role != 'Admin'" (click)="editTaskDialog(task)">Commenter</a>
              </div>
            </div>

            </div>

        </div>

        <select [ngClass]="{'non': task.state === '1-Urgente', 'pe' : task.state === '2-En-cours', 'oui' : task.state === '3-Faite' }" class="dot" [(ngModel)]='task.state' (change)='editTask(task, task._id)'>
          <option value="1-Urgente"><span class="dot">Urgente</span>
          </option>
          <option  value="2-En-cours"><span class="dot">En cours</span>
          </option>
          <option  value="3-Faite"><span class="dot" >Faite</span>
          </option>
        </select>

        </div>
        <h3 *ngIf="uncheckedTasks.length > 0">Tâches en cours</h3>

    <div class="custom-row" *ngFor="let task of uncheckedTasks">

      <div class="leftRow">
        <input type="checkbox" [(value)]='task._id' (change)="$event.target.checked? (isfrmChecked = true) : isfrmChecked = false;
        componentMethodName($event,isfrmChecked);">
        <div class="row-body">

          <strong>{{task.message}}</strong>
          <span *ngIf="task.tag && task.tag != 'CEE' && task.tag != 'MPR'" [ngClass]="{
            dossier: task.tag === 'Dossier' || task.tag === undefined,
            raccordement: task.tag === 'Raccordement',
            primexel: task.tag === 'Primexel',
            CM: task.tag === 'CM'}"> {{ task.tag }}</span>
          <span *ngIf="task.tag && task.tag === 'CEE'" class="cee"> Prime {{ task.tag | lowercase }}</span>
          <span *ngIf="task.tag && task.tag === 'MPR'" class="mpr"> Prime {{ task.tag }}</span>
          <div class="d-flex">
            <div class="mr-5">
            Tâche créée par : {{task.created_by.username}}
            </div>
            <div>
              Tâche affectée à : {{task.target.username}}
            </div>
            <div>
              <a class="edit" *ngIf="currentUser.id === task.created_by._id || this.currentUser.role === 'Admin'" (click)="editTaskDialog(task)">Modifier</a>
              <a class="edit" *ngIf="currentUser.id !== task.created_by._id && this.currentUser.role != 'Admin'" (click)="editTaskDialog(task)">Commenter</a>
            </div>
          </div>

          </div>

      </div>

      <select [ngClass]="{'non': task.state === '1-Urgente', 'pe' : task.state === '2-En-cours', 'oui' : task.state === '3-Faite' }" class="dot" [(ngModel)]='task.state' (change)='editTask(task, task._id)'>
        <option value="1-Urgente"><span class="dot">Urgente</span>
        </option>
        <option  value="2-En-cours"><span class="dot">En cours</span>
        </option>
        <option  value="3-Faite"><span class="dot" >Faite</span>
        </option>
      </select>

    </div>
  <br>
</div>
