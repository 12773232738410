<h1 mat-dialog-title>
  {{ title }}
</h1>

<div mat-dialog-content class="contain-message">
  <p>{{ message }}</p>
</div>

<div class="contain-sub_message">
  <strong>
    {{ sub_message }}
  </strong>
</div>

<div mat-dialog-actions class="contain-button" *ngIf="message != 'Vous allez effectuer un bon d\'intervention'">
  <button (click)="onDismiss()" class="btn-secondarycrm">Annuler</button>
  <button (click)="onConfirm()" class="btn-tertiarycrm">Confirmer</button>
</div>
<div mat-dialog-actions class="contain-button" *ngIf="message == 'Vous allez effectuer un bon d\'intervention'">
  <button (click)="onDismiss()" class="btn-secondarycrm" >Non</button>
  <button (click)="onConfirm()" class="btn-tertiarycrm">Oui</button>
</div>
