<mat-horizontal-stepper [linear]="true" #stepper>
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup">
      <div>
        <h6>Tableau électrique</h6>

        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Désignation du local de pose</label>
            <input class="custom-input" type="text" formControlName="local_pose" (change)="getCurrentKitAndSaveChange(this.firstFormGroup.value.local_pose,'local_pose')">
            <div *ngIf="firstFormGroup.get('local_pose').invalid && firstFormGroup.get('local_pose').errors && (firstFormGroup.get('local_pose').dirty || firstFormGroup.get('local_pose').touched)">
              <small class="text-danger"
                  *ngIf="firstFormGroup.get('local_pose').hasError('required')">
                  Champ requis
              </small>
            </div>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 title-margin">
            <label>Nombre Inter-différentiel 30mA</label>
            <div class="increment">
              <button
                class="rm"
                mat-button
                mat-icon-button
                matPrefix
                aria-label="Remove"
                (click)="incrementValueDiff(-_step, currentKit.type,'edité', 'inter_differentiel', currentKit.inter_differentiel)">
                <mat-icon>remove</mat-icon>
              </button>
              <input type="number"[value]="currentKit.inter_differentiel" />
              <button
                class="add"
                mat-button
                mat-icon-button
                matSuffix
                aria-label="Add"
                (click)="incrementValueDiff(_step, currentKit.type,'edité', 'inter_differentiel', currentKit.inter_differentiel)"
                >
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 title-margin">
            <label>Nombre de modules en réserve</label>
            <div class="increment">
              <button
                class="rm"
                mat-button
                mat-icon-button
                matPrefix
                aria-label="Remove"
                (click)="incrementValueModule(-_step, currentKit.type,'edité', 'module_reserve', currentKit.module_reserve)">
                <mat-icon>remove</mat-icon>
              </button>
              <input type="number"[value]="currentKit.module_reserve" />
              <button
                class="add"
                mat-button
                mat-icon-button
                matSuffix
                aria-label="Add"
                (click)="incrementValueModule(_step, currentKit.type,'edité', 'module_reserve', currentKit.module_reserve)"
                >
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>

        </div>

        <div class="row">

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Hauteur du compteur</label>
            <mat-form-field>
              <mat-select formControlName="hauteur_compteur" (selectionChange)="addOption(currentKit.type,'edité', 'hauteur_compteur', 'hauteur_compteur', firstFormGroup.value.hauteur_compteur)">
                <mat-option *ngFor="let tech of techs" [value]="tech">
                  {{tech}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col title-margin radio-choice">
            <mat-label>Prise de service dans le tableau :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="prise_service" (change)="getCurrentKitAndSaveChange($event.value,'prise_service')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('prise_service')">Non</mat-radio-button>
            </mat-radio-group>

          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col title-margin radio-choice">
            <mat-label>Abonnement Réseau </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="abonnement_reseau" (change)="getCurrentKitAndSaveChange($event.value,'abonnement_reseau')">
              <mat-radio-button value="Monophasé">Monophasé</mat-radio-button>
              <mat-radio-button value="Triphasé" class="ml-4">Triphasé</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col title-margin radio-choice">
            <mat-label>Puissance Abonnement Réseau </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="puissance_abonnement_reseau" (change)="getCurrentKitAndSaveChange($event.value,'puissance_abonnement_reseau')">
              <mat-radio-button value="6kVA">6kVA</mat-radio-button>
              <mat-radio-button value="9kva" class="ml-4">9kva</mat-radio-button>
              <mat-radio-button value="12kva" class="ml-4">12kva</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>


        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col title-margin radio-choice">
            <mat-label>Signalisation SAV Enerconfort : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="signalisation" (change)="getCurrentKitAndSaveChange($event.value,'signalisation')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('signalisation')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col title-margin radio-choice">
            <mat-label>Contrôle complet des serrages des câbles :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="serrage_cable" (change)="getCurrentKitAndSaveChange($event.value,'serrage_cable')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('serrage_cable')">Non</mat-radio-button>
            </mat-radio-group>

          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col title-margin radio-choice">
            <mat-label>Contrôle du bon fonctionnement de tous
              les éléments raccordés au tableaux :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="raccord_tableau" (change)="getCurrentKitAndSaveChange($event.value,'raccord_tableau')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('raccord_tableau')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col title-margin radio-choice">
            <mat-label>Repérage nominatif des disjoncteurs : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="reperage" (change)="getCurrentKitAndSaveChange($event.value,'reperage')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('reperage')">Non</mat-radio-button>
            </mat-radio-group>

          </div>
        </div>

        <div class="row">

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col title-margin radio-choice">
            <mat-label>Nettoyage chantier:</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="nettoyage" (change)="getCurrentKitAndSaveChange($event.value,'nettoyage')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('nettoyage')">Non</mat-radio-button>
            </mat-radio-group>

          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col title-margin radio-choice">
            <mat-label>Prise de recharge véhicule électrique protégée par inter-différentiel de type A</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="prise_vehicule_protege" (change)="getCurrentKitAndSaveChange($event.value,'prise_vehicule_protege')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('prise_vehicule_protege')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col title-margin mb-5">
            <mat-label>Plaque de cuisson protégée par inter-différentiel de type A</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="plaque_protege" (change)="getCurrentKitAndSaveChange($event.value,'plaque_protege')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('plaque_protege')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col title-margin radio-choice">
            <mat-label>Lave-linge protégé par inter-différentiel de type A</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="linge_protege" (change)="getCurrentKitAndSaveChange($event.value,'linge_protege')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('linge_protege')">Non</mat-radio-button>
            </mat-radio-group>

          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col title-margin mb-5">
            <mat-label>Les circuits prises sont répartis sur au moins 2
              inter-différentiel différents</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="circuit_prises" (change)="getCurrentKitAndSaveChange($event.value,'circuit_prises')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('circuit_prises')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col title-margin radio-choice">
            <mat-label>Les circuits d’éclairage sont répartis sur au
              moins 2 inter-différentiel différents</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="circuit_eclairage" (change)="getCurrentKitAndSaveChange($event.value,'circuit_eclairage')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('circuit_eclairage')">Non</mat-radio-button>
            </mat-radio-group>

          </div>
        </div>

      </div>
    </form>

    <button class="btn-secondarycrm" matStepperNext [style.opacity]="!firstFormGroup.valid ? '0.4' : '1'">Suivant</button>

  </mat-step>

  <mat-step>

    <textarea class="custom-textarea" placeholder="Observations" [(ngModel)]="currentKit.observation" (change)="getCurrentKitAndSaveChange('observation',case.observation)"></textarea>

    <ng-template matStepLabel>Done</ng-template>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Tableau electrique
        <label class="upload-label" for="mante" *ngIf="!mante"><mat-icon>add</mat-icon><span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="mante"></p>
        <input accept=".jpg,.jpeg,.png" multiple type="file" name="mante" id="mante" class="upload-box" (change)="selectMultiple($event, 'Tableau electrique')">
      </div>

    </div>

    <button *ngIf="!isValid" (click)="valid()" class="btn-secondarycrm">Confirmer</button>
  </mat-step>
</mat-horizontal-stepper>
