<p (click)="goBack()" class="mt-5">Retour</p>

<h3 class="mt-5">Rendez-vous en attente de qualification</h3>
<div class="content">
  <mat-spinner *ngIf="!show"></mat-spinner>
  <div class="row">
      <div class="col-xs-5 col-lg-4 col-sm-4" *ngFor="let case of sortData()">
          <div class="card" (click)="openDialog(case)">
              <div class="card-content">
                <p class="mb-0"><strong>Date du R1</strong></p>
                <p>{{case.r1[0].date | date:'short'}}</p>
                <p class="mb-0"><strong>Titre</strong></p>
                <p>{{case.title}}</p>
                <p class="state" [ngClass]="case.state">{{ case.state }}</p>
              </div>
          </div>
      </div>
    </div>
</div>
<p *ngIf="attentes.length == 0 && show">Aucune affaire à qualifier pour le moment</p>

