<img style="
position: relative;
    left: 97%;
    width: 6%;
    cursor: pointer;" src="../../../assets/icons/cross.svg" (click)="closeModale()">
<mat-tab-group class="col-layout main-body" (focusChange)="changeCurrentKit($event)">
  <mat-tab [label]="tab.name" *ngFor="let tab of tabs" (click)="changeCurrentKit()">
    <ng-template matTabContent>
      <div class="mt-4">
        <div *ngIf="tab.name === 'Panneaux solaires'">
          <div class="row ml-1">
            <mat-form-field style="width:10%">
              <mat-label>Nombre</mat-label>
              <input [(ngModel)]="currentKit.nombre" matInput placeholder="Nombre" type="number"
                (change)="addOption(tab.name,'edité', 'nombre', 'PV', currentKit.nombre)">
            </mat-form-field>
          </div>

          <div class="row">
            <mat-form-field class="col-7 custom-padding">
              <mat-select [(ngModel)]="currentKit.puissance"
                (selectionChange)="addOptionRadio(tab.name,'edité', 'puissance', 'PV', $event.value)"
                placeholder="Sélectionner la puissance">
                <mat-option value="500 WC">500 WC</mat-option>
                <mat-option value="375 WC">375 WC</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row ml-1">
            <mat-radio-group (change)="addOptionRadio(tab.name,'edité', 'pose', 'PV', selectedPose)"
              [(ngModel)]="selectedPose">
              <mat-radio-button value="Sur imposition">Sur imposition</mat-radio-button>
              <mat-radio-button value="Intégration">Intégration</mat-radio-button>
              <mat-radio-button value="Sol">Au sol</mat-radio-button>

            </mat-radio-group>
          </div>


          <div class="row">
            <mat-form-field class="col-7 custom-padding">
              <mat-select [(ngModel)]="currentKit.orientation"
                (selectionChange)="addOption(tab.name,'edité', 'orientation', 'PV', $event.value)"
                placeholder="Orientation">
                <mat-option class="col-10" value="Portrait">Portrait</mat-option>
                <mat-option class="col-10" value="Paysage">Paysage</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <!-- VMC -->
        <div *ngIf="tab.name === 'VMC Double Flux'">

        </div>
        <!--BT -->
        <div *ngIf="tab.name === 'Ballon thermodynamique'">
          <div class="row">
            <mat-form-field class="col-10">
              <mat-select [(ngModel)]="currentKit.capacite"
                (selectionChange)="addOption(tab.name,'edité', 'capacite', 'Ballon thermodynamique', $event.value)"
                placeholder="Sélectionner la capacité">
                <mat-option class="col-10" value="200 L">200 L</mat-option>
                <mat-option class="col-10" value="250 L">250 L</mat-option>
                <mat-option class="col-10" value="270 L">270 L</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row ml-1">
            <mat-radio-group
              (change)="addOptionRadio(tab.name,'edité', 'unite', 'Ballon thermodynamique', selectedUnite)"
              [(ngModel)]="selectedUnite">
              <mat-radio-button value="Unité intérieure">Unité intérieure</mat-radio-button>
              <mat-radio-button class="ue" value="Unité extérieure">Unité extérieure</mat-radio-button>
            </mat-radio-group>
          </div>


          <div class="row ml-1">
            <div>
              <p>Option(s)</p>
              <mat-checkbox [checked]="isDalle" (change)="addOptionBt(tab.name,$event, 'options_bt')"
                class="example-margin" value="Dalle béton">Dalle béton</mat-checkbox>
              <mat-checkbox [checked]="isGainage" (change)="addOptionBt(tab.name,$event, 'options_bt')"
                class="example-margin" value="Gainage du ballon">Gainage du ballon</mat-checkbox>
              <mat-checkbox [checked]="isDepose" (change)="addOptionBt(tab.name,$event, 'options_bt')"
                class="example-margin" value="Dépose chaudière">Dépose chaudière</mat-checkbox>
            </div>
          </div>

        </div>


        <!-- Batterie -->

        <div *ngIf="tab.name === 'Batterie'">
          <div class="row ml-1">
            <mat-form-field class="example-full-width">
              <mat-label>Nombre</mat-label>
              <input [(ngModel)]="currentKit.nombre" matInput placeholder="Nombre" type="number"
                (change)="addOption(tab.name,'edité', 'nombre', 'Batterie', currentKit.nombre)">
            </mat-form-field>
          </div>

          <div class="row ml-1">
            <mat-form-field class="example-full-width">
              <mat-label>Marque</mat-label>
              <input [(ngModel)]="currentKit.marque" matInput placeholder="marque" type="text"
                (change)="addOption(tab.name,'edité', 'marque', 'Batterie', currentKit.marque)">
            </mat-form-field>
          </div>

          <div class="row ml-1">
            <mat-form-field class="example-full-width">
              <mat-label>Puissance</mat-label>
              <input [(ngModel)]="currentKit.puissance" matInput placeholder="puissance" type="number"
                (change)="addOption(tab.name,'edité', 'puissance', 'Batterie', currentKit.puissance)">
              <p matSuffix>kWc</p>

            </mat-form-field>
          </div>

          <div class="row ml-1">
            <mat-radio-group (change)="addOptionRadio(tab.name,'edité', 'backup', 'Batterie', selectedBackup)"
              [(ngModel)]="selectedBackup">
              <mat-radio-button value="avec Backup">avec Backup</mat-radio-button>
              <mat-radio-button class="ue" value="sans Backup">sans Backup</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <!-- Mise aux normes du tableau électrique -->

        <div *ngIf="tab.name === 'Mise aux normes du tableau électrique'">

          <div class="row ml-1">
            <mat-radio-group
              (change)="addOptionRadio(tab.name,'edité', 'monotri', 'Mise aux normes du tableau électrique', selectedMonotri)"
              [(ngModel)]="selectedMonotri">
              <mat-radio-button value="Monophasé">Monophasé</mat-radio-button>
              <mat-radio-button value="Triphasé">Triphasé</mat-radio-button>
            </mat-radio-group>
          </div>

        </div>


        <!-- PAC A/E -->
        <div *ngIf="tab.name === 'PAC Air/Eau'">

          <div class="row">
            <mat-form-field class="col-7 custom-padding">
              <mat-select [(ngModel)]="currentKit.puissance"
                (selectionChange)="addOptionRadio(tab.name,'edité', 'puissance', 'Pac Air/Eau', $event.value)"
                placeholder="Sélectionner la puissance">
                <mat-option value="8 Kva">8 Kva</mat-option>
                <mat-option value="11 Kva">11 Kva</mat-option>
                <mat-option value="14 Kva">14 Kva</mat-option>
                <mat-option value="16 Kva">16 Kva</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row ml-1">
            <mat-radio-group (change)="addOptionRadio(tab.name,'edité', 'type_pac', 'PAC Air/Eau', selectedTypePac)"
              [(ngModel)]="selectedTypePac">
              <mat-radio-button value="Chauffage seul">Chauffage seul</mat-radio-button>
              <mat-radio-button value="Chauffage + ECS">Chauffage + ECS</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="row ml-1">
            <mat-radio-group
              (change)="addOptionRadio(tab.name,'edité', 'monotri', 'Mise aux normes du tableau électrique', selectedMonotri)"
              [(ngModel)]="selectedMonotri">
              <mat-radio-button value="Monophasé">Monophasé</mat-radio-button>
              <mat-radio-button value="Triphasé">Triphasé</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="row ml-1">
            <div>
              <p>Option(s)</p>
              <mat-checkbox [checked]="isDalle" (change)="addOptionPac(tab.name,$event, 'options_pac')"
                value="Dalle béton">Dalle béton</mat-checkbox>
              <mat-checkbox [checked]="isThermo" (change)="addOptionPac(tab.name,$event, 'options_pac')"
                value="Thermostat radio">Thermostat radio</mat-checkbox>
              <mat-checkbox [checked]="isDepose" (change)="addOptionPac(tab.name,$event, 'options_pac')"
                class="example-margin" value="Dépose chaudière">Dépose chaudière</mat-checkbox>
            </div>
          </div>

        </div>


        <!-- PAC A/A -->
        <div *ngIf="tab.name === 'PAC Air/Air'">

          <div class="row">
            <mat-form-field class="col-7 custom-padding">
              <mat-select [(ngModel)]="currentKit.puissance"
                (selectionChange)="addOptionRadio(tab.name,'edité', 'puissance', 'Pac Air/Air', $event.value)"
                placeholder="Sélectionner la puissance">
                <mat-option value="3 Kva">3 Kva</mat-option>
                <mat-option value="3,5 Kva">3,5 Kva</mat-option>
                <mat-option value="4 Kva">4 Kva</mat-option>
                <mat-option value="4,5 Kva">4,5 Kva</mat-option>
                <mat-option value="5 Kva">5 Kva</mat-option>
                <mat-option value="6,8 Kva">6,8 Kva</mat-option>
                <mat-option value="8,8 Kva">8,8 Kva</mat-option>
                <mat-option value="9,4 Kva">9,4 Kva</mat-option>
                <mat-option value="10 Kva">10 Kva</mat-option>
                <mat-option value="11,6 Kva">11,6 Kva</mat-option>
                <mat-option value="12,1 Kva">12,1 Kva</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="col-4 offset-1 custom-padding">
              <mat-select [(ngModel)]="currentKit.nombre"
                (selectionChange)="addOptionRadio(tab.name,'edité', 'nombre', 'Pac Air/Air', $event.value)"
                [placeholder]="selectedTypePac === 'Split' ? 'Nombre de zones' : 'Nombre de bouches'">
                <mat-option value="1">1</mat-option>
                <mat-option value="2">2</mat-option>
                <mat-option value="3">3</mat-option>
                <mat-option value="4">4</mat-option>
                <mat-option value="5">5</mat-option>
                <mat-option value="6">6</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row ml-1">
            <mat-radio-group (change)="addOptionRadio(tab.name,'edité', 'type_pac', 'PAC Air/Air', selectedTypePac)"
              [(ngModel)]="selectedTypePac">
              <mat-radio-button value="Gainable">Gainable</mat-radio-button>
              <mat-radio-button value="Split">Split</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="row ml-1">
            <div>
              <p>Option(s)</p>
              <mat-checkbox [checked]="isDalle" (change)="addOptionPac(tab.name,$event, 'options_pac')"
                value="Dalle béton">Dalle béton</mat-checkbox>
              <mat-checkbox [checked]="isThermo" (change)="addOptionPac(tab.name,$event, 'options_pac')"
                value="Thermostat radio">Thermostat radio</mat-checkbox>
              <mat-checkbox [checked]="isDepose" (change)="addOptionPac(tab.name,$event, 'options_pac')"
                class="example-margin" value="Dépose chaudière">Dépose chaudière</mat-checkbox>
            </div>
          </div>

        </div>

        <!-- Isolation -->

        <div *ngIf="tab.name === 'Isolation'">

          <div class="row ml-1">
            <mat-radio-group (change)="addOptionRadio(tab.name,'edité', 'type_iso', 'Isolation', selectedTypeIso)"
              [(ngModel)]="selectedTypeIso">
              <mat-radio-button value="En rouleaux">En rouleaux</mat-radio-button>
              <mat-radio-button value="Soufflée">Soufflée</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="row ml-1">
            <mat-form-field style="width:25%">
              <mat-label>Surface de l'isolation</mat-label>
              <input [(ngModel)]="currentKit.surface" matInput placeholder="Nombre" type="number"
                (change)="addOption(tab.name,'edité', 'surface', 'Isolation', currentKit.surface)">
              <p matSuffix>m²</p>
            </mat-form-field>
          </div>

        </div>

        <div *ngIf="tab.state === 'autre'">
          <button (click)="rmProduct()" class="btn-deletecrm">Supprimer</button>
          <ul *ngFor="let option of currentKit.options">
            <li>{{option.option}} - {{ option.value }}</li>
          </ul>

        </div>
      </div>

    </ng-template>

  </mat-tab>

  <mat-tab label="+">
    <form [formGroup]="empForm" (ngSubmit)="onSubmit()">

      <div formArrayName="products">

        <div *ngFor="let product of products().controls; let empIndex=index">
          <div [formGroupName]="empIndex">
            <mat-form-field class="product-name">
              <input matInput placeholder="Renseigner le nom du produit" formControlName="name">
            </mat-form-field>

            <div formArrayName="options">
              <div *ngFor="let option of productOptions(empIndex).controls; let optionIndex=index">
                <div [formGroupName]="optionIndex">
                  <h6 class="mb-0">Option {{optionIndex + 1}}:</h6>

                  <mat-form-field class="example-full-width">
                    <input matInput type="text" placeholder="Puissance" formControlName="option">
                  </mat-form-field>

                  <mat-form-field class="example-full-width ml-5">
                    <input matInput type="text" placeholder="1,5 kWc" formControlName="value">
                  </mat-form-field>

                  <img src="../../../assets/icons/cross.svg" (click)="removeProductOption(empIndex,optionIndex)"
                    style="width: 5%; cursor:pointer;">

                </div>
              </div>

              <button type="button" class="btn-secondarycrm" (click)="addProductOption(empIndex)">Ajouter une
                option</button>
            </div>
          </div>

        </div>
      </div>
      <p>
        <button type="submit" class="btn-secondarycrm validate" *ngIf="add">Valider</button>
      </p>
    </form>
    <p>
      <button type="button" class="btn-secondarycrm" (click)="addProduct()" *ngIf="!add">Ajouter un produit</button>
    </p>

  </mat-tab>
</mat-tab-group>