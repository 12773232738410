<h2 class="mt-5" *ngIf="customer">{{customer.name }}</h2>
<a *ngIf="customer">{{ customer.mail }}</a>

<div class="content">
  <div class="row">
      <div class="col-xs-5 col-lg-4 col-sm-4" *ngFor="let case of cases; let index = index; let isFirst = first;">
          <div class="card">
              <div class="card-content" [routerLink]="['/logistique-stock',this.case._id]">
                <p><strong>Kit vendu</strong></p>
                <!-- <p class="kit-signe">{{ case.kits_vendus }} <strong *ngIf="case.kits_vendus === null">Aucun kit vendu (CM)</strong></p> -->
                <app-product-card [kit]="case.kits_vendus"></app-product-card>


                <button class="btn-tertiarycrm" (click)="showPieces(case._id)">Voir la VT</button>
              </div>
          </div>
      </div>
    </div>
</div>
