import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { HistoriqueService } from '@app/services/historique.service';
import { PiecesService } from '@app/services/pieces.service';
import { RolesService } from '@app/services/roles.service';

@Component({
  selector: 'app-logistique-stock',
  templateUrl: './logistique-stock.component.html',
  styleUrls: ['./logistique-stock.component.scss']
})
export class LogistiqueStockComponent implements OnInit {

  caseId
  case
  selectedProducts;
  products = []



  tabs;
  currentKit = {
    nombre: '',
    puissance: '',
    orientation: '',
    capacite: '',
    unite: '',
    pose: '',
    marque: '',
    monotri: '',
    monitoring: '',
    options_bt: [],
    options_pac: [],
    type_pac: '',
    type_iso : '',
    options: [],
    name : '',
    surface: '',
    modules: [],
    onduleurs: [],
    type: '',
    type_bt:'',
    declivite: '',
    onduleur: '',
    marque_type_onduleur: '',
    n_serie_onduleur: '',
    nbr_onduleur: '',
    n_serie_ue: '',
    n_serie_ui: '',
    n_serie_caisson: '',
    n_serie: '',
    splits: '',
    ue: '',
    valid: null,

  }

  v2 = false

  pacaaFormGroup:FormGroup;
  btFormGroup:FormGroup;
  vmcFormGroup:FormGroup;
  adoucisseurFormGroup:FormGroup;

  add = false
  techs = ["azert","cdcd","jhg","poiuy","awaw",]

  modules = []
  onduleurs = []
  module_selected;
  pieces;
  currentKitValid = false

  techPieces
  displayPieces
  constructor(
    private route: ActivatedRoute,
    private sCases: CasesService,
    private _formBuilder: FormBuilder,
    private sPieces: PiecesService,
    private router: Router,
    private sb: MatSnackBar,
  ) {
    this.caseId = this.route.snapshot.params['id'];

    this.showCaseById().then(res => {
      this.tabs = JSON.parse(this.case.kits_vendus).filter(element => element.active)
      const kit = this.tabs

      const index = kit.findIndex(item => item.name === kit[0].name);
      const selected = kit[index]
      this.currentKit = selected
        if(this.case.kits_vendus != null && this.case.kits_vendus.startsWith('[')) {
          JSON.parse(this.case.kits_vendus).forEach(element => {
            if(element.active) {
              this.products.push(element.name)
            }
          });
          /////////////////////////////////////
          const array1 = this.products;
          const parseKits = JSON.parse(this.case.kits_vendus)

          parseKits.forEach(element => {
          // si un produit du kit est dans le tableau selectionner
          if(array1.includes(element.name)) {
            // on vient éditer l'object associé dans products en active true
            let result = parseKits.filter(e => e.name === element.name)[0]
            if(!result.active) {
              parseKits.filter(e => e.name === element.name)[0].active = true
            }
          }
          else {
            parseKits.filter(e => e.name === element.name)[0].active = false
          }
          });
          this.selectedProducts = JSON.stringify(parseKits)
          this.selectedProducts = JSON.parse(this.selectedProducts).filter(res => res.active)

          const array = JSON.parse(this.case.kits_vendus);
          this.checkPv(array)
          this.checkCurrentKitValid()
        }
        this.pacaaFormGroup = this._formBuilder.group({
          n_serie_ue: [this.currentKit.n_serie_ue, Validators.required],
        });
        this.vmcFormGroup = this._formBuilder.group({
          n_serie_caisson: [this.currentKit.n_serie_caisson, Validators.required],
        });
        this.adoucisseurFormGroup = this._formBuilder.group({
          n_serie: [this.currentKit.n_serie, Validators.required],
        });
        this.btFormGroup = this._formBuilder.group({
          n_serie_ue: [this.currentKit.n_serie_ue, Validators.required],
          n_serie_ui: [this.currentKit.n_serie_ui, Validators.required],

        });

        this.showPieceByCaseId()
    });

   }

   ngOnInit(): void { }

  showCaseById() {
    return new Promise(resolve => {

    this.sCases.getCaseById(this.caseId).subscribe(res => {
      this.case = res[0];
      resolve(res);
        }, error => {
          console.log(error);
      });
    });
  }


  showPieceByCaseId(){
    return new Promise(resolve => {

    this.sPieces.getTechPiecesByCaseId(this.caseId).subscribe(res => {
      this.techPieces = res;
      resolve(res);
        }, error => {
          console.log(error);
      });
    });
  }

  showPieces(caseId) {
    this.sPieces.getLastSpecificPieceByTitle(caseId, 'VT').subscribe(res => {
      this.pieces = res[0];

      if(this.pieces && this.pieces._id != undefined) {
        setTimeout(() => {
          this.router.navigate(['detail-piece', this.pieces._id])
        }, 500);
      }
      else {
        this.sb.open(`Aucune VT Conforme trouvée`, '', { duration: 2000, verticalPosition: 'bottom', panelClass: ['delete-snackbar'] });
      }

    });
  }

  changeCurrentKit(changeEvent: MatTabChangeEvent) {
    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === changeEvent.tab.textLabel);
    const selected = array[index]
    this.currentKit = selected
    this.checkPv(array)
    this.checkCurrentKitValid()
  }

  checkCurrentKitValid() {
    if(this.currentKit.type === 'VMC Double Flux') {

      if(this.currentKit.n_serie_caisson != undefined && this.currentKit.n_serie_caisson != '') {
          this.currentKitValid = true
          this.saveValid( this.currentKitValid,'valid')
        }
      else {
        this.currentKitValid = false
        this.saveValid( this.currentKitValid,'valid')
      }

    }
    if(this.currentKit.type === 'Adoucisseur d\'eau') {
      if(this.currentKit.n_serie != undefined && this.currentKit.n_serie != '') {
          this.currentKitValid = true
          this.saveValid( this.currentKitValid,'valid')
        }
      else {
        this.currentKitValid = false
        this.saveValid( this.currentKitValid,'valid')
      }

    }
    if(this.currentKit.type === 'Panneaux solaires') {
      const validModules = this.currentKit.modules.every(item => item.numero);
      const validOnduleurs = this.currentKit.onduleurs.every(item => item.numero);

       if(validModules && validOnduleurs) {
       this.currentKitValid = true
       this.saveValid( this.currentKitValid,'valid')
      }
      else {
        this.currentKitValid = false
        this.saveValid( this.currentKitValid,'valid')
      }
    }

    if(this.currentKit.type === 'Ballon thermodynamique') {
      if(this.currentKit.n_serie_ue != undefined && this.currentKit.n_serie_ue != '' &&
      this.currentKit.n_serie_ui != undefined && this.currentKit.n_serie_ui != '') {
       this.currentKitValid = true
        this.saveValid( this.currentKitValid,'valid')
      }
      else {
        this.currentKitValid = false
        this.saveValid( this.currentKitValid,'valid')
      }
    }
  }


  checkPv(array) {
    let objIndex = array.findIndex((obj => obj.type == this.currentKit.type));

    setTimeout(() => {
      if(this.currentKit.type === 'Panneaux solaires') {
        let difference = 0
        if(this.currentKit.modules.length === 0) {
          // on init les modules
          for (let index = 0; index < parseInt(this.currentKit.nombre); index++) {
              this.modules.push({"name": (index + 1), "numero": ''});
              this.onduleurs.push({"name": (index + 1), "numero": ''});

              this.currentKit.modules = this.modules
              this.currentKit.onduleurs = this.onduleurs

              array[objIndex].modules = this.modules
              array[objIndex].onduleurs = this.onduleurs

          }
        }
        else if(this.currentKit.modules.length === parseInt(this.currentKit.nombre) ){
          // même nombre

        }
        else {
          // pas même nombre

          // prendre la différence nouveau nombre et length
          // utiliser ce nombre pour spliter le tableau d'objet des 2 derniers objets
          if(this.currentKit.modules.length > parseInt(this.currentKit.nombre) ) {
            // plus de modules que le nombre

            difference = this.currentKit.modules.length - parseInt(this.currentKit.nombre)
            this.currentKit.modules.reverse().splice(0,difference)
            this.currentKit.onduleurs.reverse().splice(0,difference)

            array[objIndex].modules = this.currentKit.modules
            array[objIndex].onduleurs = this.currentKit.onduleurs

          }
          else {

            difference = parseInt(this.currentKit.nombre) - this.currentKit.modules.length
             for (let index = this.currentKit.modules.length  ; index < parseInt(this.currentKit.nombre) ; index++) {
              array[objIndex].modules.push({name: '', numero: ''});
              array[objIndex].onduleurs.push({name: '', numero: ''});

            }
            this.reloadCurrentRoute()

          }
        }
        this.case.kits_vendus = JSON.stringify(array)
        this.sCases.editCase(this.case, this.case._id).then(res => console.log(res))
      }
    }, 600);
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
  }
  addOption(name,reason, option,product, value) {

    let find = JSON.parse(this.case.kits_vendus).find(x => x.name === name);
    find[option] = value;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === name);

    const selected = array[index]
    this.currentKit = selected
    selected[option] = value

    this.case.kits_vendus = JSON.stringify(array)

    this.sCases.editCase(this.case, this.case._id).then(res => console.log(res))

  }

  editModule(module_name,value, array_name) {

    const array = JSON.parse(this.case.kits_vendus);

    const index = array.findIndex(item => item.name === this.currentKit.type);
    array[index] = this.currentKit
    array[index][array_name].find(x => x.name === module_name);
    let curr = array[index][array_name].find(x => x.name === module_name)
    curr['numero'] = value

    this.case.kits_vendus = JSON.stringify(array)
    this.sCases.editCase(this.case, this.case._id).then(res => console.log(res))
    this.checkCurrentKitValid()

  }

  editSplit(module_name,value, array_name) {

    const array = JSON.parse(this.case.kits_vendus);

    const index = array.findIndex(item => item.name === this.currentKit.type);

    array[index] = this.currentKit
    array[index][array_name].find(x => x.name === module_name);
    let curr = array[index][array_name].find(x => x.name === module_name)
    curr['numero'] = value

    this.case.kits_vendus = JSON.stringify(array)
    this.sCases.editCase(this.case, this.case._id).then(res => console.log(res))
  }

  getCurrentKitAndSaveChange(event,key) {

    this.currentKit[key] = event;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === this.currentKit.type);

    array[index] = this.currentKit

    this.case.kits_vendus = JSON.stringify(array)

    this.sCases.editCase(this.case, this.case._id).then(res => console.log(res))
    this.checkCurrentKitValid()
  }

  saveValid(event,key) {

    this.currentKit[key] = event;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === this.currentKit.type);

    array[index] = this.currentKit
    const selected = array[index]
    if(this.currentKitValid)
      selected.valid = true
    else {
      selected.valid = false

    }
    this.case.kits_vendus = JSON.stringify(array)

    this.sCases.editCase(this.case, this.case._id).then(res => console.log(res))
  }

  createSortieStock() {
    this.router.navigate(['logistique-visualize',this.case._id]);

  }

  showTechPieces() {
    this.displayPieces = !this.displayPieces
  }

  GoToDetailPiece(pieceId){
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['detail-piece', pieceId],)
  }

}
