<img
style="
position: relative;
    left: 97%;
    width: 6%;
    cursor: pointer;"
src="../../../assets/icons/cross.svg" (click)="closeModale()">
<form [formGroup]="caseForm">

  <div class="row">
    <div class="col-12">
      <input
        formControlName="title"
        id="title"
        type="text"
        class="form-control mb-4 pb-4 pt-4"
        placeholder="Titre" required>
      <div class="alert alert-danger" *ngIf="!caseForm.get('title').valid && caseForm.get('title').touched">
        <div *ngIf="!caseForm.get('title').valid && caseForm.get('title').touched">Un titre est requis</div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6" style="display: none;">
      <input
          formControlName="customer"
          [(ngModel)]="data.currentCustomer._id"
          id="name"
          type="text"
          class="form-control"
          placeholder="Id" required>
    </div>

    <div class="col-6">
      <input
          readonly
          [value]="currentCustomer.name"
          id="name"
          type="text"
          class="form-control"
          placeholder="NOM Prénom" required>
    </div>
    <div class="col-6">
      <input
          readonly
          [value]="currentCustomer.address"
          id="address"
          type="text"
          class="form-control"
          placeholder="Adresse" required>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <input
          readonly
          [value]="currentCustomer.cp"
          id="name"
          type="number"
          class="form-control"
          placeholder="cp" required>
    </div>

    <div class="col-6">
      <input
          readonly
          [value]="currentCustomer.city"
          id="address"
          type="text"
          class="form-control"
          placeholder="Ville" required>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <input
          readonly
          [value]="currentCustomer.mobile"
          id="name"
          type="number"
          class="form-control"
          placeholder="mobile" required>
    </div>

    <div class="col-6">
      <input
          readonly
          [value]="currentCustomer.fixe"
          id="address"
          type="number"
          class="form-control"
          placeholder="fixe" required>
    </div>
  </div>

  <div class="row">
    <div formGroupName="tmkClient" class="col-6">
      <input
          readonly
          [value]="currentCustomer.mail"
          id="mail"
          type="text"
          class="form-control"
          placeholder="E-mail">
    </div>

    <div formGroupName="r1" class="col-6">
      <input
          formControlName="date"
          id="date"
          class="form-control empty-date"
          placeholder="JJ/MM/AAAA xxhxx"
          [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" required>
          <owl-date-time firstDayOfWeek="1" #dt1></owl-date-time>
      <div class="alert alert-danger" *ngIf="!caseForm.get('r1.date').valid && caseForm.get('r1.date').touched">
        <div *ngIf="!caseForm.get('r1.date').valid && caseForm.get('r1.date').touched">Une date de rdv est requise</div>
      </div>
    </div>
    </div>

    <div class="row">
      <div class="col-12">
        <input
          formControlName="description"
          id="description"
          type="text"
          class="form-control description mb-2"
          [ngClass]="this.sRoles.userRole === 'Adv' || 'Admin' ? 'form-control description-adv' : 'form-control description'"
          placeholder="Débrief">
      </div>
    </div>

    <div class="row">
      <div class="basic-container col-6" tabindex="-1" #focusMe *ngIf="caseForm.value.type === 'Vente' ">
        <mat-form-field class="example-full-width" *ngIf="this.sRoles.userRole === 'Adv' || this.sRoles.userRole === 'Admin' || this.sRoles.userRole === 'Responsable ADV'">
          <mat-select type="text" placeholder="Choisir le commercial" matInput formControlName="commercial">
            <mat-option *ngFor="let commercial of commerciaux" [value]="commercial._id">
              {{ commercial.username }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="basic-container col-6" tabindex="-1" #adv *ngIf="caseForm.value.type === 'Contrat de maintenance' ">
        <mat-form-field class="example-full-width" *ngIf="this.sRoles.userRole === 'Adv' || this.sRoles.userRole === 'Admin' || this.sRoles.userRole === 'Responsable ADV'">
          <mat-select type="text" placeholder="Choisir le commercial" matInput formControlName="commercial">
            <mat-option *ngFor="let adv of advs" [value]="adv._id">
              {{ adv.username }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="basic-container col-6">
        <mat-form-field class="example-full-width" *ngIf="this.sRoles.userRole === 'Adv' || this.sRoles.userRole === 'Admin' || this.sRoles.userRole === 'Responsable ADV'">
          <mat-select type="text" placeholder="Type" matInput formControlName="type">
            <mat-option *ngFor="let type of types" [value]="type">
              {{ type }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>


<!--   <mat-form-field appearance="fill" *ngIf="this.currentRole == 'Admin' || this.currentRole == 'Adv' || this.currentRole == 'Responsable ADV'" >
    <mat-label>Commercial</mat-label>
    <mat-select formControlName="commercial">
      <mat-option *ngFor="let commercial of commerciaux" [value]="commercial._id">{{ commercial.username }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" *ngIf="this.currentRole == 'Admin' || this.currentRole == 'Adv' || this.currentRole == 'Responsable ADV'" >
    <mat-label>Adv</mat-label>
    <mat-select formControlName="adv">
      <mat-option *ngFor="let adv of advs" [value]="adv._id">{{ adv.username }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" *ngIf="this.currentRole == 'Admin' || this.currentRole == 'Adv' || this.currentRole == 'Responsable ADV'"  >
    <mat-label>TMK</mat-label>
    <mat-select formControlName="tmk">
      <mat-option *ngFor="let tmk of tmks" [value]="tmk._id">{{ tmk.username }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" *ngIf="this.currentRole == 'Admin' || this.currentRole == 'Adv' || this.currentRole == 'Responsable ADV'"  >
    <input matInput  formControlName="description" placeholder="description" required>
  </mat-form-field> -->

<!--         <form formGroupName="r1">
          <label>Date du 1er rdv</label>
          <mat-form-field appearance="fill">
            <input matInput formControlName="date" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2">
            <owl-date-time #dt2></owl-date-time>

          </mat-form-field>
        </form> -->

              <button (click)="addCase()" class="btn-secondarycrm" *ngIf="caseForm.valid">Enregistrer</button>
</form>
