import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-part2',
  templateUrl: './part2.component.html',
  styleUrls: ['./part2.component.scss']
})
export class Part2Component implements OnInit, AfterViewInit {

  caseId

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.caseId = this.route.snapshot.params['id'];

  }

  ngAfterViewInit() {
    const bars = document.querySelectorAll('.bar');
    bars.forEach((bar, index) => {
      setTimeout(() => {
        bar.classList.add('visible', `delay-${index + 1}`);
      }, index * 500); // Adjust the delay as needed
    });
  }

}
