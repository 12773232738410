import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrl} from '@environments/env';

@Injectable({
  providedIn: 'root'
})
export class ChantierService {

  url = apiUrl.apiUrl + '/chantier/';
  //url = 'http://localhost:4000/chantier/';

  headers = { 'Content-Type': 'application/json',
'Access-Control-Allow-Origin' : '*' };


  constructor( private http: HttpClient,) { }


  createChantier(Chantier): Promise<any> {
    return this.http
      .post(this.url + 'add', JSON.stringify(Chantier), { headers: this.headers })
      .toPromise()
      .then(res => res as any);
  }

  getChantierById(id) {
    return this.http.get(this.url + id);
  }

  getChantierByCaseId(id) {
    return this.http.get(this.url+"case/" + id);
  }

  getAllChantierByTechId(id){
    return this.http.get(this.url+"tech/" + id);
  }

  getLastChantier(id){
    return this.http.get(this.url+"/lastDate/case/"+id);
  }

  editChantier(ChantierForm, id): Promise<any> {
    return this.http
      .put(this.url + id, JSON.stringify(ChantierForm), { headers: this.headers })
      .toPromise()
      .then(res => res as any);
  }

  editChantierTech(ChantierForm, id): Promise<any> {
    return this.http
      .put(this.url + 'chantierr/' + id, JSON.stringify(ChantierForm), { headers: this.headers })
      .toPromise()
      .then(res => res as any);
  }

  delete(id) {
    return this.http.delete(this.url + id);
  }
}
