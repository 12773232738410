<app-commentbar [caseId]="caseId"></app-commentbar>
<app-loading *ngIf="!case"></app-loading>

<div class="icons" *ngIf='case'>
  <a class="nav-item nav-link " style="font-size:0.8rem;" (click)="showPiecesList()">
    <img *ngIf="piecesList || piecesAdd || pieceEdit"  class="actif" src="../../../assets/bar-icons/pieces-active.svg">
    <img *ngIf="!piecesList && !piecesAdd && !pieceEdit" src="../../../assets/bar-icons/pieces.svg">
  </a>

  <a class="nav-item nav-link" style="font-size:0.8rem;" (click)="showChantier()">
    <img *ngIf="chantierDetail || chantierList"  class="actif" src="../../../assets/bar-icons/travaux-active.svg">
    <img *ngIf="!chantierDetail && !chantierList" src="../../../assets/bar-icons/travaux.svg">
  </a>
</div>

<div *ngIf="!pieceEdit && !piecesAdd">
  <div class="d-flex">
    <h2>{{ case.customer.name }} - {{ case.customer.mobile }}</h2>
    <a data-toggle="modal" data-target="#exampleModal" class="show-client"(click)="toggleEditCustomer(false)">Voir les infos client</a>
    <img src="../../../assets/icons/historique.svg" *ngIf="this.sRoles.user.role === 'Admin' || this.sRoles.user.id === '5ff22a8ba0f2570004f151d0'" class="historique" [routerLink]="['/case-historique', case._id]">
  </div>
  <div class="d-flex">
    <h2 *ngIf="!showEditTitle">{{ case.title }}
      <mat-icon (click)="showEditTitle = !showEditTitle">edit</mat-icon>
    </h2>
    <h2 class="ml-4" style="color: var(--primarycrm);">{{ this.case.customer.origin }}</h2>
  </div>
    <form [formGroup]="caseForm" *ngIf="showEditTitle">
      <input class="title-input" type="text" formControlName="title" [(ngModel)]="case.title" (change)="saveChange('edité','titre de l\'affaire',case.title)">
      <mat-icon (click)="showEditTitle = !showEditTitle">close</mat-icon>

    </form>
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div *ngIf="!editCustomer"  class="modal-body">
        <ul>
          <h3>Mr et Mme {{case.customer.name}}</h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">X</button>
          <section>
          <h4>Adresse</h4>
          <li>{{ case.customer.address }}</li>
          <li>{{case.customer.cp}} {{ case.customer.city }}</li>
          </section>
          <section>
          <h4>Numéros</h4>
          <li>{{ case.customer.mobile }}</li>
          <li>{{ case.customer.fixe }}</li>
          <li> <a href="mailto:{{case.customer.mail}}">{{case.customer.mail}}</a></li>
          </section>
        </ul>
        <button class="btn-secondarycrm editCustomer" (click)="toggleEditCustomer(true)">Modifier les informations</button>
      </div>
      <div *ngIf="editCustomer"  class="modal-body">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">X</button>
        <ul>
        <h3>Mr et Mme <mat-form-field>
          <input matInput [(ngModel)]='case.customer.name' placeholder="Nom">
        </mat-form-field></h3>
        <section>
          <h4>Adresse</h4>
          <li> <mat-form-field>
            <input matInput [(ngModel)]='case.customer.address' placeholder="Adresse">
          </mat-form-field> </li>
         <li> <mat-form-field>
            <input matInput [(ngModel)]='case.customer.cp' placeholder="Code Postal">
          </mat-form-field>
          <mat-form-field >
            <input matInput [(ngModel)]='case.customer.city' placeholder="Ville">
          </mat-form-field></li>
          </section>
          <section>
                    <h4>Numéros</h4>
         <li> <mat-form-field>
            <input matInput [(ngModel)]='case.customer.mobile'  placeholder="Mobile">
          </mat-form-field></li>
         <li><mat-form-field>
            <input matInput [(ngModel)]='case.customer.fixe' placeholder="Fixe" >
          </mat-form-field></li>
          <li><mat-form-field>
            <input matInput [(ngModel)]='case.customer.mail'  placeholder="mail" >
          </mat-form-field></li>
        </section>
          </ul>

        <button class="btn-secondarycrm editCustomer" data-dismiss="modal" (click)="saveCustomerChange()" >Enregistrer les informations</button>
      </div>

    </div>
  </div>
  </div>

  <form [formGroup]="caseForm" class="d-flex row">
    <div *ngIf="case.commercial === null; then thenCommercial else elseCommercial"></div>
    <ng-template #thenCommercial>
      <mat-select class="col-2 mb-4 choice-com" formControlName="commercial" [(ngModel)]="case.commercial._id" value="case.commercial.username" (selectionChange)="saveChange(case)">
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let adv of this.sUsers.advs" [value]="adv._id">
          {{adv.username}}
        </mat-option>
      </mat-select>
    </ng-template>

    <ng-template #elseCommercial>
      <mat-select [ngClass]="case.commercial" class="col-2 mb-4 choice-com" formControlName="commercial" [(ngModel)]="case.commercial._id" value="case.commercial.username" (selectionChange)="saveChange(case)">
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let adv of arrayAdvs" [value]="adv._id">
          {{adv.username}}
        </mat-option>
      </mat-select>
    </ng-template>

  </form>

  <form [formGroup]="caseForm" class="d-flex">
    <mat-select [ngClass]="case.state" class="custom-select-state rdv select-state" formControlName="state" [(ngModel)]="case.state" value="case.state" (selectionChange)="saveChange(case)">
      <mat-option class="{{option}}-select" *ngFor="let option of options" [value]="option">
        {{option}}
      </mat-option>
    </mat-select>
  </form>
  </div>

<app-case-cm [state]="state" *ngIf="rdvs"></app-case-cm>

<button *ngIf="piecesList" class="btn-secondarycrm mt-5 mb-3" (click)="showAddPiece()">+ Nouvelle pièce</button>
<app-list-piece *ngIf="piecesList"></app-list-piece>
<app-pieces-add [titrePiece]="titrePiece" *ngIf="piecesAdd"></app-pieces-add>

<app-detail-piece [currentPiece]="currentPiece" *ngIf="pieceEdit"></app-detail-piece>
<app-chantier-list *ngIf="chantierList" ></app-chantier-list>
<app-chantier-detail [chantierId]="chantierId" *ngIf="chantierDetail"></app-chantier-detail>
