import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { HistoriqueService } from '@app/services/historique.service';
import { InfosService } from '@app/services/infos.service';
import { RolesService } from '@app/services/roles.service';
import { apiUrl } from '@environments/env';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ChantierChoiceComponent } from '@app/chantier/chantier-choice/chantier-choice.component';

@Component({
  selector: 'app-pieces-add',
  templateUrl: './add-piece.component.html',
  styleUrls: ['./add-piece.component.scss'],
})
export class AddPieceComponent implements OnInit {
  @Input() titrePiece: string;
  countUnread;
  title;
  caseId;
  pieceForm: FormGroup;
  add = true;

  apiUrl = apiUrl.apiUrl + '/piece/';
  //apiUrl = 'http://localhost:4000/piece/';
  multipleImagesPieces;
  piecesOk;
  userRole;
  mobile;
  case;
  otherPiece = 'Autre';
  chantierTitle;
  chantierId;

  timeOutLoader = true;
  type;
  myControl = new FormControl();
  options: string[] = [
    'BS Mme M-2',
    'BS Mme M-1',
    'BS Mme M',
    'BS Mr M-2',
    'BS Mr M-1',
    'BS Mr M',
    'Photo disjoncteur',
    'Photo compteur',
    'Photo maison de loin',
    'Photo des 4 côtés de la maison',
    'Photo des radiateurs',
    'Photo toiture',
    'Photo UE PAC',
    'VT',
    'RIB',
    'AI Mr',
    'AI Mme',
    'CNI Mme',
    'CNI Mr',
    'CAF',
    'Plan de masse',
    'Photo nouveau BT',
    '8 zones photos bouches',
    '10 photos plafonds',
    'Photo ancien BT',
    'Photo nouveau BT',
    'Acte notarié',
    'Taxe foncière',
    'Justificatif dom -3mois',
    'Facture EDF',
    'Etude de rentabilité',
    'Bon de commande',
    'Offre de prêt',
    'Autre',
    'Mandat MPR',
    'CNO',
    'Consuel',
    'CERFA PAC',
    'Marquage pénétration de la gaine',
  ];
  filteredOptions: Observable<string[]>;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private http: HttpClient,
    public sanitizer: DomSanitizer,
    private router: Router,
    private sRoles: RolesService,
    private sCases: CasesService,
    private sInfos: InfosService,
    private sHistoriques: HistoriqueService,
    private location: Location,
    private dialog: MatDialog
  ) {
    if (this.sRoles.user.role == 'Technicien') {
      this.chantierTitle =
        this.router.getCurrentNavigation().extras.state.title;
      this.chantierId = this.router.getCurrentNavigation().extras.state.id;
    }
  }

  ngOnInit(): void {
    this.options = this.options.sort();
    if (window.screen.width <= 600) {
      this.mobile = true;
    }
    this.userRole = this.sRoles.user.role;
    this.caseId = this.route.snapshot.params['id'];

    if (this.titrePiece === undefined) {
      this.title = '';
    } else {
      this.title = '';
    }

    let now = new Date();

    this.pieceForm = this.fb.group({
      title: new FormControl(this.title, [Validators.required]),
      description: new FormControl(''),
      qualification: new FormControl('En attente de qualification'),
      created_by: new FormControl(this.sRoles.user.id),
      commentary: new FormControl(''),
      created_at: new FormControl(now.setHours(now.getHours())),
      chantier: new FormControl(''),
    });

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
    this.showCaseById();
    this.getGroups();
  }

  _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(
      (option) => option.toLowerCase().indexOf(filterValue) === 0
    );
  }
  selectMultiple(event) {
    if (event.target.files.length > 0) {
      this.multipleImagesPieces = event.target.files;
    }
  }

  onSubmitMultiple(reason, change, value) {
    this.timeOutLoader = false;
    setTimeout(function () {
      this.timeOutLoader = true;
    }, 5000);
    const formData = new FormData();
    if (
      this.sRoles.user.role == 'Technicien' ||
      this.sRoles.user.role == 'Responsable Technicien'
    ) {
      this.type = 'technicien';
    }
    formData.append('title', this.pieceForm.value.title);
    formData.append('description', this.pieceForm.value.description);
    formData.append(
      'qualification',
      this.pieceForm.value.title !== 'Devis'
        ? this.pieceForm.value.qualification
        : 'Conforme'
    );
    formData.append('caseId', this.caseId);
    formData.append('created_at', this.pieceForm.value.created_at);
    formData.append('chantier', this.chantierId);
    formData.append('type', this.type);
    for (let img of this.multipleImagesPieces) {
      formData.append('filesPieces', img);
    }
    this.http.post<any>(this.apiUrl + 'multiplePieces', formData).subscribe(
      (res) => {
        this.piecesOk = true;
        if (
          this.userRole === 'Commercial' ||
          this.userRole === "Responsable d'agence"
        ) {
          this.showCaseByIdAndNotif();
          if (this.mobile) {
            this.router.navigate(['commercial-template', this.caseId]);
          } else {
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate(['commercial-template', this.caseId], {
              state: { value: 'piecesList' },
            });
          }
        } else if (
          this.userRole == 'Technicien' ||
          this.userRole == 'Responsable Technicien'
        ) {
          this.location.back();
        } else {
          if (this.pieceForm.value.title === 'Devis') {
            this.notifyPrimeWithDevis();
          }
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['case-template', this.caseId], {
            state: { value: 'piecesList' },
          });
        }
      },
      (err) => console.log(err)
    );
    this.sHistoriques
      .createHistorique(
        this.caseId,
        this.case.customer._id,
        reason,
        change,
        value,
        this.sRoles.user.id
      )
      .then((res) => {
        if (res['error']) {
          alert(res);
        }
      });
  }

  private notifyPrimeWithDevis(): Promise<any> {
    return new Promise((resolve) => {
      this.sCases.getCaseById(this.caseId).subscribe(
        (res) => {
          this.case = res[0];
          // id comptabilite@enerconfort.com
          let ids = ['61b083b09593180004b58149'];
          if (this.case.adv) {
            ids.push(this.case.adv._id);
          }
          ids.forEach((id) => {
            let comment = {
              comment: 'Créer le dossier MPR',
              reason: 'dossier_prime',
              created_by: this.sRoles.user.id,
              created_at: this.pieceForm.value.created_at,
              caseId: this.caseId,
              customerId: this.case.customer,
              adv: id,
            };
            this.sInfos.createInfo(comment);
          });

          resolve(res);
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }
  showCaseById() {
    return new Promise((resolve) => {
      this.sCases.getCaseById(this.caseId).subscribe(
        (res) => {
          this.case = res[0];
          resolve(res);
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }

  showCaseByIdAndNotif() {
    return new Promise((resolve) => {
      this.sCases.getCaseById(this.caseId).subscribe(
        (res) => {
          this.case = res[0];
          if (this.case.adv != null) {
            let comment = {
              comment: this.pieceForm.value.title,
              reason: 'pieces',
              created_by: this.sRoles.user.id,
              created_at: this.pieceForm.value.created_at,
              caseId: this.caseId,
              customerId: this.case.customer,
              adv: this.case.adv,
            };
            this.sInfos.createInfo(comment).then((res) => {
              if (res['error']) {
                alert(res.error);
              }
            });
          }
          resolve(res);
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }

  validPiece(isOtherPiece: boolean) {
    if (isOtherPiece) {
      this.otherPiece = this.pieceForm.value.title;
    }
    if (
      this.pieceForm.value.title == 'PV réception' ||
      this.pieceForm.value.title == 'PV livraison' ||
      this.pieceForm.value.title == "Bon d'inter" ||
      this.pieceForm.value.title == "Bon d'inter Cm" ||
      this.pieceForm.value.title == 'Recette'
    ) {
      this.openDialog();
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ChantierChoiceComponent, {
      width: '640px',
      disableClose: true,
      data: {
        caseId: this.caseId,
      },
    });
    dialogRef
      .afterClosed()
      .subscribe((data) =>
        this.pieceForm.get('description').setValue('/' + data)
      );
  }

  async getGroups(): Promise<void> {
    try {
      const countUnread: any = await this.http
        .get(apiUrl.apiUrl + '/messages/count-unread')
        .toPromise();
      this.countUnread = countUnread;
    } catch (error) {}
  }
}
