import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-part5',
  templateUrl: './part5.component.html',
  styleUrls: ['./part5.component.scss']
})
export class Part5Component implements OnInit {

  caseId
  isSelected: string | null = null;
  book;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.caseId = this.route.snapshot.params['id'];
    this.book = JSON.parse(localStorage.getItem('book'));
  }
  public selectSpan(spanNumber: string): void {
    this.isSelected = spanNumber;
    this.book.dpe_estimation = spanNumber;
    localStorage.setItem('book', JSON.stringify(this.book));
  }
}
