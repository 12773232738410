<div class="d-flex justify-content-center">
  <h4>Pour quel chantier ?</h4>
</div>
  <mat-form-field class="d-flex justify-content-center">
    <mat-label>Choisir le chantier</mat-label>
    <mat-select (selectionChange)="saveChoice($event)">
      <mat-option *ngFor="let chantier of chantiers" [value]="chantier.title">
        {{chantier.title}}
      </mat-option>
    </mat-select>
  </mat-form-field>
