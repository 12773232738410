import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { HistoriqueService } from '@app/services/historique.service';
import { MairiesService } from '@app/services/mairies.service';
import { RolesService } from '@app/services/roles.service';
import { count } from 'rxjs/operators';

@Component({
  selector: 'app-add-mairie',
  templateUrl: './add-mairie.component.html',
  styleUrls: ['./add-mairie.component.scss']
})
export class AddMairieComponent implements OnInit {
  caseId;
  case;
  mairieForm: FormGroup;
  date_fin;
  countMairie;
  user;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public sanitizer: DomSanitizer,
    private router: Router,
    private sRoles: RolesService,
    private sMairies: MairiesService,
    private sCases: CasesService,
    private sHistoriques: HistoriqueService,

  ) {
    this.user = this.sRoles.user.role;
    this.caseId = this.route.snapshot.params['id'];

    this.sMairies.getMairiesByCaseId(this.caseId).subscribe(res => {
      this.countMairie = res;
      this.countMairie = this.countMairie.length + 1;
      this.mairieForm = this.fb.group({
          caseId: new FormControl(this.caseId, [Validators.required]),
          titre: new FormControl( "Demande n°" + this.countMairie, [Validators.required]),
          dp: new FormControl('', [Validators.required]),
          state: new FormControl('', [Validators.required]),
          date_depot: new FormControl('', [Validators.required]),
          created_by: new FormControl(this.sRoles.user.id)

        });
      });
   }

  ngOnInit() {
    this.showCaseInfos();
  }
  createMairie(reason, change,value) {
     this.sMairies.createMairie(this.mairieForm.value).then(res => {
      if (res['error']) {
        console.log(res['error']);
      }
      this.goToList();
    });
    this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change,value,this.sRoles.user.id).then(res => {
      if (res['error']) {
        alert(res);
      }
    });
  }

  goToList() {
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['case-template', this.caseId], { state: { value: 'mairieList' } });
  }

  showCaseInfos() {
    return new Promise(resolve => {

    this.sCases.getCaseById(this.caseId).subscribe(res => {
      this.case = res[0];
      var dt = new Date(this.case.date_depot);
      if (this.case.abf === 'true') {
        dt.setMonth( dt.getMonth() + 3);
      }
      else {
        dt.setMonth( dt.getMonth() + 2);
      }
      this.date_fin = dt;
      resolve(res);
        }, error => {
          console.log(error);
      });
    });
  }

}
