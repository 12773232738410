<app-commentbar
  [caseId]="caseId"
  *ngIf="!piecesList && !piecesAdd"
></app-commentbar>
<app-loading *ngIf="!case"></app-loading>
<div class="icons" *ngIf="case">
  <a
    class="nav-item nav-link"
    [ngClass]="rdvs ? 'active' : ''"
    style="font-size: 0.8rem"
    (click)="showRdvs()"
  >
    <img src="../../../assets/bar-icons/rdvs.svg" />
  </a>

  <a
    class="nav-item nav-link"
    style="font-size: 0.8rem"
    (click)="showMairieList()"
  >
    <img
      *ngIf="mairieList || mairieEdit || mairieAdd"
      class="actif"
      src="../../../assets/bar-icons/mairie-active.svg"
    />
    <img
      *ngIf="!mairieList && !mairieEdit!; mairieAdd"
      src="../../../assets/bar-icons/mairie.svg"
    />
  </a>

  <a
    class="nav-item nav-link"
    style="font-size: 0.8rem"
    (click)="showPiecesList()"
  >
    <img
      *ngIf="piecesList || piecesAdd || pieceEdit"
      class="actif"
      src="../../../assets/bar-icons/pieces-active.svg"
    />
    <img
      *ngIf="!piecesList && !piecesAdd && !pieceEdit"
      src="../../../assets/bar-icons/pieces.svg"
    />
  </a>

  <a
    class="nav-item nav-link"
    style="font-size: 0.8rem"
    (click)="showFinancement()"
  >
    <img
      *ngIf="
        financementComptant ||
        financementList ||
        financementEdit ||
        financementAdd
      "
      class="actif"
      src="../../../assets/bar-icons/fi-active.svg"
    />
    <img
      *ngIf="
        !financementComptant &&
        !financementList &&
        !financementEdit &&
        !financementAdd
      "
      src="../../../assets/bar-icons/fi.svg"
    />
  </a>

  <a
    class="nav-item nav-link"
    style="font-size: 0.8rem"
    (click)="showChantier()"
  >
    <img
      *ngIf="chantierDetail || chantierList"
      class="actif"
      src="../../../assets/bar-icons/travaux-active.svg"
    />
    <img
      *ngIf="!chantierDetail && !chantierList"
      src="../../../assets/bar-icons/travaux.svg"
    />
  </a>

  <a
    class="nav-item nav-link"
    style="font-size: 0.8rem"
    (click)="showPrimeList()"
  >
    <img
      *ngIf="primeEdit || primeList"
      class="actif"
      src="../../../assets/bar-icons/primes-active.svg"
    />
    <img
      *ngIf="!primeEdit && !primeList"
      src="../../../assets/bar-icons/primes.svg"
    />
  </a>
</div>

<div class="p-2 pt-4" *ngIf="case">
  <div *ngIf="!pieceEdit && !piecesAdd">
    <div class="d-flex">
      <h2>{{ case.customer.name }} - {{ case.customer.mobile }}</h2>
      <a
        data-toggle="modal"
        data-target="#exampleModal"
        class="show-client"
        (click)="toggleEditCustomer(false)"
        >Voir les infos clients</a
      >
      <img
        src="../../../assets/icons/historique.svg"
        *ngIf="
          this.sRoles.user.role === 'Admin' ||
          this.sRoles.user.id === '6135c7036457510004c2eef1'
        "
        class="historique"
        [routerLink]="['/case-historique', case._id]"
      />
    </div>
    <div class="d-flex">
      <h2 *ngIf="!showEditTitle">
        {{ case.title }}
        <mat-icon (click)="showEditTitle = !showEditTitle">edit</mat-icon>
      </h2>
      <h2 class="ml-4" style="color: var(--primarycrm)">
        {{ this.case.customer.origin }}
      </h2>
    </div>
    <form [formGroup]="caseForm" *ngIf="showEditTitle">
      <input
        class="title-input"
        type="text"
        formControlName="title"
        [(ngModel)]="case.title"
        (change)="saveChange('edité', 'titre de l\'affaire', case.title)"
      />
      <mat-icon (click)="showEditTitle = !showEditTitle">close</mat-icon>
    </form>
    <div *ngIf="case.qualification === 'En attente'" class="rdv-qualif mt-4">
      <h6><strong>Le RDV est-il qualifié ?</strong></h6>
      <input
        type="radio"
        id="oui"
        name="oui"
        [(ngModel)]="case.qualification"
        value="qualifié"
        (change)="saveQualif('qualifié')"
      />
      <label
        class="ml-0"
        for="oui"
        [ngClass]="case.qualification === 'qualifié' ? 'oui' : 'oui-empty'"
      >
        <span class="material-icons"> done </span>OUI</label
      >
      <input
        type="radio"
        id="non"
        name="non"
        [(ngModel)]="case.qualification"
        value="non qualifié"
        (change)="saveQualif('non qualifié')"
      />
      <label
        for="non"
        [ngClass]="case.qualification === 'non qualifié' ? 'non' : 'non-empty'"
      >
        <span class="material-icons"> clear </span>NON</label
      >
    </div>
    <p></p>

    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div *ngIf="!editCustomer" class="modal-body">
            <ul>
              <h3>Mr et Mme {{ case.customer.name }}</h3>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
              <section>
                <h4>Adresse</h4>
                <li>{{ case.customer.address }}</li>
                <li>{{ case.customer.cp }} {{ case.customer.city }}</li>
              </section>
              <section>
                <h4>Numéros</h4>
                <li>{{ case.customer.mobile }}</li>
                <li>{{ case.customer.fixe }}</li>
                <li>
                  <a href="mailto:{{ case.customer.mail }}">{{
                    case.customer.mail
                  }}</a>
                </li>
                <li (click)="getUserCustomerLogin()" *ngIf="customerLog === ''">
                  <a>Voir l'identifiant du client</a>
                  <p *ngIf="customerLog != ''">
                    Identifiant: {{ customerLog }}
                  </p>
                </li>
                <li
                  (click)="ReinitPassword()"
                  *ngIf="newUserGeneratedPassword === ''"
                >
                  <a>Réinitialiser l'identifiant du client</a>
                  <p *ngIf="newUserGeneratedPassword != ''">
                    Nouveau mot de passe: {{ newUserGeneratedPassword }}
                  </p>
                </li>
              </section>
            </ul>
            <button
              class="btn-secondarycrm editCustomer"
              (click)="toggleEditCustomer(true)"
            >
              Modifier les informations
            </button>
          </div>
          <div *ngIf="editCustomer" class="modal-body">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
            <ul>
              <h3>
                Mr et Mme
                <mat-form-field>
                  <input
                    matInput
                    [(ngModel)]="case.customer.name"
                    placeholder="Nom"
                  />
                </mat-form-field>
              </h3>
              <section>
                <h4>Adresse</h4>
                <li>
                  <mat-form-field>
                    <input
                      matInput
                      [(ngModel)]="case.customer.address"
                      placeholder="Adresse"
                    />
                  </mat-form-field>
                </li>
                <li>
                  <mat-form-field>
                    <input
                      matInput
                      [(ngModel)]="case.customer.cp"
                      placeholder="Code Postal"
                    />
                  </mat-form-field>
                  <mat-form-field>
                    <input
                      matInput
                      [(ngModel)]="case.customer.city"
                      placeholder="Ville"
                    />
                  </mat-form-field>
                </li>
              </section>
              <section>
                <h4>Numéros</h4>
                <li>
                  <mat-form-field>
                    <input
                      matInput
                      [(ngModel)]="case.customer.mobile"
                      placeholder="Mobile"
                    />
                  </mat-form-field>
                </li>
                <li>
                  <mat-form-field>
                    <input
                      matInput
                      [(ngModel)]="case.customer.fixe"
                      placeholder="Fixe"
                    />
                  </mat-form-field>
                </li>
                <li>
                  <mat-form-field>
                    <input
                      matInput
                      [(ngModel)]="case.customer.mail"
                      placeholder="mail"
                    />
                  </mat-form-field>
                </li>
              </section>
            </ul>

            <button
              class="btn-secondarycrm editCustomer"
              data-dismiss="modal"
              (click)="saveCustomerChange()"
            >
              Enregistrer les informations
            </button>
          </div>
        </div>
      </div>
    </div>

    <form
      [formGroup]="caseForm"
      class="d-flex row"
      *ngIf="advs.length != 0 && commerciaux.length != 0 && case"
    >
      <div
        *ngIf="
          case.commercial === null;
          then thenCommercial;
          else elseCommercial
        "
      ></div>

      <ng-template #thenCommercial>
        <mat-select
          class="col-2 mb-4 choice-com"
          formControlName="commercial"
          value="case.commercial.username"
          (selectionChange)="
            saveChange('edité', 'commercial', 'commercial de l\'affaire')
          "
        >
          <!-- <mat-option [value]=null></mat-option> -->
          <mat-option
            *ngFor="let commercial of this.commerciaux"
            [value]="commercial._id"
          >
            {{ commercial.username }}
          </mat-option>
          <mat-option *ngFor="let adv of advs" [value]="adv._id">
            {{ adv.username }}
          </mat-option>
        </mat-select>
      </ng-template>

      <ng-template #elseCommercial>
        <mat-select
          [ngClass]="case.commercial"
          class="col-2 mb-4 choice-com"
          formControlName="commercial"
          [(ngModel)]="case.commercial._id"
          value="case.commercial.username"
          (selectionChange)="
            saveChange('edité', 'commercial', 'commercial de l\'affaire')
          "
        >
          <!-- <mat-option [value]=null></mat-option> -->
          <mat-option
            *ngFor="let commercial of this.commerciaux"
            [value]="commercial._id"
          >
            {{ commercial.username }}
          </mat-option>
          <mat-option *ngFor="let adv of this.sUsers.advs" [value]="adv._id">
            {{ adv.username }}
          </mat-option>
        </mat-select>
      </ng-template>

      <div
        *ngIf="case.adv === null; then thenBlockMobile; else elseBlockDesktop"
      ></div>
      <ng-template #thenBlockMobile>
        <mat-select
          formControlName="adv"
          class="col-2 mb-4 choice-com"
          value="case.adv.username"
          (selectionChange)="generateGddTasks($event.value)"
          (selectionChange)="saveChange('edité', 'adv', 'adv de l\'affaire')"
        >
          <mat-option *ngFor="let adv of this.sUsers.advs" [value]="adv._id">
            {{ adv.username }}
          </mat-option>
        </mat-select>
      </ng-template>

      <ng-template #elseBlockDesktop>
        <mat-select
          formControlName="adv"
          class="col-2 mb-4 choice-com"
          [(ngModel)]="case.adv._id"
          value="case.adv.username"
          (selectionChange)="generateGddTasks($event.value)"
          (selectionChange)="saveChange('edité', 'adv', 'adv de l\'affaire')"
        >
          <mat-option *ngFor="let adv of this.sUsers.advs" [value]="adv._id">
            {{ adv.username }}
          </mat-option>
        </mat-select>
      </ng-template>
    </form>

    <form [formGroup]="caseForm" class="d-flex" *ngIf="case">
      <mat-select
        *ngIf="case"
        [ngClass]="case.state"
        class="custom-select-state rdv"
        formControlName="state"
        [(ngModel)]="case.state"
        (selectionChange)="saveChange('edité', 'statut', case.state)"
      >
        <mat-option
          class="{{ option }}-select"
          *ngFor="let option of options"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-select>

      <form formGroupName="rdv_state">
        <select
          class="custom-select-rdv rdv"
          *ngIf="case"
          formControlName="status"
          [ngClass]="case.rdv_state.status != 'Oui' ? 'non-empty' : ''"
          value="case.rdv_state.status"
          (change)="saveChange('edité', 'rdv fait', case.rdv_state.status)"
        >
          <option value="">RDV fait ?</option>
          <option value="Oui">Oui</option>
          <option value="Non pas trouvé">Non pas trouvé</option>
          <option value="Non refus visite">Non refus visite</option>
          <option value="Non porte">Non porte</option>
          <option value="Non annulé">Non annulé</option>
        </select>
      </form>

      <div class="dateSign" *ngIf="case && case.state == 'Signée'">
        <input
          class="custom-date"
          placeholder="JJ/MM/AAAA xxhxx"
          formControlName="datesign"
          [owlDateTime]="dt2"
          [owlDateTimeTrigger]="dt2"
          (ngModelChange)="
            saveChange('edité', 'date de signature', case.datesign)
          "
        />
        <owl-date-time firstDayOfWeek="1" #dt2></owl-date-time>
      </div>

      <!-- <div><button class="btn-secondarycrm ml-4" style="padding: 1.7vh 3vw;" [routerLink]="['/book', case._id]">Commencer le book</button></div> -->

      <div class="dateSign" *ngIf="case.state == 'Annulée'">
        Date d'annulation {{ case.datecancel | date : "dd/MM/yy " }}
      </div>
    </form>
  </div>
  <!-- <h1 *ngIf="this.sRoles.user.role === 'Admin'">{{ case.qualification }}  /  ishidden {{ case.isHidden}}</h1> -->
  <app-admin-customer-case-detail
    *ngIf="rdvs"
    (newItemEvent)="addItem($event)"
  ></app-admin-customer-case-detail>
  <button
    *ngIf="piecesList"
    class="btn-secondarycrm mt-5 mb-3"
    (click)="showAddPiece()"
  >
    + Nouvelle pièce
  </button>

  <app-list-piece *ngIf="piecesList"></app-list-piece>

  <app-pieces-add [titrePiece]="titrePiece" *ngIf="piecesAdd"></app-pieces-add>
  <app-case-financement-comptant
    *ngIf="financementComptant"
  ></app-case-financement-comptant>

  <app-case-financement *ngIf="financementList"></app-case-financement>
  <app-case-financement-add *ngIf="financementAdd"></app-case-financement-add>
  <app-case-financement-edit
    [financementId]="financementId"
    *ngIf="financementEdit"
  ></app-case-financement-edit>
  <button
    *ngIf="financementList"
    class="btn btn-secondarycrm mt-5 mb-3"
    (click)="showAddFinancement()"
  >
    + Nouvelle demande FI
  </button>

  <button
    *ngIf="primeList"
    class="btn btn-secondarycrm mt-3"
    (click)="openDialog()"
  >
    + Nouveau dossier de prime
  </button>
  <app-case-prime-add *ngIf="primeAdd"></app-case-prime-add>
  <app-case-prime-list *ngIf="primeList"></app-case-prime-list>
  <app-case-prime-edit
    [primeId]="primeId"
    *ngIf="primeEdit"
  ></app-case-prime-edit>

  <app-detail-piece
    [currentPiece]="currentPiece"
    *ngIf="pieceEdit"
  ></app-detail-piece>
  <app-chantier-list *ngIf="chantierList"></app-chantier-list>
  <app-chantier-detail
    [chantierId]="chantierId"
    *ngIf="chantierDetail"
  ></app-chantier-detail>
  <app-list-mairie
    (voted)="onVoted($event)"
    *ngIf="mairieList"
  ></app-list-mairie>
  <app-add-mairie *ngIf="mairieAdd"></app-add-mairie>
  <app-edit-mairie [mairieId]="mairieId" *ngIf="mairieEdit"></app-edit-mairie>
  <button
    *ngIf="mairieList && disagreed"
    class="btn btn-secondarycrm mt-5 mb-3"
    (click)="showAddMairie()"
  >
    + Nouvelle demande en mairie
  </button>
</div>
