import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { AdminQualificationDetailComponent } from '../admin-qualification-detail/admin-qualification-detail.component';

@Component({
  selector: 'app-admin-qualification',
  templateUrl: './admin-qualification.component.html',
  styleUrls: ['./admin-qualification.component.scss']
})
export class AdminQualificationComponent implements OnInit {

  cases;
  attentes = [];
  Nqualifs = [];
  show = false;
  constructor(
    private sCases: CasesService,
    private dialog: MatDialog,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.showCases();
  }

  showCases() {
    this.attentes = [];
    this.Nqualifs = [];
    this.sCases.getAllCases().subscribe(res => {
      this.cases = res['cases'];

      this.show = true;
      this.cases.forEach(element => {
        if(element.qualification === 'En attente') {
          this.attentes.push(element)
        }
        else if(element.qualification === 'non qualifié') {
          this.Nqualifs.push(element)
        }
      });
    });
    this.sortData();
  }
   sortData() {
    return this.attentes.sort((a, b) => {
      return <any>new Date(a.r1[0].date) - <any>new Date(b.r1[0].date);
    });
  }

  saveChange(attente, caseId) {

    this.sCases.editCase(attente, caseId).then(res => {
      if(res['error']) {
        return res.error;
      }
      this.showCases();
    });
  }

  openDialog(currentCase): void {
    const dialogRef = this.dialog.open(AdminQualificationDetailComponent, {
      width: '60vw',
      height: '80%',
      data: {
        case: currentCase,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.show = false;
      this.showCases();
    });
  }

  goBack() {
    this.router.navigate(['/admin-dashboard']).then(() => {
      window.location.reload();
    });
  }

}
