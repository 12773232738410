<img
style="
    position: relative;
    left: 98%;
    width: 5%;
    top: -17px;
    cursor: pointer;"
src="../../../assets/icons/cross.svg" (click)="closeModale()">

<form [formGroup]="userForm">
  <mat-form-field class="example-full-width">
    <mat-label>NOM prénom</mat-label>
    <input matInput formControlName="username" placeholder="Prénom" required>
  </mat-form-field>

  <mat-form-field class="example-full-width">
    <mat-label>Email</mat-label>
    <input matInput formControlName="mail" placeholder="Email" required>
  </mat-form-field>

  <mat-form-field class="example-full-width">
    <mat-label>Mot de passe</mat-label>
    <input matInput formControlName="password" placeholder="Mot de passe" required>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Rôle</mat-label>
    <mat-select formControlName="role" [(ngModel)]="role" required>
      <mat-option placeholder="Rôle" *ngFor="let role of roles" [value]="role.name">
        {{ role.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="role === 'Commercial' || role === 'Responsable d\'agence'">
  <h6 style='text-decoration:underline;'>Informations supplémentaires</h6>
  <mat-form-field>
    <mat-label>Secteur</mat-label>
    <mat-select formControlName="sector" required>
      <mat-option *ngFor="let sector of sectors" [value]="sector.name">
        {{ sector.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Agence</mat-label>
    <mat-select formControlName="agency" required>
      <mat-option *ngFor="let agency of agencies" [value]="agency.name">
        {{ agency.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="example-full-width">
    <mat-label>Ceasy Login</mat-label>
    <input matInput formControlName="ceasy_login" placeholder="Ceasy login">
  </mat-form-field>
</div>
    <button mat-raised-button style="width: 100%;background: #78C091;color: white;box-shadow: 7px 7px 22px rgb(33 38 75 / 5%);" (click)="addUser()" *ngIf="this.userForm.valid">Enregistrer</button>

</form>
