import { state } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { ChantierService } from '@app/services/chantier.service';
import { HistoriqueService } from '@app/services/historique.service';
import { InfosService } from '@app/services/infos.service';
import { PiecesService } from '@app/services/pieces.service';
import { RolesService } from '@app/services/roles.service';
import { TasksService } from '@app/services/tasks.service';
import { UsersService } from '@app/services/users.service';

@Component({
  selector: 'app-chantier-detail',
  templateUrl: './chantier-detail.component.html',
  styleUrls: ['./chantier-detail.component.scss']
})
export class ChantierDetailComponent implements OnInit {


  @Input() chantierId: string;

  chantier;
  case;
  allTechniciens;
  validTech: string[] = [];
  isfrmChecked: any;
  chantierForm: FormGroup;
  userRole;
  qualifications = ['Terminé', 'En cours', 'Annulé'];
  pieces;
  techs = [];
  responsables = [];

  tech1 = [{
    username: '',
  }];
  tech2 = [{
    username: '',
  }];
  resp = [{
    username: '',
  }];

  displayTech;
  arrayChantier = [];
  Chantier;
  allTechs;
  constructor(
    private sChantier: ChantierService,
    private sUser: UsersService,
    private sRoles: RolesService,
    private sPieces: PiecesService,
    private router: Router,
    public sUsers: UsersService,
    private fb: FormBuilder,
    private sHistoriques: HistoriqueService,
    private sCases: CasesService,
    private sInfos: InfosService,
    private sTasks: TasksService
  ) { }

  ngOnInit(): void {
    this.userRole = this.sRoles.user.role;
    this.showAllTechs();
    this.ShowChantier();


  }

  ShowChantier() {
    this.sChantier.getChantierById(this.chantierId).subscribe(res => {
      this.chantier = res[0];
      this.allTechs.forEach(element => {
        if (element.role === 'Technicien') {
          this.techs.push(element)
        }
        else {
          this.responsables.push(element)
        }
      });
      this.showCaseById();
      this.chantier.tech[0];
      this.chantier.tech[1];
      this.displayTech = true;
      this.resp = this.responsables[0];
      this.chantierForm = this.fb.group({
        statut: new FormControl(this.chantier.statut, [Validators.required]),
        date: new FormControl(this.chantier.date, [Validators.required]),
        tech1: new FormControl(this.chantier.tech[0], [Validators.required]),
        tech2: new FormControl(this.chantier.tech[1], [Validators.required]),
        resp: new FormControl(this.responsables[0], [Validators.required]),
      });
      this.showPiecesChantier();
      this.ShowTechs()
    })
  }
  showPiecesChantier() {
    this.sPieces.getPiecesByChantierId(this.chantierId).subscribe(res => {
      this.pieces = res
    })
  }



  showCaseById() {
    return new Promise(resolve => {

      this.sCases.getCaseById(this.chantier.caseId._id).subscribe(res => {
        this.case = res[0];
        resolve(res);
      }, error => {
        console.log(error);
      });
    });
  }

  ShowTechs() {
    this.sUser.getAllTechs().subscribe(res => {
      this.allTechniciens = res['users']
      for (let index = 0; index < this.chantier.tech.length; index++) {
        for (let i = 0; i < this.allTechniciens.length; i++) {
          if (this.allTechniciens[i]._id.indexOf(this.chantier.tech[index]._id) != -1) {
            this.allTechniciens.splice(i, 1)
          }
        }
      }
    })
  }

  saveChange(chantier, chantierId, reason, change, value) {
    this.arrayChantier = [];
    this.arrayChantier.push(this.chantierForm.value.tech1, this.chantierForm.value.tech2, this.chantierForm.value.resp)
    this.Chantier = {
      _id: chantier._id,
      caseId: chantier.caseId,
      date: chantier.date,
      statut: chantier.statut,
      tech: this.arrayChantier,
      title: chantier.title,
      created_at: chantier.created_at
    };

    this.sChantier.editChantier(chantier, chantierId).then(res => {
      if (res['error']) {
        return res.error;
      }

      this.ShowChantier();
    });
    this.sHistoriques.createHistorique(chantier.caseId, chantier.caseId.customer._id, reason, change, value, this.sRoles.user.id).then(res => {
      if (res['error']) {
        alert(res);
      }
    });

  }

  saveChangeState(chantier, chantierId, reason, change, value) {
    this.arrayChantier = [];
    this.arrayChantier.push(this.chantierForm.value.tech1, this.chantierForm.value.tech2, this.chantierForm.value.resp)
    this.Chantier = {
      _id: chantier._id,
      caseId: chantier.caseId,
      date: chantier.date,
      statut: chantier.statut,
      tech: this.arrayChantier,
      title: chantier.title,
      created_at: chantier.created_at
    };

    this.sChantier.editChantier(chantier, chantierId).then(res => {
      if (res['error']) {
        return res.error;
      }
      this.ShowChantier();
    });
    this.sChantier.getLastChantier(this.case._id).subscribe(lastChantier => {



      if (lastChantier[0]._id == this.chantier._id) {
        if (this.chantier.statut === 'Terminé') {
          this.sUsers.compta.forEach(element => {
            let comment = {
              comment: 'statut du chantier : ' + chantier.statut,
              reason: 'chantier',
              created_by: this.sRoles.user.id,
              created_at: new Date(),
              caseId: this.chantier.caseId._id,
              customerId: this.case.customer,
              adv: element._id

            }
            this.sInfos.createInfo(comment).then(res => {
              if (res['error']) {
                alert(res.error);
              }
            });
            const task = {
              message: 'Facturer le chantier de ' + this.chantier.caseId.customer.name.trim(),
              commentary: '',
              state: '1-Urgente',
              date: new Date(),
              created_at: new Date(),
              created_by: '5f76e5503926a01790428179',
              caseId: this.chantier.caseId._id,
              customerId: this.case.customer._id,
              target: element._id,
              tag: null

            }
            this.sTasks.CreateTask(task).then(res => {
              if (res['error']) {
                alert(res.error);
              }
            });
          });

          this.sHistoriques.createHistorique(chantier.caseId, chantier.caseId.customer._id, reason, change, value, this.sRoles.user.id).then(res => {
            if (res['error']) {
              alert(res);
            }
          });
        }
      }
    })

  }

  saveChangeTech(chantier, chantierId, reason, change, value) {
    this.arrayChantier = [];
    this.arrayChantier.push(this.chantierForm.value.tech1, this.chantierForm.value.tech2, this.chantierForm.value.resp)
    const finalArray = { "tech": this.arrayChantier };
    this.sChantier.editChantierTech(finalArray, this.chantier._id).then(res => {
      if (res['error']) {
        return res.error;
      }
      this.ShowChantier();
    });
    this.sHistoriques.createHistorique(chantier.caseId._id, this.case.customer._id, reason, change, value, this.sRoles.user.id).then(res => {
      if (res['error']) {
        alert(res);
      }
    });
  }

  componentMethodName(event: any, isChecked: boolean) {
    if (isChecked) {
      this.validTech.push(event.target.value)
    }
    else {
      let index = this.validTech.indexOf(event.target.value);
      this.validTech.splice(index, 1);
    }
    this.chantier.tech = this.validTech
  }

  goToEditFinancement(currentPiece, caseId) {
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['case-template', caseId], { state: { value: 'pieceEdit', currentPiece: currentPiece } });
  }

  showAllTechs() {
    this.sUser.getAllTechs().subscribe(res => {
      this.allTechs = res['users']
    })
  }

}
