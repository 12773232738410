import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-part0',
  templateUrl: './part0.component.html',
  styleUrls: ['./part0.component.scss']
})
export class Part0Component implements OnInit {

  caseId

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.caseId = this.route.snapshot.params['id'];

  }
}
