<div class="admin-dashboard">
  <h2 class="my-3">Pégase vous souhaite la bienvenue, <strong style="color: var(--tertiarycrm);">{{ user.username
      }}</strong></h2>
  <div style="margin: 2% 0;">
    <ul class="d-flex custom-tab">
      <li [ngClass]="showAdminDashboard? 'active-li' : '' " (click)="showDashboard('admin')"
        *ngIf="this.sRoles.userRole === 'Admin'">Commercial</li>
      <li [ngClass]="showComptaDashboard? 'active-li' : '' " (click)="showDashboard('compta')"
        *ngIf="this.sRoles.userRole === 'Admin' && this.user.mail != 'communication2@enerconfort.com'">Comptabilité</li>
      <li [ngClass]="showAdvDashboard? 'active-li' : '' " (click)="showDashboard('adv')"
        *ngIf="this.sRoles.userRole === 'Admin' && this.user.mail != 'communication2@enerconfort.com'">Adv</li>
      <li [ngClass]="showSuiviDossiers? 'active-li' : '' " (click)="showDashboard('commerciaux')"
        *ngIf="this.sRoles.userRole === 'Admin' && this.user.mail != 'communication2@enerconfort.com'">Dossiers</li>
    </ul>
  </div>

  <div *ngIf="showAdminDashboard">
    <div *ngIf="Global;  then global else agency"></div>
    <ng-template #global>
      <div *ngIf="user.role == 'Admin' || user.role === 'Adv'">
        <div>

          <div class="row export">
            <div (click)="generateExcel()">
              Exporter <img style="width: 40px;height: 40px;" src="../../../assets/icons/excel.png" alt="excel" />
            </div>

          </div>
          <div class="d-flex" style="width: 40%;">
            <div style="width: 32%;margin-right: 11%; margin-bottom: 2rem;">
              <mat-label>{{selectedMonth.value}}</mat-label>
              <mat-select [(ngModel)]="selectedMonth" (selectionChange)="showCasesBySelectedDate()">
                <mat-option *ngFor="let month of months" [value]="month.value">{{ month.name }}</mat-option>
              </mat-select>
            </div>

            <div style="width: 32%;">
              <mat-select [(ngModel)]="selectedYear" (selectionChange)="showCasesBySelectedDate()">
                <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
              </mat-select>
            </div>
          </div>
          <div class="row">

            <div class="col-xs-5 col-lg-4 col-sm-12 col-m-12">
              <div class="card " style="height: 96%;">
                <div class="card-content" style="height: 25pc;">

                  <h3>Performance globale</h3>
                  <div class="mt-3" style="font-size: 1.5rem;">
                    CA brut :
                    <span class="ml-2">{{ adminCases?.caBrutTotal | currency:'EUR':"symbol" }}</span>

                  </div>
                  <div class="mt-3" style="font-size: 1.5rem;">
                    CA net : <span class="ml-2">{{ adminCases?.caNetTotal | currency:'EUR':"symbol"}}</span>
                  </div>
                  <div class="mt-3">Taux d'annulation :
                    <!-- <span>{{ adminCases?.taux_annulation | number :'2.1-2' }} % </span> -->
                    <span>{{ calculateGlobalTauxAnnulation() | number: '2.1-2' }} %</span>
                  </div>

                  <button class="btn-tertiarycrm suivi-annuel" (click)="goToSuiviAnnuel()">Voir suivi
                    annuel</button>
                </div>
              </div>

            </div>

            <div class="col-xs-5 col-lg-4 col-sm-12 col-m-12">
              <div class="card ">
                <div class="card-content global  ">
                  <h3>CA brut /commerciaux </h3>
                  <div class="card-commerciaux">
                    <div *ngFor="let commerciaux of adminCases?.commerciauxCaBrut let index = index"
                      class="d-flex justify-content-between">
                      <p>
                        {{commerciaux.commercial}}
                      </p>
                      <p> {{commerciaux.caBrut | currency:'EUR':"symbol" }}</p>
                    </div>
                    <div *ngFor="let commercial of commerciauxZero" class="d-flex justify-content-between">
                      <p>{{commercial.username}}</p>
                      <p> {{ 0 | currency:'EUR':"symbol" }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-5 col-lg-4 col-sm-12 col-m-12">
              <div class="card ">
                <div class="card-content global ">
                  <h3>CA net /commerciaux </h3>
                  <div class="card-commerciaux">

                    <div *ngFor="let commerciaux of adminCases?.commerciauxCaNet let index = index"
                      class="d-flex justify-content-between">
                      <p>
                        <img style="  width: 20px;height: 20px;position: absolute;left: 4px;"
                          src="../../../assets/icons/or.png" *ngIf="index === 0" />
                        <img style="  width: 20px;height: 20px;position: absolute;left: 4px;"
                          src="../../../assets/icons/argent.png" *ngIf="index === 1" />
                        <img style="  width: 20px;height: 20px;position: absolute;left: 4px;"
                          src="../../../assets/icons/bronze.png" *ngIf="index === 2" />
                        {{commerciaux.commercial}}
                      </p>
                      <p> {{commerciaux.caNet | currency:'EUR':"symbol" }}</p>
                    </div>
                    <div *ngFor="let commercial of commerciauxZero" class="d-flex justify-content-between">
                      <p>{{commercial.username}}</p>
                      <p> {{ 0 | currency:'EUR':"symbol" }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="row">

            <div *ngFor="let financement of adminCases?.financements let index = index"
              class="col-xs-5 col-lg-3 col-sm-12 col-m-12">
              <div class="card" style="cursor: pointer;">
                <div style="height: 20vh;" class="card-content" (click)="openDetailPartenaire(financement.organisme)">
                  <img style="position:absolute;width: 60px;height: 60px;"
                    src="../../../assets/icons/favorite/{{ financement.organisme }}.svg" alt="organisme" />
                  <h3 class="text-center"> {{financement.organisme === 'cofidis' ? 'Autres' : financement.organisme |
                    titlecase }}</h3>
                  <div class="text-center">
                    CA net : <span>{{financement.price_ht | currency:'EUR':"symbol" }}</span>
                  </div>
                </div>
              </div>
            </div>

          </div>


          <div class="row">

            <div class="col-xs-5 col-lg-6 col-sm-12 col-m-12"
              *ngFor="let agence of adminCases?.agences; let index = index">
              <div class="card">
                <div (click)="showAgencyDetails(agence)" class="card-content">
                  <h3> {{agence.agence}}</h3>
                  <div class="agency_graph">
                    <div style="width: 35vh;">
                      <canvas *ngIf="!mobile" baseChart
                        [data]="[agence.caBrutTotal - agence.caNetTotal, agence.caBrutTotal]" [chartType]="'doughnut'"
                        [options]="optionsDoughnut" [colors]="chartColors">
                      </canvas>
                    </div>
                    <div style="display: flex; flex-direction: column; justify-content: space-between;  ">
                      <div
                        style="display: flex; flex-direction: column; border-left: 0.3rem solid #3A3B5A; padding-left: 10px;  ">

                        CA Brut: <span>{{agence.caBrutTotal | currency:'EUR':"symbol" }}</span>
                      </div>
                      <div
                        style="display: flex; flex-direction: column;  border-left: 0.3rem solid #E95E24; padding-left: 10px; ">
                        CA net : <span>{{agence.caNetTotal | currency:'EUR':"symbol" }}</span>
                      </div>
                      <div>
                        Taux d'annulation : <span>{{agence.taux_annulation | number:'2.1-2' }} % </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-5 col-lg-6 col-sm-12 col-m-12">
              <div class="card" style="height: 97%;  ">
                <div class="card-content perfAgency " style=" height: 100%; ">
                  <h3>Chiffre d'affaires agence</h3>
                  <div style="display: flex; ">
                    <div class="col-xs-5 col-lg-6 col-sm-12 col-m-12">
                      <canvas id="myChart" height="450"></canvas>
                    </div>
                    <div class="col-xs-5 col-lg-6 col-sm-12 col-m-12"
                      style=" display: flex; flex-direction: column; justify-content: space-evenly; ">
                      <div *ngFor="let agence of adminCases?.agences">
                        <h3>{{agence.agence}}</h3>
                        <div style="display: flex; justify-content: space-between;">
                          <div style="display: inline-grid;"> <span style="color: #3a3b5a;">Brut : </span> {{
                            agence.caBrutTotal | number :'2.1-2' }} € </div>
                          <div style="display: inline-grid;"> <span style="color: #E95E24;">Net: </span> {{
                            agence.caNetTotal | number :'2.1-2' }} € </div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>

            <div class="col-xs-5 col-lg-6 col-sm-12 col-m-12">
              <div class="card">
                <h3 style="padding: 5% 5% 0;">Taux RDV Qualifié </h3>
                <div class="card-content perfAgency ">
                  <div *ngFor="let commercial of this.sStat.showCommercial" class="card-global-commerciaux  ">
                    <p>{{commercial.username}}</p>
                    <p *ngIf=" ratedMonth[0]"> {{commercial.rateRdvQualified[actualMonth]}} %</p>
                    <p *ngIf=" ratedMonth[1]"> {{commercial.rateRdvQualified[actualMonth-1]}} % </p>
                    <p *ngIf=" ratedMonth[2]"> {{commercial.rateRdvQualifiedAnnuel}} % </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xs-5 col-lg-3 col-sm-12 col-m-12">
              <div class="card">
                <div class="card-content perfAgency ">
                  <h3>RDV à qualifier </h3>
                  <div *ngIf="this.sStat.waitingQualification.length != 0 " class="chiffre">
                    {{this.sStat.waitingQualification.length}}</div>
                  <div *ngIf="this.sStat.waitingQualification.length == 0 " class="chiffre">0</div>

                  <div>Nouveaux Rendez-vous</div>

                  <button class="btn-tertiarycrm " [routerLink]="['/admin-qualification']">Qualifier</button>
                </div>
              </div>
            </div>

          </div>


        </div>
      </div>
    </ng-template>
    <ng-template #agency>
      <div class="col-xs-5 col-lg-12 col-sm-12 col-m-12 ">
        <div class="card global">
          <div *ngIf="user.role == 'Admin' || user.role === 'Adv'" (click)="showAgency()"><img
              src="../../../assets/icons/retour.svg">Retour</div>
          <div>
            <h3 class="perf-agence mb-4">Performance de l'agence {{selectedAgence.agence}}</h3>
            <div class="row">
              <div class="col-lg-2 col-6">
                <span class="chiffre-agence">{{selectedAgence.caBrutTotal | currency:'EUR':"symbol" }}</span>
              </div>
              <div class="col-lg-2 col-6">
                <span class="chiffre-agence">{{selectedAgence.caNetTotal | currency:'EUR':"symbol"}}</span>
              </div>
              <div>
                <span class="chiffre-agence annulation">{{selectedAgence.taux_annulation | number:'2.1-2'}}
                  %</span>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-2 col-6">
                <p class="subtitle-agence">Chiffre d'affaire brut</p>
              </div>
              <div class="col-lg-2 col-6">
                <p class="subtitle-agence">Chiffre d'affaire net</p>
              </div>
              <div>
                <p class="subtitle-agence annulation">Taux d'annulation</p>
              </div>
            </div>
          </div>
        </div>

        <div class="row">

          <div *ngFor="let commercial of selectedAgence.commerciaux " class="col-xs-5 col-lg-4 col-sm-12 col-m-12"
            [routerLink]="['/admin-user-stat',commercial._id]">
            <div class="card">
              <div class="card-content agency">
                <img class="pp" src="{{getProfil(commercial._id)}}">

                <div class="offset-4" *ngIf="commercial?.commercial">
                  <h3>{{getLastName(commercial.commercial)}}</h3>
                  <h4>{{getFirstName(commercial.commercial)}}</h4>
                </div>

                <div class="offset-4" *ngIf="commercial?.username">
                  <h3>{{getLastName(commercial.username)}}</h3>
                  <h4>{{getFirstName(commercial.username)}}</h4>
                </div>

                <div class="offset-4">
                  <span>CA Brut: </span> {{commercial.caBrut ? (commercial.caBrut | currency:'EUR':"symbol") : (0 |
                  currency:'EUR':"symbol") }}
                </div>
                <div class="offset-4">
                  <span>CA net: </span> {{commercial.caNet ? (commercial.caNet | currency:'EUR':"symbol") : (0 |
                  currency:'EUR':"symbol") }}
                </div>
                <div class="offset-4">
                  <span>Taux d'annulation: </span>{{ commercial.taux_annulation ? (commercial.taux_annulation |
                  number:'2.1-2') + '%' : '0' + '%' }}
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </ng-template>

  </div>



  <div *ngIf="showComptaDashboard">
    <app-compta-dashboard></app-compta-dashboard>
  </div>

  <div *ngIf="showAdvDashboard">
    <app-suivi-dossiers [userId]="advId"></app-suivi-dossiers>
    <app-table-gdd></app-table-gdd>
  </div>

</div>

<div *ngIf="showSuiviDossiers">
  <ul class="d-flex custom-tab" style="width: 117%;">
    <li [ngClass]="selectedAgency === 'Néros' ? 'active-li' : '' " (click)="showCommOfAgency('Néros')">Néros</li>
    <li [ngClass]="selectedAgency === 'Sparthios' ? 'active-li' : '' " (click)="showCommOfAgency('Sparthios')">Sparthios
    </li>
    <li [ngClass]="selectedAgency === 'Inti' ? 'active-li' : '' " (click)="showCommOfAgency('Inti')">Inti</li>
    <li [ngClass]="selectedAgency === 'Hernos' ? 'active-li' : '' " (click)="showCommOfAgency('Hernos')">Hernos</li>
    <li [ngClass]="selectedAgency === 'Amon' ? 'active-li' : '' " (click)="showCommOfAgency('Amon')">Amon</li>

  </ul>
  <ul class="d-flex custom-tab" style="width: 117%;" *ngIf="commerciaux && commerciaux.length > 0">
    <li *ngFor="let commercial of commerciaux" [ngClass]="selectedId === commercial._id ? 'active-li' : '' "
      [value]=commercial.username (click)="showComm(commercial._id)">{{ commercial.username }}</li>
  </ul>
  <app-suivi-affaires [userId]="selectedId"></app-suivi-affaires>
</div>
