<img
style="
position: relative;
    left: 97%;
    width: 6%;
    cursor: pointer;"
src="../../../assets/icons/cross.svg" (click)="closeModale()">
<h2>RDV Fid</h2>
<p>Veuillez indiquer <strong>l'heure et la date</strong> de votre RDV Fid</p>

<form [formGroup]="rdvForm">

  <div formArrayName="rdvs" class="rdvs">

    <div *ngFor="let employee of rdvs().controls; let empIndex=index">

      <div [formGroupName]="empIndex">
        <div class="first-row">
          <mat-form-field>
            <input matInput type="text" formControlName="type">
          </mat-form-field>

          <div>
            <input placeholder="Date"[owlDateTime]="dt2" placeholder="JJ/MM/AAAA xxhxx" class="empty-date" formControlName="date" [owlDateTimeTrigger]="dt2">
            <owl-date-time firstDayOfWeek="1" #dt2></owl-date-time>
          </div>
        </div>
          <textarea matInput type="text" formControlName="description" placeholder="Débrief"></textarea>

      </div>
    </div>
  </div>

  <div class="row row-btn">
    <button class="btn-secondarycrm" type="button" (click)="combineArrays('ajouté','nouveau rdv',rdvForm.value.rdvs[0].type)">Valider</button>
  </div>
</form>


