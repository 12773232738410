import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-test-connaissances',
  templateUrl: './test-connaissances.component.html',
  styleUrls: ['./test-connaissances.component.scss']
})
export class TestConnaissancesComponent implements OnInit {
  selectedCol: string | null = null;
  @Input() reponses: [string];
  @Input() question: string;
  @Input() img: string;
  @Input() partieSuivante: string;

  public caseId;
  public book;
  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.caseId = this.route.snapshot.params['id'];

    this.book = JSON.parse(localStorage.getItem('book'));

  }

  public selectCol(value: string) {
    this.selectedCol = value;
    if (this.partieSuivante === 'part2') {
      this.book.question1 = {
        reponse: value,
        bonneReponse: "70%"
      }
      if (value === "70%") {
        this.book.question1.valid = true;
      }
      else {
        this.book.question1.valid = false;
      }
    }
    else if (this.partieSuivante === 'part7') {

      this.book.question2 = {
        reponse: value,
        bonneReponse: "Habitat"
      }
      if (value === "Habitat") {
        this.book.question2.valid = true;
      }
      else {
        this.book.question2.valid = false;
      }
    }
    else {
      this.book.question3 = {
        reponse: value,
        bonneReponse: "Toiture"
      }
      if (value === "Toiture") {
        this.book.question3.valid = true;
      }
      else {
        this.book.question3.valid = false;
      }
    }

  }

  suivant() {
    localStorage.setItem('book', JSON.stringify(this.book));
    this.router.navigate([`/book/${this.partieSuivante}`, this.caseId]);
  }
}
