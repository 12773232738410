import { Component, ElementRef, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import infoData from '../../../assets/json/infoData.json'

@Component({
  selector: 'app-part17',
  templateUrl: './part17.component.html',
  styleUrls: ['./part17.component.scss']
})

export class Part17Component implements OnInit {
  @ViewChild('textAnim') textAnim: ElementRef;

  dataIndex = 0
  infoData;
  caseId
  lineY1: number = -8;
  lineY2: number = 100;
  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.infoData = infoData;
    this.caseId = this.route.snapshot.params['id'];
  }

  clickNext() {
    this.dataIndex++;
    if (this.dataIndex > 4) {
      this.router.navigate(['/book/part18', this.caseId]);
    }
    this.updateLineHeight();
    this.triggerAnimation();


  }

  clickPrev() {
    this.dataIndex--;
    if (this.dataIndex < 0) {
      this.router.navigate(['/book/part16', this.caseId]);
    }
    this.updateLineHeight(true);
    this.triggerAnimation();

  }

  updateLineHeight(isNegatif?: boolean) {
    if (isNegatif) {
      this.lineY2 -= 125;
      if (this.dataIndex === 3) {
        this.lineY2 -= 125;
      }
    }
    else {
      this.lineY2 += 125;
      if (this.dataIndex === 4) {
        this.lineY2 += 125;
      }
    }

  }

  triggerAnimation(): void {
    const element = this.textAnim.nativeElement;
    element.classList.remove('fadeinText');
    void element.offsetWidth;
    element.classList.add('fadeinText');
  }
}

