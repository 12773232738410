<div class="book-container">
  <img class="logo_couleur" src="./../../../assets/book/logo_normal.jpg" alt="logo" />

  <h1 class="dpe-title">Votre <strong>DPE</strong></h1>
  <div class="right-half">
    <div class="row-half">
      <span class="span-item delay-0" (click)="selectSpan('G')" [ngClass]="{ 'span-selected': isSelected === 'G' }">
        <img src="./../../../assets/book/part5/G.png" />
      </span>
      <span class="span-item delay-1" (click)="selectSpan('D')" [ngClass]="{ 'span-selected': isSelected === 'D' }">
        <img src="./../../../assets/book/part5/D.png" />
      </span>
    </div>
    <div class="row-half">
      <span class="span-item delay-2" (click)="selectSpan('B')" [ngClass]="{ 'span-selected': isSelected === 'B' }">
        <img src="./../../../assets/book/part5/B.png" />
      </span>
      <span class="span-item delay-3" (click)="selectSpan('A')" [ngClass]="{ 'span-selected': isSelected === 'A' }">
        <img src="./../../../assets/book/part5/A.png" />
      </span>
    </div>
    <div class="buttons">
      <button *ngIf="isSelected" class="primary-button" [routerLink]="['/book/part5bis', caseId]">
        Découvrez votre DPE
      </button>
    </div>
  </div>
  <img class="bg-img" src="./../../../assets/book/p5_left.png" />

</div>