import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrl} from '@environments/env';

@Injectable({
  providedIn: 'root'
})
export class FinancementsService {

  url = apiUrl.apiUrl + '/financement/';
  //url = 'http://localhost:4000/financement/'

  headers = { 'Content-Type': 'application/json' };

  constructor(
    private http: HttpClient,

  ) { }

  getAllFinancements() {
    return this.http.get(this.url + 'financements');
  }

  getFinancementsByCaseId(id) {
    return this.http.get(this.url + id);
  }
  getFinancementById(id) {
    return this.http.get(this.url + 'financement/' + id);
  }

  delete(id) {
    return this.http.delete(this.url + id);
  }

  editFinancement(financement, financementId): Promise<any> {
    return this.http
    .put(this.url + financementId, JSON.stringify(financement) , { headers: this.headers })
    .toPromise()
    .then(res => res as any);
  }

  createFinancement(financementForm): Promise<any> {
    return this.http
      .post(this.url + 'add', JSON.stringify(financementForm), { headers: this.headers })
      .toPromise()
      .then(res => res as any);
  }
}
