import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ModalPrevisualizeComponent } from '@app/pv/modal-previsualize/modal-previsualize.component';
import { NoModalComponent } from '@app/pv/no-modal/no-modal.component';
import { CasesService } from '@app/services/cases.service';
import { PdfsService } from '@app/services/pdfs.service';
import { PiecesService } from '@app/services/pieces.service';

@Component({
  selector: 'app-recette-bt-monobloc',
  templateUrl: './recette-bt-monobloc.component.html',
  styleUrls: ['./recette-bt-monobloc.component.scss']
})
export class RecetteBtMonoblocComponent {
  @Input() currentKit;
  @Input() case;
  @Input() chantierId
  @Input()
  public data: any

  @Output()
  public onData: EventEmitter<any> = new EventEmitter<any>();
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  capacities = ['200 L','250 L', '270 L']

  _step: number = 1;

  @Input()
  set step(_step: number) {
    this._step = this.parseNumber(_step);
  }
  isValid
  createDoc
  loader
  multipleImagesPieces

  bt
  passage
  disjoncteur
  liaison_f
  mobile = false

  piece_serie
  piece_serie_bt
  piece_serie_ue_bt
  n_serie_bt
  n_serie_ue_bt
  private parseNumber(num: any): number {
    return +num;
  }
  constructor(
    private _formBuilder: FormBuilder,
    private sCases: CasesService,
    private dialog: MatDialog,
    private sPdfs: PdfsService,
    private sPieces:PiecesService
  ) {
    if (window.screen.width <= 600) {
      this.mobile = true;
    }
   }


  ngAfterContentInit() {
    this.updateData(33)
    this.checkPieceOfCibleNserie()
    this.checkPieceOfCible()
    this.firstFormGroup = this._formBuilder.group({
      marque_type: [this.currentKit.marque_type, Validators.required],
      capacite: [this.currentKit.capacite, Validators.required],
      local_pose: [this.currentKit.local_pose, Validators.required],
      volume: [this.currentKit.volume, Validators.required],
      hors_gel: [this.currentKit.hors_gel, Validators.required],
      gaine_entree_air: [this.currentKit.gaine_entree_air, Validators.required],
      longueur_gaine_entree: [this.currentKit.longueur_gaine_entree, Validators.required],
      coude_entree_air: [this.currentKit.coude_entree_air, Validators.required],
      gaine_sortie_air: [this.currentKit.gaine_sortie_air, Validators.required],
      longueur_gaine_sortie: [this.currentKit.longueur_gaine_sortie, Validators.required],
      coude_sortie_air: [this.currentKit.coude_sortie_air, Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      gaine_conforme: [this.currentKit.gaine_conforme, Validators.required],
      bouclage: [this.currentKit.bouclage, Validators.required],
      groupe_secu: [this.currentKit.groupe_secu, Validators.required],
      cablage: [this.currentKit.cablage, Validators.required],
      fuite: [this.currentKit.fuite, Validators.required],
      prog_ballon: [this.currentKit.prog_ballon, Validators.required],
      tuyauterie: [this.currentKit.tuyauterie, Validators.required],
      mise_en_service: [this.currentKit.mise_en_service, Validators.required],
      documentation: [this.currentKit.documentation, Validators.required],
      mise_en_oeuvre: [this.currentKit.mise_en_oeuvre, Validators.required],
      nettoyage: [this.currentKit.nettoyage, Validators.required],
      etiquette: [this.currentKit.etiquette, Validators.required],
      disjoncteur_repere: [this.currentKit.disjoncteur_repere, Validators.required],
      raccordement_condensat: [this.currentKit.raccordement_condensat, Validators.required],
    });
  }
  updateData(data) {
    this.onData.emit(data)
    console.log(data)
  }

  getCurrentKitAndSaveChange(event,key) {

    this.currentKit[key] = event;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === this.currentKit.type);

    array[index] = this.currentKit

    this.case.kits_vendus = JSON.stringify(array)

    this.sCases.editCase(this.case, this.case._id).then(res => console.log(res))
  }

  openDialog(value): void {
    const dialogRef = this.dialog.open(NoModalComponent, {
      width: '640px',
      disableClose: true,
      data: {
        currentKit: this.currentKit,
        case: this.case,
        value: value
      }
    });
  }
  addOption(name,reason, option,product, value) {

    let find = JSON.parse(this.case.kits_vendus).find(x => x.name === name);
    find[option] = value;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === name);
    const selected = array[index]
    this.currentKit = selected
    selected[option] = value

    this.case.kits_vendus = JSON.stringify(array)
    this.sCases.editCase(this.case, this.case._id).then(res => console.log(res))
  }

  incrementValueCoudeEntree(step: number = 1,name,reason, option,product): void {
    this.currentKit['coude_entree_air'] = this.currentKit['coude_entree_air'] + step
    const newValue = this.currentKit['coude_entree_air']
    this.firstFormGroup.get('coude_entree_air').setValue(newValue);

    this.addOption(name,reason, option,product, newValue)
  }

  incrementValueCoudeSortie(step: number = 1,name,reason, option,product): void {

    this.currentKit['coude_sortie_air'] = this.currentKit['coude_sortie_air'] + step
    const newValue = this.currentKit['coude_sortie_air']
    this.firstFormGroup.get('coude_sortie_air').setValue(newValue);

    this.addOption(name,reason, option,product, newValue)
  }

  async valid() {
    this.isValid = true
    this.loader = true
    this.createDoc = await this.generateDoc();
    this.loader = false
    this.openDialogPrevisualize()
  }


  async generateDoc() {
    let response = await this.sPdfs.generateRecette(this.chantierId,'recette_bt_monobloc',this.case,this.currentKit,"","").then(res => {
      if(res === 'ok') {
        console.log(res)
      }
    })
    return response;
  }

  openDialogPrevisualize(): void {
    setTimeout(() => {
      const dialogRef = this.dialog.open(ModalPrevisualizeComponent, {
        width: '100vh',
        height:  '100vh',
        maxWidth: '100vh',
        maxHeight: '100vh',
        hasBackdrop: false,
        disableClose: true,
        panelClass: 'pdf-visualize',
        data: {
          titlePiece: 'recette_bt_monobloc',
          caseId: this.case._id,
          case: this.case,
          kit: this.currentKit,
          chantierId: this.chantierId
        }
      });
      dialogRef.afterClosed().subscribe(result => {

      });
    }, 2000);

  }

   selectMultiple(event, title) {
    if (event.target.files.length > 0) {
      this.multipleImagesPieces = event.target.files;
      console.log(this.multipleImagesPieces);
    }
    this.onSubmit(title)
  }

  checkPieceOfCible() {
    this.sPieces.getPiecesByChantierIdAndCible(this.chantierId._id, 'recette_monobloc').subscribe(res => {
      console.log(res)
      const pieces:any = res
      if(pieces.find(e => e.title === 'Ballon thermodynamique'))
        this.bt = true
      if(pieces.find(e => e.title === 'Passage en batiment et liaison'))
        this.passage = true
      if(pieces.find(e => e.title === 'Disjoncteur'))
        this.disjoncteur = true
      if(pieces.find(e => e.title === 'Liaison frigorifique'))
        this.liaison_f = true

    })
  }

  onSubmit(title) {
    const form = {
      title: title,
      qualification: 'Conforme',
      caseId: this.case._id,
      created_at: new Date(),
      type: 'technicien',
      chantier: this.chantierId._id,
      cible: 'recette_monobloc',
      description: '',
    }
    this.sPieces.addTechPiece(form, this.multipleImagesPieces, this.case._id, this.chantierId._id)
     setTimeout(() => {
      this.checkPieceOfCible()
    }, 1100);
  }

  selectMultipleNserie(event, title:string, n_serie:string) {
    if (event.target.files.length > 0) {
      this.multipleImagesPieces = event.target.files;
    }
    this.onSubmitNserie(title, n_serie)
  }

  onSubmitNserie(title:string,n_serie:string) {
    const form = {
      title: title,
      qualification: 'Conforme',
      caseId: this.case._id,
      created_at: new Date(),
      type: 'technicien',
      chantier: this.chantierId._id,
      cible: n_serie,
      description: '',
    }
    this.sPieces.addTechPiece(form, this.multipleImagesPieces, this.case._id, this.chantierId._id)
    setTimeout(() => {
      this.checkPieceOfCibleNserie()
    }, 1100);
  }

  checkPieceOfCibleNserie() {
    this.sPieces.getPiecesByChantierId(this.chantierId._id).subscribe(res => {
      console.log("piece_serie",res)
      this.piece_serie= res

      if(this.piece_serie.find(e => e.title === 'N° de serie ballon thermodynamique') && this.currentKit.type === 'Ballon thermodynamique') {
        this.piece_serie_bt = this.piece_serie.filter(e => e.title === 'N° de serie ballon thermodynamique')
        this.n_serie_bt = true
      }
      if(this.piece_serie.find(e => e.title === 'N° de serie UE ballon thermodynamique') && this.currentKit.type === 'Ballon thermodynamique') {
        this.piece_serie_ue_bt = this.piece_serie.filter(e => e.title === 'N° de serie UE ballon thermodynamique')
        this.n_serie_ue_bt = true
      }
    })

  }
}
