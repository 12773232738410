<app-sidebar *ngIf="!_router.url.includes('book')"></app-sidebar>
<div class="content">
    <router-outlet></router-outlet>
</div>
<app-bottombar *ngIf="mobile"></app-bottombar>


<ng-template #template let-toastRef>

  <div  class="custom_toast"  (click)='navigateTo()' (click)="toastRef.close({ dismissedByAction: true })">
    <div class="sender_c">
      <img src="../assets/bar-icons/messagerie.svg" alt="">
      <p class="toast_sender">{{ toastRef?.data.dataSender }}</p>
    </div>

    <p class="toast_text"> {{ toastRef?.data.dataText }} </p>
    <p class="toast_date">{{ toastRef?.data.dataDate }}</p>
  </div>


  <div (click)="toastRef.close({ dismissedByAction: true })" class="toast_voir"></div>
  <span id="audio"></span>

 </ng-template>
