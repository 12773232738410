import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PrimesService } from '@app/services/primes.service';
import { CasesService } from '@app/services/cases.service';
import { Router } from '@angular/router';
import { HistoriqueService } from '@app/services/historique.service';
import { RolesService } from '@app/services/roles.service';

@Component({
  selector: 'app-case-prime-add',
  templateUrl: './case-prime-add.component.html',
  styleUrls: ['./case-prime-add.component.scss']
})
export class CasePrimeAddComponent implements OnInit {

  date
  type
  primeForm:FormGroup
  products = []
  case;
  constructor(
    private dialogRef: MatDialog,
    private fb: FormBuilder,
    private sPrimes:PrimesService,
    private sCases: CasesService,
    private router: Router,
    private sHistoriques: HistoriqueService,
    private sRoles: RolesService,
    @Inject(MAT_DIALOG_DATA) public caseId: any,

  ) {
    this.showCaseById()

    this.primeForm = this.fb.group({
      caseId: [this.caseId.caseId],
      type: ["cee"],
      state: ["En cours"],
      date_saisie: [],
      date_complement: [],
      date_virement: [],
      date_envoi: [],
      date_appel: [],
      envoi: [false],
      description: [],
      montant: [],
      product: [this.products],
      n_appel: [],
      created_at: [new Date()]
    });
   }

  ngOnInit(): void { }

  addPrime() {
    this.sPrimes.createPrime(this.primeForm.value).then(res => {
      this.sHistoriques.createHistorique(this.caseId.caseId, this.case.customer._id, 'ajouté','demande de prime', this.primeForm.value.type,this.sRoles.user.id).then(res => {
      })
      this.closeModale()
    })
  }

  showCaseById() {
    this.sCases.getCaseById(this.caseId.caseId).subscribe(res => {
      this.case = res[0]
      this.products = JSON.parse(this.case.kits_vendus).filter(res => res.active)

    })
  }

  closeModale() {
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['case-template', this.caseId.caseId], { state: { value: 'primeList' } });
    this.dialogRef.closeAll();
  }



}
