<div class="mt-5">
  <p (click)="goBack()"><img src="../../../assets/icons/retour.svg">Retour</p>

  <div *ngFor="let historique of historiques" class="historiques">
    <h5>{{ historique.created_by.username }}</h5>
    <p>
      a {{ historique.reason }} {{ historique.change }} : {{ historique.value }}
    </p>
    <p class="created-at">{{ historique.created_at |date: 'short'}}</p>

  </div>

  <div class="right-card">
    <h4>Action réalisée</h4>

    <div>
      <input [disabled]="choiceSelected === 'edité' || choiceSelected === 'supprimé'" type="checkbox" name="checkboxName" value="ajouté" (change)="$event.target.checked? (isfrmChecked = true) : isfrmChecked = false;
      componentMethodName($event,isfrmChecked);"><label class="ml-2 mt-2">Ajout</label>
    </div>

    <div>
      <input [disabled]="choiceSelected === 'ajouté' || choiceSelected === 'supprimé'" type="checkbox" name="checkboxName" value="edité" (change)="$event.target.checked? (isfrmChecked = true) : isfrmChecked = false;
      componentMethodName($event,isfrmChecked);"><label class="ml-2">Modification</label>
    </div>

    <div>
      <input [disabled]="choiceSelected === 'edité' || choiceSelected === 'ajouté'" type="checkbox" name="checkboxName" value="supprimé" (change)="$event.target.checked? (isfrmChecked = true) : isfrmChecked = false;
      componentMethodName($event,isfrmChecked);"><label class="ml-2">Suppresion</label>
    </div>

<!--     <h4>Rôles</h4>
    <div>
      <input type="checkbox" name="checkboxName" value="Adv" (change)="$event.target.checked? (isfrmChecked = true) : isfrmChecked = false;
      filterByRole($event,isfrmChecked);"><label>ADV</label>
    </div>

    <div>
      <input type="checkbox" name="checkboxName" value="Commercial" (change)="$event.target.checked? (isfrmChecked = true) : isfrmChecked = false;
      filterByRole($event,isfrmChecked);"><label>Commercial</label>
    </div>

    <div>
      <input type="checkbox" name="checkboxName" value="Admin" (change)="$event.target.checked? (isfrmChecked = true) : isfrmChecked = false;
      filterByRole($event,isfrmChecked);"><label>Administrateur</label>
    </div> -->

  </div>

</div>
