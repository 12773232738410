<!-- <h1 class="mt-5 mb-4">Dossiers signés des 30 derniers jours</h1>
 -->
   <button class="btn-secondarycrm mb-4" (click)="onBtnExport()">
    Export
    <img src="../../../assets/icons/export-csv.svg">
  </button>
<ag-grid-angular *ngIf="rowData != []"
#agGrid
style="width: 90vw; height: 70vh; margin-bottom: 5%;"
id="myGrid"
class="ag-theme-alpine"
[columnDefs]="columnDefs"
[defaultColDef]="defaultColDef"
[rowData]="cases"
[rowStyle]="rowStyle"
(gridReady)="onGridReady($event)"
(cellDoubleClicked)="onSelectionChanged($event)"
rowSelection="single"
[animateRows]="true"
[rowHeight]="rowHeight"
[overlayLoadingTemplate]="loadingTemplate">
</ag-grid-angular>
