<form [formGroup]="mairieForm" *ngIf="this.mairieForm">

  <div class="choices mr-5">
    <p class="m-0">Statut de la demande</p>
    <input type="radio" id="state1" name="state" formControlName="state" value="En cours" (change)="saveChange('edité','statut demande mairie',this.mairieForm.value.state)">
    <label for="state1" [ngClass]="this.mairieForm.value.state === 'En cours' ? 'pe' : 'pe-empty'">En cours</label>

    <input type="radio" id="state2" name="state" formControlName="state" value="Refus" (change)="saveChange('edité','statut demande mairie',this.mairieForm.value.state)">
    <label for="state2" [ngClass]="this.mairieForm.value.state === 'Refus' ? 'non' : 'non-empty'">Refus</label>

    <input type="radio" id="state3" name="state" formControlName="state" value="Complément" (change)="saveChange('edité','statut demande mairie',this.mairieForm.value.state)">
    <label for="state3" [ngClass]="this.mairieForm.value.state === 'Complément' ? 'pe' : 'pe-empty'">Complément</label>

    <input type="radio" id="state4" name="state" formControlName="state" value="Acceptation" (change)="saveChange('edité','statut demande mairie',this.mairieForm.value.state)">
    <label for="state4" [ngClass]="this.mairieForm.value.state === 'Acceptation' ? 'oui' : 'oui-empty'">Acceptation</label>
  </div>

  <div class="choices mr-5">
    <p class="m-0">Demande préalable conforme ?</p>
    <input type="radio" id="dp1" name="dp" formControlName="dp" value=true (change)="saveChange('edité','DP conforme',this.mairieForm.value.dp)">
    <label for="dp1" [ngClass]="this.mairieForm.value.dp === 'true' ? 'oui' : 'oui-empty'">
      <span class="material-icons">
      done
      </span>OUI</label>

      <input type="radio" id="dp2" name="dp" formControlName="dp" value=false (change)="saveChange('edité','DP conforme',this.mairieForm.value.dp)">
      <label for="dp2" [ngClass]="this.mairieForm.value.dp === 'false' ? 'non' : 'non-empty'">
      <span class="material-icons">
        clear
        </span>NON</label>
  </div>

  <div>
    <div class="row">
      <div class="col-2">
      <h6 class="mt-1 mb-2">Date de dépot de dossier</h6>
        <input class="custom-date" formControlName="date_depot" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" (ngModelChange)="saveChange('edité','date rdv',this.mairieForm.value.date_depot)">
        <p>
        <owl-date-time firstDayOfWeek="1" #dt2></owl-date-time>
        </p>
      </div>
      <div class="col-3">
        <h6 class="mt-1 mb-2">Date de fin de validité</h6>
        <p>{{ date_fin | date: 'medium' }}</p>
      </div>
    </div>
  </div>

</form>
