import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { apiUrl} from '@environments/env';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  url = apiUrl.apiUrl + '/produit/';
  private productsUpdate = new BehaviorSubject<any[]>([]);
  productsUpdate$ = this.productsUpdate.asObservable();

  constructor(private http: HttpClient) {}

  getProducts(): Observable<any[]> {
    return this.http.get<any[]>(this.url + 'produits' );
  }

  addProductSAV(product: any): Observable<any> {
    return this.http.post(this.url + 'add', product);
  }

  notifyProductsUpdate(products: any[]) {
    this.productsUpdate.next(products);
  }
}
