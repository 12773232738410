export enum Role {
    User = 'User',
    Admin = 'Admin',
    Commercial = 'Commercial',
    RespCommercial = "Responsable d'agence",
    RespAdv = "Responsable ADV",
    Tmk = 'Tmk',
    SuperTmk = 'Superviseur TMK',
    Adv = 'Adv',
    Technicien = 'Technicien',
    RespTechnicien = 'Responsable Technicien',
    Compta = 'Compta',
    Spectateur = 'Spectateur',
    Logistique = 'Logistique',
    Rh = 'Rh',
    Beenergie = 'Beenergie',
    Communication = 'Communication'
}
