import { Role } from "./role";

export class User {
    _id: number;
    id: number;
    username: string;
    mail: string;
    role: Role;
    agency: string;
    sector: string;
    token?: string;
    created_at: Date;
    isActive:Boolean;
}
