<mat-horizontal-stepper [linear]="true" #stepper>
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup">
      <ng-template matStepLabel>Fill out your name</ng-template>

      <div>
        <h5>Unité extérieure</h5>
        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Marque et type</label>
            <input class="custom-input" type="text" formControlName="marque_type_ue" (change)="getCurrentKitAndSaveChange(this.firstFormGroup.value.marque_type_ue,'marque_type_ue')">
            <div *ngIf="firstFormGroup.get('marque_type_ue').invalid && firstFormGroup.get('marque_type_ue').errors && (firstFormGroup.get('marque_type_ue').dirty || firstFormGroup.get('marque_type_ue').touched)">
            <small class="text-danger"
                *ngIf="firstFormGroup.get('marque_type_ue').hasError('required')">
                Champ requis
            </small>
            </div>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <div class="button-wrapper mt-1">
              <label>N° de série</label>
              <label class="upload-label" for="upload" *ngIf="!n_serie_pacae_ue">+ Ajouter la photo</label>
              <p class="mb-0">
                <a target="blank" class="check" *ngIf="n_serie_pacae_ue" [routerLink]="['/detail-piece', piece_serie_pacae_ue[0]._id]">Photos n° de série</a>
              </p>
              <p *ngIf="n_serie_pacae_ue" style="color: lightgrey;font-weight: 300; margin-top: 0;
              font-size: small;">Prise le {{ piece_serie_pacae_ue[0].created_at | date:'medium' }}</p>

              <input *ngIf="!n_serie_pacae_ue" accept=".jpg,.jpeg,.png" type="file" multiple name="upload" id="upload" class="upload-box" (change)="selectMultipleNserie($event, 'N° de serie UE PAC Air Eau','n_serie_pacae_ue')">
            </div>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Pose</label>
            <mat-form-field>
              <mat-select formControlName="pose" (selectionChange)="addOption(currentKit.type,'edité', 'pose', 'pose', firstFormGroup.value.pose)">
                <mat-option *ngFor="let pose of poses" [value]="pose">
                  {{ pose }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Espace dissipation chaleur respecté : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="dissipation_respecte" (change)="getCurrentKitAndSaveChange($event.value,'dissipation_respecte')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('dissipation_respecte')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Cablage électrique conforme :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="cablage" (change)="getCurrentKitAndSaveChange($event.value,'cablage')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('cablage')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Installation sur fixation normée : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="norme_fixation" (change)="getCurrentKitAndSaveChange($event.value,'norme_fixation')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('norme_fixation')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Présence de masque entrée/ sortie d’air : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="masque_entree_sortie" (change)="getCurrentKitAndSaveChange($event.value,'masque_entree_sortie')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('masque_entree_sortie')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Référence fluide et poids</label>
            <input class="custom-input"  type="text" formControlName="ref_fluide_poids" (change)="getCurrentKitAndSaveChange(this.firstFormGroup.value.ref_fluide_poids,'ref_fluide_poids')">
            <div *ngIf="firstFormGroup.get('ref_fluide_poids').invalid && firstFormGroup.get('ref_fluide_poids').errors && (firstFormGroup.get('ref_fluide_poids').dirty || firstFormGroup.get('ref_fluide_poids').touched)">
            <small class="text-danger"
                *ngIf="firstFormGroup.get('ref_fluide_poids').hasError('required')">
                Champ requis
            </small>
          </div>
          </div>
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Puissance calorifique mode chaud</label>
            <input class="custom-input"   type="number" formControlName="puissance_chaud" (change)="getCurrentKitAndSaveChange(this.firstFormGroup.value.puissance_chaud,'puissance_chaud')">
            <div *ngIf="firstFormGroup.get('puissance_chaud').invalid && firstFormGroup.get('puissance_chaud').errors && (firstFormGroup.get('puissance_chaud').dirty || firstFormGroup.get('puissance_chaud').touched)">
            <small class="text-danger"
                *ngIf="firstFormGroup.get('puissance_chaud').hasError('required')">
                Champ requis
            </small>
          </div>
          </div>
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col title-margin radio-choice">
            <mat-label>Abonnement Réseau </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="abonnement_reseau" (change)="getCurrentKitAndSaveChange($event.value,'abonnement_reseau')">
              <mat-radio-button value="Monophasé">Monophasé</mat-radio-button>
              <mat-radio-button value="Triphasé" class="ml-4">Triphasé</mat-radio-button>
            </mat-radio-group>
          </div>
  
       </div>
       
       <div class="row">
        <div class="col-lg-6 col-s-6 col-xs-6 custom-col title-margin radio-choice">
          <mat-label>Évacuation des condensats</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="evacuation_condensat" (change)="getCurrentKitAndSaveChange($event.value,'evacuation_condensat')">
            <mat-radio-button value="oui">Oui</mat-radio-button>
            <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="col-lg-6 col-s-6 col-xs-6 custom-col title-margin radio-choice">
          <mat-label>Puissance Abonnement Réseau </mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="puissance_abonnement_reseau" (change)="getCurrentKitAndSaveChange($event.value,'puissance_abonnement_reseau')">
            <mat-radio-button value="6kVA">6kVA</mat-radio-button>
            <mat-radio-button value="9kva" class="ml-4">9kva</mat-radio-button>
            <mat-radio-button value="12kva" class="ml-4">12kva</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-s-6 col-xs-6 custom-col title-margin radio-choice">
          <mat-label>Switch SW8-3 sur «ON»</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="switch_on" (change)="getCurrentKitAndSaveChange($event.value,'switch_on')">
            <mat-radio-button value="oui">Oui</mat-radio-button>
            <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="col-lg-6 col-s-6 col-xs-6 custom-col title-margin radio-choice">
          <mat-label>Présence étiquette Enerconfort/poids du fluide </mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="etiquette_poids_fluide" (change)="getCurrentKitAndSaveChange($event.value,'etiquette_poids_fluide')">
            <mat-radio-button value="oui">Oui</mat-radio-button>
            <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      


       <div class="row">
        <div class="col-lg-3 col-s-6 col-xs-6 custom-col radio-choice title-margin last-col">
          <mat-label>Étiquette marquage société : </mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="etiquette" (change)="getCurrentKitAndSaveChange($event.value,'etiquette')">
            <mat-radio-button value="oui">Oui</mat-radio-button>
            <mat-radio-button value="non" class="ml-4" (click)="openDialog('etiquette')">Non</mat-radio-button>
          </mat-radio-group>
        </div>
     </div>
      <br>

      <div>
        <button class="btn-secondarycrm" matStepperNext [style.opacity]="!firstFormGroup.valid ? '0.4' : '1'" (click)="updateData(66)">Suivant</button>
      </div>
    </div>

    </form>
  </mat-step>


  <mat-step [stepControl]="secondFormGroup">
    <form [formGroup]="secondFormGroup">
      <div>
        <h5>Unité intérieure</h5>
        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Marque et type</label>
            <input class="custom-input" type="text" formControlName="marque_type_ui" (change)="getCurrentKitAndSaveChange(this.secondFormGroup.value.marque_type_ui,'marque_type_ui')">
            <div *ngIf="secondFormGroup.get('marque_type_ui').invalid && secondFormGroup.get('marque_type_ui').errors && (secondFormGroup.get('marque_type_ui').dirty || secondFormGroup.get('marque_type_ui').touched)">
            <small class="text-danger"
                *ngIf="secondFormGroup.get('marque_type_ui').hasError('required')">
                Champ requis
            </small>
            </div>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>N° de série</label>
            <input class="custom-input" type="text" formControlName="marque_type_ui" (change)="getCurrentKitAndSaveChange(this.secondFormGroup.value.marque_type_ui,'marque_type_ui')">
            <div *ngIf="secondFormGroup.get('marque_type_ui').invalid && secondFormGroup.get('marque_type_ui').errors && (secondFormGroup.get('marque_type_ui').dirty || secondFormGroup.get('marque_type_ui').touched)">
            <small class="text-danger"
                *ngIf="secondFormGroup.get('marque_type_ui').hasError('required')">
                Champ requis
            </small>
            </div>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Désignation local de pose</label>
            <input class="custom-input" type="text" formControlName="local_pose" (change)="getCurrentKitAndSaveChange(this.secondFormGroup.value.local_pose,'local_pose')">
            <div *ngIf="secondFormGroup.get('local_pose').invalid && secondFormGroup.get('local_pose').errors && (secondFormGroup.get('local_pose').dirty || secondFormGroup.get('local_pose').touched)">
            <small class="text-danger"
                *ngIf="secondFormGroup.get('local_pose').hasError('required')">
                Champ requis
            </small>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Pose sur mur ou sol supportant la charge : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="support_charge" (change)="getCurrentKitAndSaveChange($event.value,'support_charge')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('support_charge')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Disconnecteur :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="disconnecteur" (change)="getCurrentKitAndSaveChange($event.value,'disconnecteur')">
              <mat-radio-button value="Existant">Existant</mat-radio-button>
              <mat-radio-button value="Installé" class="ml-4">Installé</mat-radio-button>
            </mat-radio-group>
          </div>

        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Paramètre CNF :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="cnf" (change)="getCurrentKitAndSaveChange($event.value,'cnf')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('cnf')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>ECS : Paramètre DP120 à 7K :</mat-label>
            <mat-radio-group aria-label="Oui an option" formControlName="ecs_parametre" (change)="getCurrentKitAndSaveChange($event.value,'ecs_parametre')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="Non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>

        </div>
        


        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Robinet thermostatique : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="robinet" (change)="getCurrentKitAndSaveChange($event.value,'robinet')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('robinet')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Fuite sur les différents raccordements :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="fuite_racco" (change)="getCurrentKitAndSaveChange($event.value,'fuite_racco')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div>
        <h5>Circuit hydrolique</h5>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Rincage complet :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="rincage" (change)="getCurrentKitAndSaveChange($event.value,'rincage')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('rincage')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Pot à boue :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="pot_boue" (change)="getCurrentKitAndSaveChange($event.value,'pot_boue')">
              <mat-radio-button value="Existant">Existant</mat-radio-button>
              <mat-radio-button value="Installé" class="ml-4">Installé</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Désembouage :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="desembouage" (change)="getCurrentKitAndSaveChange($event.value,'desembouage')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('desembouage')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Purge d’air :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="purge" (change)="getCurrentKitAndSaveChange($event.value,'purge')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('purge')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice last-col title-margin">
            <mat-label>Bon fonctionnement des vannes/robinets diffuseurs :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="fonctionnement_diffuseur" (change)="getCurrentKitAndSaveChange($event.value,'fonctionnement_diffuseur')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('fonctionnement_diffuseur')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice last-col title-margin">
            <mat-label>Au moins 1 circuit sans robinet thermo ou électrovanne</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="circuit_sans_robinet" (change)="getCurrentKitAndSaveChange($event.value,'circuit_sans_robinet')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('circuit_sans_robinet')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
          
        </div>

      </div>
        <div>
          <button class="btn-secondarycrm" matStepperNext [style.opacity]="!secondFormGroup.valid ? '0.4' : '1'" (click)="updateData(100)">Suivant</button>
        </div>
    </form>
  </mat-step>

  <mat-step [stepControl]="thirdFormGroup">
    <form [formGroup]="thirdFormGroup">
      <div>
        <h6>Autre</h6>
        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Longueur de la liaison frigo</label>
            <input class="custom-input" type="text" formControlName="liaison_frigo" (change)="getCurrentKitAndSaveChange(this.thirdFormGroup.value.liaison_frigo,'liaison_frigo')">
            <div *ngIf="thirdFormGroup.get('liaison_frigo').invalid && thirdFormGroup.get('liaison_frigo').errors && (thirdFormGroup.get('liaison_frigo').dirty || thirdFormGroup.get('liaison_frigo').touched)">
            <small class="text-danger"
                *ngIf="thirdFormGroup.get('liaison_frigo').hasError('required')">
                Champ requis
            </small>
            </div>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Dénivelé(s) liaison frigo</label>
            <input class="custom-input" type="text" formControlName="denivele_liaison_frigo" (change)="getCurrentKitAndSaveChange(this.thirdFormGroup.value.denivele_liaison_frigo,'denivele_liaison_frigo')">
            <div *ngIf="thirdFormGroup.get('denivele_liaison_frigo').invalid && thirdFormGroup.get('denivele_liaison_frigo').errors && (thirdFormGroup.get('denivele_liaison_frigo').dirty || thirdFormGroup.get('denivele_liaison_frigo').touched)">
            <small class="text-danger"
                *ngIf="thirdFormGroup.get('denivele_liaison_frigo').hasError('required')">
                Champ requis
            </small>
            </div>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Orientation sonde extérieure</label>
            <mat-form-field>
              <mat-select formControlName="orientation_sonde" (selectionChange)="addOption(currentKit.type,'edité', 'orientation_sonde', 'orientation_sonde', thirdFormGroup.value.orientation_sonde)">
                <mat-option *ngFor="let orientation of orientations" [value]="orientation">
                  {{ orientation }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Test étanchéité : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="etancheite" (change)="getCurrentKitAndSaveChange($event.value,'etancheite')">
              <mat-radio-button value="Azote">Azote</mat-radio-button>
              <mat-radio-button value="Détecteur" class="ml-4">Détecteur</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Mise en oeuvre expliquée au client</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="mise_oeuvre" (change)="getCurrentKitAndSaveChange($event.value,'mise_oeuvre')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('mise_oeuvre')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Tirage à vide liaison frigo :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="tirage_liaison_frigo" (change)="getCurrentKitAndSaveChange($event.value,'tirage_liaison_frigo')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('tirage_liaison_frigo')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Nettoyage chantier </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="nettoyage" (change)="getCurrentKitAndSaveChange($event.value,'nettoyage')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('nettoyage')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Section des câbles électriques conforme aux normes :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="section_conforme" (change)="getCurrentKitAndSaveChange($event.value,'section_conforme')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('section_conforme')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Mise en service</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="mise_en_service" (change)="getCurrentKitAndSaveChange($event.value,'mise_en_service')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('mise_en_service')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Étanchéité au passage de la liaison frigo/bâtiment :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="etancheite_ue" (change)="getCurrentKitAndSaveChange($event.value,'etancheite_ue')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('etancheite_ue')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Étiquette marquage société et SAV :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="etiquette" (change)="getCurrentKitAndSaveChange($event.value,'etiquette')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('etiquette')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Disjoncteur PAC repéré sur le tableau électrique : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="disjoncteur_pac" (change)="getCurrentKitAndSaveChange($event.value,'disjoncteur_pac')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('disjoncteur_pac')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Vérifications avant mise en service :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="verif_service" (change)="getCurrentKitAndSaveChange($event.value,'verif_service')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('verif_service')">Non</mat-radio-button>
            </mat-radio-group>
          </div>

        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Tableau électrique près UI avec disjoncteur séparé :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="disjoncteur_separe" (change)="getCurrentKitAndSaveChange($event.value,'disjoncteur_separe')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('disjoncteur_separe')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Document remise au client</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="documentation" (change)="getCurrentKitAndSaveChange($event.value,'documentation')">
              <mat-radio-button value="Documentation technique">Documentation technique</mat-radio-button>
              <mat-radio-button value="Fiche d’intervention" class="ml-4">Fiche d’intervention</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Fiche d'intervention fluide remise au client</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="fiche_inter_fluide" (change)="getCurrentKitAndSaveChange($event.value,'fiche_inter_fluide')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>
          
          <div class="col-lg-12 col-s-6 col-xs-6 custom-col radio-choice title-margin last-col">
            <mat-label>Vérification de l’échange de chaleur au niveau de la totalité des diffuseurs :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="echange_chaleur" (change)="getCurrentKitAndSaveChange($event.value,'echange_chaleur')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" (click)="openDialog('echange_chaleur')">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      <br>

      <div>
        <button class="btn-secondarycrm" matStepperNext [style.opacity]="!thirdFormGroup.valid ? '0.4' : '1'" (click)="updateData(100)">Suivant</button>
      </div>
    </div>
    </form>
  </mat-step>

  <mat-step>

    <textarea class="custom-textarea" placeholder="Observations" [(ngModel)]="currentKit.observation" (change)="getCurrentKitAndSaveChange('observation',case.observation)"></textarea>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Tuyau et calorifugeage
        <label class="upload-label" for="uploadtuyau" *ngIf="!tuyau"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="tuyau"></p>
        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="uploadtuyau" class="upload-box" (change)="selectMultiple($event, 'Tuyau et calorifugeage')">
      </div>

      <div class="button-wrapper mt-3 col-lg-6">
        Evacuation des condensats
        <label class="upload-label" for="uploadcondensat" *ngIf="!condensat"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="condensat"></p>
        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="uploadcondensat" class="upload-box" (change)="selectMultiple($event, 'Sortie des condensats')">
      </div>

    </div>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Photo unité extérieure

        <label class="upload-label" for="uploadue" *ngIf="!ue"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="ue"></p>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="uploadue" class="upload-box" (change)="selectMultiple($event, 'Photo unité extérieure')">
      </div>

      <div class="button-wrapper mt-3 col-lg-6">
        Réseau comble aéroliques
        <label class="upload-label" for="uploadreseau_comble" *ngIf="!reseau_comble"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="reseau_comble"></p>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="uploadreseau_comble" class="upload-box" (change)="selectMultiple($event, 'Réseau comble aéroliques')">
      </div>

    </div>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Photo unité intérieure
        <label class="upload-label" for="uploadui" *ngIf="!ui"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="ui"></p>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="uploadui" class="upload-box" (change)="selectMultiple($event, 'Photo unité intérieure')">
      </div>

    </div>


    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Disjoncteur
        <label class="upload-label" for="uploaddisjoncteur" *ngIf="!disjoncteur"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="disjoncteur"></p>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="uploaddisjoncteur" class="upload-box" (change)="selectMultiple($event, 'Disjoncteur')">
      </div>
    </div>

      <button *ngIf="!isValid" (click)="valid()" class="btn-secondarycrm">Confirmer</button>

  </mat-step>
</mat-horizontal-stepper>
