import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import html2canvas from 'html2canvas';
import { apiUrl} from '@environments/env';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-logistique-visualize',
  templateUrl: './logistique-visualize.component.html',
  styleUrls: ['./logistique-visualize.component.scss']
})
export class LogistiqueVisualizeComponent implements OnInit {
  capturedImage
  kit;
  case;
  caseId;
  pv;
  vmc;
  iso;
  bt;
  pacaa;
  pacae;
  adoucisseur;
  blob;
  showPdf = false;
  today = new Date()
  apiUrl = apiUrl.apiUrl + '/piece/';

  constructor(
    private sCases: CasesService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private sb: MatSnackBar
  ) {
    this.caseId = this.route.snapshot.params['id'];
    this.sCases.getCaseById(this.caseId).subscribe(res => {
      this.case = res[0]
      this.kit = JSON.parse(this.case.kits_vendus).filter(e => e.active && e.valid)
      console.log("kit", this.kit)
      this.kit.forEach(element => {
        if(element.type === 'Panneaux solaires') {
          this.pv = element
        }
        if(element.type === 'VMC Double Flux') {
          this.vmc = element
        }
        if(element.type === 'Isolation') {
          this.iso = element
        }
        if(element.type === 'Ballon thermodynamique') {
          this.bt = element
        }
        if(element.type === 'PAC Air/Air') {
          this.pacaa = element
        }
        if(element.type === 'PAC Air/Eau') {
          this.pacae = element
        }
        if(element.type === 'Adoucisseur d\'eau') {
          this.adoucisseur = element
        }
      });
    })

  }

  ngOnInit(): void {
  }


  getCurrentKitAndSaveChange(event,key,currentKit) {
    currentKit[key] = event;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === currentKit.type);

    array[index] = currentKit


    this.case.kits_vendus = JSON.stringify(array)

    this.sCases.editCase(this.case, this.case._id)
  }

  aaa() {
    this.kit.forEach(element => {
      this.getCurrentKitAndSaveChange(false,'sortie',element)
    });
  }

  clickme() {
    html2canvas(document.querySelector("#capture")).then(canvas => {
      this.capturedImage = canvas.toDataURL();

      canvas.toBlob(function (blob) {
        let url = URL.createObjectURL(blob);
        var reader = new FileReader();
        reader.readAsDataURL(blob);
      });
      const imageName = 'name.png';
      const imageBlob = this.dataURItoBlob(this.capturedImage);
      const imageFile = new File([imageBlob], imageName, { type: 'image/png' });

      this.onSubmitMultiple("Bon de sortie de stock", imageFile)
    });

  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI.replace(/^[^,]+,/, ''));
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  onSubmitMultiple(title,blob) {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', '');
    formData.append('qualification', 'Conforme');
    formData.append('caseId', this.caseId);
    formData.append('filesPieces', blob);
    formData.append('created_at', new Date().toString());


    this.http.post<any>(this.apiUrl + 'multiplePieces', formData).subscribe(
      (res) => {
        this.sb.open(`Pièce ajoutée`, '', { duration: 2000, verticalPosition: 'bottom', panelClass: ['add-snackbar'] });
        //this.router.navigate(['/logistique-dashboard']);
      },
      (err) => console.log(err)
    );

  }

}
