import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ModalPrevisualizeComponent } from '@app/pv/modal-previsualize/modal-previsualize.component';
import { NoModalComponent } from '@app/pv/no-modal/no-modal.component';
import { CasesService } from '@app/services/cases.service';
import { PdfsService } from '@app/services/pdfs.service';
import { PiecesService } from '@app/services/pieces.service';

@Component({
  selector: 'app-recette-pacae',
  templateUrl: './recette-pacae.component.html',
  styleUrls: ['./recette-pacae.component.scss']
})
export class RecettePacaeComponent {

  @Input() currentKit;
  @Input() case;
  @Input() chantierId
  @Input()
  public data: any

  @Output()
  public onData: EventEmitter<any> = new EventEmitter<any>();

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;

  poses = ["au sol","sur chassis"]
  orientations = ["Nord","Sud","Est","Ouest"]

  selected = new FormControl(0);
  pieces
  _step: number = 1;

  multipleImagesPieces

  ui = false
  circuit_vicie = false
  disjoncteur = false
  cablage = false
  circuit_neuf = false
  tuyau
  condensat
  ue
  reseau_comble
  mobile = false
  n_serie = false

  piecesNserie;
  n_serie_pacae_ue
  piece_serie_pacae_ue
  loader
  createDoc
  isValid = false

  @Input()
  set step(_step: number) {
    this._step = this.parseNumber(_step);
  }
  private parseNumber(num: any): number {
    return +num;
  }
  ngAfterContentInit() {
    this.checkPieceNSerie()
    this.checkPiece()
    this.updateData(33)
    this.firstFormGroup = this._formBuilder.group({
      marque_type_ue: [this.currentKit.marque_type_ue, Validators.required],
      pose: [this.currentKit.pose, Validators.required],
      dissipation_respecte: [this.currentKit.dissipation_respecte, Validators.required],
      cablage: [this.currentKit.cablage, Validators.required],
      norme_fixation: [this.currentKit.norme_fixation, Validators.required],
      masque_entree_sortie: [this.currentKit.masque_entree_sortie, Validators.required],
      ref_fluide_poids: [this.currentKit.ref_fluide_poids, Validators.required],
      puissance_chaud: [this.currentKit.puissance_chaud, Validators.required],
      quantite_fluide: [this.currentKit.quantite_fluide, Validators.required],
      etiquette: [this.currentKit.etiquette, Validators.required],
      abonnement_reseau: [this.currentKit.abonnement_reseau, Validators.required],
      puissance_abonnement_reseau: [this.currentKit.puissance_abonnement_reseau, Validators.required],
      switch_on: [this.currentKit.switch_on, Validators.required],
      etiquette_poids_fluide: [this.currentKit.etiquette_poids_fluide, Validators.required],
      evacuation_condensat: [this.currentKit.evacuation_condensat, Validators.required],
      

    });
    this.secondFormGroup = this._formBuilder.group({
        marque_type_ui: [this.currentKit.marque_type_ui, Validators.required],
        local_pose: [this.currentKit.local_pose, Validators.required],
        support_charge: [this.currentKit.support_charge, Validators.required],
        disconnecteur: [this.currentKit.disconnecteur, Validators.required],
        robinet: [this.currentKit.robinet, Validators.required],
        fuite_racco: [this.currentKit.fuite_racco, Validators.required],
        rincage: [this.currentKit.rincage, Validators.required],
        pot_boue: [this.currentKit.pot_boue, Validators.required],
        desembouage: [this.currentKit.desembouage, Validators.required],
        purge: [this.currentKit.purge, Validators.required],
        fonctionnement_diffuseur: [this.currentKit.fonctionnement_diffuseur, Validators.required],
        circuit_sans_robinet: [this.currentKit.circuit_sans_robinet, Validators.required],
        cnf: [this.currentKit.cnf, Validators.required],
        ecs_parametre: [this.currentKit.ecs_parametre, Validators.required],

        
    });

    this.thirdFormGroup = this._formBuilder.group({
      liaison_frigo: [this.currentKit.liaison_frigo, Validators.required],
      denivele_liaison_frigo: [this.currentKit.denivele_liaison_frigo, Validators.required],
      orientation_sonde: [this.currentKit.orientation_sonde, Validators.required],
      etancheite: [this.currentKit.etancheite, Validators.required],
      mise_oeuvre: [this.currentKit.mise_oeuvre, Validators.required],
      tirage_liaison_frigo: [this.currentKit.tirage_liaison_frigo, Validators.required],
      nettoyage: [this.currentKit.nettoyage, Validators.required],
      section_conforme: [this.currentKit.section_conforme, Validators.required],
      mise_en_service: [this.currentKit.mise_en_service, Validators.required],
      etancheite_ue: [this.currentKit.etancheite_ue, Validators.required],
      etiquette: [this.currentKit.etiquette, Validators.required],
      disjoncteur_pac: [this.currentKit.disjoncteur_pac, Validators.required],
      verif_service: [this.currentKit.verif_service, Validators.required],
      disjoncteur_separe: [this.currentKit.disjoncteur_separe, Validators.required],
      documentation: [this.currentKit.documentation, Validators.required],
      echange_chaleur: [this.currentKit.echange_chaleur, Validators.required],
      fiche_inter_fluide: [this.currentKit.fiche_inter_fluide, Validators.required],
    });

  }
  constructor(
    private _formBuilder: FormBuilder,
    private sCases: CasesService,
    private dialog: MatDialog,
    private sPdfs: PdfsService,
    private sPieces: PiecesService) {
      if (window.screen.width <= 1100) {
        this.mobile = true;
      }
     }

  updateData(data) {
    this.onData.emit(data)
  }

  getCurrentKitAndSaveChange(event,key) {

    this.currentKit[key] = event;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === this.currentKit.type);

    array[index] = this.currentKit

    this.case.kits_vendus = JSON.stringify(array)

    this.sCases.editCase(this.case, this.case._id).then(res => console.log(res))
  }


  addOption(name,reason, option,product, value) {

    let find = JSON.parse(this.case.kits_vendus).find(x => x.name === name);
    find[option] = value;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === name);
    const selected = array[index]
    this.currentKit = selected
    selected[option] = value

    this.case.kits_vendus = JSON.stringify(array)

    this.sCases.editCase(this.case, this.case._id)

  }

  openDialog(value): void {
    const dialogRef = this.dialog.open(NoModalComponent, {
      width: '640px',
      disableClose: true,
      data: {
        currentKit: this.currentKit,
        case: this.case,
        value: value
      }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }


  test(bouche_name,value) {

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === this.currentKit.type);

    array[index].bouches_reprise.find(x => x.name === bouche_name);
    let curr = array[index].bouches_reprise.find(x => x.name === bouche_name)
    curr['local_pose'] = value
    this.case.kits_vendus = JSON.stringify(array)
    this.sCases.editCase(this.case, this.case._id).then(res => console.log(res))

  }

  async valid() {
    this.isValid = true
    this.loader = true
    this.createDoc = await this.generateDoc();
    this.loader = false
    this.openDialogPrevisualize()
  }


  async generateDoc() {
    let response = await this.sPdfs.generateRecette(this.chantierId._id,'recette_pacae',this.case,this.currentKit,"","").then(res => {
      if(res === 'ok') {
        console.log(res)
      }
    })
    return response;
  }

  openDialogPrevisualize(): void {
    setTimeout(() => {
      const dialogRef = this.dialog.open(ModalPrevisualizeComponent, {
        width: '100vh',
        height:  '100vh',
        maxWidth: '100vh',
        maxHeight: '100vh',
        hasBackdrop: false,
        disableClose: true,
        panelClass: 'pdf-visualize',
        data: {
          titlePiece: 'recette_pacae',
          caseId: this.case._id,
          case: this.case,
          kit: this.currentKit,
          chantierId: this.chantierId
        }
      });
      dialogRef.afterClosed().subscribe(result => {

      });
    }, 2000);

  }

   selectMultiple(event, title) {
    if (event.target.files.length > 0) {
      this.multipleImagesPieces = event.target.files;
    }
    this.onSubmit(title)
  }

  checkPiece() {
    this.sPieces.getPiecesByChantierIdAndCible(this.chantierId._id, 'recette_pacae').subscribe(res => {
      this.pieces= res
      if(this.pieces.find(e => e.title === 'Tuyau et calorifugeage'))
        this.tuyau = true
      if(this.pieces.find(e => e.title === 'Sortie des condensats'))
        this.condensat = true
      if(this.pieces.find(e => e.title === 'Photo unité extérieure'))
        this.ue = true
      if(this.pieces.find(e => e.title === 'Réseau comble aéroliques'))
        this.reseau_comble = true
      if(this.pieces.find(e => e.title === 'Photo unité intérieure'))
        this.ui = true
      if(this.pieces.find(e => e.title === 'Disjoncteur'))
        this.disjoncteur = true
    })

  }

  onSubmit(title) {
    const form = {
      title: title,
      qualification: 'Conforme',
      caseId: this.case._id,
      created_at: new Date(),
      type: 'technicien',
      chantier: this.chantierId,
      cible: 'recette_pacae',
      description: '',
    }
    this.sPieces.addTechPiece(form, this.multipleImagesPieces, this.case._id, this.chantierId._id)
     setTimeout(() => {
      this.checkPiece()
    }, 1100);
  }

  checkPieceNSerie() {
    this.sPieces.getPiecesByChantierIdAndCible(this.chantierId._id, 'n_serie_pacae_ue').subscribe(res => {
      this.piecesNserie= res
      if(this.piecesNserie.find(e => e.title === 'N° de serie UE PAC Air Eau') && this.currentKit.type === 'PAC Air/Eau') {

        this.piece_serie_pacae_ue = this.piecesNserie.filter(e => e.title === 'N° de serie UE PAC Air Eau')
        this.n_serie_pacae_ue = true

      }
    })
  }

  selectMultipleNserie(event, title:string, n_serie:string) {
    if (event.target.files.length > 0) {
      this.multipleImagesPieces = event.target.files;
    }
    this.onSubmitNserie(title, n_serie)
  }

  onSubmitNserie(title:string,n_serie:string) {
    const form = {
      title: title,
      qualification: 'Conforme',
      caseId: this.case._id,
      created_at: new Date(),
      type: 'technicien',
      chantier: this.chantierId,
      cible: n_serie,
      description: '',
    }
    this.sPieces.addTechPiece(form, this.multipleImagesPieces, this.case._id, this.chantierId._id)
    setTimeout(() => {
      this.checkPieceNSerie()
    }, 1100);
  }

}
