<div *ngIf="!mobile && isAdmin || isAdv " [ngClass]=" showBar ? 'favoriteOn' : 'favoriteOff'">
  <p (click)="showComment()">  <img src="../../assets/icons/languette.svg"></p>

  <ul [ngClass]=" showBar ? 'iconsOn' : 'iconsOff'">
    <li><a href="https://podio.com/home" target="_blank"><img class="fav" src="../../assets/icons/favorite/podio.svg"></a></li>
    <li><a href="https://cloud.sellandsign.com/calinda/hub/selling/back_office" target="_blank"><img class="fav" src="../../assets/icons/favorite/ss.svg"></a></li>
    <li><a href="https://distribution.cetelem.fr/" target="_blank"><img class="fav" src="../../assets/icons/favorite/cetelem.svg"></a></li>
    <li><a href="https://www.adhefi.com/#" target="_blank"><img class="fav" src="../../assets/icons/favorite/sofinco.svg"></a></li>
    <li><a href="https://www.moncofidispro.fr/fr/identification/identification.cgi" target="_blank"><img class="fav" src="../../assets/icons/favorite/cofidis.svg"></a></li>

    <li><a href="https://cee.certinergy.com/connexion?redirect=%2Faccueil" target="_blank"><img class="fav" src="../../assets/icons/favorite/cee.svg"></a></li>
    <li><a href="https://www.ilovepdf.com/fr" target="_blank"><img class="fav" src="../../assets/icons/favorite/pdf.svg"></a></li>
    <li><a href="https://www.maprimerenov.gouv.fr/prweb/PRAuth/7Mxan1OJCsCMG25cbV0jQ72dMSri-7mQ*/!STANDARD" target="_blank"><img class="fav" src="../../assets/icons/favorite/renov.svg"></a></li>
    <li><a href="https://www.geoportail.gouv.fr/" target="_blank"><img class="fav" src="../../assets/icons/favorite/geo.svg"></a></li>
    <li><a href="https://www.enedis.fr/" target="_blank"><img class="fav" src="../../assets/icons/favorite/enedis.svg"></a></li>

  </ul>

</div>
