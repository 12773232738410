<form [formGroup]="financementForm">

<div class="choices mr-5">
  <p class="m-0">Statut de la demande financière</p>
  <input type="radio" id="state1" name="state" formControlName="state" value="En cours">
  <label for="state1" [ngClass]="this.financementForm.value.state === 'En cours' ? 'pe' : 'pe-empty'">En cours</label>

  <input type="radio" id="state2" name="state" formControlName="state" value="Refus">
  <label for="state2" [ngClass]="this.financementForm.value.state === 'Refus' ? 'non' : 'non-empty'">Refus</label>

  <input type="radio" id="state3" name="state" formControlName="state" value="Complément">
  <label for="state3" [ngClass]="this.financementForm.value.state === 'Complément' ? 'pe' : 'pe-empty'">Complément</label>

  <input type="radio" id="state4" name="state" formControlName="state" value="Acceptation">
  <label for="state4" [ngClass]="this.financementForm.value.state === 'Acceptation' ? 'oui' : 'oui-empty'">Acceptation</label>

  <input type="radio" id="state5" name="state" formControlName="state" value="Acompte">
  <label for="state5" [ngClass]="this.financementForm.value.state === 'Acompte' ? 'oui' : 'oui-empty'">Acompte</label>

  <input type="radio" id="state6" name="state" formControlName="state" value="Payé">
  <label for="state6" [ngClass]="this.financementForm.value.state === 'Payé' ? 'oui' : 'oui-empty'">Payé</label>
</div>

  <p>Date du dépôt de dossier</p>
  <input [ngClass]="this.financementForm.value.date_depot != '' ? 'custom-date' : 'empty-date'" formControlName="date_depot" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2">
  <owl-date-time firstDayOfWeek="1" #dt2></owl-date-time>

<div class="row">
  <div class="col-3">
    <h6>Type d'opération financière</h6>
    <mat-select [ngClass]="this.financementForm.value.type_op ? 'oui' : 'pe'" class="custom-select-state rdv" formControlName="type_op" value="type_op">
      <mat-option value="Papier">Papier</mat-option>
      <mat-option value="Dématérialisé">Dématérialisé</mat-option>
    </mat-select>
  </div>

  <div class="choices choices-fi col-5 mr-5">
    <h6>Nom du partenaire financier</h6>
    <input type="radio" id="partenaire1" name="partenaire" formControlName="partenaire" value="sofinco">
    <label for="partenaire1" [ngClass]="this.financementForm.value.partenaire === 'sofinco' ? 'oui' : 'oui-empty'">Sofinco</label>

    <input type="radio" id="partenaire2" name="partenaire" formControlName="partenaire" value="cetelem">
    <label for="partenaire2" [ngClass]="this.financementForm.value.partenaire === 'cetelem' ? 'oui' : 'oui-empty'">Cételem</label>

    <input data-toggle="modal" data-target="#testDeOuf" type="radio" id="partenaire3" name="partenaire" formControlName="partenaire" [(value)]="otherPartenaire">
    <label for="partenaire3" [ngClass]="this.financementForm.value.partenaire != 'cetelem' && this.financementForm.value.partenaire !='sofinco' && this.financementForm.value.partenaire !='Autre' ? 'oui' : 'oui-empty'">{{otherPartenaire}}</label>


<div class="modal fade" id="testDeOuf" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <mat-form-field class="example-full-width">
          <mat-label>Nom du partenaire</mat-label>
          <input matInput formControlName="partenaire">
        </mat-form-field>
        <button type="button" (click)="validPartenaire()" class="btn btn-secondarycrm"data-dismiss="modal">Valider</button>
      </div>
    </div>
  </div>
</div>

  </div>
</div>

  <div class="choices mr-5">
    <p class="m-0">Sepa conforme</p>
    <input type="radio" id="sepa1" name="sepa" formControlName="sepa" [value]=true>
    <label for="sepa1" [ngClass]="this.financementForm.value.sepa ? 'oui' : 'oui-empty'">
      <span class="material-icons">
      done
      </span>OUI</label>

      <input type="radio" id="sepa2" name="sepa" formControlName="sepa" [value]=false>
      <label for="sepa2" [ngClass]="!this.financementForm.value.sepa  ? 'non' : 'non-empty'">
      <span class="material-icons">
        clear
        </span>NON</label>
  </div>

  </form>
  <button class="btn-secondarycrm" *ngIf="financementForm.valid && financementForm.value.partenaire != 'Autre'" (click)="createFinancement('ajouté','demande de financement',financementForm.value.partenaire)">Créer le suivi FI</button>
