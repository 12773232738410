import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-part6',
  templateUrl: './part6.component.html',
  styleUrls: ['./part6.component.scss']
})
export class Part6Component implements OnInit {

  public reponses = ['Industrie', 'Transport', 'Tertiaire', 'Habitat'];
  public question = 'Quel est <strong>le secteur</strong> consommant le <strong>plus d’énergie ?</strong>';
  public img = "./../../../assets/book/p6_image-right.png";
  caseId;

  public partieSuivante = "part7"

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.caseId = this.route.snapshot.params['id'];
  }

}
