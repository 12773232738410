<form class="gc">
  <mat-form-field *ngIf="group.type === 'channel' && !group._id">
    <mat-label>Nom du groupe</mat-label>
    <input type="text" name="goup.title" placeholder="Nom du groupe" matInput [(ngModel)]="group.title">
  </mat-form-field>
  <mat-form-field *ngIf="group.type === 'channel' || (group.type === 'direct' && !group.users.length)">
    <mat-label>{{ (group.type === 'channel' && !group._id) ? 'Ajouter des membres' : 'Choisir un utilisateur' }}</mat-label>
    <input type="text" [aria-label]="(group.type === 'channel' && !group._id) ? 'Ajouter des membres' : 'Choisir un utilisateur'" matInput [formControl]="myControl" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="addUser($event.option.value)">
      <mat-option *ngFor="let user of filteredOptions | async" [value]="user">{{ user.username }}</mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <div class="gc__users" *ngIf="group.users.length">
    <strong>{{ group.type === 'channel' ? 'Membres inscrit' : 'Utilisateur choisi' }}</strong>
    <div class="gc__user" *ngFor="let userId of group.users">
      <strong>{{ getUser(userId).username }}</strong>
      <span (click)="removeUser(userId)">
        <i></i>
      </span>
    </div>
  </div>
  <div class="gc__actions">
    <button class="btn-secondarycrm btn-with-loader"
      mat-raised-button
      [class.loading]="isLoading"
      [disabled]="isLoading"
      (click)="save()">
      <span class="btn-content">
        <span *ngIf="!group._id">Créer le groupe +</span>
        <span *ngIf="group._id">Ajouter +</span>
      </span>
      <mat-spinner [diameter]="20" color="warn" *ngIf="isLoading"></mat-spinner>
    </button>
  </div>
</form>
