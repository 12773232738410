import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrl } from '@environments/env';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  url = apiUrl.apiUrl + '/customer/';
  //url = 'http://localhost:4000/customer/'

  headers = { 'Content-Type': 'application/json' };

  constructor(
    private http: HttpClient,

  ) { }

  getAllCustomers(limit) {
    return this.http.get(this.url + '/customers/' + limit);
  }

  getAllValidateCustomers() {
    return this.http.get(this.url + '/customers/validate');
  }

  getCustomersByState() {
    return this.http.get(this.url + '/customers/campagne');
  }

  searchCustomer(name) {
    const nameObject = {
      name: name,
    }
    return this.http
    .post(this.url + 'search', JSON.stringify(nameObject) , { headers: this.headers })
    .toPromise()
    .then(res => res as any);
  }


  createCustomer(CustomerForm): Promise<any> {
    return this.http
    .post(this.url + 'add', JSON.stringify(CustomerForm) , { headers: this.headers })
    .toPromise()
    .then(res => res as any);
  }

  editCustomer(CustomerForm, id): Promise<any> {
    return this.http
    .put(this.url + id, JSON.stringify(CustomerForm) , { headers: this.headers })
    .toPromise()
    .then(res => res as any);
  }

  getCustomerById(id) {
    return this.http.get(this.url + id);
  }
  delete(id) {
    return this.http.delete(this.url + id);
  }

  searchCustomers(params: any): Observable<any[]> {
    return this.http.get<any[]>(this.url +'search/byCriteria', { params });
  }
}
