import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CasesService } from '@app/services/cases.service';
import { HistoriqueService } from '@app/services/historique.service';
import { RolesService } from '@app/services/roles.service';

@Component({
  selector: 'app-rdv-add',
  templateUrl: './rdv-add.component.html',
  styleUrls: ['./rdv-add.component.scss']
})
export class RdvAddComponent implements OnInit {

  rdvForm: FormGroup;
  case;
  constructor(
    @Inject(MAT_DIALOG_DATA) public arrayRdvs: any,
    @Inject(MAT_DIALOG_DATA) public caseId: any,
    @Inject(MAT_DIALOG_DATA) public state: any,

    private dialogRef: MatDialog,
    private fb: FormBuilder,
    private sCases: CasesService,
    private sRoles: RolesService,
    private sHistoriques: HistoriqueService,
    private sb: MatSnackBar,
  ) {

    this.rdvForm = this.fb.group({
      rdvs: this.fb.array([]),
    });
   }

  ngOnInit(): void {
    console.log(this.state);
    this.addRdv();
    this.showCaseById();
  }

  rdvs(): FormArray {
    return this.rdvForm.get("rdvs") as FormArray
  }

  combineArrays(reason,change,value) {
    console.log("valeur this arrayRdvs", this.arrayRdvs);
    console.log("rdvForm", this.rdvForm.value);
    const arrayCombine = this.arrayRdvs.arrayRdvs.concat(this.rdvForm.value.rdvs);
    console.log(arrayCombine);
    this.saveRdvs(arrayCombine, reason, change,value);
  }

  saveRdvs(arrayRdvs, reason, change, value) {
    console.log(arrayRdvs);
    this.sCases.addRdv(arrayRdvs, this.caseId.caseId).then(res => {
      if(res['error']) {
        return res.error;
      }
      else {
        this.sb.open(`Rdv ajouté`, '', { duration: 2000, verticalPosition: 'bottom', panelClass: ['add-snackbar']  });
      }
    });

    this.sHistoriques.createHistorique(this.caseId.caseId, this.case.customer._id, reason, change,value,this.sRoles.user.id).then(res => {
      console.log(res);
      if (res['error']) {
        alert(res);
      }
    });
    this.closeModale();
  }

  showCaseById() {
    return new Promise(resolve => {

    this.sCases.getCaseById(this.caseId.caseId).subscribe(res => {
      this.case = res[0];
      resolve(res);
        }, error => {
          console.log(error);
      });
    });
  }

  newRdv(): FormGroup {
    return this.fb.group({
      type: 'Fid',
      date: [''],
      description: '',
    })
  }

    addRdv() {
      this.rdvs().push(this.newRdv());
    }

  closeModale()
  {
    this.dialogRef.closeAll();
  }
}
