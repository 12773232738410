
<div *ngIf="case" class="content">
<div class="row col-sm-12 col-12 date-container">
  <div class="rdvs-container">
  <form [formGroup]="caseForm" class="col-sm-6 col-md-6 col-lg-6 col-xl-4">
        <p>Date et heure du R1</p>
        <form formGroupName="r1">
          <input class="custom-date" formControlName="date" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" (ngModelChange)="saveChange('edité','date r1',this.caseForm.value.r1.date)">
          <owl-date-time firstDayOfWeek="1" [disabled]="statut == 'Signée' "  (ngModelChange)="saveChange()" #dt2></owl-date-time>
        </form>
  </form>

  <div *ngFor="let rdv of arrayRdvs" class="col-sm-6 col-md-6 col-lg-6 col-xl-4">
    <p>Date et heure du {{ rdv.type }} <a *ngIf="statut != 'Signée'"  class="delete" (click)="deleteOneRdv(rdv)">x</a></p>
      <input  class="custom-date" [(ngModel)]="rdv.date" placeholder="Date"[owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" (ngModelChange)="editRdvs('edité','date rdv',rdv.date)">
      <owl-date-time firstDayOfWeek="1"  [disabled]="statut == 'Signée' " #dt2></owl-date-time>
  </div>


  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4">
    <button class="btn-secondarycrm" (click)="openDialog()">+ RDV</button>
  </div>
</div>

</div>

  <div class="row scroll-choice">
    <div class="col-12 col-sm-10">
      <mat-tab-group (selectedTabChange)="currTab($event)" id="main">
        <mat-tab class="custom-tab" label="Description">
          <form class="caseForm" [formGroup]="caseForm">
              <textarea class="custom-textarea" placeholder="Description" formControlName="description" [(ngModel)]="case.description" (change)="saveChange('edité','description',case.description)"></textarea>
          </form>
        </mat-tab>

        <mat-tab class="custom-tab" label="R1">
          <form class="caseForm" [formGroup]="caseForm">
            <form formGroupName="r1">

              <textarea class="custom-textarea" placeholder="Débrief" formControlName="debrief" (change)="saveChange('edité','débrief r1',this.caseForm.value.r1.debrief)"></textarea>

              </form>
          </form>
        </mat-tab>
        <mat-tab class="caseForm" *ngFor="let tab Of arrayRdvs;let i= index" [label]="tab.type | slice:0:5">
            <textarea class="custom-textarea" [(ngModel)]="tab.description" (change)="editRdvs('edité','débrief rdv',tab.description)" [readOnly]="statut == 'Signée' " ></textarea>
        </mat-tab>
      </mat-tab-group>

    </div>
  </div>

<form [formGroup]="caseForm">

<div class="row input-bottom">

   <div class="col-sm-3 col-12" *ngIf="v1">
    <h3>Kit vendu</h3>
    <textarea formControlName="kits_vendus" [(ngModel)]="case.kits_vendus" (change)="saveChange('edité','kit vendu',case.kits_vendus)" [readOnly]="statut == 'Signée' " ></textarea>
  </div>

  <form [formGroup]="caseForm" class="col-md-3 col-xs-8" *ngIf="v2" >
    <mat-form-field class="col-10 custom-padding">
      <mat-select class="financement" [value]=products placeholder='Sélectionner le kit vendu' (selectionChange)="saveChangeKit($event.value,'edité', 'kit vendu',products)" multiple>
        <mat-option class="col-10" value="Adoucisseur d'eau">Adoucisseur d'eau</mat-option>
        <mat-option class="col-10" value="Ballon thermodynamique">Ballon thermodynamique</mat-option>
        <mat-option class="col-10" value="Batterie">Batterie</mat-option>
        <mat-option class="col-10" value="Isolation">Isolation</mat-option>
        <mat-option class="col-10" value="Mise aux normes du tableau électrique">Mise aux normes du tableau électrique</mat-option>
        <mat-option class="col-10" value="PAC Air/Air">PAC Air/Air</mat-option>
        <mat-option class="col-10" value="PAC Air/Eau">PAC Air/Eau</mat-option>
        <mat-option class="col-10" value="Panneaux solaires">Panneaux solaires</mat-option>
        <mat-option class="col-10" value="Thermostat radio">Thermostat radio</mat-option>
        <mat-option class="col-10" value="VMC Double Flux">VMC Double Flux</mat-option>
        <mat-option *ngFor="let autre of autres" [value]="autre">
          {{ autre }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ul *ngFor="let product of selectedProducts">
      <li>
        <h6>{{ product.name }}</h6>
        <!--   BT  -->
       <p *ngIf="product.name === 'Ballon thermodynamique'">{{ product.pose }} {{ product.capacite }} {{ product.unite }}<span *ngFor="let option of product.options_bt"> {{ option }}</span></p>
          <!-- Mise aux normes -->
        <p *ngIf="product.name === 'Mise aux normes du tableau électrique'">{{ product.monotri }}</p>
          <!-- PAC Air/Air -->
        <p *ngIf="product.name === 'PAC Air/Air'"></p>
          <!-- PAC Air/Eau -->
        <p *ngIf="product.name === 'PAC Air/Eau'">{{ product.type_pac }} <span *ngFor="let option of product.options_pac"> {{ option }}</span></p>
          <!-- PV -->
        <p *ngIf="product.name === 'Panneaux solaires'">
          <span *ngIf="product.nombre">{{ product.nombre }} PV</span> {{ product.puissance }} {{ product.pose }} {{ product.orientation }}
        </p>
        <!-- BATTERIE -->
        <p *ngIf="product.name === 'Batterie'">
          <span *ngIf="product.nombre">{{ product.nombre }} batteries</span> {{ product.marque }} <span *ngIf='product.puissance'>{{ product.puissance }} kWc</span>
        </p>

        <!-- Autres-->
        <p *ngIf="product.state && product.state === 'autre'">
          <span *ngFor="let option of product.options">
            <span class="subtitle">{{ option.option }} {{ option.value }}</span>
          </span>

        </p>
      </li>
    </ul>
    </form>

  <div class="col-md-2 mt-3">
    <a (click)="openKitVendu()" *ngIf="v2">modifier le kit</a>
  </div>

  <div *ngIf="!mobile" class="col-7">
    <mat-form-field class="col-8 custom-padding">
      <mat-select formControlName="financement_type" [(ngModel)]="case.financement_type" (selectionChange)="saveChange('edité','type de financement',case.financement_type)">
        <mat-option [disabled]="statut == 'Signée' " value="Comptant">Comptant</mat-option>
        <mat-option [disabled]="statut == 'Signée' " value="sofinco">Demande de financement Sofinco</mat-option>
        <mat-option [disabled]="statut == 'Signée' " value="cetelem">Demande de financement Cetelem</mat-option>
        <mat-option [disabled]="statut == 'Signée' " value="cofidis">Demande de financement Cofidis</mat-option>
      </mat-select>
    </mat-form-field>
  <br>
  <mat-form-field class="example-full-width" class="col-4 custom-padding">
    <input type="number" formControlName="price_ht" matInput placeholder="Prix HT" [(ngModel)]="case.price_ht" (change)="saveChange('edité','prix HT',case.price_ht)" [disabled]='disableTextbox'>
  </mat-form-field>

  <mat-form-field class="example-full-width" class="col-4 custom-padding">
    <input type="number" formControlName="price_ttc" matInput placeholder="Prix TTC" [(ngModel)]="case.price_ttc" (change)="saveChange('edité','prix TTC',case.price_ttc)" [readOnly]="statut == 'Signée' " >
  </mat-form-field>
  </div>
</div>





<div *ngIf="mobile" class="col-12 financement-mobile">

  <h5>Financement</h5>
  <mat-form-field class="col-12 custom-padding">
    <mat-select formControlName="financement_type" [(ngModel)]="case.financement_type" (selectionChange)="saveChange('edité','type de financement',case.financement_type)">
      <mat-option [disabled]="statut == 'Signée' " value="Comptant">Comptant</mat-option>
      <mat-option [disabled]="statut == 'Signée' " value="sofinco">Demande de financement Sofinco</mat-option>
      <mat-option [disabled]="statut == 'Signée' " value="cetelem">Demande de financement Cetelem</mat-option>
      <mat-option [disabled]="statut == 'Signée' " value="cofidis">Demande de financement Cofidis</mat-option>
    </mat-select>
  </mat-form-field>
<br>
<mat-form-field class="example-full-width" class="col-6 custom-padding">
  <input type="number" formControlName="price_ht" matInput placeholder="Prix HT" [(ngModel)]="case.price_ht" (change)="saveChange('edité','prix HT',case.price_ht)" [disabled]='disableTextbox'>
</mat-form-field>

<mat-form-field class="example-full-width" class="col-6 custom-padding" >
  <input type="number" formControlName="price_ttc" matInput  placeholder="Prix TTC" [(ngModel)]="case.price_ttc" (change)="saveChange('edité','prix TTC',case.price_ttc)" [disabled]="currentlyDisabled === 'two'">
</mat-form-field>
<br>
  <div class="row">
    <button class="btn-secondarycrm mt-4 mb-2 offset-4 col-5" [routerLink]="['/add-piece', caseId]">Nouvelle pièce +</button>
  </div>

  <div class="row">
    <mat-card class="col-xs-12 col-lg-3 col-sm-2 mt-3 manquante" *ngFor="let piece of piecesManquantes" (click)="goToEditFinancement(piece._id)">
      <img class="arrow" src="../../../assets/icons/arrow-right.svg">
      <mat-card-content>
        <h6 class="title">{{ piece.title }}</h6>
        <p class="qualif-mairie"
        [ngClass]="{'nonconforme':piece.qualification === 'Manquante'}">{{ piece.qualification }}</p>

      </mat-card-content>
    </mat-card>
    </div>

  <div class="row">
    <mat-card class="col-xs-12 col-lg-3 col-sm-2 mt-3" *ngFor="let piece of pieces" (click)="goToEditFinancement(piece._id)">
      <img class="arrow" src="../../../assets/icons/arrow-right.svg">
      <mat-card-content>
        <h6 class="title">{{ piece.title }}</h6>
        <p class="qualif-mairie"
        [ngClass]="{'attente':piece.qualification === 'En attente de qualification',
                    'conforme':piece.qualification === 'Conforme',
                    'nonconforme':piece.qualification === 'Non conforme'
                  }">{{ piece.qualification }}</p>

      </mat-card-content>
    </mat-card>
    </div>
</div>
</form>


</div>

