<div class="icons-template">
  <a class="nav-item nav-link " style="font-size:0.8rem;">
    <img src="../../../assets/bar-icons/compta-encours.svg" routerLink="/compta-dashboard">
  </a>

  <a class="nav-item nav-link " style="font-size:0.8rem;">
    <img src="../../../assets/bar-icons/cm.svg"  >
  </a>

  <a class="nav-item nav-link " style="font-size:0.8rem;" routerLink="/compta-primes-table">
    <img src="../../../assets/bar-icons/primes.svg">
  </a>

  <a class="nav-item nav-link " style="font-size:0.8rem;" routerLink="/compta-leads">
    <img src="../../../assets/bar-icons/audit-active.svg">
  </a>
</div>


<h3 class="mt-5 mb-3">Dossiers signés</h3>

<ag-grid-angular
#agGrid
style="width: 85vw; height: 90vh; margin-bottom: 5%;"
id="myGrid"
class="ag-theme-alpine"
[columnDefs]="columnDefs"
[defaultColDef]="defaultColDef"
[rowData]="rowData"
[rowStyle]="rowStyle"
(gridReady)="onGridReady($event)"
rowSelection="single"
[animateRows]="true"
(cellDoubleClicked)="onSelectionChanged()"
[rowHeight]="rowHeight">
</ag-grid-angular>
