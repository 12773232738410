import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrl } from '@environments/env';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

 url = apiUrl.apiUrl + '/task/';
//url = 'http://localhost:4000/task/'

  headers = { 'Content-Type': 'application/json' };


  constructor(
    private http: HttpClient,
  ) { }

  CreateTask(TaskForm): Promise<any> {
    return this.http
      .post(this.url + 'add', JSON.stringify(TaskForm), { headers: this.headers })
      .toPromise()
      .then(res => res as any);
  }

  getAllCaseTaskByAdminId(caseId, adminId){
    return this.http.get(this.url+ 'tasks/' + caseId + "/" + adminId  )
  }

  getTaskBYId(id){
    return this.http.get(this.url + id)
  }

  getTaskByTargetId(Id){
    return this.http.get(this.url +'target/' + Id)
  }
  getCheckedTaskByTargetId(Id){
    return this.http.get(this.url +'targetCheck/' + Id)
  }

  getTaskByCreatedById(Id){
    return this.http.get(this.url +'createdBy/' + Id)
  }

  getTaskByCaseId(caseId){
    return this.http.get(this.url +'case/' + caseId)
  }
  getTaskByMessage(message) {
    return this.http.get(this.url +'message/' + message)
  }

  editTask(Task, id): Promise<any> {
    return this.http
      .put(this.url + id, JSON.stringify(Task), { headers: this.headers })
      .toPromise()
      .then(res => res as any);
  }

  deleteTask(id){
    return this.http.delete(this.url + id)
  }
}
