import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CasesService } from '@app/services/cases.service';
import { InfosService } from '@app/services/infos.service';
import { apiUrl } from '@environments/env';

@Component({
  selector: 'app-manquante-piece',
  templateUrl: './manquante-piece.component.html',
  styleUrls: ['./manquante-piece.component.scss']
})
export class ManquantePieceComponent implements OnInit {

  apiUrl = apiUrl.apiUrl + '/piece/';
  case
  selectedPieces = []
  multipleImagesPieces;
  options = [
    { title: "Book" },
    { title: "BS Mme M-2" },
    { title: "BS Mme M-1" },
    { title: "BS Mme M" },
    { title: 'BS Mr M-2' },
    { title: 'BS Mr M-1' },
    { title: 'BS Mr M' },
    { title: 'Photo disjoncteur' },
    { title: 'Photo compteur' },
    { title: 'Photo combles' },
    { title: 'Photo maison de loin' },
    { title: 'Photo des 4 côtés de la maison' },
    { title: 'Photo des radiateurs' },
    { title: 'Photo toîture' },
    { title: 'Photo UE PAC' },
    { title: 'Photo UI' },
    { title: 'VT' },
    { title: 'RIB' },
    { title: 'AI Mr' },
    { title: 'AI Mme' },
    { title: 'CNI Mme' },
    { title: 'CNI Mr' },
    { title: 'CAF' },
    { title: 'Plan de masse' },
    { title: '8 zones photos bouches' },
    { title: '10 photos plafonds' },
    { title: 'Photo ancien BT' },
    { title: 'Photo nouveau BT' },
    { title: 'Photos complémentaires' },
    { title: 'Acte notarié' },
    { title: 'Taxe foncière' },
    { title: 'Justificatif dom -3mois' },
    { title: 'Facture EDF' },
    { title: 'Etude de rentabilité' },
    { title: 'Bon de commande' },
    { title: 'Offre de prêt' },
    { title: 'Mandat MPR' },
    { title: 'Autre' },
    { title: 'Marquage pénétration de la gainee' },


  ];
  myForm: FormGroup;
  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private dialogRef: MatDialog,
    private sb: MatSnackBar,
    private sCases: CasesService,
    private sInfos: InfosService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.showCaseById()
    this.myForm = this.fb.group({
      title: this.fb.array([])
    });
  }

  showCaseById() {
    return new Promise(resolve => {

      this.sCases.getCaseById(this.data.caseId).subscribe(res => {
        this.case = res[0];
        resolve(res);
      }, error => {
        console.log(error);
      });
    });
  }

  onChange(title: string, isChecked: boolean) {
    const titleFormArray = <FormArray>this.myForm.controls.title;

    if (isChecked) {
      titleFormArray.push(new FormControl(title));
    } else {
      let index = titleFormArray.controls.findIndex(x => x.value == title)
      titleFormArray.removeAt(index);
    }
  }

  selectMultiple(event) {
    if (event.target.files.length > 0) {
      this.multipleImagesPieces = event.target.files;
    }
  }

  onSubmitMultiple() {
    this.myForm.value.title.forEach(element => {
      const formData = new FormData();
      formData.append('title', element);
      formData.append('description', '');
      formData.append('qualification', 'Manquante');
      formData.append('caseId', this.data.caseId);
      const blob = new Blob(["testing"], { type: "application/pdf" });
      formData.append('filesPieces', blob);

      this.http.post<any>(this.apiUrl + '/multiplePieces', formData).subscribe(
        (res) => {
          console.log(res)
        },
        (err) => console.log(err)
      );
    });
    if (this.case.adv != null) {
      setTimeout(() => {
        let info = {
          comment: "Pièce(s) manquante(s)",
          reason: 'piece_manquante',
          created_by: this.case.adv._id,
          created_at: new Date(),
          caseId: this.data.caseId,
          customerId: this.case.customer._id,
          adv: this.case.commercial._id,
        }
        this.sInfos.createInfo(info).then(res => {
          console.log(res);
          if (res['error']) {
            alert(res.error);
          }
        });
      }, 500);
    }

    this.closeModale()

  }

  closeModale() {
    this.sb.open(`Pièce manquante ajoutée`, '', { duration: 2000, verticalPosition: 'bottom', panelClass: ['add-snackbar'] });
    this.dialogRef.closeAll();
  }

}
