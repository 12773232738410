

<div class="spinner" *ngIf="!commercial">
  <img style="width:25%;position:absolute;right: 50%;left: 38%;" src="../../../assets/loader_final.gif">
</div>
<div *ngIf="commercial" class="marge-off" >

  <div class="row">
    <button mat-button (click)="goBack()"><img src="../../../assets/icons/retour.svg">Retour</button>


    <div class="col-xs-5 col-lg-12 col-sm-12 col-m-12">
      <div class="card ">
        <div class="card-content global ">
          <round-progress class="graph"  [current]="pourcentage" [max]='100' [semicircle]="true" [rounded]="true" [color]="this.color"  [background]="'#B3DAE7'" ></round-progress>
            <div class=" right-column-global" >

            <h3>Performance globale {{ commercial.username}} </h3>
              <div class="test" >
                <div>CA brut: <span>{{ commercial.caBrut[actualMonth] | currency:'EUR':"symbol" }}</span></div>
                <div>CA net : <span>{{ commercial.caNet[actualMonth] | currency:'EUR':"symbol"}}</span></div>
                <div>Taux d'annulation : <span>{{commercial.newCancelRate[actualMonth] | number:'2.1-2'}} % </span></div>
              </div>
            </div>
        </div>
      </div>

      <mat-select class="rdv" [(ngModel)]='filter.state'>
        <mat-option value='all'>Tous</mat-option>
        <mat-option *ngFor="let state of states" [value]="state.name">{{state.ca}}</mat-option>
      </mat-select>

<section class="wrapper-fostrap">
  <div class="container-fostrap">
      <div class="content">
              <div class="row">
                <div class="col-xs-5 col-lg-4 col-sm-4" *ngFor="let case of commercialCases[actualMonth] | statePipe: filter.state " (click)="goToCase(case._id)">
                  <div class="card">
                          <div class="card-content">
                            <p class="mb-0"><strong>Nom du client</strong></p>
                            <p> {{ case.customer.name }}  </p>
                            <p class="mb-0"><strong>Kit vendu</strong></p>
                            <!-- <p class="break-kit">{{ case.kits_vendus }}</p> -->
                            <app-product-card class="mb-4" [kit]="case.kits_vendus"></app-product-card>
                            <p class="state" [ngClass]="case.state">{{ case.state }}</p>
                            <div class="row mt-4">
                              <div class="col-8">
                                <p style="margin-top: 17%;"><em>Montant HT</em></p>
                                <p>{{ case.price_ht | currency:'EUR':"symbol" }}</p>
                              </div>
                              <div class="col-4">
                                <p style="margin-top: 37%;"><em>Date de signature</em></p>
                                <p>{{ case.datesign | date:'shortDate' }}</p>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
        </div>
  </div>
</section>
</div>
