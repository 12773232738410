import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CasesService } from '@app/services/cases.service';
import { HistoriqueService } from '@app/services/historique.service';
import { InfosService } from '@app/services/infos.service';
import { PicturesService } from '@app/services/pictures.service';
import { RolesService } from '@app/services/roles.service';
import { UsersService } from '@app/services/users.service';
import { AuthenticationService } from '@app/_services';

@Component({
  selector: 'app-commentbar',
  templateUrl: './commentbar.component.html',
  styleUrls: ['./commentbar.component.scss']
})
export class CommentbarComponent implements OnInit {
  @Input() caseId: string;
  @ViewChild('selection') selection;

  commentForm: FormGroup;
  users;
  case;
  arrayComments = [];
  advComments = [];
  commercialComments = [];
  space = '';
  currentUser;
  currentRole;
  show;
  currentPicture;
  otherPicture;
  showPicture = false;
  disabledButton = false
  dateNow;
  showComment = false;
  da;
  mobile;
  likes = []
  profilPictures = []
  showPictures = false

  usersPictures = []
  showPP = false
  imageSrc;

  constructor(
    private fb: FormBuilder,
    public sUsers: UsersService,
    public sRoles: RolesService,
    private sCases: CasesService,
    private authenticationService: AuthenticationService,
    private sPictures: PicturesService,
    private sInfos: InfosService,
    private sHistoriques: HistoriqueService,
    private http:HttpClient

  ) {
    this.imageSrc = 'assets/empty_pp_circle.svg'
    this.authenticationService.user.subscribe(x => this.currentUser = x);
    this.currentRole = this.currentUser.role

    if (window.screen.width <= 600) {
      this.mobile = true;
    }

  }

  ngOnInit(): void {
    let now = new Date();
    this.dateNow = now.setHours(now.getHours())
    this.sUsers.showUserRole();
    this.showCaseById();

    this.commentForm = this.fb.group({
      caseId: new FormControl(this.caseId),
      id: new FormControl(),
      tag: new FormControl([]),
      comment: new FormControl(),
      space: new FormControl(''),
      created_by: new FormControl(this.sRoles.user.id),
      created_byname : new FormControl(this.sRoles.user.username),
      created_at: new FormControl(this.dateNow),
      like: new FormControl(this.likes)
    });
  }

  get comment(): any { return this.commentForm.get('comment'); }

  showCaseById() {
    return new Promise(resolve => {
    this.sCases.getCaseById(this.caseId).subscribe(res => {

      this.case = res[0];
      const unique = [];

      this.case.comments.map(x => unique.filter(a => a.created_by == x.created_by).length > 0 ? null : unique.push(x));

      unique.forEach(element => {
        if(element.created_by != undefined) {
          this.sPictures.getPicturesByUserId(element.created_by).subscribe(res => {
            this.usersPictures.push({
              id : element.created_by,
              picture: res
            })
          })
        }
      });

      this.arrayComments = this.case.comments;
      this.arrayComments.forEach(element => {

        if (this.case.space === 'adv') {
          this.advComments.push(element);
        }
        else {
          this.commercialComments.push(element);
        }

/*         if(element.created_by != undefined) {
          this.sPictures.getPicturesByUserId(element.created_by).subscribe(res => {
            this.usersPictures.push({
              id : element.created_by,
              picture: res
            })
          })
        } */
      });

      setTimeout(() => {
        this.showPP = true
      }, 1000);
      resolve(res);
      this.showDAbyCommercialId()
      setTimeout(() => {
        this.scrollTop();
        this.showPictures = true;

      }, 1000);

        }, error => {
          console.log(error);
      });
    });
  }

  getProfil(created_by) {
    let profil:any = this.usersPictures.filter(p => p.id === created_by)
    if(profil) {
      profil = profil[0]
      if(profil && profil.picture[0]) {
        profil = 'https://api-crm.s3.eu-west-3.amazonaws.com/'+ created_by +'/picture/' +  profil.picture[0].image
      }
      else {
        profil = this.imageSrc
      }
    }
    else {
      profil = this.imageSrc
    }
    return  profil
  }

  pushLike(commentId) {
    setTimeout(() => {
      const objIndex = this.arrayComments.find((obj => obj.id == commentId));
      objIndex.like.push(this.currentUser.username)
      this.addLike(this.arrayComments)
       this.addInfo(objIndex, "aime" )
     }, 500);

  }
  rmLike(commentId) {
    const objIndex = this.arrayComments.find((obj => obj.id == commentId));
    const username = objIndex.like.indexOf(this.currentUser.username);
    objIndex.like.splice(username,1)
    this.addInfo(objIndex, "n'aime plus " )
    this.addLike(this.arrayComments)
  }

  combineArrays(space,reason, change,value) {

    if (this.commentForm.value.comment != '') {
      this.commentForm.get('id').setValue(this.getRandomId());
      this.commentForm.value.created_at = Date.now();
      this.commentForm.get('space').setValue(space);
      this.commentForm.value.tag.forEach(element => {

        let comment = {
          comment : 'commentaire',
          reason : 'commentaire',
          created_by : this.sRoles.user.id,
          created_byname : this.sRoles.user.username,
          created_at : this.dateNow,
          caseId: this.caseId,
          customerId: this.case.customer,
          adv: element,
        }
        this.sInfos.createInfo(comment).then(res => {console.log(res)});
      });
        this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change,value,this.sRoles.user.id).then(res => {
          if (res['error']) {
            alert(res);
          }
        });

      const arrayCombine = this.arrayComments.concat(this.commentForm.value);
      this.postComment(arrayCombine);
    }
    if(space === 'client' && this.case.customer.mail != '') {
      this.sendMail()
    }
  }

  sendMail() {
    const message = {
      comment: this.commentForm.value.comment,
      created_byname: this.commentForm.value.created_byname,
      mail:this.case.customer.mail
    }
    return this.http
    .post('http://localhost:4000/pegase-client/nouveauMessage', JSON.stringify(message), { headers: { 'Content-Type': 'application/json' } })
    .toPromise()
  }

  getRandomId() {
    return Math.floor(Math.random() * Math.floor(9999999));
  }
  postComment(arrayComments) {
    this.sCases.addComment(arrayComments, this.caseId).then(res => {
      if(res['error']) {
        return res.error;
      }
      this.showCaseById();
      this.comment.reset();
      this.commentForm.get('tag').setValue([]);


    });
  }

  addLike(arrayComments) {
    this.sCases.addLikeInComment(arrayComments, this.caseId).then(res => {
      if(res['error']) {
        return res.error;
      }
      this.showCaseById();
      this.comment.reset();
    });
  }

  deleteComment(comment) {
    if ( confirm( "Voulez vous supprimer le commentaire ?" ) ) {
      this.sCases.deleteComment(comment, this.caseId).then(res => {
        if(res['error']) {
          return res.error;
        }
        this.showCaseById();

      });
    } else {

    }
  }
  getUsernameMention(tag: any) {
    const member = this.sUsers.users.find(m => m._id === tag);
    return member ? member.username : 'Aucun';

  }
  scrollTop() {
    document.getElementById('chat').scrollTop = 9899999999;
    this.showComment = true;
  }

  addInfo(comment, message){
    let info = {
              comment : message,
              reason : 'like',
              created_by : this.sRoles.user.id,
              created_at : new Date(),
              caseId: this.caseId,
              customerId: this.case.customer._id,
              adv: comment.created_by
            }
            this.sInfos.createInfo(info).then(res => {
              if (res['error']) {
                alert(res.error);
              }
            });
  }

  showDAbyCommercialId() {
    this.sUsers.getDAbyCommercialAgency(this.case.commercial.agency).subscribe( res => {

     if(res['user'][0] && res['user'][0]._id != this.case.commercial._id) {
      this.da = res['user'][0]
      return this.da
    }
    });
  }

  openMention() {
    this.selection.open();
  }

  autoGrowTextZone(e) {
    if(this.commentForm.value.comment != '') {
      e.target.style.height = "43px";
      e.target.style.height = (e.target.scrollHeight + 25)+"px";
    }
    else {
      e.target.style.height = (e.target.scrollHeight - 25)+"px";
    }
  }
  typeOf(value) {
    return typeof value;
  }
}
