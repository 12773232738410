<div>
<h6 class="my-5">Procès verbal de livraison d'installation</h6>

<app-header-info [caseId]='caseId'></app-header-info>

<h4>Équipements installés</h4>

<div class="pv" *ngIf="pv && !pv.livraison">
  <h6>Panneaux photovoltaïque micro-onduleur</h6>
  <div class="row">
    <div class="col-lg-3 col-s-6 col-xs-6">Marque et type : <u> {{ pv.marque_type }}</u></div>
    <div class="col-lg-3 col-s-6 col-xs-6">Numéro de série: <span *ngFor="let module of pv.modules">{{ module.numero }} /</span></div>
    <div class="col-lg-3 col-s-6 col-xs-6">Nombre: <span>{{ pv.nombre }}</span></div>
    <br>
  </div>
  <div class="row">
    <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
      <label>Référence PV</label>
      <input type="text" class="custom-input" [(ngModel)]="pv.reference" (change)="getCurrentKitAndSaveChange(pv.reference,'reference', pv)">
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3 col-s-6 col-xs-6">Marque et type : <u>{{ pv.marque_type_onduleur }}</u></div>
    <div class="col-lg-3 col-s-6 col-xs-6">Numéro de série: <span *ngFor="let onduleur of pv.onduleurs">{{ onduleur.numero }} /</span></div>
    <div class="col-lg-3 col-s-6 col-xs-6">Nombre: <span>{{ pv.nbr_onduleur }} micros-onduleur</span></div>
  </div>
  <div class="row">
    <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
      <label>Référence Onduleur</label>
      <input type="text" class="custom-input" [(ngModel)]="pv.reference_onduleur" (change)="getCurrentKitAndSaveChange(pv.reference_onduleur,'reference_onduleur', pv)">
    </div>
  </div>

<br>

  <div class="row">
    <div class="col-lg-5 col-s-6 col-xs-6 custom-col radio-choice">
      <mat-label>Test de fonctionnement:</mat-label>
      <mat-radio-group aria-label="Select an option" [(ngModel)]="pv.test" (change)="getCurrentKitAndSaveChange($event.value,'test', pv)">
        <mat-radio-button value="oui">Oui</mat-radio-button>
        <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="col-lg-3 col-s-6 col-xs-6 custom-col radio-choice">
      <mat-label>Explication client:</mat-label>
      <mat-radio-group aria-label="Select an option" [(ngModel)]="pv.explication" (change)="getCurrentKitAndSaveChange($event.value,'explication', pv)">
        <mat-radio-button value="oui">Oui</mat-radio-button>
        <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

</div>

<br>


<div class="iso" *ngIf="iso && !iso.livraison">
  <h6>Isolation</h6>
  <div class="row">
    <div class="col-lg-3 col-s-6 col-xs-6">Type d'isolation : <u>{{ iso.type_iso }}</u></div>
    <div class="col-lg-3 col-s-6 col-xs-6">Surface : <span>{{iso.surface}}</span> m2</div>
  </div>

<br>

  <div class="row">
    <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
      <label>Epaisseur en mm</label>
      <input type="number" class="custom-input" [(ngModel)]="iso.epaisseur" (change)="getCurrentKitAndSaveChange(iso.epaisseur,'epaisseur', iso)">
    </div>
  </div>
</div>

<br>

<div class="bt" *ngIf="bt && !bt.livraison">

  <h6>Ballon thermodynamique</h6>
  <div class="row">
    <div class="col-lg-3 col-s-6 col-xs-6">Marque et type : <u> {{ bt.marque_type }}</u></div>
    <div class="col-lg-3 col-s-6 col-xs-6">Numéro de série: <span>{{ bt.n_serie_ui }}</span></div>
    <div class="col-lg-3 col-s-6 col-xs-6">Capacité: <span>{{ bt.capacite }}</span></div>
    <div class="col-lg-3 col-s-6 col-xs-6">Nombre:<span>1</span></div>
  </div>

<br>

  <div class="row">
    <div class="col-lg-5 col-s-6 col-xs-6 custom-col radio-choice">
      <mat-label>Test de fonctionnement:</mat-label>
      <mat-radio-group aria-label="Select an option"  [(ngModel)]="bt.test" (change)="getCurrentKitAndSaveChange($event.value,'test', bt)">
        <mat-radio-button value="oui">Oui</mat-radio-button>
        <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="col-lg-3 col-s-6 col-xs-6 custom-col radio-choice">
      <mat-label>Explication client:</mat-label>
      <mat-radio-group aria-label="Select an option" [(ngModel)]="bt.explication" (change)="getCurrentKitAndSaveChange($event.value,'explication', bt)">
        <mat-radio-button value="oui">Oui</mat-radio-button>
        <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
      <label>Référence BT</label>
      <input type="text" class="custom-input" [(ngModel)]="bt.reference" (change)="getCurrentKitAndSaveChange(bt.reference,'reference', bt)">
    </div>
  </div>
</div>


<div class="pacaa" *ngIf="pacaa && !pacaa.livraison">
  <h6>Pompe à chaleur Air air</h6>
  <div class="row">
    <div class="col-lg-3 col-s-6 col-xs-6">Marque référence modules UE: <u> {{ pacaa.marque_type_ue }}</u></div>
    <div class="col-lg-3 col-s-6 col-xs-6">Numéro de série: <span>{{ pacaa.n_serie_ue }}</span></div>
    <div class="col-lg-3 col-s-6 col-xs-6">Nombre UE:<span>1</span></div>
  </div>
  <div class="row">
    <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
      <label>Référence UE</label>
      <input type="text" class="custom-input" [(ngModel)]="pacaa.reference_ue" (change)="getCurrentKitAndSaveChange(pacaa.reference_ue,'reference_ue', pacaa)">
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3 col-s-6 col-xs-6">Marque et type UI : <u> {{ pacaa.marque_type_ui }}</u></div>
    <div class="col-lg-3 col-s-6 col-xs-6">Numéro de série: <span *ngFor="let n of pacaa.splits">{{ n.numero }}</span></div>
    <div class="col-lg-3 col-s-6 col-xs-6">Nombre UI:<span>{{ pacaa.nbr_split }}</span></div>
  </div>
  <div class="row">
    <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
      <label>Référence UI</label>
      <input type="text" class="custom-input" [(ngModel)]="pacaa.reference_ui" (change)="getCurrentKitAndSaveChange(pacaa.reference_ui,'reference_ui', pacaa)">
    </div>
  </div>

<br>

  <div class="row">
    <div class="col-lg-5 col-s-6 col-xs-6 custom-col radio-choice">
      <mat-label>Test de fonctionnement:</mat-label>
      <mat-radio-group aria-label="Select an option" [(ngModel)]="pacaa.test" (change)="getCurrentKitAndSaveChange($event.value,'test', pacaa)">
        <mat-radio-button value="oui">Oui</mat-radio-button>
        <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="col-lg-3 col-s-6 col-xs-6 custom-col radio-choice">
      <mat-label>Explication client:</mat-label>
      <mat-radio-group aria-label="Select an option" [(ngModel)]="pacaa.explication" (change)="getCurrentKitAndSaveChange($event.value,'explication', pacaa)">
        <mat-radio-button value="oui">Oui</mat-radio-button>
        <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

</div>

<div class="pacae" *ngIf="pacae && !pacae.livraison">
  <h6>Pompe à chaleur Air Eau</h6>
  <div class="row">
    <div class="col-lg-3 col-s-6 col-xs-6">Marque référence modules UE: <u> {{ pacae.marque_type_ue }}</u></div>
    <div class="col-lg-3 col-s-6 col-xs-6">Numéro de série: <span>{{ pacae.n_serie_ue }}</span></div>
    <div class="col-lg-3 col-s-6 col-xs-6">Nombre UE:<span> 1 </span></div>
  </div>
  <div class="row">
    <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
      <label>Référence UE</label>
      <input type="text" class="custom-input" [(ngModel)]="pacae.reference_ue" (change)="getCurrentKitAndSaveChange(pacae.reference_ue,'reference_ue', pacae)">
    </div>
  </div>

  <div class="row">
    <div class="col-lg-3 col-s-6 col-xs-6">Marque et type UI : <u> {{ pacae.marque_type_ui }}</u></div>
    <div class="col-lg-3 col-s-6 col-xs-6">Numéro de série: <span>b</span></div>
    <div class="col-lg-3 col-s-6 col-xs-6">Nombre UI:<span> 1 </span></div>
  </div>
  <div class="row">
    <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
      <label>Référence UI</label>
      <input type="text" class="custom-input" [(ngModel)]="pacae.reference_ui" (change)="getCurrentKitAndSaveChange(pacae.reference_ui,'reference_ui', pacae)">
    </div>
  </div>
<br>

  <div class="row">
    <div class="col-lg-5 col-s-6 col-xs-6 custom-col radio-choice">
      <mat-label>Test de fonctionnement:</mat-label>
      <mat-radio-group aria-label="Select an option" [(ngModel)]="pacae.test" (change)="getCurrentKitAndSaveChange($event.value,'test', pacae)">
        <mat-radio-button value="oui">Oui</mat-radio-button>
        <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="col-lg-3 col-s-6 col-xs-6 custom-col radio-choice">
      <mat-label>Explication client:</mat-label>
      <mat-radio-group aria-label="Select an option" [(ngModel)]="pacae.explication" (change)="getCurrentKitAndSaveChange($event.value,'explication', pacae)">
        <mat-radio-button value="oui">Oui</mat-radio-button>
        <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>


</div>
<button class="btn-secondarycrm" *ngIf="!isValid" (click)="valid()">Confirmer</button>

<app-loading *ngIf="loader"></app-loading>
 <a (click)="reinitPv()" style="color:#E95E24; text-decoration: underline; margin-top: 2rem;">Reinitialiser le pv de livraison</a>
