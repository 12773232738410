import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';

@Component({
  selector: 'app-recette-template',
  templateUrl: './recette-template.component.html',
  styleUrls: ['./recette-template.component.scss']
})
export class RecetteTemplateComponent implements OnInit {

  currentKit;
  case;
  caseId;
  chantierId;
  kit
  pv;
  vmc;
  iso;
  bt;
  pacaa;
  pacae;
  adoucisseur;
  mante;
  value
  public data: any;

  constructor(
    private sCases: CasesService,
    private route: ActivatedRoute,
    private router: Router,
) {
    if(this.currentKit = this.router.getCurrentNavigation().extras.state != undefined) {
      this.currentKit = this.router.getCurrentNavigation().extras.state.kit
      this.chantierId = this.router.getCurrentNavigation().extras.state.chantierId
    }
    else {
      this.currentKit = JSON.parse(localStorage.getItem('currentKit'))
      this.case = localStorage.getItem('case')
      this.chantierId = JSON.parse(localStorage.getItem('chantierId'))
    }

    this.caseId = this.route.snapshot.params['id'];
    this.sCases.getCaseById(this.caseId).subscribe(res => {
      this.case = res[0]
      this.kit = JSON.parse(this.case.kits_vendus).filter(e => e.active && e.valid)
      this.kit.forEach(element => {
        if(element.type === 'Panneaux solaires') {
          this.pv = element
        }
        if(element.type === 'VMC Double Flux') {
          this.vmc = element
        }
        if(element.type === 'Isolation') {
          this.iso = element
        }
        if(element.type === 'Ballon thermodynamique') {
          this.bt = element
        }
        if(element.type === 'PAC Air/Air') {
          this.pacaa = element
        }
        if(element.type === 'Mise aux normes du tableau électrique') {
          this.mante = element
        }

        if(element.type === 'PAC Air/Eau') {
          this.pacae = element
        }
        if(element.type === 'Adoucisseur d\'eau') {
          this.adoucisseur = element
        }
      });

    })

  }

  ngOnInit(): void {
  }

  updateData(event) {
    this.data = event;
  }

  getCurrentKitAndSaveChange(event,key,currentKit) {

    currentKit[key] = event;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === currentKit.type);

    array[index] = currentKit

    this.case.kits_vendus = JSON.stringify(array)
    this.sCases.editCase(this.case, this.case._id)
  }

}
