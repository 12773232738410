import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { FinancementsService } from '@app/services/financements.service';
import { HistoriqueService } from '@app/services/historique.service';
import { RolesService } from '@app/services/roles.service';

@Component({
  selector: 'app-case-financement-edit',
  templateUrl: './case-financement-edit.component.html',
  styleUrls: ['./case-financement-edit.component.scss']
})
export class CaseFinancementEditComponent implements OnInit {
  @Input() financementId: string;
  currentFinancement;
  caseId;
  case;
  financementForm: FormGroup;
  financement;

  options = ["En cours", "Refus", "Complément", "Acceptation", "Acompte", "Payé"];
  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public sanitizer: DomSanitizer,
    private router: Router,
    private sFinancements: FinancementsService,
    private sHistoriques: HistoriqueService,
    private sRoles: RolesService,
    private sCases: CasesService,


  ) {
    this.caseId = this.route.snapshot.params['id'];
    this.showFinancementInfos().then(res => {
    this.showCurrentFinancement().then(res => {
      this.currentFinancement = res[0];
      this.financementForm = this.fb.group({
        caseId: new FormControl(this.caseId, [Validators.required]),
        type_op: new FormControl(this.currentFinancement.type_op, [Validators.required]),
        partenaire: new FormControl(this.currentFinancement.partenaire, [Validators.required]),
        state: new FormControl(this.currentFinancement.state, [Validators.required]),
        sepa: new FormControl([], [Validators.required]),
        date_depot: new FormControl(this.currentFinancement.date_depot, [Validators.required]),
        deblocage: new FormControl(this.currentFinancement.deblocage, [Validators.required]),
        date_deblocage: new FormControl(this.currentFinancement.date_deblocage, [Validators.required]),
        acompte: new FormControl(this.currentFinancement.acompte, [Validators.required]),
        date_acompte: new FormControl(this.currentFinancement.date_acompte, [Validators.required]),
        montant_acompte: new FormControl(this.currentFinancement.montant_acompte, [Validators.required]),
        solde: new FormControl(this.currentFinancement.solde, [Validators.required]),
        date_solde: new FormControl(this.currentFinancement.date_solde, [Validators.required]),
        montant_solde: new FormControl(this.currentFinancement.montant_solde, [Validators.required]),
      });
    });
  })

    }

  ngOnInit(): void {
    this.showFinancementInfos();
    this.showCaseInfos();
  }

  showCurrentFinancement() {
    return new Promise(resolve => {

      this.sFinancements.getFinancementById(this.financementId).subscribe(res => {
        this.currentFinancement = res[0];
        resolve(res);
          }, error => {
            console.log(error);
        });
      });
  }
  showFinancementInfos() {
    return new Promise(resolve => {

    this.sFinancements.getFinancementsByCaseId(this.caseId).subscribe(res => {
      resolve(res);
        }, error => {
          console.log(error);
      });
    });
  }

  showCaseInfos() {
    return new Promise(resolve => {

    this.sCases.getCaseById(this.caseId).subscribe(res => {
      this.case = res[0];
      resolve(res);
        }, error => {
          console.log(error);
      });
    });
  }

  saveChange(reason, change, value) {
    this.sFinancements.editFinancement(this.financementForm.value, this.currentFinancement._id).then(res => {
      if(res['error']) {
        return res.error;
      }
    });
    this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change,value,this.sRoles.user.id).then(res => {
      if (res['error']) {
        alert(res);
      }
    });
  }


}
