import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-part9',
  templateUrl: './part9.component.html',
  styleUrls: ['./part9.component.scss']
})
export class Part9Component implements OnInit {
  caseId: string;
  step = 0;
  isSelected: number | null = null;
  equipementSelected: string[] = [];

  machineSlider = 0;
  secheLingeSlider = 0;
  eauChaudeSlider = 0;
  chauffageSlider = 0;
  cuisineSlider = 0;

  machineIndex: number | null = null;
  secheIndex: number | null = null;
  eauChaudeIndex: number | null = null;
  chauffageIndex: number | null = null;
  cuisineIndex: number | null = null;

  tableauValeurs = Array(24).fill(0);
  courbeConso = [0, 0, 0, 0, 0, 0.00224, 1.5, 2.1, 2.79531, 3, 3.7, 4.11019, 3.7, 3, 2.87245, 2.1, 1, 0.00511, 0, 0, 0, 0, 0, 0];

  ChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            zeroLineColor: '#FFFFFF',
            color: "rgba(0, 0, 0, 0)",
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            zeroLineColor: 'red',
            color: "rgba(0, 0, 0, 0)",
          },
        },
      ],
    },
  };
  ChartLabels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];

  ChartType = 'bar';
  ChartLegend = true;
  ChartData = [
    { data: this.tableauValeurs, label: 'Remote', type: 'bar', backgroundColor: '#73c097' },
    { data: this.courbeConso, label: 'Visit', type: 'line', borderColor: '#FC7503', backgroundColor: 'transparent' },
  ];

  value: any;

  options: Options = {
    floor: 0,
    ceil: 24,
    showSelectionBar: true,
    translate: (value: number): string => value + " h",
  };

  book: any;

  constructor(private route: ActivatedRoute, private router: Router, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.caseId = this.route.snapshot.params['id'];
    this.book = JSON.parse(localStorage.getItem('book'));
  }

  updateChart(kwh: number, name: string, value: number): void {
    const index = value - 1;

    switch (name) {
      case 'machineSlider':
        this.changeValueIndexSelect(index, this.machineIndex, kwh);
        this.machineIndex = index;
        break;
      case 'secheLingeSlider':
        this.changeValueIndexSelect(index, this.secheIndex, kwh);
        this.secheIndex = index;
        break;
      case 'eauChaudeSlider':
        this.changeValueIndexSelect(index, this.eauChaudeIndex, kwh);
        this.eauChaudeIndex = index;
        break;
      case 'chauffageSlider':
        this.changeValueIndexSelect(index, this.chauffageIndex, kwh);
        this.chauffageIndex = index;
        break;
      case 'cuisineSlider':
        this.changeValueIndexSelect(index, this.cuisineIndex, kwh);
        this.cuisineIndex = index;
        break;
    }
    this.cdr.detectChanges();
  }

  changeValueIndexSelect(index: number, machine: number | null, kwh: number): void {
    if (index === machine) {
      this.tableauValeurs[index] += kwh;
    } else {
      this.tableauValeurs[index] += kwh;
      if (machine !== null) {
        this.tableauValeurs[machine] -= kwh;
      }
    }
    this.ChartData[0].data = [...this.tableauValeurs];
  }

  changeStepAndRouting(): void {
    if (this.step === 2) {
      this.router.navigate(['/book/part9bis', this.caseId]);
    }
    this.step++;

  }

  selectBoussole(boussole: number): void {
    this.isSelected = boussole;
  }

  changeStepPrecedent(): void {
    if (this.step === 0) {
      this.router.navigate(['/book/part8', this.caseId]);
    }
    this.step--;
  }

  selectEquipement(equipement: string): void {
    const index = this.equipementSelected.indexOf(equipement);
    if (index === -1) {
      this.equipementSelected.push(equipement);
    } else {
      this.equipementSelected.splice(index, 1);
    }
    this.book.equipement = this.equipementSelected;
    localStorage.setItem('book', JSON.stringify(this.book));
  }

  isEquipementSelected(equipement: string): boolean {
    return this.equipementSelected.includes(equipement);
  }
}
