<app-header-info [caseId]='caseId'></app-header-info>

<div id="capture">

  <h4>Bon de sortie de stock du {{ today | date:'longDate' }}</h4>

  <div class="bt mt-3" *ngIf="pv && !pv.sortie">
    <h6 class="mt-4">Panneaux photovoltaïque micro-onduleur</h6>
    <div class="row mb-2">
      <div class="col-lg-3 col-s-6 col-xs-6"><strong>Nombre de modules : </strong> <u>{{ pv.nombre}} </u></div>
      <div class="col-lg-3 col-s-6 col-xs-6"><strong>Marque : </strong><span>Eurener</span></div>
      <div class="col-lg-3 col-s-6 col-xs-6"><strong>Référence modules : </strong><span>Turbo plus {{ pv.puissance }}</span></div>
    </div>
    <div class="row mb-4">
      <div class="col-lg-12 col-s-6 col-xs-6"><strong>Numéro de série modules : </strong><span *ngFor="let module of pv.modules">{{ module.numero }},</span></div>
    </div>

    <div class="row mb-2">
      <div class="col-lg-3 col-s-6 col-xs-6"><strong>Nombre d'onduleurs : </strong><u>{{ pv.nombre }} micro-onduleurs </u></div>
      <div class="col-lg-3 col-s-6 col-xs-6"><strong>Marque : </strong><span>Enphase</span></div>
      <div class="col-lg-3 col-s-6 col-xs-6"><strong>Référence : </strong><span>IQ7</span></div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-s-6 col-xs-6"><strong>Numéro de série onduleurs : </strong><span *ngFor="let onduleur of pv.onduleurs">{{ onduleur.numero }},</span></div>
    </div>

  </div>

  <div class="bt" *ngIf="bt && !bt.sortie">
    <h6>Ballon thermodynamique</h6>
    <div class="row">
      <div class="col-lg-3 col-s-6 col-xs-6">Nombre d'unité extérieure : <u>1 </u></div>
      <div class="col-lg-3 col-s-6 col-xs-6">Marque : <span>Atlantic</span></div>
      <div class="col-lg-3 col-s-6 col-xs-6">Numéro de série module: <span>{{ bt.n_serie_ue }}</span></div>
    </div>

    <div class="row">
      <div class="col-lg-3 col-s-6 col-xs-6">Nombre d'unité intérieure : <u>1 </u></div>
      <div class="col-lg-3 col-s-6 col-xs-6">Marque : <span>Atlantic</span></div>
      <div class="col-lg-3 col-s-6 col-xs-6">Numéro de série module: <span>{{ bt.n_serie_ui }}</span></div>
    </div>

  </div>

  <br>

  <div class="vmc mt-4" *ngIf="vmc && !vmc.sortie">
    <h6>VMC Double Flux</h6>
    <div class="row">
      <div class="col-lg-12 col-s-6 col-xs-6">Numéro de série caisson isolé : <u> {{ vmc.n_serie_caisson }} </u></div>
    </div>
  </div>

  <div class="adoucisseur mt-4" *ngIf="adoucisseur && !adoucisseur.sortie">
    <h6>Adoucisseur d'eau</h6>
    <div class="row">
      <div class="col-lg-12 col-s-6 col-xs-6">Numéro de série : <u> {{ adoucisseur.n_serie }} </u></div>
    </div>
  </div>

</div>
<!-- <button class="btn-secondarycrm" (click)="sign()">Signer</button> -->
<button class="btn-secondarycrm" (click)="clickme()">Confirmer</button>
