import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/_services';
import { CaseCreateComponent } from '@app/case/case-create/case-create.component';
import { CasesService } from '@app/services/cases.service';
import { CustomersService } from '@app/services/customers.service';
import { InfosService } from '@app/services/infos.service';
import { PiecesService } from '@app/services/pieces.service';
import { PrimesService } from '@app/services/primes.service';
import { apiUrl } from '@environments/env';
import { FusionCasesCustumerComponent } from '../fusion-cases-custumer/fusion-cases-custumer.component';
@Component({
  selector: 'app-commercial-customer-case',
  templateUrl: './commercial-customer-case.component.html',
  styleUrls: ['./commercial-customer-case.component.scss'],
})
export class CommercialCustomerCaseComponent implements OnInit {
  countUnread;
  customerId: string;
  currentCustomer;
  currentUser;
  cases;
  states = [
    { name: 'Signée', active: false, color: '#FFFF' },
    { name: 'Prospection', active: false, color: '#505050' },
    { name: 'Négociation', active: false, color: '#FFFF' },
    { name: 'Non Signée', active: false, color: '#505050' },
  ];

  path;
  showCross = false;
  showSelect = false;
  showFusionButton = false;
  isFusionDialogOpen = false;

  filter = {
    state: 'all',
    title: '',
  };
  mobile;
  showUnread = true;
  showRead = true;
  infos;
  infosRead = [];
  infosUnread = [];
  pieces;
  primes;
  allCases;
  allInfos;

  //

  v1;
  v2;

  products = [];
  selectedProducts;
  autres = [];
  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private sCases: CasesService,
    private sCustomers: CustomersService,
    private dialog: MatDialog,
    private sPieces: PiecesService,
    private router: Router,
    private sInfos: InfosService,
    private sPrimes: PrimesService,
    private http: HttpClient
  ) {
    this.authenticationService.user.subscribe((x) => (this.currentUser = x));
    /* this.scriptAllCase() */
  }

  ngOnInit() {
    if (window.screen.width <= 600) {
      this.mobile = true;
    }
    this.customerId = this.route.snapshot.params['id'];
    if (
      this.currentUser.role == 'Commercial' ||
      this.currentUser.role === "Responsable d'agence"
    ) {
      this.showCasesByCustomerAndCommercialId(
        this.customerId,
        this.currentUser.id
      );
      this.path = '/commercial-template';
    } else if (
      this.currentUser.role == 'Admin' ||
      this.currentUser.role == 'Adv' ||
      this.currentUser.role == 'Responsable ADV' ||
      this.currentUser.role == 'Compta'
    ) {
      this.showCasesByCustomerId(this.customerId);
      this.path = '/case-template';
    } else if (this.currentUser.role == 'Responsable Technicien') {
      this.showCasesByCustomerId(this.customerId);
      this.path = '/technicien-case-list-pieces';
    }
    this.showCurrentCustomer();
    this.showInfosByAdvId();
    this.getGroups();

    this.cases.forEach((caseItem) => {
      caseItem.selected = false;
    });
  }

  showCasesByCustomerAndCommercialId(customerId, commercialId) {
    this.sCases
      .getAllCasesByCustomerAndCommercialId(customerId, commercialId)
      .subscribe((res) => {
        this.cases = res;
      });
  }

  showCasesByCustomerId(customerId) {
    this.sCases.getAllCasesByCustomerId(customerId).subscribe((res) => {
      this.cases = res;
    });
  }

  setFilter(value) {
    if (this.filter.state == value) {
      this.filter.state = 'all';
      this.states.forEach((element) => {
        element.active = false;
      });
    } else {
      this.filter.state = value;

      this.states.forEach((element) => {
        if (value == element.name) {
          element.active = true;
        } else {
          element.active = false;
        }
      });
    }
  }
  showCurrentCustomer() {
    this.sCustomers.getCustomerById(this.customerId).subscribe((res) => {
      this.currentCustomer = res[0];
    });
  }

  showEdit() {
    this.showCross = !this.showCross;
    this.showSelect = false;
  }

  showFus() {
    this.showSelect = !this.showSelect;
    this.showCross = false;
    this.showFusionButton = false;

    this.checkIfCasesSelected();
  }

  checkIfCasesSelected() {
    this.showFusionButton = this.cases.some((caseItem) => caseItem.selected);
  }

  toggleCaseSelection(caseItem) {
    this.checkIfCasesSelected();
  }

  fuseCases(): void {
    const selectedCases = this.cases.filter((c) => c.selected); // ici je ne conserve que les affaires que j'ai sélectionnées

    this.isFusionDialogOpen = true;
    const dialogRef = this.dialog.open(FusionCasesCustumerComponent, {
      width: '640px',
      data: {
        selectedCases, // je transmet les affires sélectionnées comme data au composant FusionCasesCustumerComponent
        currentCustomerId: this.customerId, // je transmet l'id du client dont je sélectionne les affaires comme data au composant FusionCasesCustumerComponent,
        // pour ensuite l'exclure dans le loadcustumers().
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.isFusionDialogOpen = false;
      this.showSelect = false;
      this.ngOnInit(); // Je rafraîchis la liste des affaires après la fusion
    });
  }

  goToDetailCase(caseId, selectedCase) {
    this.ngOnInit();
    if (selectedCase.type != 'Contrat de maintenance') {
      if (this.mobile && this.currentUser.role === 'Admin') {
        this.router.navigate(['commercial-template', caseId]);
      } else {
        this.router.navigate([this.path, caseId]);
      }
    } else {
      if (this.currentUser.role === 'Responsable Technicien') {
        this.router.navigate(['technicien-case-list-pieces', caseId]);
      } else {
        this.router.navigate(['case-cm-template', caseId]);
      }
    }
  }
  showPieceByCaseIdAnDelete(caseId) {
    this.sPieces.getPiecesByCaseId(caseId).subscribe((response) => {
      this.pieces = response;
      this.pieces.forEach((element) => {
        this.sPieces.delete(element._id).subscribe((response) => {});
      });
    });
  }

  showPrimesByCaseIdAnDelete(caseId) {
    this.sPrimes.getPrimesByCaseId(caseId).subscribe((response) => {
      this.primes = response;
      this.primes.forEach((element) => {
        this.sPrimes.delete(element._id).subscribe((response) => {});
      });
    });
  }

  deleteCase(id) {
    if (confirm('Etes vous sur de vouloir effectuer la suppression ?')) {
      this.showPieceByCaseIdAnDelete(id);
      this.showPrimesByCaseIdAnDelete(id);

      this.sCases.delete(id).subscribe((response) => {
        if (
          this.currentUser.role == 'Commercial' ||
          this.currentUser.role === "Responsable d'agence"
        ) {
          this.showCasesByCustomerAndCommercialId(
            this.customerId,
            this.currentUser.id
          );
        } else if (
          this.currentUser.role == 'Admin' ||
          this.currentUser.role == 'Adv' ||
          this.currentUser.role == 'Responsable ADV'
        ) {
          this.showCasesByCustomerId(this.customerId);
        }
      });
      this.showCross = false;
    }
  }
  createCaseDialog(currentCustomer): void {
    const dialogRef = this.dialog.open(CaseCreateComponent, {
      width: '640px',
      data: {
        currentCustomer: currentCustomer,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (
        this.currentUser.role == 'Commercial' ||
        this.currentUser.role === "Responsable d'agence"
      ) {
        this.showCasesByCustomerAndCommercialId(
          this.customerId,
          this.currentUser.id
        );
      } else if (
        this.currentUser.role == 'Admin' ||
        this.currentUser.role == 'Adv' ||
        this.currentUser.role == 'Responsable ADV'
      ) {
        this.showCasesByCustomerId(this.customerId);
      }
    });
  }

  showInfosByAdvId() {
    this.infosRead = [];
    this.infosUnread = [];
    this.sInfos.getInfoByAdvId(this.currentUser.id).subscribe((res) => {
      this.infos = res;
      this.infos.forEach((element) => {
        if (element.vu) {
          this.infosRead.push(element);
        } else {
          this.infosUnread.push(element);
        }
      });
    });
  }

  async getGroups(): Promise<void> {
    try {
      const countUnread: any = await this.http
        .get(apiUrl.apiUrl + '/messages/count-unread')
        .toPromise();
      this.countUnread = countUnread;
    } catch (error) {}
  }
}
