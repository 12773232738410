<mat-horizontal-stepper [linear]="true" #stepper>
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup">
      <div>
        <h5>Champs photovoltaïque</h5>
        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
              <label>Marque et type</label>
              <input class="custom-input" type="text" formControlName="marque_type" (change)="getCurrentKitAndSaveChange($event.value,'marque_type')">
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">

            <div class="button-wrapper mt-1">
              <label>N° de série</label>
              <label class="upload-label" for="n_serie_pv" *ngIf="!n_serie_pv">+ Ajouter la photo</label>
              <p class="mb-0">
                <a target="blank" class="check" *ngIf="n_serie_pv" [routerLink]="['/detail-piece', piece_serie_pv[0]._id]">Photos n° de série</a>
              </p>
              <p *ngIf="n_serie_pv" style="color: lightgrey;font-weight: 300; margin-top: 0;
              font-size: small;">Prise le {{ piece_serie_pv[0].created_at | date:'medium' }}</p>

              <input *ngIf="!n_serie_pv" accept=".jpg,.jpeg,.png" multiple type="file" multiple name="n_serie_pv" id="n_serie_pv" class="upload-box" (change)="selectMultipleNserie($event, 'N° de serie panneaux solaires','n_serie_pv')">
            </div>

          </div>

          <div class="col-lg-3 col-s-6 col-xs-6">
            <label>Nombre</label>
            <div class="increment">
              <button
                class="rm"
                mat-button
                mat-icon-button
                matPrefix
                aria-label="Remove"
                (click)="incrementValueOnduleur(-_step, currentKit.type,'edité', 'module_reserve', currentKit.nbr_onduleur)">
                <mat-icon>remove</mat-icon>
              </button>
              <input type="number"[value]="currentKit.nbr_onduleur" />
              <button
                class="add"
                mat-button
                mat-icon-button
                matSuffix
                aria-label="Add"
                (click)="incrementValueOnduleur(_step, currentKit.type,'edité', 'nbr_onduleur', currentKit.nbr_onduleur)"
                >
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <div class="row my-5">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Pose</label>
            <mat-form-field>
              <mat-select formControlName="pose" (selectionChange)="addOption(currentKit.type,'edité', 'pose', 'pose', firstFormGroup.value.pose)"  (selectionChange)="verifyForm()">
                <mat-option *ngFor="let pose of poses_pv" [value]="pose" >
                  {{pose}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Orientation</label>
            <mat-form-field>
              <mat-select  formControlName="orientation" (selectionChange)="addOption(currentKit.type,'edité', 'orientation', 'orientation', firstFormGroup.value.orientation)">
                <mat-option *ngFor="let orientation of orientations" [value]="orientation">
                  {{ orientation }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col">
            <label>Déclivité</label>
            <input class="custom-input" formControlName="declivite" (change)="getCurrentKitAndSaveChange($event.value,'declivite')">
          </div>
        </div>
      <div>
        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Traversée toiture des câbles :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="traverse_toiture" (change)="getCurrentKitAndSaveChange($event.value,'traverse_toiture')">
              <mat-radio-button value="Col de cygne">Col de cygne</mat-radio-button>
              <mat-radio-button value="Tuile chatière" class="ml-4">Tuile chatière</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Étanchéité abergement gauche : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="etancheite_abergement_g" (change)="getCurrentKitAndSaveChange($event.value,'etancheite_abergement_g')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" >Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Étanchéité en partie supérieure du champs : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="etancheite_superieure" (change)="getCurrentKitAndSaveChange($event.value,'etancheite_superieure')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" >Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Étanchéité abergement droite : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="etancheite_abergement_d" (change)="getCurrentKitAndSaveChange($event.value,'etancheite_abergement_d')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Étanchéité en partie inférieure du champs : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="etancheite_inferieure" (change)="getCurrentKitAndSaveChange($event.value,'etancheite_inferieure')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Étanchéité inter module : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="etancheite_inter" (change)="getCurrentKitAndSaveChange($event.value,'etancheite_inter')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Partie inférieure du champ</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="inferieure_champ" (change)="getCurrentKitAndSaveChange($event.value,'inferieure_champ')">
              <mat-radio-button value="à l'égout">à l'égout</mat-radio-button>
              <mat-radio-button value="Bavette etancheité" class="ml-4">Bavette etancheité</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Écran sous toiture :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="ecran_sous_toiture" (change)="getCurrentKitAndSaveChange($event.value,'ecran_sous_toiture')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>


        </div>
      </div>

<!-- AU SOL -->
      <div *ngIf="sol">
        <div class="row last">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Chassis lestés : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="ancrage" (change)="getCurrentKitAndSaveChange($event.value,'ancrage')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

      </div>

<!-- SUR IMPOSITION -->
<div *ngIf="surimpo">
  <div class="row">

    <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
      <mat-label>Étanchéité des pattes de fixations : </mat-label>
      <mat-radio-group aria-label="Select an option" formControlName="etancheite_fixation" (change)="getCurrentKitAndSaveChange($event.value,'etancheite_fixation')">
        <mat-radio-button value="oui">Oui</mat-radio-button>
        <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
      <mat-label>Pattes de fixation conformes</mat-label>
      <mat-radio-group aria-label="Select an option" formControlName="patte_fixation" (change)="getCurrentKitAndSaveChange($event.value,'patte_fixation')">
        <mat-radio-button value="oui">Oui</mat-radio-button>
        <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="row last">
    <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
      <mat-label>Contrôle étanchéité par arrosage : </mat-label>
      <mat-radio-group aria-label="Select an option" formControlName="etancheite_arrosage" (change)="getCurrentKitAndSaveChange($event.value,'etancheite_arrosage')">
        <mat-radio-button value="oui">Oui</mat-radio-button>
        <mat-radio-button value="non" class="ml-4" >Non</mat-radio-button>
      </mat-radio-group>
    </div>


    <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
      <mat-label>Traversée toiture des câbles :</mat-label>
      <mat-radio-group aria-label="Select an option" formControlName="traverse_toiture" (change)="getCurrentKitAndSaveChange($event.value,'traverse_toiture')">
        <mat-radio-button value="Col de cygne">Col de cygne</mat-radio-button>
        <mat-radio-button value="Tuile chatière" class="ml-4">Tuile chatière</mat-radio-button>
      </mat-radio-group>
    </div>

  </div>

</div>
<!-- facade -->
      <div *ngIf="facade">
        <div class="row last">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>En brise soleil :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="brise_soleil" (change)="getCurrentKitAndSaveChange($event.value,'brise_soleil')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col radio-choice last title-margin">
            <mat-label>Chassis sur structure porteuse :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="chassis" (change)="getCurrentKitAndSaveChange($event.value,'chassis')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

      </div>
      </div>
          <button class="btn-secondarycrm" matStepperNext [style.opacity]="!firstFormGroup.valid ? '0.4' : '1'" (click)="updateData(50)">Suivant</button>
    </form>
  </mat-step>

  <mat-step [stepControl]="secondFormGroup">
    <form [formGroup]="secondFormGroup">
        <h5>Onduleur et coffret AC</h5>
        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
              <label>Marque et type</label>
              <input class="custom-input" type="text" formControlName="marque_type_onduleur" (change)="getCurrentKitAndSaveChange(this.secondFormGroup.value.marque_type_onduleur,'marque_type_onduleur')">
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">

            <div class="button-wrapper mt-1">
              <label>N° de série</label>
              <label class="upload-label" for="n_serie_ondu" *ngIf="!n_serie_ondu">+ Ajouter la photo</label>
              <p class="mb-0">
                <a target="blank" class="check" *ngIf="n_serie_ondu" [routerLink]="['/detail-piece', piece_serie_pv[0]._id]">Photos n° de série</a>
              </p>
              <p *ngIf="n_serie_ondu" style="color: lightgrey;font-weight: 300; margin-top: 0;
              font-size: small;">Prise le {{ piece_serie_pv[0].created_at | date:'medium' }}</p>

              <input *ngIf="!n_serie_ondu" accept=".jpg,.jpeg,.png" multiple type="file" multiple name="n_serie_ondu" id="n_serie_ondu" class="upload-box" (change)="selectMultipleNserie($event, 'N° de serie onduleurs','n_serie_ondu')">
            </div>
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Relevé compteur d’injection</label>
            <input class="custom-input" type="text" formControlName="compteur_injection" (change)="getCurrentKitAndSaveChange(this.secondFormGroup.value.compteur_injection,'compteur_injection')">
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
              <label>Relevé compteur de sous tirage</label>
              <input class="custom-input" type="text" formControlName="compteur_sous_tirage" (change)="getCurrentKitAndSaveChange(this.secondFormGroup.value.compteur_sous_tirage,'compteur_sous_tirage')">
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Type de relais de découplage</label>
            <input class="custom-input" type="text" formControlName="type_relais" (change)="getCurrentKitAndSaveChange(this.secondFormGroup.value.type_relais,'type_relais')">
          </div>

          <div class="col-lg-3 col-s-6 col-xs-6 custom-col title-margin">
            <label>Nombre de relais</label>
            <div class="increment">
              <button
                class="rm"
                mat-button
                mat-icon-button
                matPrefix
                aria-label="Remove"
                (click)="incrementValueRelais(-_step, currentKit.type,'edité', 'nbr_relais', currentKit.nbr_relais)">
                <mat-icon>remove</mat-icon>
              </button>
              <input type="number"[value]="currentKit.nbr_relais" />
              <button
                class="add"
                mat-button
                mat-icon-button
                matSuffix
                aria-label="Add"
                (click)="incrementValueRelais(_step, currentKit.type,'edité', 'nbr_relais', currentKit.nbr_relais)"
                >
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Coffret à hauteur réglementaire : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="hauteur_coffret" (change)="getCurrentKitAndSaveChange($event.value,'hauteur_coffret')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Parafoudre protégé : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="parafoudre" (change)="getCurrentKitAndSaveChange($event.value,'parafoudre')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" >Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Coupure AC inf. à 1m80 :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="coupure_ac" (change)="getCurrentKitAndSaveChange($event.value,'coupure_ac')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Signalement réglementaire :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="signalement_reglementaire" (change)="getCurrentKitAndSaveChange($event.value,'signalement_reglementaire')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Inter différentiel 30mA : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="inter_diff" (change)="getCurrentKitAndSaveChange($event.value,'inter_diff')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" >Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Continuité de terre :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="continuite_terre" (change)="getCurrentKitAndSaveChange($event.value,'continuite_terre')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row last">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Disjoncteur :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="disjoncteur" (change)="getCurrentKitAndSaveChange($event.value,'disjoncteur')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

          <button class="btn-secondarycrm" matStepperNext [style.opacity]="!secondFormGroup.valid ? '0.4' : '1'" (click)="updateData(75)">Suivant</button>

    </form>
  </mat-step>


  <mat-step [stepControl]="thirdFormGroup">
    <form [formGroup]="thirdFormGroup">

        <h5 class="title-margin">Passerelle de communication</h5>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Raccordement électrique :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="raccordement_electrique" (change)="getCurrentKitAndSaveChange($event.value,'raccordement_electrique')">
              <mat-radio-button value="Coffret AC">Coffret AC</mat-radio-button>
              <mat-radio-button value="Autre prise" class="ml-4">Autre prise</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Connexion : </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="connexion" (change)="getCurrentKitAndSaveChange($event.value,'connexion')">
              <mat-radio-button value="Ethernet">Ethernet</mat-radio-button>
              <mat-radio-button value="Wifi" class="ml-4">Wifi</mat-radio-button>
              <mat-radio-button value="CPL" class="ml-4">CPL</mat-radio-button>

            </mat-radio-group>
          </div>
        </div>


        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Installée à proximité du tableau client :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="proximite_tableau" (change)="getCurrentKitAndSaveChange($event.value,'proximite_tableau')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Sité créé :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="site" (change)="getCurrentKitAndSaveChange($event.value,'site')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row last">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Monitoring internet :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="monitoring_internet" (change)="getCurrentKitAndSaveChange($event.value,'monitoring_internet')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" >Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Compte client créé + explications interface</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="compte_client" (change)="getCurrentKitAndSaveChange($event.value,'compte_client')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" >Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
          <button class="btn-secondarycrm" matStepperNext [style.opacity]="!thirdFormGroup.valid ? '0.4' : '1'" (click)="updateData(100)">Suivant</button>
    </form>
  </mat-step>

  <mat-step [stepControl]="fourFormGroup">
    <form [formGroup]="fourFormGroup">
        <h5>Autre</h5>

        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Nettoyage du champs de production :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="nettoyage_champs" (change)="getCurrentKitAndSaveChange($event.value,'nettoyage_champs')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" >Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Documentation remise :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="documentation" (change)="getCurrentKitAndSaveChange($event.value,'documentation')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" >Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>


        <div class="row">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Signalisation sur AGCP :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="signalisation_agcp" (change)="getCurrentKitAndSaveChange($event.value,'signalisation_agcp')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Signalisation SAV Enerconfort :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="signalisation" (change)="getCurrentKitAndSaveChange($event.value,'signalisation')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" >Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

        <div class="row last">
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Autoconsommation coupure dans le tableau du client :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="autoconso_coupure" (change)="getCurrentKitAndSaveChange($event.value,'autoconso_coupure')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4" >Non</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-lg-6 col-s-6 col-xs-6 custom-col radio-choice title-margin">
            <mat-label>Nettoyage chantier :</mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="nettoyage" (change)="getCurrentKitAndSaveChange($event.value,'nettoyage')">
              <mat-radio-button value="oui">Oui</mat-radio-button>
              <mat-radio-button value="non" class="ml-4">Non</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>

          <button class="btn-secondarycrm" matStepperNext [style.opacity]="!fourFormGroup.valid ? '0.4' : '1'" (click)="updateData(100)">Suivant</button>
    </form>
  </mat-step>

  <mat-step>

    <textarea class="custom-textarea" placeholder="Observations" [(ngModel)]="currentKit.observation" (change)="getCurrentKitAndSaveChange('observation',case.observation)"></textarea>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Champ photovoltaïque
        <label class="upload-label" for="upload" *ngIf="!champ_photo"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="champ_photo"></p>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="upload" class="upload-box" (change)="selectMultiple($event, 'Champ photovoltaïque')">
      </div>

      <div class="button-wrapper mt-3 col-lg-6">
        Toîture globale
        <label class="upload-label" for="upload2" *ngIf="!toiture"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="toiture"></p>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="upload2" class="upload-box" (change)="selectMultiple($event, 'Toîture globale')">
      </div>
    </div>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Coffret AC/DC
        <label class="upload-label" for="upload3" *ngIf="!coffret_acdc"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="coffret_acdc"></p>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="upload3" class="upload-box" (change)="selectMultiple($event, 'Coffret AC/DC')">

      </div>

      <div class="button-wrapper mt-3 col-lg-6">
        Pièces onduleurs
        <label class="upload-label" for="upload4" *ngIf="!pieces"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="pieces"></p>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="upload4" class="upload-box" (change)="selectMultiple($event, 'Pièces onduleurs')">
      </div>

    </div>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Coffret de sécurité
        <label class="upload-label" for="uploadCoffre" *ngIf="!coffret_secu"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="coffret_secu"></p>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="uploadCoffre" class="upload-box" (change)="selectMultiple($event, 'Coffret de sécurité')">
      </div>

    </div>

    <div class="row">
      <div class="button-wrapper mt-3 col-lg-6">
        Onduleur
        <label class="upload-label" for="upload5" *ngIf="!onduleur"><mat-icon>add</mat-icon> <span *ngIf="!mobile">Ajouter la photo</span></label>
        <p class="check" *ngIf="onduleur"></p>

        <input accept=".jpg,.jpeg,.png" multiple type="file" name="upload" id="upload5" class="upload-box" (change)="selectMultiple($event, 'Onduleur')">
      </div>

    </div>
        <button class="btn-secondarycrm" *ngIf="!isValid" (click)="updateData(100)"(click)="valid()">Confirmer</button>

  </mat-step>


</mat-horizontal-stepper>
