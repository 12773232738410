import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { HistoriqueService } from '@app/services/historique.service';
import { PiecesService } from '@app/services/pieces.service';
import { PrimesService } from '@app/services/primes.service';
import { RolesService } from '@app/services/roles.service';
import { UsersService } from '@app/services/users.service';
import { apiUrl} from '@environments/env';


@Component({
  selector: 'app-compta-primes-detail',
  templateUrl: './compta-primes-detail.component.html',
  styleUrls: ['./compta-primes-detail.component.scss']
})
export class ComptaPrimesDetailComponent implements OnInit {
  primeId
  caseId
  case;
  prime;
  primeForm:FormGroup
  editCustomer = false
  billImg
  multipleImagesPieces
  apiUrl = apiUrl.apiUrl + '/piece/';
  //apiUrl = 'http://localhost:4000/piece/';
  bill;
  multipleProduct = false;

  constructor(
    private route: ActivatedRoute,
    private sPrimes: PrimesService,
    private sCases: CasesService,
    public sUsers: UsersService,
    private fb: FormBuilder,
    private sb: MatSnackBar,
    private http: HttpClient,
    private sPieces: PiecesService,
    private sRoles: RolesService,
    private sHistoriques: HistoriqueService,
  ) { }

  ngOnInit(): void {
        this.primeId = this.route.snapshot.params['id'];
        this.sPrimes.getPrimeById(this.primeId).subscribe(res => {
          this.prime = res[0]
          this.caseId = this.prime.caseId

          this.sCases.getCaseById(this.prime.caseId).subscribe(res => {
            this.case = res[0]
            if(JSON.stringify(this.prime.product).substring(0,1) === '[' && JSON.stringify(this.prime.product) != '[]') {
              this.multipleProduct = true;
            }
          })
          this.primeForm = this.fb.group({
            type: [this.prime.type],
            state: [this.prime.state],
            date_saisie: [this.prime.date_saisie],
            date_complement: [this.prime.date_complement],
            date_virement: [this.prime.date_virement],
            date_envoi: [this.prime.date_envoi],
            date_appel: [this.prime.date_appel],
            n_appel: [this.prime.n_appel],
            envoi: [this.prime.envoi],
            montant: [this.prime.montant],
            product: [this.prime.product],
            n_dossier: [this.prime.n_dossier]
          });
          this.getBill()
        })
  }

  saveChange(reason, change, value) {
    this.sPrimes.editPrime(this.primeForm.value, this.primeId).then(res => {
      if(res['error']) {
        return res.error;
      }
    });
  }

  toggleEditCustomer(Boolean){
    this.editCustomer = Boolean
  }

  selectMultiple(event, title) {
    if (event.target.files.length > 0) {
      this.multipleImagesPieces = event.target.files;
      this.onSubmitMultiple(title,'ajouté','pièce',title )
    }
  }

  onSubmitMultiple(title, reason, change,value) {
    let titleProduct
    let prods = []
    if(this.multipleProduct) {
      this.prime.product.forEach(element => {
        prods.push(element.name)
      });
      titleProduct =  prods.toString()
    }
    else if(JSON.stringify(this.prime.product) === '[]') {
      titleProduct = new Date(this.prime.created_at).getTime()
    }
    else {
      titleProduct = this.prime.product.name.includes('/') ? this.prime.product.name.replace('/', ' ') : this.prime.product[0].name
    }
    const now = new Date().toString()
    const formData = new FormData();

    formData.append('title', 'facture '+ this.prime.type + ' ' + titleProduct);
    formData.append('description', '');
    formData.append('qualification', 'Conforme');
    formData.append('caseId', this.caseId);
    formData.append('created_at', now);

    for (let img of this.multipleImagesPieces) {
      formData.append('filesPieces', img);
    }
    this.prime.facture_done = true
    this.sPrimes.editPrime(this.prime, this.prime._id).then(res => {
      if(res['error']) {
        return res.error;
      }

    })
     this.http.post<any>(this.apiUrl + 'multiplePieces', formData).subscribe(
      (res) => {
        this.sb.open(`Facture ajoutée`, '', { duration: 2000, verticalPosition: 'bottom', panelClass: ['add-snackbar'] });
        this.getBill()
      },
      (err) => console.log(err)
    );
  }
  getBill(){
    let titleProduct;
    if(JSON.stringify(this.prime.product) === '[]') {
      titleProduct = 'empty'
    }
    else {
      titleProduct = this.prime.product.name.includes('/') ? this.prime.product.name.replace('/', ' ') : this.prime.product.name
    }

    this.sPieces.getBillPrimeByCaseId(this.prime.type,new Date(this.prime.created_at).getTime(), JSON.stringify(titleProduct), this.caseId).subscribe(res =>{
      this.bill = res[0]
      this.billImg = this.bill.image.split(',')[0]

    })
  }
  killBill(id,reason, change,value) {
    if (confirm("Confirmer la suppression?")) {
      this.sPieces.delete(id).subscribe(response => {
        this.bill = null
        this.prime.facture_done = false
        this.sPrimes.editPrime(this.prime, this.prime._id).then(res => {
          if(res['error']) {
            return res.error;
          }
          else {
            this.sb.open(`Facture supprimée`, '', { duration: 2000, verticalPosition: 'bottom', panelClass: ['delete-snackbar'] });
          }
        })
      });
      this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change,value,this.sRoles.user.id).then(res => {
        if (res['error']) {
          alert(res);
        }
      });
    }
  }

}


