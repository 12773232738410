<div class="book-container">
  <img class="logo_couleur" src="./../../../assets/book/logo_normal.jpg" alt="logo" />
  <h1>Évolution des prix des énergies entre 2010 et 2024</h1>
  <div class="timeline">
    <span>En juin 2010</span>
    <span class="arrow-svg">
      <svg width="96" height="16" viewBox="0 0 96 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="animated-path"
          d="M1.30543 5.04778C0.779534 4.8791 0.216468 5.16868 0.0477849 5.69457C-0.120898 6.22047 0.168678 6.78353 0.694572 6.95222L1.30543 5.04778ZM95.8844 6.46676C96.1422 5.97833 95.9552 5.3734 95.4668 5.11562L87.5073 0.914788C87.0189 0.657004 86.4139 0.843982 86.1562 1.33241C85.8984 1.82085 86.0854 2.42577 86.5738 2.68356L93.6489 6.41763L89.9148 13.4927C89.657 13.9811 89.844 14.5861 90.3324 14.8438C90.8208 15.1016 91.4258 14.9147 91.6836 14.4262L95.8844 6.46676ZM0.694572 6.95222C9.44221 9.75807 19.3467 14.0649 33.9022 15.4952C48.4798 16.9277 67.6988 15.4852 95.2953 6.9554L94.7047 5.0446C67.3012 13.5148 48.3536 14.9057 34.0978 13.5048C19.82 12.1018 10.2245 7.90861 1.30543 5.04778L0.694572 6.95222Z"
          fill="#031966" />
      </svg>
    </span>
    <span>En juin 2024</span>
  </div>

  <div class="bar-chart">
    <div class="bar" data-value="0,08 €">
      <div class="year">2010</div>
      <div class="value">0,08 €</div>
      <div class="additional-info">
        <div class="contain-circle-svg">
          <img src="./../../../assets/book/part2/fioul.svg" alt="fioul" class="circle-svg fioul">
        </div>
        <div class="label fioul">Fioul</div>
        <div class="percentage fioul">+ 20 %</div>
      </div>
    </div>
    <div class="bar" data-value="0,13 €">
      <div class="year">2024</div>
      <div class="value">0,13 €</div>
    </div>
    <div class="bar" data-value="0,03 €">
      <div class="year">2010</div>
      <div class="value">0,03 €</div>
      <div class="additional-info">
        <div class="contain-circle-svg">

          <img src="./../../../assets/book/part2/gaz.svg" alt="gaz" class="circle-svg gaz">
        </div>

        <div class="label gaz">Gaz</div>
        <div class="percentage red gaz">+ 333 %</div>
      </div>
    </div>
    <div class="bar" data-value="0,13 €">
      <div class="year">2024</div>
      <div class="value">0,13 €</div>
    </div>
    <div class="bar" data-value="0,07 €">
      <div class="year">2010</div>
      <div class="value">0,07 €</div>
      <div class="additional-info">
        <div class="contain-circle-svg">

          <img src="./../../../assets/book/part2/granule.svg" alt="granule" class="circle-svg granule">
        </div>

        <div class="label granule">Granulé</div>
        <div class="percentage granule">+ 57 %</div>
      </div>
    </div>
    <div class="bar" data-value="0,11 €">
      <div class="year">2024</div>
      <div class="value">0,11 €</div>
    </div>
    <div class="bar" data-value="0,13 €">
      <div class="year">2010</div>
      <div class="value">0,13 €</div>
    </div>
    <div class="bar" data-value="0,25€">
      <div class="year">2024</div>
      <div class="value">0,25 €</div>
      <div class="additional-info" style="margin:0 0 -7rem 0;">
        <div class="contain-circle-svg">

          <img src="./../../../assets/book/part2/electricite.svg" alt="electricite" class="circle-svg electricite">
        </div>

        <div class="label electricite">Électricité</div>
        <div class="percentage red electricite">+ 92 %</div>
      </div>
    </div>
    <div class="bar" data-value="0,35 €">
      <svg width="23" height="34" viewBox="0 0 23 34" fill="none" xmlns="http://www.w3.org/2000/svg" style="position: absolute;
      top: 2.5rem;">
        <path
          d="M8.6751 32.8549C8.92739 33.2697 8.43494 33.7355 8.0371 33.4589C5.08001 31.4139 -0.412091 26.8388 0.0245597 21.32C0.61889 13.7951 5.28378 11.2043 6.97216 8.02885C8.25543 5.61029 8.21904 2.0443 8.12686 0.477211C8.10503 0.120613 8.48831 -0.11712 8.79882 0.0599667C10.6328 1.10793 15.2831 4.5793 15.5014 12.9024C15.5087 13.1716 15.744 13.3778 16.0108 13.3414C17.5343 13.1304 18.4925 9.73421 18.8806 8.01187C18.9679 7.62131 19.4822 7.53155 19.6981 7.86874C20.6976 9.43583 22.5242 13.2541 22.1603 19.8184C21.811 26.1256 18.0558 30.6449 15.8726 32.7627C15.5208 33.1047 14.9556 32.7215 15.1424 32.2703C15.9817 30.2471 17.1655 26.9504 17.168 24.4081C17.168 23.9666 16.5979 23.7992 16.3456 24.1607C15.482 25.3906 14.3055 25.934 13.5268 26.1693C13.1799 26.2736 12.8548 25.9558 12.9616 25.6089C13.6238 23.4814 15.0138 17.2786 9.96807 14.0959C9.67455 13.9115 9.29126 14.1031 9.27186 14.4476C9.00016 19.5467 8.33063 18.5206 6.52823 23.0788C5.24011 26.3367 7.26569 30.5285 8.67753 32.8573L8.6751 32.8549Z"
          fill="white" />
      </svg>

      <div class="year">Prévision 2030</div>
      <div class="value">0,35 €</div>
    </div>
    <div class="bar">
    </div>

  </div>
  <div class="sous-texte">
    <h5>Comparaison du coût des énergies</h5>
    <p>en euros par kilowattheure (kWh)</p>
  </div>

  <div class="right-container">
    <div class="item">
      <img src="./../../../assets/book/part2/card1.jpg" alt="Description" style="width:80px;height: 50px;">
      <div class="text">
        Le prix de l’électricité a connu une augmentation considérable de <strong class="red">+92%</strong> entre 2010
        et 2024 !
      </div>
    </div>
    <div class="item">
      <img src="./../../../assets/book/part2/card2.jpg" alt="Description" style="width:80px;height: 50px;">
      <div class="text">
        Entre 2010 et 2024, le prix du litre de fioul a augmenté de <strong class="warning">62%</strong>.
      </div>
    </div>
    <div class="item">
      <img src="./../../../assets/book/part2/card3.jpg" alt="Description" style="width:80px;height: 50px;">
      <div class="text">
        Le prix du gaz a plus que quadruplé en augmentant de <strong class="red">333%</strong> en 15 ans à peine !
      </div>
    </div>
    <div class="item">
      <img src="./../../../assets/book/part2/card4.jpg" alt="Description" style="width:80px;height: 50px;">
      <div class="text">
        Depuis 2010, le prix du granulé de bois a subi une augmentation de <strong class="warning">57%</strong>.
      </div>
    </div>
  </div>

  <button class="primary-button" [routerLink]="['/book/part4', caseId]">Suivant <svg width="22" height="20"
      viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0303 6.53057C13.3232 6.23768 13.3232 5.76281 13.0303 5.46991L8.25736 0.696943C7.96447 0.40405 7.48959 0.40405 7.1967 0.696943C6.90381 0.989837 6.90381 1.46471 7.1967 1.7576L11.4393 6.00024L7.1967 10.2429C6.90381 10.5358 6.90381 11.0107 7.1967 11.3035C7.48959 11.5964 7.96447 11.5964 8.25736 11.3035L13.0303 6.53057ZM0.5 6.75024H12.5V5.25024H0.5V6.75024Z"
        fill="white" />
    </svg>
  </button>

</div>


<div class="bandeau">
  <h1>En résumé</h1>
</div>