<img *ngIf="mobile" class="notification" [routerLink]="['/infos-advs']" src="../../../assets/icons/notification.svg">
<p *ngIf="mobile" class="compteur">
  <span matBadge="{{ infosUnread.length }}" matBadgeSize="small"></span>
</p>
<img *ngIf="mobile" class="messagerie-icon" [routerLink]="['/messagerie']" src="../../../assets/bar-icons/messagerie.svg">
<p *ngIf="mobile" class="compteur-messagerie">
  <span *ngIf="countUnread" matBadge="{{ countUnread.count }}" matBadgeSize="small"></span>
</p>

<div class="marge-off mt-5">
<div class="container-fluid" *ngIf="currentCustomer">
  <button *ngIf="currentUser.role === 'Commercial' || currentUser.role === 'Responsable d\'agence'"  mat-button [routerLink]="['/commercial-customer-list']"><img src="../../../assets/icons/retour.svg">Retour</button>
  <button *ngIf="currentUser.role === 'Adv' || currentUser.role === 'Admin' && !mobile"  mat-button [routerLink]="['/admin-customer-list']"><img src="../../../assets/icons/retour.svg">Retour</button>
  <button *ngIf="currentUser.role === 'Adv' || currentUser.role === 'Admin' && mobile"  mat-button [routerLink]="['/commercial-customer-list']"><img src="../../../assets/icons/retour.svg">Retour</button>


<div class="row mt-5">

  <div class="col-3" *ngIf="!mobile">
    <h2 class="mb-0">{{ currentCustomer.name }}</h2>
    <a href="mailto:{{ currentCustomer.mail }}">{{ currentCustomer.mail }}</a>
  </div>

  <div class="col-6 customer" *ngIf="mobile">
    {{ currentCustomer.name }}
  </div>

  <div class="col-4" *ngIf="mobile">
    <mat-select
     class="rdv" [(ngModel)]='filter.state'>
      <mat-option value='all'>Tous</mat-option>
      <mat-option *ngFor="let state of states" [value]="state.name">{{state.name}}</mat-option>
    </mat-select>
  </div>
  <div class="col-9 text-right" *ngIf="!mobile">
    <button class="filter" *ngFor="let state of states" [ngClass]="!state.active ? 'filter'+state.name : 'filter'+state.name+'Disabled'" (click)="setFilter(state.name)">{{state.name}}</button>
  </div>

</div>
      <div class="content">
              <div class="row">
                  <div class="col-xs-5 col-lg-4 col-sm-4" *ngFor="let case of cases | statePipe: filter.state ">
                      <div class="card" [ngClass]="(showCross || showSelect) ? 'disabled' : ''" (click)="showCross || showSelect || goToDetailCase(case._id, case)">
                          <div class="card-content">
                            <p class="mb-0"><strong>CA HT</strong></p>
                            <p> {{ case.price_ht | currency:'EUR':true }}  </p>
                            <p *ngIf="case.state == 'Signée'" class="mb-0"><strong>Kit vendu</strong></p>
                            <app-product-card *ngIf="case.state == 'Signée'" [kit]="case.kits_vendus"></app-product-card>
                            <p *ngIf="case.state != 'Signée'" class="mb-0"><strong>Titre</strong></p>
                            <p *ngIf="case.state != 'Signée'">{{case.title}}</p>
                            <p class="state" [ngClass]="case.state">{{ case.state }}</p>
                            <p class="type" *ngIf="case.type === 'Contrat de maintenance'">{{ case.type }}</p>
                            <p class="commercial" *ngIf="case.commercial">{{ case.commercial.username }}</p>
                          </div>
                      </div>
                      <img class="delete" src="../../../assets/icons/delete.svg" *ngIf="showCross" (click)="deleteCase(case._id)">

                      <input type="checkbox" *ngIf="showSelect" [(ngModel)]="case.selected" (change)="toggleCaseSelection(case)" [disabled]="isFusionDialogOpen">

                  </div>
                  <div class="col-xs-5 col-lg-4 col-sm-4">
                    <div class="card">
                        <div class="card-content add text-center">
                          <img src="../../../assets/icons/add.svg" (click)="createCaseDialog(currentCustomer)">
                        </div>
                    </div>
                </div>
                </div>
        </div>
        <a class="p-3" (click)="showEdit()"  *ngIf="currentUser.role === 'Adv' || currentUser.role === 'Admin'">Modifier la liste d'affaire</a>
        <a class="p-3" (click)="showFus()"  *ngIf="currentUser.role === 'Adv' || currentUser.role === 'Admin'">Fusionner des affaires</a>

        <button *ngIf="showFusionButton && showSelect" class="fusion-button" (click)="fuseCases()">Fusion</button>
</div>
</div>
