import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ModalPrevisualizeComponent } from '@app/pv/modal-previsualize/modal-previsualize.component';
import { NoModalComponent } from '@app/pv/no-modal/no-modal.component';
import { CasesService } from '@app/services/cases.service';
import { ChantierService } from '@app/services/chantier.service';
import { PdfsService } from '@app/services/pdfs.service';
import { PiecesService } from '@app/services/pieces.service';

@Component({
  selector: 'app-recette-pv-onduleur',
  templateUrl: './recette-pv-onduleur.component.html',
  styleUrls: ['./recette-pv-onduleur.component.scss']
})
export class RecettePvOnduleurComponent {

  @Input() currentKit;
  @Input() case;
  @Input() chantierId
  @Input()
  public data: any

  @Output()
  public onData: EventEmitter<any> = new EventEmitter<any>();

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourFormGroup: FormGroup;
  surimpo = false
  sol = false
  facade = false
  integration = false
  poses_pv = ["Sol","Façade","Intégration","Sur imposition"]
  orientations = ["Portrait","Paysage"]

  champ_photo = false
  toiture = false
  coffret_acdc = false
  pieces = false
  coffret_secu = false
  onduleur = false

  multipleImagesPieces
  lastChantier
  _step: number = 1;
  mobile = false

  createDoc
  loader
  recetteChoice

  @Input()
  set step(_step: number) {
    this._step = this.parseNumber(_step);
  }
  private parseNumber(num: any): number {
    return +num;
  }
  ngAfterContentInit() {
    this.updateData(25)
    this.verifyForm()
    this.secondFormGroup = this._formBuilder.group({
      marque_type_onduleur: [this.currentKit.marque_type_onduleur, Validators.required],
      compteur_injection: [this.currentKit.compteur_injection, Validators.required],
      compteur_sous_tirage: [this.currentKit.compteur_sous_tirage, Validators.required],
      tension_cc: [this.currentKit.tension_cc, Validators.required],
      tension_ac: [this.currentKit.tension_ac, Validators.required],

      install_ventilee: [this.currentKit.install_ventilee, Validators.required],
      degagement_conforme: [this.currentKit.degagement_conforme, Validators.required],
      defaut_isolement: [this.currentKit.defaut_isolement, Validators.required],
      signalement_reglementaire: [this.currentKit.signalement_reglementaire, Validators.required],
      mise_terre_capot: [this.currentKit.mise_terre_capot, Validators.required],
      degagement_lateraux_conforme: [this.currentKit.degagement_lateraux_conforme, Validators.required],
    });

    this.thirdFormGroup = this._formBuilder.group({
      coffret_dc: [this.currentKit.coffret_dc, Validators.required],
      parafoudre_protege: [this.currentKit.parafoudre_protege, Validators.required],
      interrupteur_sectionneur: [this.currentKit.interrupteur_sectionneur, Validators.required],
      continuite_terres: [this.currentKit.continuite_terres, Validators.required],
      parafoudre: [this.currentKit.parafoudre, Validators.required],
      plaque_separation: [this.currentKit.plaque_separation, Validators.required],
      signalisation_reglementaire: [this.currentKit.signalisation_reglementaire, Validators.required],
      coffret_reglementaire: [this.currentKit.coffret_reglementaire, Validators.required],
      inter_diff: [this.currentKit.inter_diff, Validators.required],
      signalisation_cablage: [this.currentKit.signalisation_cablage, Validators.required],
      disjoncteur_20a: [this.currentKit.disjoncteur_20a, Validators.required],
      coupure_tableau: [this.currentKit.coupure_tableau, Validators.required],
      coupure_ac_180: [this.currentKit.coupure_ac_180, Validators.required],
      monitoring_internet: [this.currentKit.monitoring_internet, Validators.required],
      signalisation: [this.currentKit.signalisation, Validators.required],
      compte_client: [this.currentKit.compte_client, Validators.required],
      site: [this.currentKit.site, Validators.required],
      nettoyage: [this.currentKit.nettoyage, Validators.required],
      connexion: [this.currentKit.connexion, Validators.required],
    });


    setTimeout(() => {
      this.getLastChantier()
    }, 2000);
  }

  constructor(
    private _formBuilder: FormBuilder,
    private sCases: CasesService,
    private dialog:MatDialog,
    private sPdfs: PdfsService,
    private sPieces: PiecesService,
    private sChantiers: ChantierService
  ) {
    if (window.screen.width <= 1100) {
      this.mobile = true;
    }
   }

  updateData(data) {
    this.onData.emit(data)
    console.log(data)
  }

  getLastChantier(){
    this.sChantiers.getLastChantier(this.case._id).subscribe(res => {
      this.lastChantier = res[0];
      console.log(this.lastChantier)
      this.checkPieceOfCible()
    })
  }
  verifyForm() {
    if(this.currentKit.pose === 'Sur imposition') {
      this.initSurImpositionForm()
      this.surimpo = true
      this.sol = false
      this.facade = false
      this.integration = false
    }
    if(this.currentKit.pose === 'Intégration') {
      this.initIntegrationsitionForm()
      this.integration = true
      this.surimpo = false
      this.sol = false
      this.facade = false
    }
    if(this.currentKit.pose === 'Sol') {
      this.initSolForm()
      this.sol = true
      this.facade = false
      this.surimpo = false
      this.integration = false
    }
    if(this.currentKit.pose === 'Façade') {
      this.initFacadeForm()
      this.facade = true
      this.sol = false
      this.surimpo = false
      this.integration = false
    }

  }
  initIntegrationsitionForm(): void {
    this.firstFormGroup = this._formBuilder.group({
      marque_type: [this.currentKit.marque_type, Validators.required],
      pose: [this.currentKit.pose, Validators.required],
      orientation: [this.currentKit.orientation, Validators.required],
      declivite: [this.currentKit.declivite, Validators.required],
      traverse_toiture: [this.currentKit.traverse_toiture, Validators.required],
      etancheite_abergement_g: [this.currentKit.etancheite_abergement_g, Validators.required],
      etancheite_superieure: [this.currentKit.etancheite_superieure, Validators.required],
      etancheite_abergement_d: [this.currentKit.etancheite_abergement_d, Validators.required],
      etancheite_inferieure: [this.currentKit.etancheite_inferieure, Validators.required],
      etancheite_inter: [this.currentKit.etancheite_inter, Validators.required],
      inferieure_champ: [this.currentKit.inferieure_champ, Validators.required],
      ecran_sous_toiture: [this.currentKit.ecran_sous_toiture, Validators.required],
      etancheite_arrosage: [this.currentKit.etancheite_arrosage, Validators.required],
     });
  }

  initSolForm(): void {
    this.firstFormGroup = this._formBuilder.group({
      marque_type: [this.currentKit.marque_type, Validators.required],
      pose: [this.currentKit.pose, Validators.required],
      orientation: [this.currentKit.orientation, Validators.required],
      declivite: [this.currentKit.declivite, Validators.required],
      fixation: [this.currentKit.fixation, Validators.required],
      chassis: [this.currentKit.chassis, Validators.required],
      ancrage: [this.currentKit.ancrage, Validators.required],
     });
  }
  initFacadeForm(): void {
    this.firstFormGroup = this._formBuilder.group({
      marque_type: [this.currentKit.marque_type, Validators.required],
      pose: [this.currentKit.pose, Validators.required],
      orientation: [this.currentKit.orientation, Validators.required],
      declivite: [this.currentKit.declivite, Validators.required],
      brise_soleil: [this.currentKit.brise_soleil, Validators.required],
      chassis: [this.currentKit.chassis, Validators.required],
     });
  }

  initSurImpositionForm(): void {
    this.firstFormGroup = this._formBuilder.group({
      marque_type: [this.currentKit.marque_type, Validators.required],
      pose: [this.currentKit.pose, Validators.required],
      orientation: [this.currentKit.orientation, Validators.required],
      declivite: [this.currentKit.declivite, Validators.required],
      traverse_toiture: [this.currentKit.traverse_toiture, Validators.required],
      patte_fixation: [this.currentKit.patte_fixation, Validators.required],
      etancheite_fixation: [this.currentKit.etancheite_fixation, Validators.required],
     });
  }

  getCurrentKitAndSaveChange(event,key) {

    this.currentKit[key] = event;

    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === this.currentKit.type);

    array[index] = this.currentKit

    this.case.kits_vendus = JSON.stringify(array)
    this.sCases.editCase(this.case, this.case._id).then(res => console.log(res))
  }


  incrementValueOnduleur(step: number = 1,name,reason, option,product): void {

    this.currentKit['nbr_onduleur'] = this.currentKit['nbr_onduleur'] + step
    console.log("this.currentKit['nbr_onduleur']",this.currentKit['nbr_onduleur'])
    console.log("this.currentKit",this.currentKit)

    const newValue = this.currentKit['nbr_onduleur']
    console.log("this.currentKit",this.currentKit)

    this.addOption(name,reason, option,product, newValue)
  }

  addOption(name,reason, option,product, value) {

    let find = JSON.parse(this.case.kits_vendus).find(x => x.name === name);
    console.log("find",find)
    find[option] = value;

    console.log( "find after",find)
    const array = JSON.parse(this.case.kits_vendus);
    const index = array.findIndex(item => item.name === name);
    const selected = array[index]
    console.log("selected", selected)
    this.currentKit = selected
    selected[option] = value

    this.case.kits_vendus = JSON.stringify(array)
    this.sCases.editCase(this.case, this.case._id).then(res => console.log(res))

  }

  openDialog(value): void {
    const dialogRef = this.dialog.open(NoModalComponent, {
      width: '640px',
      disableClose: true,
      data: {
        currentKit: this.currentKit,
        case: this.case,
        value: value
      }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  async valid() {
    this.loader = true
    this.createDoc = await this.generateDoc();
    this.loader = false
    this.openDialogPrevisualize()
  }

  async generateDoc() {

    if(this.currentKit.pose === 'Façade') {
      this.recetteChoice = 'recette_pv_onduleur_facade'
    }
    else if(this.currentKit.pose === 'Sol') {
      this.recetteChoice = 'recette_pv_onduleur_sol'

    }
    else if(this.currentKit.pose === 'Sur imposition') {
      this.recetteChoice = 'recette_pv_onduleur_surimposition'

    }
    else {
      this.recetteChoice = 'recette_pv_onduleur_integration'
    }

    console.log("this.recetteChoice",this.recetteChoice)

    let response = await this.sPdfs.generateRecette(this.chantierId,this.recetteChoice,this.case,this.currentKit,"","").then(res => {
      if(res === 'ok') {
        console.log(res)
      }
    })
    return response;
  }

  openDialogPrevisualize(): void {
    setTimeout(() => {
      const dialogRef = this.dialog.open(ModalPrevisualizeComponent, {
        width: '100vh',
        height:  '100vh',
        maxWidth: '100vh',
        maxHeight: '100vh',
        hasBackdrop: false,
        disableClose: true,
        panelClass: 'pdf-visualize',
        data: {
          titlePiece: this.recetteChoice,
          caseId: this.case._id,
          case: this.case,
          kit: this.currentKit,
          chantierId: this.chantierId
        }
      });
      dialogRef.afterClosed().subscribe(result => {

      });
    }, 1000);

  }

  selectMultiple(event, title) {
    if (event.target.files.length > 0) {
      this.multipleImagesPieces = event.target.files;
      console.log(this.multipleImagesPieces);
    }
    this.onSubmit(title)
  }

  onSubmit(title) {
    const form = {
      title: title,
      qualification: 'Conforme',
      caseId: this.case._id,
      created_at: new Date(),
      type: 'technicien',
      chantier: this.chantierId,
      cible: 'recette_pv_onduleur',
      description: '',
    }
    this.sPieces.addTechPiece(form, this.multipleImagesPieces, this.case._id, this.lastChantier._id)

    setTimeout(() => {
      this.checkPieceOfCible()
    }, 1100);
  }

  checkPieceOfCible() {
    this.sPieces.getPiecesByChantierIdAndCible(this.lastChantier._id, 'recette_pv_onduleur').subscribe(res => {
      console.log(res)
      const pieces:any = res
      if(pieces.find(e => e.title === 'Champ photovoltaïque'))
        this.champ_photo = true
      if(pieces.find(e => e.title === 'Toîture globale'))
        this.toiture = true
      if(pieces.find(e => e.title === 'Coffret AC/DC'))
        this.coffret_acdc = true
      if(pieces.find(e => e.title === 'Pièces onduleurs'))
        this.pieces = true
      if(pieces.find(e => e.title === 'Coffret de sécurité'))
        this.coffret_secu = true
      if(pieces.find(e => e.title === 'Onduleur'))
        this.onduleur = true
    })

  }

}
