import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProductService } from '@app/services/product.service';
import { AddProductComponent } from '@app/add-product/add-product.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-sav-dashboard',
  templateUrl: './sav-dashboard.component.html',
  styleUrls: ['./sav-dashboard.component.scss']
})

export class SavDashboardComponent implements OnInit {

  products: any[]; // Type selon votre modèle de données

  constructor(
    private productService: ProductService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loadProducts();
     this.productService.productsUpdate$.subscribe(products => {
    this.products = products;
  });
  }

  loadProducts() {
    this.productService.getProducts().subscribe(response => {
      this.products = response;
    });
  }

  openAddProductDialog(): void {
    const dialogRef = this.dialog.open(AddProductComponent, {
      width: '640px',
      disableClose: true
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result === 'added') {
        this.loadProducts();
      }
    });
  }
}
