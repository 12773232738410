  <div class="gdd-card">
    <h5 (click)="editTaskGddDialog(task)">{{ task.title }}</h5>
    <a *ngIf="task.caseId && task.customerId" [routerLink]="['/case-template', task.caseId._id]" target="blank" class="affaire">Affaire {{ task.customerId?.name }}</a>
    <a *ngIf="!task.caseId && !task.customerId">aucune affaire liée</a>

    <div class="row mt-4">
      <div class="col-10" (click)="editTaskGddDialog(task)">
      <img *ngIf="loading && currentUser.id === '5ff22a8ba0f2570004f151d0'" class="pp" src="{{getProfil(task.target)}}">
      <img *ngIf="loading && currentUser.id != '5ff22a8ba0f2570004f151d0'" class="pp" src="{{ imageSrc }}">

      <img *ngIf="!loading" class="pp" src='../../../assets/loader1.gif'>

      </div>



        <img class="dots" src="../../../assets/icons/three_dot.svg" (click)="editTaskGddDialog(task)">
<!--         <mat-select [ngClass]="task.state === 'Terminé' ? 'none' : ''" class="menu-dot" name="choice" [(ngModel)]="selectedValue" (selectionChange)="changeState(task, $event.value)" [disabled]="task.state === 'Terminé'">
          <mat-option  *ngFor="let com of task.commentary" [value]="com">
            {{com.title}}
          </mat-option>
        </mat-select> -->

    </div>
    <div class="row mt-5" (click)="editTaskGddDialog(task)">
      <div class="col-2 hours">
        {{ differenceHours | number: '1.0-0' }}
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="clock">
          <path d="M7.01477 0.34375C3.26868 0.34375 0.233521 3.37891 0.233521 7.125C0.233521 10.8711 3.26868 13.9062 7.01477 13.9062C10.7609 13.9062 13.796 10.8711 13.796 7.125C13.796 3.37891 10.7609 0.34375 7.01477 0.34375ZM12.921 7.125C12.921 10.3789 10.2687 13.0312 7.01477 13.0312C3.76086 13.0312 1.10852 10.4062 1.10852 7.125C1.10852 3.89844 3.73352 1.21875 7.01477 1.21875C10.2413 1.21875 12.921 3.87109 12.921 7.125ZM8.8468 9.55859C8.98352 9.66797 9.20227 9.61328 9.2843 9.47656L9.5304 9.17578C9.63977 9.01172 9.58508 8.82031 9.44836 8.71094L7.5343 7.31641V3.29688C7.5343 3.13281 7.37024 2.96875 7.20618 2.96875H6.82336C6.63196 2.96875 6.49524 3.13281 6.49524 3.29688V7.67188C6.49524 7.78125 6.52258 7.86328 6.60461 7.94531L8.8468 9.55859Z" fill="#A5A5A5"/>
        </svg>

      </div>
      <div class="col-9 pl-0">
        <div class="progress">
          <div class="bar"
          [ngStyle]="{width: progressValue}">
          </div>
        </div>
      </div>
    </div>



  </div>
