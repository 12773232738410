import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CasesService } from '@app/services/cases.service';
import { CustomersService } from '@app/services/customers.service';
import { RolesService } from '@app/services/roles.service';
import { UsersService } from '@app/services/users.service';
import { AuthenticationService } from '@app/_services';

@Component({
  selector: 'case-create',
  templateUrl: './case-create.component.html',
  styleUrls: ['./case-create.component.scss'],
})
export class CaseCreateComponent implements OnInit {
  loading;
  cases = [];
  caseForm: FormGroup;

  currentUser;
  currentCustomer;
  currentRole;
  commerciaux = [];
  advs = [];
  tmks = [];
  users = [];
  customers = [];
  type = 'Vente';

  types = ['Vente', 'Contrat de maintenance'];

  kit = [
    { name: "Adoucisseur d'eau", type: "Adoucisseur d'eau", active: false },
    {
      name: 'Ballon thermodynamique',
      type: 'Ballon thermodynamique',
      coude_entree_air: null,
      coude_sortie_air: null,
      active: false,
      options_bt: [],
    },
    { name: 'Batterie', type: 'Batterie', marque: 'Huawei', active: false },
    {
      name: 'Mise aux normes du tableau électrique',
      type: 'Mise aux normes du tableau électrique',
      active: false,
    },
    {
      name: 'PAC Air/Air',
      type: 'PAC Air/Air',
      active: false,
      options_pac: [],
    },
    {
      name: 'PAC Air/Eau',
      type: 'PAC Air/Eau',
      active: false,
      options_pac: [],
    },
    {
      name: 'Panneaux solaires',
      type: 'Panneaux solaires',
      modules: [],
      onduleurs: [],
      nbr_relais: null,
      active: false,
    },
    { name: 'Thermostat radio', type: 'Thermostat radio', active: false },
    {
      name: 'VMC Double Flux',
      type: 'VMC Double Flux',
      obturateurs: [],
      obturateur: null,
      active: false,
    },
    { name: 'Isolation', type: 'Isolation', active: false },
  ];

  constructor(
    private sCases: CasesService,
    public sUsers: UsersService,
    private sCustomers: CustomersService,
    private fb: FormBuilder,
    public sRoles: RolesService,
    private authenticationService: AuthenticationService,
    private dialogRef: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.currentCustomer = data.currentCustomer;
    this.authenticationService.user.subscribe((x) => (this.currentUser = x));
    this.currentRole = this.currentUser.role;
    this.caseForm = this.fb.group({
      type: [this.type],
      cm: [
        {
          formule: null,
          tarif: null,
          comment: null,
          produits: [],
          paiement: {
            type_paiement: null,
            cheque1: null,
            mensualite: null,
            montant: null,
            cheque_depose: false,
            months: [
              { name: 'Janvier', state: 'en attente', montant: 0, comment: '' },
              { name: 'Février', state: 'en attente', montant: 0, comment: '' },
              { name: 'Mars', state: 'en attente', montant: 0, comment: '' },
              { name: 'Avril', state: 'en attente', montant: 0, comment: '' },
              { name: 'Mai', state: 'en attente', montant: 0, comment: '' },
              { name: 'Juin', state: 'en attente', montant: 0, comment: '' },
              { name: 'Juillet', state: 'en attente', montant: 0, comment: '' },
              { name: 'Août', state: 'en attente', montant: 0, comment: '' },
              {
                name: 'Septembre',
                state: 'en attente',
                montant: 0,
                comment: '',
              },
              { name: 'Octobre', state: 'en attente', montant: 0, comment: '' },
              {
                name: 'Novembre',
                state: 'en attente',
                montant: 0,
                comment: '',
              },
              {
                name: 'Décembre',
                state: 'en attente',
                montant: 0,
                comment: '',
              },
            ],
          },
          years: [],
          datesign: null,
        },
      ],
      title: [null, Validators.required],
      customer: [this.data.currentCustomer._id],
      commercial: [null],
      adv: [undefined],
      tmk: [null],
      created_by: [this.currentUser.id],
      state: ['Prospection'],
      description: [null],
      r1: this.fb.group({
        debrief: [''],
        date: [''],
      }),
      kits_vendus: [
        JSON.stringify(this.kit),
        // TODO:
        // - Remplacer stringfy par l'array (this.kit)
        // - renommer kit => kits
        // - traquer là où kits_vendus a été utilisé et enlever l'eventuel JSON.parse
        // - faire un back-up de la BDD
        // - créer une route qui va agir sur toutes les anciennes valeurs de kits_valeurs pour les transformer en objet
      ],
      financement_type: [null],
      financement_comptant: [
        {
          choice: '',
          cheques: 'false',
          cheque1: {
            date: '',
            encaissement: false,
            date_validation: '',
            validation: false,
          },
          cheque2: {
            date: '',
            encaissement: false,
            date_validation: '',
            validation: false,
          },
          cheque3: {
            date: '',
            encaissement: false,
            date_validation: '',
            validation: false,
          },
        },
      ],
      price_ht: [null],
      price_ttc: [null],
      rdv_state: [
        {
          status: '',
          why: '',
        },
      ],
      mairie: '',
      letter: this.fb.group({
        status: [''],
        date: [''],
      }),
      dp: '',
      date_depot: '',
      tarif: this.fb.group({
        status: [''],
        why: [''],
        accepted: [''],
      }),
    });
  }

  ngOnInit(): void {
    this.showUsers();
    this.showCustomers();
  }

  showUsers() {
    this.sUsers.getAllUsers().subscribe((res) => {
      this.users = res['users'];
      this.users.forEach((element) => {
        if (
          element.role === 'Commercial' ||
          element.role === "Responsable d'agence"
        ) {
          this.commerciaux.push(element);
        } else if (
          element.role === 'Adv' ||
          element.role === 'Responsable ADV'
        ) {
          this.advs.push(element);
        } else if (
          element.role === 'Tmk' ||
          element.role === 'Superviseur TMK'
        ) {
          this.tmks.push(element);
        }
      });
    });
  }

  showCustomers() {
    this.sCustomers.getAllCustomers(9999).subscribe((res) => {
      this.customers = res['customers'];
    });
  }

  addCase() {
    if (
      this.currentRole == 'Commercial' ||
      this.currentRole == "Responsable d'agence"
    ) {
      this.caseForm.value.commercial = this.currentUser.id;
      this.caseForm.value.adv = null;
      this.caseForm.value.tmk = null;
    }

    this.sCases.createCase(this.caseForm.value).then((res) => {
      if (res['error']) {
        alert(res.error);
      } else {
        this.closeModale();
      }
    });
  }

  closeModale() {
    this.dialogRef.closeAll();
  }
}
