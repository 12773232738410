import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CaseProductsComponent } from '@app/case/case-products/case-products.component';
import { RdvAddComponent } from '@app/rdv/rdv-add/rdv-add.component';
import { CasesService } from '@app/services/cases.service';
import { HistoriqueService } from '@app/services/historique.service';
import { InfosService } from '@app/services/infos.service';
import { RolesService } from '@app/services/roles.service';
import { TasksService } from '@app/services/tasks.service';
import { CreateTaskComponent } from '@app/tasks/create-task/create-task.component';
import { TaskCaseListComponent } from '@app/tasks/task-case-list/task-case-list.component';
import { Output, EventEmitter } from '@angular/core';
import { CustomerModalComponent } from '@app/dialogs/customer-modal/customer-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsersService } from '@app/services/users.service';

@Component({
  selector: 'app-admin-customer-case-detail',
  templateUrl: './admin-customer-case-detail.component.html',
  styleUrls: ['./admin-customer-case-detail.component.scss'],
})
export class AdminCustomerCaseDetailComponent implements OnInit {
  @Output() newItemEvent = new EventEmitter<string>();

  addNewItem(value: string) {
    this.newItemEvent.emit(value);
  }
  caseId: string;
  currentCustomer = {
    name: '',
  };
  case;
  caseForm: FormGroup;

  rdvForm: FormGroup;
  arrayRdvs = [];
  state;
  curTab: any = 0;
  lettres = ['pli simple', 'par mail', 'LR/AR'];
  task;
  v1;
  v2;

  products = [];
  selectedProducts;
  autres = [];
  today = new Date();

  ngOnInit() {}

  constructor(
    private route: ActivatedRoute,
    private sCases: CasesService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private sHistoriques: HistoriqueService,
    public sRoles: RolesService,
    private sTasks: TasksService,
    private sInfos: InfosService,
    private _snackBar: MatSnackBar,
    private sUsers: UsersService
  ) {
    this.caseId = this.route.snapshot.params['id'];
    this.showCaseById().then((res) => {
      this.arrayRdvs = this.case.rdvs;
      this.state = this.case.state;

      if (this.case.kits_vendus != undefined) {
        if (this.case.kits_vendus.startsWith('[')) this.v2 = true;
        else {
          this.v1 = true;
          this.products = this.case.kits_vendus;
        }

        if (
          this.case.kits_vendus != null &&
          this.case.kits_vendus.startsWith('[')
        ) {
          JSON.parse(this.case.kits_vendus).forEach((element) => {
            if (element.active) {
              this.products.push(element.name);
            }
          });
          const array1 = this.products;
          const parseKits = JSON.parse(this.case.kits_vendus);

          parseKits.forEach((element) => {
            // si un produit du kit est dans le tableau
            if (array1.includes(element.name)) {
              // on vient éditer l'object associé dans products en active true
              let result = parseKits.filter((e) => e.name === element.name)[0];

              if (!result.active) {
                parseKits.filter((e) => e.name === element.name)[0].active =
                  true;
              }
            } else {
              parseKits.filter((e) => e.name === element.name)[0].active =
                false;
            }
          });
          this.selectedProducts = JSON.stringify(parseKits);
          this.selectedProducts = JSON.parse(this.selectedProducts).filter(
            (res) => res.active
          );
          this.addNewItem(this.selectedProducts);

          this.products.forEach((element) => {
            if (
              element != "Adoucisseur d'eau" &&
              element != "Ballon thermodynamique" &&
              element != 'Batterie' &&
              element != 'Mise aux normes du tableau électrique' &&
              element != 'PAC Air/Air' &&
              element != 'PAC Air/Eau' &&
              element != 'Panneaux solaires' &&
              element != 'Thermostat radio' &&
              element != 'Isolation' &&
              element != 'VMC Double Flux'
            ) {
              this.autres.push(element);
            }
          });
        } else {
          this.products = this.case.kits_vendus;
        }
      } else {
        this.v1 = true;
      }

      this.caseForm = this.fb.group({
        r1: this.fb.group({
          debrief: [this.case.r1[0].debrief],
          date: [this.case.r1[0].date],
        }),
        kits_vendus: [this.products],
        financement_type: [null],
        price_ht: [null],
        price_ttc: [null],
        description: [null],
        tarif: this.fb.group({
          status: [null],
          why: [null],
          accepted: [null],
        }),
        letter: this.fb.group({
          status: [this.case.letter.status],
          date: [this.case.letter.date],
        }),
      });
      this.rdvForm = this.fb.group({
        rdvs: this.fb.array([]),
      });
    });
  }

  currTab(event) {
    this.curTab = event.index;
  }

  rdvs(): FormArray {
    return this.rdvForm.get('rdvs') as FormArray;
  }

  combineArrays() {
    const arrayCombine = this.arrayRdvs.concat(this.rdvForm.value.rdvs);
    this.saveRdvs(arrayCombine);
  }

  showCaseById() {
    return new Promise((resolve) => {
      this.sCases.getCaseById(this.caseId).subscribe(
        (res) => {
          this.case = res[0];
          this.arrayRdvs = this.case.rdvs;
          resolve(res);
          this.showTasksByMessage();
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }

  saveChangeTarif(reason, change, value) {
    if (
      confirm(
        'Continuer ? La non conformité du tarif déclenchera une demande de vérification'
      )
    ) {
      this.sHistoriques
        .createHistorique(
          this.caseId,
          this.case.customer._id,
          reason,
          change,
          value,
          this.sRoles.user.id
        )
        .then((res) => {
          if (res['error']) {
            alert(res);
          }
        });
      const productsName = [];
      if (this.selectedProducts && this.selectedProducts.length != 0) {
        this.selectedProducts.forEach((element) => {
          if (element.active) {
            productsName.push(element.name);
          }
        });
        this.saveChangeKit(productsName, reason, change, value);
      } else {
        this.sCases.editCase(this.caseForm.value, this.caseId).then((res) => {
          if (res['error']) {
            return res.error;
          } else {
            this.showCaseById();
          }
        });
      }

      if (this.case.tarif.status === 'non') {
        const task = {
          message: "Valider le tarif de l'affaire : " + this.case.customer.name,
          commentary: '',
          state: '1-Urgente',
          date: new Date(),
          created_at: new Date(),
          caseId: this.case._id,
          customerId: this.case.customer._id,
          created_by: '5f76e5503926a01790428179',
          target: '5f77416ab0286e4dbc4a9d6f',
          tag: null,
        };
        this.sTasks.CreateTask(task).then((res) => {
          if (res['error']) {
            alert(res.error);
          }
        });
      }
    } else {
      this.showCaseById();
    }
  }

  saveChange(reason, change, value) {
    this.sHistoriques
      .createHistorique(
        this.caseId,
        this.case.customer._id,
        reason,
        change,
        value,
        this.sRoles.user.id
      )
      .then((res) => {
        if (res['error']) {
          alert(res);
        }
      });
    const productsName = [];
    if (this.selectedProducts && this.selectedProducts.length != 0) {
      this.selectedProducts.forEach((element) => {
        if (element.active) {
          productsName.push(element.name);
        }
      });
      this.saveChangeKit(productsName, reason, change, value);
    } else {
      if (this.v2) {
        this.caseForm.value.kits_vendus = undefined;
      }

      this.sCases.editCase(this.caseForm.value, this.caseId).then((res) => {
        if (res['error']) {
          return res.error;
        } else {
          this.showCaseById();
        }
      });
    }
  }

  saveChangeKit(event, reason, change, value) {
    const array1 = event;

    const parseKits = JSON.parse(this.case.kits_vendus);

    parseKits.forEach((element) => {
      // si un produit du kit est dans le tableau selectionner
      if (array1.includes(element.name)) {
        // on vient éditer l'object associé dans products en active true
        let result = parseKits.filter((e) => e.name === element.name)[0];

        if (!result.active) {
          parseKits.filter((e) => e.name === element.name)[0].active = true;
        }
      } else {
        parseKits.filter((e) => e.name === element.name)[0].active = false;
      }
    });
    this.caseForm.value.kits_vendus = JSON.stringify(parseKits);

    this.selectedProducts = JSON.stringify(parseKits);
    this.selectedProducts = JSON.parse(this.selectedProducts).filter(
      (res) => res.active
    );
    this.addNewItem(this.selectedProducts);

    this.products.forEach((element) => {
      if (
        element != "Adoucisseur d`'eau" &&
        element != 'Ballon thermodynamique' &&
        element != 'Batterie' &&
        element != 'Mise aux normes du tableau électrique' &&
        element != 'PAC Air/Air' &&
        element != 'PAC Air/Eau' &&
        element != 'Panneaux solaires' &&
        element != 'Thermostat radio' &&
        element != 'Isolation' &&
        element != 'VMC Double Flux'
      ) {
        this.autres.push(element);
      }
    });

    this.sHistoriques
      .createHistorique(
        this.caseId,
        this.case.customer._id,
        reason,
        change,
        value,
        this.sRoles.user.id
      )
      .then((res) => {
        if (res['error']) {
          alert(res);
        }
      });
    this.sCases.editCase(this.caseForm.value, this.caseId).then((res) => {
      if (res['error']) {
        return res.error;
      } else {
        this.showCaseById();
      }
    });
  }

  showTasksByMessage() {
    const message =
      "Valider le tarif de l'affaire : " + this.case.customer.name.trim();

    this.sTasks.getTaskByMessage(message).subscribe((res) => {
      this.task = res[0];
    });
  }

  editStateTask() {
    this.task.state = '3-Faite';
    this.sTasks.editTask(this.task, this.task._id).then((res) => {
      if (res['error']) {
        alert(res.error);
      }
    });
  }

  saveChangeAccepted(reason, change, value) {
    this.editStateTask();

    this.sHistoriques
      .createHistorique(
        this.caseId,
        this.case.customer._id,
        reason,
        change,
        value,
        this.sRoles.user.id
      )
      .then((res) => {
        if (res['error']) {
          alert(res);
        }
      });
    const productsName = [];
    if (this.selectedProducts && this.selectedProducts.length != 0) {
      this.selectedProducts.forEach((element) => {
        if (element.active) {
          productsName.push(element.name);
        }
      });
      this.saveChangeKit(productsName, reason, change, value);
    } else {
      this.sCases.editCase(this.caseForm.value, this.caseId).then((res) => {
        if (res['error']) {
          return res.error;
        } else {
          this.showCaseById();
        }
      });
    }

    let message = '';
    if (this.case.tarif.accepted === 'oui') {
      message = 'Validation du tarif';
    } else {
      message = 'Refus du tarif';
    }

    let info = {
      comment: message,
      reason: 'valid_tarif',
      created_by: this.sRoles.user.id,
      created_at: new Date(),
      caseId: this.caseId,
      customerId: this.case.customer._id,
      adv: '6135c7036457510004c2eef1',
    };
    this.sInfos.createInfo(info).then((res) => {
      if (res['error']) {
        alert(res.error);
      }
    });
  }

  saveRdvs(arrayRdvs) {
    this.sCases.addRdv(arrayRdvs, this.caseId).then((res) => {
      if (res['error']) {
        return res.error;
      }
    });

    this.showCaseById();
  }

  deleteOneRdv(arrayRdvs) {
    this.sCases.deleteRdv(arrayRdvs, this.caseId).then((res) => {
      if (res['error']) {
        return res.error;
      }
    });
    this.showCaseById();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(RdvAddComponent, {
      width: '640px',
      disableClose: true,
      data: {
        arrayRdvs: this.arrayRdvs,
        caseId: this.caseId,
        state: this.state,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.showCaseById();
    });
  }

  openKitVendu(): void {
    const dialogRef = this.dialog.open(CaseProductsComponent, {
      width: '640px',
      disableClose: true,
      data: {
        currentCase: this.case,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.selectedProducts = this.case.kits_vendus;
      this.selectedProducts = JSON.parse(this.selectedProducts).filter(
        (res) => res.active
      );
    });
  }

  editRdvs(reason, change, value) {
    this.sHistoriques
      .createHistorique(
        this.caseId,
        this.case.customer._id,
        reason,
        change,
        value,
        this.sRoles.user.id
      )
      .then((res) => {
        if (res['error']) {
          alert(res);
        }
      });
    this.sCases.editCase(this.case, this.caseId).then((res) => {
      if (res['error']) {
        return res.error;
      }
      this.showCaseById();
    });
  }

  checkIdentifiantCustomer() {
    this.sUsers.getUserByCustomerId(this.case.customer._id).subscribe((res) => {
      if (JSON.stringify(res) === '[]') {
        const checkModal = this.dialog.open(CustomerModalComponent, {
          width: '30%',
          data: {
            currentCase: this.case,
          },
        });
        checkModal.afterClosed().subscribe((result) => {
          if (result != undefined) {
            this._snackBar.open(
              `Identifiant: ${result.login}  mot de passe: ${result.password} `,
              '',
              {
                duration: 55000,
                verticalPosition: 'top',
                panelClass: ['info2-snackbar'],
              }
            );
          }
        });
      }
    });
  }

  createTaskDialog(): void {
    const dialogRef = this.dialog.open(CreateTaskComponent, {
      width: '40%',
      height: '80%',
      data: {
        caseId: this.caseId,
        customerId: this.case.customer._id,
        inCase: true,
        edit: false,
      },
    });
  }

  showTaskDialog(): void {
    const dialogRef = this.dialog.open(TaskCaseListComponent, {
      width: '60%',
      height: '80%',
      data: {
        currentCase: this.case,
      },
    });
  }
}
