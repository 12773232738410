import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrimesService } from '@app/services/primes.service';
import { RolesService } from '@app/services/roles.service';

@Component({
  selector: 'app-compta-primes-table',
  templateUrl: './compta-primes-table.component.html',
  styleUrls: ['./compta-primes-table.component.scss']
})
export class ComptaPrimesTableComponent implements OnInit {

  rowHeight
  rowStyle
  columnDefs
  defaultColDef
  gridApi
  gridColumnApi
  primes = []
  rest
  sum
  constructor(
    private sPrimes: PrimesService,
    private router: Router,
    private sRoles: RolesService,
  ) {
    this.getAllPrimes()

    this.rowHeight = 65;
    this.rowStyle = { background: 'white' };

    this.columnDefs = [
      {
        headerName: 'N° de dossier',
        field: "n_dossier",
        cellClass: 'cell-align',
        filter: 'agTextColumnFilter',
        cellStyle: data => {
          if(data.data.type === 'cee') {
            return {backgroundColor: '#f5fbfd'};
          }
        },
      },
      {
        headerName: 'Nom',
        field: "caseId.customer.name",
        cellClass: 'cell-align',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Statut',
        field: "state",
        cellClass: 'cell-align',
        filter: 'agTextColumnFilter',
        cellStyle: cellStyle,
      },
      {
        headerName: 'Date de saisie',
        field: "date_saisie",
        cellClass: 'cell-align',
        filter: 'agDateColumnFilter',
        cellRenderer: (data) => {
            return data.value ? (new Date(data.value)).toLocaleDateString() : '-';
        },
      },
      {
        headerName: 'Produit',
        field: "product",
        cellClass: 'cell-align',
        filter: 'agTextColumnFilter',
        cellRenderer: (data) => {
           if(JSON.stringify(data.value).substring(0,1) === '[' && data.value.length != 0) {
            let prods = []
            data.value.forEach(element => {
              prods.push(element.name)
            });
            return prods.toString()
          }
          else if(JSON.stringify(data.value).substring(0,1) === '[' && data.value.length === 0) {
            return 'ancien kit'
          }
          else {
            return data.value.name
          }
      },
      },
      {
        headerName: 'Prime',
        field: "montant",
        cellClass: 'cell-align',
        filter: 'agNumberColumnFilter',
        cellRenderer: (data) => {
          return data.value ? data.value + ' €' : '-';
        },
      },
      {
        headerName: 'Date d\'envoi',
        field: "date_envoi",
        cellClass: 'cell-align',
        filter: 'agDateColumnFilter',
        cellRenderer: (data) => {
          return data.value ? (new Date(data.value)).toLocaleDateString() : '-';
        },
      },
      {
        headerName: 'Appel à facturation',
        field: "n_appel",
        cellClass: 'cell-align',
        filter: 'agTextColumnFilter',
        cellStyle: data => {
          if(data.data.type === 'mpr') {
            return {backgroundColor: '#f5fbfd'};
          }
        },
        cellRenderer: (data) => {
          if(data.data.type === 'cee') {
            return data.value ? data.value : '-';
          }
          else {
            return ''
          }
        },
      },
      {
        headerName: 'Facture',
        field: "facture_done",
        cellClass: 'cell-align',
        cellStyle: data => {
          if(data.value) {
            return {color: '#78C091'}
          }
        },
        cellRenderer: (data) => {

            return data.value ? 'Faite' : '-';


        },
      },
      {
        headerName: 'Virement',
        field: "date_virement",
        cellClass: 'cell-align',
        filter: 'agDateColumnFilter',
        cellRenderer: (data) => {
          return data.value ? (new Date(data.value)).toLocaleDateString() : '-';
        },
      },
      {
        headerName: 'Type',
        field: "type",
        cellClass: 'cell-align',
        filter: 'agTextColumnFilter',
        cellRenderer: (data) => {
          return data.value ? data.value.toUpperCase() : '-';
        },
      },

    ];

    this.defaultColDef = {
      flex: 1,
      minWidth: 0,
      sortable: true,
      floatingFilter: true,
      resizable: true,
    };

    function cellStyle(params) {
      var color = numberToColor(params.value);
      return { color: color };
    }
    function factureStyle(params) {

      let color;
      params.value ? color = '#78C091' : ''
      return { color: color };
    }
    function numberToColor(val) {
      if (val === 'Refus') {
        return '#F45B69';
      }
      else if(val === 'En cours' || val === 'Complément') {
        return '#F0C808';
      }
      else {
        return '#78C091';
      }
    }
   }

  ngOnInit(): void { }

  getAllPrimes() {
    this.sPrimes.getAllPrimes().subscribe(res => {
      this.primes = res["primes"]
      this.rest = this.primes.filter(res => res.state != 'Acceptation' && res.state != 'Payé' && res.state != 'Refus')
      this.sum = Object.values(this.rest).reduce((t, {montant}) => t + montant, 0)
    })
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onSelectionChanged() {
     var selectedRows = this.gridApi.getSelectedRows();

    if(this.sRoles.user.role != 'Compta') {
      let url = this.router.serializeUrl(
        this.router.createUrlTree(['/case-template/', selectedRows[0].caseId._id], { state: { value: 'primeList' } })
        );
        window.open(url, '_blank');
    }
    else {
      let url = this.router.serializeUrl(
        this.router.createUrlTree(['/compta-primes-detail/', selectedRows[0]._id])
      );
      window.open(url, '_blank');
    }

  }

}
