import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrl} from '@environments/env';


@Injectable({
  providedIn: 'root'
})
export class AvoirService {

  url = apiUrl.apiUrl + '/avoir/';
  //url = 'http://localhost:4000/avoir/'
  headers = { 'Content-Type': 'application/json' };


  constructor(
    private http: HttpClient,

  ) { }

  createAvoir(avoirForm): Promise<any> {
    return this.http
      .post(this.url + 'add', JSON.stringify(avoirForm), { headers: this.headers })
      .toPromise()
      .then(res => res as any);
  }

  getAvoirByCaseId(id) {
    return this.http.get(this.url + 'case/' + id);
  }

  editAvoir(avoirForm, id): Promise<any> {
    return this.http
      .put(this.url + id, JSON.stringify(avoirForm), { headers: this.headers })
      .toPromise()
      .then(res => res as any);
  }

  delete(id) {
    return this.http.delete(this.url + id);
  }
}
