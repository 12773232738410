<div class="row">
  <div (click)="choice('recette')" class="col-lg-5 col-sm-10 choice top">
      <img [src]="recettePath" class="recette_pac">
  </div>
  <div (click)="choice('livraison')" class="col-lg-5 col-sm-10 choice">
    <img src="../../../assets/icons/livraison.svg" class="recette_pac">
  </div>
  <div (click)="choice('reception')" class="col-lg-5 col-sm-10 choice bottom">
    <img src="../../../assets/icons/reception.svg" class="recette_pac">
  </div>

  <div (click)="choice('bon_inter')" class="col-lg-5 col-sm-10 choice bottom">
    <img src="../../../assets/icons/bi.svg" class="recette_pac">
  </div>
</div>
