<div class="book-container">
  <img class="logo_couleur" src="./../../../assets/book/logo_normal.jpg" alt="logo" />
  <h1>Testons vos connaissances</h1>
  <div class="sub-container">
    <p [innerHTML]="question"></p>

  </div>

  <div class="row">
    <app-card-reponse (click)="selectCol(reponse)" *ngFor="let reponse of reponses; index as index" [reponse]="reponse"
      [selectedCol]="selectedCol" [index]="index"></app-card-reponse>
  </div>

  <div class="sub-container">
    <button *ngIf="selectedCol" class="primary-button" (click)="suivant()">
      Valider votre réponse
    </button>
  </div>
</div>
<div class="bandeau"></div>
<img [src]="img" class="image-right"
  [ngClass]="{'part6': partieSuivante === 'part7', 'part11': partieSuivante === 'part12'}" />