import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-compta-template',
  templateUrl: './compta-template.component.html',
  styleUrls: ['./compta-template.component.scss']
})
export class ComptaTemplateComponent implements OnInit {

  showCm = false;
  toggleEncours = false;

  constructor() { }

  ngOnInit(): void {
  }

  toggleCm() {
    this.showCm = !this.showCm;
  }
  goToEncours() {
    this.showCm = false
    this.toggleEncours = !this.toggleEncours;

  }
}
