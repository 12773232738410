<div class="sidebar-right" id="chat" *ngIf="!showPP"><img class="loader" src="../../../assets/loader_final.gif"></div>
<div class="sidebar-right" id="chat" *ngIf="showPP">

  <ul *ngIf="this.sRoles.userRole === 'Admin' || this.sRoles.userRole === 'Adv' || this.sRoles.userRole === 'Compta'"
    class="nav nav-tabs scroll " id="myTab" role="tablist">
    <li class="nav-item">
      <a class="nav-link active" id="commercial-tab" data-toggle="tab" href="#commercial" role="tab"
        aria-controls="commercial" aria-selected="false">Commercial</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile"
        aria-selected="false">Adv</a>
    </li>
    <!--     <li  class="nav-item">
      <a class="nav-link" id="client-tab" data-toggle="tab" href="#client" role="tab" aria-controls="client" aria-selected="true">Client</a>
    </li> -->

  </ul>


  <div class="tab-content" id="myTabContent">
    <div
      *ngIf="this.sRoles.userRole === 'Admin' || this.sRoles.userRole === 'Adv' || this.sRoles.userRole === 'Commercial' || this.sRoles.userRole === 'Responsable d\'agence' || this.sRoles.userRole === 'Compta'"
      class="tab-pane fade show active" id="commercial" role="tabpanel" aria-labelledby="commercial-tab">
      <div class="chat-content">
        <div *ngFor="let comment of arrayComments; index as indexOfelement;"
          [ngClass]="currentUser.id === comment.created_by ? 'chat-message-send': 'chat-message-receive'">
          <div *ngIf="comment.space === 'commercial'" class="d-flex">

            <div class="chat-message"
              [ngClass]="currentUser.id === comment.created_by ? 'commentSend': 'commentReceive' ">
              <a class="delete" *ngIf="currentUser.id== comment.created_by" (click)="deleteComment(comment)"><span
                  class="material-icons">
                  highlight_off
                </span></a>
              <section class="myDIV">
                <p *ngIf="typeOf(comment.tag) != 'string' && comment.tag != '' && comment.tag.length > 1"
                  style="font-size: 0.7em;">@{{ getUsernameMention(comment.tag[0]) }} <span>+ {{ comment.tag.length - 1
                    }} personne<span *ngIf="comment.tag.length > 2">s</span></span></p>
                <p *ngIf="comment.tag.length === 1" style="font-size: 0.7em;">@{{ getUsernameMention(comment.tag[0]) }}
                </p>

                <p *ngIf="typeOf(comment.tag) === 'string' && comment.tag != ''" style="font-size: 0.7em;">@{{
                  getUsernameMention(comment.tag) }}

                <section class="hide_mention" *ngIf="typeOf(comment.tag) != 'string'">
                  <ul *ngFor="let tag of comment.tag">
                    <li *ngIf="getUsernameMention(tag) != getUsernameMention(comment.tag[0])">{{ getUsernameMention(tag)
                      }}</li>
                  </ul>
                </section>
              </section>


              <p class="comment">{{comment.comment}}</p>
              <div class="footer">
                <p class="created_at" style="font-size: x-small;margin-bottom: 0;">{{ comment.created_at | date: 'short'
                  }}</p>
                <section class="myDIV" id="container_like"
                  *ngIf="comment.like && !comment.like.includes(currentUser.username)" (click)="pushLike(comment.id)">
                  <input type="checkbox" id="like">
                  <label for="like"></label>
                  <svg *ngIf="currentUser.id != comment.created_by" width="25" height="15" viewBox="0 0 80 71"
                    fill="none" xmlns="http://www.w3.org/2000/svg" class="like-input">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M70.6406 10.0281C77.9867 17.6641 77.7516 29.8095 70.1156 37.1556L39.6128 66.5001L9.7083 36.984C2.16704 29.5407 2.08764 17.3933 9.53096 9.85199C16.9743 2.31073 29.1217 2.23134 36.6629 9.67466L39.9561 12.925L43.5131 9.50305C51.1492 2.15699 63.2946 2.39207 70.6406 10.0281Z"
                      stroke="rgba(33, 38, 75, 1)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <svg *ngIf="currentUser.id === comment.created_by" width="25" height="15" viewBox="0 0 80 71"
                    fill="none" xmlns="http://www.w3.org/2000/svg" class="like-input-current">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M70.6406 10.0281C77.9867 17.6641 77.7516 29.8095 70.1156 37.1556L39.6128 66.5001L9.7083 36.984C2.16704 29.5407 2.08764 17.3933 9.53096 9.85199C16.9743 2.31073 29.1217 2.23134 36.6629 9.67466L39.9561 12.925L43.5131 9.50305C51.1492 2.15699 63.2946 2.39207 70.6406 10.0281Z"
                      stroke="rgba(255, 255, 255, 1)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <section class="hide">
                    <ul *ngFor="let user of comment.like">
                      <li>{{ user }}</li>
                    </ul>
                  </section>
                </section>

                <section class="myDIV" id="container_like"
                  *ngIf="comment.like && comment.like.includes(currentUser.username)" (click)="rmLike(comment.id)">
                  <input type="checkbox" id="like">
                  <label for="like"></label>
                  <svg *ngIf="currentUser.id != comment.created_by" width="25" height="15" viewBox="0 0 80 71"
                    xmlns="http://www.w3.org/2000/svg" class="like-input isLiked">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M70.6406 10.0281C77.9867 17.6641 77.7516 29.8095 70.1156 37.1556L39.6128 66.5001L9.7083 36.984C2.16704 29.5407 2.08764 17.3933 9.53096 9.85199C16.9743 2.31073 29.1217 2.23134 36.6629 9.67466L39.9561 12.925L43.5131 9.50305C51.1492 2.15699 63.2946 2.39207 70.6406 10.0281Z"
                      stroke="rgba(33, 38, 75, 1)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <svg *ngIf="currentUser.id === comment.created_by" width="25" height="15" viewBox="0 0 80 71"
                    fill="none" xmlns="http://www.w3.org/2000/svg" class="like-input-current isLikedCurrentUser">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M70.6406 10.0281C77.9867 17.6641 77.7516 29.8095 70.1156 37.1556L39.6128 66.5001L9.7083 36.984C2.16704 29.5407 2.08764 17.3933 9.53096 9.85199C16.9743 2.31073 29.1217 2.23134 36.6629 9.67466L39.9561 12.925L43.5131 9.50305C51.1492 2.15699 63.2946 2.39207 70.6406 10.0281Z"
                      stroke="rgba(255, 255, 255, 1)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <section class="hide">
                    <ul *ngFor="let user of comment.like">
                      <li>{{ user }}</li>
                    </ul>
                  </section>
                </section>

              </div>

            </div>

            <img data-toggle="tooltip" data-placement="top" title="{{ comment.created_byname }}"
              [ngClass]="currentUser.id === comment.created_by ? 'pp_current': 'pp' " *ngIf="showPP"
              src="{{getProfil(comment.created_by)}}">

          </div>
        </div>
      </div>
      <form [formGroup]="commentForm" class="form-field" *ngIf="case">
        <div class="container-input">

          <textarea (keyup)="autoGrowTextZone($event)" class="input-message" formControlName="comment"
            placeholder="Votre message ..."
            (keyup.enter)="combineArrays('commercial','ajouté','un commentaire',this.commentForm.value.comment)"></textarea>

          <mat-form-field style="width:0;">
            <!-- <img src="../../assets/icons/cross.svg"> -->
            <mat-select class="select_mention" #selection formControlName="tag" multiple>
              <mat-option *ngIf="da" [value]="da._id">{{ da.username }}</mat-option>
              <mat-option *ngIf="case.commercial != undefined || null" [value]="case.commercial._id">{{
                case.commercial.username}}</mat-option>
              <mat-option *ngFor="let adv of this.sUsers.advs" [value]="adv._id">
                {{adv.username}}
              </mat-option>
              <mat-option value="60bdd1b05847ab0004161018">Marion Thelohan</mat-option>
              <mat-option value="64f6e3c62fdd710002a62945">Celia Perrière</mat-option>

            </mat-select>
          </mat-form-field>

          <img class="mention" (click)="openMention()" src="../../assets/bar-icons/notification-primary.svg">
        </div>

        <button class="send-message"
          (click)="combineArrays('commercial','ajouté','un commentaire',this.commentForm.value.comment)">Envoyer le
          message <img src="../../assets/icons/send-message.svg"></button>
      </form>
    </div>


    <div *ngIf="this.sRoles.userRole === 'Admin' || this.sRoles.userRole === 'Adv' || this.sRoles.userRole === 'Compta'"
      class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
      <div class="chat-content">
        <div *ngFor="let comment of arrayComments; index as indexOfelement;"
          [ngClass]="currentUser.id === comment.created_by ? 'chat-message-send': 'chat-message-receive'">
          <div *ngIf="comment.space === 'adv'" class="d-flex">

            <div class="chat-message"
              [ngClass]="currentUser.id === comment.created_by ? 'commentSend': 'commentReceive' ">
              <a class="delete" *ngIf="currentUser.id== comment.created_by" (click)="deleteComment(comment)"><span
                  class="material-icons">
                  highlight_off
                </span></a>
              <section class="myDIV">
                <p *ngIf="typeOf(comment.tag) != 'string' && comment.tag != '' && comment.tag.length > 1"
                  style="font-size: 0.7em;">@{{ getUsernameMention(comment.tag[0]) }} <span>+ {{ comment.tag.length - 1
                    }} personne<span *ngIf="comment.tag.length > 2">s</span></span></p>
                <p *ngIf="comment.tag.length === 1" style="font-size: 0.7em;">@{{ getUsernameMention(comment.tag[0]) }}
                </p>

                <p *ngIf="typeOf(comment.tag) === 'string' && comment.tag != ''" style="font-size: 0.7em;">@{{
                  getUsernameMention(comment.tag) }}

                <section class="hide_mention" *ngIf="typeOf(comment.tag) != 'string'">
                  <ul *ngFor="let tag of comment.tag">
                    <li *ngIf="getUsernameMention(tag) != getUsernameMention(comment.tag[0])">{{ getUsernameMention(tag)
                      }}</li>
                  </ul>
                </section>
              </section>


              <p class="comment">{{ comment.comment}} </p>
              <div class="footer">
                <p class="created_at" style="font-size: x-small;margin-bottom: 0;">{{ comment.created_at | date: 'short'
                  }}</p>
                <section class="myDIV" id="container_like"
                  *ngIf="comment.like && !comment.like.includes(currentUser.username)" (click)="pushLike(comment.id)">
                  <input type="checkbox" id="like">
                  <label for="like"></label>
                  <svg *ngIf="currentUser.id != comment.created_by" width="25" height="15" viewBox="0 0 80 71"
                    fill="none" xmlns="http://www.w3.org/2000/svg" class="like-input">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M70.6406 10.0281C77.9867 17.6641 77.7516 29.8095 70.1156 37.1556L39.6128 66.5001L9.7083 36.984C2.16704 29.5407 2.08764 17.3933 9.53096 9.85199C16.9743 2.31073 29.1217 2.23134 36.6629 9.67466L39.9561 12.925L43.5131 9.50305C51.1492 2.15699 63.2946 2.39207 70.6406 10.0281Z"
                      stroke="rgba(33, 38, 75, 1)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <svg *ngIf="currentUser.id === comment.created_by" width="25" height="15" viewBox="0 0 80 71"
                    fill="none" xmlns="http://www.w3.org/2000/svg" class="like-input-current">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M70.6406 10.0281C77.9867 17.6641 77.7516 29.8095 70.1156 37.1556L39.6128 66.5001L9.7083 36.984C2.16704 29.5407 2.08764 17.3933 9.53096 9.85199C16.9743 2.31073 29.1217 2.23134 36.6629 9.67466L39.9561 12.925L43.5131 9.50305C51.1492 2.15699 63.2946 2.39207 70.6406 10.0281Z"
                      stroke="rgba(255, 255, 255, 1)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <section class="hide">
                    <ul *ngFor="let user of comment.like">
                      <li>{{ user }}</li>
                    </ul>
                  </section>
                </section>

                <section class="myDIV" id="container_like"
                  *ngIf="comment.like && comment.like.includes(currentUser.username)" (click)="rmLike(comment.id)">
                  <input type="checkbox" id="like">
                  <label for="like"></label>
                  <svg *ngIf="currentUser.id != comment.created_by" width="25" height="15" viewBox="0 0 80 71"
                    xmlns="http://www.w3.org/2000/svg" class="like-input isLiked">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M70.6406 10.0281C77.9867 17.6641 77.7516 29.8095 70.1156 37.1556L39.6128 66.5001L9.7083 36.984C2.16704 29.5407 2.08764 17.3933 9.53096 9.85199C16.9743 2.31073 29.1217 2.23134 36.6629 9.67466L39.9561 12.925L43.5131 9.50305C51.1492 2.15699 63.2946 2.39207 70.6406 10.0281Z"
                      stroke="rgba(33, 38, 75, 1)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <svg *ngIf="currentUser.id === comment.created_by" width="25" height="15" viewBox="0 0 80 71"
                    fill="none" xmlns="http://www.w3.org/2000/svg" class="like-input-current isLikedCurrentUser">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M70.6406 10.0281C77.9867 17.6641 77.7516 29.8095 70.1156 37.1556L39.6128 66.5001L9.7083 36.984C2.16704 29.5407 2.08764 17.3933 9.53096 9.85199C16.9743 2.31073 29.1217 2.23134 36.6629 9.67466L39.9561 12.925L43.5131 9.50305C51.1492 2.15699 63.2946 2.39207 70.6406 10.0281Z"
                      stroke="rgba(255, 255, 255, 1)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <section class="hide">
                    <ul *ngFor="let user of comment.like">
                      <li>{{ user }}</li>
                    </ul>
                  </section>
                </section>

              </div>

            </div>
            <img data-toggle="tooltip" data-placement="top" title="{{ comment.created_byname }}"
              [ngClass]="currentUser.id === comment.created_by ? 'pp_current': 'pp' " *ngIf="showPP"
              src="{{getProfil(comment.created_by)}}">

          </div>
        </div>
      </div>
      <form [formGroup]="commentForm" class="form-field" *ngIf="case">
        <div class="container-input">

          <textarea (keyup)="autoGrowTextZone($event)" class="input-message" formControlName="comment"
            placeholder="Votre message ..."
            (keyup.enter)="combineArrays('adv','ajouté','un commentaire',this.commentForm.value.comment)"></textarea>

          <mat-form-field style="width:0;">
            <!-- <img src="../../assets/icons/cross.svg"> -->
            <mat-select class="select_mention" #selection formControlName="tag" multiple>
              <mat-option *ngIf="da" [value]="da._id">{{ da.username }}</mat-option>
              <mat-option *ngIf="case.commercial != undefined || null" [value]="case.commercial._id">{{
                case.commercial.username}}</mat-option>
              <mat-option *ngFor="let adv of this.sUsers.advs" [value]="adv._id">
                {{adv.username}}
              </mat-option>
              <mat-option value="60bdd1b05847ab0004161018">Marion Thelohan</mat-option>
              <mat-option value="64f6e3c62fdd710002a62945">Celia Perrière</mat-option>

            </mat-select>
          </mat-form-field>

          <img class="mention" (click)="openMention()" src="../../assets/bar-icons/notification-primary.svg">
        </div>

        <button class="send-message"
          (click)="combineArrays('adv','ajouté','un commentaire',this.commentForm.value.comment)">Envoyer le message
          <img src="../../assets/icons/send-message.svg"></button>
      </form>
    </div>


    <div
      *ngIf="this.sRoles.userRole === 'Admin' || this.sRoles.userRole === 'Adv' || this.sRoles.userRole === 'Technicien' || this.sRoles.userRole === 'Responsable Technicien' "
      [ngClass]="this.sRoles.userRole === 'Technicien' || this.sRoles.userRole === 'Responsable Technicien' ? 'fade show active' : '' "
      class="tab-pane " id="technicien" role="tabpanel" aria-labelledby="technicien-tab">
      <div class="chat-content">
        <div *ngFor="let comment of arrayComments; index as indexOfelement;"
          [ngClass]="currentUser.id === comment.created_by ? 'chat-message-send': 'chat-message-receive'">
          <div *ngIf="comment.space === 'technicien'" class="d-flex">

            <div class="chat-message"
              [ngClass]="currentUser.id === comment.created_by ? 'commentSend': 'commentReceive' ">
              <a class="delete" *ngIf="currentUser.id== comment.created_by" (click)="deleteComment(comment)"><span
                  class="material-icons">
                  highlight_off
                </span></a>
              <section class="myDIV">
                <p *ngIf="typeOf(comment.tag) != 'string' && comment.tag != '' && comment.tag.length > 1"
                  style="font-size: 0.7em;">@{{ getUsernameMention(comment.tag[0]) }} <span>+ {{ comment.tag.length - 1
                    }} personne<span *ngIf="comment.tag.length > 2">s</span></span></p>
                <p *ngIf="comment.tag.length === 1" style="font-size: 0.7em;">@{{ getUsernameMention(comment.tag[0]) }}
                </p>

                <p *ngIf="typeOf(comment.tag) === 'string' && comment.tag != ''" style="font-size: 0.7em;">@{{
                  getUsernameMention(comment.tag) }}

                <section class="hide_mention" *ngIf="typeOf(comment.tag) != 'string'">
                  <ul *ngFor="let tag of comment.tag">
                    <li *ngIf="getUsernameMention(tag) != getUsernameMention(comment.tag[0])">{{ getUsernameMention(tag)
                      }}</li>
                  </ul>
                </section>
              </section>


              <p class="comment">{{ comment.comment}}</p>
              <div class="footer">
                <p class="created_at" style="font-size: x-small;margin-bottom: 0;">{{ comment.created_at | date: 'short'
                  }}</p>
                <section class="myDIV" id="container_like"
                  *ngIf="comment.like && !comment.like.includes(currentUser.username)" (click)="pushLike(comment.id)">
                  <input type="checkbox" id="like">
                  <label for="like"></label>
                  <svg *ngIf="currentUser.id != comment.created_by" width="25" height="15" viewBox="0 0 80 71"
                    fill="none" xmlns="http://www.w3.org/2000/svg" class="like-input">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M70.6406 10.0281C77.9867 17.6641 77.7516 29.8095 70.1156 37.1556L39.6128 66.5001L9.7083 36.984C2.16704 29.5407 2.08764 17.3933 9.53096 9.85199C16.9743 2.31073 29.1217 2.23134 36.6629 9.67466L39.9561 12.925L43.5131 9.50305C51.1492 2.15699 63.2946 2.39207 70.6406 10.0281Z"
                      stroke="rgba(33, 38, 75, 1)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <svg *ngIf="currentUser.id === comment.created_by" width="25" height="15" viewBox="0 0 80 71"
                    fill="none" xmlns="http://www.w3.org/2000/svg" class="like-input-current">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M70.6406 10.0281C77.9867 17.6641 77.7516 29.8095 70.1156 37.1556L39.6128 66.5001L9.7083 36.984C2.16704 29.5407 2.08764 17.3933 9.53096 9.85199C16.9743 2.31073 29.1217 2.23134 36.6629 9.67466L39.9561 12.925L43.5131 9.50305C51.1492 2.15699 63.2946 2.39207 70.6406 10.0281Z"
                      stroke="rgba(255, 255, 255, 1)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <section class="hide">
                    <ul *ngFor="let user of comment.like">
                      <li>{{ user }}</li>
                    </ul>
                  </section>
                </section>

                <section class="myDIV" id="container_like"
                  *ngIf="comment.like && comment.like.includes(currentUser.username)" (click)="rmLike(comment.id)">
                  <input type="checkbox" id="like">
                  <label for="like"></label>
                  <svg *ngIf="currentUser.id != comment.created_by" width="25" height="15" viewBox="0 0 80 71"
                    xmlns="http://www.w3.org/2000/svg" class="like-input isLiked">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M70.6406 10.0281C77.9867 17.6641 77.7516 29.8095 70.1156 37.1556L39.6128 66.5001L9.7083 36.984C2.16704 29.5407 2.08764 17.3933 9.53096 9.85199C16.9743 2.31073 29.1217 2.23134 36.6629 9.67466L39.9561 12.925L43.5131 9.50305C51.1492 2.15699 63.2946 2.39207 70.6406 10.0281Z"
                      stroke="rgba(33, 38, 75, 1)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <svg *ngIf="currentUser.id === comment.created_by" width="25" height="15" viewBox="0 0 80 71"
                    fill="none" xmlns="http://www.w3.org/2000/svg" class="like-input-current isLikedCurrentUser">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M70.6406 10.0281C77.9867 17.6641 77.7516 29.8095 70.1156 37.1556L39.6128 66.5001L9.7083 36.984C2.16704 29.5407 2.08764 17.3933 9.53096 9.85199C16.9743 2.31073 29.1217 2.23134 36.6629 9.67466L39.9561 12.925L43.5131 9.50305C51.1492 2.15699 63.2946 2.39207 70.6406 10.0281Z"
                      stroke="rgba(255, 255, 255, 1)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <section class="hide">
                    <ul *ngFor="let user of comment.like">
                      <li>{{ user }}</li>
                    </ul>
                  </section>
                </section>

              </div>
            </div>
            <img data-toggle="tooltip" data-placement="top" title="{{ comment.created_byname }}"
              [ngClass]="currentUser.id === comment.created_by ? 'pp_current': 'pp' " *ngIf="showPP"
              src="{{getProfil(comment.created_by)}}">

          </div>
        </div>
      </div>
      <form [formGroup]="commentForm" class="form-field" *ngIf="case">
        <div class="container-input">

          <textarea (keyup)="autoGrowTextZone($event)" class="input-message" formControlName="comment"
            placeholder="Votre message ..."
            (keyup.enter)="combineArrays('technicien','ajouté','un commentaire',this.commentForm.value.comment)"></textarea>

          <mat-form-field style="width:0;">
            <!-- <img src="../../assets/icons/cross.svg"> -->
            <mat-select class="select_mention" #selection formControlName="tag" multiple>
              <mat-option *ngIf="da" [value]="da._id">{{ da.username }}</mat-option>
              <mat-option *ngIf="case.commercial != undefined || null" [value]="case.commercial._id">{{
                case.commercial.username}}</mat-option>
              <mat-option *ngFor="let adv of this.sUsers.advs" [value]="adv._id">
                {{adv.username}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <img class="mention" (click)="openMention()" src="../../assets/bar-icons/notification-primary.svg">
        </div>

        <button class="send-message"
          (click)="combineArrays('technicien','ajouté','un commentaire',this.commentForm.value.comment)">Envoyer le
          message <img src="../../assets/icons/send-message.svg"></button>
      </form>
    </div>


    <div *ngIf="this.sRoles.userRole === 'Admin' || this.sRoles.userRole === 'Adv' || this.sRoles.userRole === 'Compta'"
      class="tab-pane fade" id="client" role="tabpanel" aria-labelledby="client-tab">
      <div class="chat-content">
        <div *ngFor="let comment of arrayComments; index as indexOfelement;"
          [ngClass]="currentUser.id === comment.created_by ? 'chat-message-send': 'chat-message-receive'">
          <div *ngIf="comment.space === 'client'" class="d-flex">

            <div class="chat-message"
              [ngClass]="currentUser.id === comment.created_by ? 'commentSend': 'commentReceive' ">
              <a class="delete" *ngIf="currentUser.id== comment.created_by" (click)="deleteComment(comment)"><span
                  class="material-icons">
                  highlight_off
                </span></a>
              <section class="myDIV">
                <p *ngIf="typeOf(comment.tag) != 'string' && comment.tag != '' && comment.tag.length > 1"
                  style="font-size: 0.7em;">@{{ getUsernameMention(comment.tag[0]) }} <span>+ {{ comment.tag.length - 1
                    }} personne<span *ngIf="comment.tag.length > 2">s</span></span></p>
                <p *ngIf="comment.tag.length === 1" style="font-size: 0.7em;">@{{ getUsernameMention(comment.tag[0]) }}
                </p>

                <p *ngIf="typeOf(comment.tag) === 'string' && comment.tag != ''" style="font-size: 0.7em;">@{{
                  getUsernameMention(comment.tag) }}

                <section class="hide_mention" *ngIf="typeOf(comment.tag) != 'string'">
                  <ul *ngFor="let tag of comment.tag">
                    <li *ngIf="getUsernameMention(tag) != getUsernameMention(comment.tag[0])">{{ getUsernameMention(tag)
                      }}</li>
                  </ul>
                </section>
              </section>


              <p class="comment">{{ comment.comment}} </p>
              <div class="footer">
                <p class="created_at" style="font-size: x-small;margin-bottom: 0;">{{ comment.created_at | date: 'short'
                  }}</p>
                <section class="myDIV" id="container_like"
                  *ngIf="comment.like && !comment.like.includes(currentUser.username)" (click)="pushLike(comment.id)">
                  <input type="checkbox" id="like">
                  <label for="like"></label>
                  <svg *ngIf="currentUser.id != comment.created_by" width="25" height="15" viewBox="0 0 80 71"
                    fill="none" xmlns="http://www.w3.org/2000/svg" class="like-input">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M70.6406 10.0281C77.9867 17.6641 77.7516 29.8095 70.1156 37.1556L39.6128 66.5001L9.7083 36.984C2.16704 29.5407 2.08764 17.3933 9.53096 9.85199C16.9743 2.31073 29.1217 2.23134 36.6629 9.67466L39.9561 12.925L43.5131 9.50305C51.1492 2.15699 63.2946 2.39207 70.6406 10.0281Z"
                      stroke="rgba(33, 38, 75, 1)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <svg *ngIf="currentUser.id === comment.created_by" width="25" height="15" viewBox="0 0 80 71"
                    fill="none" xmlns="http://www.w3.org/2000/svg" class="like-input-current">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M70.6406 10.0281C77.9867 17.6641 77.7516 29.8095 70.1156 37.1556L39.6128 66.5001L9.7083 36.984C2.16704 29.5407 2.08764 17.3933 9.53096 9.85199C16.9743 2.31073 29.1217 2.23134 36.6629 9.67466L39.9561 12.925L43.5131 9.50305C51.1492 2.15699 63.2946 2.39207 70.6406 10.0281Z"
                      stroke="rgba(255, 255, 255, 1)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <section class="hide">
                    <ul *ngFor="let user of comment.like">
                      <li>{{ user }}</li>
                    </ul>
                  </section>
                </section>

                <section class="myDIV" id="container_like"
                  *ngIf="comment.like && comment.like.includes(currentUser.username)" (click)="rmLike(comment.id)">
                  <input type="checkbox" id="like">
                  <label for="like"></label>
                  <svg *ngIf="currentUser.id != comment.created_by" width="25" height="15" viewBox="0 0 80 71"
                    xmlns="http://www.w3.org/2000/svg" class="like-input isLiked">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M70.6406 10.0281C77.9867 17.6641 77.7516 29.8095 70.1156 37.1556L39.6128 66.5001L9.7083 36.984C2.16704 29.5407 2.08764 17.3933 9.53096 9.85199C16.9743 2.31073 29.1217 2.23134 36.6629 9.67466L39.9561 12.925L43.5131 9.50305C51.1492 2.15699 63.2946 2.39207 70.6406 10.0281Z"
                      stroke="rgba(33, 38, 75, 1)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <svg *ngIf="currentUser.id === comment.created_by" width="25" height="15" viewBox="0 0 80 71"
                    fill="none" xmlns="http://www.w3.org/2000/svg" class="like-input-current isLikedCurrentUser">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M70.6406 10.0281C77.9867 17.6641 77.7516 29.8095 70.1156 37.1556L39.6128 66.5001L9.7083 36.984C2.16704 29.5407 2.08764 17.3933 9.53096 9.85199C16.9743 2.31073 29.1217 2.23134 36.6629 9.67466L39.9561 12.925L43.5131 9.50305C51.1492 2.15699 63.2946 2.39207 70.6406 10.0281Z"
                      stroke="rgba(255, 255, 255, 1)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <section class="hide">
                    <ul *ngFor="let user of comment.like">
                      <li>{{ user }}</li>
                    </ul>
                  </section>
                </section>

              </div>

            </div>
            <img data-toggle="tooltip" data-placement="top" title="{{ comment.created_byname }}"
              [ngClass]="currentUser.id === comment.created_by ? 'pp_current': 'pp' " *ngIf="showPP"
              src="{{getProfil(comment.created_by)}}">

          </div>
        </div>
      </div>
      <form [formGroup]="commentForm" class="form-field" *ngIf="case">
        <div class="container-input">

          <textarea (keyup)="autoGrowTextZone($event)" class="input-message" formControlName="comment"
            placeholder="Votre message ..."
            (keyup.enter)="combineArrays('client','ajouté','un commentaire',this.commentForm.value.comment)"></textarea>

          <mat-form-field style="width:0;">
            <mat-select class="select_mention" #selection formControlName="tag" multiple>
              <mat-option *ngFor="let adv of this.sUsers.advs" [value]="adv._id">
                {{adv.username}}
              </mat-option>
              <mat-option value="60bdd1b05847ab0004161018">Marion Thelohan</mat-option>
              <mat-option value="64f6e3c62fdd710002a62945">Celia Perrière</mat-option>

            </mat-select>
          </mat-form-field>

          <img class="mention" (click)="openMention()" src="../../assets/bar-icons/notification-primary.svg">
        </div>

        <button class="send-message"
          (click)="combineArrays('client','ajouté','un commentaire',this.commentForm.value.comment)">Envoyer le message
          <img src="../../assets/icons/send-message.svg"></button>
      </form>
    </div>
  </div>

</div>