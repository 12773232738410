<div class="contain-dialog">
  <div class="row">
    <div class="col-12">
      <input
      [(ngModel)]="title"
      id="title"
      type="text"
      class="form-control mb-4 pb-4 pt-4"
      placeholder="Titre" required>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <input
      [(ngModel)]="infos"
      id="title"
      type="text"
      class="form-control mb-4 pb-4 pt-4"
      placeholder="Ex: faire signer le mandat BAF" required>
    </div>
  </div>

  <div class="row">
    <div class="col-5">
      <input class="empty-date" placeholder="JJ/MM/AAAA à xxhxx" [(ngModel)]="date" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2">
      <owl-date-time firstDayOfWeek="1" #dt2></owl-date-time>
    </div>
      <mat-select class="custom-select-state rdv col-6" [(ngModel)]="resp" value="responsable._id" class="col-5" placeholder="Responsable technique">
        <mat-option *ngFor="let responsable of responsables" [value]="responsable._id" >
          {{responsable.username}}
        </mat-option>
      </mat-select>
  </div>

<div class="row">
  <mat-select class="custom-select-state rdv" [(ngModel)]="tech1" value="tech._id" class="col-5" placeholder="Techniciens 1">
    <mat-option *ngFor="let tech of allTechniciens" [value]="tech._id">
      {{tech.username}}
    </mat-option>
  </mat-select>

  <mat-select class="custom-select-state rdv" [(ngModel)]="type" value="type" class="col-5">
    <mat-option value="installation">Installation</mat-option>
    <mat-option value="sav">SAV</mat-option>
    <mat-option value="cm">CM</mat-option>

  </mat-select>
</div>
    <button class="btn-secondarycrm" (click)="validationChantier('ajouté','travaux',this.title)" *ngIf="this.title && this.date && this.tech1.length != 0 && this.resp.length != 0">Créer le chantier</button>
  </div>

