import { ComptaCaseDetailComponent } from './compta/compta-case-detail/compta-case-detail.component';
import { ComptaDashboardComponent } from './compta/compta-dashboard/compta-dashboard.component';
import { AdminUserStatComponent } from './admin/admin-user-stat/admin-user-stat.component';
import { TaskListComponent } from './tasks/task-list/task-list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';

import { LoginComponent } from './login';
import { AuthGuard } from './_helpers';
import { Role } from './_models';
import { CommercialDashboardComponent } from './commercial/commercial-dashboard/commercial-dashboard.component';
import { CommercialCustomerListComponent } from './commercial/commercial-customer-list/commercial-customer-list.component';
import { AdminUserListComponent } from './admin/admin-user-list/admin-user-list.component';
import { AdminCustomerListComponent } from './admin/admin-customer-list/admin-customer-list.component';
import { AdminCaseAffectationComponent } from './admin/admin-case-affectation/admin-case-affectation.component';
import { CommercialCustomerCaseComponent } from './commercial/commercial-customer-case/commercial-customer-case.component';
import { CommercialCaseDetailComponent } from './commercial/commercial-case-detail/commercial-case-detail.component';
import { AddPieceComponent } from './pieces/add/add-piece.component';
import { ListPieceComponent } from './pieces/list/list-piece.component';
import { AdminCustomerCaseDetailComponent } from './admin/admin-customer-case-detail/admin-customer-case-detail.component';
import { TmkCasesComponent } from './tmk/tmk-cases/tmk-cases.component';
import { AdvDashboardComponent } from './adv/adv-dashboard/adv-dashboard.component';
import { CaseTemplateComponent } from './case/case-template/case-template.component';
import { DetailPieceComponent } from './pieces/detail-piece/detail-piece.component';
import { CaseFinancementAddComponent } from './case/case-financement/case-financement-add/case-financement-add.component';
import { CaseFinancementListComponent } from './case/case-financement/case-financement-list/case-financement-list.component';
import { TechnicienDashboardComponent } from './technicien/technicien-dashboard/technicien-dashboard.component';
import { TechnicienCaseDetailComponent } from './technicien/technicien-case-detail/technicien-case-detail.component';
import { ChantierDetailComponent } from './chantier/chantier-detail/chantier-detail.component';
import { CommercialTemplateComponent } from './commercial/commercial-template/commercial-template.component';
import { CommercialCaseListComponent } from './commercial/commercial-case-list/commercial-case-list.component';
import { ProfileComponent } from './profile/profile.component';
import { InfosComponent } from './infos/infos.component';
import { AdminQualificationComponent } from './admin/admin-qualification/admin-qualification.component';
import { CaseHistoriqueComponent } from './case/case-historique/case-historique.component';
import { AdminSuiviAnnuelComponent } from './admin/admin-suivi-annuel/admin-suivi-annuel.component';
import { TechnicienListChantierComponent } from './technicien/technicien-list-chantier/technicien-list-chantier.component';
import { GroupsListComponent } from './groups/groups-list/groups-list.component';
import { CaseTableComponent } from './case/case-table/case-table.component';
import { CaseCmComponent } from './case/case-cm/case-cm.component';
import { CaseCmTemplateComponent } from './case/case-cm-template/case-cm-template.component';
import { ComptaCmTableComponent } from './compta/compta-cm-table/compta-cm-table.component';
import { ComptaCmDetailComponent } from './compta/compta-cm-detail/compta-cm-detail.component';
import { ComptaPrimesTableComponent } from './compta/compta-primes-table/compta-primes-table.component';
import { ComptaPrimesDetailComponent } from './compta/compta-primes-detail/compta-primes-detail.component';
import { ComptaLeadsComponent } from './compta/compta-leads/compta-leads.component';

import { SpectateurDashboardComponent } from './spectateur/spectateur-dashboard/spectateur-dashboard.component';
import { ComptaTemplateComponent } from './compta/compta-template/compta-template.component';
import { CasePrimeListComponent } from './case-prime/case-prime-list/case-prime-list.component';
import { CasePrimeEditComponent } from './case-prime/case-prime-edit/case-prime-edit.component';
import { CasePrimeAddComponent } from './case-prime/case-prime-add/case-prime-add.component';

import { AdminFinancementComponent } from './admin/admin-financement/admin-financement.component';
import { SuiviAffairesComponent } from './commercial/suivi-affaires/suivi-affaires.component';
import { LivraisonVisualizeComponent } from './pv/livraison-visualize/livraison-visualize.component';
import { RecetteTemplateComponent } from './pv/recette-template/recette-template.component';
import { ReceptionVisualizeComponent } from './pv/reception-visualize/reception-visualize.component';
import { SuiviDossiersComponent } from './adv/suivi-dossiers/suivi-dossiers.component';

import { Part19Component } from './book/part19/part19.component';
import { Part18Component } from './book/part18/part18.component';
import { Part17Component } from './book/part17/part17.component';
import { Part16Component } from './book/part16/part16.component';
import { Part15Component } from './book/part15/part15.component';
import { Part14Component } from './book/part14/part14.component';
import { Part13Component } from './book/part13/part13.component';
import { Part12Component } from './book/part12/part12.component';
import { Part11Component } from './book/part11/part11.component';
import { Part10Component } from './book/part10/part10.component';
import { Part9Component } from './book/part9/part9.component';
import { Part8Component } from './book/part8/part8.component';
import { Part7Component } from './book/part7/part7.component';
import { Part6Component } from './book/part6/part6.component';
import { Part5Component } from './book/part5/part5.component';
import { Part4Component } from './book/part4/part4.component';
import { Part3Component } from './book/part3/part3.component';
import { Part2Component } from './book/part2/part2.component';
import { Part1Component } from './book/part1/part1.component';

import { BookComponent } from './book/book.component';
import { BiVisualizeComponent } from './pv/bi-visualize/bi-visualize.component';
import { BiVisualizeCmComponent } from './pv/bi-visualize-cm/bi-visualize-cm.component';

import { SavDashboardComponent } from './sav-dashboard/sav-dashboard.component';
import { Part0Component } from './book/part0/part0.component';
import { Part4bisComponent } from './book/part4bis/part4bis.component';
import { Part5bisComponent } from './book/part5bis/part5bis.component';
import { Part20Component } from './book/part20/part20.component';
import { Part9BisComponent } from './book/part9bis/part9bis.component';
import { Part21Component } from './book/part21/part21.component';
import { TechnicienCaseListPiecesComponent } from './technicien/technicien-case-list-pieces/technicien-case-list-pieces.component';
import { LogistiqueDashboardComponent } from './logistique/logistique-dashboard/logistique-dashboard.component';
import { LogistiqueDetailComponent } from './logistique/logistique-detail/logistique-detail.component';
import { LogistiqueStockComponent } from './logistique/logistique-stock/logistique-stock.component';
import { LogistiqueVisualizeComponent } from './logistique/logistique-visualize/logistique-visualize.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin-dashboard',
    component: AdminDashboardComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.RespCommercial, Role.Adv, Role.RespAdv] },
  },
  {
    path: 'admin-user-list',
    component: AdminUserListComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.Adv] },
  },
  {
    path: 'admin-user-stat/:id',
    component: AdminUserStatComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.RespCommercial] },
  },
  {
    path: 'admin-suivi-annuel',
    component: AdminSuiviAnnuelComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.Adv] },
  },
  {
    path: 'admin-customer-list',
    component: AdminCustomerListComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.Adv, Role.Compta, Role.RespTechnicien] },
  },
  {
    path: 'admin-customer-case-detail/:id',
    component: AdminCustomerCaseDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.Adv] },
  },
  {
    path: 'admin-case-affectation',
    component: AdminCaseAffectationComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, Role.Adv] },
  },
  {
    path: 'admin-qualification',
    component: AdminQualificationComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'admin-financement/:name',
    component: AdminFinancementComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Admin, , Role.Adv, Role.RespAdv] },
  },
  {
    path: 'commercial-dashboard',
    component: CommercialDashboardComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'commercial-customer-list',
    component: CommercialCustomerListComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'commercial-case-list',
    component: CommercialCaseListComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'commercial-customer-case/:id',
    component: CommercialCustomerCaseComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'commercial-case-detail/:id',
    component: CommercialCaseDetailComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'commercial-template/:id',
    component: CommercialTemplateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'add-piece/:id',
    component: AddPieceComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'list-piece/:id',
    component: ListPieceComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'detail-piece/:pieceId',
    component: DetailPieceComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'tmk-cases',
    component: TmkCasesComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Tmk, Role.SuperTmk, Role.Beenergie] },
  },
  {
    path: 'adv-dashboard',
    component: AdvDashboardComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Adv] },
  },
  {
    path: 'case-template/:id',
    component: CaseTemplateComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.Adv, Role.Admin, Role.Compta] },
  },
  {
    path: 'case-financement-add/:id',
    component: CaseFinancementAddComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'case-financement-list/:id',
    component: CaseFinancementListComponent,
    canActivate: [AuthGuard],
    data: {},
  },

  {
    path: 'case-financement-edit/:id',
    component: CaseFinancementListComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'technicien-list-chantier',
    component: TechnicienListChantierComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'technicien-case-list-pieces/:id',
    component: TechnicienCaseListPiecesComponent,
    canActivate: [AuthGuard],
    data: {},
  },

  {
    path: 'technicien-dashboard',
    component: TechnicienDashboardComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'technicien-case-detail/:id',
    component: TechnicienCaseDetailComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'chantier-detail/:id',
    component: ChantierDetailComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'messagerie',
    component: GroupsListComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'task-list',
    component: TaskListComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'profile/:userid',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'infos-advs',
    component: InfosComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'case-historique/:id',
    component: CaseHistoriqueComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'compta-dashboard',
    component: ComptaDashboardComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'compta-case-detail/:id',
    component: ComptaCaseDetailComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'case-table',
    component: CaseTableComponent,
    canActivate: [AuthGuard],
    data: {},
  },

  // ------- BOOK ---------
  {
    path: 'book/:id',
    component: BookComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'book/part0/:id',
    component: Part0Component,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'book/part1/:id',
    component: Part1Component,
    canActivate: [AuthGuard],
    data: {},
  },

  {
    path: 'book/part2/:id',
    component: Part2Component,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'book/part3/:id',
    component: Part3Component,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'book/part4/:id',
    component: Part4Component,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'book/part4bis/:id',
    component: Part4bisComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'book/part5/:id',
    component: Part5Component,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'book/part5bis/:id',
    component: Part5bisComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'book/part6/:id',
    component: Part6Component,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'book/part7/:id',
    component: Part7Component,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'book/part8/:id',
    component: Part8Component,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'book/part9/:id',
    component: Part9Component,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'book/part9bis/:id',
    component: Part9BisComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'book/part10/:id',
    component: Part10Component,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'book/part11/:id',
    component: Part11Component,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'book/part12/:id',
    component: Part12Component,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'book/part13/:id',
    component: Part13Component,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'book/part14/:id',
    component: Part14Component,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'book/part15/:id',
    component: Part15Component,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'book/part16/:id',
    component: Part16Component,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'book/part17/:id',
    component: Part17Component,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'book/part18/:id',
    component: Part18Component,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'book/part19/:id',
    component: Part19Component,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'book/part20/:id',
    component: Part20Component,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'book/part21/:id',
    component: Part21Component,
    canActivate: [AuthGuard],
    data: {},
  },

  // ------- BOOK ---------
  {
    path: 'case-cm/:id',
    component: CaseCmComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'case-cm-template/:id',
    component: CaseCmTemplateComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'compta-cm-table',
    component: ComptaCmTableComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'compta-primes-table',
    component: ComptaPrimesTableComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'compta-primes-detail/:id',
    component: ComptaPrimesDetailComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'compta-cm-detail/:id',
    component: ComptaCmDetailComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'compta-template',
    component: ComptaTemplateComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'compta-leads',
    component: ComptaLeadsComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'spectateur-dashboard',
    component: SpectateurDashboardComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'case-prime-add/:id',
    component: CasePrimeAddComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'case-prime-list/:id',
    component: CasePrimeListComponent,
    canActivate: [AuthGuard],
    data: {},
  },

  {
    path: 'case-prime-edit/:id',
    component: CasePrimeEditComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'livraison-visualize/:id',
    component: LivraisonVisualizeComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'reception-visualize/:id',
    component: ReceptionVisualizeComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'bi-visualize/:id',
    component: BiVisualizeComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'bi-visualize-cm/:id',
    component: BiVisualizeCmComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'recette-template/:id',
    component: RecetteTemplateComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'suivi-affaires',
    component: SuiviAffairesComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'suivi-dossiers',
    component: SuiviDossiersComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'logistique-dashboard',
    component: LogistiqueDashboardComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'logistique-detail/:id',
    component: LogistiqueDetailComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'logistique-stock/:id',
    component: LogistiqueStockComponent,
    canActivate: [AuthGuard],
    data: {},
  },
  {
    path: 'logistique-visualize/:id',
    component: LogistiqueVisualizeComponent,
    canActivate: [AuthGuard],
    data: {},
  },

  { path: 'sav-dashboard', component: SavDashboardComponent },
  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
