<div class="group">
  <div class="group__header">
    <i class="group__header__back" *ngIf="isMobile" (click)="goBack()"></i>
    <div class="group__header__title">{{ getGroupTitle() }}</div>
    <div class="group__header__actions">
      <i *ngIf="isAdmin && group && group.type === 'channel'" class="add-users" (click)="promptUsers()"></i>
      <i class="more" mat-button [matMenuTriggerFor]="menu"></i>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="groupArchive()">Archiver le groupe</button>
        <button mat-menu-item (click)="groupDestroy()">Supprimer le groupe</button>
      </mat-menu>
    </div>
  </div>
  <div class="group__content" #content>
    <button class="group__content__loadmore btn-with-loader" [class.loading]="isLoading" (click)="loadmore()" *ngIf="messages.length >= 20 && newMessagesCount >= 20">
      <span class="btn-content">Charger plus de messages</span>
      <mat-spinner [diameter]="20" color="warn" *ngIf="isLoading"></mat-spinner>
    </button>
    <div *ngFor="let message of messages">
      <app-message [me]="currentUser" [message]="message" [group]="group" [socket]="socket"></app-message>
    </div>
  </div>
  <div class="group__footer">
    <div class="group__footer__color">
      <textarea placeholder="Entrer votre message..." (keyup.enter)="send()" [(ngModel)]="text"></textarea>
      <div class="group__footer__actions">
        <div class="group__footer__action checkbox" *ngIf="isAdmin">
          <mat-checkbox [(ngModel)]="isSendNotification"></mat-checkbox>
          <span>Notifier</span>
        </div>
        <div class="group__footer__action" [class.loading]="isAttaching">
          <i class="attach" (click)="attach()"></i>
          <mat-spinner [diameter]="isMobile ? 20 : 30" color="warn" *ngIf="isAttaching"></mat-spinner>
        </div>
        <div class="group__footer__action" [class.loading]="isSending">
          <i class="send" (click)="send()"></i>
          <mat-spinner [diameter]="isMobile ? 20 : 30" color="warn" *ngIf="isSending"></mat-spinner>
        </div>
      </div>
    </div>
  </div>
</div>
