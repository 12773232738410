<form [formGroup]="primeForm" *ngIf="prime" class="mt-5">
    <section class="verse-client">
      <mat-checkbox class="example-margin" formControlName="verse_client" (change)="saveChange('edité','versé au client',primeForm.value.verse_client ? 'oui' : 'non')">Versé au client</mat-checkbox>
    </section>

    <div class="row" *ngIf="prime.type === 'mpr'">
      <div class="col-6">
        <h5>N° de dossier</h5>
          <input formControlName="n_dossier" type="text" class="form-control mb-0 mt-1" placeholder="Ex: MPR-2021-310778" (change)="saveChange('edité',primeForm.value.n_dossier,primeForm.value.n_dossier)">

      </div>
    </div>

    <div class="choices mr-5 mt-4">
      <h5 class="m-0">Statut de la demande</h5>
      <input type="radio" id="state1" name="state" formControlName="state" value="En cours" (change)="saveChange('edité',primeForm.value.type,primeForm.value.state)">
      <label for="state1" [ngClass]="this.primeForm.value.state === 'En cours' ? 'pe' : 'pe-empty'">En cours</label>

      <input type="radio" id="state3" name="state" formControlName="state" value="Complément" (change)="saveChange('edité',primeForm.value.type,primeForm.value.state)">
      <label for="state3" [ngClass]="this.primeForm.value.state === 'Complément' ? 'pe' : 'pe-empty'">Complément</label>

      <input type="radio" id="state4" name="state" formControlName="state" value="Acceptation" (change)="saveChange('edité',primeForm.value.type,primeForm.value.state)">
      <label for="state4" [ngClass]="this.primeForm.value.state === 'Acceptation' ? 'oui' : 'oui-empty'">Acceptation</label>

      <input type="radio" id="state2" name="state" formControlName="state" value="Refus" (change)="saveChange('edité',primeForm.value.type,primeForm.value.state)">
      <label for="state2" [ngClass]="this.primeForm.value.state === 'Refus' ? 'non' : 'non-empty'">Refus</label>

      <input type="radio" id="state6" name="state" formControlName="state" value="Payé" (change)="saveChange('edité',primeForm.value.type,primeForm.value.state)">
      <label for="state6" [ngClass]="this.primeForm.value.state === 'Payé' ? 'oui' : 'oui-empty'">Payé</label>
    </div>


    <div class="row">
      <div class="col-4">
        <h5>Date de saisie du dossier</h5>
      </div>

      <div class="col-4" *ngIf="this.primeForm.value.state === 'Complément' || this.primeForm.value.date_complement != null">
        <h5>Date d'envoi des compléments</h5>
      </div>

      <div class="col-4" *ngIf="this.primeForm.value.state === 'Payé' || this.primeForm.value.date_virement != null">
        <h5>Date de virement</h5>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <input class="empty-date" formControlName="date_saisie" placeholder="JJ/MM/AAAA xxhxx" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" (ngModelChange)="saveChange('edité','Date de saisie',primeForm.value.date_saisie)">
        <span class="trigger" [owlDateTimeTrigger]="dt1">
          <img src="../../../assets/icons/calendar.svg" class="calendar">
        </span>
        <owl-date-time firstDayOfWeek="1" #dt1></owl-date-time>
      </div>

      <div class="col-4" *ngIf="this.primeForm.value.state === 'Complément' || this.primeForm.value.date_complement != null">
        <input class="empty-date" formControlName="date_complement" placeholder="JJ/MM/AAAA xxhxx" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" (ngModelChange)="saveChange('edité','Date de complément',primeForm.value.date_complement)">
        <span class="trigger" [owlDateTimeTrigger]="dt2">
          <img src="../../../assets/icons/calendar.svg" class="calendar">
        </span>
        <owl-date-time firstDayOfWeek="1" #dt2></owl-date-time>
      </div>

      <div class="col-4" *ngIf="this.primeForm.value.state === 'Payé' || this.primeForm.value.date_virement != null">
        <input class="empty-date" formControlName="date_virement" placeholder="JJ/MM/AAAA xxhxx" [owlDateTime]="dt3" [owlDateTimeTrigger]="dt3" (ngModelChange)="saveChange('edité','Date de virement',primeForm.value.date_virement)">
        <span class="trigger" [owlDateTimeTrigger]="dt3">
          <img src="../../../assets/icons/calendar.svg" class="calendar">
        </span>
        <owl-date-time firstDayOfWeek="1" #dt3></owl-date-time>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-6" style="display: grid;">
        <h5>Le dossier est-il envoyé ?</h5>

        <mat-select [ngClass]="this.primeForm.value.envoi === true ? 'Signée' : 'Annulée'" style="width: 16%;" class="custom-select-state rdv" formControlName="envoi" (selectionChange)="saveChange('edité','dossier envoyé ?',primeForm.value.envoi ? 'oui' : 'non')">
          <mat-option [value]=true>Oui</mat-option>
          <mat-option [value]=false>Non</mat-option>
        </mat-select>
      </div>

    <div class="col-6">
      <div *ngIf="prime.type === 'cee'">
        <h5>Produit éligible</h5>
        <ul class="product">
          <li>{{primeForm.value.product.name }}</li>
        </ul>
      </div>

      <div *ngIf="prime.type === 'mpr'">
        <h5>Produit(s) éligible(s)</h5>
        <div *ngIf="multipleProduct">
          <ul class="product" *ngFor="let prod of prime.product">
            <li>{{prod.name }}</li>
          </ul>
        </div>
        <div *ngIf="!multipleProduct">
          <ul class="product">
            <li>{{primeForm.value.product.name }}</li>
          </ul>
        </div>
      </div>


      </div>
    </div>
    <div class="row mt-5">
      <div class="col-6" style="display: grid;">
        <h5>Date d'envoi du dossier</h5>
        <div>
          <input class="empty-date" formControlName="date_envoi" placeholder="JJ/MM/AAAA xxhxx" [owlDateTime]="dt3" [owlDateTimeTrigger]="dt3" (ngModelChange)="saveChange('edité','date d\'envoi ?',primeForm.value.envoi ? 'oui' : 'non')">
          <span class="trigger" [owlDateTimeTrigger]="dt3">
            <img src="../../../assets/icons/calendar.svg" class="calendar">
          </span>
          <owl-date-time firstDayOfWeek="1" #dt3></owl-date-time>
        </div>

        <input formControlName="montant" type="number" class="form-control" placeholder="Montant" (change)="saveChange('edité',primeForm.value.type,primeForm.value.state)">

      </div>

      <div class="col-6" *ngIf="prime.type === 'cee'">
        <h5>Liste des pièces à fournir</h5>
        <div class="scroll">
          <ul *ngFor="let piece of checkProducts">
            <li [ngClass]="piece.qualification === null ? 'check-pieces' : ''">{{ piece.title }}
              <span
              [class]="piece.qualification">
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-6" *ngIf="prime.type === 'mpr'">
        <h5>Liste des pièces à fournir</h5>
        <div class="scroll">
          <ul *ngFor="let piece of checkProductsMpr">
            <li [ngClass]="piece.qualification === null ? 'check-pieces' : ''">{{ piece.title }}
              <span
              [class]="piece.qualification">
              </span>
            </li>
          </ul>
        </div>
      </div>

    </div>


    <div class="row mt-5" *ngIf="prime.type === 'cee'">
      <div class="col-6" style="display: grid;">
        <h5>Date de l'appel à facturation</h5>
        <div>
          <input class="empty-date" formControlName="date_appel" placeholder="JJ/MM/AAAA xxhxx" [owlDateTime]="dt4" [owlDateTimeTrigger]="dt4" (ngModelChange)="saveChange('edité','date appel à facturation ?',primeForm.value.date_appel)">
          <span class="trigger" [owlDateTimeTrigger]="dt4">
            <img src="../../../assets/icons/calendar.svg" class="calendar">
          </span>
          <owl-date-time firstDayOfWeek="1" #dt4></owl-date-time>
        </div>
        <input formControlName="n_appel" type="text" class="form-control" placeholder="N° d'appel à facturation" (change)="saveChange('edité','n° d\'appel à facturation',primeForm.value.n_appel)">

      </div>

    </div>

    <div class="row mt-5" *ngIf="prime.type === 'mpr'">
      <div class="col-6" style="display: grid;">
        <h5>Notification de versement</h5>
        <div>
          <input class="empty-date" formControlName="date_appel" placeholder="JJ/MM/AAAA xxhxx" [owlDateTime]="dt4" [owlDateTimeTrigger]="dt4" (ngModelChange)="saveChange('edité','date appel à facturation ?',primeForm.value.date_appel)">
          <span class="trigger" [owlDateTimeTrigger]="dt4">
            <img src="../../../assets/icons/calendar.svg" class="calendar">
          </span>
          <owl-date-time firstDayOfWeek="1" #dt4></owl-date-time>
        </div>

         <div class="col-xs-12 col-lg-6 col-sm-3 btn-secondarycrm button-wrapper upload-bill" *ngIf="!bill && prime.type === 'mpr'">
          <input accept=".jpg,.jpeg,.png,.pdf" multiple type="file" name="upload" id="upload" class="upload-box" (change)="selectMultiple($event)">
          + Ajouter la notification
        </div>

        <a href="https://api-crm.s3.eu-west-3.amazonaws.com/{{bill.caseId}}/pieces/{{billImg}}" class="col-xs-12 col-lg-6 col-sm-3 btn-secondarycrm button-wrapper upload-bill" style="z-index: 999999;" *ngIf="bill && prime.type === 'mpr'">
          Voir la notification
        </a>
        <img class="rm-facture" src="../../../assets/icons/delete.svg" (click)="killBill(bill._id,'supprimé','pièce',bill.title)" *ngIf="bill && prime.type === 'mpr'">

      </div>

    </div>

    <div class="row">
      <div class="col-12">
        <h5>Suivi de la demande</h5>

        <textarea formControlName="description" type="text" class="desc mb-3" placeholder="Description" (change)="saveChange('edité','Description prime',primeForm.value.description)"></textarea>

      </div>
    </div>

  </form>

