
  <h6 class="my-3">Type de paiement</h6>
  <div class="paiement" *ngIf="case">{{ case.financement_type }}</div>


<form *ngIf="financementForm" [formGroup]="financementForm" class="custom-radio">
  <div class="choices mr-5">
    <p class="m-0">Type de réglement comptant</p>
    <input type="radio" id="choice11" name="choice" formControlName="choice" [(ngModel)]="case.financement_comptant.choice" value='Virement' (change)="saveChange('edité','le type de réglement comptant',case.financement_comptant.choice)">
    <label for="choice11" [ngClass]="this.financementForm.value.choice === 'Virement' ? 'oui' : 'oui-empty'">Virement</label>

      <input type="radio" id="choice2" name="choice" formControlName="choice" value='Chèques' [(ngModel)]="case.financement_comptant.choice" (change)="saveChange('edité','le type de réglement comptant',case.financement_comptant.choice)">
      <label for="choice2" [ngClass]="this.financementForm.value.choice === 'Chèques' ? 'oui' : 'oui-empty'">Chèques</label>
  </div>
  <div class="choices mr-5" *ngIf="this.financementForm && case.financement_comptant.choice === 'Chèques'">
    <p class="m-0">Les 3 chèques sont-ils présents?</p>
    <input type="radio" id="cheques1" name="cheques" [(ngModel)]="case.financement_comptant.cheques" formControlName="cheques" value=true (change)="saveChange('edité','les 3 chèques sont ils présents ? ',this.financementForm.value.cheques === 'true' ? 'oui' : 'non')">
    <label for="cheques1" [ngClass]="this.financementForm.value.cheques === 'true' ? 'oui' : 'oui-empty'">
      <span class="material-icons">
      done
      </span>OUI</label>

      <input type="radio" id="cheques2" name="cheques" [(ngModel)]="case.financement_comptant.cheques" formControlName="cheques" value=false (change)="saveChange('edité','les 3 chèques sont ils présents ? ',this.financementForm.value.cheques === 'true' ? 'oui' : 'non')">
      <label for="cheques2" [ngClass]="this.financementForm.value.cheques === 'false' ? 'non' : 'non-empty'">
      <span class="material-icons">
        clear
        </span>NON</label>
  </div>

<form *ngIf="this.financementForm && case.financement_comptant.choice === 'Chèques'" formGroupName="cheque1" class="row mt-4">
  <div class="col-3">

  <p class="mb-0">Date de dépôt du 1er chèque</p>

  <input placeholder="JJ/MM/AAAA xxhxx" [ngClass]="case.financement_comptant.cheque1.date != '' ? 'custom-date' : 'empty-date'" [(ngModel)]="case.financement_comptant.cheque1.date" formControlName="date" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1"  (ngModelChange)="saveChange('edité','la date de dépot du cheque 1',case.financement_comptant.cheque1.date)">
  <owl-date-time firstDayOfWeek="1" #dt1></owl-date-time>
  </div>
  <div class="col-2">
    <mat-form-field class="example-full-width" *ngIf="case.financement_comptant.cheque1.encaissement">
      <input *ngIf="case.financement_comptant.cheque1.encaissement" type="number" formControlName="montant" matInput [(ngModel)]="case.financement_comptant.cheque1.montant" formControlName="montant" (change)="saveChange('edité','le montant du cheque 1',case.financement_comptant.cheque1.montant)">
    </mat-form-field>
  </div>
  <div class="col-2">
    <select class="custom-select-rdv rdv" formControlName="encaissement" [ngClass]="!case.financement_comptant.cheque1.encaissement ? 'non-empty' : ''" value="case.financement_comptant.cheque1.encaissement" (change)="saveChange('edité','le dépot du cheque 1',financementForm.value.cheque1.encaissement === true? 'oui' : 'non')">
      <option [ngValue]=true>Oui</option>
      <option [ngValue]=false>Non</option>
    </select>
  </div>

  <div class="col-2">
    <p>Solde : {{ case.price_ttc - (case.financement_comptant.cheque1.montant + case.financement_comptant.cheque2.montant + case.financement_comptant.cheque3.montant) | number :'2.1-2' }} </p>
</div>
</form>

<form *ngIf="this.financementForm && case.financement_comptant.choice === 'Chèques'" formGroupName="cheque2" class="row mt-4">
  <div class="col-3">

  <p class="m-0">Date de dépôt du 2nd chèque</p>

  <input placeholder="JJ/MM/AAAA xxhxx" [ngClass]="case.financement_comptant.cheque2.date != '' ? 'custom-date' : 'empty-date'" [(ngModel)]="case.financement_comptant.cheque2.date" formControlName="date" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" (ngModelChange)="saveChange('edité','la date de dépot du cheque 2',case.financement_comptant.cheque2.date)">
  <owl-date-time firstDayOfWeek="1" (ngModelChange)="saveChange()" #dt2></owl-date-time>
  </div>

  <div class="col-2">
    <mat-form-field class="example-full-width" *ngIf="case.financement_comptant.cheque2.encaissement">
      <input type="number" formControlName="montant" matInput [(ngModel)]="case.financement_comptant.cheque2.montant" formControlName="montant" (change)="saveChange('edité','le montant du cheque 2',case.financement_comptant.cheque2.montant)">
    </mat-form-field>
  </div>
  <div class="col-2">
    <select class="custom-select-rdv rdv" formControlName="encaissement" [ngClass]="!case.financement_comptant.cheque2.encaissement ? 'non-empty' : ''" value="case.financement_comptant.cheque2.encaissement" (change)="saveChange('edité','le dépot du cheque 2',financementForm.value.cheque2.encaissement === true? 'oui' : 'non')">
      <option [ngValue]=true>Oui</option>
      <option [ngValue]=false>Non</option>
    </select>
</div>

</form>

<form *ngIf="this.financementForm && case.financement_comptant.choice === 'Chèques'" formGroupName="cheque3" class="row mt-4">
  <div class="col-3">
  <p class="m-0">Date de dépôt du 3ème chèque</p>

  <input placeholder="JJ/MM/AAAA xxhxx" [ngClass]="case.financement_comptant.cheque3.date != '' ? 'custom-date' : 'empty-date'" [(ngModel)]="case.financement_comptant.cheque3.date" formControlName="date" [owlDateTime]="dt3" [owlDateTimeTrigger]="dt3" (ngModelChange)="saveChange('edité','la date de dépot du cheque 3',case.financement_comptant.cheque3.date)">
  <owl-date-time firstDayOfWeek="1" (ngModelChange)="saveChange()" #dt3></owl-date-time>
  </div>
  <div class="col-2">
    <mat-form-field class="example-full-width" *ngIf="case.financement_comptant.cheque3.encaissement">
      <input type="number" formControlName="montant" matInput [(ngModel)]="case.financement_comptant.cheque3.montant" formControlName="montant" (change)="saveChange('edité','le montant du cheque 2',case.financement_comptant.cheque2.montant)">
    </mat-form-field>
  </div>

  <div class="col-2">
    <select class="custom-select-rdv rdv" formControlName="encaissement" [ngClass]="!case.financement_comptant.cheque3.encaissement ? 'non-empty' : ''" value="case.financement_comptant.cheque3.encaissement" (change)="saveChange('edité','le dépot du cheque 3',financementForm.value.cheque3.encaissement === true? 'oui' : 'non')">
      <option [ngValue]=true>Oui</option>
      <option [ngValue]=false>Non</option>
    </select>

  </div>

</form>

<form *ngIf="this.financementForm && case.financement_comptant.choice === 'Virement'" formGroupName="cheque1" class="row mt-4" >
  <div class="col-3">

  <p class="mb-0">Date du 1er virement</p>

  <input placeholder="JJ/MM/AAAA xxhxx" [ngClass]="case.financement_comptant.cheque1.date != '' ? 'custom-date' : 'empty-date'" [(ngModel)]="case.financement_comptant.cheque1.date" formControlName="date" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1"  (ngModelChange)="saveChange()">
  <owl-date-time firstDayOfWeek="1" (ngModelChange)="saveChange()" #dt1></owl-date-time>
  </div>
  <div class="col-2">
    <mat-form-field class="example-full-width" *ngIf="case.financement_comptant.cheque1.encaissement">
      <input *ngIf="case.financement_comptant.cheque1.encaissement" type="number" formControlName="montant" matInput [(ngModel)]="case.financement_comptant.cheque1.montant" formControlName="montant" (change)="saveChange()">
    </mat-form-field>
  </div>
  <div class="col-2">
    <select class="custom-select-rdv rdv" formControlName="encaissement" [ngClass]="!case.financement_comptant.cheque1.encaissement ? 'non-empty' : ''" value="case.financement_comptant.cheque1.encaissement" (change)="saveChange()">
      <option [ngValue]=true>Oui</option>
      <option [ngValue]=false>Non</option>
    </select>
  </div>

  <div class="col-2">
    <p>Solde : {{ case.price_ttc - (case.financement_comptant.cheque1.montant + case.financement_comptant.cheque2.montant + case.financement_comptant.cheque3.montant) | number :'2.1-2' }} </p>
</div>
</form>

<form *ngIf="this.financementForm && case.financement_comptant.choice === 'Virement'" formGroupName="cheque2" class="row mt-4">
  <div class="col-3">

  <p class="m-0">Date du 2nd virement</p>

  <input placeholder="JJ/MM/AAAA xxhxx" [ngClass]="case.financement_comptant.cheque2.date != '' ? 'custom-date' : 'empty-date'" [(ngModel)]="case.financement_comptant.cheque2.date" formControlName="date" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" (ngModelChange)="saveChange()">
  <owl-date-time firstDayOfWeek="1" (ngModelChange)="saveChange()" #dt2></owl-date-time>
  </div>

  <div class="col-2">
    <mat-form-field class="example-full-width" *ngIf="case.financement_comptant.cheque2.encaissement">
      <input type="number" formControlName="montant" matInput [(ngModel)]="case.financement_comptant.cheque2.montant" formControlName="montant" (change)="saveChange()">
    </mat-form-field>
  </div>
  <div class="col-2">
    <select class="custom-select-rdv rdv" formControlName="encaissement" [ngClass]="!case.financement_comptant.cheque2.encaissement ? 'non-empty' : ''" value="case.financement_comptant.cheque2.encaissement" (change)="saveChange()">
      <option [ngValue]=true>Oui</option>
      <option [ngValue]=false>Non</option>
    </select>
</div>

</form>

<form *ngIf="this.financementForm && case.financement_comptant.choice === 'Virement'" formGroupName="cheque3" class="row mt-4">
  <div class="col-3">
  <p class="m-0">Date du 3ème virement</p>

  <input placeholder="JJ/MM/AAAA xxhxx" [ngClass]="case.financement_comptant.cheque3.date != '' ? 'custom-date' : 'empty-date'" [(ngModel)]="case.financement_comptant.cheque3.date" formControlName="date" [owlDateTime]="dt3" [owlDateTimeTrigger]="dt3" (ngModelChange)="saveChange()">
  <owl-date-time firstDayOfWeek="1" (ngModelChange)="saveChange()" #dt3></owl-date-time>
  </div>
  <div class="col-2">
    <mat-form-field class="example-full-width" *ngIf="case.financement_comptant.cheque3.encaissement">
      <input type="number" formControlName="montant" matInput [(ngModel)]="case.financement_comptant.cheque3.montant" formControlName="montant" (change)="saveChange()">
    </mat-form-field>
  </div>

  <div class="col-2">
    <select class="custom-select-rdv rdv" formControlName="encaissement" [ngClass]="!case.financement_comptant.cheque3.encaissement ? 'non-empty' : ''" value="case.financement_comptant.cheque3.encaissement" (change)="saveChange()">
      <option [ngValue]=true>Oui</option>
      <option [ngValue]=false>Non</option>
    </select>

  </div>

</form>
</form>
