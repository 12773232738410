import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { ChantierService } from '@app/services/chantier.service';
import { PiecesService } from '@app/services/pieces.service';
import { TasksService } from '@app/services/tasks.service';
import { RowNode } from 'ag-grid-community';

@Component({
  selector: 'app-case-table',
  templateUrl: './case-table.component.html',
  styleUrls: ['./case-table.component.scss']
})
export class CaseTableComponent implements OnInit {
  cases;
  gridApi;
  gridColumnApi;

  rowData;
  rowHeight
  rowStyle
  columnDefs;
  defaultColDef;

  chantiers
  pieces
  tasks
  constructor(
    private sCases: CasesService,
    private router: Router,
    private sChantiers: ChantierService,
    private sPieces: PiecesService,
    private sTasks: TasksService,
  ) {
    this.rowHeight = 65;
    this.rowStyle = { background: 'white' };
    this.columnDefs = [
      {
        headerName: 'ID',
        field: 'id',
        cellClass: 'cell-align',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Créée le',
        field: 'created_at',
        cellClass: 'cell-align',
        filter: 'agDateColumnFilter',
        cellRenderer: (data) => {
          return data.value ? (new Date(data.value)).toLocaleDateString() : '';
        },
      },
      {
        headerName: 'Client',
        field: 'customer.name',
        cellClass: 'cell-align',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'mail',
        field: 'customer.mail',
        cellClass: 'cell-align',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'cp',
        field: 'customer.cp',
        cellClass: 'cell-align',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Titre',
        field: 'title',
        cellClass: 'cell-align',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Commercial',
        field: 'commercial.username',
        cellClass: 'cell-align',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Adv',
        field: 'adv.username',
        cellClass: 'cell-align',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Statut',
        field: 'state',
        cellStyle: cellStyle,
        cellClass: 'cell-align',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'prix ttc',
        field: 'price_ttc',
        cellClass: 'cell-align',
        cellRenderer: (data) => {
          return data.value ? data.value + ' €' : '';
        },
      },
      {
        headerName: 'Date de signature',
        field: 'datesign',
        filter: 'agDateColumnFilter',
        cellClass: 'cell-align',
        cellRenderer: (data) => {
          return data.value ? (new Date(data.value)).toLocaleDateString() : '';
        },
      },
      {
        headerName: 'Créée par',
        field: 'created_by.username',
        cellClass: 'cell-align',
        sortable: true,
        filter: true
      },
      {
        field: 'age',
        pinnedRowCellRenderer: 'customPinnedRowRenderer',
        pinnedRowCellRendererParams: { style: { 'font-style': 'italic' } },
      },

  ];
  this.defaultColDef = {
    flex: 1,
    minWidth: 0,
    sortable: true,
    floatingFilter: true,
    resizable: true,
    pagination: true,

  };

  function cellStyle(params) {
    var color = numberToColor(params.value);
    return { color: color };
  }
  function numberToColor(val) {
    if (val === 'Signée') {
      return '#78C091';
    }
    else if(val === 'Non Signée' || val === 'Annulée') {
      return '#F45B69';
    }
    else if(val === 'Prospection') {
      return '#06AED5';
    }
    else if(val === 'Négociation') {
      return '#F0C808';
    }
  }
   }

  ngOnInit(): void {
    this.showAllCases()
  }

  onBtnExport() {
    this.gridApi.exportDataAsCsv();
  }


  generatePinnedBottomData(){
    // generate a row-data with null values
    let result = {};

    this.gridColumnApi.getAllGridColumns().forEach(item => {
        result[item.colId] = null;
    });
    return this.calculatePinnedBottomData(result);
}

calculatePinnedBottomData(target: any){
    //list of columns fo aggregation
    let columnsWithAggregation = ['price_ttc']
    columnsWithAggregation.forEach(element => {
        this.gridApi.forEachNodeAfterFilter((rowNode: RowNode) => {

            if (rowNode.data[element] && rowNode.data.state === 'Signée')
                target[element] += Number(rowNode.data[element].toFixed(2));
        });
        if (target[element])
            target[element] = target[element].toFixed(2);
    })
    return target;
}


  showAllCases() {
    this.sCases.getAdminTableCases().subscribe(res => {
      this.cases = res['newData'];
      this.rowData = this.cases
  })
  }
  onSelectionChanged() {
    var selectedRows = this.gridApi.getSelectedRows();
    //this.router.navigate(['/case-template/', selectedRows[0].id]);
     const url = this.router.serializeUrl(
      this.router.createUrlTree(['/case-template/', selectedRows[0].id])
    );
    window.open(url, '_blank');
  }
  showInfos() {
    var selectedRows = this.gridApi.getSelectedRows();
    this.showChantierByCaseId(selectedRows[0].id);
    this.showPiecesByCaseId(selectedRows[0].id)
    this.showTasksByCaseId(selectedRows[0].id)
  }
  showChantierByCaseId(caseid) {
    this.sChantiers.getChantierByCaseId(caseid).subscribe(res => {
      this.chantiers = res
    })
  }
  showPiecesByCaseId(caseid) {
    this.sPieces.getPiecesByCaseId(caseid).subscribe(res => {
      this.pieces = res
    })
  }
  showTasksByCaseId(caseid) {
    this.sTasks.getTaskByCaseId(caseid).subscribe(res => {
      this.tasks = res
    })
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    setTimeout(()=>{
      let pinnedBottomData = this.generatePinnedBottomData();
    this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 3000)
  }

}
