import { Component, OnInit, Input } from '@angular/core';
import { format } from 'date-fns';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class GroupsMessageComponent implements OnInit {
  @Input() group: any;
  @Input() me: any;
  @Input() message: any;
  @Input() socket: any;
  @Input() groupMembers: any;
  member = undefined;

  constructor() {

   }

  ngOnInit(): void {
    this.member = this.group.members.find(m => m._id === this.message.userId);
    if (this.message.type !== 'date-break' && !this.message.read.includes(this.me.id)) this.socket.emit(`messages:read`, this.message);
  }

  getHour(): string {
    return format(new Date(this.message.created_at), `HH'h'mm`);
  }

  getInitials(): string {
    if (!this.member) return 'AA';
    const initials = [];
    const names = this.member.username.split(' ');
    names.forEach(n => initials.push(n.charAt(0)));
    return initials.join('');
  }

  getUsername(): string {
    return this.member ? this.member.username : 'Aa';
  }

}
