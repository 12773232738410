import { Component, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CasesService } from '@app/services/cases.service';
import { PicturesService } from '@app/services/pictures.service';
import * as Chart from 'chart.js';
import * as moment from 'moment';
import { AdminFinancementComponent } from '../admin-financement/admin-financement.component';
import { AdminCases } from './../../_models/AdminCases';
import { RolesService } from './../../services/roles.service';
import { StatService } from './../../services/stat.service';
import { UsersService } from './../../services/users.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['admin-dashboard.component.scss'],
})
export class AdminDashboardComponent implements OnInit, OnChanges {
  month = new Date();
  actualMonth;
  mobile;
  advId = '6135c7036457510004c2eef1'; // id de Dora
  advCases = {
   advCaNet: [],
   advCaBrut: [],
   CABrutTotaladv: 0,
   CAnetTotaladv: 0,
             };
  user;
  Global = true;
  adminCases: AdminCases;
  commerciauxZero = [];
  selectedAgence = [];
  chosenAgency = {
    name: '',
    caBrut: [],
    caNet: [],
    case: [],
    cancelCase: [],
    rateCancel: [],
    commerciaux: [],
    newRateCancel: [],
  };

  ratedMonth = [true, false, false];

  toggleMonth = [
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ];

  showAdminDashboard = true;
  showComptaDashboard = false;
  showAdvDashboard = false;
  showSuiviDossiers = false;

  optionsDoughnut = {
    tooltips: {
      enabled: false,
    },
    responsive: true,
    rotation: 0.25 * Math.PI,
    cutoutPercentage: 85,
    legend: {
      display: false,
    },
  };
  chartColors = [
    {
      backgroundColor: ['rgba(58, 59, 90, 0.8)', 'rgba(233, 94, 36, 0.5)'],

    }
  ];
  stackedBarOption = {
    borderRadius: '50px',
    tooltips: {
      enabled: true,
    },
    legend: {
      display: true,
    },
    scales: {
      yAxes: [
        {
          display: false,
        },
      ],
      xAxes: [
        {
          ticks: {
            fontColor: '#3A3B5A',

          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };
  usersPictures = [];
  imageSrc;

  selectedId;
  selectedAgency;
  commerciaux;

  selectedMonth;
  currentDate = new Date();
  selectedYear: number;
  months = [
    { name: 'Aucun', value: 999 },
    { name: 'Janvier', value: 0 },
    { name: 'Fevrier', value: 1 },
    { name: 'Mars', value: 2 },
    { name: 'Avril', value: 3 },
    { name: 'Mai', value: 4 },
    { name: 'Juin', value: 5 },
    { name: 'Juillet', value: 6 },
    { name: 'Aout', value: 7 },
    { name: 'Septembre', value: 8 },
    { name: 'Octobre', value: 9 },
    { name: 'Novembre', value: 10 },
    { name: 'Décembre', value: 11 }];
  years = [2021, 2022, 2023, 2024, 2025];

  agences = [];
  agencesBrut = [];
  agencesNet = [];
  color: [
    {
      backgroundColor: ['#E95E24', '#3A3B5A'],
      borderColor: ['#E95E24', '#3A3B5A'],
    },
  ];
  constructor(
    public sUsers: UsersService,
    public sRoles: RolesService,
    public sCases: CasesService,
    public sStat: StatService,
    private dialog: MatDialog,
    private router: Router,
    private sPictures: PicturesService) {
    this.selectedYear = moment().year();
    this.selectedMonth = moment().month();
    const formattedDate = moment().format('YYYY-MM-DD');

    this.fetchDashboardData(formattedDate);

    if (window.screen.width <= 600) {
      this.mobile = true;
    }
    this.imageSrc = 'assets/empty_pp.svg';
    this.actualMonth = this.month.getMonth();
    this.user = this.sRoles.user;
    if (this.user.role == "Responsable d'agence") {
      this.sUsers.getUserById(this.user.id).subscribe((res) => {
        this.showAgency(res[0].agency);
      });
    }
  }

  ngOnInit(): void {
    this.sStat.resetStat();
  }

  ngOnChanges() {
    if (this.user.role != 'Admin') {
      this.showCommerciaux();
      alert(this.sRoles.user.agency)
      this.showCommOfAgency(this.sRoles.user.agency)
    }
  }

  createChart(ctx: CanvasRenderingContext2D) {

    const myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.agences,
        datasets: [
          {
            label: 'CA Net',
            data: this.agencesNet,
            backgroundColor: 'rgba(233, 94, 36, 0.5)',
            borderColor: 'rgba(233, 94, 36, 1)',
            borderWidth: 1,
          },
          {
            label: 'CA Brut',
            data: this.agencesBrut,
            backgroundColor: 'rgba(58, 59, 90, 0.8)',
            borderColor: 'rgba(58, 59, 90, 1)',
            borderWidth: 1,

          },
        ],
      },
      options: {
        scales: {

          yAxes: [{
            ticks: {
              beginAtZero: true,
            },
          }],
        },
        hover: null,
      },
    });
  }

  public generateExcel() {
    this.sStat.generateExcel(this.adminCases)
  }


  showCasesBySelectedDate() {
    if (this.selectedMonth !== 999) {
      const formattedDateFinal = moment([this.selectedYear, this.selectedMonth, moment().date()]);
      const formattedDateString = formattedDateFinal.format('YYYY-MM-DD');
      this.fetchDashboardData(formattedDateString);
    }
    else {
      // ajout d'un mois random
      const formattedDateFinal = moment([this.selectedYear, 1, moment().date()]);
      const formattedDateString = formattedDateFinal.format('YYYY-MM-DD');
      this.fetchDashboardData(formattedDateString, this.selectedYear)
    }
  }

  public fetchDashboardData(date: string, year?: number) {
    this.sCases.getCasesByDate(date, year).subscribe(cases => {
      this.adminCases = cases;
      this.agences = []
      this.agencesNet = []
      this.agencesBrut = []
      this.adminCases.agences.forEach(agence => {
        this.agences.push(agence.agence)
        this.agencesNet.push(agence.caNetTotal)
        this.agencesBrut.push(agence.caBrutTotal)
        const canvas = document.getElementById('myChart') as HTMLCanvasElement;
        const ctx = canvas.getContext('2d');
        if (ctx) {
          this.createChart(ctx);
        }

      });
      this.sUsers.getAllCommerciaux().subscribe(res => {
        // Récupère les commerciaux actifs qui n'ont fait aucun CA
        this.commerciauxZero = res['users'].filter(item2 => {
          return !this.adminCases.commerciauxCaBrut.some(item1 => item1.commercial === item2.username) && item2.username !== "test";
        });
      })

       this.sCases.getCasesByAdv(date, year).subscribe((advCases) => {
      this.advCases.advCaNet = advCases.advDetails.map((adv) => ({
        commercial: adv.advName,
        caNet: adv.caNet,
      }));
      this.advCases.advCaBrut = advCases.advDetails.map((adv) => ({
        commercial: adv.advName,
        caBrut: adv.caBrut,
      }));

          const CANetTotaladv = advCases.caNetTotaladv || 0;
          const CABrutTotaladv = advCases.caBrutTotaladv || 0;

          this.adminCases.caNetTotal += CANetTotaladv;
          this.adminCases.caBrutTotal += CABrutTotaladv;
         
          this.adminCases.commerciauxCaNet.push(...this.advCases.advCaNet);
          this.adminCases.commerciauxCaBrut.push(...this.advCases.advCaBrut);

          this.adminCases.commerciauxCaNet.sort((a, b) => b.caNet - a.caNet);
          this.adminCases.commerciauxCaBrut.sort((a, b) => b.caBrut - a.caBrut);
      });
    })
  }

  calculateGlobalTauxAnnulation(): number {
  if (!this.adminCases || !this.advCases) {
    return 0; // Si les données ne sont pas encore chargées, renvoyer 0
  }

  // CA Brut total combiné (commerciaux + adv)
  const globalCaBrutTotal =
    (this.adminCases?.caBrutTotal || 0) + (this.advCases?.CABrutTotaladv || 0);

  // CA Net total combiné (commerciaux + adv)
  const globalCaNetTotal =
    (this.adminCases?.caNetTotal || 0) + (this.advCases?.CAnetTotaladv || 0);

  // Calcul du taux d'annulation
  const difference = globalCaBrutTotal - globalCaNetTotal;

  return globalCaBrutTotal > 0
    ? (difference / globalCaBrutTotal) * 100
    : 0; // Si CA Brut total est 0, éviter une division par 0
}


  showComm(event) {
    this.selectedId = event;
  }
  showCommOfAgency(agency) {
    this.selectedAgency = agency;
    this.sUsers.getCommercialByAgency(agency).subscribe((res) => {
      this.commerciaux = res['user'].filter((a) => a.isActive);
    });
  }
  async showCommerciaux() {
    await this.sUsers.getUserById(this.user.id).subscribe((res) => {
      //commercial = commercial._id
    });
  }
  getShortName(fullName) {
    return fullName
      .split(' ')
      .map((n) => n[0])
      .join('');
  }
  getFirstName(fullName) {
    return fullName.split(' ')[0];
  }
  getLastName(fullName) {
    return fullName.split(' ')[1];
  }

  showAgencyDetails(agence) {
    agence.commerciaux.push(...this.commerciauxZero.filter(commercial => commercial.agency === agence.agence));

    this.selectedAgence = agence;
    this.Global = !this.Global;
    this.sUsers.getCommercialByAgency(agence.agence).subscribe((res) => {
      const commerciaux = res['user'];
      commerciaux.forEach((element) => {
        this.sPictures.getPicturesByUserId(element._id).subscribe((res) => {
          this.usersPictures.push({
            id: element._id,
            picture: res,
          });
        });
      });
    });
  }

  showAgency(agency) {
    this.sUsers.getCommercialByAgency(agency).subscribe((res) => {
      const commerciaux = res['user'];
      commerciaux.forEach((element) => {
        this.sPictures.getPicturesByUserId(element._id).subscribe((res) => {
          this.usersPictures.push({
            id: element._id,
            picture: res,
          });
        });
      });
    });

    if (this.Global) {
      this.Global = false;
      this.chosenAgency = this.sStat.agencyArray.find((a) => a.name == agency);
      this.chosenAgency.commerciaux = this.chosenAgency.commerciaux.filter(
        (c) => c.isActive == true
      );
    } else {
      this.Global = true;
    }
  }

  getProfil(created_by) {
    let profil: any = this.usersPictures.filter((p) => p.id === created_by);
    if (profil) {
      profil = profil[0];
      if (profil && profil.picture[0]) {
        profil =
          'https://api-crm.s3.eu-west-3.amazonaws.com/' +
          created_by +
          '/picture/' +
          profil.picture[0].image;
      } else {
        profil = this.imageSrc;
      }
    } else {
      profil = this.imageSrc;
    }
    return profil;
  }
  showMonth(index) {
    this.toggleMonth[index] = !this.toggleMonth[index];
    if (index == 1) {
      if (this.toggleMonth[index] == false) {
        this.sStat.userCaBrut.sort((a, b) =>
          a.caBrutAnnuel > b.caBrutAnnuel ? -1 : 1
        );
      } else {
        this.sStat.userCaBrut.sort((a, b) =>
          a.caBrut[this.actualMonth] > b.caBrut[this.actualMonth] ? -1 : 1
        );
      }
    } else if (index == 2) {
      if (this.toggleMonth[index] == false) {
        this.sStat.userCaNet.sort((a, b) =>
          a.caNetAnnuel > b.caNetAnnuel ? -1 : 1
        );
      } else {
        this.sStat.userCaNet.sort((a, b) =>
          a.caNet[this.actualMonth] > b.caNet[this.actualMonth] ? -1 : 1
        );
      }
    } else if (index == 7) {
      if (this.toggleMonth[index] == false) {
        this.sStat.agencyArray.sort((a, b) =>
          a.caNetAnnuel > b.caNetAnnuel ? -1 : 1
        );
        this.sStat.agencyCaBrut.sort((a, b) =>
          a.caBrutAnnuel > b.caBrutAnnuel ? -1 : 1
        );
      } else {
        this.sStat.agencyArray.sort((a, b) =>
          a.caNet[this.actualMonth] > b.caNet[this.actualMonth] ? -1 : 1
        );
        this.sStat.agencyCaBrut.sort((a, b) =>
          a.caBrut[this.actualMonth] > b.caBrut[this.actualMonth] ? -1 : 1
        );
      }
    }
  }

  showRated(index) {
    this.ratedMonth[0] = false;
    this.ratedMonth[1] = false;
    this.ratedMonth[2] = false;
    this.ratedMonth[index] = true;

    if (index == 0) {
      this.sStat.commerciaux.sort((a, b) =>
        a.rateRdvQualified[this.actualMonth] >
          b.rateRdvQualified[this.actualMonth]
          ? -1
          : 1
      );
    } else if (index == 1) {
      this.sStat.commerciaux.sort((a, b) =>
        a.rateRdvQualified[this.actualMonth - 1] >
          b.rateRdvQualified[this.actualMonth - 1]
          ? -1
          : 1
      );
    } else if (index == 2) {
      this.sStat.commerciaux.sort((a, b) =>
        a.rateRdvQualifiedAnnuel > b.rateRdvQualifiedAnnuel ? -1 : 1
      );
    }
  }

  showDashboard(dashboard) {
    if (dashboard == 'admin') {
      this.showAdminDashboard = true;
      this.showComptaDashboard = false;
      this.showSuiviDossiers = false;
      this.showAdvDashboard = false;
    } else if (dashboard == 'compta') {
      this.sStat.resetStat();
      this.showAdminDashboard = false;
      this.showAdvDashboard = false;
      this.showComptaDashboard = true;
      this.showSuiviDossiers = false;
    } else if (dashboard == 'adv') {
      this.showAdminDashboard = false;
      this.showComptaDashboard = false;
      this.showSuiviDossiers = false;
      this.showAdvDashboard = true;
    } else if (dashboard == 'commerciaux') {
      this.showAdminDashboard = false;
      this.showComptaDashboard = false;
      this.showSuiviDossiers = true;
      this.showAdvDashboard = false;
    }
  }

  openDetailPartenaire(selectedPartenaire): void {
    const dialogRef = this.dialog.open(AdminFinancementComponent, {
      width: '60%',
      height: '80%',
      data: {
        agences: this.adminCases.agences,
        financements: this.adminCases.financements,
        selectedPartenaire: selectedPartenaire
      },
    });
  }

  goToSuiviAnnuel() {
    this.router.navigate(['/admin-suivi-annuel'], { state: { adminCases: this.adminCases } });
  }
}
