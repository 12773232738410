<img *ngIf="mobile" class="notification-tech" [routerLink]="['/infos-advs']" src="../../../assets/icons/notification.svg">
<p *ngIf="mobile" class="compteur-tech">
  <span matBadge="{{ infosUnread.length }}" matBadgeSize="small"></span>
</p>

<section class="wrapper-fostrap">
  <div class="container-fostrap">
    <mat-form-field class="recherche" *ngIf="chantiers != undefined">
      <input matInput [(ngModel)]="query"
      type="text" name="userQuestion" id="userQuestions"
      (keydown)="research()" placeholder="Recherche Nom ou prénom">
      <button *ngIf="query" matSuffix mat-icon-button aria-label="Clear" (click)="searchResult=[] && query=''">
        <mat-icon>close</mat-icon>
      </button>

    </mat-form-field>
  </div>
</section>

<section class="wrapper-fostrap" *ngIf="searchResult.length > 0">
  <div class="container-fostrap">
    <div class="content">
      <div class="row">
        <div class="col-xs-12 col-lg-12 col-sm-12">Résultat de la recherche</div>
        <div class=" container-travaux" >
        <div class="col-xs-12 col-lg-4 col-sm-6" *ngFor="let chantier of searchResult">
          <div class="card" [routerLink]="['/technicien-case-detail',chantier._id]" >
            <div class="card-content">
              <div style="display: flex;"><p style="color: var(--tertiarycrm); font-weight: 600;" > {{ chantier.date| date:'short' }}</p> <p style="font-weight: 600; margin-left: 5%; " >{{chantier.title}} </p></div>
              <div class="qualif-mairie" [ngClass]="{'attente':chantier.statut === 'En cours',
              'conforme':chantier.statut === 'Terminé',
              'nonconforme':chantier.statut === 'Annulé'
             }" >{{chantier.statut}} </div>

              <p style="font-size: 16px;" class="little infos" *ngIf="chantier.caseId && chantier.caseId.customer" data-toggle="tooltip" data-placement="top">
                {{ chantier.caseId.customer.name }}
             </p>
              <p *ngIf="chantier.caseId" style="font-size: 14px; font-weight: 300;" >{{chantier.caseId.customer.address}} {{chantier.caseId.customer.city}} {{chantier.caseId.customer.cp}} </p>
              <p class="techs"> {{chantier.tech[0].username}} / {{ chantier.chef }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</section>


<section class="wrapper-fostrap" *ngIf="searchResult.length == 0">
  <div class="container-fostrap">
    <div class="content">
      <div class="row">
        <div class="col-xs-12 col-lg-12 col-sm-12">Jours précédents</div>
        <div class=" container-travaux" >
        <div class="col-xs-12 col-lg-4 col-sm-6" *ngFor="let chantier of lastChantier">
          <div class="card" [routerLink]="['/technicien-case-detail',chantier._id]" >
            <div class="card-content">
              <div style="display: flex;"><p style="color: var(--tertiarycrm); font-weight: 600;" > {{ chantier.date| date:'short' }}</p> <p style="font-weight: 600; margin-left: 5%; " >{{chantier.title}} </p></div>
              <div class="qualif-mairie" [ngClass]="{'attente':chantier.statut === 'En cours',
              'conforme':chantier.statut === 'Terminé',
              'nonconforme':chantier.statut === 'Annulé'
             }" >{{chantier.statut}} </div>

              <p style="font-size: 16px;" class="little infos" *ngIf="chantier.caseId && chantier.caseId.customer" data-toggle="tooltip" data-placement="top">
                {{ chantier.caseId.customer.name }}
             </p>
              <p *ngIf="chantier.caseId" style="font-size: 14px; font-weight: 300;" >{{chantier.caseId.customer.address}} {{chantier.caseId.customer.city}} {{chantier.caseId.customer.cp}} </p>
              <p class="techs"> {{chantier.tech[0].username}} / {{ chantier.chef }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</section>

<section class="wrapper-fostrap"  *ngIf="currentUser.role !='Responsable Technicien' && searchResult.length == 0">
  <div class="container-fostrap">
    <div class="content">
      <div class="row">
        <div class="col-xs-12 col-lg-12 col-sm-12">Aujourd'hui</div>
        <div class=" container-travaux">
        <div class="col-xs-12 col-lg-4 col-sm-6" *ngFor="let chantier of todayChantier">
          <div class="card" [routerLink]="['/technicien-case-detail',chantier._id]" >
            <div class="card-content">
              <div style="display: flex;"><p style="color: var(--tertiarycrm); font-weight: 600;" > {{ chantier.date| date:'short' }}</p> <p style="font-weight: 600; margin-left: 5%; " >{{chantier.title}} </p></div>
              <div class="qualif-mairie" [ngClass]="{'attente':chantier.statut === 'En cours',
              'conforme':chantier.statut === 'Terminé',
              'nonconforme':chantier.statut === 'Annulé'
             }" >{{chantier.statut}} </div>
              <p style="font-size: 16px;" class="little infos" *ngIf="chantier.caseId && chantier.caseId.customer" data-toggle="tooltip" data-placement="top">
                {{ chantier.caseId.customer.name }}
             </p>
              <p *ngIf="chantier.caseId" style="font-size: 14px; font-weight: 300;" >{{chantier.caseId.customer.address}} {{chantier.caseId.customer.city}} {{chantier.caseId.customer.cp}} </p>
              <p class="techs"> {{chantier.tech[0].username}} / {{ chantier.chef }}</p>

            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</section>

<div *ngIf="currentUser.role =='Responsable Technicien && searchResult.length == 0'" >

<section class="wrapper-fostrap">
  <div class="container-fostrap">
    <div class="content">
      <div class="row">
        <div class="col-xs-12 col-lg-12 col-sm-12" > Aujourd'hui </div>
        <div class=" container-travaux" >
        <div class="col-xs-12 col-lg-4 col-sm-6" *ngFor="let chantier of todayChantier">
          <div class="card" [routerLink]="['/technicien-case-detail',chantier._id]" >
            <div class="card-content">
              <div style="display: flex;"><p style="color: var(--tertiarycrm); font-weight: 600;" > {{ chantier.date| date:'short' }}</p> <p style="font-weight: 600; margin-left: 5%; " >{{chantier.title}} </p></div>
              <div class="qualif-mairie" [ngClass]="{'attente':chantier.statut === 'En cours',
              'conforme':chantier.statut === 'Terminé',
              'nonconforme':chantier.statut === 'Annulé'
             }" >{{chantier.statut}} </div>

              <p style="font-size: 16px;" class="little infos" *ngIf="chantier.caseId && chantier.caseId.customer" data-toggle="tooltip" data-placement="top">
                {{ chantier.caseId.customer.name }}
             </p>
              <p *ngIf="chantier.caseId" style="font-size: 14px; font-weight: 300;" >{{chantier.caseId.customer.address}} {{chantier.caseId.customer.city}} {{chantier.caseId.customer.cp}} </p>
              <p class="techs"> {{chantier.tech[0].username}} / {{ chantier.chef }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</section>
<section class="wrapper-fostrap">
  <div class="container-fostrap">
    <div class="content">
      <div class="row">
        <div class="col-xs-12 col-lg-12 col-sm-12" >Chantier à venir</div>
        <div class=" container-travaux">
        <div class="col-xs-12 col-lg-4 col-sm-6" *ngFor="let chantier of weekChantier">
          <div class="card" [routerLink]="['/technicien-case-detail',chantier._id]" >
            <div class="card-content">
              <div style="display: flex;"><p style="color: var(--tertiarycrm); font-weight: 600;" > {{ chantier.date| date:'short' }}</p> <p style="font-weight: 600; margin-left: 5%; " >{{chantier.title}} </p></div>
              <div class="qualif-mairie" [ngClass]="{'attente':chantier.statut === 'En cours',
              'conforme':chantier.statut === 'Terminé',
              'nonconforme':chantier.statut === 'Annulé'
             }" >{{chantier.statut}} </div>
              <p style="font-size: 16px;" class="little infos" *ngIf="chantier.caseId && chantier.caseId.customer" data-toggle="tooltip" data-placement="top">
                {{ chantier.caseId.customer.name }}
             </p>
              <p *ngIf="chantier.caseId" style="font-size: 14px; font-weight: 300;" >{{chantier.caseId.customer.address}} {{chantier.caseId.customer.city}} {{chantier.caseId.customer.cp}} </p>
              <p class="techs">  {{chantier.tech[0].username}} /  {{ chantier.chef }}</p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</section>
</div>
