import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { RolesService } from '@app/services/roles.service';
import { FinancementsService } from '@app/services/financements.service';
import { HistoriqueService } from '@app/services/historique.service';
import { CasesService } from '@app/services/cases.service';

@Component({
  selector: 'app-case-financement-add',
  templateUrl: './case-financement-add.component.html',
  styleUrls: ['./case-financement-add.component.scss']
})
export class CaseFinancementAddComponent implements OnInit {

  caseId;
  financementForm: FormGroup;
  case;
  multipleImagesFinancements;
  financementsOk;
  user;

  types = ['Papier', 'Dématérialisé'];

  otherPartenaire = "Autre"

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public sanitizer: DomSanitizer,
    private router: Router,
    private sRoles: RolesService,
    private sFinancements: FinancementsService,
    private sHistoriques: HistoriqueService,
    private sCases: CasesService,
  ) {
    this.user = this.sRoles.user.role;
    this.caseId = this.route.snapshot.params['id'];
    this.financementForm = this.fb.group({
      caseId: new FormControl(this.caseId, [Validators.required]),
      type_op: new FormControl('', [Validators.required]),
      partenaire: new FormControl( this.otherPartenaire, [Validators.required]),
      state: new FormControl('', [Validators.required]),
      sepa: new FormControl(false, [Validators.required]),
      date_depot: new FormControl('', [Validators.required]),
      date_deblocage: new FormControl(null),
      deblocage: new FormControl(false),
      created_by: new FormControl(this.sRoles.user.id),
      acompte: new FormControl(false),
      solde: new FormControl(false),
    });
   }

  ngOnInit(): void {
    this.showCaseById();
   }

  createFinancement(reason, change,value) {
     this.sFinancements.createFinancement(this.financementForm.value).then(res => {
      if (res['error']) {
        console.log(res['error']);
      }
      this.goToList();
    });
    this.sHistoriques.createHistorique(this.caseId, this.case.customer._id, reason, change,value,this.sRoles.user.id).then(res => {
      if (res['error']) {
        alert(res);
      }
    });
  }

  showCaseById() {
    return new Promise(resolve => {

    this.sCases.getCaseById(this.caseId).subscribe(res => {
      this.case = res[0];
      resolve(res);
        }, error => {
          console.log(error);
      });
    });
  }

  goToList() {
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['case-template', this.caseId], { state: { value: 'financementList' } });
  }


  validPartenaire(){
    this.otherPartenaire = this.financementForm.value.partenaire
  }
}
