import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrl} from '@environments/env';

@Injectable({
  providedIn: 'root'
})
export class InfosService {
  url = apiUrl.apiUrl + '/info/';
  //url = 'http://localhost:4000/info/'
  headers = { 'Content-Type': 'application/json' };

  constructor(
    private http: HttpClient,
  ) { }


  getAllInfos() {
    return this.http.get(this.url);
  }

  getInfoById(id) {
    return this.http.get(this.url + id);
  }
  getInfoByAdvId(id) {
    // 200 dernieres notifs
    return this.http.get(this.url + 'adv/' + id);
  }

  getInfoByComptaId(id) {
    return this.http.get(this.url + 'compta/' + id);
  }

  createInfo(InfoForm): Promise<any> {
    return this.http
      .post(this.url + 'add', JSON.stringify(InfoForm), { headers: this.headers })
      .toPromise()
      .then(res => res as any);
  }

  editInfo(InfoForm, id): Promise<any> {
    return this.http
      .put(this.url + id, JSON.stringify(InfoForm), { headers: this.headers })
      .toPromise()
      .then(res => res as any);
  }

  delete(id) {
    return this.http.delete(this.url + id);
  }
}
